import {call, put, takeEvery} from 'redux-saga/effects';
import * as chartActionTypes from './actionTypes';
import * as actionTypes from './../../actionTypes';
import AsyncWidgetService from "../../../../services/async/AsyncWidgetService";

function* loadMilkWidgetValue({ id }) {
    try {
        const value = yield call(getMilkWidgetValueRequest, id);
        yield put({
            type: actionTypes.LOAD_WIDGET_DATA_SUCCESS,
            id: id,
            data: value
        })
    } catch (e) {
        yield put({
            id: id,
            type: actionTypes.LOAD_WIDGET_DATA_FAILED
        });
    }
}

const milkSagas = [
    takeEvery(chartActionTypes.LOAD_MILK_WIDGET_VALUE,  loadMilkWidgetValue)
];

const getMilkWidgetValueRequest = (id) => {
    return AsyncWidgetService.getMilkWidgetValue(id);
};

export { milkSagas };




