import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import _ from "lodash";
import Select from "react-select";

class TwoInputFieldMulti extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            dropValue: props.dropValue? props.dropValue: '',
            multiValue: props.multiValue? props.multiValue: '',
            multiOptions: props.multiOptions? props.multiOptions: [],
            selectData: props.selectData? props.selectData: [],
            dataId: props.dataId? props.dataId: 'id',
            dataAttribute: props.dataAttribute? props.dataAttribute : 'name',
            error: props.error? props.error: '',
            isVisible: !_.isNil(props.isVisible)? props.isVisible: true,
            isRequired: !_.isNil(props.isRequired)? props.isRequired: false,
            disabled: !_.isNil(props.disabled)? props.disabled: false,
            bottomMargin:  !_.isNil(props.bottomMargin)? props.bottomMargin: true,
        }
    }

    componentWillReceiveProps(nextProps) {
        let newState = {
            isVisible: !_.isNil(nextProps.isVisible)? nextProps.isVisible: true,
            isRequired: !_.isNil(nextProps.isRequired)? nextProps.isRequired: true,
            error: !_.isNil(nextProps.error) ? nextProps.error : this.state.error,
            textValue: !_.isNil(nextProps.textValue) ? nextProps.textValue : this.state.textValue,
            dropValue: !_.isNil(nextProps.dropValue) ? nextProps.dropValue : this.state.dropValue
        };

        if(nextProps.forceNewPropValue) {
            newState.multiValue = nextProps.multiValue;
            newState.multiOptions = nextProps.multiOptions;
            newState.error = nextProps.error;
        }

        this.setState(newState);
    }

    validateField(...param) {
        return true;
    }

    focus(){
        this.input.focus();
    }

    setError(error){
        this.setState({error: error});
    }

    setOptions(value){
        this.setState({multiOptions: value});
    }

    setData(value){
        this.setState({multiValue: value});
    }

    getFieldValue() {
        let values = [];
        if(!_.isEmpty(this.state.multiValue)){
            this.state.multiValue.forEach(val => {
                if(!_.isNil(val[this.state.dataId])) {
                    values.push(val[this.state.dataId])
                } else {
                    values.push(val);
                }
            });
        }
        return values;
    }

    getDropFieldValue() {
        return this.state.dropValue;
    }

    getMultiFieldValue() {
        return this.state.multiValue;
    }

    getError(){
        return this.state.error;
    }

    clearFieldValue(){
        this.setMultiFieldValue('');
        this.setDropFieldValue('');
    }

    setMultiFieldValue(value) {
        this.setState({multiValue: value});
    }

    setDropFieldValue(value) {
        this.setState({dropValue: value});
    }

    setFieldValue(value) {
        this.setState({multiValue: value});
    }

    setVisibility(value){
        this.setState({isVisible: value});
    }

    isVisible(){
        return this.state.isVisible;
    }

    render() {
        let className = 'form-group col-lg-' + this.props.width + ' ' + this.state.className;
        if(!this.state.bottomMargin) {
            className += ' mb-zero-absolute'
        }
        if (this.state.labelPosition === 'in-line') {
            className += ' flex-row';
            if(!_.isEmpty(this.state.error)) {
                className += ' has-error';
            }
        }
        if(this.state.isVisible) {
            return <div className={className}>
                {createLabel(this)}
                {createInput(this)}
                {createHelper(this)}
                {this.props.children}
            </div>;
        } else {
            return <div className={className}/>;
        }
    }
}

function createLabel(component){
    if(!_.isNil(component.props.fieldLabel)){
        let labelStyle = component.state.labelWidth ? {width: component.state.labelWidth} : {};
        let smallLabel;
        let br;
        let hasAsterisk = !_.isNil(component.props.hasRequiredAsterisk)? component.props.hasRequiredAsterisk : true;
        if (!_.isNil(component.props.smallFieldLabel)) {
            smallLabel = <small className={classnames('optional')}> {component.props.smallFieldLabel} </small>;
            br = <br/>;
        }
        return (
            <div>
                <label className={classnames({"required-field": component.state.isRequired && hasAsterisk && !component.props.isView},'pd-r-15')} style={labelStyle}>
                    {component.props.fieldLabel}
                    {br}
                    {smallLabel}
                </label>
            </div>
        );
    } else {
        return null;
    }
}

function createInput(component){
    let element;
    if(component.props.isView) {
        element = (
            <div>
                <Select
                    multi
                    className={'two-input-drop col-xs-12 p-l-r-0'}
                    disabled={true}
                    value={component.state.multiValue}
                    placeholder={component.props.multiPlaceHolder}
                    options={component.state.multiOptions}
                    labelKey={component.state.dataAttribute}
                    valueKey={component.state.dataId}
                />
            </div>
        );
    } else {
        element = (<div className='col-xs-12 two-input'>
            <Select
                className={'two-input-drop-firstItem col-xs-4 col-sm-3 col-md-2 col-lg-4'}
                disabled={component.props.disabled || _.isEmpty(component.state.selectData)}
                clearable={false}
                value={component.state.dropValue}
                placeholder={component.props.dropPlaceHolder}
                options={component.state.selectData}
                onChange={value => handleDropFieldChange(component, value)}
                labelKey={component.state.dataAttribute}
                valueKey={component.state.dataId}
            />
            <Select.Creatable
                multi
                className={'two-input-drop-secondItem p-l-r-0 col-xs-8 col-sm-9 col-md-10 col-lg-8'}
                disabled={false}
                clearable={true}
                value={component.state.multiValue}
                placeholder={component.props.multiPlaceHolder}
                options={component.state.multiOptions}
                onChange={value => handleMultiFieldChange(component, value)}
                labelKey={component.state.dataAttribute}
                valueKey={component.state.dataId}
            />
        </div>);
    }

    if(!_.isEmpty(component.state.error) &&
        component.state.labelPosition === 'in-line'){
        element = getErrorAreaForInlineView(component, element);
    } else if (!_.isEmpty(component.state.error)){
        element = getErrorArea(component, element);
    }

    return element;
}

function createHelper(component) {
    if(!_.isNil(component.props.fieldHelper)){
        let element;
        if(component.props.isView) {
            element = (
                <span className="f-size-14">{component.props.fieldHelper}</span>
            );
        } else {
            element = (
                <div className="labeled-field-helper">{component.props.fieldHelper}</div>
            );
        }
        return element;
    }
}

function getErrorArea(component, field) {
    return (
        <div className={classnames('has-error')}>
            {field}
            <span className='help-block pull-right'><i>{component.state.error}</i></span>
        </div>
    );
}

function getErrorAreaForInlineView(component,field) {
    return (
        <div className='has-error entity-div-field-error'>
            {field}
            <span className='has-error-color pull-right'><i>{component.state.error}</i></span>
        </div>
    );
}


function handleDropFieldChange(component, value) {
    component.setState({dropValue: value[component.state.dataId]});
}

function handleMultiFieldChange(component, value) {
    const onFieldChange = component.props.onFieldChange ? component.props.onFieldChange : () => {};

    if (_.isEmpty(value)){
        component.setState({multiValue: [], multiOptions: [], dropValue: []}, () => onFieldChange(component.state.multiValue));
    } else {
        let newValue = value[value.length - 1];
        if (_.isEmpty(newValue) || validateOption(component, newValue[component.state.dataId])) {
            let newValueWithPrefix = (_.startsWith(newValue[component.state.dataId], '+') || _.startsWith(newValue[component.state.dataId], '00')) ? newValue[component.state.dataId] : component.getDropFieldValue() + ' ' + newValue[component.state.dataId];
            let index = _.findIndex(component.state.multiOptions, i => i === newValue);
            let newOptions = component.state.multiOptions;

            newValue[component.state.dataId] = newValueWithPrefix;
            newValue[component.state.dataAttribute]  = newValueWithPrefix;
            value[value.length - 1] = newValue;
            if (index > -1) {
                newOptions[index] = newValue;
            }
            component.setState({multiValue: value, multiOptions: newOptions}, () => onFieldChange(component.state.multiValue));
        } else {
            component.setState({
                multiOptions: _.remove(component.state.multiOptions, i => i !== newValue)
            });
        }
    }
}

function validateOption(component, newValue) {
    if (!_.isNil(component.props.validatorRegex)) {

        if (_.isNil(newValue) || !component.props.validatorRegex.test(newValue)) {
            return false;
        }
    }
    return true;
}



TwoInputFieldMulti.propTypes = {
    multiPlaceHolder: PropTypes.string,
    dropPlaceHolder: PropTypes.string,
    fieldStyle: PropTypes.object,
    enabledClassName: PropTypes.string,
    disabledClassName: PropTypes.string,
    onFieldChange: PropTypes.func,
    validator: PropTypes.func,
    onClickField: PropTypes.func,
    dropValue: PropTypes.any,
    multiValue: PropTypes.any,
    isView: PropTypes.bool,
    permitCreate: PropTypes.bool,
    isRequired: PropTypes.bool,
    onBlur: PropTypes.func
};

export default TwoInputFieldMulti;