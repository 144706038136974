import axios from "axios";
import _ from "lodash";

const AsyncWidgetService = {

    editWidgetAsync: function (widgetId, widgetInfo) {
        return new Promise((accept, reject) => {
            let formBox = new FormData();
            if (!_.isNil(widgetInfo.configs) && !_.isNil(widgetInfo.configs.imageDropzone)) {
                widgetInfo.configs.imageDropzone.forEach(file => {
                    formBox.append('files', file);
                });
                widgetInfo.configs.imageDropzone = null;
            }
            formBox.append('request', new Blob([JSON.stringify(widgetInfo)], {type: "application/json"}));
            axios.put("/widgets/" + widgetId, formBox).then(
                response => accept(response.data),
                error => reject(error)
            )
        });
    },

    getAnimalsMortalityByCategory: function (entities) {
        return new Promise((accept, reject) =>{
            axios.get('/widgets/mortalityByCauseInfo', {
                params: {entityIds: entities}
            }).then(
                response => accept(response.data),
                error => reject(error)
            )
        });
    },

    fetchRealTimeWidgetDataAsync: function (interfaceIds) {
        return new Promise((accept, reject) => {
            let url = "/widgets"
            if(_.includes(window.location.href, "/public-dashboard")) {
                url += "/public"
            }
            axios.post(url + "/last_readings", {interfaceIds: interfaceIds}).then(
                response => accept(response.data),
                error => reject(error)
            )
        });
    },

    getAverageDailyGain: function(entities, species, time, startDate, endDate) {
        return new Promise ((accept, reject) =>{
            axios.get('/widgets/averageDailyGainInfo', {
                params: {entities: entities, species: species, time: time, startDate: startDate, endDate: endDate}}).then(
                response => accept(response.data),
                (error) => reject(error)
            )});
    },

    getConversionIndex: function(entities, species, time, startDate, endDate) {
        return new Promise((accept,reject) => {
            axios.get('/widgets/conversionIndexInfo', {
                params: {entities: entities, species: species, time: time, startDate: startDate, endDate: endDate}
            }).then(
                response => accept(response.data),
                (error) => reject(error)
            )});
    },

    getAverageWeightOfAnimalExitsInfo: function(entities, species, time, startDate, endDate) {
        return new Promise((accept,reject) => {
            axios.get('/widgets/averageWeightOfAnimalExitsInfo', {
                params: {entities: entities, species: species, time: time, startDate: startDate, endDate: endDate}
            }).then(
                response => accept(response.data),
                (error) => reject(error)
            )});
    },

    getMortality: function(entities, species, time, startDate, endDate) {
        return new Promise((accept,reject) => {
            axios.get('/widgets/mortalityInfo', {
                params: {entities: entities, species: species, time: time, startDate: startDate, endDate: endDate}
            }).then(
                response => accept(response.data),
                (error) => reject(error)
            )});
    },

    getChartWidgetData: function(widgetId, searchData) {
        return new Promise((accept, reject) => {
            let url = "/widgets"
            if(_.includes(window.location.href, "/public-dashboard")) {
                url += "/public"
            }
            axios.get(url + "/chart_series_readings/" + widgetId, {
                params: searchData
            }).then(response => {
                accept(response.data);
            }, reject);
        });
    },

    getAnimalsByWeight: function(entities) {
        return new Promise((accept, reject) =>{
            axios.get('/widgets/animalsByWeightInfo', {
                params: {entityIds: entities}
            }).then(response => {
                accept(response.data);
            }, reject);
        });
    },

    getAsyncConsumptionWidgetReadings: function (widgetId) {
        return new Promise((accept, reject) => {
            let url = "/widgets"
            if(_.includes(window.location.href, "/public-dashboard")) {
                url += "/public"
            }
            axios.get(url + '/consumption/' + widgetId)
                .then(response => {
                    accept(response.data);
                }, reject);
        });
    },

    getAsyncSiloInfoData: function (widgetId) {
        return new Promise((accept, reject) => {
            let url = "/widgets"
            if(_.includes(window.location.href, "/public-dashboard")) {
                url += "/public"
            }
            axios.get(url + '/siloInfo/' + widgetId).then(response => {
                accept(response.data);
            }, reject);
        });
    },

    getMilkWidgetValue: function (widgetId) {
        return new Promise((accept, reject) => {
            let url = "/widgets"
            if(_.includes(window.location.href, "/public-dashboard")) {
                url += "/public"
            }
            axios.get(url + '/milk/' + widgetId).then(response => {
                accept(response.data);
            }, reject);
        });
    },

    getBatchKPIEvolutionWidgetReadings: function (widgetId) {
        return new Promise((accept, reject) => {
            let url = "/widgets"
            axios.get(url + '/batch-evolution/' + widgetId).then(response => {
                accept(response.data);
            }, reject);
        });
    }
};

export default AsyncWidgetService;