import _ from "lodash";
import EntityService from "../services/EntityService";
import LocalesUtils from "./LocalesUtils";

let EntityUtils = {

    getSectorsWithFarmName(entities) {
        if(_.isNil(entities)) {
            return [];
        }
        let refactoredEntities = [];
        entities.forEach(entity => {
            refactoredEntities.push(this.getSectorWithFarmName(entity));
        });
        return refactoredEntities;
    },

    getSectorWithFarmName(entity) {
        let ent = _.cloneDeep(entity);
        if (ent.type.name === EntityService.entityType.SECTOR.name && ent.farmOfSector) {
            ent.complexName = ent.farmOfSector.displayName + ' | ' + ent.displayName;
        } else {
            ent.complexName = ent.displayName;
        }
        return ent;
    },

    excludeFarms(sectors) {
        let result = [];
        _.forEach(sectors, entity => {
            if(entity.type.name === EntityService.entityType.SECTOR.name) {
                result.push(entity);
            }
        });
        return result;
    },

    getEntityInfoFromResponse(data) {
        let entityInfo = {};
        entityInfo.id = data.id;
        entityInfo.entityName = data.name;
        entityInfo.displayName = data.displayName;
        entityInfo.code = data.code;
        entityInfo.status = data.status;
        entityInfo.dashboard = data.dashboard;
        entityInfo.responsibleUsers =
            _.isNil(data.responsibleUsers)? [] :
                _.map(data.responsibleUsers, user => {
                    return {
                        value: user.userId,
                        label: user.shortName
                    }
                });
        entityInfo.parentId = _.isNil(data.parent)? "" : data.parent.id;
        entityInfo.typeId = data.type.id;
        entityInfo.type = data.type;
        entityInfo.area = _.isNil(data.area)? "" : data.area;
        entityInfo.usableArea = _.isNil(data.usableArea)? "" : data.usableArea;
        entityInfo.tags = _.isNil(data.tags)? "" :
            _.map(data.tags, tag => {
                let tagName = tag.name;
                if(tag.translations) {
                    tagName = LocalesUtils.getLocaleString(tag.translations);
                }
                return {value: tag.id, label: tagName};
            });
        entityInfo.latitude = _.isNil(data.latitude)? "" : data.latitude;
        entityInfo.longitude = _.isNil(data.longitude)? "" : data.longitude;
        entityInfo.areaBoundaries = _.isNil(data.areaBoundaries)? [] : JSON.parse(data.areaBoundaries);

        return entityInfo;
    },

    getBillingInfoFromResponse(data) {
        let billingInfo = {};

        if (!_.isNil(data)) {
            billingInfo.billingName = data.name;
            billingInfo.email = data.email;
            billingInfo.fax = data.fax;
            billingInfo.phone = data.phoneNumber;
            billingInfo.addressLine1 = data.addressLine1;
            billingInfo.addressLine2 = data.addressLine2;
            billingInfo.postalCode = data.postalCode;
            billingInfo.city = data.city;
            billingInfo.region = data.region;
            billingInfo.vat = data.vat;
            billingInfo.iban = data.iban;
            billingInfo.bicSwiftIso9362 = data.bicSwiftIso9362;
            billingInfo.country = _.isNil(data.country)? "" : data.country;
        }

        return billingInfo;
    },

    selectedEntityTypeIs(type1, type2) {
        return !_.isNil(type1) && !_.isNil(type2) && _.capitalize(type1.name) === _.capitalize(type2.name);
    },

    filterEntitiesOfType(entities, typeName) {
        return _.filter(entities, entity => {return entity.type.name === typeName;});
    },

    getTenantEntityWithType(entities) {
        let rootEntities = [];
        _.forEach(entities, e => {
            rootEntities.push({
                ...e,
                name: e.type.name + ' | ' + e.displayName,
            })
        });
        return _.sortBy(rootEntities, ['name']);
    }
};

export default EntityUtils;