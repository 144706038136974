import {combineReducers} from "redux";
import {all} from 'redux-saga/effects';
import {dashboardSagas} from "./reducers/dashboard/sagas";
import {dashboardTreeSagas} from "./reducers/dashboard/dashboardTreeSagas";
import {entitiesSagas} from "./reducers/entities/sagas";
import {manualWeighingSagas} from "./views/batches/manualWeighing/form/sagas";
import {batchesSagas} from "./reducers/batches/sagas";
import {RESET_STATE} from "./rootActions";

import routerReducer from "./reducers/routes/reducer";

import realTimeReducer from "./reducers/dashboard/realTimeReducer";
import dashboardReducer from './reducers/dashboard/dashboardReducer';
import dashboardTreeReducer from './reducers/dashboard/dashboardTreeReducer';
import widgetReducer from './reducers/dashboard/widgetReducer';
import widgetConfigReducer from "./reducers/dashboard/widgetConfigReducer";
import entitiesReducer from "./reducers/entities/reducer";

import batchesReducer from "./reducers/batches/reducer";
import manualWeighingReducer from "./views/batches/manualWeighing/form/reducers";

const appReducer = combineReducers({
    //dashboard
    dashboardReducer: dashboardReducer,
    widgetReducer: widgetReducer,
    realTimeReducer: realTimeReducer,
    widgetConfigReducer: widgetConfigReducer,
    dashboardTreeReducer: dashboardTreeReducer,

    //entities
    entitiesReducer: entitiesReducer,

    //batches
    batchesReducer: batchesReducer,
    manualWeighingReducer: manualWeighingReducer,

    routerReducer: routerReducer
});

const rootReducer = (state, action) => {
    if (action.type === RESET_STATE) {
        state = {
            routerReducer : state.routerReducer
        }
    }
    return appReducer(state, action)
};

function* rootSagas() {
    yield all([
        ...dashboardSagas,
        ...entitiesSagas,
        ...dashboardTreeSagas,

        ...batchesSagas,

        ...manualWeighingSagas,
    ]);
}

export {
    rootReducer,
    rootSagas
}