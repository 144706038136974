import axios from "axios";


const AsyncProductionCurveService = {

    fetchCurveDetails(curveId, ageArray) {
        return new Promise((accept, reject) => {
            axios.get('/productionCurves/' + curveId + '/curveDetails', {
                params: {
                    "age": ageArray
                }
            }).then(response => {
                accept(response.data);
            }, reject);
        });
    },

    getProductionCurves: function (searchObject) {
        return new Promise((accept, reject) => {
            axios.get('/batches/productionCurves', {
                params: {
                    ...searchObject,
                    status: true
                }
            }).then(response => {
                accept(response.data);
            }, reject);
        });
    }
};

export default AsyncProductionCurveService;