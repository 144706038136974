import React, {useEffect, useMemo, useState} from "react";
import RuleService from "../../../../../services/RuleService";
import RolesUtils from "../../../../../utils/RolesUtils";
import {permissions} from "../../../../../utils/permissions";
import RuleDetailsForm from "./RuleDetailsForm";
import _ from "lodash";
import __ from "../../../../../utils/EnhancedLodash";
import {t} from "../../../../../utils/Translation";
import {useHistory, useLocation} from "react-router";
import BasicInfoTab from "./newrule/BasicInfoTab";
import EntityService from "../../../../../services/EntityService";
import Alerts from "../../../../../utils/AlertUtils";
import EventAccordion from "./newrule/EventAccordion";
import GenericTabContent from "../../../../../components/panels/tabs/GenericTabContent";
import EventTab from "./newrule/EventTab";
import EventService from "../../../../../services/EventService";
import UserSessionService from "../../../../../services/UserSessionService";
import moment from "moment";
import {config} from "../../../../../config";
import DialCodesUtils from "../../../../../utils/DialCodesUtils";
import UserService from "../../../../../services/UserService";
import LabeledField from "../../../../../components/fields/labeled/LabeledField";
import ErrorService from "../../../../../services/ErrorService";
import SwitchField from "../../../../../components/fields/switch/SwitchField";
import DateUtils from "../../../../../utils/DateUtils";
import {genericEventConfigurationBuilder} from "../../alarms/parse/ParseFunctions";
import RuleTemplateService from "../../../../../services/RuleTemplateService";
import LocalesUtils from "../../../../../utils/LocalesUtils";
import {reduceActionContactListCount} from "../../../../events/components/EventDetailsFormContainer";
import AsyncInterfaceService from "../../../../../services/async/AsyncInterfaceService";
import AsyncTerminalService from "../../../../../services/async/AsyncTerminalService";

//generic inputs ref
let loaded = false;
let refs =  {
    inputRefs: {}
};

const RuleDetailsFormContainer = (props) => {
    const location = useLocation();
    const history = useHistory();
    const ruleId = props.match.params.ruleId;
    const [tab, setTab] = useState(0);
    const [loading, setLoading] = useState(true);
    const [remoteData, setRemoteData] = useState({});
    const [data, setData] = useState({displayName: {value: undefined}  });
    const updateDataState = (key, newState) => setData(prevState => ({...prevState,  [key]: {...prevState[key], ...newState}}));
    const defaultEventData = useMemo(() => ({
        hasEventData: { value :false },
        title: {},
        status: {},
        autoClose: {value: true},
        severity: {},
        ruleDelay: {},
        description: {},
        category: {},
        hasRepetitionTime: {},
        repetitionTime: {},
        actionConfigurations: {
            mailAction: {
                active: {value: true},
                notifyResponsible: {value: true},
                users: {},
                emails: {},
                recurrence: {value: moment('00:00', config.datepicker.formats.timeWithoutSeconds)}
            },
            smsAction: {
                active: {value: false},
                notifyResponsible: {value: true},
                users: {},
                numbers: {},
                recurrence: {value: moment('00:00', config.datepicker.formats.timeWithoutSeconds)}
            },
            voiceAction: {
                active: {value: false},
                notifyResponsible: {value: true},
                users: {},
                numbers: {}
            },
            whatsAppAction: {
                active: {value: false},
                notifyResponsible: {value: true},
                users: {},
                numbers: {},
                recurrence: {value: moment('00:00', config.datepicker.formats.timeWithoutSeconds)}
            },
        }
    }), []);
    const [eventData, setEventData] = useState(defaultEventData);
    const updateDataEventState = (key, newState) => setEventData(prevState => ({...prevState,  [key]: {...prevState[key], ...newState}}));
    const [formState, setFormState] = useState({
        isView: !_.isNil(props.match.params.ruleId),
        isEdit: false,
        isCreate: _.isNil(props.match.params.ruleId),
        user: UserSessionService.getCurrentUserId()
    });
    const hasDeletePermission = useMemo(() => RolesUtils.hasPermissionForName(permissions.rules.delete), []);
    const hasEditPermission = useMemo(() => RolesUtils.hasPermissionForName(permissions.rules.update), []);
    const deleteOrEditIsPossible = !(data?.deploymentStatus === RuleService.deploymentStatus.PENDING_DEPLOY ||
        data?.deploymentStatus === RuleService.deploymentStatus.PENDING_UNDEPLOY || data?.isSystemRule)
    const isGlobalRule = data?.templateId?.value?.id === RuleService.templates.cloudAlarm;
    const showAllInterfaces = !_.isNil(data?.showAllInterfaces?.value) ? data?.showAllInterfaces?.value : false;
    const dialCodes = useMemo(() =>DialCodesUtils.getDialCodes().map(code => ({'value': code.id, 'label': code.name})), []);
    const defaultDialCode = useMemo(() => DialCodesUtils.getDefaultDialCode(), []);
    const [panelsTitle, setPanelsTitle] = useState([]);
    const [tabPanels, setTabPanels] = useState([BasicInfoTab.render(formState, data, (key, value) => updateDataState(key, {value: value}))]);

    useEffect(() => {
        if(!formState.isCreate) {
            getRuleDetails(ruleId, details => setRemoteData(details));
        } else {
            getEntityList(updateDataState);
            setLoading(false);
        }
        getEventCategories(eventData, updateDataEventState);
        getUsers(setEventData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if(__.isNilOrEmpty(remoteData)) {
            return;
        }
        setRemoteDataIntoForm(remoteData, setData, setEventData);
    }, [remoteData])

    useEffect(() => {
        let titleList = [];
        _.forOwn(data?.templateId?.groups, (group, key) => {
            titleList.push(titleList.length + 2 +". " + group.name);
        });
        if(data?.templateId?.hasEvent) {
            titleList.push(titleList.length + 2 + t('rules.form.tabs.events.eventHeader'));
            if(data.hasEventData){
                titleList.push(titleList.length + 2 + t('rules.form.tabs.events.actionsHeader'));
            }
        }
        setPanelsTitle(titleList);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.templateId])

    useEffect(() => {
        getSectorsList(data, updateDataState);
        getEntityResponsibleUsers(data, updateDataState);
        getTerminals(data, updateDataState);
        getTemplateList(data, updateDataState);
        getRuleCategories(data, updateDataState);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data.rootEntityId?.value]);

    useEffect(() => {
        handleRuleTemplateCategoriesChange(data, formState, updateDataState);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.templateCategory?.value]);

    useEffect(() => {
        const {isCreate} = formState;
        if(isCreate) {
            getRuleTemplateDetails(data, updateDataState);
            updateStatusValue(data, updateDataState, isGlobalRule);
        }
        getTerminals(data, updateDataState);
        updateStatusVisibility(data, updateDataState);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.templateId?.value]);

    useEffect(() => {
        const {isCreate} = formState;

        if(isCreate){
            updateShowAllInterfacesValue(data, updateDataState, showAllInterfaces);
        }

        updateShowAllInterfacesVisibility(data, updateDataState);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.sectorId?.value]);

    useEffect(() => {
        if(!data.templateId?.templateDetails) {
            setTabPanels([BasicInfoTab.render(formState, data, (key, value) => updateDataState(key, {value: value}))])
            return;
        }

        createTemplateGroupsNames(data, titles => setPanelsTitle(titles));
        createTemplateContents(data, isGlobalRule, formState,
            (key, value) => updateDataState(key, {value: value}),
            contents => setTabPanels([
                BasicInfoTab.render(formState, data, (key, value) => updateDataState(key, {value: value})), ...contents
            ]), history);

        setTimeout(() => {
            if(!loaded) {
                setLoading(false);
                loaded= true;
            }
            setGenericData(data);
            setLoading(false);
        }, 1000);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data?.terminalId, data?.rootEntityId, data?.rootEntityId?.error,
        data?.terminalId?.error,  data?.templateId?.error, data?.sectorId, data?.sectorId?.error,  data?.displayName?.error,
        data?.templateId, data?.templateCategory, formState?.isView, formState?.isEdit, data?.showAllInterfaces, data?.isCloudProcessor ]);

    const updateEventAction = (value, key, type) => setEventData( _data => {
        return {
            ..._data,
            actionConfigurations : {
                ..._data.actionConfigurations,
                [type]: {
                    ..._data.actionConfigurations[type],
                    [key]: {
                        ..._data.actionConfigurations[type][key],
                        value: value
                    }
                }
            }
        }
    });

    const onBack = () => {
        if(location?.state?.groupEntity?.value && location?.state?.groupEntity?.data){
            history.push("/rules/groups", {groupEntity: location?.state?.groupEntity?.value?.id, expandedGroup: location?.state?.ruleGroup});
        } else if(location?.state?.groupEntity?.value && !location?.state?.groupEntity?.data){
            history.push("/rules/groups", {groupEntity: location?.state?.groupEntity?.value?.id, expandedGroup: RuleService.expandedGroup.NO_GROUP});
        } else if(location?.state?.dashboardId) {
            history.push("/farmview?dashboard=" + location?.state?.dashboardId);
        } else if(location?.state?.alarmRuleId) {
            history.push("/rules/alarms/" + location?.state?.alarmRuleId + "/details", {ruleData:  location?.state?.ruleData});
        } else if(location?.state?.inter?.id) {
            history.push("/rules/scheduler/" + location?.state?.inter?.id + "/details", {inter:  location?.state?.inter, deploymentStatus: location?.state?.inter?.deploymentStatus});
        } else if(location?.state?.eventId) {
            history.push("/events/" + location?.state?.eventId + "/details");
        }else if(location.moreProps && location.moreProps.historyBack) {
            history.goBack();
        } else {
            history.push('/rules', {...location.state});
        }
    }

    const onEdit = () => {
        const {isView, isEdit} = formState;
        setFormState({
            ...formState,
            isView: !isView,
            isEdit: !isEdit
        });
        if(isEdit) {
            //reset form state to remoteData
            setRemoteDataIntoForm(remoteData, setData, setEventData);
        }
    }

    const onDelete = () => {
        deleteRule(remoteData, onBack);
    }

    const onFormCreateSubmit = (deploy) => {
        const ruleCreateData = getFormData(data, eventData, deploy);
        const isValid = validateForm(data, eventData, ruleCreateData, isGlobalRule, setData, setEventData, setTab);
        if(!isValid) {
            return;
        }

        handleControlRuleCheck(ruleCreateData, () => handleCreateRuleSubmit(ruleCreateData, deploy, onBack)).then();
    }

    const handleControlRuleCheck = async (ruleData, callback) => {
        if(RuleService.isControlRule(ruleData.templateCategory)) {
            const interfaceSensors = [];
            ruleData.inputs.forEach(item => {
                if (item.id.toLowerCase().includes('sensor')) {
                    interfaceSensors.push(item.value)
                }
            })

            if(interfaceSensors.length > 0){
                const allHaveAlarms = await AsyncInterfaceService.checkInterfacesHaveAlarmsByInterfaceIds(interfaceSensors);

                const alertText = allHaveAlarms ? t('rules.form.alerts.allHaveAlarms.yes.text') :
                    t('rules.form.alerts.allHaveAlarms.no.text');
                const confirmText = t('rules.form.alerts.allHaveAlarms.confirmButtonText');

                await Alerts.confirmWarningFlow({
                    title: t('rules.form.alerts.allHaveAlarms.title'),
                    html: alertText,
                    confirmButtonText: confirmText
                }).show(() => {
                    callback();
                });
            }
        } else {
            callback();
        }

    }

    const onFormEditSubmit = (deploy, hasRuleGroup) => {

        const ruleEditData = getFormData(data, eventData, deploy);
        let oldRuleStatus, newRuleStatus;

        if(isGlobalRule){
            oldRuleStatus = remoteData.status;
            newRuleStatus = ruleEditData.status;
        }else{
            oldRuleStatus = remoteData.deploymentStatus;
            newRuleStatus = deploy ? RuleService.deploymentStatus.DEPLOYED : RuleService.deploymentStatus.UNDEPLOYED;
        }

        const needToDropRuleGroup = hasRuleGroup && oldRuleStatus !== newRuleStatus &&
            oldRuleStatus !== RuleService.deploymentStatus.ERROR;

        ruleEditData.id = ruleId;
        const isValid = validateForm(data, eventData, ruleEditData, isGlobalRule, setData, setEventData, setTab);
        if(!isValid) {
            return;
        }

        handleControlRuleCheck(ruleEditData, () => handleEditRuleSubmit(ruleEditData, deploy, onBack, needToDropRuleGroup)).then();

    }

    const onDeploySubmit = () => {
        handleControlRuleCheck({
            displayName: remoteData.displayName,
            inputs: remoteData.inputs,
            templateCategory: remoteData.ruleTemplate.category.id}, () => onDeploy(remoteData, onBack)).then();
    }

    const onUnDeploySubmit = () => {
        onUnDeploy(remoteData, onBack);
    }

    let eventsTitles = [];
    let eventsPanels = [];
    if(data.templateId?.templateDetails) {
        eventsTitles =   createTemplateGroupsNamesEvents(data, eventData, data.templateId?.templateDetails.groups);
        eventsPanels =  createTemplateContentsEvents(data, eventData, isGlobalRule, formState,
            (key, value) => updateDataEventState(key, {value: value}),
            updateEventAction,
            dialCodes,
            defaultDialCode
        );
    }
    return (

        <RuleDetailsForm
            loading={loading}
            data={data}
            ruleDetails={remoteData}
            isView={formState.isView}
            isEdit={formState.isEdit}
            isCreate={formState.isCreate}
            isDeleted={!!remoteData.deletedTimestamp}
            isSystemRule={remoteData.isSystemRule}
            hasEditPermission={hasEditPermission}
            hasDeletePermission={hasDeletePermission}
            deleteOrEditIsPossible={deleteOrEditIsPossible}
            isGlobalRule={isGlobalRule}

            tab={tab}
            onTabSelected={tabSelected => setTab(tabSelected)}
            panelsTitle={[...panelsTitle, ...eventsTitles]}
            tabPanels={[...tabPanels, ...eventsPanels]}

            onBack={onBack}
            onEdit={onEdit}
            onDelete={onDelete}
            onFormCreateSubmit={onFormCreateSubmit}
            onFormEditSubmit={onFormEditSubmit}
            onUnDeploySubmit={onUnDeploySubmit}
            onDeploySubmit={onDeploySubmit}
        />
    );
}

function setRemoteDataIntoForm(remoteData, setData, setEventData) {
    setData(preState => {
        return {
            ...preState,
            rootEntityId: {...preState.rootEntityId, value: remoteData.rootEntity, data: [remoteData.rootEntity] },
            sectorId: {...preState.sectorId, value: remoteData.sector},
            showAllInterfaces: {...preState.showAllInterfaces, value: remoteData.showAllInterfaces},
            isCloudProcessor: {...preState.isCloudProcessor, value: remoteData.isCloudProcessor},
            terminalId: {...preState.terminalId, value: remoteData.terminal},
            templateId: {
                ...preState.templateId,
                data: [remoteData.ruleTemplate],
                value: remoteData.ruleTemplate,
                templateDetails: {
                    ...remoteData.ruleTemplate,
                    inputs: remoteData.inputs
                }
            },
            templateCategory: {
                ...preState.templateCategory,
                value: {
                    id: remoteData.ruleTemplate.category.id,
                    name: remoteData.ruleTemplate.category.name
                }
            },
            displayName: { value: remoteData.displayName},
            status: { value: remoteData.status === "ENABLED"},
            deploymentStatus: remoteData.deploymentStatus,
            isSystemRule: remoteData.isSystemRule
        }
    });

    const emailConfig = remoteData?.eventConfiguration?.actionConfigurations.find(ac => ac.type === "MAIL") || {};
    const voiceConfig = remoteData?.eventConfiguration?.actionConfigurations.find(ac => ac.type === "VOICE") || {};
    const smsConfig = remoteData?.eventConfiguration?.actionConfigurations.find(ac => ac.type === "SMS") || {};
    const whatsAppConfig = remoteData?.eventConfiguration?.actionConfigurations.find(ac => ac.type === "WHATSAPP") || {};
    setEventData(prevState => ({
        ...prevState,
        hasEventData: {value: !!remoteData.eventConfiguration},
        title: {value: remoteData?.eventConfiguration?.title},
        status: {value: remoteData?.eventConfiguration?.status},
        autoClose: {value: remoteData?.autoClose},
        severity: {...prevState.severity, value: remoteData?.eventConfiguration?.severityLevel},
        ruleDelay: {value: remoteData?.eventConfiguration?.ruleDelay ? remoteData.eventConfiguration.ruleDelay + "" : "0"},
        description: {value: remoteData?.eventConfiguration?.description},
        category: {...prevState.category, value: remoteData?.eventConfiguration?.category?.value},
        hasRepetitionTime: {value: !__.isNilOrEmpty(remoteData.repetitionTime)},
        repetitionTime: {value: DateUtils.secondsToTime(remoteData?.repetitionTime)},
        actionConfigurations: {
            mailAction: {
                active: {value: !__.isNilOrEmpty(emailConfig.status) ? emailConfig.status : false},
                notifyResponsible: {value: !__.isNilOrEmpty(emailConfig.notifyResponsible) ? emailConfig.notifyResponsible : false},
                users: {
                    ...prevState.actionConfigurations.mailAction.users,
                    value: emailConfig?.properties?.usersList
                },
                emails: {
                    options: emailConfig?.properties?.contactsList.map(u => {
                        return {value: u, label: u, className: "Select-create-option-placeholder"}
                    }),
                    value: emailConfig?.properties?.contactsList.map(u => {
                        return {value: u, label: u, className: "Select-create-option-placeholder"}
                    })
                },
                recurrence: {value: moment(emailConfig?.recurrence ? DateUtils.secondsToTime(emailConfig.recurrence) : "00:00" , config.datepicker.formats.timeWithoutSeconds)}
            },
            smsAction: {
                active: {value: !__.isNilOrEmpty(smsConfig.status) ? smsConfig.status : false},
                notifyResponsible: {value: !__.isNilOrEmpty(smsConfig.notifyResponsible) ? smsConfig.notifyResponsible : false},
                users: {
                    ...prevState.actionConfigurations.smsAction.users,
                    value: smsConfig?.properties?.usersList
                },
                numbers: {
                    value: smsConfig?.properties?.contactsList.map(u => {
                        return {value: u, label: u, className: "Select-create-option-placeholder"}
                    })
                },
                recurrence: {value: moment(smsConfig?.recurrence ? DateUtils.secondsToTime(smsConfig.recurrence) : "00:00", config.datepicker.formats.timeWithoutSeconds)}
            },
            voiceAction: {
                active: {value: !__.isNilOrEmpty(voiceConfig.status) ? voiceConfig.status : false},
                notifyResponsible: {value: !__.isNilOrEmpty(voiceConfig.notifyResponsible) ? voiceConfig.notifyResponsible : false},
                users: {
                    ...prevState.actionConfigurations.voiceAction.users,
                    value: voiceConfig?.properties?.usersList
                },
                numbers: {
                    value: voiceConfig?.properties?.contactsList.map(u => {
                        return {value: u, label: u, className: "Select-create-option-placeholder"}
                    })
                },
            },
            whatsAppAction: {
                active: {value: !__.isNilOrEmpty(whatsAppConfig.status) ? whatsAppConfig.status : false},
                notifyResponsible: {value: !__.isNilOrEmpty(whatsAppConfig.notifyResponsible) ? whatsAppConfig.notifyResponsible : false},
                users: {
                    ...prevState.actionConfigurations.whatsAppAction.users,
                    value: whatsAppConfig?.properties?.usersList
                },
                numbers: {
                    value: whatsAppConfig?.properties?.contactsList.map(u => {
                        return {value: u, label: u, className: "Select-create-option-placeholder"}
                    })
                },
                recurrence: {value: moment(whatsAppConfig?.recurrence ? DateUtils.secondsToTime(whatsAppConfig.recurrence) : "00:00", config.datepicker.formats.timeWithoutSeconds)}
            },
        }
    }));
}

function getFormData(data, eventData, deploy) {
    let formParams = {};

    Object.keys(data).forEach(key => {
        formParams[key] = __.isObject(data[key]?.value) ? data[key]?.value?.id : data[key]?.value
    });

    if(data.templateId?.value?.id === RuleService.templates.cloudAlarm){
        formParams.status = deploy ? 'ENABLED' : 'DISABLED';
    } else {
        formParams.status = __.isNilOrEmpty(data.status.value) || data.status.value ? 'ENABLED' : 'DISABLED'
    }

    let inputs = [];
    Object.keys(refs.inputRefs).forEach(key => {
        const field = refs.inputRefs[key];
        if (!_.isNil(field)) {
            if(!_.isEmpty(field.getFieldValue())) {
                inputs.push({id: key, value: field.getFieldValue()});
            } else if (field instanceof SwitchField) {
                inputs.push({id: key, value: field.getFieldValue() ? 'ENABLED' : 'DISABLED'});
            }
        }
    });
    formParams.inputs = inputs;

    //TODO AFTER REVIEW RULE CODE REMOVE THIS CODE
    if(refs.inputRefs.device) {
        try {
            let interfaceId = refs.inputRefs.device.getDropDownObjectFromValue(refs.inputRefs.device.getFieldValue()).id;
            formParams.interfaceIds = [interfaceId];
        }catch (e) {
            ErrorService.createError(e.stack, JSON.stringify(e));
        }
    } else if(refs.inputRefs.firstDevice && refs.inputRefs.secondDevice){
        try {
            let firstDeviceInterface = refs.inputRefs.firstDevice.getDropDownObjectFromValue(refs.inputRefs.firstDevice.getFieldValue()).id;
            let secondDeviceInterface = refs.inputRefs.secondDevice.getDropDownObjectFromValue(refs.inputRefs.secondDevice.getFieldValue()).id;
            formParams.interfaceIds = [firstDeviceInterface, secondDeviceInterface];
        }catch (e) {
            ErrorService.createError(e.stack, JSON.stringify(e));
        }
    }else if(formParams.templateId === RuleService.templates.scheduler.normal || formParams.templateId === RuleService.templates.scheduler.forced) {
        try {
            const interfaceId = refs.inputRefs.scheduler.schedulerRefs.iface.getDropDownObjectFromValue(refs.inputRefs.scheduler.schedulerRefs.iface.getFieldValue()).id;
            formParams.interfaceIds = [interfaceId];
        }catch (e) {
            ErrorService.createError(e.stack, JSON.stringify(e));
        }
    } else if(formParams.templateId === RuleService.templates.cloudAlarm) {
        try {
            formParams.interfaceIds = _.find(formParams['inputs'], {id: "dropgroup-input"}).value.map(input => input.interfaceId);
        }catch (e) {
            ErrorService.createError(e.stack, JSON.stringify(e));
        }
    }

    formParams.isCyclical = _.find(formParams['inputs'], {id: "dropgroup-input"})?.value.some(input => input.isCyclical) ?? false;

    formParams.autoClose = eventData?.hasEventData?.value ? eventData?.autoClose?.value : true;

    if(data?.templateId?.templateDetails?.hasEvent && eventData?.hasEventData?.value) {
        formParams.eventConfiguration = genericEventConfigurationBuilder(eventData, eventData.ruleDelay.value);
        formParams.repetitionTime = eventData?.hasRepetitionTime?.value ? DateUtils.timeToSeconds(eventData.repetitionTime.value) : null;
    }

    return formParams;
}

function validateForm(data, eventData, formData, isGlobalRule, setData, setEventData, setTab) {
    let isValid = true;
    Object.keys(formData).forEach(key => {
        switch (key) {
            case "rootEntityId":
            case "templateId":
            case "displayName":
            case "sectorId":
                data[key].error = __.isNilOrEmpty(data[key].value) ? t('generic.errors.emptyRequiredField') : undefined;
                break;
            case "terminalId":
                data[key].error = !isGlobalRule && __.isNilOrEmpty(data[key].value) ? t('generic.errors.emptyRequiredField') : undefined;
                break;
            default:
                return;
        }
    });

    setData({...data});
    Object.keys(data).forEach(key => {
        if(isValid && !__.isNilOrEmpty(data[key]?.error)) {
            isValid = false;
        }
    });

    if(!isValid){
        setTab(0);
        return isValid;
    }

    let tab = Number.MAX_VALUE;
    Object.keys(refs.inputRefs).forEach(key => {
        const field = refs.inputRefs[key];
        if(refs.inputRefs[key]) {
            if (!field.validateField()) {
                isValid = false;
                if(field?.props?.tabNumber && (field.props.tabNumber <= tab || tab === -1)){
                    tab = field.props.tabNumber;
                } else {
                    tab = field.getTabNumber()
                }
            }
        }
    });

    if(!isValid){
        setTab(tab);
        return isValid;
    }

    if(eventData?.hasEventData?.value) {
        Object.keys(eventData).forEach(key => {
            switch (key) {
                case "title":
                case "description":
                case "status":
                case "severity":
                case "category":
                    eventData[key].error = __.isNilOrEmpty(eventData[key].value) ? t('generic.errors.emptyRequiredField') : undefined;
                    break;
                case "repetitionTime":
                    eventData[key].error = eventData.hasRepetitionTime?.value && __.isNilOrEmpty(eventData[key].value) ? t('generic.errors.emptyRequiredField') : undefined;
                    break;
                default:
                    return;
            }
        });

        Object.keys(eventData).forEach(key => {
            if(isValid && !__.isNilOrEmpty(eventData[key].error)) {
                isValid = false;
            }
        });

        setEventData({...eventData});
    }

    return isValid;
}

export function getRuleDetails(ruleId, callback) {
    RuleService.getRuleDetails(ruleId, callback, () => Alerts.applicationError());
}

function setGenericData(data) {
    const {templateId} = data;
    _.forEach(templateId?.templateDetails?.inputs, input => {
        let inputField = refs.inputRefs[input.id];

        if (inputField) {
            if(inputField instanceof LabeledField && inputField.props.fieldType === 'dateTimeInterval'){
                let start = input.value.start;
                let end = input.value.end;
                let switchValue = false;
                if(inputField.props.hasDate && !inputField.props.hasTime) {
                    let startTimeArr = _.split(start, '-');
                    start = moment().year(startTimeArr[0]).month((_.parseInt(startTimeArr[1])-1)).date(startTimeArr[2]);
                    if(_.isNil(end) || _.isEmpty(end)) {
                        end = '';
                        switchValue = true;
                        inputField.setDisabledInterval(true, 'end')
                    }
                    else {
                        let endTimeArr = _.split(end, '-');
                        end = moment().year(endTimeArr[0]).month((_.parseInt(endTimeArr[1])-1)).date(endTimeArr[2]);
                        switchValue = false;
                        inputField.setDisabledInterval(false, 'end')
                    }
                } else if (inputField.props.hasTime && !inputField.props.hasDate) {
                    let startTimeArr = _.split(start, ':');
                    let endTimeArr = _.split(end, ':');
                    start = moment().date(1).hours(startTimeArr[0]).minutes(startTimeArr[1]).seconds(startTimeArr[2]);
                    end = moment().date(1).hours(endTimeArr[0]).minutes(endTimeArr[1]).seconds(endTimeArr[2]);
                    if (start.format('HH:mm:ss') === '00:00:00' && end.format('HH:mm:ss') === '23:59:59') {
                        inputField.setDisabled(true);
                        switchValue = true;
                    } else {
                        inputField.setDisabled(false);
                        switchValue = false;
                    }
                }
                inputField.setIntervalValueFor(start, 'start');
                inputField.setIntervalValueFor(end, 'end');
                setTimeout(() => inputField.switch?.setFieldValue(switchValue), 10);
            } else if(inputField instanceof LabeledField && inputField.props.fieldType === 'dateTime') {
                let value = input.value;
                if(inputField.props.hasDate && !inputField.props.hasTime) {
                    let valueArr = _.split(value, '-');
                    value = moment().year(valueArr[0]).month((_.parseInt(valueArr[1])-1)).date(valueArr[2]);
                } else if (inputField.props.hasTime && !inputField.props.hasDate) {
                    let valueArr = _.split(value, ':');
                    value = moment().date(1).hours(valueArr[0]).minutes(valueArr[1]).seconds(valueArr[2]);
                }
                setTimeout(() => inputField.setFieldValue(value), 0);
            } else {
                setTimeout(() => inputField.setFieldValue(input.value), 0);
            }
        }
    });
}

function createTemplateGroupsNames(data, callback) {
    let titleList = [];

    _.forOwn(data.templateId.templateDetails.groups, (group, key) => {
        if(data.templateCategory?.value?.id === 'ALARM_WITHOUT_ACTUATION' && group.id === 'actions')
            return

        titleList.push(titleList.length + 2 +". " + group.name);
    });
    callback(titleList);
}

function createTemplateContents(data, isGlobalRule, formState, updateFormValue, callback, history) {
    let contentList = [];
    let tabNumber = 1;

    const {templateId, rootEntityId, terminalId, sectorId, showAllInterfaces} = data;

    _.forOwn(templateId.templateDetails.groups , (group, key) => {
        if(data.templateCategory?.value?.id === 'ALARM_WITHOUT_ACTUATION' && group.id === 'actions')
            return

        let tab =
            <GenericTabContent
                inputs={group.inputs}
                refs={refs}
                selectedEntity={rootEntityId.value}
                selectedTerminal={terminalId.value}
                selectedSector={sectorId.value}
                selectedRule={templateId.value}
                tabNumber={tabNumber}
                viewMode={formState.isView}
                editMode={formState.isEdit}
                isGlobalRule={isGlobalRule}
                showAllInterfaces={showAllInterfaces.value}
                history={history}
            />;
        tabNumber++;
        contentList.push(tab);
    });
    callback(contentList);
}

function createTemplateGroupsNamesEvents(data, dataEvent, groups) {
    let titleList = []
    let groupsArray = Object.values(groups)

    const filteredGroups = groupsArray.filter(groupItem => groupItem.id !== 'actions' || data.templateCategory?.value?.id !== 'ALARM_WITHOUT_ACTUATION')

    if(data.templateId.templateDetails.hasEvent) {
        titleList.push(filteredGroups.length + titleList.length + 2 + t('rules.form.tabs.events.eventHeader'));
        if(dataEvent?.hasEventData?.value){
            titleList.push(filteredGroups.length + titleList.length + 2 + t('rules.form.tabs.events.actionsHeader'));
        }
    }
    return titleList;
}

function createTemplateContentsEvents(data, dataEvent, isGlobalRule, formState, updateFormEventValue, updateEventAction, dialCodes, defaultDialCode) {
    let contentList = [];
    const {isView} = formState;
    const {templateId} = data;

    if(templateId.templateDetails.hasEvent) {
        contentList.push(EventTab.render(formState, data, dataEvent, isGlobalRule, updateFormEventValue));

        if(dataEvent?.hasEventData?.value){
            const smsService = UserSessionService.getSmsServiceStatus() && RolesUtils.hasPermissionForName(permissions.notifications.sms);
            const voiceService = UserSessionService.getVoiceServiceStatus() && RolesUtils.hasPermissionForName(permissions.notifications.voice);
            const whatsAppService = UserSessionService.getWhatsappServiceStatus() && RolesUtils.hasPermissionForName(permissions.notifications.sms);
            const farmResponsible = data?.rootEntityId?.farmResponsible ? data?.rootEntityId?.farmResponsible : [];
            const eventAccordion = EventAccordion.createAccordionFromEventTypes(
                isView,
                smsService,
                voiceService,
                whatsAppService,
                dataEvent.actionConfigurations,
                dialCodes,
                defaultDialCode,
                farmResponsible,
                updateEventAction
            );
            contentList.push(eventAccordion);
        }
    }
    return contentList;
}

function getEntityList(callback) {
    RuleService.getActiveFarms(farms => {
        callback("rootEntityId", { data: farms});
    }, error => {
        console.error(error);
    });
}

async function getEntityResponsibleUsers({rootEntityId}, callback) {
    if(__.isNilOrEmpty(rootEntityId?.value)) {
        callback("rootEntityId",{
            rootEntityId: {
                farmResponsible: []
            }
        });
        return;
    }

    const responsibleUsers = await EntityService.getEntityResponsibleUsers(rootEntityId.value.id);
    callback("rootEntityId",{
        farmResponsible: responsibleUsers
    });
}

function getTemplateList({rootEntityId, templateCategory}, callback) {
    if(__.isNilOrEmpty(rootEntityId?.value)) {
        callback("templateId", {data: [], value: undefined});
        callback("terminalId", {data: [], value: undefined});
        return;
    }

    RuleService.getTemplateList({entityId: rootEntityId.value.id}, templateList => {
        const category = templateCategory?.value?.id;
        const filteredTemplateList = category ? templateList.filter(template => template?.category?.id === category) : templateList;
        callback("templateId", {
            data: filteredTemplateList,
            all: templateList
        });

    }, () => Alerts.applicationError());
}

function getSectorsList({rootEntityId, sectorId}, callback) {
    if(__.isNilOrEmpty(rootEntityId?.value)) {
        callback("sectorId", {data: [], value: undefined});
        return;
    }
    EntityService.getEntitySectorsList(rootEntityId.value.id, sectorList => {
        callback("sectorId", {data: sectorList});
    }, () => Alerts.applicationError())
}

function handleRuleTemplateCategoriesChange({templateCategory, templateId, rootEntityId}, {isCreate}, callback) {
    if(templateId?.all && isCreate && !__.isNilOrEmpty(rootEntityId?.value)) {
        const category = templateCategory?.value?.id
        let filteredTemplates = category ? templateId.all.filter(template => {
            return category === 'ALARM_WITHOUT_ACTUATION' ? template?.category?.id === category : template?.category?.id === category
        }) : templateId.all;
        callback("templateId", {data: filteredTemplates, value: undefined, templateDetails: undefined});
    }
}

function getRuleTemplateDetails({templateId}, callback) {
    if(!_.isNil(templateId?.value)) {
        RuleService.getRuleTemplate(templateId.value, templateStruct => {
            callback("templateId", {templateDetails: templateStruct});
        }, error => {
            console.error(error)
        });
    } else {
        callback("templateId", {value: undefined, templateDetails: undefined});
    }
}

function getTerminals({rootEntityId, terminalId, templateId}, callback) {
    if (!_.isNil(rootEntityId) && !_.isNil(rootEntityId?.value?.id) && !_.isNil(templateId?.value) && !__.isNilOrEmpty(templateId?.value.profiles)) {
        RuleService.getTerminalsFromEntityAndRule(rootEntityId.value.id, templateId.value.profiles, terminals => {
            callback("terminalId", {
                data: terminals,
                isRequired: true
            });
        }, error => {
            console.error(error)
        });
    } else {
        callback("terminalId",{
            data: [],
            value: undefined,
            isRequired: false
        });
    }
}

function updateShowAllInterfacesVisibility({sectorId}, callback) {
    callback("showAllInterfaces", {isVisible: !__.isNilOrEmpty(sectorId?.value)});
}

function updateShowAllInterfacesValue(data, callback, showAllInterfaces) {
    callback("showAllInterfaces", {value: showAllInterfaces});
}

function updateStatusVisibility({templateId}, callback) {
    callback("status", {isVisible: templateId?.value ? __.isNilOrEmpty(templateId?.value.profiles) : true});
}

function updateStatusValue(data, callback, isGlobalRule) {
    callback("status", {value: !isGlobalRule});
}

export function getEventCategories(eventData, updateDataEventState) {
    EventService.getEventCategories(categories => {
        updateDataEventState("category", {data: categories});
    }, error => {
        console.error(error);
    });
}

function getRuleCategories({rootEntityId}, updateDataEventState) {
    if(__.isNilOrEmpty(rootEntityId?.value)) {
        updateDataEventState("templateCategory", {data: [], value: undefined});
        return;
    }

    RuleTemplateService.getRuleTemplateCategories( categories => {
        updateDataEventState("templateCategory", {
            data: categories.map(cat => ({
                id: cat.id,
                name: LocalesUtils.getLocaleString(cat.name),
                order: cat.order
            }))
        });
    });
}

export function getUsers(setEventData) {
    const protectHiddenValues = !RolesUtils.hasPermissionForName(permissions.users.read) && !RolesUtils.hasPermissionForName(permissions.users.list);
    UserService.getActiveUsersList(users => {
        setEventData(prevState => ({
            ...prevState,
            actionConfigurations: {
                mailAction: {
                    ...prevState.actionConfigurations.mailAction,
                    users: {
                        ...prevState.actionConfigurations.mailAction.users,
                        data: users
                    },
                    protectHiddenValues: protectHiddenValues
                },
                smsAction: {
                    ...prevState.actionConfigurations.smsAction,
                    users: {
                        ...prevState.actionConfigurations.smsAction.users,
                        data: users
                    },
                    protectHiddenValues: protectHiddenValues
                },
                voiceAction: {
                    ...prevState.actionConfigurations.voiceAction,
                    users: {
                        ...prevState.actionConfigurations.voiceAction.users,
                        data: users
                    },
                    protectHiddenValues: protectHiddenValues
                },
                whatsAppAction: {
                    ...prevState.actionConfigurations.whatsAppAction,
                    users: {
                        ...prevState.actionConfigurations.whatsAppAction.users,
                        data: users
                    },
                    protectHiddenValues: protectHiddenValues
                },
            }
        }));
    })
}

//delete rule
export function deleteRule({id, displayName}, onBack) {
    const rulesApplicationError = Alerts.applicationError({
        html: t('rules.alerts.error.delete.text', {ruleName: displayName})
    }).onClose(onBack);

    Alerts.confirmWarningFlow({
        title: t('rules.alerts.delete.title'),
        html: t('rules.alerts.delete.text', {ruleName: displayName}),
        confirmButtonText: t("generic.delete")
    }).show(() => {
        Alerts.loading();
        RuleService.deleteRule(id, response => {
            let cannotDeleteRuleDeferred = config.deferredStatus.CANNOT_DELETE_A_DEPLOYED_OR_PENDING_RULE;
            if (_.isNil(response.deferredStatus) || _.isEmpty(response.deferredStatus)) {
                Alerts.timedSuccess({
                    html: t('rules.alerts.delete.success.text', {ruleName: displayName})
                }).onClose(onBack).show();
            } else if (response.deferredStatus === cannotDeleteRuleDeferred) {
                Alerts.applicationError({
                    title: t('rules.alerts.delete.deferred.title'),
                    html: t('rules.alerts.delete.deferred.text', {displayName: displayName}),
                }).onClose(onBack).show();
            } else {
                rulesApplicationError.show();
            }
        }, () => rulesApplicationError.show());
    });
}

//edit
export function handleEditRuleSubmit(ruleData, deployRule, onBack, needToDropRuleGroup) {

    let eventConfigWarnings = validateEventConfig(ruleData);

    if (eventConfigWarnings.length !== 0) {
        let warningMsg = "";
        _.forEach(eventConfigWarnings, msg => {
            warningMsg = warningMsg + "<br>" + msg;
        });
        Alerts.confirmWarningFlow({
            title: t('rules.alerts.config.title'),
            html: t('rules.alerts.config.text', {param: warningMsg}),
            confirmButtonText: t("generic.yes"),
            cancelButtonText: t("generic.no"),
        }).show(() => {
            callEditRule(ruleData, deployRule, onBack, needToDropRuleGroup);
        })
    } else {
        callEditRule(ruleData, deployRule, onBack, needToDropRuleGroup);
    }
}

function validateEventConfig(ruleData){
    let eventConfigWarnings = [];
    if(!_.isNil(ruleData.eventConfiguration) && !ruleData.autoClose){
        eventConfigWarnings.push(t('rules.alerts.config.autoClose'));
    }

    if(!_.isNil(ruleData.eventConfiguration) && !_.isNil(ruleData.eventConfiguration.actionConfigurations) && (
        (
            (!__.isNilOrEmpty(ruleData.eventConfiguration.actionConfigurations[0]) && ruleData.eventConfiguration.actionConfigurations[0].status) &&
            (ruleData.eventConfiguration.actionConfigurations[0].properties.usersList.length === 0 ) &&
            (ruleData.eventConfiguration.actionConfigurations[0].properties.contactsList.length === 0 ) &&
            (!ruleData.eventConfiguration.actionConfigurations[0].notifyResponsible)
        ) || (
            (!__.isNilOrEmpty(ruleData.eventConfiguration.actionConfigurations[1]) && ruleData.eventConfiguration.actionConfigurations[1].status) &&
            (ruleData.eventConfiguration.actionConfigurations[1].properties.usersList.length === 0) &&
            (ruleData.eventConfiguration.actionConfigurations[1].properties.contactsList.length === 0 ) &&
            (!ruleData.eventConfiguration.actionConfigurations[1].notifyResponsible)
        ) || (
            (!__.isNilOrEmpty(ruleData.eventConfiguration.actionConfigurations[2]) && ruleData.eventConfiguration.actionConfigurations[2].status) &&
            (ruleData.eventConfiguration.actionConfigurations[2].properties.usersList.length === 0) &&
            (ruleData.eventConfiguration.actionConfigurations[2].properties.contactsList.length === 0 ) &&
            (!ruleData.eventConfiguration.actionConfigurations[2].notifyResponsible)
        )
    )){
        eventConfigWarnings.push(t('rules.alerts.config.noUser'));
    }
    return eventConfigWarnings;
}

async function callEditRule(ruleData, deployRule, onBack, needToDropRuleGroup) {
    ruleData.deployRule = deployRule;
    Alerts.loading();

    if(ruleData.terminalId && deployRule){
        const terminalIsConnected = await AsyncTerminalService.checkTerminalStatus(ruleData.terminalId);

        if(!terminalIsConnected) {
            await Alerts.confirmWarningFlow({
                title: t('rules.form.buttons.deploy'),
                html: t('rules.form.alerts.terminalNotConnected'),
                confirmButtonText: t("rules.list.columns.checkBox.ruleGroup.singleAdd.confirmWarningButton"),
            }).show(() => {
                confirmEditRule(ruleData, onBack, needToDropRuleGroup)
            });
        } else {
            confirmEditRule(ruleData, onBack, needToDropRuleGroup)
        }
    } else {
        editRule(ruleData, onBack)
    }

}

function confirmEditRule(ruleData, onBack, needToDropRuleGroup){
    if (needToDropRuleGroup) {
        Alerts.confirmWarningFlow({
            title: t("rules.list.columns.checkBox.ruleGroup.singleAdd.confirmWarningTitle"),
            html: t("rules.list.columns.checkBox.ruleGroup.singleAdd.confirmWarningText"),
            confirmButtonText: t("rules.list.columns.checkBox.ruleGroup.singleAdd.confirmWarningButton"),
        }).show(() => {
            ruleData.removeFromGroup = needToDropRuleGroup;
            editRule(ruleData, onBack)
        });
    } else {
        editRule(ruleData, onBack)
    }
}

function editRule(ruleData, onBack){
    RuleService.editRule(ruleData.id, ruleData, (ruleSaved) => {
        Alerts.timedSuccess({
            html: t('rules.form.alerts.editRule.success.text', {ruleName: ruleData.displayName}) +
                (getContactsCount(ruleData) !== getContactsCount(ruleSaved) ?
                    '<br><br><b class="c-orange">' + t('generic.contactWarning') + '</b>' : '')
        }).onClose(onBack).show();
    }, () =>
        Alerts.applicationError({
            html: t('rules.form.alerts.newRule.error.text', {ruleName: ruleData.displayName})
        }).show()
    );
}


//create
export async function handleCreateRuleSubmit(ruleData, deployRule, onBack) {
    let alertText, confirmText;
    ruleData.deployRule = deployRule;
    if (deployRule) {
        alertText = t('rules.form.alerts.newRule.createAndDeploy.text', {ruleName: ruleData.displayName});
        confirmText = t('rules.form.alerts.newRule.createAndDeploy.confirmButtonText');

        if(ruleData.terminalId) {
            const terminalIsConnected = await AsyncTerminalService.checkTerminalStatus(ruleData.terminalId);

            if (!terminalIsConnected) {
                alertText += "<br><br>" + t('rules.form.alerts.terminalNotConnected')
            }
        }

        await Alerts.confirmWarningFlow({
            title: t('rules.form.alerts.newRule.createAndDeploy.title'),
            html: alertText,
            confirmButtonText: confirmText
        }).show(() => {
            handleSaveRule(ruleData, onBack);
        });
    } else {
        handleSaveRule(ruleData, onBack);
    }
}

function handleSaveRule(ruleData, onBack) {
    let eventConfigWarnings = validateEventConfig(ruleData);
    if (eventConfigWarnings.length !== 0) {
        let warningMsg = "";
        _.forEach(eventConfigWarnings, msg => {
            warningMsg = warningMsg + "<br>" + msg;
        });

        Alerts.confirmWarningFlow({
            title: t('rules.alerts.config.title'),
            html: t('rules.alerts.config.text', {param: warningMsg}),
            confirmButtonText: t("generic.yes"),
            cancelButtonText: t("generic.no"),
        }).show(() => {
            callCreateRule(ruleData, onBack)
        })
    } else {
        callCreateRule(ruleData, onBack);
    }
}

function callCreateRule(ruleData, onBack) {
    Alerts.loading();
    RuleService.createRule(ruleData, (ruleSaved) => {
        Alerts.timedSuccess({
            title: t('rules.form.alerts.newRule.saved.title'),
            html: t('rules.form.alerts.newRule.saved.text', {ruleName: ruleData.displayName}) +
                (getContactsCount(ruleData) !== getContactsCount(ruleSaved) ?
                    '<br><br><b class="c-orange">' + t('generic.contactWarning') + '</b>' : '')
        }).onClose(onBack).show();
    }, () => {
        Alerts.applicationError({
            html: t('rules.form.alerts.newRule.error.text', {ruleName: ruleData.displayName})
        }).show();
    });
}

async function onUnDeploy({id, displayName, ruleGroups, terminal}, onBack) {

    let undeployText = t('rules.alerts.undeploy.text', {ruleName: displayName})

    undeployText += ruleGroups.length > 0 ?
        t("rules.list.columns.checkBox.additionalText.singular") : "";

    if(terminal && terminal.id) {
        const terminalIsConnected = await AsyncTerminalService.checkTerminalStatus(terminal.id);

        if (!terminalIsConnected) {
            undeployText += "<br><br>" + t('rules.form.alerts.terminalNotConnected')
        }
    }

    Alerts.confirmWarningFlow({
        title: t('rules.alerts.undeploy.title'),
        html: undeployText,
        confirmButtonText: t('rules.alerts.undeploy.confirmButtonText')
    }).show(() => {
        RuleService.quickUndeployRules([id], () =>{
            Alerts.timedSuccess({
                html: t('rules.alerts.undeploy.success.text', {ruleName: displayName})
            }).onClose(onBack).show();
        }, () => {
            Alerts.applicationError({
                html: t('rules.alerts.error.undeploy.text', {ruleName: displayName})
            }).show();
        })
    });
}

async function onDeploy({id, ruleGroups, terminal}, onBack) {

    let deployText = t("rules.alerts.deploy.confirmWarningText");
    deployText += ruleGroups.length > 0 ?
        t("rules.list.columns.checkBox.additionalText.singular") : "";

    const successFunc = () => {
        Alerts.timedSuccess({
            html: t("rules.alerts.deploy.successText")
        }).onClose(onBack).show();
    };

    const errorFunc = () => {
        Alerts.applicationError({
            html: t("rules.alerts.deploy.errorText")
        }).show();
    };

    if(terminal && terminal.id) {
        const terminalIsConnected = await AsyncTerminalService.checkTerminalStatus(terminal.id);

        if (!terminalIsConnected) {
            deployText += "<br><br>" + t('rules.form.alerts.terminalNotConnected')
        }
    }

    await Alerts.confirmWarningFlow({
        title: t("rules.alerts.deploy.confirmWarningTitle"),
        html: deployText,
        confirmButtonText: t("rules.alerts.deploy.confirmWarningButton")
    }).show(() => {
        RuleService.quickDeployRules([id], successFunc, errorFunc)
    });
}

function getContactsCount(ruleData){
    return ruleData?.eventConfiguration?.actionConfigurations ? reduceActionContactListCount(ruleData.eventConfiguration.actionConfigurations) : 0
}


export default RuleDetailsFormContainer;