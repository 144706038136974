import {
    library
} from '@fortawesome/fontawesome-svg-core';

// import your icons
import {
} from '@fortawesome/free-regular-svg-icons';

import {
    faEuroSign,
    faLayerGroup,
    faSyringe,
    faCheckCircle,
    faBan,
    faAngleRight,
    faAngleLeft,
    faTrash,
    faPencilAlt,
    faAlignJustify,
    faTimes,
    faStar,
    faBell,
    faBellSlash,
    faCog,
    faEye,
    faPlus,
    faExclamationCircle,
    faPlusCircle,
    faClone,
    faClock,
    faCaretLeft,
    faCaretRight,
    faCircle, faExchangeAlt, faDownload, faTasks, faTable,
    faArrowCircleUp,
    faArrowCircleDown
} from '@fortawesome/free-solid-svg-icons';

library.add(
    faEuroSign,
    faLayerGroup,
    faSyringe,
    faCheckCircle,
    faBan,
    faAngleRight,
    faAngleLeft,
    faTrash,
    faPencilAlt,
    faAlignJustify,
    faTimes,
    faStar,
    faBell,
    faBellSlash,
    faCog,
    faEye,
    faPlus,
    faExclamationCircle,
    faPlusCircle,
    faClone,
    faClock,
    faCaretLeft,
    faCaretRight,
    faCircle,
    faExchangeAlt,
    faBan,
    faDownload,
    faTasks,
    faTable,
    faArrowCircleUp,
    faArrowCircleDown
);

export default library;