import React from 'react'
import {t} from "../../utils/Translation";
import RoundedButton from "../buttons/RoundedButton";
import SwitchField from "../fields/switch/SwitchField";
import LabeledField from "../fields/labeled/LabeledField";
import classNames from "classnames";

const GlobalRuleHeaderForm = ({isCyclical, startDate, endDate, setStartCycle, setEndCycle, setIsCyclical, addNewLine, isView, isMobile}) => {
    return (
        <div className={"flex flex-row-center flex-wrap"}>
            {!isView && (
                <RoundedButton
                    label={t("rules.globalRule.addRuleCondition")}
                    onClick={() => addNewLine()}
                    classes={classNames("rounded-btn-success pd-5 mb-10", {"col-xs-12" : isMobile})}
                />
            )}
            <div className={"flex flex-1 flex-row-center flex-wrap"}>
                <SwitchField
                    forceNewPropValue={true}
                    noMarginBottom={true}
                    fieldLabel={t('rules.globalRule.fields.cyclical.label')}
                    width={ isCyclical ?  (isView ? '4' : '2') : '12'}
                    isView={isView}
                    className={classNames("col-xs-12", {"flex justify-center": isMobile && !isView})}
                    checkedLabel={t('rules.globalRule.fields.cyclical.active')}
                    uncheckedLabel={t('rules.globalRule.fields.cyclical.disabled')}
                    isChecked={isCyclical}
                    onFieldChange={value => setIsCyclical(value)}
                />
                {isCyclical && (
                    <>
                        <LabeledField
                            fieldLabel={t('rules.globalRule.fields.cycleStartDate.label')}
                            forceNewPropValue={true}
                            fieldType="dateTime"
                            isView={isView}
                            width={"3"}
                            className={"col-xs-12"}
                            value={startDate}
                            hasDate={true}
                            hasTime={true}
                            isRequired={true}
                            keepDatetimeValuesInUTC={true}
                            onFieldChange={(value) => setStartCycle(value)}
                        />
                        <LabeledField
                            fieldLabel={t('rules.globalRule.fields.cycleEndDate.label')}
                            forceNewPropValue={true}
                            fieldType="dateTime"
                            isView={isView}
                            width={"3"}
                            className={"col-xs-12"}
                            value={endDate}
                            hasDate={true}
                            hasTime={true}
                            isRequired={true}
                            keepDatetimeValuesInUTC={true}
                            onFieldChange={(value) => setEndCycle(value)}
                        />
                    </>
                )}
            </div>
        </div>
    )
}

export default GlobalRuleHeaderForm;