import React, {useEffect, useRef, useState} from 'react';
import RuleService from "../../services/RuleService";
import GlobalRuleHeaderForm from "./GlobalRuleHeaderForm";
import GlobalRuleLines from "./GlobalRuleLines";
import classNames from "classnames";
import LabeledField from "../fields/labeled/LabeledField";
import {t} from "../../utils/Translation";
import moment from "moment";
import {config} from "../../config";
import uuid from "../../utils/uuid";
import ErrorDisplay from "../error/ErrorDisplay";
import _ from "lodash";
import RuleCacheService from "../../services/RuleCacheService";
import __ from "../../utils/EnhancedLodash";

const GlobalRuleForm = (props) => {

    const [interfaceList, setInterfaceList] = useState(props.interfaceList || []);
    const [terminalList, setTerminalList] = useState(props.terminalList || []);
    const [isCyclical, setIsCyclical] = useState(false)
    const [permanenceTime, setPermanenceTime] = useState({value: "00:10", error: null})
    const [repetitionTime, setRepetitionTime] = useState({value: "00:00", error: null})
    const [notificationLimit, setNotificationLimit] = useState({value: "3", error: null})
    const [error, setError] = useState(new Set())
    const [lineData, setLineData] = useState([])
    const [cycleStartDate, setCycleStartDate] = useState(null)
    const [cycleEndDate, setCycleEndDate] = useState(null)

    const lineDataRef = useRef(lineData);
    const cycleStartDateRef = useRef(cycleStartDate);
    const cycleEndDateRef = useRef(cycleEndDate);
    const permanenceTimeRef = useRef(permanenceTime);
    const repetitionTimeRef = useRef(repetitionTime);
    const notificationLimitRef = useRef(notificationLimit);
    const isCyclicalRef = useRef(isCyclical);

    // Create refs to later access the current values from outside scope
    useEffect(() => {
        lineDataRef.current = lineData;
        cycleStartDateRef.current = cycleStartDate;
        cycleEndDateRef.current = cycleEndDate;
        permanenceTimeRef.current = permanenceTime;
        repetitionTimeRef.current = repetitionTime;
        notificationLimitRef.current = notificationLimit;
        isCyclicalRef.current = isCyclical;
    });

    useEffect(() => {
        props.onGlobalRuleChange({
            setFieldValue: (data) => loadRuleData(data),
            getFieldValue: () => getFieldValue(
                lineDataRef.current,
                permanenceTimeRef.current,
                repetitionTimeRef.current,
                notificationLimitRef.current,
                cycleStartDateRef.current,
                cycleEndDateRef.current,
                isCyclicalRef.current
            ),
            validateField: () => validate(
                lineDataRef.current,
                cycleStartDateRef.current,
                cycleEndDateRef.current,
                permanenceTimeRef.current,
                repetitionTimeRef.current,
                notificationLimitRef.current,
                isCyclicalRef.current
            ),
            // This is used for the old and obsolete rules section
            getTabNumber: () => props.tabNumber,
            setTerminalList: (terminalList) => mapTerminalList(terminalList)
        });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        if(!props.globalRule && props.selectedEntity && props.selectedTerminal) {
            RuleService.getInterfacesWithCategory(props.selectedEntity.id, props.selectedTerminal.id, null, null, interfaces => {
                if(props.selectedSector && !props.showAllInterfaces) {
                    interfaces = interfaces.filter(iface => iface.entity.id === props.selectedSector.id);
                }
                mapInterfaceList(interfaces);
            }, () => {
                throw new Error("It was not possible to fetch the interface List");
            });
        }
        // eslint-disable-next-line
    }, [])

    const onChangeStartDate = (value) => {
        const changedLines = lineData.map(line => {
            if(line.cycle && line.cycle.length > 0){
                line.cycle = line.cycle.map(cycleDay => {
                    if(!props.isView){
                        cycleDay.date = value.clone().add(cycleDay.day, 'days')
                    }
                    return cycleDay;
                })
            }
            return line
        })

        if(changedLines && changedLines.length > 0){
            setLineData(changedLines)
        }
    }

    const validate = (lines, cycleStartDate, cycleEndDate, permanenceTime, repetitionTime, notificationLimit, isCyclical) => {

        setError(new Set())

        let isValid = (props.isRequired && lines.length > 0 &&
            permanenceTime && repetitionTime && notificationLimit);

        if(isValid) {
            lines.forEach(line => {
                isValid = validateLine(line, isValid, isCyclical, cycleStartDate, cycleEndDate)
            })
        } else {
            if(!permanenceTime) {
                setPermanenceTime({error: t('generic.dropgroup.error.emptyRequiredField')})
            }

            if (!repetitionTime) {
                setRepetitionTime({error: t('generic.dropgroup.error.emptyRequiredField')})
            }

            if (!notificationLimit) {
                setNotificationLimit({error: t('generic.dropgroup.error.emptyRequiredField')})
            }

            if (!lines || lines.length === 0){
                setError((prevState) => {
                    return new Set([...prevState, t('generic.dropgroup.error.noLines')]);
                });
            }
        }

        return isValid;
    }

    const validateLine = (line, isValid, isCyclical, cycleStartDate, cycleEndDate) => {

        const errors = new Set()

        if(!line.terminalId){
            isValid = false
            errors.add(t('rules.globalRule.errors.noTerminal'))
        }

        if(!line.interfaceId){
            isValid = false
            errors.add(t('rules.globalRule.errors.noInterface'))
        }

        if(!isCyclical && !line.logicOperator){
            isValid = false
            errors.add(t('rules.globalRule.errors.noLogicOperator'))
        }

        if(!isCyclical && _.isNil(line.action)){
            isValid = false
            errors.add(t('rules.globalRule.errors.noAction'))
        }

        if(isCyclical){
            let isCycleValid = true

            if(!line.cycle || line.cycle.length === 0){
                isValid = false
                errors.add(t('rules.globalRule.errors.noCycle'))
            } else {
                let currentCycleDay = line?.cycle[0].day || 0
                line.cycle.forEach(cycleItem => {
                    if(cycleItem.day === null || cycleItem.day === undefined){
                        isCycleValid = false
                        errors.add(t('rules.globalRule.errors.noCycleDay'))
                    }

                    if(cycleItem.day && +cycleItem.day < currentCycleDay){
                        isCycleValid = false
                        errors.add(t('rules.globalRule.errors.noGreaterCycleDay'))
                    } else if(cycleItem.day) {
                        currentCycleDay = +cycleItem.day
                    }

                    if(cycleItem.day && cycleItem.day > Math.abs(cycleStartDate.clone().diff(cycleEndDate, 'days'))){
                        isCycleValid = false
                        errors.add(t('rules.globalRule.errors.cycleDayOutside'))
                    }

                    if(!cycleItem.logicOperator){
                        isCycleValid = false
                        errors.add(t('rules.globalRule.errors.noLogicOperator'))
                    }

                    if(_.isNil(cycleItem.action) || cycleItem.action === ""){
                        isCycleValid = false
                        errors.add(t('rules.globalRule.errors.noAction'))
                    }
                })

                if(!isCycleValid){
                    isValid = false
                }
            }

            if(!cycleStartDate){
                isValid = false
                errors.add(t('rules.globalRule.errors.noCycleStartDate'))
            }

            if(!cycleEndDate){
                isValid = false
                errors.add(t('rules.globalRule.errors.noCycleEndDate'))
            }

            if(cycleStartDate && cycleEndDate && cycleStartDate.isAfter(cycleEndDate)){
                isValid = false
                errors.add(t('rules.globalRule.errors.startCycleDateAfterEndCycleDate'))
            }
        }



        if (!isValid){
            setError((prevState) => {
                return new Set([...prevState, ...errors]);
            });
        }

        return isValid
    }

    const getFieldValue = (lines, permanenceTime, repetitionTime, notificationLimit, cycleStartDate, cycleEndDate, isCyclical) => {
        let result = [];
        _.forOwn(lines, line => {
            if(!_.isNil(line)){
                let lineData = getLineValue(line, permanenceTime, repetitionTime, notificationLimit,
                    cycleStartDate, cycleEndDate, isCyclical);
                if(!_.isNil(lineData)) {
                    result.push(lineData)
                }
            }
        });

        return result;
    }

    const getLineValue = (line, permanenceTime, repetitionTime, notificationLimit,
                          cycleStartDate, cycleEndDate, isCyclical) => {
        if(line.interfaceId) {
            return {
                interfaceId: line.interfaceId,
                terminalId: line.terminalId,
                logicOperator: line.logicOperator,
                inputValue: line.action?.toString(),
                permanenceTime: permanenceTime?.value || '00:10',
                repetitionTime: repetitionTime?.value || '00:00',
                notificationLimit: notificationLimit?.value || '3',
                cycle: line.cycle?.map(item => ({...item, date: moment.isMoment(item.date) ?
                        item.date.format(config.datepicker.formats.isoDateTime) : item.date })),
                isCyclical: isCyclical || null,
                cycleStartDate: cycleStartDate,
                cycleEndDate: cycleEndDate
            };
        } else {
            return null;
        }
    }

    const loadRuleData = (data) => {
        let lines =[];
        data.forEach(lineData => {
            let cachedInterfaces = RuleCacheService.getInterfacesOfTerminal(lineData.terminalId);
            let interfaceObj;
            if(!_.isEmpty(cachedInterfaces)) {
                interfaceObj = _.find(cachedInterfaces, iface => {
                    return props.globalRule? lineData.interfaceId === iface.id : lineData.interfaceId === iface.interfaceId;
                });
            } else if (!_.isEmpty(interfaceList)) {
                interfaceObj = _.find(interfaceList, iface => {
                    return props.globalRule? lineData.interfaceId === iface.id : lineData.interfaceId === iface.interfaceId;
                });
            }

            let id = uuid();
            lines.push({
                    key: id,
                    id: id,
                    interfaceList: !_.isEmpty(interfaceList) ? interfaceList : cachedInterfaces,
                    terminalList: terminalList,
                    terminalId: lineData.terminalId,
                    interfaceId: lineData.interfaceId,
                    logicOperator: lineData.logicOperator,
                    action: lineData.inputValue,
                    interfaceDataType: interfaceObj ? interfaceObj.terminalInterfaceDataType: null,
                    logicOperations: interfaceObj ?
                        interfaceObj.terminalInterfaceDataType === RuleService.dataType.BOOLEAN ?
                            [RuleService.getObjectFor(RuleService.logicOperator.EQUAL, t('generic.logicOperations.equal'))] :
                            RuleCacheService.getLogicalOperationsForDataType(interfaceObj.terminalInterfaceDataType) :
                        null,
                    lineData: lineData || {},
                    cycle: lineData.isCyclical ? lineData.cycle : null
                })
        })

        if(!__.isNilOrEmpty(lines)){
            setPermanenceTime({ value: lines[0].lineData?.permanenceTime || '00:00' })
            setRepetitionTime({ value: lines[0].lineData?.repetitionTime || '00:00' })
            setNotificationLimit(lines[0].lineData?.notificationLimit ? {value: lines[0].lineData.notificationLimit} : {value: "3"})
            setIsCyclical(lines[0].lineData?.isCyclical || false)
            setCycleStartDate(lines[0].lineData?.isCyclical ? moment(lines[0].lineData?.cycleStartDate) : null)
            setCycleEndDate(lines[0].lineData?.isCyclical ? moment(lines[0].lineData?.cycleEndDate) : null)
            setLineData(lines)
        }

    }

    const mapInterfaceList = (interfaces, lineToUpdate) => {
        const lineCopy = lineData.map(line => {
            if(lineToUpdate.id === line.id) {
                line.interfaceList = interfaces;
            }
            return line;
        })

        setLineData(lineCopy);
        setInterfaceList(interfaces);
    }

    const mapTerminalList = (terminals) => {
        const lineCopy = lineData.map(line => {
            line.terminalList = terminals;
            return line;
        })

        setLineData(lineCopy);
        setTerminalList(terminals);
    }

    const addNewLine = () => {
        let id = uuid();
        setLineData([...lineData, {
            id: id,
            interfaceList: interfaceList,
            terminalList: terminalList,
        }])
    }

    const deleteLine = (lineId) => {
        const linesAfterDelete = lineData.filter(line => line.id !== lineId)
        setLineData(linesAfterDelete);
    }

    const renderPermanenceAndRepetitionTimeSection = () => {
        return (
            <div className="flex flex-wrap mt-25">
                <div className={classNames('col-lg-3 col-xs-12 pd-r-0', {"pd-b-15": isMobile})} style={{minWidth: '230px'}}>
                    <LabeledField
                        forceNewPropValue={true}
                        fieldPlaceHolder={'HH:mm'}
                        fieldLabel={t('alarmRules.form.permanenceTime.placeholder')}
                        fieldType='dateTime'
                        width= "12"
                        className="p-left-0"
                        isRequired={true}
                        hasRequiredAsterisk={true}
                        hasDate={false}
                        hasTime={true}
                        hasSeconds={false}
                        bottomMargin={false}
                        iconClassName={"fa fa-calendar"}
                        keepDatetimeValuesInUTC={true}
                        isView={props.isView}
                        tooltip={t('alarmRules.form.permanenceTime.tooltip')}
                        value={permanenceTime.value}
                        error={permanenceTime.error}
                        onFieldChange={(value) => {
                            setPermanenceTime({
                                value: value && moment(value).isValid() ? value.format(config.datepicker.formats.timeWithoutSeconds) : value,
                                error: null
                            })
                        }}
                    />
                </div>
                <div className={classNames('col-lg-3 col-xs-12 pd-r-0', {"pd-b-15": isMobile})}>
                    <LabeledField
                        forceNewPropValue={true}
                        fieldPlaceHolder={'HH:mm'}
                        fieldLabel={t('alarmRules.form.repeatTime.placeholder')}
                        fieldType='dateTime'
                        width= "12"
                        className="p-left-0"
                        isRequired={true}
                        hasRequiredAsterisk={true}
                        hasDate={false}
                        hasTime={true}
                        hasSeconds={false}
                        bottomMargin={false}
                        iconClassName={"fa fa-calendar"}
                        keepDatetimeValuesInUTC={true}
                        isView={props.isView}
                        tooltip={t('alarmRules.form.repeatTime.tooltip')}
                        value={repetitionTime.value}
                        error={repetitionTime.error}
                        onFieldChange={(value) => {
                            setRepetitionTime({
                                value: value && moment(value).isValid() ? value.format(config.datepicker.formats.timeWithoutSeconds) : value,
                                error: null
                            })
                        }}
                    />
                </div>
                <div className={classNames('col-lg-2 col-xs-12 pd-r-0', {"pd-b-15": isMobile})} style={{minWidth: '230px'}}>
                    <LabeledField
                        forceNewPropValue={true}
                        fieldPlaceHolder={t('alarmRules.form.notificationLimit.placeholder')}
                        fieldLabel={t('alarmRules.form.notificationLimit.placeholder')}
                        fieldType='number'
                        width= "12"
                        className="p-left-0"
                        isRequired={true}
                        hasRequiredAsterisk={true}
                        bottomMargin={false}
                        isView={props.isView}
                        min={"1"}
                        tooltip={t('alarmRules.form.notificationLimit.tooltip')}
                        value={notificationLimit.value}
                        error={notificationLimit.error}
                        onFieldChange={(value) => {
                            setNotificationLimit({
                                value: value,
                                error: null
                            })
                        }}
                    />
                </div>
            </div>
        )
    }

    const isMobile = window.matchMedia("(min-width: 200px) and (max-width: 1200px)").matches;

    return (
        <div className="row form-group">
            <GlobalRuleHeaderForm
                isCyclical={isCyclical}
                isView={props.isView}
                isMobile={isMobile}
                addNewLine={() => addNewLine()}
                startDate={cycleStartDate}
                endDate={cycleEndDate}
                setStartCycle={(value) => {
                    onChangeStartDate(value)
                    setCycleStartDate(value)
                }}
                setEndCycle={(value) => setCycleEndDate(value)}
                setIsCyclical={(value) => setIsCyclical(value)}
            />
            {error && error.size > 0 && <ErrorDisplay errors={error}/>}
            <GlobalRuleLines
                lines={lineData}
                isCyclical={isCyclical}
                isView={props.isView}
                actionType={props.actionType}
                globalRule={props.globalRule}
                cycleStart={cycleStartDate}
                cycleEnd={cycleEndDate}
                showAllInterfaces={props.showAllInterfaces}
                selectedSector={props.selectedSector}
                mapInterfaceList={(interfaceList, lineToUpdate) => mapInterfaceList(interfaceList, lineToUpdate)}
                deleteLine={(lineId) => deleteLine(lineId)}
                onUpdateLines={(updatedLines) => {
                    setLineData(updatedLines)
                }}
            />
            {lineData && lineData.length > 0 && renderPermanenceAndRepetitionTimeSection()}
        </div>
    )
}

export default GlobalRuleForm;