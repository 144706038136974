import * as actionTypes from "./actionTypes";

const initialState =  {
    widgets: { }
};

const widgetReducer = (state = initialState, payload) => {
    switch (payload.type) {
        case actionTypes.LOAD_WIDGETS_SUCCESS:
            return {
                widgets: payload.dashboard.widgets
            };
        case actionTypes.CREATE_WIDGET:
            return {
                widgets: {
                    ...state.widgets,
                    [payload.widget.id]: {
                        ...payload.widget
                    }
                }
            };
        case actionTypes.EDIT_WIDGET:
            if(state.widgets[payload.widget.id]) {
                return {
                    widgets: {
                        ...state.widgets,
                        [payload.widget.id]: {
                            ...state.widgets[payload.widget.id],
                            ...payload.widget
                        }
                    }
                };
            } else {
                return state;
            }
        case actionTypes.DELETE_WIDGET:
            return {
                widgets: {
                    ...Object.keys(state.widgets).reduce((acc, key) => {
                        if (key !== payload.widget.id) {
                            return {
                                ...acc,
                                [key]: state.widgets[key]
                            }
                        }
                        return acc;
                    }, {})
                }
            };
        case actionTypes.UPDATE_LAYOUT:
            return {
                ...state,
                widgets: {
                    ...state.widgets,
                    ...payload.widgetsToUpdate.reduce(
                        (acc, widgetToUpdate) => ({
                            ...acc,
                            [widgetToUpdate.i]: {
                                ...state.widgets[widgetToUpdate.i],
                                configs: {
                                    ...state.widgets[widgetToUpdate.i].configs,
                                    rowIndex: widgetToUpdate.y,
                                    columnIndex: widgetToUpdate.x,
                                    widgetHeight: widgetToUpdate.h,
                                    widgetWidth: widgetToUpdate.w
                                }
                            }
                        }),
                        {}
                    )
                }
            };
        case actionTypes.CLEAN_LAYOUT:
            return initialState;
        default:
            return state;
    }
};

export default widgetReducer;