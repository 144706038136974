import React from 'react';
import Dropzone from 'react-dropzone'
import classnames from 'classnames';
import PropTypes from 'prop-types';
import _ from 'lodash';
import I18n from 'i18next';
import { config } from '../../config';
import UUID from './../../utils/uuid';
import Alerts from "./../../utils/AlertUtils";
import fileuploadicon from './../../img/file-upload-solid.svg';
import icon from './../../img/helpFieldIcon.svg';

class Drop extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            infoVisible: false,
            maxFileSize: !_.isNil(props.maxFileSize) ? props.maxFileSize : 15,
            maxFilesNumber: !_.isNil(props.maxFilesNumber) ? props.maxFilesNumber : 15,
            errorMaxSize: !_.isNil(props.errorMaxSize) ? props.errorMaxSize : I18n.t('errors.dropzoneField.maxFiles'),
            errorMaxFiles: !_.isNil(props.errorMaxFiles) ? props.errorMaxFiles : I18n.t('errors.dropzoneField.maxSize'),
            isView: !_.isNil(props.isView) ? props.isView : false,
            deleteWarning: !_.isNil(props.deleteWarning) ? props.deleteWarning : false,
            minimalState: !_.isNil(props.minimalState) ? props.minimalState : false,
        };
        this.usageComponent = this.props.usageComponent ? this.props.usageComponent : '';
        this.onlyImages = props.onlyImages ? props.onlyImages : false
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        if(nextProps.isView !== prevState.isView) {
            return {
                isView: nextProps.isView
            }
        }
        return null;
    }

    setNewFiles(newFiles, isView) {
        let self = this;
        let files = [];
        _.forEach(newFiles, function (f) {
            if (self.state.files.length > self.props.maxFilesNumber) {
                self.setError(this.state.errorMaxFiles);
                return;
            }
            files.push(f);
        });
        self.setState({
            isView, files
        });
    }

    setIsView(isView) {
        this.setState({isView: isView})
    }

    addFiles(newFiles) {
        let self = this;
        if (self.onlyImages) {
            newFiles = validateFile(newFiles);
        }

        if (self.usageComponent === 'widget') {
            let file = [];
            if (!_.isEmpty(newFiles)) {
                if (newFiles.length > self.props.maxFilesNumber) {
                    self.setError(self.state.errorMaxFiles);
                    return;
                }
                file.push(newFiles);
                self.setState({files: newFiles});
            }
            return;
        }
        _.forEach(newFiles, function (f) {
            if (self.state.files.length === self.props.maxFilesNumber) {
                self.setError(self.state.errorMaxFiles);
                return;
            }

            if (self.state.files.indexOf(f) === -1) {
                self.state.files.push(f);
            }
            self.setState({files: self.state.files});
        });
    }

    onDropAccepted(newFiles) {
        convertFiles(newFiles);
        this.addFiles(newFiles);
        if(this.props.onFilesUpdate) {
            this.props.onFilesUpdate();
        }
    }

    onDropRejected() {
        this.setError(this.state.errorMaxSize);
    }

    getFieldValue() {
        return this.state.files;
    }

    validateField() {
        return true;
    }

    isVisible() {
        return true;
    }

    setError(error) {
        this.setState({ error: error });

        if (!_.isNil(this.props.onErrorEvent)) {
            this.props.onErrorEvent(error);
        }
    }

    getFileCount(){
        return this.state.files.length;
    }

    clickOnFile(file, e) {

        e.stopPropagation();

        if (!this.state.isView) {
            let index = this.state.files.indexOf(file);
            if(this.state.deleteWarning) {
                Alerts.confirmWarningFlow({
                    title: I18n.t('components.dropzone.deleteTitle'),
                    confirmButtonText: I18n.t("generic.delete")
                }).show(() => {
                    this.state.files.splice(index, 1);
                    this.setState(
                        {files: this.state.files},
                        () => {
                            if(this.props.onFilesUpdate) {
                                this.props.onFilesUpdate();
                            }
                        });
                });
            } else {
                this.state.files.splice(index, 1);
                this.setState({files: this.state.files}, () => {
                    if(this.props.onFilesUpdate) {
                        this.props.onFilesUpdate();
                    }
                });
            }
        } else {
            if (!_.isNil(this.props.onExportEvent)) {
                this.props.onExportEvent(file.name);
            }
        }
    }

    getFilesElement() {
        let content = [];
        let iconValue = this.state.isView ? config.generic.dropzone.downloadIcon : config.generic.dropzone.deleteIcon;
        this.state.files.forEach(
            (f) => {
                content.push(
                    <div key={f.name} className='dz-preview dz-processing dz-image-preview dz-error dz-complete cursor-pointer ' style={this.state.isView ? { pointerEvents: 'all' } : null} onClick={(event) => { this.clickOnFile(f, event) }}>
                        <div className='dz-image'>{getFilePreview(f)}</div>
                        <div className='dz-details'>
                            <svg className={classnames({ 'dz-svg-view': this.state.isView }, 'dz-svg')} viewBox='0 0 54 54' version='1.1' >
                                <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
                                    <g stroke='#747474' strokeOpacity='0.198794158' fill='#FFFFFF' fillOpacity='0.816519475'>
                                        <path d={iconValue}/>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div>
                            <span className='dz-error-message' data-dz-errormessage=''>{f.name}</span>
                        </div>
                    </div>
                );
            }
        );
        return content;
    }

    render() {
        const { isView, minimalState } = this.state;

        let minimalComponent = null;
        if(minimalState) {
            minimalComponent = (
                <div className = "relative-parent">
                    <div>
                        <label className='pd-r-15'>{this.props.fieldLabel}</label>
                    </div>
                    <button
                        type="button"
                        className="btn btn-bold btn-buffer btn-success btn-size"
                        onClick={() => this.dropzone.open()}>
                        {I18n.t('batches.details.feedMovements.form.button.label2')}
                    </button>
                    {getFilesInfoBox(this)}
                </div>
            ) ;

        }

        return (
            <div className={this.props.outerDivClass}>
                {getInfoBox(this)}
                <section className={'dz-section col-md-' + this.props.width}>
                    {minimalComponent}
                    <Dropzone
                        className={classnames('form-group filepicker dropzone dz-started dropzoneContentArea', this.props.className, {'display-none': minimalState}, {'cursor-pointer': !isView})}
                        style={isView ? { pointerEvents: 'none' } : null}
                        onDropAccepted={this.onDropAccepted.bind(this)}
                        onDropRejected={this.onDropRejected.bind(this)}
                        maxSize={this.state.maxFileSize * 1000000}
                        ref={(c) => this.dropzone = c}>
                        <div className={'dz-default dz-message ' + classnames({ 'dz-message-hidden': this.state.files.length > 0 })} key='dropzone-placeholder'><span><strong>{this.props.fieldPlaceHolder}</strong></span></div>
                        {this.getFilesElement()}
                    </Dropzone>
                </section>
            </div>
        );
    }
}

function validateFile(newFiles) {
    let files=[];
    newFiles.forEach(file => {
        if (file.type.indexOf('image') !== -1) {
            files.push(file);
        }
    });
    return files;
}

function getFilePreview(file) {

    if (file.type.indexOf('image') !== -1)
        return <img data-dz-thumbnail='' alt={file.name} src={file.preview} height='120' width='120' />;

    let iconClassName = 'fa-file-o';

    if (file.type.indexOf('pdf') !== -1)
        iconClassName = 'fa-file-pdf-o';

    if (file.type.indexOf('word') !== -1)
        iconClassName = 'fa-file-word-o';

    if (file.type.indexOf('powerpoint') !== -1)
        iconClassName = 'fa-file-powerpoint-o';

    if (file.type.indexOf('text') !== -1)
        iconClassName = 'fa-file-text-o';

    if (file.type.indexOf('excel') !== -1 || file.type.indexOf('sheet') !== -1)
        iconClassName = 'fa-file-excel-o';

    if (file.type.indexOf('video') !== -1)
        iconClassName = 'fa-file-video-o';

    if (file.type.indexOf('audio') !== -1)
        iconClassName = 'fa-file-audio-o';

    if (file.name.indexOf('.zip') !== -1 || file.name.indexOf('.7z') !== -1 || file.name.indexOf('.rar') !== -1)
        iconClassName = 'fa-file-archive-o';

    return <i className={'fa ' + iconClassName + ' fa-4 dz-iconPreview'} aria-hidden='true'/>;
}

function convertFiles(files) {
    const toDataURL = url => fetch(url)
        .then(response => response.blob())
        .then(blob => new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onloadend = () => resolve(reader.result);
            reader.onerror = reject;
            reader.readAsDataURL(blob);
        }));

    files.forEach(f => {
        if (_.isNil(f.content) || _.isEmpty(f.content)) {
            f.fileName = f.name;
            f.fileSize = f.size;
            f.fileType = f.type;
            toDataURL(f.preview).then(dataUrl => {
                f.content = dataUrl;
            })
        }
    });
}

function getFilesInfoBox(component) {
    if(!_.isNil(component.state.files) && !_.isEmpty(component.state.files)) {
        return (
            <div className = "relative-child">
                <img
                    alt=""
                    className="icon dropzone-helper-icon ml-4"
                    src={fileuploadicon}
                    width={15}
                    title={I18n.t("generic.importFile.clickme")}
                    onClick={() => toggleSowInfo(component)}
                />
                {renderFilesNames(component)}
            </div>
        );
    }
    return null;
}

function renderFilesNames(component) {
    let content = [];
    _.forEach(component.state.files, f => {
        let fileNameLine = (
            <div className="dropzone-helper-tooltip-line" key={f.fileName}>
                <span className="dropzone-helper-tooltip-line-fileName">{getTruncatedFileName(f.fileName)}</span>
                <span className="dropzone-helper-tooltip-line-remove-button" title = "Remove file" style={{marginLeft : 10, cursor: "pointer"}}><i className="fa fa-close red-fa-close" onClick={() => deleteFile(component, f)}/></span>
            </div>
        );
        content.push(fileNameLine)
    });

    return (
        <div key={UUID()} className={'dropzone-helper-tooltip-container drop-info adjust-uploaded-files ' + classnames({'hidden': !component.state.infoVisible})}>
            {content}
        </div>
    );
}

function deleteFile(component, file) {
    let index = component.state.files.indexOf(file);
    if(index !== -1) {
        component.state.files.splice(index, 1);
        component.setState({files: component.state.files});
    }
}

function getInfoBox(component) {
    let content = [];

    if(component.state.isView){
        return content;
    }
    content.push(<div key={UUID()} className='drop-area'>
        <img alt="" onMouseEnter={() => showInfo(component)} onMouseLeave={() => hideInfo(component)} className='icon' src={icon}/>
    </div>);

    content.push(<div key={UUID()} className={'drop-info ' + classnames({'hidden': !component.state.infoVisible })}>
        {I18n.t('components.dropzone.placeholderInfo')}<br />
        {I18n.t('components.dropzone.filesLengthInfo', {max: component.state.maxFileSize})}<br />
        {I18n.t('components.dropzone.filesNumberInfo', {max: component.state.maxFilesNumber})}

    </div>);

    return content;
}

function showInfo(component) {
    component.setState({
        infoVisible: true
    });
}

function hideInfo(component) {
    component.setState({
        infoVisible: false
    });
}

function toggleSowInfo(component) {
    component.setState({
        infoVisible: !component.state.infoVisible
    });
}

function getTruncatedFileName(fileName) {
    let fileNameArray = _.split(fileName, '.');
    let fileExtension = fileNameArray[fileNameArray.length - 1];
    _.pullAt(fileNameArray, [fileNameArray.length - 1]);
    let fileNameResult = _.join(fileNameArray, '.');
    let fileNameWillBeTruncated = fileNameResult.length > 15;
    fileNameResult = _.truncate(fileNameResult, { 'length' : 17 });
    return fileNameResult + (fileNameWillBeTruncated ? '' : '.') + fileExtension;
}

Drop.propTypes = {
    fieldPlaceHolder: PropTypes.string,
    className: PropTypes.string,
    maxFilesNumber: PropTypes.number,
    maxFileSize: PropTypes.number,
    width: PropTypes.number,
    errorMaxSize: PropTypes.string,
    errorMaxFiles: PropTypes.string,
    onErrorEvent: PropTypes.func,
    isView: PropTypes.bool,
    deleteWarning: PropTypes.bool,
    outerDivClass: PropTypes.string,
    onFileSelected: PropTypes.func,
    minimalState: PropTypes.bool
};

export default Drop;