import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import _ from "lodash";
import {config} from '../../../config';
import moment from 'moment';
import Select from 'react-select';
import TimezoneUtils from "../../../utils/TimezoneUtils";
import HelperIcon from './../../../img/helpFieldIcon.svg';
import UserLanguageService from "../../../services/UserLanguageService";
import DateUtils from "../../../utils/DateUtils";
import DateTimeWithMask from "../dateTime/DateTimeWithMask";
import Inputmask from "inputmask";
import __ from "../../../utils/EnhancedLodash";
import NumberUtils from "../../../utils/NumberUtils";
import Tooltip from "../../tooltip/Tooltip";
import {t} from "../../../utils/Translation";
import 'moment/min/locales';

class LabeledField extends React.Component {
    constructor(props){
        super(props);
        this.field = null;
        let dataAttribute = props.dataAttribute? props.dataAttribute : 'name';
        let iconAttribute = props.iconAttribute ? props.iconAttribute : '';
        let hasIcon = props.hasIcon ? props.hasIcon : false;
        const sortAttribute =  props.sortAttribute || undefined;
        const unSortData = !__.isNilOrEmpty(props.unSortData) ? props.unSortData : false;
        let data = props.selectData ? unSortData ? props.selectData : sortData(props.selectData, this, dataAttribute, sortAttribute) : [];
        this.state = {
            value: props.value? props.value: '',
            interval: props.interval? props.interval : {
                start: '',
                end: '',
            },
            fieldType: props.fieldType? props.fieldType : 'text',
            data: data,
            error: props.error? props.error: '',
            labelPosition: props.labelPosition? props.labelPosition: 'top-left',
            dataAttribute: dataAttribute,
            iconAttribute: iconAttribute,
            hasIcon: hasIcon,
            src: props.src,
            sortAttribute: sortAttribute,
            suffix: props.suffix ? props.suffix : '',
            dataId: props.dataId? props.dataId: 'id',
            labelWidth: props.labelWidth,
            hasDate: !_.isNil(props.hasDate)? props.hasDate : true,
            hasTime: !_.isNil(props.hasTime)? props.hasTime : true,
            hasSeconds: !_.isNil(props.hasSeconds)? props.hasSeconds : false,
            isVisible: !_.isNil(props.isVisible)? props.isVisible: true,
            hideable: !_.isNil(props.hideable)? props.hideable: false,
            disabled: !_.isNil(props.disabled)? props.disabled: false,
            disabledInterval: props.disabledInterval? props.disabledInterval : {start: false, end: false},
            maxTextLength: !_.isNil(props.maxTextLength)? props.maxTextLength : config.labeledField.maxTextLength,
            bottomMargin: !_.isNil(props.bottomMargin)? props.bottomMargin : true,
            className: props.className? props.className: '',
            isRequired: !_.isNil(props.isRequired)? props.isRequired : false,
            isMasked: !_.isNil(props.isMasked)? props.isMasked : false,
            autoload: !_.isNil(props.autoload)? props.autoload : false,
            fieldPlaceHolder: props.fieldPlaceHolder? props.fieldPlaceHolder : '',
            dropdownClearable: !_.isNil(props.dropdownClearable)? props.dropdownClearable : true,
            keepDatetimeValuesInUTC: !_.isNil(props.keepDatetimeValuesInUTC)? props.keepDatetimeValuesInUTC : false,
            previousDateTimeValue: null,
            isFocused: false,
            labelPopup: props.labelPopup,
            maxSelectLimit: props.maxSelectLimit ?? null,
        }
    }


    componentDidMount() {
        this.setDisabledOnEmptyData(this.state.data);
        if(this.state.fieldType === 'dateTime' || this.state.fieldType === 'dateTimeInterval'){
            Inputmask().mask(document.querySelectorAll("input[data-inputmask]"));
        }
    }

    componentWillReceiveProps(nextProps) {
        let newDataAttribute = nextProps.dataAttribute? nextProps.dataAttribute : this.state.dataAttribute;
        let newSortAttribute = nextProps.sortAttribute? nextProps.sortAttribute : this.state.sortAttribute;
        const unSortData = !__.isNilOrEmpty(nextProps.unSortData) ? nextProps.unSortData : false;
        let newData = nextProps.selectData? unSortData ? nextProps.selectData : sortData(nextProps.selectData, this, newDataAttribute, newSortAttribute) : this.state.data;
        let newState = {
            data: newData,
            fieldType: nextProps.fieldType? nextProps.fieldType : this.state.fieldType,
            dataAttribute: newDataAttribute,
            disabled: !_.isNil(nextProps.disabled)? nextProps.disabled : this.state.disabled,
            fieldPlaceHolder: !_.isNil(nextProps.fieldPlaceHolder)? nextProps.fieldPlaceHolder : this.state.fieldPlaceHolder,
            isVisible: !_.isNil(nextProps.isVisible)? nextProps.isVisible : this.state.isVisible,
            isRequired: !_.isNil(nextProps.isRequired)? nextProps.isRequired : this.state.isRequired,
            error:  !_.isNil(nextProps.error)? nextProps.error : this.state.error,
            maxSelectLimit: nextProps.maxSelectLimit ? nextProps.maxSelectLimit : this.state.maxSelectLimit,
        };
        if(nextProps.forceNewPropValue) {
            newState.value = nextProps.value;
            newState.error = nextProps.error;
            newState.disabled = nextProps.disabled;
            newState.interval = nextProps.interval;
            newState.labelPopup = nextProps.labelPopup;
        }

        this.setState(newState, () => {
            this.setDisabledOnEmptyData(nextProps.selectData? nextProps.selectData : this.state.data);
            if(nextProps.forceNewPropValue && (this.state.fieldType === 'dateTime' || this.state.fieldType === 'dateTimeInterval')){
                Inputmask().mask(document.querySelectorAll("input[data-inputmask]"));
            }
        });
    }

    setDisabledOnEmptyData(data) {
        if((this.state.fieldType==='dropdown' || this.state.fieldType==='dropdown-multi')  && _.isEmpty(data)){
            if(this.state.fieldType === 'dateTimeInterval') {
                this.setState({disabledInterval: { start: true, end: true}, interval: {start: '', end: ''}});
            } else {
                this.setState({disabled: true, value: ""});
            }
        } else if((this.state.fieldType==='dropdown' || this.state.fieldType==='dropdown-multi') && !_.isEmpty(data) && !this.props.disabled) {
            this.setDisabled(false);
        }
    }

    setFieldPlaceHolder(fieldPlaceHolder){
        this.setState({fieldPlaceHolder: fieldPlaceHolder});
    }

    focus() {
        this.field.focus();
    }

    static moveCaretAtEnd(e) {
        let temp_value = e.target.value;
        e.target.value = '';
        e.target.value = temp_value;
    }

    setDisabled(disabled) {
        if(this.state.fieldType === 'dateTimeInterval') {
            this.setState({disabledInterval: { start: disabled, end: disabled}});
        } else {
            this.setState({disabled: disabled});
        }
    }

    setIsFocused(isFocused, afterIsFocusedIsChanged) {
        afterIsFocusedIsChanged = afterIsFocusedIsChanged? afterIsFocusedIsChanged : () => {};
        this.setState({isFocused}, afterIsFocusedIsChanged);
    }

    getIsFocused() {
        return this.state.isFocused;
    }

    setDisabledInterval(disabled, origin){
        if (origin === 'start') {
            this.setState((prevState) => {
                return {disabledInterval: { start: disabled, end: prevState.disabledInterval.end}};
            });
        } else if (origin === 'end') {
            this.setState((prevState) => {
                return {disabledInterval: { start: prevState.disabledInterval.start, end: disabled}};
            });
        }
    }

    validateField(...param) {
        let fieldValue = this.getFieldValue();
        if(this.state.isRequired && this.state.isVisible){
            if(_.isNil(fieldValue) || _.isEmpty(fieldValue) || (this.state.fieldType === 'dateTimeInterval' && !validIsRequiredForDateTimeIntervals(fieldValue, this))){
                if(!_.isNil(this.props.requiredFailError)){
                    this.setError(this.props.requiredFailError);
                } else {
                    this.setError(t('errors.labeledField.required'));
                }
                return false;
            }
        }

        if (!_.isNil(fieldValue) && fieldValueLengthExceedsMaxTextLength(fieldValue, this.state.maxTextLength) && (this.state.fieldType === 'text' || this.state.fieldType === 'text-area')) {
            this.setError(t('errors.labeledField.tooLong'));
            return false;
        }

        if(!_.isNil(this.props.validator)){
            let errorMessage = this.props.validator(param);
            if(!_.isEmpty(errorMessage)){
                this.setError(errorMessage);
                return false;
            }
        }

        if(this.state.fieldType === 'dateTime' && this.state.isVisible && !isDateTimeValid(fieldValue) && !this.state.disabledInterval){
            this.setError(t('errors.dateTimeField.error'));
            return false;
        }

        if(this.state.fieldType === 'dateTimeInterval' &&
            this.state.interval.start instanceof moment &&
            this.state.interval.end instanceof moment &&
            this.state.interval.end.isBefore(this.state.interval.start)){
            this.setError(t('errors.dateTimeField.dateTimeIntervalEndDate'));
            return false;
        }

        this.setError('');
        return true;
    }

    setError(error, afterErrorIsSet){
        afterErrorIsSet = afterErrorIsSet? afterErrorIsSet : () => {};
        this.setState({error: error}, () => afterErrorIsSet());
    }

    getFieldValue() {
        if(this.state.fieldType === 'dateTime') {
            return getDateTimeFieldValue(this);
        } else if (this.state.fieldType === 'dateTimeInterval') {
            return getDateTimeIntervalFieldValue(this);
        } else {
            return this.state.value;
        }
    }

    getRawFieldValue() {
        if (this.state.fieldType === 'dateTimeInterval') {
            return this.state.interval;
        } else {
            return this.state.value;
        }
    }

    getDropDownObjectFromValue(value) {
        let valueToSearch = value? value : this.state.value;
        if(this.state.fieldType === 'dropdown' &&
            !_.isNil(valueToSearch) && !_.isEmpty(valueToSearch) &&
            !_.isEmpty(this.state.data)) {
            let self = this;
            return _.find(this.state.data, (item) => {
                return item[self.state.dataId] === valueToSearch;
            });
        }
    }

    getError(){
        return this.state.error;
    }

    hasError() {
        return !_.isEmpty(this.state.error);
    }

    clearFieldValue(){
        if(this.state.fieldType==='dateTimeInterval') {
            this.setIntervalValue({
                start: '',
                end: '',
            });
        } else  if(this.state.fieldType==='dateTime') {
            this.setFieldValue(null);
            if(this.field) {
                this.field.setState({inputValue: '', selectedDate: null});
            }
            if(!_.isNil(this.props.onFieldChange)) {
                this.props.onFieldChange('');
            }
        } else {
            this.setFieldValue('');
        }
    }

    setFieldValue(value, afterValueIsSet) {
        afterValueIsSet = afterValueIsSet? afterValueIsSet : () => {};
        this.setState({value: value}, () => {
            afterValueIsSet();
        });
    }

    setIntervalValue(value) {
        this.setState({interval: value});
    }

    setIntervalValueFor(value, origin) {
        if (origin === 'start') {
            this.setState((prevState) => {
                return {interval: { start: value, end: prevState.interval.end}};
            });
        } else if (origin === 'end') {
            this.setState((prevState) => {
                return {interval: { start: prevState.interval.start, end: value}};
            });
        }
    }

    setDatetimePreviousValue(previousDateTimeValue, afterPreviousValueIsSet) {
        afterPreviousValueIsSet = afterPreviousValueIsSet ? afterPreviousValueIsSet : () => {};
        this.setState({previousDateTimeValue}, afterPreviousValueIsSet);
    }

    setData(data, afterDataIsSet) {
        afterDataIsSet = afterDataIsSet? afterDataIsSet : () => {};
        let sortedData = sortData(data, this, this.state.dataAttribute, this.state.sortAttribute);
        let self = this;
        this.setState({
            data: sortedData
        }, () => {
            if (_.isEmpty(sortedData)) {
                self.setFieldValue('');
            }
            self.setDisabledOnEmptyData(sortedData);
            afterDataIsSet();
        });
    }

    setDataWithValue(data, value, afterDataIsSet) {
        afterDataIsSet = afterDataIsSet? afterDataIsSet : () => {};
        let sortedData = sortData(data, this, this.state.dataAttribute, this.state.sortAttribute);
        let self = this;
        this.setState({
            data: sortedData,
            value: value
        }, () => {
            if (_.isEmpty(sortedData)) {
                self.setFieldValue('');
            }
            self.setDisabledOnEmptyData(sortedData);
            afterDataIsSet();
        });
    }

    getData() {
        return this.state.data;
    }

    setDataAttribute(dataAttribute){
        this.setState({dataAttribute: dataAttribute});
    }

    setDataId(dataId){
        this.setState({dataId: dataId});
    }

    setVisibility(value, afterVisibilityIsSet){
        afterVisibilityIsSet = afterVisibilityIsSet? afterVisibilityIsSet : () => {};
        this.setState({isVisible: value}, afterVisibilityIsSet);
    }

    isVisible(){
        return this.state.isVisible;
    }

    setIsRequired(isRequired) {
        this.setState({isRequired: isRequired});
    }

    async executeScroll() {
        const errorDivs = document.getElementsByClassName("errorScrollDiv");
        return await errorDivs && errorDivs.length > 0 ? errorDivs[0]?.scrollIntoView({behavior: "smooth"}) : null;
    }

    render() {
        let {
            className,
            bottomMargin,
            labelPosition,
            error,
            isVisible,
            hideable
        } = this.state;

        let {
            fieldHelper,
            width,
            children
        } = this.props;

        let hasHelper = !_.isNil(fieldHelper);
        className = 'form-group col-lg-' + width + ' ' + className;
        if(!bottomMargin) {
            className += ' mb-zero-absolute'
        }
        if (labelPosition === 'in-line') {
            className += ' flex-row';
            if(!_.isEmpty(error)) {
                className += ' has-error';
            }
        }
        if(isVisible && !hasHelper) {
            return (
                <>
                    { error &&
                        <div className={"errorScrollDiv"}/>
                    }
                    <div className={className}>

                        {createLabel(this)}
                        {createInput(this)}
                        {children}
                    </div>
                </>
            );
        }

        if(isVisible && hasHelper) {
            return (
                <>
                    { error &&
                        <div className={"errorScrollDiv"}/>
                    }
                    <div className={className + " flex-column"}>
                        {createLabel(this)}
                        <div className={"flex"}>
                            {createInput(this)}
                            {createHelper(this)}
                        </div>
                        {children}
                    </div>
                </>
            );

        }

        if(hideable) {
            return null;
        }

        return <div className={className}/>;
    }
}

function sortData(data, component, dataAttribute, sortAttribute) {
    if(!_.isNil(component.props.sort)){
        return component.props.sort(data);
    } else {
        return sortAttribute ? _.sortBy(data, sortAttribute, (element) => {return element[dataAttribute]}): _.sortBy(data, (element) => {return element[dataAttribute]})
    }
}

function createHelper(component) {
    if(!_.isNil(component.props.fieldHelper)) {
        let element;
        if(component.props.isView) {
            element = (
                <span className="f-size-14" style={{ padding: "5px 0px 5px 12px" }}>{component.props.fieldHelper}</span>
            );
        } else {
            element = (
                <div className="labeled-field-helper">{component.props.fieldHelper}</div>
            );
        }
        return element;
    }
}

function validIsRequiredForDateTimeIntervals(value, component) {
    return (
        _.has(value, 'start') &&
        _.has(value, 'end') && (
            component.state.disabledInterval.start || (
                !component.state.disabledInterval.start &&
                !_.isNil(value.start) &&
                !_.isEmpty(value.start)
            )
        ) && (
            component.state.disabledInterval.end || (
                !component.state.disabledInterval.end &&
                !_.isNil(value.end) &&
                !_.isEmpty(value.end)
            )
        )
    )
}

function getDateTimeFieldValue(component){
    if ((typeof component.state.value === 'string' || component.state.value instanceof String)) {
        let value = component.state.value;
        let dateFormatString = getDateFormatString(component);
        if(moment(value, dateFormatString).isValid()) {
            return value;
        }

    } else {
        let value = _.isNil(component.state.value) ? '' : component.state.value;
        if(!_.isEmpty(value)) {
            return component.props.keepDatetimeValuesInUTC ?
                value.format(getDateFormatString(component)) :
                TimezoneUtils.convertUserTimezoneToUTCFormat(value).format(getDateFormatString(component));
        }
    }
}

function getDateFormatString(component) {
    if (component.props.hasDate && component.props.hasTime) {
        return config.datepicker.formats.isoDateTime;
    } else if (component.props.hasDate && !component.props.hasTime) {
        return config.datepicker.formats.alternativeDate;
    } else if (!component.props.hasDate && component.props.hasTime && component.props.hasSeconds) {
        return config.datepicker.formats.time;
    } else if (!component.props.hasDate && component.props.hasTime && !component.props.hasSeconds) {
        return config.datepicker.formats.timeWithoutSeconds;
    }
}

function getDateTimeIntervalFieldValue(component) {
    let startValue = !_.isNil(component.state.interval.start) && moment(component.state.interval.start).isValid() ? component.state.interval.start : '';
    let endValue = !_.isNil(component.state.interval.end) && moment(component.state.interval.end).isValid() ?  component.state.interval.end : '';

    startValue = _.isEmpty(startValue)? null :
        component.props.keepDatetimeValuesInUTC ?
            component.state.interval.start.format(getDateFormatString(component)) :
            TimezoneUtils.convertUserTimezoneToUTCFormat(component.state.interval.start).format(getDateFormatString(component));
    endValue = _.isEmpty(endValue)? null :
        component.props.keepDatetimeValuesInUTC ?
            component.state.interval.end.format(getDateFormatString(component)) :
            TimezoneUtils.convertUserTimezoneToUTCFormat(component.state.interval.end).format(getDateFormatString(component));

    return {start: startValue, end: endValue};
}

function handleFieldChange(component) {
    return (e) => {
        if ((component.state.fieldType === 'dropdown-multi' || component.state.fieldType === 'async-dropdown-multi') && component.state.maxSelectLimit) {
            if (e.length > component.state.maxSelectLimit) {
                return;
            }
        }
        if(component.state.fieldType === 'dropdown' || component.state.fieldType === 'async-dropdown'){
            component.setState({value: e});
        } else if(component.state.fieldType === 'dropdown-multi' || component.state.fieldType === 'async-dropdown-multi' ) {
            component.setState({value: _.map(e, (element) => {return element[component.state.dataId];})});
        } else {
            if(!__.isNilOrEmpty(component.props.step)) {
                if(NumberUtils.decimalPlaces(component.props.step) >= NumberUtils.decimalPlaces(e.target.value) ) {
                    component.setState({value: e.target.value});
                } else {
                    return;
                }
            } else {
                component.setState({value: e.target.value});
            }
        }
        if(!_.isNil(component.props.onFieldChange)) {
            if (component.state.fieldType === 'dropdown') {
                let model = null;
                component.state.data.forEach(el => {
                    if (el[component.state.dataId] === e) {
                        model = el;
                    }
                });

                component.props.onFieldChange(model);
            } else if(component.state.fieldType === 'dropdown-multi') {
                component.props.onFieldChange(_.map(e, (element) => {return element[component.state.dataId];}));
            } else {
                component.props.onFieldChange(e.target.value);
            }
        }
    };
}

function handleMultiFieldChange(component, values) {
    const fieldValue = _.map(values, (element) => {return element[component.state.dataId];});
    component.setState({value: fieldValue});
    if(!_.isNil(component.props.onFieldChange)) {
        component.props.onFieldChange(fieldValue);
    }
}

function handleFieldBlur(component) {
    return (e) => {
        if(!_.isNil(component.props.onBlur)) {
            component.props.onBlur(e.target.value);
        }
    };
}

function handleDateTimeFieldBlur(component) {
    return momentValue => {
        component.setState({
            isFocused: false,
            previousDateTimeValue: null
        },()=> {
            if(!_.isNil(component.props.onBlur)) {
                component.props.onBlur(momentValue, component.state.interval);
            }
        });
    };
}

function handleFieldClick(component) {
    return () => {
        if(!_.isNil(component.props.onClickField)) {
            component.props.onClickField();
        }
    }
}

function handleButtonGroupChange(component, id) {
    if(component.state.value !== id){
        component.setState({value: id}, () => {
            if(!_.isNil(component.props.onFieldChange)) {
                component.props.onFieldChange(id);
            }
        });
    }
}

function getEmptyPlaceholder(value){
    if(_.isNil(value) || _.isEmpty(value) || value === 'NaN/Invalid date/NaN'){
        return '- - -'
    } else {
        return value;
    }
}

function getDropdownValue(component) {
    const {
        data,
        dataId,
        value,
        dataAttribute,
        iconAttribute,
        hasIcon,
        src
    } = component.state;

    let obj = '';
    if(_.isObject(value)) {
        if(hasIcon && value[iconAttribute]){
            return <><img src={src + value[iconAttribute] + '?cacheControl=' + new Date().getTime()} alt={""} style={{width:"30px",  marginRight:"5px", marginBottom:"2px"}}/>{value[dataAttribute]}</>
        } else {
            return value[dataAttribute]
        }
    } else {
        obj = _.find(data, (o) => {
            return o[dataId] === value
        });
    }

    let result = '';
    if(!_.isNil(obj)){
        result = obj[dataAttribute];
    }
    return result;
}

function getMultiDropdownValue(component) {
    let values = [];
    let {
        value,
        data,
        dataId,
        dataAttribute
    } = component.state;
    value = _.isArray(value) || __.isNilOrEmpty(value) ? value : [value];
    _.forEach(value, el => {
        let obj = _.find(data, (o) => {return o[dataId] === el});
        if(!_.isNil(obj)){
            values.push(
                <li key={obj[dataAttribute]}>{obj[dataAttribute]}</li>
            );
        }
    });
    if(_.isEmpty(values)) {
        return '- - -';
    } else {
        return <ul>{values}</ul>;
    }
}

function getDropdownField(component, isMulti, isAsync) {
    let element;
    let dropdownClassName = _.isEmpty(component.state.error) ? "labeled-field-dropdown" : "labeled-field-dropdown dropdown-has-error";
    if(component.props.isView) {
        element = (
            <div className="text-field-info">
                {isMulti? getMultiDropdownValue(component) : getEmptyPlaceholder(getDropdownValue(component))}
            </div>
        );
    } else if(isAsync) {
        element = (
            <div className="input-group col-xs-12 p-total-0 wp-initial">
                <Select.Async
                    className={dropdownClassName}
                    simpleValue={!isMulti}
                    multi={isMulti}
                    autoload={component.props.autoload}
                    loadOptions={(value) => component.props.asyncLoadOptions(value)}
                    clearable={component.state.dropdownClearable}
                    disabled={component.state.disabled}
                    value={component.state.value}
                    autosize={false}
                    placeholder={component.props.fieldPlaceHolder}
                    onChange={handleFieldChange(component)}
                    labelKey={component.state.dataAttribute}
                    valueKey={component.state.dataId}
                    isSearchable={!component.props.isReadOnly || true}
                />
            </div>
        );
    } else {
        if(component.props.creatable) {
            let options = _.isEmpty(component.state.data) && !_.isEmpty(component.state.value) ? component.state.value : component.state.data;
            options = options.map(opt => {return {'id': opt, 'name': opt};});
            element = (
                <div className="input-group col-xs-12 p-total-0 wp-initial">
                    <Select.Creatable
                        className={dropdownClassName}
                        simpleValue={!isMulti}
                        multi={isMulti}
                        clearable={component.state.dropdownClearable}
                        value={component.state.value}
                        placeholder={component.state.fieldPlaceHolder}
                        options={options}
                        autosize={false}
                        onChange={handleFieldChange(component)}
                        labelKey={component.state.dataAttribute}
                        valueKey={component.state.dataId}
                        isSearchable={!component.props.isReadOnly || true}
                    />
                </div>
            );
        } else if(isMulti) {
            let props = {
                className:dropdownClassName,
                simpleValue:false,
                multi:true,
                disabled:component.state.disabled || _.isEmpty(component.state.data),
                clearable:component.state.dropdownClearable,
                value: component.state.hasIcon && (component.state.value !== null && component.state.value !== "") ? getIcon(component.state.value, component.state.src) : component.state.value,
                placeholder:component.state.fieldPlaceHolder,
                options:component.state.hasIcon ? component.state.data.map(item => getIcon(item, component.state.src)) : component.state.data,
                autosize:false,
                onChange:handleFieldChange(component),
                labelKey:component.state.dataAttribute,
                valueKey:component.state.dataId,
            }

            if (component.props.allowSelectAll) {
                //https://medium.com/@alex_escalante/react-select-alloptionoptions-with-a-single-click-1ebf5a33fe31

                const valuesSelected = !__.isNilOrEmpty(props.value) ? props.value.length : 0;
                const valuesOptions = !__.isNilOrEmpty(props.options) ? props.options.length : 0;
                if (valuesSelected !== valuesOptions) {
                    const selectAllOption = getSelectAllOption(component.state.dataAttribute);
                    return (
                        <Select
                            {...props}
                            onBlur={()=> {
                                if(!_.isNil(component.props.onBlur)) {
                                    component.props.onBlur();
                                }
                            }}
                            options={[selectAllOption, ...props.options]}
                            onChange={selected => {
                                if (selected.length > 0 && selected[selected.length - 1].id === selectAllOption.id) {
                                    return handleMultiFieldChange(component, props.options);
                                }
                                return handleMultiFieldChange(component, selected);
                            }}
                        />
                    );
                }
            }

            return <Select onBlur={()=> {
                if(!_.isNil(component.props.onBlur)) {
                    component.props.onBlur();
                }
            }} {...props}  />;

        } else {
            element = (
                <div className="input-group col-xs-12 p-total-0 wp-initial" onClick={handleFieldClick(component)}>
                    <Select
                        className={dropdownClassName}
                        isSearchable={!component.props.isReadOnly || true}
                        simpleValue={!isMulti}
                        multi={isMulti}
                        disabled={component.state.disabled || _.isEmpty(component.state.data)}
                        clearable={component.state.dropdownClearable}
                        value={component.state.hasIcon &&  (component.state.value !== null && component.state.value !== "") ? getIcon(component.state.value, component.state.src) : component.state.value}
                        placeholder={component.state.fieldPlaceHolder}
                        options={component.state.hasIcon ? component.state.data.map(item => getIcon(item, component.state.src)) : component.state.data}
                        autosize={false}
                        onChange={handleFieldChange(component)}
                        labelKey={component.state.dataAttribute}
                        valueKey={component.state.dataId}
                        onBlur={handleFieldBlur(component)}
                    />
                </div>
            )
        }
    }
    return element;
}

function getTextField(component) {
    let element;
    if(component.props.isView) {
        element = (
            <div className="text-field-info">
                {getEmptyPlaceholder(component.state.value)}
                {component.state.suffix}
            </div>
        );
    } else {
        let value = component.state.value? component.state.value : '';
        element = (
            <input
                onBlur={handleFieldBlur(component)}
                value={value}
                onChange={handleFieldChange(component)}
                onClick={handleFieldClick(component)}
                type={component.state.isMasked ? 'password' : 'text'}
                placeholder={component.state.fieldPlaceHolder}
                className='form-control'
                autoComplete='off'
                disabled={component.state.disabled}
                readOnly={component.props.isReadOnly}
                onFocus={(e) => LabeledField.moveCaretAtEnd(e)}
                onKeyPress={(e) => handleInputKeyPress(e, component)}
                ref={c => component.field = c}

            />
        );
    }
    return element;
}

function getNumberField(component) {
    let element;
    if(component.props.isView) {
        element = (
            <div className="text-field-info">
                {getEmptyPlaceholder(component.state.value)}
                {component.state.suffix}
            </div>
        );
    } else {
        let value = !isNaN(component.state.value) ? component.state.value : '';
        element = (
            <input
                onBlur={handleFieldBlur(component)}
                value={value}
                onChange={handleFieldChange(component)}
                onClick={handleFieldClick(component)}
                type={'number'}
                placeholder={component.state.fieldPlaceHolder}
                className='form-control'
                autoComplete='off'
                step={!__.isNilOrEmpty(component.props.step) ? component.props.step : undefined }
                min={!__.isNilOrEmpty(component.props.min) ? component.props.min : undefined }
                max={!__.isNilOrEmpty(component.props.max) ? component.props.max : undefined }
                disabled={component.state.disabled}
                readOnly={component.props.isReadOnly}
                onFocus={(e) => LabeledField.moveCaretAtEnd(e)}
                onKeyPress={(e) => handleInputKeyPress(e, component)}
                ref={c => component.field = c}

            />
        );
    }
    return element;
}

function handleInputKeyPress(e, component) {
    if(e.key === 'Enter'){
        if(!_.isNil(component.props.handleSubmitAction)) {
            component.props.handleSubmitAction(component.getFieldValue());
        }
    }
}

function getTextAreaField(component) {
    let element;
    let value = getEmptyPlaceholder(component.state.value);
    if(component.props.isView) {
        element = (
            <div className="text-field-info">
                {replaceNewLine(value)}
            </div>
        );
    } else {
        value = component.state.value ? component.state.value : '';
        element = (
            <textarea
                value={value}
                onBlur={handleFieldBlur(component)}
                onChange={handleFieldChange(component)}
                onClick={handleFieldClick(component)}
                placeholder={component.state.fieldPlaceHolder}
                className='form-control'
                disabled={component.state.disabled}
                readOnly={component.props.isReadOnly}
                rows={component.props.rows}
                style={{resize: "vertical", overflowX: 'hidden'}}>
            </textarea>
        );
    }
    return element;
}

function replaceNewLine(content) {
    let result = [];
    let partsOfStr = content.split('\n');
    partsOfStr.forEach(part => {
        result.push(<br/>);
        result.push(part);
    });
    result.shift();
    return result;
}

function getButtonGroupField(component) {
    let btn_size = 100 / component.state.data.length;
    let element;
    let isMobile = window.matchMedia("(min-width: 200px) and (max-width: 768px)").matches;
    let divStyle = component.props.isView || component.state.disabled ? { pointerEvents: 'none', zIndex: 10} : isMobile ? { height: '5rem', marginBottom: '15px', zIndex: 10} : null;
    if(component.props.isView) {
        element = (
            <div className="text-field-info">
                {getEmptyPlaceholder(getDropdownValue(component))}
            </div>
        );
    } else {
        element = (
            <div
                className={classnames('btn-group col-xs-12', { 'btn-group-edit': !component.props.isView && !component.state.disabled })}
                style={divStyle}
            >
                {component.state.data.map((dataElement) => {
                    return (
                        <button
                            key={dataElement.id}
                            type="button"
                            className={classnames(component.props.buttonClassName, { 'btn-clicked': component.state.value === dataElement.id }, { 'btn-disabled': component.state.disabled && component.state.value !== dataElement.id })}
                            style={component.props.buttonClassName ? {} : { width: btn_size + "%" }}
                            onClick={() => handleButtonGroupChange(component, dataElement.id)}
                        >
                            {dataElement.name}
                        </button>);
                })
                }
            </div>
        );
    }
    return element;
}

function getLabeledList(component) {
    let labelTextValues = _.isNil(component.state.value) || _.isEmpty(component.state.value) ? [] : component.state.value;

    if (labelTextValues.length >= 5) {
        labelTextValues = [component.props.moreThan5LabelsText];
    }

    let labelList = [];

    labelTextValues.forEach(labelText => {
        labelList.push(<span className="label label-primary mr-5">{labelText}</span>);
    });

    return <div className="display-inline-block">{labelList}</div>;
}

function getFieldIcon(component,field) {
    let flexInputGroup = '';
    let flexInputAddon = '';
    if (component.state.labelPosition === 'in-line') {
        flexInputGroup = ' flex-input-group';
        flexInputAddon = ' flex-input-group-addon';
    }

    return (
        <div className={'input-group col-xs-12' + flexInputGroup}>
                <span className={classnames('input-group-addon' + flexInputAddon,{'blocked-input-group-addon': component.state.disabled})}>
                    <i className={classnames(component.props.iconClassName,'flex-align-self-center')}/>
                </span>
            {field}
        </div>
    );
}

function getErrorArea(component, field) {
    return (
        <div className={classnames('has-error')}>
            {field}
            <span className='help-block pull-right'><i>{component.state.error}</i></span>
        </div>
    );
}

function getErrorAreaForInlineView(component,field) {
    return (
        <div className='has-error entity-div-field-error'>
            {field}
            <span className='has-error-color pull-right'><i>{component.state.error}</i></span>
        </div>
    );
}

function getTimeFormat(component) {
    if(!component.state.hasTime){
        return false;
    }
    if(!component.state.hasSeconds){
        return config.datepicker.formats.timeWithoutSeconds;
    }
    return config.datepicker.formats.time;
}

function getDateFormat(component) {
    if(!component.state.hasDate){
        return false;
    }
    return config.datepicker.formats.date;
}

function getTimeMask(component) {
    if(!component.state.hasTime){
        return "";
    }
    if(!component.state.hasSeconds){
        return "99:99";
    }
    return "99:99:99";
}

function getDateMask(component) {
    if(!component.state.hasDate){
        return "";
    }
    return "99/99/9999";
}


function handleDateTimeFieldChange(component) {

    return (e) => {
        let previousValue = component.state.value;
        let isFocused = true;
        if((component.state.hasDate && !component.state.hasTime) || checkIfDatePickerWasDoubleClicked(e, component.state.value) ) {
            previousValue = null;
            isFocused = false;
        }
        component.setState({
            value: e,
            previousDateTimeValue: previousValue,
            isFocused: isFocused
        }, () => {
            if(!_.isNil(component.props.onFieldChange)) {
                if( e instanceof moment && !(component.props.keepDatetimeValuesInUTC || component.props.avoidDateTimeConversionToUserTimeZone)) {
                    component.props.onFieldChange(TimezoneUtils.convertUserTimezoneToUTCFormat(e));
                } else {
                    component.props.onFieldChange(e);
                }
            }
        });
    };
}

function handleDateTimeIntervalFieldChange(component, origin) {
    return (e) => {
        component.setIntervalValueFor(e, origin);
        if(!_.isNil(component.props.onFieldChange)) {
            component.props.onFieldChange(e, origin);
        }
    };
}

function getDateTimeField(component) {
    let isValidDate = component.props.dateValidator? component.props.dateValidator : () => {return true};
    let element;
    let {
        isView,
        hasDate,
        hasTime,
        avoidDateTimeConversionToUserTimeZone,
        keepDatetimeValuesInUTC,
        ignoreForceRightClearField
    } = component.props;
    let {
        value,
        hasSeconds,
        error,
        fieldPlaceHolder,
        disabled
    } = component.state;


    if(isView) {
        element = (
            <div className="text-field-info">
                {getEmptyPlaceholder(DateUtils.simpleDisplayDateTime(value, hasDate, hasTime, avoidDateTimeConversionToUserTimeZone || keepDatetimeValuesInUTC, !hasSeconds))}
            </div>
        );
    } else {
        let timeFormat = getTimeFormat(component);
        let dateFormat = getDateFormat(component);
        let timeMask = getTimeMask(component);
        let dateMask = getDateMask(component);
        let mask;
        if(!_.isEmpty(dateMask) && !_.isEmpty(timeMask)) {
            mask = dateMask + " " + timeMask;
        } else if(!_.isEmpty(dateMask)) {
            mask = dateMask;
        } else {
            mask = timeMask;
        }

        // // fix bug in report form
        // if((typeof value === 'string' || value instanceof String) && (!hasDate && hasTime && !hasSeconds)) {
        //     //value = value.replace(/:[^:]*$/,'');
        // }

        if( ! ((typeof value === 'string' || value instanceof String) && !__.isNilOrEmpty(value) && value.includes("_"))) {
            if(!__.isNilOrEmpty(value) && moment(value, getDateFormatString(component)).isValid()) {
                if(!(keepDatetimeValuesInUTC || avoidDateTimeConversionToUserTimeZone)) {
                    value = TimezoneUtils.convertUTCToUserTimezoneWithFormat(value, getDateFormatString(component));
                } else {
                    value = moment(value, getDateFormatString(component));
                }
            }
        }

        element = (
            <div className='datetime-parent-container' style={!_.isEmpty(error) ? {borderColor: '#a94442'} : {}}>
                <div className={component.state.disabled? 'datetime-container-disabled' : 'datetime-container'}>
                    <DateTimeWithMask
                        mask={mask}
                        locale={UserLanguageService.getCurrentLanguage()}
                        onBlur={handleDateTimeFieldBlur(component)}
                        timeFormat={timeFormat}
                        dateFormat={dateFormat}
                        value={value}
                        inputProps={{placeholder: fieldPlaceHolder, disabled: disabled}}
                        isValidDate={value => {return isValidDate(value);}}
                        onChange={handleDateTimeFieldChange(component)}
                        ref={(c) => component.field = c}
                    />
                </div>
                {!disabled && !__.isNilOrEmpty(value)?
                    <div className='datetime-close-container'>
                        <span
                            className={'Select-clear-zone clear-datetime-button ' + (!ignoreForceRightClearField? 'clear-datetime-button-force-right' : '')}
                            title={t('generic.clear')}
                            aria-label={t('generic.clear')}
                            onClick={() => component.clearFieldValue()}>
                            ×
                        </span>
                    </div>
                    : null}
            </div>
        );
    }
    return element;
}

function checkIfDatePickerWasDoubleClicked(value1, value2) {
    if(!_.isNil(value1) || !_.isNil(value2)){
        return false
    }
    return (
        value1 !== "" &&
        value2 !== "" &&
        value2.format(config.datepicker.formats.dateTime) === value1.format(config.datepicker.formats.dateTime)
    );
}

function getDateTimeIntervalField(component) {
    let element;
    if(component.props.isView) {
        element = (
            <div className="flex-row">
                <div className="col-md-6">
                    <label className='pd-r-15 pull-left'>
                        {t('components.dateTimeInterval.start')}:
                        <span style={{fontWeight: "400"}}> {getEmptyPlaceholder(DateUtils.simpleDisplayDateTime(component.state.interval.start, component.props.hasDate, component.props.hasTime, component.props.avoidDateTimeConversionToUserTimeZone))}</span>
                    </label>
                </div>
                <div className="col-md-6">
                    <label className='pd-r-15 pull-left'>
                        {t('components.dateTimeInterval.end')}:
                        <span style={{fontWeight: "400"}}> {getEmptyPlaceholder(DateUtils.simpleDisplayDateTime(component.state.interval.end, component.props.hasDate, component.props.hasTime, component.props.avoidDateTimeConversionToUserTimeZone))}</span>
                    </label>
                </div>
            </div>
        );
    } else {
        let timeFormat = getTimeFormat(component);
        let dateFormat = getDateFormat(component);
        let timeMask = getTimeMask(component);
        let dateMask = getDateMask(component);
        let mask = "";
        if(!_.isEmpty(dateMask) && !_.isEmpty(timeMask)) {
            mask = dateMask + " " + timeMask;
        } else if(!_.isEmpty(dateMask)) {
            mask = dateMask;
        } else {
            mask = timeMask;
        }
        let userLanguage = UserLanguageService.getCurrentLanguage();

        let start = component.state.interval.start;
        if(!_.isNil(start) && !_.isUndefined(start) && moment(start).isValid()) {
            start = moment(start);
        }

        let end = component.state.interval.end;
        if(!_.isNil(end) && !_.isUndefined(end) && moment(end).isValid()) {
            end = moment(end);
        }

        element = (
            <div className="flex-row" style={{whiteSpace: "initial"}}>
                <div className="col-md-6">
                    <label className='pd-r-15 pull-left'>
                        {t('components.dateTimeInterval.start')}:
                    </label>
                    <div className='datetime-parent-container' style={_.assign({display: "inline-flex"}, !_.isEmpty(component.state.error) ? {borderColor: '#a94442'} : {})}>
                        <div className={component.state.disabledInterval.start? 'datetime-container-disabled' : 'datetime-container'}>
                            <DateTimeWithMask
                                mask={mask}
                                locale={userLanguage}
                                onBlur={handleDateTimeFieldBlur(component)}
                                timeFormat={timeFormat}
                                dateFormat={dateFormat}
                                closeOnSelect={component.state.hasDate && !component.state.hasTime}
                                value={start}
                                inputProps={{placeholder: component.state.fieldPlaceHolder, disabled: component.state.disabledInterval.start}}
                                onChange={handleDateTimeIntervalFieldChange(component, 'start')}
                            />
                        </div>
                        {!component.state.disabled && !component.state.disabledInterval.start && !_.isEmpty(component.state.interval.start) && !_.isNil(component.state.interval.start)?
                            <div className='datetime-close-container'>
                                <span
                                    className='Select-clear-zone clear-datetime-button'
                                    title={t('generic.clear')}
                                    aria-label={t('generic.clear')}
                                    onClick={() => component.setIntervalValueFor('','start')}>
                                    ×
                                </span>
                            </div>
                            : null}
                    </div>
                </div>
                <div className="col-md-6">
                    <label className='pd-r-15 pull-left'>
                        {t('components.dateTimeInterval.end')}:
                    </label>
                    <div className='datetime-parent-container' style={_.assign({display: "inline-flex"}, !_.isEmpty(component.state.error) ? {borderColor: '#a94442'} : {})}>
                        <div className={component.state.disabledInterval.end? 'datetime-container-disabled' : 'datetime-container'}>
                            <DateTimeWithMask
                                mask={mask}
                                locale={userLanguage}
                                onBlur={handleDateTimeFieldBlur(component)}
                                timeFormat={timeFormat}
                                dateFormat={dateFormat}
                                value={end}
                                isValidDate={value => endDateIsAfterStartDate(component, value)}
                                inputProps={{placeholder: component.state.fieldPlaceHolder, disabled: component.state.disabledInterval.end}}
                                closeOnSelect={component.state.hasDate && !component.state.hasTime}
                                onChange={handleDateTimeIntervalFieldChange(component, 'end')}
                            />
                        </div>
                        {!component.state.disabled && !component.state.disabledInterval.end && !_.isEmpty(component.state.interval.end) && !_.isNil(component.state.interval.end)?
                            <div className='datetime-close-container'>
                                <span
                                    className='Select-clear-zone clear-datetime-button'
                                    title={t('generic.clear')}
                                    aria-label={t('generic.clear')}
                                    onClick={() => component.setIntervalValueFor('','end')}>
                                        ×
                                </span>
                            </div>
                            : null}
                    </div>
                </div>
            </div>
        );
    }
    return element;
}

function isDateTimeValid(dateTime) {
    let formats = [
        config.datepicker.formats.dateTime,
        config.datepicker.formats.dateTimeList,
        config.datepicker.formats.date,
        config.datepicker.formats.alternativeDate,
        config.datepicker.formats.time,
        config.datepicker.formats.timeWithoutSeconds,
        config.datepicker.formats.isoDateTime
    ];
    return moment(dateTime, formats, true).isValid();
}

function endDateIsAfterStartDate(component, value) {
    if(!_.isNil(value) &&
        !_.isEmpty(value) &&
        component.state.hasDate &&
        !_.isNil(component.state.interval.start) &&
        !_.isEmpty(component.state.interval.start)) {
        return value.isSameOrAfter(component.state.interval.start, 'year') &&
            value.isSameOrAfter(component.state.interval.start, 'month') &&
            value.isSameOrAfter(component.state.interval.start, 'day');
    } else {
        return true;
    }
}

function fieldValueLengthExceedsMaxTextLength(value, length) {
    return (length !== 0 && value.length > length);
}

function createInput(component){
    let field;
    if(component.state.fieldType === 'dropdown') {
        field = getDropdownField(component, false, false);
    } else if(component.state.fieldType === 'dropdown-multi') {
        field = getDropdownField(component, true, false);
    } else if(component.state.fieldType === 'async-dropdown') {
        field = getDropdownField(component, false, true);
    } else if(component.state.fieldType === 'async-dropdown-multi') {
        field = getDropdownField(component, true, true);
    } else if(component.state.fieldType === 'text'){
        field = getTextField(component);
    } else if(component.state.fieldType === 'number'){
        field = getNumberField(component);
    } else if(component.state.fieldType === 'text-area'){
        field = getTextAreaField(component);
    } else if(component.state.fieldType === 'labeled-list'){
        field = getLabeledList(component);
    } else if(component.state.fieldType === 'button-group'){
        field = getButtonGroupField(component);
    } else if(component.state.fieldType === 'dateTime'){
        field = getDateTimeField(component);
    } else if(component.state.fieldType === 'dateTimeInterval'){
        field = getDateTimeIntervalField(component);
    } else {
        field = null;
    }

    if(!_.isNil(component.props.iconClassName) && !component.props.isView) {
        field = getFieldIcon(component, field);
    }

    if(!_.isEmpty(component.state.error)) {
        field = component.state.labelPosition === 'in-line' ? getErrorAreaForInlineView(component, field) : getErrorArea(component, field);
        component.executeScroll();
    }

    return field;
}

function createLabel(component){
    if(!_.isNil(component.props.fieldLabel)){
        let labelStyle = component.state.labelWidth ? {width: component.state.labelWidth} : {};
        let smallLabel;
        let maxTextLength;
        let br;
        let hasAsterisk = !_.isNil(component.props.hasRequiredAsterisk)? component.props.hasRequiredAsterisk : true;
        if (!_.isNil(component.props.smallFieldLabel)) {
            smallLabel = <small className={classnames('optional')}> {component.props.smallFieldLabel} </small>;
            br = <br/>;
        }

        let tooltipHelperClassName = "pd-r-15 labeled-field-helper-icon " +  (hasAsterisk ? "" : "pd-l-10");

        let labelIcon = !_.isNil(component.props.labelIcon) && (
            <img alt="" width={15} src={component.props.labelIcon}/>)

        if(!_.isNil(component.props.labelIconTooltip)){
            labelIcon = <Tooltip content={component.props.labelIconTooltip}>
                {labelIcon}
            </Tooltip>
        }

        if(!_.isNil(component.props.maxTextLength) && !component.state.disabled){
            const value = component.state.value? component.state.value : '';
            maxTextLength = (<span>
                <i className="fa fa-keyboard-o" aria-hidden="true"/>
                <i> <b>{(component.props.maxTextLength - value.length)}</b></i>
            </span>)
        }

        let tooltipHelper =  !_.isNil(component.props.tooltip) && (
            <Tooltip content={component.props.tooltip}>
                {<img alt="" className={tooltipHelperClassName} src={HelperIcon}/>}
            </Tooltip>
        );

        let helperIcon = !_.isNil(component.state.labelPopup) ? component.state.labelPopup : null;
        return (
            <div style={labelStyle}>
                <label className={classnames({"required-field": component.state.isRequired && hasAsterisk && !component.props.isView},'pd-r-15', { "pd-t-5" : component.props.isView })} >
                    {component.props.fieldLabel}{component.props.maxSelectLimit ? ` (${t('generic.maxAbbr')} ${component.props.maxSelectLimit})` : ''}
                    {br}
                    {smallLabel}
                </label>
                {labelIcon}
                {maxTextLength}
                {tooltipHelper}
                {helperIcon}
            </div>
        );
    } else {
        return null;
    }
}

function getSelectAllOption(dataAttribute) {
    return {id: "*", [dataAttribute]: t('generic.selectAll') }
}

function getIcon(item, src){
    if (item?.icon){
        return {
            ...item,
            id: item.id,
            name:[
                <>
                    <img className={"flex-1"} src={src + item?.icon + '?cacheControl=' + new Date().getTime()} alt={item.name} style={{width:"30px",  marginRight:"5px", marginBottom:"2px"}}/>
                </>,
                item.name]
        }
    }

    return item;
}

LabeledField.propTypes = {
    fieldLabel: PropTypes.string,
    fieldPlaceHolder: PropTypes.string,
    smallFieldLabel: PropTypes.string,
    fieldType: PropTypes.string.isRequired,
    width: PropTypes.string.isRequired,
    onFieldChange: PropTypes.func,
    fieldName: PropTypes.string,
    selectData: PropTypes.array,
    isRequired: PropTypes.bool,
    hasRequiredAsterisk: PropTypes.bool,
    iconClassName: PropTypes.string,
    requiredFailError: PropTypes.string,
    validator: PropTypes.func,
    onClickField: PropTypes.func,
    isReadOnly: PropTypes.bool,
    creatable: PropTypes.bool,
    error: PropTypes.string,
    labelPosition: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(moment), PropTypes.array, PropTypes.object]),
    isView: PropTypes.bool,
    hasDate: PropTypes.bool,
    hasTime: PropTypes.bool,
    disabled: PropTypes.bool,
    interval: PropTypes.object,
    dataAttribute: PropTypes.string,
    hasIcon: PropTypes.bool,
    src: PropTypes.string,
    suffix: PropTypes.string,
    dataId: PropTypes.string,
    fieldHelper: PropTypes.string,
    maxTextLength: PropTypes.number,
    bottomMargin: PropTypes.bool,
    onBlur: PropTypes.func,
    className: PropTypes.string,
    sort: PropTypes.func,
    asyncLoadOptions: PropTypes.func,
    autoload: PropTypes.bool,
    dropdownClearable: PropTypes.bool,
    isVisible: PropTypes.bool,
    isMasked: PropTypes.bool,
    hideable: PropTypes.bool,
    avoidDateTimeConversionToUserTimeZone: PropTypes.bool,
    keepDatetimeValuesInUTC: PropTypes.bool,
    ignoreForceRightClearField: PropTypes.bool,
    forceNewPropValue: PropTypes.bool,
    tooltip: PropTypes.string,
    buttonClassName: PropTypes.string,
    hasSeconds: PropTypes.bool,
    allowSelectAll: PropTypes.bool,
    step: PropTypes.string,
    min: PropTypes.string,
    max: PropTypes.string,
    unSortData: PropTypes.bool,
    sortAttribute: PropTypes.string,
    labelPopup: PropTypes.object,
    labelIcon: PropTypes.object,
    labelIconTooltip: PropTypes.string
};

export default LabeledField;