import axios from "axios";
import _ from "lodash";
import {t} from "../utils/Translation";

let WidgetService = {
    InterfaceCategory: {
        TEMPERATURE: "TEMPERATURE",
    },

    widgetCategory: {
        INSTANT_READING: "INSTANT_READING",
        HISTORICAL_DATA: "HISTORICAL_DATA",
        OTHERS: "OTHERS"
    },

    WidgetType: {
        DIRECT_VALUE: "DIRECT_VALUE",
        MULTIPLE_INTERFACES: "MULTIPLE_INTERFACES",
        SIMPLE_MULTI_LINE_CHART: "SIMPLE_MULTI_LINE_CHART",
        COMPLEX_CHART: "COMPLEX_CHART",
        REGRESSION_CHART: "REGRESSION_CHART",
        SIMPLE_IMAGE: "SIMPLE_IMAGE",
        SIMPLE_SEARCH_TABLE: "SIMPLE_SEARCH_TABLE",
        STICKY_NOTE: "STICKY_NOTE",
        CONTROLLER: "CONTROLLER",
        IFRAME: "IFRAME",
        MAP: "MAP",
        WEATHER: "WEATHER",
        CONSUMPTION: "CONSUMPTION",
        SIMPLE_BATCH_INFO: "SIMPLE_BATCH_INFO",
        SILO: "SILO",
        MORTALITY_BY_CAUSE: "MORTALITY_BY_CAUSE",
        ANIMAL_WEIGHT: "ANIMAL_WEIGHT",
        COUNTER: "COUNTER",
        TITLE: "TITLE",
        GAUGE: "GAUGE",
        MILK: "MILK",
        SCORE_CARD: "SCORE_CARD",
        RULE_GROUPS: "RULE_GROUPS",
        BATCH_KPI_EVOLUTION: "BATCH_KPI_EVOLUTION"
    },

    WidgetDefaultSize: {
        DIRECT_VALUE: {h: 1, w: 3, minH: 1, maxH: 1, minW: 3, maxW: Infinity},
        GAUGE: {h: 2, w: 4, minH: 2, maxH: 2, minW: 4, maxW: 4},
        MILK: {h: 2, w: 12, minH: 2, maxH: Infinity, minW: 6, maxW: Infinity},
        SIMPLE_MULTI_LINE_CHART: {h: 3, w: 5, minH: 1, maxH: Infinity, minW: 2, maxW: Infinity},
        COMPLEX_CHART: {h: 3, w: 5, minH: 3, maxH: 3, minW: 2, maxW: Infinity},
        REGRESSION_CHART: {h: 3, w: 5, minH: 3, maxH: 3, minW: 2, maxW: Infinity},
        SIMPLE_IMAGE: {h: 2, w: 2, minH: 1, maxH: Infinity, minW: 1, maxW: Infinity},
        SIMPLE_SEARCH_TABLE: {h: 3, w: 12, minH: 3, maxH: Infinity, minW: 5, maxW: Infinity},
        MULTIPLE_INTERFACES: {h: 3, w: 5, minH: 1, maxH: Infinity, minW: 5, maxW: Infinity},
        STICKY_NOTE: {h: 1, w: 2, minH: 1, maxH: Infinity, minW: 1, maxW: Infinity},
        CONTROLLER: {h: 1, w: 3, minH: 1, maxH: 1, minW: 3, maxW: Infinity},
        IFRAME: {h: 3, w: 4, minH: 1, maxH: Infinity, minW: 2, maxW: Infinity},
        MAP: {h: 2, w: 4, minH: 2, maxH: Infinity, minW: 2, maxW: Infinity},
        WEATHER: {h: 2, w: 4, minH: 2, maxH: Infinity, minW: 3, maxW: Infinity},
        CONSUMPTION: {h: 2, w: 4, minH: 2, maxH: 2, minW: 4, maxW: Infinity},
        SIMPLE_BATCH_INFO: {h: 2, w: 4, minH: 2, maxH: 2, minW: 4, maxW: Infinity},
        SILO: {h: 2, w: 6, minH: 2, maxH: 2, minW: 5, maxW: Infinity},
        MORTALITY_BY_CAUSE: {h: 4, w: 4, minH: 3, maxH: 8, minW: 4, maxW: Infinity},
        ANIMAL_WEIGHT: {h: 4, w: 4, minH: 3, maxH: 8, minW: 4, maxW: Infinity},
        COUNTER: {h: 2, w: 2, minH: 2, maxH: 2, minW: 1, maxW: 2},
        TITLE: { h: 1, w: 2, minH: 1, maxH: 1, minW: 1, maxW: Infinity },
        SCORE_CARD: {h: 1, w: 3, minH: 1, maxH: 1, minW: 2, maxW: Infinity},
        RULE_GROUPS: {h: 2, w: 3, minH: 2, maxH: 2, minW: 3, maxW: 4},
        BATCH_KPI_EVOLUTION: {h: 3, w: 5, minH: 3, maxH: 3, minW: 3, maxW: Infinity},
    },

    WidgetConfigFieldType: {
        DISPLAY_NAME: "widgetDisplayName",
        INTERFACE_CATEGORY: "interfaceCategory",
        INTERFACE_SELECTOR: "interfaceSelector",
        CATEGORY_SELECTOR: "categorySelector",
        MULTIPLE_INTERFACES_SELECTOR: "multipleInterfacesSelector",
        MULTIPLE_INTERFACES_COLOR_CONFIG: "multipleInterfacesColorConfig",
        INTERFACE_SERIES_SELECTOR: "interfaceIds",
        CHART_WINDOW_RANGE: "chartWindowRange",
        DROPZONE: "imageDropzone",
        SEARCH_DATA_TYPE_SELECTOR: "searchDataTypeSelector",
        TEXT: "text",
        INTERFACE_ACTION: "interfaceAction",
        CHART_SERIES_SELECTOR: "chartSeriesSelector",
        REGRESSION_CHART_SERIES_SELECTOR: "regressionChartSeriesSelector",
        URL: "widgetUrl",
        FARM_SELECTOR: "farmSelector",
        LAST_EVENTS: "lastEvents",
        HEIGHT: "widgetHeight",
        EXPORT: "export",
        LOCATION_SEARCH: "widgetLocationOptions",
        INTERVAL_DEFINITION: "intervalDefinition",
        SLIDER: "widgetTransparency",
        IMAGE_LABEL: "imageLabel",
        BATCH_INFO_OPTION: "batchInfoOption",
        SPECIES_SELECTOR: "speciesSelector",
        GAUGE_CONFIG: "gaugeConfig",
        MILK_CONFIG: "milkConfig",
        SCORE_CARD_CONFIG: "scoreCardConfigField",
        RULE_GROUPS_CONFIG: "ruleGroupsConfigField",
        BATCH_SELECTOR: "batchSelector",
        BATCH_KPI_EVOLUTION_SELECTOR: "batchKPIEvolutionSelector",
    },

    WidgetConfigs: [
        "widgetDisplayName",
        "interfaceIds",
        "chartWindowRange",
        "interfaceCategory",
        "categorySelector",
        "imageDropzone",
        "text",
        "interfaceAction",
        "chartSeries",
        "interfaceAction",
        "widgetUrl",
        "widgetHeight",
        "farmSelector",
        "lastEvents",
        "widgetLocationOptions",
        "export",
        "intervalDefinition",
        "widgetError",
        "widgetTransparency",
        "imageLabel",
        "multipleInterfacesSelector",
        "multipleInterfacesColorConfig",
        "batchInfoOption",
        "speciesSelector",
        "batchSelector",
        "gaugeConfig",
        "milkConfig",
        "scoreCardConfigField",
        "ruleGroupsConfigField"
    ],

    widget: {
        weather : {
            days: [
                {id: '2', name: "2"},
                {id: '3', name: "3"},
                {id: '4', name: "4"},
                {id: '5', name: "5"},
                {id: '6', name: "6"},
                {id: '7', name: "7"}
            ],
            temperatureUnit: [
                {id: 'CELSIUS', name: "ºC"},
                {id: 'FAHRENHEIT', name: "ºF"}
            ],
            windSpeedUnit: [
                {id: 'KILOMETER_PER_HOUR', name: "km/h"},
                {id: 'BEAUFORT', name: "bft"},
                {id: 'METER_PER_SECOND', name: "m/s"},
                {id: 'MILE_PER_HOUR', name: "mph"},
                {id: 'KNOT', name: "kn"}
            ],
            precipitationUnit: [
                {id: 'MILLIMETER', name: "mm"},
                {id: 'INCH', name: "in"}
            ],
            color: [
                {id: 'monochrome', name: "Monochrome"},
                {id: 'coloured', name: "Coloured"}
            ]
        },
        simpleSearchTable: {
            dateTimeKeys: {
                lastLoginTo: 'lastLoginTo',
                lastLoginFrom: 'lastLoginFrom',
                lastReadingDateFrom: 'lastReadingDateFrom',
                lastReadingDateTo: 'lastReadingDateTo',
                dateFrom: 'dateFrom',
                dateTo: 'dateTo',
                toDate: 'toDate',
                fromDate: 'fromDate',
            }
        },
        simpleBatchInfo: {
            options: {
                mortality: "MORTALITY",
                open: "OPEN",
                averageDailyGain: "AVERAGE_DAILY_GAIN",
                conversionIndex: "CONVERSION_INDEX",
                averageWeightAnimalExists: "AVERAGE_WEIGHT_ANIMALS_EXISTS"
            }
        },
        animalWeight: {
            options: {
                totals: "TOTALS",
                perFarm: "PER_FARM"
            },
            weightGroups: [
                {id:"lessThan90", min: 0, max: 90, label: "< 90kg"},
                {id:"91to100", min: 90, max: 100, label: "91kg - 100kg"},
                {id:"101to110", min: 100, max: 110, label: "101kg - 110kg"},
                {id:"111to120", min: 110, max: 120, label: "111 - 120kg"},
                {id:"moreThan120", min: 120, max: 9999, label: "> 120kg"}
            ]
        },
        counter: {
            types: {
                RULES: "RULES",
                EVENTS: "EVENTS"
            }
        }
    },

    categoriesWithoutDecimalPlaces: [
        'PERCENTAGE',
        'VENTILATION_CONTROL',
        'HEATING_CONTROL',
        'ILUMINATION_CONTROL',
        'LANTERN_CONTROL',
        'WINDOW_CONTROL'
    ],

    getLastWidgetReadings: function (interfaceIds, onSuccess, onFailure) {
        onSuccess = onSuccess ? onSuccess : () => { };
        onFailure = onFailure ? onFailure : () => { };

        if (!_.isNil(interfaceIds) && !_.isEmpty(interfaceIds)) {
            axios.get("/widgets/last_readings?interfaceIds=" + interfaceIds).then(response => {
                onSuccess(response.data);
            }, onFailure);
        }
    },

    getChartWidgetData: function(widgetId, searchData, onSuccess, onFailure) {
        onSuccess = onSuccess ? onSuccess : () => { };
        onFailure = onFailure ? onFailure : () => { };

        if(!_.isNil(widgetId)) {
            axios.get("/widgets/chart_series_readings/" + widgetId, {
                params: searchData
            }).then(response => {
                onSuccess(response.data);
            }, onFailure);
        }
    },

    createWidget: function (widgetInfo, onSuccess, onFailure) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onFailure = onFailure ? onFailure : () => {};

        let formBox = new FormData();
        if (!_.isNil(widgetInfo.configs) && !_.isNil(widgetInfo.configs.imageDropzone)) {
            widgetInfo.configs.imageDropzone.forEach(file => {
                formBox.append('files', file);
            });
            widgetInfo.configs.imageDropzone = null;
        }
        formBox.append('request', new Blob([JSON.stringify(widgetInfo)], {type: "application/json"}));
        axios.post('/widgets',formBox).then((response) => {
            onSuccess(response.data);
        }, onFailure);
    },

    deleteWidget: function (dashboardId, widgetId, onSuccess, onFailure) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onFailure = onFailure ? onFailure : () => {};

        let url = _.isEmpty(dashboardId) ? '/widgets/' + widgetId  : '/widgets/' + dashboardId + "/" + widgetId;
        axios.delete(url).then(response => {
            onSuccess(response.data);
        }, onFailure);
    },

    duplicateWidget: function (dashboardId, widgetId, onSuccess, onFailure) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onFailure = onFailure ? onFailure : () => {};
        axios.post('/widgets/duplicate', {
            dashboardId: dashboardId,
            widgetId: widgetId
        }).then(response => {
            onSuccess(response.data);
        }, onFailure);
    },

    editWidget: function (widgetId, widgetInfo, onSuccess, onFailure) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onFailure = onFailure ? onFailure : () => {};

        let formBox = new FormData();
        if (!_.isNil(widgetInfo.configs) && !_.isNil(widgetInfo.configs.imageDropzone)) {
            widgetInfo.configs.imageDropzone.forEach(file => {
                formBox.append('files', file);
            });
            widgetInfo.configs.imageDropzone = null;
        }
        formBox.append('request', new Blob([JSON.stringify(widgetInfo)], {type: "application/json"}));
        axios.put('/widgets/'+ widgetId, formBox).then((response) => {
            onSuccess(response.data);
        }, onFailure);
    },

    getAllFarmEntities: function (onSuccess, onFailure) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onFailure = onFailure ? onFailure : () => {};

        axios.get("/entities?entityTypeNames=FARM").then(response => {
            onSuccess(response.data);
        }, onFailure);
    },

    getAllInterfaces: function (entityId, category, dataType, historyConfiguration, onSuccess, onFailure) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onFailure = onFailure ? onFailure : () => {};

        let searchData = {
            category: category,
            historyConfiguration: historyConfiguration,
            dataType: dataType
        };

        axios.get("/entities/" + entityId + "/interfaces", {
            params: searchData
        }).then(response => {
            onSuccess(response.data);
        }, onFailure);
    },

    getWidgetCategoryConfigs: function (categoryName, onSuccess, onFailure) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onFailure = onFailure ? onFailure : () => {};

        this.getAllWidgetCategories(categories => {
            let categoryConfig = null;
            categories.forEach(cat => {
                cat.widgets.forEach(widgetType => {
                    if (widgetType.type === categoryName) {
                        categoryConfig = widgetType;
                    }
                });
            });

            onSuccess(categoryConfig);
        }, onFailure);
    },

    actOnInterface: function (entityId, terminalId, interfaceId, interfaceActionValue, onSuccess, onFailure) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onFailure = onFailure ? onFailure : () => {};

        let url = '/interfaces/' + entityId + '/terminals/' + terminalId + '/interfaces/' + interfaceId + '/actuate';
        let body = {value: interfaceActionValue};

        axios.post(url, body).then(onSuccess, onFailure);
    },

    getWidgetPreviewImage: function (widgetId, imageName, onSuccess, onFailure) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onFailure = onFailure ? onFailure : () => {};

        axios.get("/static/widgets/image/" + widgetId + "/" + imageName, {
            responseType: 'arraybuffer'
        }).then(response => {
            onSuccess(response.data);
        }, onFailure);
    },

    getAsyncWeatherCitySearch: function (search) {
        search = encodeURIComponent(search.trim());
        return new Promise((accept, reject) => {
            axios.get("https://www.meteoblue.com/en/server/search/query3?query=" + search + "&orderBy=&itemsPerPage=10&page=1"
            ).then(response => {
                accept(response.data);
            }, reject);
        });
    },

    getBatchInfoOptions() {
        return [{
            id: WidgetService.widget.simpleBatchInfo.options.mortality,
            name: t("widgets.configurations.batchInfo.options.mortality")
        },  {
            id: WidgetService.widget.simpleBatchInfo.options.averageDailyGain,
            name: t("widgets.configurations.batchInfo.options.avg")
        }, {
            id: WidgetService.widget.simpleBatchInfo.options.conversionIndex,
            name: t("widgets.configurations.batchInfo.options.ci")
        }, {
            id: WidgetService.widget.simpleBatchInfo.options.averageWeightAnimalExists,
            name: t("widgets.configurations.batchInfo.options.averageWeightAnimalExists")
        }];
    },

    getSelectAll() {
        return {
            id: 'selectAll',
            name: t("widgets.configurations.weather.selectAll")
        };
    },

    getWeatherInfoOptions() {
        return [{
            id: 'pictogram',
            name: t("widgets.configurations.weather.pictogram")
        }, {
            id: 'temperatureMax',
            name: t("widgets.configurations.weather.temperatureMax")
        }, {
            id: 'temperatureMin',
            name: t("widgets.configurations.weather.temperatureMin")
        }, {
            id: 'windSpeed',
            name: t("widgets.configurations.weather.windSpeed")
        }, {
            id: 'windGust',
            name: t("widgets.configurations.weather.windGust")
        }, {
            id: 'windDirection',
            name: t("widgets.configurations.weather.windDirection")
        }, {
            id: 'uvIndex',
            name: t("widgets.configurations.weather.uvIndex")
        }, {
            id: 'relativeHumidity',
            name: t("widgets.configurations.weather.relativeHumidity")
        }, {
            id: 'precipitation',
            name: t("widgets.configurations.weather.precipitation")
        }, {
            id: 'precipitationProbability',
            name: t("widgets.configurations.weather.precipitationProbability")
        }, {
            id: 'rainSPOT',
            name: t("widgets.configurations.weather.rainSPOT")
        }, {
            id: 'pressure',
            name: t("widgets.configurations.weather.pressure")
        }];
    },

    getBatchInfoTitle() {
        return t("widgets.configurations.batchInfo.title");
    },

    getCounterInfoTitle() {
        return t("widgets.configurations.counter.title");
    },

    getCounterOptions() {
        return [{
            id: WidgetService.widget.counter.types.EVENTS,
            name: t("widgets.configurations.counter.types.events")
        }, {
            id: WidgetService.widget.counter.types.RULES,
            name: t("widgets.configurations.counter.types.rules")
        }];
    },

    getTitlePositionTitle() {
        return t("widgets.configurations.title.position.label");
    },

    getTitlePositions() {
        return [{
            id: "left",
            name: t("widgets.configurations.title.position.left")
        }, {
            id: "center",
            name: t("widgets.configurations.title.position.center")
        },{
            id: "right",
            name: t("widgets.configurations.title.position.right")
        }];
    },

    getTitleHasSeparator() {
        return [{
            id: "line",
            name: t("widgets.configurations.title.line.label")
        },];
    },

    getConsumptionAggregatorTitle() {
        return t("widgets.configurations.consumption.title");
    },

    getConsumptionAggregatorOptions() {
        return [{
            id: "AVG",
            name: t("widgets.configurations.consumption.types.avg")
        }, {
            id: "SUM",
            name: t("widgets.configurations.consumption.types.sum")
        }];
    },

    getAnimalWeightOptions() {
        return [{
            id: WidgetService.widget.animalWeight.options.totals,
            name: t("widgets.configurations.animalWeight.options.totals")
        }, {
            id: WidgetService.widget.animalWeight.options.perFarm,
            name: t("widgets.configurations.animalWeight.options.perFarm")
        }];
    },

    getAnimalWeightTitle() {
        return t("widgets.configurations.animalWeight.title");
    },

    getAllWidgetCategories: function (onSuccess = () => { }) {
        onSuccess([{
            name: "INTERFACES",
            widgets: [{
                type: "DIRECT_VALUE",
                configGroups: [{
                    configTypes: [{
                        required: true,
                        name: "interfaceSelector",
                        excludeInterfaceType: "OUT"
                    }]
                }, {
                    configTypes: [{
                        required: false,
                        name: "widgetDisplayName"
                    }]
                }, {
                    configTypes: [{
                        required: true,
                        name: "intervalDefinition"
                    }]
                }, {
                    configTypes: [{
                        key: 'min_interface',
                        required: true,
                        toggle: true,
                        isEnable: false,
                        headerLabel: t("widgets.configurations.showMinInterface"),
                        name: "interfaceSelector",
                        excludeInterfaceType: "OUT",
                        displayNameAction: false
                    }]
                }, {
                    configTypes: [{
                        key: 'max_interface',
                        required: true,
                        toggle: true,
                        isEnable: false,
                        headerLabel: t("widgets.configurations.showMaxInterface"),
                        name: "interfaceSelector",
                        excludeInterfaceType: "OUT",
                        displayNameAction: false
                    }]
                }]
            },{
                type: "SCORE_CARD",
                configGroups: [{
                    configTypes: [{
                        name: "scoreCardConfigField",
                        required: true,
                    }]
                },{
                    configTypes: [{
                        name: "widgetDisplayName",
                        required: true
                    }]
                }]
            }, {
                type: "GAUGE",
                configGroups: [{
                    configTypes: [{
                        required: true,
                        name: "interfaceSelector",
                        excludeInterfaceType: "OUT",
                        dataType: ["INTEGER", "DECIMAL"]
                    }]
                }, {
                    configTypes: [{
                        required: false,
                        name: "widgetDisplayName"
                    }]
                }, {
                    configTypes: [{
                        required: true,
                        name: "gaugeConfig"
                    }]
                }]
            }, {
                type: "CONTROLLER",
                configGroups: [{
                    configTypes: [{
                        name: "interfaceSelector",
                        excludeInterfaceType: "IN",
                        required: true,
                    }]
                }, {
                    configTypes: [{
                        name: "widgetDisplayName",
                        required: true
                    }]
                }]
            }, {
                type: "MULTIPLE_INTERFACES",
                configGroups: [{
                    configTypes: [{
                        required: true,
                        name: "widgetDisplayName"
                    }]
                }, {
                    configTypes: [{
                        required: true,
                        name: "multipleInterfacesSelector"
                    }]
                }, {
                    configTypes: [{
                        required: false,
                        name: "multipleInterfacesColorConfig"
                    }]
                }]
            }, {
                type: "CONSUMPTION",
                configGroups: [{
                    configTypes: [{
                        required: true,
                        name: "interfaceSelector",
                        excludeInterfaceType: "OUT",
                        fixedInterfaceCategory: ["POWER", "WATER_CONSUMPTION", "COOLING_CONSUMPTION", "GENERAL_CONSUMPTION", "HEATING_CONSUMPTION", "ILLUMINATION_CONSUMPTION",
                            "VENTILATION_CONSUMPTION", "WATER_PUMP_CONSUMPTION"],
                        chooseCategory: false
                    }]
                }, {
                    configTypes: [{
                        name: "batchInfoOption",
                        required: true,
                        title: WidgetService.getConsumptionAggregatorTitle(),
                        options: WidgetService.getConsumptionAggregatorOptions()
                    }]
                }, {
                    configTypes: [{
                        required: false,
                        name: "widgetDisplayName"
                    }]
                }, {
                    configTypes: [{
                        required: true,
                        name: "intervalDefinition",
                        percentageCheckboxIsVisible: false
                    }]
                }]
            }, {
                type: "SILO",
                configGroups: [{
                    configTypes: [{
                        key: 'weight_interface',
                        required: true,
                        name: "interfaceSelector",
                        excludeInterfaceType: "OUT",
                        fixedInterfaceCategory: ["SILO_WEIGHT"],
                        chooseCategory: false,
                        headerLabel: t("widgets.configurations.silo.options.weightInterface")
                    }]
                }, {
                    configTypes: [{
                        key: 'time_interface',
                        required: false,
                        name: "interfaceSelector",
                        excludeInterfaceType: "OUT",
                        fixedInterfaceCategory: ["FEED_LINE_WORKING_TIME", "VALVE_TIME", "EXPOSURE_TIME", "COOLING_TIME"],
                        chooseCategory: false,
                        headerLabel: t("widgets.configurations.silo.options.timeInterface")
                    }]
                }, {
                    configTypes: [{
                        required: false,
                        name: "widgetDisplayName"
                    }]
                }]
            }]
        }, {
            name: "DATA",
            widgets: [{
                type: "COMPLEX_CHART",
                configGroups: [{
                    configTypes: [{
                        name: "widgetDisplayName",
                        required: true
                    }]
                }, {
                    configTypes: [{
                        name: this.WidgetConfigFieldType.CHART_SERIES_SELECTOR,
                        required: true,
                    }]
                }, {
                    configTypes: [{
                        name: "export",
                        required: false
                    }]
                }]
            },{
                type: "REGRESSION_CHART",
                configGroups: [{
                    configTypes: [{
                        name: "widgetDisplayName",
                        required: true
                    }]
                }, {
                    configTypes: [{
                        name: this.WidgetConfigFieldType.REGRESSION_CHART_SERIES_SELECTOR,
                        required: true,
                    }]
                }]
            }, {
                type: "SIMPLE_SEARCH_TABLE",
                configGroups: [{
                    configTypes: [{
                        name: "widgetDisplayName",
                        required: true
                    }, {
                        name: "searchDataTypeSelector",
                        required: true
                    }],
                }]
            }]
        }, {
            name: "OTHERS",
            widgets: [
                {
                    type: "STICKY_NOTE",
                    configGroups: [{
                        configTypes: [{
                            name: "widgetDisplayName",
                            required: true
                        },
                        {
                            name: "text",
                            required: true
                        }]
                    }]
                },
                {
                    type: "WEATHER",
                    configGroups: [{
                        configTypes: [{
                            required: true,
                            name: "widgetDisplayName"
                        }]
                    },
                    {
                        configTypes: [{
                            required: true,
                            name: "widgetLocationOptions"
                        }]
                    }]
                },
                {
                    type: "MAP",
                    configGroups: [{
                        configTypes: [{
                            name: "widgetDisplayName",
                            required: true
                        }, {
                            name: "farmSelector",
                            required: false,
                            farmAndSector: true
                        }, {
                            name: "lastEvents",
                            required: true
                        }]
                    }]
                },
                {
                    type: "IFRAME",
                    configGroups: [{
                        configTypes: [{
                            name: "widgetUrl",
                            required: true
                        }]
                    }]
                },
                {
                    type: "SIMPLE_IMAGE",
                    configGroups: [{
                        configTypes: [{
                            name: "imageDropzone",
                            required: true
                        }]
                    }, {
                        configTypes: [{
                            required: false,
                            name: "widgetTransparency"
                        }, {
                            required: false,
                            name: "imageLabel"
                        }]
                    }]
                },
                {
                    type: "COUNTER",
                    configGroups: [{
                        configTypes: [{
                            name: "farmSelector",
                            unique : true,
                            required: true
                        }]}, {
                        configTypes: [{
                            name: "batchInfoOption",
                            required: true,
                            title: WidgetService.getCounterInfoTitle(),
                            options: WidgetService.getCounterOptions()
                        }]
                    }]
                },
            {
                type: "TITLE",
                configGroups: [{
                    configTypes: [{
                        name: "widgetDisplayName",
                        required: true
                    }]
                },{
                    configTypes: [{
                        key: 'position',
                        name: "batchInfoOption",
                        required: true,
                        title: WidgetService.getTitlePositionTitle(),
                        options: WidgetService.getTitlePositions()
                    }, {
                        key: 'line',
                        name: "batchInfoOption",
                        required: true,
                        title: "",
                        minSelected:0,
                        options: WidgetService.getTitleHasSeparator()
                    }]
                }]
            },
            {
                type: "MILK",
                configGroups: [{
                    configTypes: [{
                        name: "widgetDisplayName",
                        required: true
                    }]
                },{
                    configTypes: [{
                        key: 'position',
                        name: "milkConfig",
                        required: true,
                    }]
                }]
            },
            {
                type: "RULE_GROUPS",
                configGroups: [{
                    configTypes: [{
                        name: "ruleGroupsConfigField",
                        required: true,
                    }]
                }]
            }]
        },
            {
            name: "BATCHES",
            widgets: [{
                type: "SIMPLE_BATCH_INFO",
                configGroups: [{
                    configTypes: [{
                        name: "widgetDisplayName",
                        required: true
                    }]
                },
                    {
                    configTypes: [{
                        name: "speciesSelector",
                        required: false
                    }]
                }, {
                    configTypes: [{
                        name: "chartWindowRange",
                        required: true
                    }]
                }, {
                    configTypes: [{
                        name: "batchInfoOption",
                        required: true,
                        title: WidgetService.getBatchInfoTitle(),
                        options: WidgetService.getBatchInfoOptions()
                    }]
                }]
            },
                {
                type: "MORTALITY_BY_CAUSE",
                configGroups: [{
                    configTypes: [{
                        name: "widgetDisplayName",
                        required: true
                    }]
                }, {
                    configTypes: [{
                        name: "farmSelector",
                        required: false
                    }]
                }]
            },
                {
                type: "ANIMAL_WEIGHT",
                configGroups: [{
                    configTypes: [{
                        name: "widgetDisplayName",
                        required: true
                    }]
                }, {
                    configTypes: [{
                        name: "farmSelector",
                        required: false
                    }]
                }, {
                    configTypes: [{
                        name: "batchInfoOption",
                        required: true,
                        title: WidgetService.getAnimalWeightTitle(),
                        options: WidgetService.getAnimalWeightOptions()
                    }]
                }]},
                {
                    type: "BATCH_KPI_EVOLUTION",
                    configGroups: [{
                        configTypes: [{
                            name: "widgetDisplayName",
                            required: true
                        }]
                    },  {
                        configTypes: [{
                            name: "batchSelector",
                            required: true
                        }]
                    },{
                        configTypes: [{
                            name: this.WidgetConfigFieldType.BATCH_KPI_EVOLUTION_SELECTOR,
                            required: true,
                        }]
                    }]
                },
            ]
        }]);
    },

    groupValuesForOptions(values, options, groupByFarm) {
        let result = {};
        _.forEach(options, option => {
            result[option.id] = {
                option,
                animalNumber: 0
            };
        });
        if(groupByFarm) {
            result.entityTotals = {};
            _.forOwn(values, (animalsByWeightArray, entityName) => {
                let totalAnimalsForEntity = 0;
                result.entityTotals[entityName] = 0;
                _.forEach(animalsByWeightArray, animalsNumberAndWeight => {
                    totalAnimalsForEntity += animalsNumberAndWeight.animalNumber;
                    _.forOwn(result, (option, key) => {
                        if(key !== 'entityTotals') {
                            option.entities = option.entities? option.entities : {};
                            if(!option.entities[entityName]) {
                                option.entities[entityName] = 0;
                            }
                            if(animalsNumberAndWeight.animalWeight >= option.option.min && animalsNumberAndWeight.animalWeight < option.option.max) {
                                option.animalNumber += animalsNumberAndWeight.animalNumber;
                                option.entities[entityName] += animalsNumberAndWeight.animalNumber;
                                return;
                            }
                        }
                    })
                });
                result.entityTotals[entityName] = totalAnimalsForEntity;
            });
        } else {
            _.forOwn(values, (animalsByWeightArray, entityName) => {
                _.forEach(animalsByWeightArray, animalsNumberAndWeight => {
                    _.forOwn(result, option => {
                        if(animalsNumberAndWeight.animalWeight >= option.option.min && animalsNumberAndWeight.animalWeight < option.option.max) {
                            option.animalNumber += animalsNumberAndWeight.animalNumber;
                            return;
                        }
                    })
                });
            });
        }
        return result;
    }
};

export default WidgetService;