import React from "react";
import Row from "../../row/Row";
import LabeledField from "../labeled/LabeledField";
import {t} from "../../../utils/Translation";
import moment from "moment";
import {config} from "../../../config";
import _ from "lodash";
import PropTypes from 'prop-types';

class AddCycleForm extends React.Component {
    constructor(props) {
        super(props);

        this.cycleScheduleFieldRefs = {};

        this.state = {
            day: null,
            startDate: null
        };
    }

    setDay(day, afterDayIsSet){
        afterDayIsSet = afterDayIsSet? afterDayIsSet : () => {};
        this.setState({day: day}, () => {
            afterDayIsSet();
        });
    }

    getDay(){
        return this.state.day;
    }

    getStartTime(){
        return this.cycleScheduleFieldRefs.startTime.getFieldValue();
    }

    recalcStartDate(){
        calcStartDate(this);
    }

    setStartDate(date, afterDateIsSet) {
        afterDateIsSet = afterDateIsSet? afterDateIsSet : () => {};
        this.setState({startDate: date}, () => {
            afterDateIsSet();
        });
    }

    render() {
        let self = this;
        return (
            <div className="ml-total-15">
                <h3>{t("generic.cyclegroup.addCycle")}</h3>
                <Row className='pd-t-15'>
                    <LabeledField
                        fieldLabel={t('generic.cyclegroup.fields.day.label')}
                        fieldPlaceHolder={t('generic.cyclegroup.fields.day.placeholder')}
                        fieldType='text'
                        width='4'
                        labelWidth='150px'
                        onBlur={() => updateDate(self)}
                        validator={params => validateDay(params)}
                        ref={c => {self.cycleScheduleFieldRefs.day = c}}
                    />
                    <LabeledField
                        fieldLabel={t('generic.cyclegroup.fields.startDate.label')}
                        fieldPlaceHolder={t('generic.cyclegroup.fields.startDate.placeholder')}
                        fieldType='dateTime'
                        hasTime={false}
                        hasDate={true}
                        isView={true}
                        width='4'
                        labelWidth='150px'
                        ref={c => {self.cycleScheduleFieldRefs.startDate = c}}
                    />
                    <LabeledField
                        fieldLabel={t('generic.cyclegroup.fields.startTime.label')}
                        fieldType='dateTime'
                        hasDate={false}
                        hasTime={true}
                        keepDatetimeValuesInUTC={true}
                        width='4'
                        labelWidth='150px'
                        ref={c => {self.cycleScheduleFieldRefs.startTime = c}}
                    />
                </Row>
                {createFormButtons(self)}
            </div>
        );
    }
}

function updateDate(component){
    let newDay = component.cycleScheduleFieldRefs.day.getFieldValue();
    if(isValidNewDay(component, newDay)){
        component.setDay(newDay, () => {
            component.recalcStartDate();
        });
    }
}

function calcStartDate(component){
    let auxDate = component.props.firstDayCycle.clone();
    if(!_.isEmpty(auxDate)) {
        auxDate.add(_.parseInt(component.state.day)-1, 'd');
        component.setStartDate(auxDate, () => {
            component.cycleScheduleFieldRefs.startDate.setFieldValue(auxDate);
        });
    }
}

function validateDay(params) {
    let newDay = params[0];
    let refDay = params[1];

    let regex = config.generic.positiveornegativenumberregex;
    let errorMessage = '';

    if(!regex.test(newDay)) {
        errorMessage = t('errors.generic.notANumber');
    }
    if(_.isEmpty(errorMessage) && !_.isNaN(refDay) && newDay >= refDay){
        errorMessage = t('errors.generic.notLessThan', {max: refDay});
    }
    if(_.isEmpty(errorMessage) && newDay < 1){
        errorMessage = t('errors.generic.notGreaterThan', {min: 0});
    }
    return errorMessage;
}

function isValidNewDay(component, newDay) {
    let lastDay = component.props.lastDay;
    let regex = config.generic.numberregex;

    if(regex.test(newDay) && newDay > 0 && (_.isNaN(lastDay) || newDay < lastDay)){
        component.cycleScheduleFieldRefs.day.validateField(newDay, lastDay);
        return true;
    } else {
        component.cycleScheduleFieldRefs.day.validateField(newDay, lastDay);
        return false;
    }
}

function createFormButtons(component) {
    return (
        <Row>
            <div className="col-lg-5 col-lg-offset-7 pd-r-25">
                <div className="pull-right">
                    <button type="button" onClick={() => onFormCancel(component)} className="btn btn-buffer btn-white">{t('generic.cancel')}</button>
                    <button type="button" disabled={!component.getDay()} onClick={() => onFormSubmit(component)} className="btn btn-bold btn-buffer btn-success">{t("generic.cyclegroup.addCycle")}</button>
                </div>
            </div>
        </Row>
    );
}

function onFormCancel(component) {
    component.props.cycleGroup.addCycleModal.hideModal();
}

function onFormSubmit(component) {
    component.props.cycleGroup.addNewCycle(component.getDay(), component.getStartTime() || moment().startOf('day'));
    component.props.cycleGroup.addCycleModal.hideModal();
}

AddCycleForm.propTypes = {
    lastDay: PropTypes.number,
    preCycleStartDate: PropTypes.instanceOf(moment),
    cycleGroup: PropTypes.object
};

export default AddCycleForm;