import axios from "axios";
import _ from "lodash";
import EntityService from "./EntityService";
import UserSessionService from "./UserSessionService";
import RuleCacheService from "./RuleCacheService";
import UserService from "./UserService";
import EventService from "./EventService";
import DialCodesUtils from "../utils/DialCodesUtils";
import LocalesUtils from "../utils/LocalesUtils";
import __ from "../utils/EnhancedLodash";
import {t} from "../utils/Translation";
import EntityUtils from "../utils/EntityUtils";
import InterfaceService from "./InterfaceService";

let RuleService = {

    templateFieldOptions: {
        fieldWidth: "6"
    },

    weekdays: {
        withWeekend: [
            {id: 'sun', name: t('rules.form.tabs.weekdays.sun')},
            {id: 'mon', name: t('rules.form.tabs.weekdays.mon')},
            {id: 'tue', name: t('rules.form.tabs.weekdays.tue')},
            {id: 'wed', name: t('rules.form.tabs.weekdays.wed')},
            {id: 'thu', name: t('rules.form.tabs.weekdays.thu')},
            {id: 'fri', name: t('rules.form.tabs.weekdays.fri')},
            {id: 'sat', name: t('rules.form.tabs.weekdays.sat')},
        ],
        selectAll: () => {
            return {
                id: 'select_all',
                name: t('generic.selectAll')
            }
        }
    },

    dataType: {
        BOOLEAN: 'BOOLEAN',
        STRING: 'STRING',
        DECIMAL: 'DECIMAL',
        INTEGER: 'INTEGER'
    },

    logicOperator: {
        BETWEEN: 'BETWEEN',
        EQUAL: 'EQUAL',
        strings: {
            EQUAL: t('generic.logicOperations.equal')
        }
    },

    actionType: {
        INPUT: 'INPUT',
        OUTPUT: 'OUTPUT'
    },

    deploymentStatus: {
        DEPLOYED: 'DEPLOYED',
        UNDEPLOYED: 'UNDEPLOYED',
        ERROR: 'ERROR',
        PENDING_DEPLOY: 'PENDING_DEPLOY',
        PENDING_UNDEPLOY: 'PENDING_UNDEPLOY',
        ENABLED: 'ENABLED',
        DISABLED: 'DISABLED'
    },

    templates: {
        cloudAlarm: 'global-template',
        alarmTemplate: 'template-act-if-condition-evaluate-instant-measurement-during-time',
        scheduler: {
            forced: "template-scheduler-forced",
            normal: "template-scheduler"
        }
    },

    categories:{
        cycleThermostat: 'SENSORIAL_RULES_CYCLE_THERMOSTAT',
        cycleDoubleThermostat: 'SENSORIAL_RULES_CYCLE_DOUBLE_THERMOSTAT',
        cycleProportional: 'SENSORIAL_RULES_CYCLE_PROPORTIONAL',
        sensorialRulesThermostat: 'SENSORIAL_RULES_THERMOSTAT',
        sensorialRulesDoubleThermostat: 'SENSORIAL_RULES_DOUBLE_THERMOSTAT',
        sensorialRulesProportional: 'SENSORIAL_RULES_PROPORTIONAL',
        alarmWithoutActuation: 'ALARM_WITHOUT_ACTUATION'
    },

    expandedGroup: {
        NO_GROUP : "NO_GROUP"
    },

    parseRuleOperationPremiseToCloud(operation) {
        switch (operation) {
            case "GREATER":
                return "GREATER";
            case "GREATEROREQUAL":
                return "GREATER_EQUAL";
            case "MINOR":
                return "LESS";
            case "MINOROREQUAL":
                return "LESS_EQUAL";
            case "EQUAL":
                return "EQUAL";
            case "NOTEQUAL":
                return "NOT_EQUAL";
            default:
                return operation;
        }
    },

    parseRuleOperationCloudToOnPremise(operation) {
        switch (operation) {
            case "GREATER":
                return "GREATER";
            case "GREATER_EQUAL":
                return "GREATEROREQUAL";
            case "LESS":
                return "MINOR";
            case "LESS_EQUAL":
                return "MINOROREQUAL";
            case "EQUAL":
                return "EQUAL";
            case "NOT_EQUAL":
                return "NOTEQUAL";
            default:
                return operation;
        }
    },

    loadRules: function (searchData, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        axios.get('/rules/search', {
            params: searchData
        }).then((response) => {
            let data = response.data;
            _.forEach(data.content, lineData => {
                lineData.entity = {
                    rootEntity: lineData.rootEntity,
                    sector: lineData.sector
                };
                lineData.ruleTemplate.name = LocalesUtils.getLocaleString(lineData.ruleTemplate.name);
                lineData.status = {
                    isGlobalRule: _.isNil(lineData.terminal),
                    deploymentStatus : {
                        status: lineData.deploymentStatus,
                        message: lineData.deploymentMessage
                    },
                    status: lineData.status
                }
            });
            onSuccess(data);
        }, (error) => {
            onError(error);
        });
    },

    getInterfaceRules: function (interfaceId, search, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};
        axios.get('/rules/interface/' + interfaceId, {params:search}).then((response) => {
            onSuccess(response.data);
        }, (error) => {
            onError(error);
        });
    },

    countRules: function (searchData, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        axios.get('/rules/count', {
            params: searchData
        }).then((response) => {
                onSuccess(response.data);
            },
            (error) => {
                onError(error);
            }
        );
    },

    getRuleDetails: function (ruleId, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        axios.get('/rules/' + ruleId).then((response) => {
            response.data.ruleTemplate = RuleService.filterTemplateWithCurrentLocale(response.data.ruleTemplate);
            response.data.inputs = RuleService.getListFromString(response.data.inputs);
            RuleService.loadDataToCacheFromRuleTemplateAndInputs(response.data,
                () => {
                    response.data.eventCategoryList = RuleCacheService.getEventCategories();
                    response.data.eventSectorList = EntityUtils.filterEntitiesOfType(RuleCacheService.getSectorsOfFarm(response.data.rootEntity.id), EntityService.entityType.SECTOR.name);
                    onSuccess(response.data);
                },
                error => {
                    throw new Error(error);
                },
                true
            );
        }, error => {
            onError(error);
        });
    },

    createRule: function (ruleData, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};
        axios.post('/rules/', ruleData).then((response) => {
            onSuccess(response.data);
        }, (error) => {
            onError(error);
        });
    },

    duplicateRule: function (ruleId, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        axios.post('/rules/duplicate',{'ruleId': ruleId}).then((response) => {
            onSuccess(response.data);
        }, (error) => {
            onError(error);
        });
    },

    editRule: function (ruleId, ruleData, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        axios.put('/rules/' + ruleId, ruleData).then((response) => {
            onSuccess(response.data);
        }, (error) => {
            onError(error);
        });
    },

    editRuleNames: function (ruleData, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        axios.put('/rules/name', ruleData).then((response) => {
            onSuccess(response.data);
        }, (error) => {
            onError(error);
        });
    },

    editRuleInputs: function (ruleId, ruleData, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        axios.put('/rules/' + ruleId + '/inputs', ruleData).then((response) => {
            onSuccess(response.data);
        }, (error) => {
            onError(error);
        });
    },

    deleteRule: function (ruleId, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        axios.delete('/rules/' + ruleId).then((response) => {
            onSuccess(response.data);
        }, (error) => {
            onError(error);
        });
    },

    undeployRule: function (ruleId, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => { };
        onError = onError ? onError : () => { };

        axios.put('/rules/' + ruleId + '/undeploy').then((response) => {
            onSuccess(response.data);
        }, (error) => {
            onError(error);
        });
    },

    getListFromString(string) {
        return JSON.parse(string);
    },

    getTerminalsFromEntity: function (entityId, useCache, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        let cachedInfo = RuleCacheService.getTerminalsOfEntity(entityId);

        if(useCache && cachedInfo) {
            onSuccess(cachedInfo);
        } else {
            EntityService.getEntityTerminalsList(entityId, {},terminalList => {
                RuleCacheService.storeTerminalsOfEntity(entityId, terminalList);
                onSuccess(terminalList);
            }, onError);
        }
    },

    getTerminalsFromEntityAndRule: function (entityId, ruleProfile, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        let cachedInfo = RuleCacheService.getTerminalsOfEntity(entityId);
        if(cachedInfo) {
            onSuccess(cachedInfo);
        } else {
            let filter = {}
            if(!__.isNilOrEmpty(ruleProfile)) {
                filter = {terminalProfile: ruleProfile.map(r => r.value)}
            }
            EntityService.getEntityTerminalsList(entityId, filter, terminalList => {
                onSuccess(terminalList);
            }, error => {
                onError(error);
            });
        }
    },

    getTemplateList: function (entityId, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};
        if(UserSessionService.getRuleTemplateListForEntity(entityId? entityId.entityId : null)) {
            onSuccess(UserSessionService.getRuleTemplateListForEntity(entityId? entityId.entityId : null));
        } else {
            axios.get('/rules/template', {params: entityId})
                .then(response => {
                    let templateListWithCurrentLocale = [];
                    let templateAux = {};
                    response.data.forEach(template => {
                        templateAux = template;
                        templateAux = RuleService.filterTemplateWithCurrentLocale(templateAux);
                        templateListWithCurrentLocale.push(templateAux);
                    });
                    UserSessionService.storeRuleTemplateListForEntity(entityId? entityId.entityId : null, templateListWithCurrentLocale);
                    onSuccess(templateListWithCurrentLocale);
                }, (error) => {
                    onError(error);
                });
        }
    },

    getRuleTemplate: function (template, onSuccess, onError){
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        axios.get('/rules/template/' + template.id)
            .then(response => {
                let templateWithCurrentLocale = RuleService.filterTemplateWithCurrentLocale(response.data);
                onSuccess(templateWithCurrentLocale);
            }, error => {
                onError(error);
            });
    },

    getDeploymentStatus: function () {
        return {
            DEPLOYED: t('rules.deploymentStatus.deployed'),
            UNDEPLOYED: t('rules.deploymentStatus.undeployed'),
            ERROR: t('rules.deploymentStatus.error'),
            PENDING_DEPLOY: t('rules.deploymentStatus.pendingDeploy'),
            PENDING_UNDEPLOY: t('rules.deploymentStatus.pendingUndeploy'),
            ENABLED: t("generic.active"),
            DISABLED: t("generic.inactive")
        }
    },

    getDeploymentStatusOptions: function () {
        return [{
            id: 'DEPLOYED',
            name: t('rules.list.searchBox.fields.deploymentStatus.deployed')
        }, {
            id: 'UNDEPLOYED',
            name: t('rules.list.searchBox.fields.deploymentStatus.undeployed')
        }, {
            id: 'ERROR',
            name: t('rules.list.searchBox.fields.deploymentStatus.error')
        }, {
            id: 'PENDING_DEPLOY',
            name: t('rules.list.searchBox.fields.deploymentStatus.pendingDeploy')
        }, {
            id: 'PENDING_UNDEPLOY',
            name: t('rules.list.searchBox.fields.deploymentStatus.pendingUndeploy')
        }];
    },

    getWeekdays: function () {
        return [
            { id: 'sun', name: t('rules.form.tabs.weekdays.sun'), disabled: false },
            { id: 'mon', name: t('rules.form.tabs.weekdays.mon'), disabled: false },
            { id: 'tue', name: t('rules.form.tabs.weekdays.tue'), disabled: false },
            { id: 'wed', name: t('rules.form.tabs.weekdays.wed'), disabled: false },
            { id: 'thu', name: t('rules.form.tabs.weekdays.thu'), disabled: false },
            { id: 'fri', name: t('rules.form.tabs.weekdays.fri'), disabled: false },
            { id: 'sat', name: t('rules.form.tabs.weekdays.sat'), disabled: false }
        ];
    },

    getSelectAll: function () {
        return { id: 'select_all', name: t('rules.form.tabs.weekdays.selectAll'), disabled: false };
    },

    getTranslationForDeploymentStatus: function (status) {
        switch (status) {
            case RuleService.deploymentStatus.DEPLOYED:
                return RuleService.getDeploymentStatus().DEPLOYED;
            case RuleService.deploymentStatus.UNDEPLOYED:
                return RuleService.getDeploymentStatus().UNDEPLOYED;
            case RuleService.deploymentStatus.ERROR:
                return RuleService.getDeploymentStatus().ERROR;
            case RuleService.deploymentStatus.PENDING_DEPLOY:
                return RuleService.getDeploymentStatus().PENDING_DEPLOY;
            case RuleService.deploymentStatus.PENDING_UNDEPLOY:
                return RuleService.getDeploymentStatus().PENDING_UNDEPLOY;
            default:
                return "";
        }
    },

    getDeployedStatusCircle: function  (status) {
        let circleClass = "";
        switch(status) {
            case RuleService.deploymentStatus.DEPLOYED:
            case RuleService.deploymentStatus.ENABLED:
                circleClass = "fa fa-lg fa-fw fa-circle c-green";
                break;
            case RuleService.deploymentStatus.UNDEPLOYED:
            case RuleService.deploymentStatus.DISABLED:
                circleClass = "fa fa-lg fa-fw fa-circle c-gray";
                break;
            case RuleService.deploymentStatus.ERROR:
                circleClass = "fa fa-lg fa-fw fa-circle-o c-red";
                break;
            case RuleService.deploymentStatus.PENDING_DEPLOY:
            case RuleService.deploymentStatus.PENDING_UNDEPLOY: {
                circleClass = "fa fa-lg fa-fw fa-circle c-orange";
                break;
            }
            default:
                break;
        }
        return circleClass;
    },

    getInterfacesWithCategory: function (selectedEntityId, selectedTerminalId, interfaceCategory, otherFilters, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        if(_.isNil(selectedTerminalId)) {
            EntityService.getEntityInterfaces(selectedEntityId, otherFilters, interfaces => {
                onSuccess(RuleService.filterInterfacesWithCategory(interfaces, interfaceCategory))
            }, error => {
                onError(error)
            });
        } else {
            let cachedInfo = RuleCacheService.getInterfacesOfTerminal(selectedTerminalId);
            if(cachedInfo) { //before: if(cachedInfo && !otherfilters)
                //If 'otherFilters' exists, filter them (before this commit, the else clause was run instead)
                setTimeout(() => onSuccess(RuleService.filterInterfacesWithCategory(cachedInfo, interfaceCategory, otherFilters)), 10);
            } else {
                EntityService.getEntityTerminalInterfacesList(selectedEntityId, selectedTerminalId, otherFilters, interfaces => {
                    interfaces = _.map(interfaces, iface => {
                        let namePrefix = iface.entity.farmOfSector ?
                            iface.entity.farmOfSector.displayName + " | " + iface.entity.displayName :
                            iface.entity.displayName;
                        iface.displayName = namePrefix + " - " + iface.displayName;
                        return iface;
                    });
                    if (!otherFilters) {
                        RuleCacheService.storeInterfacesOfTerminal(selectedTerminalId, interfaces);
                    }
                    onSuccess(RuleService.filterInterfacesWithCategory(interfaces, interfaceCategory));
                }, error => {
                    onError(error)
                });
            }
        }
    },

    filterInterfacesWithCategory(interfaces, category, otherFilters) {
        let result = interfaces;
        if (!_.isNil(category)) {
            result = _.filter(interfaces, interf => {
                return (_.isArray(category) && _.includes(category, interf.category.id))
                    || (_.isString(category) &&  category === interf.category.id);
            });
        }
        if(!_.isNil(otherFilters)) {
            if(otherFilters.interfaceId) {
                if(Array.isArray(otherFilters.interfaceId)){
                    result = interfaces.filter(inter => {
                        const interfaceParts = inter.interfaceId.split(".")
                        if(interfaceParts && interfaceParts.length > 1){
                            return otherFilters.interfaceId.includes(interfaceParts[interfaceParts.length - 1])
                        }else{
                            return false
                        }
                    })
                }else{
                    result = _.filter(interfaces, interf => _.endsWith(interf.interfaceId, ("." + otherFilters.interfaceId)));
                }
            }
        }
        return result;
    },

    getInterfacesFromTerminal(terminal, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        let cachedInfo = RuleCacheService.getInterfacesOfTerminal(terminal.id);

        if(cachedInfo) {
            onSuccess(cachedInfo);
        } else {
            EntityService.getEntityTerminalInterfacesList(terminal.rootEntity.id, terminal.id, null, interfaces => {
                interfaces = _.map(interfaces, iface => {
                    if (iface.category.id === InterfaceService.categories.CONNECTION_STATUS) {
                        iface.displayName = iface.terminal.displayName + " - " + iface.displayName;
                        return iface
                    }

                    let namePrefix = iface.entity.farmOfSector ?
                        iface.entity.farmOfSector.displayName + " | " + iface.entity.displayName :
                        iface.entity.displayName;
                    iface.displayName = namePrefix + " - " + iface.displayName;
                    return iface;
                });
                RuleCacheService.storeInterfacesOfTerminal(terminal.id, interfaces);
                onSuccess(interfaces)
            }, error => {
                onError(error)
            });
        }
    },

    filterTemplateWithCurrentLocale: function (originalTemplate) {
        let templateAux = originalTemplate;
        templateAux = LocalesUtils.filterLocalesOfObject(templateAux);
        if(!_.isNil(templateAux.groups) && !_.isEmpty(templateAux.groups)) {
            _.forOwn(templateAux.groups, (group, key) => {
                group = LocalesUtils.filterLocalesOfObject(group);
                if(!_.isNil(group.inputs) && !_.isEmpty(group.inputs)) {
                    _.forOwn(group.inputs, (input, key) => {
                        input = RuleService.filterLocalesOfInputs(input);
                    });
                }
            });
        }
        return templateAux;
    },

    filterLocalesOfInputs: function (input) {
        let inputAux = input;
        if(!_.isNil(inputAux.name)){
            inputAux.name = LocalesUtils.getLocaleString(inputAux.name);
        }
        if(!_.isNil(inputAux.description)){
            inputAux.description = LocalesUtils.getLocaleString(inputAux.description);
        }
        if(!_.isNil(inputAux.help)){
            inputAux.help = LocalesUtils.getLocaleString(inputAux.help);
        }
        if(!_.isNil(inputAux.inputContext) && !_.isNil(inputAux.inputContext.inputs) && !_.isEmpty(inputAux.inputContext.inputs)){
            _.forOwn(inputAux.inputContext.inputs, (input, key) => {
                input = RuleService.filterLocalesOfInputs(input);
            });
        } else {
            return inputAux;
        }
    },

    getActiveFarms: function (onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        let cachedInfo = RuleCacheService.getActiveFarmEntities();

        if(cachedInfo) {
            onSuccess(cachedInfo);
        } else {
            EntityService.getActiveEntityList({
                    entityTypeNames: [EntityService.entityType.FARM.name],
                    entityStatus: EntityService.entityStatus.ACTIVE
                },
                entities=> {
                    RuleCacheService.storeActiveFarmEntities(entities);
                    onSuccess(entities);
                },
                onError);
        }
    },

    getLogicOperationsForDataType(dataType, unwantedDataTypes, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        let cachedInfo = RuleCacheService.getLogicalOperationsForDataType(dataType);

        if(cachedInfo) {
            onSuccess(cachedInfo);
        } else {
            dataType = {dataType: dataType};

            axios.get('/rules/logicOperations', {
                params: dataType
            }).then((response) => {
                let dataTypes = response.data.sort();
                if(!_.isNil(unwantedDataTypes) && !_.isEmpty(unwantedDataTypes)) {
                    _.forEach(unwantedDataTypes, dataTypeToRemove => {
                        _.remove(dataTypes, dataType => {
                            return dataType === dataTypeToRemove;
                        })
                    });
                }
                let results = [];
                _.forEach(dataTypes, logOpString => {
                    results.push(RuleService.getObjectFor(logOpString, RuleService.getLogicalOperationName(logOpString)));
                });
                RuleCacheService.storeLogicalOperationsForDataType(dataType.dataType, results);
                onSuccess(results);
            }, error => {
                onError(error);
            });
        }
    },

    loadDataToCacheFromRuleTemplateAndInputs(ruleData, afterDataIsLoaded) {
        afterDataIsLoaded = afterDataIsLoaded? afterDataIsLoaded : () => {};
        let ruleTemplate = ruleData.ruleTemplate;
        let inputs = ruleData.inputs;

        if(!__.isNilOrEmpty(ruleTemplate.profiles)) {
            RuleService.getActiveFarms(
                () => {
                    let filter = {}
                    if(!__.isNilOrEmpty(ruleTemplate.profiles)) {
                        filter = {terminalProfile: ruleTemplate.profiles.map(r => r.value)}
                    }
                    EntityService.getEntityTerminalsList(ruleData.rootEntity.id, filter, terminalList => {
                        RuleCacheService.storeTerminalsOfEntity(ruleData.rootEntity.id, terminalList);
                    }, error => {
                        console.error(error)
                    });
                    RuleService.getInterfacesFromTerminal( ruleData.terminal, () => {
                            if(ruleTemplate.hasEvent) {
                                RuleService.loadEventDataToCache(ruleData.rootEntity.id, () => afterDataIsLoaded());
                            } else {
                                afterDataIsLoaded();
                            }
                        },
                        () => {
                            throw new Error("It was not possible to fetch the interface list for the selected terminal");
                        });
                },
                () => {
                    throw new Error("It was not possible to fetch for active farms in Rule Edit");
                }
            );
        } else {
            RuleService.getActiveFarms(
                () => {
                    RuleService.getTerminalsFromEntity(
                        ruleData.rootEntity.id,
                        true,
                        terminals => {
                            let terminalsInRuleInputs = RuleService.getUniqueTerminalIdsFromGlobalRuleInputs(inputs);
                            let counter = 0;
                            for (let i = 0; i < terminalsInRuleInputs.length; ++i) {
                                let terminalObj = _.find(terminals, terminal => {return terminal.id === terminalsInRuleInputs[i];});
                                if(terminalObj) {
                                    RuleService.getInterfacesFromTerminal(
                                        terminalObj,
                                        // eslint-disable-next-line
                                        () => {
                                            if(++counter >= terminalsInRuleInputs.length) {
                                                RuleService.getLogicalOperationsFromInputs(inputs,
                                                    () => {
                                                        RuleService.loadEventDataToCache(ruleData.rootEntity.id, () => afterDataIsLoaded());
                                                    }
                                                );
                                            }
                                        },
                                        () => {
                                            throw new Error("It was not possible to fetch the interface list for terminal " + terminalObj.id);
                                        }
                                    );
                                }
                            }
                        },
                        () => {
                            throw new Error("It was not possible to fetch terminals of entity in Rule Edit");
                        }
                    );
                },
                () => {
                    throw new Error("It was not possible to fetch active farms in Rule Edit");
                }
            );
        }
    },

    loadEventDataToCache(entityId, afterEventDataIsGathered) {
        afterEventDataIsGathered = afterEventDataIsGathered? afterEventDataIsGathered : () => {};
        UserService.getActiveUsersList(
            () => {
                EventService.getEventCategories(
                    () => {
                        EntityService.getEntitySectorsList(
                            entityId,
                            () => {
                                DialCodesUtils.getDefaultDialCode();
                                afterEventDataIsGathered();
                            },
                            () => {
                                throw new Error("It was not possible to fetch for sectors in Rule Edit");
                            }
                        );
                    },
                    () => {
                        throw new Error("It was not possible to fetch event categories on Rule Edit");
                    }
                );
            },
            () => {
                throw new Error("It was not possible to fetch active users on Rule Edit");
            },
            true
        );
    },

    getUniqueTerminalIdsFromGlobalRuleInputs(globalRuleInputs) {
        let uniqueTerminalIds = [];
        let dropgroupInput = _.find(globalRuleInputs, input => { return input.id === 'dropgroup-input'; });
        let dropgroupOutput = _.find(globalRuleInputs, input => { return input.id === 'dropgroup-output'; });

        if(dropgroupInput) {
            _.forEach(dropgroupInput.value, line => {
                let terminalIndex = _.findIndex(uniqueTerminalIds, terminalId => {return line.terminalId === terminalId});
                if(terminalIndex === -1) {
                    uniqueTerminalIds.push(line.terminalId);
                }
            });
        }

        if(dropgroupOutput) {
            _.forEach(dropgroupOutput.value, line => {
                let terminalIndex = _.findIndex(uniqueTerminalIds, terminalId => {return line.terminalId === terminalId});
                if(terminalIndex === -1) {
                    uniqueTerminalIds.push(line.terminalId);
                }
            });
        }

        return uniqueTerminalIds;

    },

    getLogicalOperationsFromInputs(inputs, afterLogicalOperationsAreSet) {
        afterLogicalOperationsAreSet = afterLogicalOperationsAreSet? afterLogicalOperationsAreSet : () => {};

        let logicalOperations = [];
        let interfaceIdsFromInputs = [];
        let dropgroupInput = _.find(inputs, input => { return input.id === 'dropgroup-input'; });

        if(dropgroupInput) {
            _.forEach(dropgroupInput.value, line => {
                let interfaceIdIndex = _.findIndex(interfaceIdsFromInputs, id => {return line.interfaceId === id});
                if(interfaceIdIndex === -1) {
                    interfaceIdsFromInputs.push(line.interfaceId);
                    let terminalsInterfaces = RuleCacheService.getInterfacesOfTerminal(line.terminalId);
                    let ifaceObj = _.find(terminalsInterfaces, iface => { return iface.id === line.interfaceId; });
                    if(!_.isNil(ifaceObj)){
                        let logicOperationIndex = _.findIndex(logicalOperations, op => {return op === ifaceObj.terminalInterfaceDataType;});
                        if(logicOperationIndex === -1) {
                            logicalOperations.push(ifaceObj.terminalInterfaceDataType);
                        }
                    }
                }
            });
        }
        if(!_.isEmpty(logicalOperations)){
            let counterAux = 0;
            for (let i = 0; i < logicalOperations.length; ++i) {
                RuleService.getLogicOperationsForDataType(
                    logicalOperations[i],
                    [RuleService.logicOperator.BETWEEN],
                    // eslint-disable-next-line
                    () => {
                        if (++counterAux >= logicalOperations.length) {
                            afterLogicalOperationsAreSet();
                        }
                    },
                    () => {
                        throw new Error("It was not possible to fetch logical operations for DataType " + logicalOperations[i] + " on Rule Edit");
                    }
                );
            }
        } else {
            afterLogicalOperationsAreSet();
        }

    },

    getLogicalOperationName(name) {
        return t('generic.logicOperations.' + _.toLower(name));
    },

    getObjectFor(id, name) {
        return {id: id, name: name};
    },

    quickDeployRules : function (ruleIds, onSuccess=() => { }, onError=() => { }) {
        axios.put('/rules/deploy', ruleIds).then((response) => {
            onSuccess(response.data);
        }, (error) => {
            onError(error);
        });
    },

    quickUndeployRules(ruleIds, onSuccess=() => { }, onError=() => { }) {
        axios.put('/rules/undeploy', ruleIds).then((response) => {
            onSuccess(response.data);
        }, (error) => {
            onError(error);
        });
    },

    createAlarmRule: function (ruleData, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        axios.post('/rules/bulk', ruleData).then((response) => {
            onSuccess(response.data);
        }, (error) => {
            onError(error);
        });
    },

    editAlarmRule: function (ruleData, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        axios.put('/rules/bulk', ruleData).then((response) => {
            onSuccess(response.data);
        }, (error) => {
            onError(error);
        });
    },

    isControlRule: function (templateCategory) {
        return [RuleService.categories.sensorialRulesProportional,
            RuleService.categories.sensorialRulesDoubleThermostat,
            RuleService.categories.sensorialRulesThermostat,
            RuleService.categories.cycleThermostat,
            RuleService.categories.cycleProportional,
            RuleService.categories.cycleDoubleThermostat,
        ].includes(templateCategory);
    }
};

export default RuleService;