import _ from "lodash";
import __ from "../utils/EnhancedLodash";

let ProductionUtils = {
    getBatchCodeWithEnterpriseCode: function (batches) {
        _.forEach(batches, batch => {
           return ProductionUtils.getBatchCodeWithEnterpriseCodeObject(batch);
        });
        return batches;
    },

    getBatchCodeWithEnterpriseCodeObject: function (batch) {
        if (!_.isNil(batch.entity) && !_.isNil(batch.entity.farmOfSector) && !_.isNil(batch.entity.farmOfSector.parent)
            && !_.isNil(batch.entity.farmOfSector.parent.code) && !_.isEmpty(batch.entity.farmOfSector.parent.code)) {
            batch.complexCode = batch.entity.farmOfSector.parent.code + "." + batch.code + " | " + (!__.isNilOrEmpty(batch.productionClass) ? batch.productionClass : "---");
        } else {
            batch.complexCode = batch.code + " | " + (!__.isNilOrEmpty(batch.productionClass) ? batch.productionClass : "---");
        }
        return batch;
    }
};

export default ProductionUtils;