const ThingsControlTheme = {
    images : {
        loginLogo: "/img/thingsControl/thingscontrol-logo.svg",
        whiteLogo: "/img/thingsControl/thingscontrol-logo-white.svg",
        favicon: "/img/thingsControl/favicon.ico",
        leftMenuLogo: "/img/thingsControl/thingscontrol_logo_only.svg",
        headerLogo: "/img/thingsControl/thingscontrol_logo_header.svg",

        touchIconIphone: "/img/touch-icon-iphone.png",
        touchIconIpad:"/img/thingsControl/touch-icon-ipad.png",
        touchIconIphoneRetina: "/img/thingsControl/touch-icon-iphone-retina.png",
        touchIconIpadRetina: "/img/thingsControl/touch-icon-ipad-retina.png",
        appleTouchStartupImage: "/img/apple-touch-startup-image",
        qrcodeLogo: "/img/thingsControl/thingscontrol-logo.png"
    },
    css: [
        "/themes/thingsControl/thingsControl.css"
    ],
    strings: {
        title: "ThingsControl - Industrial IOT Platform"
    }
}

export default ThingsControlTheme;