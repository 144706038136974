const LivingLabTheme = {
    images : {
        loginLogo: "/img/livinglab/logo.png",
        whiteLogo: "/img/livinglab/logo.png",
        favicon: "/img/livinglab/favicon.ico",
        leftMenuLogo: "/img/livinglab/logo.png",
        headerLogo: "/img/livinglab/logo.png",
        pt2020: "/img/livinglab/pt2020.png",
        touchIconIphone: "/img/touch-icon-iphone.png",
        touchIconIpad:"/img/touch-icon-ipad.png",
        touchIconIphoneRetina: "/img/touch-icon-iphone-retina.png",
        touchIconIpadRetina: "/img/touch-icon-ipad-retina.png",
        appleTouchStartupImage: "/img/apple-touch-startup-image",
        qrcodeLogo: "/img/livinglab/logo.png"
    },
    css: [
        "/themes/livingLab/livingLab.css"
    ],
    strings: {
        title: "LivingLab - MyFarmcontrol"
    }
}

export default LivingLabTheme;