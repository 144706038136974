import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import _ from "lodash";

class Row extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            isVisible: !_.isNil(props.isVisible)? props.isVisible: true,
        }
    }

    static getDerivedStateFromProps(nextProps, state) {
        if(!_.isNil(nextProps.isVisible)) {
            return {
                isVisible: nextProps.isVisible
            }
        }
        return null;
    }

    setVisibility(value){
        this.setState({isVisible: value});
    }

    isVisible(){
        return this.state.isVisible;
    }

    render() {
        if(this.state.isVisible) {
            return <div className={classnames("row",this.props.className)} style={this.props.style}>
                {this.props.children}
            </div>;
        } else {
            return null;
        }
    }
}

Row.propTypes = {
    className: PropTypes.string,
};

export default Row;
