import React from 'react';
import PropTypes from "prop-types";
import ImgButton from "../buttons/ImgButton";
import back from "../../img/back/back.svg";
import backOnHover from "../../img/back/back_on_over.svg";
import edit from "../../img/edit/edit.svg";
import editSelected from "../../img/edit/edit_selected.svg";
import editOnHover from "../../img/edit/edit_on_over.svg";
import editSelectedOnHover from "../../img/edit/edit_selected_on_over.svg";
import editDisable from "../../img/edit/edit_disabled.svg";
import trash from "../../img/trash/trash.svg";
import trashOnHover from "../../img/trash/trash_on_over.svg";
import trashDisable from "../../img/trash/trash_disabled.svg";

const FormHeader =
    ({
         isCreate = false,
         isView = false,
         isEdit = false,
         onModal = false,
         showBack= true,
         hasEditPermission = false,
         hasDeletePermission = false,
         hasAdditionalIcons = false,
         tooltipEdit = "",
         tooltipDelete = "",
         additionalIcons = null,
         onBack = ()=>{},
         onEdit = ()=>{},
         onDelete = ()=>{},
     }) => {
        return (
            <div className="ml-8-xs-sm create-form-button">
                <div className="form-top-menu">
                    { !onModal && showBack &&
                    <div onClick={() => onBack()}>
                        <ImgButton
                            alt = "back"
                            image={back}
                            divClassname={"back"}
                            onHoverImage = {backOnHover}
                        />
                    </div>
                    }
                    {<div>

                    </div>}
                    { !isCreate &&
                    <div className={"flex"}>
                        {
                            hasAdditionalIcons &&
                            <>
                                {additionalIcons}
                            </>
                        }
                        {
                            hasEditPermission &&
                            <ImgButton
                                alt = "edit"
                                tooltip={tooltipEdit}
                                handleIconClick={() => onEdit()}
                                image={isView ? edit : editSelected}
                                onHoverImage = {isView ? editOnHover : editSelectedOnHover}
                                disableImage={editDisable}
                                disabled={!(isView || isEdit)}
                            />
                        }
                        {
                            hasDeletePermission &&
                            <ImgButton
                                alt="trash"
                                tooltip={tooltipDelete}
                                handleIconClick={() => onDelete()}
                                image={trash}
                                onHoverImage={trashOnHover}
                                disableImage={trashDisable}
                                disabled={!(isView || isEdit)}
                            />
                        }
                    </div>
                    }
                </div>
            </div>
        );
    };

FormHeader.propTypes = {
    isCreate: PropTypes.bool,
    isView: PropTypes.bool,
    isEdit: PropTypes.bool,
    hasEditPermission: PropTypes.bool,
    hasDeletePermission: PropTypes.bool,
    hasAdditionalIcons: PropTypes.bool,
    tooltipEdit: PropTypes.string,
    tooltipDelete: PropTypes.string,
    additionalIcons: PropTypes.any,
    onBack: PropTypes.func,
    onEdit: PropTypes.func,
    onDelete: PropTypes.func
};

export default FormHeader;
