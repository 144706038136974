import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {Checkbox} from 'react-icheck';
import uuid from "../../../utils/uuid";
import {t} from "../../../utils/Translation";
import Row from "../../row/Row";

class CheckboxGroup extends React.Component {
    constructor(props) {
        super(props);
        let data = {};
        let options = props.options ? props.options : [];
        props.options.forEach(option => {
            data[option.id] = props.startAllChecked;
        });
        this.state = {
            options: options,
            classNameCheckbox: props.classNameCheckbox ? props.classNameCheckbox : '',
            selectAll: props.selectAll? props.selectAll : {},
            groupLabel: props.groupLabel? props.groupLabel : '',
            checkedOptions: props.checkedOptions || data,
            selectAllChecked: false,
            inLine: !_.isNil(props.inLine)? props.inLine : true,
            width: !_.isNil(props.width)? props.width : "12",
            labelInLine: !_.isNil(props.labelInLine)? props.labelInLine : false,
            maxSelected : !_.isNil(props.maxSelected) ? props.maxSelected : null,
            minSelected : !_.isNil(props.minSelected) ? props.minSelected : null,
            isRequired: !_.isNil(props.isRequired)? props.isRequired : false,
            showError: false,
            errorMessage: !_.isNil(props.errorMessage) ?
                props.errorMessage :
                props.minSelected ?
                    t("components.checkboxGroup.error.atLeastOptions", { minOption: props.minSelected }) :
                    t("components.checkboxGroup.error.generic")
        };
    }

    componentDidMount() {
        if (this.props.startAllChecked) {
            initCheckedOptions(this, true);
        }
    }

    componentWillReceiveProps(nextProps) {
        if (optionsAreNew(this.state.options, nextProps.options)) {
            this.setState({
                options: nextProps.options ? nextProps.options : this.state.options
            });
        }
        if(nextProps.forceNewPropValue) {
            this.setState({
                options: nextProps.options ? nextProps.options : this.state.options,
                checkedOptions: nextProps.checkedOptions ? nextProps.checkedOptions : {},
                errorMessage: nextProps.errorMessage ? nextProps.errorMessage : this.state.errorMessage,
                showError: !!nextProps.errorMessage
            });
        }
    }

    handleSelectAllChange(checked) {
        let afterSelectAllIsChecked = this.props.onSelectAllChanged ? this.props.onSelectAllChanged : () => { };
        this.setState({ selectAllChecked: checked }, () => {
            afterSelectAllIsChecked(checked, true);
            setAllOptions(this, checked);
        });
    }

    setAllOptionsDisabled(disabled) {
        let options = _.cloneDeep(this.state.options);
        _.forEach(options, el => {
            el.disabled = disabled;
        });
        this.setOptions(options);
    }

    handleOptionCheckChange(e, checked) {
        this.setCheckedOption(e.target.id, checked);
        if (!checked) {
            this.setState({ selectAllChecked: false });
        } else {
            this.checkIfAllBoxesAreChecked();
        }
    }

    setDisabledOption(optionId, disabled) {
        let options = this.state.options;
        let optionIndex = _.findIndex(options, option => {
            return option.id === optionId;
        });
        if (optionIndex !== -1) {
            options[optionIndex].disabled = disabled;
            this.setOptions(options);
        }
    }

    checkIfAllBoxesAreChecked() {
        let allOptionsChecked = true;
        let afterSelectAllIsChecked = this.props.onSelectAllChanged ? this.props.onSelectAllChanged : () => { };
        _.forOwn(this.state.checkedOptions, (field, key) => {
            if (!this.state.checkedOptions[key]) {
                allOptionsChecked = false;
            }
        });
        this.setState({ selectAllChecked: allOptionsChecked }, afterSelectAllIsChecked(allOptionsChecked, false));
    }

    validateMaxSelectedBoxes(newValue) {
        let max = this.state.maxSelected;
        if (_.isNil(max)) {
            return true;
        }
        let count = 0;
        _.forOwn(this.state.checkedOptions, (field, key) => {
            if (this.state.checkedOptions[key]) {
                count++;
            }
        });

        if (newValue) {
            return max > count;
        } else {
            return max >= count;
        }
    }

    setOptions(options) {
        this.setState({ options: options });
    }

    setCheckedOptions(checkedOptions, afterCheckedOptionsAreSet) {
        let self = this;
        afterCheckedOptionsAreSet = afterCheckedOptionsAreSet ? afterCheckedOptionsAreSet : () => { };
        let onFieldChange = this.props.onFieldChange ? this.props.onFieldChange : () => { };
        this.setState({ checkedOptions: checkedOptions }, () => {
            self.checkIfAllBoxesAreChecked();
            afterCheckedOptionsAreSet();
            onFieldChange(this.getFieldValue());
        });
    }

    setCheckedOption(optionId, checked) {
        let onOptionChanged = this.props.onOptionChanged ? this.props.onOptionChanged : () => { };
        let onFieldChange = this.props.onFieldChange ? this.props.onFieldChange : () => { };
        let newCheckedOptions = this.state.checkedOptions;
        if (this.validateMaxSelectedBoxes(checked)) {
            newCheckedOptions[optionId] = checked;
            this.setCheckedOptions(newCheckedOptions, () => {
                onOptionChanged(optionId, checked);
                onFieldChange(this.getFieldValue());
            });
        } else {
            let newCheckedOptions2 = {};
            let oneChange = false;
            _.forOwn(newCheckedOptions, function (checked, option) {
                if (!oneChange && checked) {
                    newCheckedOptions2[option] = false;
                    oneChange = true;
                } else {
                    newCheckedOptions2[option] = checked
                }
            });
            newCheckedOptions2[optionId] = checked;
            this.setCheckedOptions(newCheckedOptions2, onOptionChanged(optionId, checked));
        }
    }

    getFieldValue() {
        return this.state.checkedOptions;
    }

    countSelectedBoxes() {
        let count = 0;
        _.forOwn(this.state.checkedOptions, function (checked, option) {
            if (checked) {
                count++;
            }
        });
        return count;
    }

    validateField() {
        if (!_.isNil(this.state.minSelected)) {
            if (this.countSelectedBoxes() < this.state.minSelected) {
                this.showErrorMessage(true);
                return false;
            }
        }
        this.showErrorMessage(false);
        return true;
    }

    setFieldValue(data) {
        this.setCheckedOptions(data);
    }

    showErrorMessage(error) {
        this.setState({ showError: error })
    }

    render() {
        return (
            <div className={"form-group col-lg-" + this.state.width} style={{ whiteSpace: 'initial', paddingTop: "5px"}}>
                <Row>
                    <div className="checkbox-flex mr-15">
                        {createInlineCheckboxes(this)}
                    </div>
                    {renderErrorMessage(this)}
                </Row>
            </div>
        )
    }
}

function renderErrorMessage(component) {
    if (!component.state.showError) {
        return null;
    }
    return (
        <div className='has-error'>
            <span className={component.props.errorMessageClassName ? component.props.errorMessageClassName : 'help-block pull-right'}><i>{component.state.errorMessage}</i></span>
        </div>
    );
}

function createInlineCheckboxes(component) {
    let checkboxes = [];
    let key;
    const classNameCheckbox = component.state.classNameCheckbox;

    component.state.options.forEach(option => {
        key = uuid();
        let checkBoxClass = "icheckbox_square-green" + ((option.locked || option.disabled || component.props.isView) ? " disabled" : " bg-white");
        if (option.locked) {
            component.state.checkedOptions[option.id] = true;
        }
        let checkboxDisabled = option.locked || option.disabled || component.props.isView;
        let checkboxChecked = option.checked || (component.state.checkedOptions[option.id] ? component.state.checkedOptions[option.id] : false);
        checkboxes.push(
            <div className={classNameCheckbox + " pd-l-15"}>
                <label key={component.props.id + "_" + option.id + "_" + key}
                       className="checkbox-inline i-checks">
                    <div>
                        <Checkbox
                            id={option.id}
                            key={component.props.id + "_" + option.id + "_" + key}
                            name={option.name}
                            checkboxClass={checkBoxClass}
                            increaseArea="20%"
                            disabled={checkboxDisabled}
                            label={<span style={{ cursor: checkboxDisabled ? "initial" : "pointer", fontWeight: checkboxDisabled ? "400" : "700" }}>{" " + option.name}</span>}
                            checked={checkboxChecked}
                            onChange={(e, checked) => {
                                e.stopPropagation();
                                component.handleOptionCheckChange(e, checked)
                            }}
                        />
                    </div>
                </label>
            </div>
        );
    });

    if (!_.isEmpty(component.props.selectAll) && !component.props.isView) {
        checkboxes.push(
            <div className="pd-l-15">
                {createSelectAll(component)}
            </div>
        );
    }
    return checkboxes;
}

function createSelectAll(component) {
    let selectAllStyle = component.state.inLine ? {} : { marginTop: "20px" };
    let option = null;
    if (!_.isEmpty(component.props.selectAll) && !component.props.isView) {
        let selectAllEnabled = !_.isNil(component.props.selectAllEnabled) ? component.props.selectAllEnabled : true;
        let checkBoxClass = "icheckbox_square-green" + (selectAllEnabled ? " bg-white" : "");
        let label = (
            <label key={component.props.selectAll.id} className="checkbox-inline i-checks" style={selectAllStyle}>
                <div key={component.props.selectAll.id}>
                    <Checkbox
                        name={component.props.selectAll.name}
                        checkboxClass={checkBoxClass}
                        increaseArea="20%"
                        disabled={!selectAllEnabled}
                        label={<span style={{ cursor: !selectAllEnabled ? "initial" : "pointer", fontWeight: !selectAllEnabled ? "400" : "700" }}>{" " + component.props.selectAll.name}</span>}
                        checked={component.state.selectAllChecked}
                        onChange={(e, checked) => {
                            e.stopPropagation();
                            component.handleSelectAllChange(checked)
                        }}
                    />
                </div>
            </label>
        );
        if(!component.state.inLine){
            option = (
                <div className={component.props.optionClassName ? component.props.optionClassName : "col-sm-4"} /*style={{float: "inherit"}}*/>
                    {label}
                </div>);
        } else {
            option = label;
        }
    }
    return option
}

function setAllOptions(component, checked) {
    let data = component.state.checkedOptions;
    _.forOwn(data, (field, key) => {
        data[key] = checked;
    });
    component.setCheckedOptions(data)
}

function initCheckedOptions(component, checked) {
    let data = {};
    component.state.options.forEach(option => {
        data[option.id] = checked;
    });
    component.setCheckedOptions(data)
}

function optionsAreNew(options, options2) {
    let differentIds = _.differenceBy(options, options2, 'id');
    return !_.isEmpty(differentIds);
}

CheckboxGroup.propTypes = {
    options: PropTypes.arrayOf(PropTypes.object),
    selectAll: PropTypes.object,
    groupLabel: PropTypes.string,
    isView: PropTypes.bool,
    startAllChecked: PropTypes.bool,
    onOptionChanged: PropTypes.func,
    onSelectAllChanged: PropTypes.func,
    selectAllEnabled: PropTypes.bool,
    inLine: PropTypes.bool,
    labelInLine: PropTypes.bool,
    maxSelected: PropTypes.number,
    minSelected: PropTypes.number,
    isRequired: PropTypes.bool,
    onError: PropTypes.bool,
    errorMessage: PropTypes.string,
    forceNewPropValue: PropTypes.bool,
    checkedOptions: PropTypes.object
};

export default CheckboxGroup;
