import {useEffect, useState} from 'react';
import {useHistory, useLocation} from 'react-router-dom'
import actions from "./reducers/routes/actions";
import {connect} from "react-redux";
import __ from "./utils/EnhancedLodash";

const BrowserRouter = (props) => {
    const loc = useLocation();
    const history = useHistory()
    const [currentUrl, setCurrentUrl] = useState(undefined);

    useEffect(() => {
        return history.listen((location) => {
            setCurrentUrl(location.pathname);
        }, [history])
    });

    if(__.isNilOrEmpty(currentUrl) || currentUrl !== loc.pathname) {
        props.onURLChange(loc.pathname);
    } else {
        props.onURLChange(currentUrl);
    }

    return props.children;
}

const mapDispatchToProps = {
    onURLChange: actions.onURLChange
};

export default connect(undefined, mapDispatchToProps, null, {})
(BrowserRouter);