import React from 'react';

class AccordionContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            position: props.position,
            active: props.active,
        };
    }

    toggleVisibility() {
        this.setState({
            active: !this.state.active
        })
    }

    render() {
        let activePanelClass = this.state.active? ' in' : '';

        return <div className={"panel-collapse collapse" + activePanelClass}>
            <div className="panel-body" style={this.props.customStyle}>
                {this.props.componentToShow}
            </div>
        </div>;
    }
}

export default AccordionContent;