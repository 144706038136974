import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import Select from 'react-select';
import _ from 'lodash';
import HelperIcon from './../../img/helpFieldIcon.svg';
import 'react-select/dist/react-select.css';
import Tooltip from "../tooltip/Tooltip";

class MultiSelectField extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            fieldPlaceHolder: !_.isNil(props.fieldPlaceHolder) ? props.fieldPlaceHolder : '',
            disabled: !_.isNil(props.isView) ? props.isView : false,
            showListView: !_.isNil(props.showListView) ? props.showListView : false,
            options: !_.isNil(props.options) ? props.options : [],
            value: !_.isNil(props.value) ? props.value : [],
            hiddenValues: !_.isNil(props.hiddenValues) ? this.props.hiddenValues : [],
            isVisible: !_.isNil(props.isVisible) ? props.isVisible : true,
            width: props.width,
            creatable: !_.isNil(props.permitCreate) ? props.permitCreate : false,
            protectHiddenValues: !_.isNil(this.props.protectHiddenValues) ? this.props.protectHiddenValues : false,
            lastOptions: [],
            error: this.props.error ? props.error : '',
            useCustomFilter: !_.isNil(props.useCustomFilter) ? props.useCustomFilter : false,
            isViewMode: !_.isNil(props.isViewMode) ? props.isViewMode : false,
        }
    }

    static getDerivedStateFromProps(nextProps, prevState) {
        return {
            disabled: nextProps.isView,
            showListView: nextProps.showListView,
            options: !_.isNil(nextProps.options) ? nextProps.options : prevState.options,
            value: !_.isNil(nextProps.value) ? nextProps.value : prevState.value,
            hiddenValues: !_.isNil(nextProps.hiddenValues) ? nextProps.hiddenValues : prevState.hiddenValues,
            error: !_.isNil(nextProps.error) ? nextProps.error : prevState.error
        }
    }

    handleSelectChange(value) {
        let newValue = value[value.length - 1];
        if (!this.state.protectHiddenValues || this.state.creatable) {
            if (_.isEmpty(newValue) || this.validateOption(newValue.value)) {
                this.setState({value}, () => {
                    if (!_.isNil(this.props.onFieldChange)) {
                        this.props.onFieldChange(value);
                    }
                });
            } else {
                let index = this.state.options.findIndex(i => i === newValue);
                if (index > -1) {
                    this.state.options.splice(index, 1);
                }
            }
        } else {
            if (_.isEmpty(newValue) || this.validateOption(newValue.value)) {
                let hiddenValues = this.state.hiddenValues;
                if (_.isEmpty(newValue)) {
                    hiddenValues = _.remove(hiddenValues, (val) => {
                        return (_.filter(this.state.options, ['value', val]).length === 0);
                    });
                } else {
                    hiddenValues.push(newValue.value);
                }
                this.setState({value: hiddenValues, hiddenValues: hiddenValues});
            }
        }
    }

    toggleDisabled(e) {
        this.setState({disabled: e.target.checked});
    }

    setOptions(options, callback = () => {}) {
        this.setState({options: options}, callback);
    }

    setData(value) {
        this.setState({value: value, hiddenValues: value});
    }

    setVisibility(value) {
        this.setState({isVisible: value});
    }

    isVisible() {
        return this.state.isVisible;
    }

    validateField(...param) {
        if (!_.isNil(this.props.validator)) {
            let errorMessage = this.props.validator(param);
            if (!_.isEmpty(errorMessage)) {
                this.setError(errorMessage);
                return false;
            }
        }
        this.setError('');
        return true;
    }

    getFieldValue() {
        let values = [];
        if (!_.isNil(this.state.value) && !_.isEmpty(this.state.value)) {
            if (_.isArray(this.state.value)) {
                _.forEach(this.state.value, val => {
                    values.push(!_.isNil(val.value) ? val.value : val)
                });
            } else {
                values.push(this.state.value);
            }
        }
        return values;
    }

    setError(error) {
        this.setState({error: error});
    }

    validateOption(newValue) {
        if (!_.isNil(this.props.validatorRegex)) {

            if (_.isNil(newValue) || !this.props.validatorRegex.test(newValue)) {
                return false;
            }
        }
        return true;
    }

    showMultiDropdownListView() {
        let values = [];
        let {
            value,
        } = this.state;

        value.sort((a, b) => (a.value > b.value) ? 1 : ((b.value > a.value) ? -1 : 0))
            .forEach(v => {
            values.push(
                <li key={v.value}>{v.label}</li>
            );
        })

        if(_.isEmpty(values)) {
            return '- - -';
        } else {
            return <ul>{values}</ul>;
        }
    }

    render() {
        let self = this;
        let className = 'form-group col-md-' + this.props.width;
        let tooltipHelper = !_.isNil(this.props.tooltip) && (
            <Tooltip content={this.props.tooltip}>
                {<img alt="" className="pd-r-15 labeled-field-helper-icon" src={HelperIcon}/>}
            </Tooltip>
        );

        if(this.state.error !== "") {
            className += ' has-error';
        }

        if(this.state.showListView){
            return (
                <div className="form-group col-lg-4">
                    <label className={"pd-r-15 pd-t-5"}>{this.props.fieldLabel}</label>
                    <div className="text-field-info">
                        {this.showMultiDropdownListView()}
                    </div>
                </div>)
        }

        if (this.state.isVisible) {
            return (
                <div className={className}>
                    <div>
                        <label
                            className={classnames({"required-field": self.props.isRequired && !self.props.isView}, 'pd-r-15')}>
                            {this.props.fieldLabel}
                        </label>
                        {tooltipHelper}
                    </div>
                    {getFieldIcon(this,
                        <div className='chosen-container chosen-container-multi'>
                            {constructSelect(this, this.state.creatable)}
                        </div>
                    )}
                    {
                        (self.state.error !== "") &&
                        getErrorArea(self)
                    }
                </div>
            );
        } else {
            return <div className={className}/>;
        }
    }
}

function constructSelect(component, creatable) {
    let field;
    let dropdownClassName
    if(component.state.isViewMode){
        dropdownClassName = "labeled-field-dropdown dropdown-is-view"
    } else {
        dropdownClassName = component.state.error === "" ? "labeled-field-dropdown" : "labeled-field-dropdown dropdown-has-error";
    }

    if (creatable) {
        field = (
            <Select.Creatable
                className={dropdownClassName}
                multi
                disabled={component.state.disabled}
                value={component.state.value}
                placeholder={component.state.fieldPlaceHolder}
                options={component.state.options}
                filterOption={component.state.useCustomFilter ? filterOption : undefined}
                onChange={(value) => component.handleSelectChange(value)}
            />
        );
    } else {
        field = (
            <Select
                className={dropdownClassName}
                multi
                disabled={component.state.disabled}
                value={component.state.value}
                placeholder={component.state.fieldPlaceHolder}
                options={component.state.options}
                filterOption={component.state.useCustomFilter ? filterOption : undefined}
                onChange={(value) => component.handleSelectChange(value)}
            />
        );
    }
    return field
}

function filterOption(option, inputValue) {
    return option?.searchValue?.toLowerCase().includes(inputValue)
}

function getErrorArea(component) {
    return (
        <div className={classnames('has-error')}>
            <span className='help-block pull-right'><i>{component.state.error}</i></span>
        </div>
    );
}

function getFieldIcon(component, field) {
    if (!_.isNil(component.props.iconClassName)) {
        return (
            <div className='input-group col-xs-12'>
                <span className='input-group-addon'>
                    <i className={classnames(component.props.iconClassName, 'flex-align-self-center')} />
                </span>
                {field}
            </div>
        );
    }
    return field;
}

MultiSelectField.propTypes = {
    fieldLabel: PropTypes.string,
    fieldPlaceHolder: PropTypes.string,
    options: PropTypes.array,
    isView: PropTypes.bool,
    value: PropTypes.array,
    hiddenValues: PropTypes.array,
    width: PropTypes.string,
    permitCreate: PropTypes.bool,
    validator: PropTypes.func,
    protectHiddenValues: PropTypes.bool,
    isRequired: PropTypes.bool
};

export default MultiSelectField;