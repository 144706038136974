import _ from 'lodash';
import TimezoneUtils from "./TimezoneUtils";
import UserSessionService from "../services/UserSessionService";
import moment from "moment";
import {config} from "../config";
import React from "react";

let DateUtils = {
    simpleDisplayDateTimeWithFormat(dateIso8601, hasDate=true, hasTime=true, avoidConversion, hiddenSeconds, format) {
        if(_.isNil(dateIso8601)) {
            return '- - -';
        }

        let dateTime = null;

        if(!avoidConversion) {
            if(!hasDate && hasTime) {
                dateTime = TimezoneUtils.convertUTCToUserTimezoneWithFormat(dateIso8601, this.getDateTimeFormat(hasDate, hasTime, hiddenSeconds));
            } else {
                dateTime = TimezoneUtils.convertUTCToUserTimezone(dateIso8601);
            }
            let locale = UserSessionService.getCurrentUserLanguage();
            dateTime = dateTime.locale(locale);
        } else {
            if(_.isNumber(dateIso8601)) {
                dateTime = moment.utc(dateIso8601);
            } else {
                dateTime =  moment(dateIso8601, this.getDateTimeFormat(hasDate, hasTime, hiddenSeconds));
            }
        }
        let result = dateTime.format(format);
        if(result === 'Invalid date') {
            result = '- - -'
        }
        return result;
    },

    simpleDisplayDateTime(dateIso8601, hasDate=true, hasTime=true, avoidConversion, hiddenSeconds) {
        if(_.isNil(dateIso8601)) {
            return '- - -';
        }

        let dateTime = null;

        if(!avoidConversion) {
            if(!hasDate && hasTime) {
                dateTime = TimezoneUtils.convertUTCToUserTimezoneWithFormat(dateIso8601, this.getDateTimeFormat(hasDate, hasTime, hiddenSeconds));
            } else {
                dateTime = TimezoneUtils.convertUTCToUserTimezone(dateIso8601);
            }
            let locale = UserSessionService.getCurrentUserLanguage();
            dateTime = dateTime.locale(locale);
        } else {
            if(_.isNumber(dateIso8601)) {
                dateTime = moment.utc(dateIso8601);
            } else {
                dateTime =  moment(dateIso8601, this.getDateTimeFormat(hasDate, hasTime, hiddenSeconds));
            }
        }
        let result;
        if(hasDate && hasTime){
            if(hiddenSeconds) {
                result = dateTime.format(config.datepicker.formats.dateTimeListWithoutSeconds);
            } else {
                result = dateTime.format(config.datepicker.formats.dateTimeList);
            }
        } else if (hasDate) {
            result = dateTime.format(config.datepicker.formats.date);
        } else if (hasTime) {
            if(hiddenSeconds) {
                result = dateTime.format(config.datepicker.formats.timeWithoutSeconds);
            } else {
                result = dateTime.format(config.datepicker.formats.time);
            }
        }
        if(result === 'Invalid date') {
            result = '- - -'
        }
        return result;
    },

    dateTimeToTimestamp(dateIso8601) {
        if(_.isNil(dateIso8601)) {
            return moment().utc().valueOf()
        }
        return moment.utc(dateIso8601, 'YYYY-MM-DDTHH:mm:ss').valueOf();
    },

    timeToSeconds(time) {
        if(_.isNil(time)) {
            return 0;
        }

        if (time instanceof moment) {
            time = time.format('HH:mm');
            time = _.split(time, ':');
            let hours = Number(time[0]);
            let minutes = Number(time[1]);
            minutes += hours*60;
            return minutes*60;
        }

        return time;

    },

    secondsToTime(totalSeconds) {
        if(_.isNil(totalSeconds)) {
            return null;
        }

        let hours = Math.floor(totalSeconds / 3600) % 24;
        let minutes = Math.floor(totalSeconds / 60) % 60;

        let time = (hours > 0 ? (hours < 10 ? '0' + hours : hours) + ':' : '00:') + (minutes < 10 ? '0' + minutes : minutes);

        return moment(time, 'HH:mm');
    },

    sortType: {
        DESC: 'DESC',
        ASC: 'ASC'
    },

    sortByDate(array, key, op = 'DESC') {
        if (op === 'ASC') {
            array.sort(function (left, right) {
                return moment.utc(left[key]).diff(moment.utc(right[key]))
            });
            return array;
        }

        array.sort(function (left, right) {
            return moment.utc(right[key]).diff(moment.utc(left[key]))
        });

        return array;
    },

    secondsToString(totalSeconds) {
        if (_.isNil(totalSeconds)) {
            return null;
        }

        let days = Math.floor(totalSeconds / 86400);
        let hours = Math.floor((totalSeconds - days * 86400) / 3600);
        let minutes = Math.floor((totalSeconds - (days * 86400 + hours * 3600)) / 60);

        if (days === 0 && hours === 0 && minutes === 0) {
            return totalSeconds.toString() + ' s';
        }

        return (days > 0 ? (days.toString() + ' d ') : '') +
            (hours > 0 ? (hours.toString() + ' h ') : '') +
            (minutes > 0 ? (minutes.toString() + ' min') : '');
    },

    getDateTimeFormat(hasDate, hasTime, hiddenSeconds){
        if(hasDate && hasTime){
            return config.datepicker.formats.dateTimeList;
        } else if (hasDate) {
            return config.datepicker.formats.alternativeDate;
        } else if (hasTime) {
            if (hiddenSeconds) {
                return config.datepicker.formats.timeWithoutSeconds;
            } else {
                return config.datepicker.formats.time;
            }
        }
    },

    getDateTimeFormatHumanDate(hasDate, hasTime, hiddenSeconds){
        if(hasDate && hasTime){
            return config.datepicker.formats.dateTimeList;
        } else if (hasDate) {
            return config.datepicker.formats.date;
        } else if (hasTime) {
            if (hiddenSeconds) {
                return config.datepicker.formats.timeWithoutSeconds;
            } else {
                return config.datepicker.formats.time;
            }
        }
    },

    convertToTimeZone(date, format='DD-MM-YYYY') {
        if (_.isNil(date)) {
            return '- - -'
        }
        return TimezoneUtils.convertUTCToUserTimezone(date).format(format);
    },

    splitDateIntoTwoLines(value) {
        const result = _.isNil(value) ? ' - - - ' : DateUtils.simpleDisplayDateTime(value);
        const resultParts = result.split(" ");
        return <span>{resultParts[0]}<br/>{resultParts[1]}</span>;
    },

    getWeekdays(){
        return [
            'sun',
            'mon',
            'tue',
            'wed',
            'thu',
            'fri',
            'sat'
        ];
    },

    convertSecondsInDate(time){
        const currentDate = moment(time * 1000).utc();
        const hours = currentDate.hours();
        const mins = currentDate.minutes();
        const secs = currentDate.seconds();
        return (hours > 0 ? (hours < 10 ? '0' + hours : hours) + 'h ' : '') + (mins < 10 ? '0' + mins : mins) + 'm '
            + (secs < 10 ? '0' + secs : secs) + 's';
    }

};

export default DateUtils;