/* eslint-disable */
import EventService from "./services/EventService";

export const config = {
    homepage: {
        refreshWidgetDataIntervalMillis: 60000
    },
    alerts: {
        successTimeoutMillis: 3000
    },
    user: {
        emailregex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
        phoneregex: /^[\s()+-]*([0-9][\s()+-]*){6,20}$/,
        passwordregex: /^(?=.{6,25}$)(?=.*[^a-zA-Z0-9])(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])/,
        displayNameregex: /^.{3,50}$/,
        status: {
            ACTIVE: 'ACTIVE',
            INACTIVE: 'INACTIVE'
        },
        avatar: {
            base_url: '/services'
        }
    },
    interface: {
        displayNameregex: /^.{3,50}$/,
        categories:{
            icon:{
                base_url: '/services/static/icon/interfaceCategories/'
            }
        }
    },
    terminals: {
        profile: {
            FC_SMARTBOX_1 : "FC-SMARTBOX-1",
            FC_SMARTBOX_ROOM : "FC-SMARTBOX-ROOM"
        },
        view: {
            maxDataSize: 100,
            columns: 3,
            width: "4"
        },
        provisionStatus: {
            UPDATING: "UPDATING",
            NOT_PROVISIONED: "NOT_PROVISIONED",
            PROVISIONED: "PROVISIONED"
        }
    },
    events: {
        availableServices: [EventService.eventTypes.mail],
        serviceFields: {
            MAIL: {
                users: 'emailUsers'
            },
            SMS: {
                users: 'smsUsers'
            },
            VOICE: {
                users: 'voiceUsers'
            },
        }
    },
    filterPages: {
        EVENTS: 'EVENTS',
        RULES: 'RULES',
        RULE_TEMPLATES: 'RULE_TEMPLATES',
        BATCHES: 'BATCHES',
        BATCH_ANALYTICS: 'BATCH_ANALYTICS',
        GENETICS: 'GENETICS',
        PRODUCTION_CURVES: 'PRODUCTION_CURVES',
        DEATH_REASONS: 'DEATH_REASONS',
        FEED_TYPES: 'FEED_TYPES',
        FEED_FACTORIES: 'FEED_FACTORIES',
        FEED_MOVEMENTS: 'FEED_MOVEMENTS',
        AUTOMATIC_FEED_MOVEMENTS: 'AUTOMATIC_FEED_MOVEMENTS',
        ANIMAL_MOVEMENTS: 'ANIMAL_MOVEMENTS',
        WEIGHING: 'WEIGHING',
        REPORTS: 'REPORTS',
        TASKS: 'TASKS',
        TASK_BOARD: 'TASK_BOARD',
        TASK_TEMPLATES: 'TASK_TEMPLATES',
        ENTITIES: 'ENTITIES',
        TARIFFS: 'TARIFFS',
        USERS: 'USERS',
        TERMINALS: 'TERMINALS',
        TERMINAL_INTERFACES: 'TERMINAL_INTERFACES',
        AUDIT: 'AUDIT',
        HELPDESK: 'HELPDESK'
    },
    widgets: {
        simple_image: {
            base_url: '/services/static/widgets/image/'
        },
        weather: {
            providerUrl: "www.meteoblue.com"
        },
        map: {
            dashboardsUrl: '/farmview?dashboard='
        },
        iframe: {
            minHeight: 100,
            maxHeight: 999
        },
        directValue:{
            preview: {
                defaultFontColor: "inherit",
                defaultBackgroundColor: "#eaeaea",
            },
            defaultFontColor: "#6F7172",
            defaultBackgroundColor: "#eaeaea",
            simpleColorPicker: {
                fontColor: ["#FFFFFF", "#676A6C"],
                size: "97px"
            },
            complexColorPicker: {
                size: 100,
                disableAlpha: true
            }
        },
        simpleSearchTable: {
            pollingTaskIntervalMilliseconds: 30000
        },
        multiInterfaces:{
            defaultSortName: 'displayName',
            defaultSortOrder: 'ASC'
        },
        stickyNote: {
            textMaxLength: 10000
        }
    },
    generic: {
        terminaltimeoutregex: /^0*([0-9]|[1-8][0-9]|9[0-9]|[1-8][0-9]{2}|9[0-8][0-9]|99[0-9]|[1-8][0-9]{3}|9[0-8][0-9]{2}|99[0-8][0-9]|999[0-9]|[1-3][0-9]{4}|4[0-2][0-9]{3}|43[01][0-9]{2}|43200)$/,
        numberregex: /^\d+$/,
        floatnumberregex:/[-+]?[0-9]*\.*,?[0-9]+$/,
        locationregex: /^-?[0-9]\d*(\.\d+)?$/,
        positiveornegativenumberregex: /^-?\d+$/,
        decimalregex: /^\d+(\.\d+)?$/,
        arearegex: /^\d{0,10}(?:\.\d{1,10})?$/,
        positiveornegativedecimalregex: /^-?\d+(\.\d+)?$/,
        dateisoregex: /^\d{4}-\d\d-\d\dT\d\d:\d\d:\d\d(\.\d+)?(([+-]\d\d:\d\d)|Z)?$/i,
        dashboarddisplaynameregex: /^.{3,50}$/,
        urlregex : /^(?:(?:(?:https?):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:0)(?:\.\d{1,3}){3})(?!(?:0\.0|0\.0)(?:\.\d{1,3}){2})(?!0\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i,
        iframeheightregex: /^0*([1-8][0-9]{2}|9[0-8][0-9]|99[0-9])$/,
        farmCodeRegex: /^([a-zA-Z]|[0-9]){3}$/,
        enterpriseCodeRegex: /^([a-zA-Z]|[0-9]){2}$/,
        error: {
            confirmButtonColor: '#19aa8d',
        },
        dropzone: {
            deleteIcon: 'M32.6568542,29 L38.3106978,23.3461564 C39.8771021,21.7797521 39.8758057,19.2483887 38.3137085,17.6862915 C36.7547899,16.1273729 34.2176035,16.1255422 32.6538436,17.6893022 L27,23.3431458 L21.3461564,17.6893022 C19.7823965,16.1255422 17.2452101,16.1273729 15.6862915,17.6862915 C14.1241943,19.2483887 14.1228979,21.7797521 15.6893022,23.3461564 L21.3431458,29 L15.6893022,34.6538436 C14.1228979,36.2202479 14.1241943,38.7516113 15.6862915,40.3137085 C17.2452101,41.8726271 19.7823965,41.8744578 21.3461564,40.3106978 L27,34.6568542 L32.6538436,40.3106978 C34.2176035,41.8744578 36.7547899,41.8726271 38.3137085,40.3137085 C39.8758057,38.7516113 39.8771021,36.2202479 38.3106978,34.6538436 L32.6568542,29 Z M27,53 C41.3594035,53 53,41.3594035 53,27 C53,12.6405965 41.3594035,1 27,1 C12.6405965,1 1,12.6405965 1,27 C1,41.3594035 12.6405965,53 27,53 Z',
            downloadIcon: 'M16 18l8-8h-6v-8h-4v8h-6zM23.273 14.727l-2.242 2.242 8.128 3.031-13.158 4.907-13.158-4.907 8.127-3.031-2.242-2.242-8.727 3.273v8l16 6 16-6v-8z'
        },
        detailsPanel: {
            defaultNumberElementsPerLine: 4
        },
        services: {
            defaultExpiryTimerForSessionStorageSeconds: 20
        },
        sessionTimeout: {
            idleTimeout: 3600000,         //1 hour
            requestsTimeout: 7200000,     //2 hours
            tokenRequestTimeout: 7200000 //2 hours
        },
        quill: {
            toolbarDefaultConfig: [
                ['bold', 'italic', 'underline', 'strike'],
                ['blockquote', 'code-block'],
                [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                [{ 'script': 'sub' }, { 'script': 'super' }],
                [{ 'indent': '-1' }, { 'indent': '+1' }],
                [{ 'direction': 'rtl' }],
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                [{ 'color': [] }, { 'background': [] }],
                [{ 'font': [] }],
                [{ 'align': [] }],
                ['link', 'image'],
                ['clean']
            ]
        },
        minimumWindowWith : 320,
    },
    deferredStatus: {
        CANNOT_DELETE_USER: 'CANNOT_DELETE_USER',
        USER_EMAIL_ALREADY_EXISTS: 'USER_EMAIL_ALREADY_EXISTS',
        USER_INACTIVE_RESPONSIBLE: 'USER_INACTIVE_RESPONSIBLE',
        CANNOT_DELETE_ROLE: 'CANNOT_DELETE_ROLE',
        TERMINAL_ID_ALREADY_EXISTS: 'TERMINAL_ID_ALREADY_EXISTS',
        CONFIRM_INACTIVATE_ENTITY_WITH_DEPENDENCIES: 'CONFIRM_INACTIVATE_ENTITY_WITH_DEPENDENCIES',
        CANNOT_DELETE_ENTITY_WITH_DEPENDENCIES: 'CANNOT_DELETE_ENTITY_WITH_DEPENDENCIES',
        CANNOT_DELETE_TERMINAL_WITH_DEPENDENCIES: 'CANNOT_DELETE_TERMINAL_WITH_DEPENDENCIES',
        CANNOT_EDIT_TERMINAL_WITH_DEPENDENCIES: 'CANNOT_EDIT_TERMINAL_WITH_DEPENDENCIES',
        CANNOT_DELETE_TERMINAL_WITH_ACTIVE_RULES: 'CANNOT_DELETE_TERMINAL_WITH_ACTIVE_RULES',
        CANNOT_DELETE_INTERFACE_WITH_ACTIVE_RULES: 'CANNOT_DELETE_INTERFACE_WITH_ACTIVE_RULES',
        CANNOT_DELETE_INTERFACE_BEING_DEFAULT_INTERFACE: 'CANNOT_DELETE_INTERFACE_BEING_DEFAULT_INTERFACE',
        CANNOT_DELETE_INTERFACE_WITH_VIRTUAL_INTERFACES_CONFIGURED: 'CANNOT_DELETE_INTERFACE_WITH_VIRTUAL_INTERFACES_CONFIGURED',
        CANNOT_DELETE_A_DEPLOYED_OR_PENDING_RULE: 'CANNOT_DELETE_A_DEPLOYED_OR_PENDING_RULE',
        CANNOT_UNDEPLOY_RULE_WITH_DEPENDENCIES: 'CANNOT_UNDEPLOY_RULE_WITH_DEPENDENCIES',
        CANNOT_DELETE_BATCH_WITH_DEPENDENCIES: 'CANNOT_DELETE_BATCH_WITH_DEPENDENCIES',
        CANNOT_TRANSFER_ENTITY_WITH_DEPENDENCIES: 'CANNOT_TRANSFER_ENTITY_WITH_DEPENDENCIES',
        CANNOT_UPDATE_ENTITY_ALREADY_HAS_DEFAULT_DASHBOARD: 'CANNOT_UPDATE_ENTITY_ALREADY_HAS_DEFAULT_DASHBOARD',
        ENTITY_DOES_NOT_HAS_DEFAULT_DASHBOARD: "ENTITY_DOES_NOT_HAS_DEFAULT_DASHBOARD",
        API_ACCESS_USERNAME_ALREADY_EXISTS: "API_ACCESS_USERNAME_ALREADY_EXISTS",
        ENTITY_CODE_IN_USE: "ENTITY_CODE_IN_USE",
        CANNOT_EDIT_ENTITY_OR_CATEGORY_OF_DEFAULT_INTERFACE: 'CANNOT_EDIT_ENTITY_OR_CATEGORY_OF_DEFAULT_INTERFACE',
        GENETICS_DISPLAY_NAME_IN_USE: 'GENETICS_DISPLAY_NAME_IN_USE',
        FEED_TYPE_DISPLAY_NAME_IN_USE: 'FEED_TYPE_DISPLAY_NAME_IN_USE',
        FEED_FACTORY_DISPLAY_NAME_IN_USE: 'FEED_FACTORY_DISPLAY_NAME_IN_USE',
        FEED_FACTORY_NOT_FOUND:'FEED_FACTORY_NOT_FOUND',
        PRODUCTION_CURVE_DISPLAY_NAME_IN_USE: 'PRODUCTION_CURVE_DISPLAY_NAME_IN_USE',
        SPECIES_DEATH_REASON_NAME_IN_USE: 'SPECIES_DEATH_REASON_NAME_IN_USE',
        FEED_TYPE_NOT_FOUND: 'FEED_TYPE_NOT_FOUND',
        PRODUCTION_BATCH_NOT_FOUND:'PRODUCTION_BATCH_NOT_FOUND',
        ACTION_TYPE_NOT_FOUND:'ACTION_TYPE_NOT_FOUND',
        TASK_TEMPLATE_NOT_FOUND: 'TASK_TEMPLATE_NOT_FOUND',
        WRONG_FILE_CONFIGURATION: 'WRONG_FILE_CONFIGURATION',
        MISSING_AGE_VALUES: 'MISSING_AGE_VALUES',
        MISSING_CURVE_DETAILS_VALUES: 'MISSING_CURVE_DETAILS_VALUES',
        ORIGIN_BATCH_CLOSED: 'ORIGIN_BATCH_CLOSED',
        DESTINATION_BATCH_CLOSED: 'DESTINATION_BATCH_CLOSED',
        TRANSACTION_WOULD_RESULT_IN_NEGATIVE_ANIMALS: 'TRANSACTION_WOULD_RESULT_IN_NEGATIVE_ANIMALS',
        CANNOT_DELETE_TASK_TEMPLATE_WITH_DEPENDENCIES:  'CANNOT_DELETE_TASK_TEMPLATE_WITH_DEPENDENCIES',
        DEATH_CAUSE_NOT_FOUND: 'DEATH_CAUSE_NOT_FOUND',
        CANNOT_CREATE_BATCH_FOR_SECTOR_WITH_OPEN_BATCH_IN_SAME_TIMEFRAME : 'CANNOT_CREATE_BATCH_FOR_SECTOR_WITH_OPEN_BATCH_IN_SAME_TIMEFRAME',
        TRANSACTION_CANNOT_BE_BEFORE_BATCH_OPEN_DATE: 'TRANSACTION_CANNOT_BE_BEFORE_BATCH_OPEN_DATE',
        EXIT_TRANSACTION_CANNOT_BE_PERFORMED_BEFORE_FIRST_TRANSACTION: 'EXIT_TRANSACTION_CANNOT_BE_PERFORMED_BEFORE_FIRST_TRANSACTION',
        TERMINAL_NOT_CONNECTED: 'TERMINAL_NOT_CONNECTED',
        TERMINAL_NOT_PROVISIONED: 'TERMINAL_NOT_PROVISIONED',
        TERMINAL_UPDATING: 'TERMINAL_UPDATING'
    },
    passwordAlerts:{
        CORE_RESPONSE_OLD_PASSWORD_EQUAL_TO_CURRENT_ERROR: "OLD_PASSWORD_EQUAL_TO_CURRENT",
    },

    productionBatchesAlerts:{
        EVENT_DATE_GTE_LAST_ANI_MOV_DATE: 'EVENT_DATE_GTE_LAST_ANI_MOV_DATE'
    },

    tables: {
        pollingTaskIntervalMilliseconds: 60000,
        default: {
            sizePerPageList: [{
                text: '10',
                value: 10
            }, {
                text: '20',
                value: 20
            }, {
                text: '50',
                value: 50
            }]
        },
        USERS: {
            ignoreCache: ["universalSearch"],
            cacheKey: 'usersListCache',
            defaultSort: {
                column: 'shortName',
                order: 'asc'
            },
            sizePerPageList: [{
                text: '10',
                value: 10
            }, {
                text: '20',
                value: 20
            }, {
                text: '50',
                value: 50
            }]
        },
        RULES: {
            ignoreCache: ["universalSearch"],
            cacheKey: 'rulesListCache',
            defaultSort: {
                column: 'lastUpdateTimestampUtc',
                order: 'desc'
            },
            sizePerPageList: [{
                text: '10',
                value: 10
            }, {
                text: '20',
                value: 20
            }, {
                text: '50',
                value: 50
            }]
        },
        RULES_TEMPLATE: {
            ignoreCache: ["universalSearch"],
            cacheKey: "ruleTemplate",
            defaultSort: {
                column: 'name',
                order: 'asc'
            },
            sizePerPageList: [{
                text: '10',
                value: 10
            }, {
                text: '20',
                value: 20
            }, {
                text: '50',
                value: 50
            }]
        },
        BATCHES: {
            ignoreCache: ["universalSearch"],
            cacheKey: 'batchListCache',
            defaultSort: {
                column: 'openDate',
                order: 'desc'
            },
            sizePerPageList: [{
                text: '10',
                value: 10
            }, {
                text: '20',
                value: 20
            }, {
                text: '50',
                value: 50
            }],
            dropzone: {
                maxFileSize: 15, /*in Mb*/
                maxFilesNumber: 15
            }
        },
        GENETICS: {
            ignoreCache: ["universalSearch"],
            cacheKey: 'geneticListCache',
            defaultSort: {
                column: 'code',
                order: 'asc'
            },
            sizePerPageList: [{
                text: '10',
                value: 10
            }, {
                text: '20',
                value: 20
            }, {
                text: '50',
                value: 50
            }]
        },
        CURVES: {
            ignoreCache: ["universalSearch"],
            cacheKey: 'curveListCache',
            defaultSort: {
                column: 'displayName',
                order: 'asc'
            },
            sizePerPageList: [{
                text: '10',
                value: 10
            }, {
                text: '20',
                value: 20
            }, {
                text: '50',
                value: 50
            }]
        },
        FEED_TYPES: {
            ignoreCache: ["universalSearch"],
            cacheKey: 'feedTypeListCache',
            defaultSort: {
                column: 'code',
                order: 'asc'
            },
            sizePerPageList: [{
                text: '10',
                value: 10
            }, {
                text: '20',
                value: 20
            }, {
                text: '50',
                value: 50
            }]
        },
        FEED_FACTORY: {
            ignoreCache: ["universalSearch"],
            cacheKey: 'feedFactoryListCache',
            defaultSort: {
                column: 'displayName',
                order: 'asc'
            },
            sizePerPageList: [{
                text: '10',
                value: 10
            }, {
                text: '20',
                value: 20
            }, {
                text: '50',
                value: 50
            }]
        },
        DEATH_REASON: {
            ignoreCache: ["universalSearch"],
            cacheKey: 'deathReasonListCache',
            defaultSort: {
                column: 'displayName',
                order: 'asc'
            },
            sizePerPageList: [{
                text: '10',
                value: 10
            }, {
                text: '20',
                value: 20
            }, {
                text: '50',
                value: 50
            }]
        },
        BATCH_DETAILS: {
            ignoreCache: ["universalSearch"],
            cacheKey: 'batchDetailsListCache',
            defaultSort: {
                column: 'creationTimestamp',
                order: 'desc'
            },
            sizePerPageList: [{
                text: '10',
                value: 10
            }, {
                text: '20',
                value: 20
            }, {
                text: '50',
                value: 50
            }]
        },
        BATCH_DETAILS_TASKS: {
            ignoreCache: ["universalSearch"],
            cacheKey: 'batchDetailsTasksListCache',
            defaultSort: {
                column: 'dueDate',
                order: 'desc'
            },
            sizePerPageList: [{
                text: '10',
                value: 10
            }, {
                text: '20',
                value: 20
            }, {
                text: '50',
                value: 50
            }]
        },
        BATCH_DETAILS_EVENTS: {
            ignoreCache: ["universalSearch"],
            cacheKey: 'batchDetailsEventsListCache',
            defaultSort: {
                column: 'openDate',
                order: 'desc'
            },
            sizePerPageList: [{
                text: '10',
                value: 10
            }, {
                text: '20',
                value: 20
            }, {
                text: '50',
                value: 50
            }]
        },
        BATCH_DETAILS_ANIMAL_MOVEMENTS: {
            ignoreCache: ["universalSearch"],
            cacheKey: 'batchDetailsAnimalMovementsListCache',
            defaultSort: {
                column: 'timestamp',
                order: 'desc'
            },
            sizePerPageList: [{
                text: '10',
                value: 10
            }, {
                text: '20',
                value: 20
            }, {
                text: '50',
                value: 50
            }]
        },
        BATCH_DETAILS_FEED_WATER: {
            ignoreCache: ["universalSearch"],
            cacheKey: 'batchDetailsFeedWaterListCache',
            defaultSort: {
                column: 'timestamp',
                order: 'desc'
            },
            sizePerPageList: [{
                text: '10',
                value: 10
            }, {
                text: '20',
                value: 20
            }, {
                text: '50',
                value: 50
            }]
        },
        BATCH_DETAILS_MANUAL_WEIGHING: {
            ignoreCache: ["universalSearch"],
            cacheKey: 'batchDetailsManualWeighingCache',
            defaultSort: {
                column: 'timestamp',
                order: 'desc'
            },
            sizePerPageList: [{
                text: '10',
                value: 10
            }, {
                text: '20',
                value: 20
            }, {
                text: '50',
                value: 50
            }]
        },
        FEED_MOVEMENTS: {
            ignoreCache: ["universalSearch"],
            cacheKey: 'feedMovementsListCache',
            defaultSort: {
                column: 'timestamp',
                order: 'desc'
            },
            sizePerPageList: [{
                text: '10',
                value: 10
            }, {
                text: '20',
                value: 20
            }, {
                text: '50',
                value: 50
            }]
        },
        AUTOMATIC_FEED_MOVEMENTS: {
            ignoreCache: ["universalSearch"],
            cacheKey: 'automaticFeedMovementsListCache',
            defaultSort: {
                column: 'timestamp',
                order: 'desc'
            },
            sizePerPageList: [{
                text: '10',
                value: 10
            }, {
                text: '20',
                value: 20
            }, {
                text: '50',
                value: 50
            }]
        },
        ANIMAL_MOVEMENTS: {
            ignoreCache: ["universalSearch"],
            cacheKey: 'animalMovementsListCache', 
            defaultSort: {
                column: 'timestamp',
                order: 'desc'
            },
            sizePerPageList: [{
                text: '10',
                value: 10
            }, {
                text: '20',
                value: 20
            }, {
                text: '50',
                value: 50
            }]
        },
        MANUAL_WEIGHING: {
            ignoreCache: ["universalSearch"],
            cacheKey: 'manualWeighingCache',
            defaultSort: {
                column: 'timestamp',
                order: 'desc'
            },
            sizePerPageList: [{
                text: '10',
                value: 10
            }, {
                text: '20',
                value: 20
            }, {
                text: '50',
                value: 50
            }]
        },
        TASK_TEMPLATES: {
            cacheKey: 'taskTemplatesListCache',
            ignoreCache: ["universalSearch"],
            defaultSort: {
                column: 'creationTimestamp',
                order: 'desc'
            },
            sizePerPageList: [{
                text: '10',
                value: 10
            }, {
                text: '20',
                value: 20
            }, {
                text: '50',
                value: 50
            }]
        },
        TASKS: {
            cacheBoardKey: 'taskBoardListCache',
            cacheKey: 'taskListCache',
            ignoreCache: ["universalSearch"],
            defaultSort: {
                column: 'dueDate',
                order: 'desc'
            },
            sizePerPageList: [{
                text: '10',
                value: 10
            }, {
                text: '20',
                value: 20
            }, {
                text: '50',
                value: 50
            }]
        },
        TERMINALS: {
            cacheKey: 'terminalsListCache',
            ignoreCache: ["universalSearch"],
            defaultSort: {
                column: 'lastCommunicationTimestampUtc',
                order: 'desc'
            },
            sizePerPageList: [{
                text: '10',
                value: 10
            }, {
                text: '20',
                value: 20
            }, {
                text: '50',
                value: 50
            }]
        },
        ENTITIES: {
            cacheKey: 'entitiesListCache',
            ignoreCache: ["universalSearch"],
            defaultSort: {
                column: 'displayName',
                order: 'asc'
            },
            sizePerPageList: [{
                text: '10',
                value: 10
            }, {
                text: '20',
                value: 20
            }, {
                text: '50',
                value: 50
            }]
        },
        INTERFACES: {
            cacheKey: 'interfacesListCache',
            ignoreCache: ["universalSearch"],
            defaultSort: {
                column: 'lastReadingTimestampUtc',
                order: 'desc'
            },
            sizePerPageList: [{
                text: '10',
                value: 10
            }, {
                text: '20',
                value: 20
            }, {
                text: '50',
                value: 50
            }]
        },
        INTERFACE_READINGS: {
            cacheKey: 'interfaceReadingListCache',
            ignoreCache: ["universalSearch"],
            defaultSort: {
                column: 'timestamp',
                order: 'desc'
            },
            sizePerPageList: [{
                text: '10',
                value: 10
            }, {
                text: '20',
                value: 20
            }, {
                text: '50',
                value: 50
            }],
        },
        EVENTS: {
            cacheKey: 'eventListCache',
            ignoreCache: ["universalSearch"],
            defaultSort: {
                column: 'openDate',
                order: 'desc'
            },
            sizePerPageList: [{
                text: '10',
                value: 10
            },{
                text: '20',
                value: 20
            },{
                text: '50',
                value: 50
            }],
            dropzone: {
                maxFileSize: 15, /*in Mb*/
                maxFilesNumber: 15
            }
        },
        HELPDESK: {
            cacheKey: 'helpdeskListCache',
            ignoreCache: ["universalSearch"],
            defaultSort: {
                column: 'openDate',
                order: 'desc'
            },
            sizePerPageList: [{
                text: '10',
                value: 10
            },{
                text: '20',
                value: 20
            },{
                text: '50',
                value: 50
            }],
            dropzone: {
                maxFileSize: 15, /*in Mb*/
                maxFilesNumber: 15
            }
        },
        ROLES: {
            ignoreCache: ["universalSearch"],
            defaultSort: {
                column: 'name',
                order: 'asc'
            },
            sizePerPageList: [{
                text: '10',
                value: 10
            }, {
                text: '20',
                value: 20
            }, {
                text: '50',
                value: 50
            }]
        },
        AUDIT: {
            ignoreCache: ["universalSearch"],
            cacheKey: 'auditListCache',
            defaultSort: {
                column: 'timestampUtc',
                order: 'desc'
            },
            sizePerPageList: [{
                text: '10',
                value: 10
            }, {
                text: '20',
                value: 20
            }, {
                text: '50',
                value: 50
            }]
        },
        TARIFFS: {
            ignoreCache: ["universalSearch"],
            cacheKey: 'tariffListCache',
            defaultSort: {
                column: 'displayName',
                order: 'asc'
            },
            sizePerPageList: [{
                text: '10',
                value: 10
            }, {
                text: '20',
                value: 20
            }, {
                text: '50',
                value: 50
            }]
        },
        API: {
            ignoreCache: ["universalSearch"],
            cacheKey: 'APIListCache',
            defaultSort: {
                column: 'displayName',
                order: 'asc'
            },
            sizePerPageList: [{
                text: '10',
                value: 10
            },{
                text: '20',
                value: 20
            },{
                text: '50',
                value: 50
            }],
        },
        SUBSCRIPTIONS : {
            ignoreCache: ["universalSearch"],
            cacheKey: 'reportsListCache',
            defaultSort: {
                column: 'displayName',
                order: 'asc'
            },
            sizePerPageList: [{
                text: '10',
                value: 10
            }, {
                text: '20',
                value: 20
            }, {
                text: '50',
                value: 50
            }]
        },
    },
    datepicker: {
        formats: {
            dateTime: 'DD/MM/YYYY HH:mm:ss',
            dateTimeList: 'DD-MM-YYYY HH:mm:ss',
            dateTimeListWithoutSeconds: 'DD-MM-YYYY HH:mm',
            date: 'DD/MM/YYYY',
            alternativeDate: 'YYYY-MM-DD',
            time: 'HH:mm:ss',
            timeWithoutSeconds: 'HH:mm',
            isoDateTime: 'YYYY-MM-DDTHH:mm:ss',
            isoDate: 'YYYY-MM-DD'
        }
    },
    labeledField: {
        maxTextLength: 150
    },
    timelineField: {
        maxElements: 4
    },
    map: {
        appKey: 'AIzaSyD4uGaeEQrEepMStBYOVly_vG2goxApUog',
        defaultCenter: {
            lat: 39.106491511,
            lng: -8.2270313
        },
        height: '450px',
        defaultZoom: 7,
        typeId: 'roadmap',
        placeZoom: 16,
        areaDecimalPlaces: 2,
        polygonMarkers: {
            color: 'green',
            scale: 5
        },
        searchBox: {
            style: {
                boxSizing: 'border-box',
                MozBoxSizing: 'border-box',
                border: '1px solid transparent',
                width: '250px',
                height: '32px',
                marginLeft: '10px',
                padding: '0 12px',
                borderRadius: '1px',
                boxShadow: '0 2px 6px rgba(0, 0, 0, 0.3)',
                fontSize: '14px',
                outline: 'none',
                textOverflow: 'ellipses',
            }
        },
        zoomValues: {
            min: 1,
            max: 20
        }
    },
    ipstack: {
        url: "http://api.ipstack.com/check?access_key=064ea945e07d02050fc40d6ec58994a9",
        defaultDialCode: '+351'
    },
    cache: {
        timeout: 300 //300 seconds
    },
    navigationRoutes : {
        TASKS: 'tasks',
        RULES: 'rules',
        PRODUCTION: 'production',
        SYSTEM: 'system',
        ANALYTICS: 'analytics'
    },
    errorUrl: "services/error/fe",
    ignoreUrl: ["/terms-of-use", "/data-protection-notice"]
};