import * as actionTypes from "./actionTypes";

const initialState =  {
    batchList: {
        loading: false,
        data: []
    },
    batchDetails: {
        loading: false
    },
    curveDetails: {
        loading: false
    }
};

const batchesReducer = (state = initialState, payload) => {
    switch (payload.type) {
        case actionTypes.GET_BATCH_LIST_REQUEST:
            return {
                ...state,
                batchList: {
                    ...state.batchList,
                    loading: true,
                    data: []
                }
            };
        case actionTypes.GET_BATCH_LIST_SUCCESS:
            return {
                ...state,
                batchList: {
                    ...state.batchList,
                    loading: false,
                    data:payload.payload
                }
            };
        case actionTypes.GET_BATCH_LIST_FAILED:
            return {
                ...state,
                batchList: {
                    ...state.batchList,
                    loading: false,
                    data: [],
                    error: payload
                }
            };
        case actionTypes.RESET_BATCH_LIST:
            return {
                ...state,
                batchList: {
                    ...state.batchList,
                    loading: false,
                    data: []
                }
            };


        case actionTypes.GET_BATCH_REQUEST:
            return {
                ...state,
                batchDetails: {
                    ...state.batchDetails,
                    loading: true
                }
            };
        case actionTypes.GET_BATCH_SUCCESS:
            return {
                ...state,
                batchDetails: {
                    ...state.batchDetails,
                    loading: false,
                    [payload.batch.id]: {
                        data: {
                            ...payload.batch
                        }
                    }
                }
            };
        case actionTypes.GET_BATCH_FAILED:
            return {
                ...state,
                batchDetails: {
                    ...state.batchDetails,
                    loading: false,
                    [payload.batch.id]: {
                        data: null
                    },
                    error: payload.payload
                }
            };

        case actionTypes.GET_PRODUCTION_CURVE_REQUEST:
            return {
                ...state,
                curveDetails: {
                    ...state.curveDetails,
                    loading: true
                }
            };
        case actionTypes.GET_PRODUCTION_CURVE_SUCCESS:
            return {
                ...state,
                curveDetails: {
                    ...state.curveDetails,
                    loading: false,
                    [payload.curveId] : payload.payload,
                    error: null
                }
            };
        case actionTypes.GET_PRODUCTION_CURVE_FAILED:
            return {
                ...state,
                curveDetails: {
                    ...state.curveDetails,
                    loading: false,
                    error: payload
                }
            };

        case actionTypes.GET_PRODUCTION_BATCH_WINDOW_REQUEST:
            return {
                ...state,
                batchDetails: {
                    [payload.batchId] : {
                        ...state.batchDetails[payload.batchId],
                        window: {
                            loading: true
                        }
                    }
                }
            };
        case actionTypes.GET_PRODUCTION_BATCH_WINDOW_SUCCESS:
            return {
                ...state,
                batchDetails: {
                    ...state.batchDetails,
                    [payload.batchId] : {
                        ...state.batchDetails[payload.batchId],
                        window: {
                            loading: false,
                            error: null,
                            ...payload.payload,
                        }
                    }
                }
            };
        case actionTypes.GET_PRODUCTION_BATCH_WINDOW_FAILED:
            return {
                ...state,
                batchDetails: {
                    ...state.batchDetails,
                    [payload.batchId] : {
                        ...state.batchDetails[payload.batchId],
                        window: {
                            loading: false,
                            error: payload.payload
                        }
                    }
                }
            };
        default:
            return state;
    }
};

export default batchesReducer;