import _ from "lodash";
import axios from "axios";
import UserSessionService from "./UserSessionService";
import UserLanguageService from "./UserLanguageService";
import DialCodesUtils from "../utils/DialCodesUtils";
import __ from "../utils/EnhancedLodash";

let AuthenticationService = {
    login: function (username, password, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        axios.post("/auth", {
            email: username,
            password: password
        }).then(response => {
            if(_.isNil(response) || _.isNil(response.data.sessionToken) || _.isEmpty(response.data.shortName)){
                onError("Mandatory session fields missing: sessionToken or shortName");
                return;
            }
            UserSessionService.storeAuthInfo(response.data);
            UserLanguageService.setUserLanguage(UserSessionService.getCurrentUserLanguage());
            DialCodesUtils.loadToCacheDefaultDialCode();
            axios.defaults.headers.common["Authorization"] = "Bearer " + UserSessionService.getSessionToken();

            onSuccess(response.data);
        }, error => {
            onError(error.response.status);
        });
    },

    logout: function (forcedLogout, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        axios.delete("/auth", {
            headers: {
                Authorization: "Bearer " + UserSessionService.getSessionToken()
            }
        }).then(() => {
            UserSessionService.removeAuthInfo(forcedLogout);
            delete axios.defaults.headers.common["Authorization"];
            onSuccess();
        }, error => {
            onError(error);
        });
    },

    extendSessionToken: function(onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        axios.put("/auth", {}).then(response => {
            UserSessionService.replaceTokenWith(response.data);
            onSuccess(response.data);
        }, error => {
            onError(error);
        });
    },

    logoutWithoutRequest: function (history) {
        UserSessionService.removeAuthInfo(true);
        UserLanguageService.setDefaultLanguage();
        delete axios.defaults.headers.common["Authorization"];
        if(!_.includes(window.location.href, "/login")) {
            if(!__.isNilOrEmpty(history)){
                history.push('/login');
            } else {
                window.history.pushState(null, null, '/login');
                window.location.reload();
            }
        }
    },

    activateAccount: function (userId, code, password, onSuccess, onError) {
        let url = "/users/" + userId + "/account?resetPassword=false";
        sendAccountPasswordRequest(url, code, password, onSuccess, onError);
    },

    resetPassword: function (userId, code, password, onSuccess, onError) {
        let url = "/users/" + userId + "/account?resetPassword=true";
        sendAccountPasswordRequest(url, code, password, onSuccess, onError);
    },

    tokenAuth: function(token, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        axios.post("/auth/token-auth", { token })
            .then(response => {
                if (_.isNil(response) || _.isNil(response.data.sessionToken)) {
                    onError("Mandatory session fields missing: sessionToken");
                    return;
                }

                UserSessionService.storeAuthInfo(response.data);
                UserLanguageService.setUserLanguage(UserSessionService.getCurrentUserLanguage());
                axios.defaults.headers.common["Authorization"] = "Bearer " + UserSessionService.getSessionToken();

                onSuccess(response.data);
            })
            .catch(error => {
                onError(error.response.status);
            });
    },
};

function sendAccountPasswordRequest(url, code, password, onSuccess, onError) {
    onSuccess = onSuccess ? onSuccess : () => {};
    onError = onError ? onError : () => {};

    axios.post(url, {
        activationCode: code,
        password: password
    }).then(response => {
        UserSessionService.removeAuthInfo();
        delete axios.defaults.headers.common["Authorization"];
        onSuccess(response);
    }, error => {
        onError(error.response.status);
    });
}

export default AuthenticationService;