import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import I18n from 'i18next';
import _ from "lodash";
import HelperIcon from './../../../img/helpFieldIcon.svg';
import __ from "../../../utils/EnhancedLodash";
import Tooltip from "../../tooltip/Tooltip";

class SwitchField extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isChecked: !_.isNil(props.isChecked)? props.isChecked : true,
            isVisible: !_.isNil(props.isVisible)? props.isVisible : true,
            checkedLabel: props.checkedLabel,
            uncheckedLabel: props.uncheckedLabel,
            disabled: !_.isNil(props.disabled)? props.disabled : false,
            forceNewPropValue: !_.isNil(props.forceNewPropValue)? props.forceNewPropValue : false,
            paddingTop: props.paddingTop ? props.paddingTop : "5"
        };
        this.toggleSwitch = this.toggleSwitch.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        let newState = {
            checkedLabel: nextProps.checkedLabel,
            uncheckedLabel: nextProps.uncheckedLabel,
            isVisible: !_.isNil(nextProps.isVisible) ? nextProps.isVisible : this.state.isVisible,
            paddingTop: nextProps.paddingTop ? nextProps.paddingTop : "5"
        };
        if(nextProps.forceNewPropValue) {
            if(!__.isNilOrEmpty(nextProps.isChecked)) {
                newState.isChecked = nextProps.isChecked;
            }
            if(!__.isNilOrEmpty(nextProps.isVisible)) {
                newState.isVisible = nextProps.isVisible;
            }
            if(!__.isNilOrEmpty(nextProps.disabled)) {
                newState.disabled = nextProps.disabled;
            }
        }
        this.setState(newState);
    }

    setFieldValue(data, afterFieldIsSet=()=>{}){
        this.setState({isChecked: data}, () => afterFieldIsSet());
    }

    setFieldValueAndIsDisable(isChecked, isDisable, afterFieldIsSet=()=>{}) {
        this.setState({isChecked: isChecked, disabled: isDisable}, () => afterFieldIsSet());
    }

    validateField() {
        return true;
    }

    setVisibility(isVisible) {
        this.setState({
            isVisible: isVisible
        })
    }

    isVisible() {
        return this.state.isVisible;
    }

    getFieldValue(){
        return this.state.isChecked;
    }

    setDisable(isDisable) {
        this.setState({
            disabled: isDisable
        });
    }

    toggleSwitch(e) {
        e.stopPropagation();
        let self = this;
        let afterSwitchIsToggled = this.props.onFieldChange? this.props.onFieldChange : () => {};

        this.setState({isChecked: !this.state.isChecked}, () => {
            afterSwitchIsToggled(self.getFieldValue());
        });
    }

    render() {
        if(!this.state.isVisible){
            return null;
        } else {
            return (
                <div className={classnames("form-group","col-lg-" + this.props.width, {"mb-zero-absolute": !_.isNil(this.props.bottomMargin) && !this.props.bottomMargin}, {"flex-row": this.props.inline}, this.props.className)}>
                    {getField(this)}
                </div>
            );
        }
    }
}

function getField(component) {
    let element;
    if(component.props.isView) {
        let label = component.props.fieldLabel? getFieldLabel(component) : null;
        element = (
            <div>
                {label}
                {getLabel(component)}
            </div>
        );
    } else {
        let label = component.props.fieldLabel? getFieldLabel(component) : null;
        let switchField = getSwitchField(component);
        element = (
            <div className={classnames({"flex-row": component.props.inline})}>
                {label}
                {switchField}
                {component.props.inline && getLabel(component)}
            </div>
        );
    }
    return element;
}

function getFieldLabel(component) {
    let labelStyle = component.props.labelWidth ? {width: component.props.labelWidth} : {};
    const pdTop = component.props.isView ? `pd-t-${component.state.paddingTop}` : "pd-t-0";
    let tooltipHelper =  !_.isNil(component.props.tooltip) && (
        <Tooltip content={component.props.tooltip}>
            {<img alt="" className='pd-r-15 pd-l-10 labeled-field-helper-icon' src={HelperIcon}/>}
        </Tooltip>
    );

    return (
        <div style={labelStyle}>
            <label className={classnames(pdTop,{"pd-r-15": component.props.inline}, {"m-b-none": !component.props.noMarginBottom})}>
                {component.props.fieldLabel}
            </label>
            {tooltipHelper}
        </div>
    );
}

function getSwitchField(component) {
    let switchField = null;
    const {
        onOff,
        right,
        invert
    } = component.props;

    if(onOff){
        switchField = (
            <div className="switch">
                <div className="onoffswitch" onClick={(e)=> {component.toggleSwitch(e)}}>
                    <input type="checkbox" cursor="false" checked={component.state.isChecked} onChange={(e)=>{component.toggleSwitch(e)}} className="onoffswitch-checkbox" disabled={component.state.disabled}/>
                    <label className={classnames("onoffswitch-label", {"mb-zero-absolute": !_.isNil(component.props.bottomMargin) && !component.props.bottomMargin})}>
                        <span className="onoffswitch-inner"/>
                        <span className="onoffswitch-switch"/>
                    </label>
                </div>
            </div>);
    } else if(right && invert) {
        const getLeftLabel = ( component ) => {
            let label;
            if(component.state.isChecked){
                label = <span className={classnames("pd-r-10",component.props.checkedLabelClassName, {"switch-field-child": !component.props.inline})}>{component.state.checkedLabel? component.state.checkedLabel : I18n.t('generic.active')}</span>;
            } else {
                label = <span className={classnames("pd-r-10",component.props.uncheckedLabelClassName, {"switch-field-child": !component.props.inline})}>{component.state.uncheckedLabel? component.state.uncheckedLabel : I18n.t('generic.inactive')}</span>;
            }
            return label;
        }

        switchField = (
            <div className={classnames({"switch-field-parent float-right": !component.props.inline})}>
                {!component.props.inline && getLeftLabel(component)}
                <input type="checkbox" cursor="false" onClick={(e)=> {component.toggleSwitch(e)}} checked={component.state.isChecked} disabled={component.state.disabled} className={classnames("uiswitch switch-field m-l-10", {"switch-field-child": !component.props.inline})}/>
            </div>
        );
    } else {
        switchField = (
            <div className={classnames({"switch-field-parent": !component.props.inline})}>
                <input type="checkbox" cursor="false" onClick={(e)=> {component.toggleSwitch(e)}} checked={component.state.isChecked} disabled={component.state.disabled} className={classnames("uiswitch switch-field", {"switch-field-child": !component.props.inline})}/>
                {!component.props.inline && getLabel(component)}
            </div>
        );
    }
    return switchField;
}

function getLabel(component) {
    let label;
    const pdTop = component.props.isView ? `pd-t-${component.state.paddingTop}` : "pd-t-0";
    if(component.state.isChecked){
        label = <span className={classnames(pdTop,{"pd-l-10": !component.props.isView},component.props.checkedLabelClassName, {"switch-field-child": !component.props.inline})}>{component.state.checkedLabel? component.state.checkedLabel : I18n.t('generic.active')}</span>;
    } else {
        label = <span className={classnames(pdTop,{"pd-l-10": !component.props.isView},component.props.uncheckedLabelClassName, {"switch-field-child": !component.props.inline})}>{component.state.uncheckedLabel? component.state.uncheckedLabel : I18n.t('generic.inactive')}</span>;
    }
    return label;
}

SwitchField.propTypes = {
    forceNewPropValue: PropTypes.bool,
    paddingTop: PropTypes.string,
    fieldLabel: PropTypes.string,
    width: PropTypes.string.isRequired,
    checkedLabel: PropTypes.string,
    uncheckedLabel: PropTypes.string,
    checkedLabelClassName: PropTypes.string,
    uncheckedLabelClassName: PropTypes.string,
    className: PropTypes.string,
    isView: PropTypes.bool,
    inline: PropTypes.bool,
    onOff: PropTypes.bool,
    isChecked: PropTypes.bool,
    noMarginBottom:PropTypes.bool,
    onFieldChange: PropTypes.func,
    isVisible: PropTypes.bool,
    disabled: PropTypes.bool
};

export default SwitchField;