import React from "react";
import _ from "lodash";
import PropTypes from 'prop-types';
import Modal from 'react-modal';
import Loading from "../loading/Loading";

class ModalComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state={
            modalIsOpen: false,
            data: this.props.data,
            className: _.isNil(this.props.className) ? "" : this.props.className
        };
    }

    componentWillReceiveProps(nextProps, nextContext) {
        this.setState({
            data: !_.isNil(nextProps.data)? nextProps.data : this.state.data,
            className: !_.isNil(nextProps.className)? nextProps.className : this.state.className
        });
    }

    componentWillUnmount() {
        removeModalBodyClassName();
    }

    showLoading() {
        this.showModal(<Loading className="full-height"/>, 'loading-modal');
    }

    setData(data, afterDataIsSet=()=>{}) {
        this.setState({
            data: data
        }, afterDataIsSet);
    }

    showModal(data, className=null) {
        addModalBodyClassName();

        let state = {modalIsOpen: true};

        if(data) {
            state.data = data;
        }

        if(!_.isNil(className)) {
            state.className = className;
        }

        this.setState(state);

        if(this.props.onModalComponentOpened) {
            this.props.onModalComponentOpened();
        }

    }

    hideModal(){
        removeModalBodyClassName();
        this.setState({modalIsOpen: false});
        if(this.props.onModalComponentClosed) {
            this.props.onModalComponentClosed();
        }
    }

    render() {
        let self = this;
        const { modalIsOpen, className, data } = this.state;
        const { shouldCloseOnOverlayClick = true, shouldCloseOnEsc = true } = this.props;
        return (
            <Modal
                isOpen={modalIsOpen}
                className={'fc-modal ' + className}
                shouldCloseOnEsc={shouldCloseOnEsc}
                onRequestClose={() => self.hideModal()}
                contentLabel=""
                shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
                appElement={document.getElementById('root')}
                ref={c => self.modal = c}>
                {data}
            </Modal>
        );
    }
}

function addModalBodyClassName() {
    if (document && document.body && !document.body.className.match("modal-open")) {
        let orig = document.body.className;
        document.body.className = orig + (orig ? ' ' : '') + 'modal-open';
    }
}

function removeModalBodyClassName() {
    if (document && document.body) {
        document.body.className = document.body.className.replace(/ ?modal-open/, '');
    }
}

ModalComponent.propTypes = {
    data: PropTypes.any,
    className: PropTypes.string,
    onModalComponentOpened: PropTypes.func,
    onModalComponentClosed: PropTypes.func,
    shouldCloseOnEsc: PropTypes.bool,
    shouldCloseOnOverlayClick: PropTypes.bool
};


export default ModalComponent;
