import _ from "lodash";
import moment from "moment";
import {config} from "../../config";

export const getEnterprises = state => {
    let requestTimestamp = state.entitiesReducer.enterpriseList.timestamp;

    if(!_.isNil(requestTimestamp) && !_.isUndefined(requestTimestamp) && moment().utc().diff(requestTimestamp, "seconds") > config.cache.timeout) {
        return null;
    }
    return state.entitiesReducer.enterpriseList.data;
};