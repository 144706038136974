import React from 'react';
import GenericInput from "../../fields/input/GenericInput";

const GenericTabContent = (props) => {

    return props.inputs ? (
            Object.values(props.inputs).map(input => (
                <GenericInput
                    key={input.id}
                    parentForm={props.refs}
                    globalRule={props.isGlobalRule}
                    input={input}
                    tabNumber={props.tabNumber}
                    editMode={props.editMode}
                    viewMode={props.viewMode}
                    selectedEntity={props.selectedEntity}
                    selectedTerminal={props.selectedTerminal}
                    selectedSector={props.selectedSector}
                    selectedRule={props.selectedRule}
                    showAllInterfaces={props.showAllInterfaces}
                    history={props.history}
                />
            ))
        ) : null
}

export default GenericTabContent;