import {call, put, takeEvery} from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import _ from "lodash";
import AsyncEntityService from "../../services/async/AsyncEntityService";
import EntityService from "../../services/EntityService";
import AsyncWidgetService from "../../services/async/AsyncWidgetService";
import LocalesUtils from "../../utils/LocalesUtils";
import AsyncInterfaceService from "../../services/async/AsyncInterfaceService";
import __ from "../../utils/EnhancedLodash";

const getEntityInterfaces = entityId => AsyncEntityService.getEntityInterfaces(entityId);
const fetchRealTimeWidgetDataAsync = interfaceIds => AsyncWidgetService.fetchRealTimeWidgetDataAsync(interfaceIds);
const getLastAggregatedReadings = interfaceIds => AsyncInterfaceService.getLastAggregatedReadings(interfaceIds);

function* getEntityInterface({dashboard}) {
    let interfacesId = [];

    try {
        const interfaces = yield call(getEntityInterfaces, dashboard.entity.id);

        const entityType = dashboard.entity.type.name;

        let group = _.groupBy(interfaces, _.property('entity.id'));
        if(entityType === EntityService.entityType.FARM.name) {
            //delete group[dashboard.entity.id];
        }
        let sectors = [];
        _.forOwn(group, (interfaceArray, key) => {
            let sector = {
                sector: interfaceArray[0].entity,
                categories: []
            };

            let groupCategory = _.groupBy(interfaceArray, _.property('category.id'));
            _.forOwn(groupCategory, (interfaceArray, key) => {
                let category = {
                    category : {
                        id: interfaceArray[0].category.id,
                        name: _.isObject(interfaceArray[0].category) ? LocalesUtils.filterLocalesOfObject(interfaceArray[0].category).name : interfaceArray[0].category
                    },
                    interfaces: interfaceArray
                };
                interfacesId = [...interfacesId, ...interfaceArray.map(inter => inter.id)];
                sector.categories.push(category);
            });
            sectors.push(sector);
        });

        sectors = _.sortBy(sectors, _.property("sector.displayName"));

        yield put({
            type: actionTypes.TREE_ENTITY_INTERFACES_SUCCESS,
            tree: sectors,
            interfacesId: interfacesId
        });

    } catch (e) {
        yield put({
            type: actionTypes.TREE_ENTITY_INTERFACES_ERROR
        });
    }

    if(!__.isNilOrEmpty(interfacesId)) {
        try {
            const readingsArray = yield call(fetchRealTimeWidgetDataAsync, [...new Set(interfacesId)]);
            yield put({
                type: actionTypes.TREE_ENTITY_INTERFACES_REAL_TIME_DATA_SUCCESS,
                readings: readingsArray
            });
        } catch (e) {
            yield put({
                type: actionTypes.TREE_ENTITY_INTERFACES_REAL_TIME_DATA_ERROR
            });
        }

        try {
            const readingsArray = yield call(getLastAggregatedReadings, [...new Set(interfacesId)]);

            yield put({
                type: actionTypes.TREE_ENTITY_INTERFACES_AGGREGATE_DATA_SUCCESS,
                readings: readingsArray
            });
        } catch (e) {
            yield put({
                type: actionTypes.TREE_ENTITY_INTERFACES_AGGREGATE_DATA_ERROR
            });
        }
    }
}

function* fetchWidgetReadings({ interfaceIds }) {
    try {
        const readingsArray = yield call(fetchRealTimeWidgetDataAsync, [...new Set(interfaceIds)]);
        yield put({
            type: actionTypes.TREE_ENTITY_INTERFACES_REAL_TIME_DATA_SUCCESS,
            readings: readingsArray
        });
    } catch (e) {
        yield put({
            type: actionTypes.TREE_ENTITY_INTERFACES_REAL_TIME_DATA_ERROR
        });
    }
}

function* fetchLastAggregatedReadings({ interfaceIds }) {
    try {
        const readingsArray = yield call(getLastAggregatedReadings, [...new Set(interfaceIds)]);

        yield put({
            type: actionTypes.TREE_ENTITY_INTERFACES_AGGREGATE_DATA_SUCCESS,
            readings: readingsArray
        });
    } catch (e) {
        yield put({
            type: actionTypes.TREE_ENTITY_INTERFACES_AGGREGATE_DATA_ERROR
        });
    }
}

const dashboardTreeSagas = [
    takeEvery(actionTypes.TREE_ENTITY_INTERFACES_REQUEST, getEntityInterface),
    takeEvery(actionTypes.TREE_ENTITY_INTERFACES_REAL_TIME_DATA_REQUEST, fetchWidgetReadings),
    takeEvery(actionTypes.TREE_ENTITY_INTERFACES_AGGREGATE_DATA_REQUEST, fetchLastAggregatedReadings)
];

export { dashboardTreeSagas };
