import { call, put, takeEvery } from 'redux-saga/effects';
import * as consumptionActionTypes from './actionTypes';
import * as actionTypes from './../../actionTypes';
import AsyncWidgetService from "../../../../services/async/AsyncWidgetService";

function* fetchConsumptionData({ id }) {
    try {
        const consumptionReadings = yield call(getConsumptionReadingsDataRequest, id);
        yield put({
            type: actionTypes.LOAD_WIDGET_DATA_SUCCESS,
            id: id,
            data: consumptionReadings
        })
    } catch (e) {
        yield put({
            id: id,
            type: actionTypes.LOAD_WIDGET_DATA_FAILED
        });
    }
}

const consumptionSagas = [
    takeEvery(consumptionActionTypes.LOAD_CONSUMPTION_WIDGET_DATA_REQUEST,  fetchConsumptionData)
];

const getConsumptionReadingsDataRequest = (widgetId) => {
    return AsyncWidgetService.getAsyncConsumptionWidgetReadings(widgetId);
};

export { consumptionSagas };
