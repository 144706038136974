import axios from "axios";

const AsyncDashboardService = {
    getUserDashboardList: function () {
        return new Promise((accept, reject) => {
            axios.get("/dashboard/list").then(response => {
                accept(response.data);
            }, reject);
        });
    },

    getEntityDashboardList: function () {
        return new Promise((accept, reject) => {
            axios.get("/dashboard/favourites").then(response => {
                accept(response.data);
            }, reject);
        });
    },

    getHomepageDashboard: function() {
        return new Promise((accept, reject) => {
            axios.get("/dashboard/homepage").then(response => {
                accept(response.data);
            }, reject);
        });
    },

    createDashboard: function (dashboardName) {
        return new Promise( (accept, reject) => {
            axios.post( "/dashboard", {
                displayName : dashboardName
            }).then(response => {
                accept(response.data);
            }, reject);
        });
    },

    updateDashboardName: function (dashboardId, dashboardName) {
        return new Promise( (accept, reject) => {
            axios.put( "/dashboard/" + dashboardId , {
                displayName: dashboardName
            }).then((response) => {
                accept(response.data);
            }, reject);
        });
    },

    deleteDashboard: function (dashboardId) {
        return new Promise( (accept, reject) => {
            axios.delete( "/dashboard/" + dashboardId).then((response) => {
                accept(response.data);
            }, reject);
        });
    },

    setDashboardHomePage: function (defaultId) {
        return new Promise( (accept, reject) => {
            axios.put( "/dashboard/homepage", {dashboardId:defaultId}).then((response) => {
                accept(response.data);
            }, reject);
        });
    },

    addDashboardFavourite: function (ids){
        return new Promise((accept, reject) => {
            axios.put( "/dashboard/favourites/add", ids).then((response) => {
                accept(response.data);
            }, reject);
        });
    },

    removeEntityDashboardFavourite: function (ids){
        return new Promise((accept, reject) => {
            axios.put( "/dashboard/favourites/remove", ids).then((response) => {
                accept(response.data);
            }, reject);
        });
    },

    removeDashboardFavourite: function (dashboardId) {
        return new Promise( (accept, reject) => {
            axios.delete( "/dashboard/favourites/" + dashboardId).then((response) => {
                accept(response.data);
            }, reject);
        });
    },

    copyEntityDashboardToUser: function (dashboardId) {
        return new Promise((accept, reject) => {
            axios.post( "/dashboard/copy/" + dashboardId , {}).then((response) => {
                accept(response.data);
            }, reject);
        });
    }

};

export default AsyncDashboardService;
