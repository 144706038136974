import React from "react";
import {t} from "../../../../../utils/Translation";
import Row from "../../../../../components/row/Row";
import RuleService from "../../../../../services/RuleService";
import ImgButton from "../../../../../components/buttons/ImgButton";
import back from "../../../../../img/back/back.svg";
import backOnHover from "../../../../../img/back/back_on_over.svg";
import edit from "../../../../../img/edit/edit.svg";
import editOnHover from "../../../../../img/edit/edit_on_over.svg";
import editSelected from "../../../../../img/edit/edit_selected.svg";
import editSelectedOnHover from "../../../../../img/edit/edit_selected_on_over.svg";
import editDisable from "../../../../../img/edit/edit_disabled.svg";
import trash from "../../../../../img/trash/trash.svg";
import trashOnHover from "../../../../../img/trash/trash_on_over.svg";
import trashDisable from "../../../../../img/trash/trash_disabled.svg";
import Alerts from "../../../../../utils/AlertUtils";
import Loading from "../../../../../components/loading/Loading";
import BasePanel from "../../../../../components/panels/basepanel/BasePanel";
import EntityAudit from "../../../../base/entityAudit/EntityAuditView";
import classnames from 'classnames';
import useWindowSize from "../../../../../hooks/useWindowSize";
import __ from "../../../../../utils/EnhancedLodash";
import TabbedPanel from "../../../../../components/panels/tabs/TabbedPanel";
import HelperIcon from "../../../../../img/helpFieldIcon.svg";
import RoundedButton from "../../../../../components/buttons/RoundedButton";

const RuleDetailsForm = (props) => {
    const {isMobile} = useWindowSize();
    const {
        loading, data, isView, isEdit,isCreate, isDeleted, hasEditPermission, hasDeletePermission, deleteOrEditIsPossible,
        isSystemRule, tab, onTabSelected, ruleDetails
    } = props;

    return (
        <BasePanel>
            <div className="ml-8-xs-sm create-form-button">
                <div className={"form-top-menu"}>
                    <div onClick={props.onBack}>
                        <ImgButton
                            alt = "back"
                            image={back}
                            onHoverImage = {backOnHover}
                        />
                    </div>
                    { !isCreate && !isDeleted && !isSystemRule &&
                    <div className={"flex"}>
                        {
                            <ImgButton
                                alt = "rule template helper"
                                handleIconClick={() => handleRuleInfoPointClick(data.templateId.templateDetails)}
                                image={HelperIcon}
                                tooltip={t('rules.list.tooltips.ruleTemplate.infoPoint')}
                                onHoverImage = {HelperIcon}
                            />
                        }
                        {
                            hasEditPermission &&
                            <ImgButton
                                alt = "edit"
                                handleIconClick={props.onEdit}
                                image={isView ? edit : editSelected}
                                onHoverImage = {isView ? editOnHover : editSelectedOnHover}
                                disableImage={editDisable}
                                disabled={!((isView || isEdit) && deleteOrEditIsPossible)}
                            />
                        }
                        {
                            hasDeletePermission &&
                            <ImgButton
                                alt = "trash"
                                handleIconClick={props.onDelete}
                                image={trash}
                                onHoverImage = {trashOnHover}
                                disableImage={trashDisable}
                                disabled={!((isView || isEdit) && deleteOrEditIsPossible)}
                            />
                        }
                    </div>
                    }
                </div>
            </div>
            <div className={classnames({"multifeed-label-fields-padding": isMobile})}>
                { loading && <Loading className="form-loading"/> }
                <div className={loading ? "opacity-0" : "opacity-1" }>
                    <Row>
                        <div className={classnames({"rule-form col-md-12 multifeed-label-fields-padding": isMobile, "rule-form col-md-12": !isMobile})}>
                            {getFormHeader(props)}
                            <TabbedPanel
                                forceActiveTabPosition={true}
                                activeTabPosition={tab}
                                onTabSelected={onTabSelected}
                                tabHeaderClass='rule-tab-header'
                                className="rule-form-mobile-top-header"
                                tabTitles={[t('rules.form.tabs.basicInfo.header'), ...props.panelsTitle]}
                                tabPanels={props.tabPanels}
                            />
                        </div>
                    </Row>
                    <div className="flex flex-row-reverse justify-between align-items-end pd-b-20 ">
                        {createFormButtons(props)}
                        {
                            isView && <div>
                                <EntityAudit
                                    createdBy={ruleDetails.createdBy}
                                    creationTimestampUtc={ruleDetails.creationTimestampUtc}
                                    lastUpdatedBy={ruleDetails.lastUpdatedBy}
                                    lastUpdateTimestampUtc={ruleDetails.lastUpdateTimestampUtc}
                                    deletedTimestamp={ruleDetails.deletedTimestamp}
                                />
                            </div>
                        }
                    </div>
                </div>
            </div>
        </BasePanel>
    );
}

function getFormHeader(props) {
    const { data, isView, isEdit} = props;
    let ruleName = data.displayName?.value ? data.displayName.value : "";
    let templateName = data.templateId?.value ? data.templateId.value.name : "";
    let terminal = data.terminalId?.value ? data.terminalId.value : "";
    let timezone = terminal ? getTerminalTimezoneHeader(terminal) : null;

    if(isView || isEdit) {
        return (
            <div className="form-header">
                { ruleName && <h2 className="mr-7" style={{display: "inline"}}>{ruleName}</h2> }
                { templateName && <h2 style={{display: "inline", fontSize: "18px", fontStyle: "italic"}}>{templateName}</h2> }
                {timezone}
            </div>
        );
    } else {
        if(ruleName && templateName) {
            ruleName = ruleName + ' ';
            templateName = templateName + ' ';
        } else if(ruleName) {
            ruleName = ruleName + ' ';
        } else if(templateName) {
            templateName = templateName  + ' ' ;
        }
        return (
            <div className="form-header">
                { ruleName && <h2 className="mr-7" style={{display: "inline"}}>{ruleName}</h2> }
                { templateName && <h2 style={{display: "inline", fontSize: "18px", fontStyle: "italic"}}>{templateName}</h2> }
                {timezone}
            </div>
        );
    }
}
function getTerminalTimezoneHeader(terminal) {
    if(terminal && terminal.timeZone) {
        const timeZoneStr = __.isObject(terminal.timeZone) ? terminal.timeZone.name : terminal.timeZone;
        return (
            <h4 className="ml-12 mt-10 mb-zero-absolute" style={{fontWeight: '500'}}>
                {t('rules.form.tabs.basicInfo.terminalTimezone.header', {terminalTimezone: timeZoneStr})}
            </h4>
        );
    }
}
function createFormButtons(props) {

    let { isEdit, isCreate, isView, deleteOrEditIsPossible, data: {deploymentStatus}, isGlobalRule,
        onFormEditSubmit, onFormCreateSubmit, onUnDeploySubmit, onDeploySubmit, ruleDetails } = props;

    if(isGlobalRule){
        deploymentStatus = ruleDetails.status === RuleService.deploymentStatus.ENABLED ? RuleService.deploymentStatus.DEPLOYED :
            RuleService.deploymentStatus.UNDEPLOYED
    }

    const hasRuleGroup = ruleDetails?.ruleGroups?.length > 0;

    const isRuleDeployed = deploymentStatus === RuleService.deploymentStatus.DEPLOYED;

    if(props.isDeleted || props.isSystemRule){
        return (
            <Row>
                <div className="col-lg-5 col-lg-offset-7 pd-r-25">
                    <div className="pull-right">
                    </div>
                </div>
            </Row>
        );
    } else if(isEdit && deleteOrEditIsPossible) {
        return (
            <Row>
                <div className="col-lg-5 col-lg-offset-7 pd-r-25">
                    <div className="pull-right">
                        <>
                            {!isRuleDeployed &&
                            <RoundedButton onClick={() => onFormEditSubmit(false, hasRuleGroup)}
                                           label={t('rules.form.buttons.editRule')}
                                           classes="btn-buffer rounded-btn-success"/>
                            }
                            <RoundedButton onClick={() => onFormEditSubmit(true, hasRuleGroup)}
                                           label={t('rules.form.buttons.editRuleAndDeploy')}
                                           classes="btn-buffer rounded-btn-success"/>
                        </>
                    </div>
                </div>
            </Row>
        );
    } else if(isCreate) {
        return (
            <Row>
                <div className="col-lg-5 col-lg-offset-7 pd-r-25">
                    <div className="pull-right">
                        <RoundedButton onClick={() => onFormCreateSubmit(false)}
                                       classes="btn-buffer rounded-btn-success"
                                       label={t('rules.form.buttons.addRule')}/>
                        <RoundedButton onClick={() => onFormCreateSubmit(true)}
                                       classes="btn-buffer rounded-btn-success"
                                       label={t('rules.form.buttons.addAndDeploy')}/>
                    </div>
                </div>
            </Row>
        );
    } else if(isView && deleteOrEditIsPossible) {
        return (
            <Row>
                <div className="col-lg-5 col-lg-offset-7 pd-r-25">
                    <div className="pull-right">
                        {
                            deploymentStatus === RuleService.deploymentStatus.DEPLOYED &&
                            <RoundedButton onClick={onUnDeploySubmit}
                                           classes="btn-buffer rounded-btn-success"
                                           label={t('rules.form.buttons.undeploy')} />
                        }
                        {
                            deploymentStatus === RuleService.deploymentStatus.UNDEPLOYED &&
                            <RoundedButton onClick={onDeploySubmit}
                                           classes={"btn-buffer rounded-btn-success"}
                                           label={t('rules.form.buttons.deploy')} />
                        }
                    </div>
                </div>
            </Row>
        );
    }
}
RuleDetailsForm.defaultProps = {
    loading: false,
    data: {}
}
export function handleRuleInfoPointClick(data) {
    const sanitizeDescriptionLength = data?.description.replace(/<\/?[^>]+(>|$)/g, "").length;
    const descriptionText = sanitizeDescriptionLength>0 ? data.description : t('rules.form.tabs.basicInfo.ruleDescription.default');
    const descriptionWidth = sanitizeDescriptionLength>100 ? '75%' : '50%';

    Alerts.default({
        title: data?.name || '',
        html: `<div style="text-align: left">${descriptionText}</div>`,
        width: descriptionWidth
    }).show();
}

export default RuleDetailsForm;