import * as actionTypes from "./actionTypes";

const initialState =  {
    lastUrl: undefined,
    currentUrl: undefined
};

const routerReducer = (state = initialState, payload) => {
    switch (payload.type) {
        case actionTypes.ON_URL_CHANGE:

            if(payload.url === state.currentUrl) {
                return state;
            }

            return {
                lastUrl: state.currentUrl,
                currentUrl: payload.url
            };
        default:
            return state;
    }
};

export default routerReducer;