import React from "react";
import {t} from "../../../../../../utils/Translation";
import Row from "../../../../../../components/row/Row";
import LabeledField from "../../../../../../components/fields/labeled/LabeledField";
import ImgButton from "../../../../../../components/buttons/ImgButton";
import ExclamationIcon from "../../../../../../img/exclamation.svg";
import HelperIcon from "../../../../../../img/helpFieldIcon.svg";
import {handleRuleInfoPointClick} from "../RuleDetailsForm";
import {Checkbox} from "react-bootstrap";
import Alerts from "../../../../../../utils/AlertUtils";
import RuleService from "../../../../../../services/RuleService";

let BasicInfoTab = {
    render : ( {isView, isEdit, isCreate, user}, data, updateFormValue) => {
        const showRuleTemplateInfoPoint = isCreate && data?.templateId?.value?.name
        //TODO remove this after VP testing
        const adminUsers = ['0a2000d7-700f-12bb-8170-14d55e521db8', '0a20012c-7741-155a-8177-4ec374e40e84',
            '0a200113-5fee-1553-815f-fe1c15bc192a', '0a20010b-5fc5-1fb9-815f-c574952f02e1']
        const isCloudProcessorAvailable = data.deploymentStatus === undefined && isCreate ? false :
            data.deploymentStatus === RuleService.deploymentStatus.DEPLOYED;

        return (
            <div className='active-tab' >
                <Row className='pd-t-5'>
                    <LabeledField
                        forceNewPropValue={true}
                        key="ruleDetailsForm.basicInfoRefs.displayName"
                        fieldLabel={t('rules.form.tabs.basicInfo.ruleName.label')}
                        fieldPlaceHolder={t('rules.form.tabs.basicInfo.ruleName.placeholder')}
                        fieldType="text"
                        width="6"
                        isRequired={true}
                        isView={isView}
                        value={data?.displayName?.value}
                        error={data?.displayName?.error}
                        onFieldChange={entity => updateFormValue("displayName", entity)}
                    />
                </Row>
                <Row className='pd-t-5'>
                    <LabeledField
                        forceNewPropValue={true}
                        key="ruleDetailsForm.basicInfoRefs.entityId"
                        fieldLabel={t('rules.form.tabs.basicInfo.entity.label')}
                        fieldPlaceHolder={t('rules.form.tabs.basicInfo.entity.placeholder')}
                        fieldType="dropdown"
                        dataAttribute="displayName"
                        width='6'
                        isRequired={true}
                        isView={isView}
                        iconClassName="fa fa-sitemap"
                        disabled={isEdit}
                        selectData={data?.rootEntityId?.data}
                        value={data?.rootEntityId?.value}
                        error={data?.rootEntityId?.error}
                        onFieldChange={entity => updateFormValue("rootEntityId", entity)}
                    />
                </Row>
                <Row className='pd-t-5'>
                    <LabeledField
                        forceNewPropValue={true}
                        key="ruleDetailsForm.basicInfoRefs.sectorId"
                        labelIcon={data?.showAllInterfaces?.value ? ExclamationIcon : null}
                        labelIconTooltip={isView ? t('rules.form.tabs.basicInfo.switchShowAllInterfaces.tooltip') : null}
                        fieldLabel={t('rules.form.tabs.basicInfo.sector.label')}
                        fieldPlaceHolder={t('rules.form.tabs.basicInfo.sector.placeholder')}
                        fieldType="dropdown"
                        dataAttribute="displayName"
                        width='6'
                        isView={isView}
                        isRequired={true}
                        iconClassName="fa fa-sitemap"
                        selectData={data?.sectorId?.data}
                        value={data?.sectorId?.value}
                        error={data?.sectorId?.error}
                        onFieldChange={entity => updateFormValue("sectorId", entity)}
                    />
                </Row>
                <Row className='pd-t-5'>
                    <LabeledField
                        forceNewPropValue={true}
                        key="ruleDetailsForm.basicInfoRefs.templateCategory"
                        fieldLabel={t('rules.form.tabs.basicInfo.ruleTemplateCategory.label')}
                        fieldPlaceHolder={t('rules.form.tabs.basicInfo.ruleTemplateCategory.placeholder')}
                        fieldType="dropdown"
                        width="6"
                        isView={isView}
                        disabled={isEdit}
                        selectData={data?.templateCategory?.data}
                        sortAttribute="order"
                        value={data?.templateCategory?.value}
                        onFieldChange={entity => updateFormValue("templateCategory", entity)}
                    />
                </Row>
                <Row className='pd-t-5'>
                    <LabeledField
                        forceNewPropValue={true}
                        key="ruleDetailsForm.basicInfoRefs.templateId"
                        fieldLabel={t('rules.form.tabs.basicInfo.ruleTemplate.label')}
                        fieldPlaceHolder={t('rules.form.tabs.basicInfo.ruleTemplate.placeholder')}
                        fieldType="dropdown"
                        iconClassName="fa fc-icon-checklist"
                        width="6"
                        isRequired={true}
                        isView={isView}
                        disabled={isEdit}
                        selectData={data?.templateId?.data}
                        value={data?.templateId?.value}
                        error={data?.templateId?.error}
                        onFieldChange={entity => updateFormValue("templateId", entity)}
                        labelPopup = {showRuleTemplateInfoPoint ? (<ImgButton
                            alt = "rule template helper"
                            height={30}
                            divClassname={"img-btn-inline"}
                            classes={"pd-r-15 labeled-field-helper-icon"}
                            handleIconClick={() => handleRuleInfoPointClick(data.templateId.templateDetails)}
                            image={HelperIcon}
                            tooltip={t('rules.list.tooltips.ruleTemplate.infoPoint')}
                            onHoverImage = {HelperIcon}
                        />) : null}
                    />
                </Row>
                <Row className='pd-t-5'>
                    <LabeledField
                        forceNewPropValue={true}
                        key="ruleDetailsForm.basicInfoRefs.terminalId"
                        fieldLabel={t('rules.form.tabs.basicInfo.terminal.label')}
                        fieldPlaceHolder={t('rules.form.tabs.basicInfo.terminal.placeholder')}
                        fieldType="dropdown"
                        width="6"
                        dataAttribute="displayName"
                        isView={isView}
                        iconClassName="fa fc-icon-terminal"
                        selectData={data?.terminalId?.data}
                        value={data?.terminalId?.value}
                        error={data?.terminalId?.error}
                        isRequired={data?.terminalId?.isRequired}
                        onFieldChange={entity => updateFormValue("terminalId", entity)}
                    />
                </Row>
                {(isEdit || isCreate) && (
                    <>
                        <Row className='pd-t-5'>
                            <div className="flex form-group col-lg-6 cursor-pointer">
                                <Checkbox
                                    inline
                                    checked={data?.showAllInterfaces?.value}
                                    className="cursor-pointer"
                                    onChange={e => {
                                        const checked = e.target.checked;
                                        if (checked) {
                                            Alerts.confirmWarningFlow({
                                                html: t('rules.form.tabs.basicInfo.switchShowAllInterfaces.warning'),
                                                confirmButtonText: t("generic.yes"),
                                                cancelButtonText: t("generic.no")
                                            }).show(() => {
                                                updateFormValue("showAllInterfaces", checked)
                                            });
                                        } else {
                                            updateFormValue("showAllInterfaces", e.target.checked)
                                        }
                                    }}
                                />
                                <span className="mr-5">{<i style={{fontSize: '12px'}}><img alt="" width={12}
                                                                                           src={ExclamationIcon}/>&nbsp;&nbsp;{
                                    t('rules.form.tabs.basicInfo.switchShowAllInterfaces.tooltip') + '?'
                                }</i>}</span>
                            </div>
                        </Row>
                        {adminUsers.includes(user) &&
                            <Row className='pd-t-5'>
                                <div className="flex form-group col-lg-6 cursor-pointer">
                                    <Checkbox
                                        inline
                                        checked={data?.isCloudProcessor?.value}
                                        className="cursor-pointer"
                                        disabled={isCloudProcessorAvailable}
                                        onChange={e => {
                                            const checked = e.target.checked;
                                            updateFormValue("isCloudProcessor", checked)
                                        }}
                                    />
                                    <span className="mr-5">{<i style={{fontSize: '12px'}}><img alt="" width={12}
                                                                                               src={ExclamationIcon}/>&nbsp;&nbsp;{
                                        t('rules.form.tabs.basicInfo.virtualProcessor.label')
                                    }</i>}</span>
                                </div>
                            </Row>
                        }
                    </>
                )}
            </div>
        );
    },
};
export default BasicInfoTab;