import _ from "lodash";
import moment from 'moment';
import 'moment-timezone';
import jstz from 'jstz';
import UserSessionService from "../services/UserSessionService";
import __ from "./EnhancedLodash";

let TimezoneUtils = {
    getTimezoneDataForDropdown: function () {
        let newData = [];
        _.map(moment.tz.names(), op => {
            newData.push({
                'id': op,
                'offset' : moment.tz(op).format('Z'),
                'name': '(GMT' + moment.tz(op).format('Z') + ') ' + op
            });
        });
        return newData;
    },

    getTimezoneObject: function (name) {
        if(name) {
            return {
                id: name,
                offset : moment.tz(name).format('Z'),
                name: '(GMT' + moment.tz(name).format('Z') + ') ' + name
            }
        }
    },

    getBrowserTimezone: function () {
        let timezone = jstz.determine();
        return timezone.name();
    },

    convertUTCToUserTimezoneForWidgets: function (datetime) {
        return moment(datetime, 'YYYY-MM-DDTHH:mm:ss').tz(UserSessionService.getCurrentUserTimezone());
    },

    convertUTCToUserTimezone: function (datetime) {
        return moment.tz(moment.utc(datetime), UserSessionService.getCurrentUserTimezone());
    },

    convertUTCToUserTimezoneWithFormat: function (datetime, format) {
        return moment.tz(moment.utc(datetime, format), UserSessionService.getCurrentUserTimezone());
    },

    convertUTCToTimezone: function (datetime, timezone) {
        return moment.tz(moment.utc(datetime), timezone);
    },

    getCurrentTimeWithUserTimezone: function () {
        return moment.tz(moment(), UserSessionService.getCurrentUserTimezone());
    },

    convertUserTimezoneToUTCFormat: function (datetime) {
        if(!_.isNil(datetime) && !_.isEmpty(datetime)){
            let timezone = UserSessionService.getCurrentUserTimezone();
            let newDatetime = datetime.clone();
            newDatetime.tz(timezone);
            newDatetime.add(datetime.utcOffset() - newDatetime.utcOffset(), 'minutes');
            return newDatetime.utc();
        } else {
            return datetime;
        }
    },

    convertTimezoneToUTCFormat: function (datetime, timezone) {
        if(!_.isNil(datetime) && !_.isEmpty(datetime)){
            let newDatetime = datetime.clone();
            newDatetime.tz(timezone);
            newDatetime.add(datetime.utcOffset() - newDatetime.utcOffset(), 'minutes');
            return newDatetime.utc();
        } else {
            return datetime;
        }
    },

    getUserTimezoneOffsetInSeconds: function() {
        return moment.tz(moment(), UserSessionService.getCurrentUserTimezone()).utcOffset();
    },

    getDateTimeFieldValue(value, dateFormatString, keepDatetimeValuesInUTC = false){
        if ((typeof value === 'string' || value instanceof String)) {
            if(moment(value, dateFormatString).isValid()) {
                return value;
            }
        } else {
            if(!__.isNilOrEmpty(value)) {
                return keepDatetimeValuesInUTC ? value.format(dateFormatString) : TimezoneUtils.convertUserTimezoneToUTCFormat(value).format(dateFormatString);
            }
        }
    }

};

export default TimezoneUtils;

