import React from "react";
import _ from "lodash";
import {t} from "../../../utils/Translation";
import PropTypes from "prop-types";
import SchedulerConfigsLine from "./SchedulerConfigsLine";
import uuid from "../../../utils/uuid";
import moment from 'moment';
import RuleService from "../../../services/RuleService";

class SchedulerConfigs extends React.Component {
    constructor(props){
        super(props);

        this.configLineRefs = {};

        this.state = {
            visible: false,
            dataType: props.dataType?  props.dataType : null,
            configLines: props.configLines? props.configLines : [],
            tariffMode: !_.isNil(props.tariffMode) ? props.tariffMode : false,
            isView: !_.isNil(props.isView) ? props.isView : false,
            tariffCategories: !_.isNil(props.tariffCategories) ? props.tariffCategories : [],
        }
    }

    componentDidMount() {
        if(!_.isEmpty(this.props.configsData)) {
            this.resetConfigsWithData(this.props.configsData);
        }
    }

    resetConfigsWithData(data) {
        convertConfigsDataIntoLines(this, data);
    }

    validateConfig(...param) {
        let valid = this.state.configLines.length > 0;
        if(!valid) {
            this.props.parent.setErrorForLine(this.props.id);
        } else {
            _.forOwn(this.configLineRefs, configLine => {
                if(configLine && !configLine.validateConfigLine()){
                    valid = false;
                }
            });
        }
        return valid;
    }

    setIsView (isView) {
        this.setState({isView: isView});
    }

    getFieldValue() {
        return this.state.value;
    }

    getConfigLines() {
        return this.state.configLines;
    }

    updateLineValue(configLineId, property, value) {
        saveNewValue(this, configLineId, property, value);
    }

    setInterfaceDataType(dataType, resetActionFields) {
        let self = this;
        let lines = this.state.configLines;
        if(resetActionFields){
            _.forEach(lines, line => {
                line.action = dataType === RuleService.dataType.BOOLEAN ? true : null;
                line.forced = dataType === RuleService.dataType.BOOLEAN ? true : null;
            });
            this.setConfigLines(lines, () => {
                this.setState({dataType: dataType}, () => {
                    updateConfigsLinesDataTypes(self, dataType)
                })
            });
        } else {
            this.setState({dataType: dataType}, () => {
                updateConfigsLinesDataTypes(self, dataType)
            })
        }
    }

    setConfigLines(configLines, afterConfigLinesAreSet) {
        afterConfigLinesAreSet = afterConfigLinesAreSet? afterConfigLinesAreSet : () => {};
        this.setState({configLines: configLines}, () => {
            afterConfigLinesAreSet();
        });
    }

    toggleVisibility() {
        this.setState({visible: !this.state.visible});
    }

    deleteLine(lineId) {
        let configLines = this.state.configLines;
        _.remove(configLines, line => {
            return line.id === lineId;
        });
        _.remove(this.configLineRefs, lineRef => {
            if(!_.isNil(lineRef)) {
                return lineRef.props.id === lineId;
            }
        });
        this.setConfigLines(configLines);
    }

    getEndTimeOfConfigLineBefore(configLineId) {
        let result = null;
        let indexOfConfigLine = _.findIndex(this.state.configLines, line => {
            return line.id === configLineId;
        });
        if(indexOfConfigLine > 0) {
            result = this.configLineRefs[this.state.configLines[indexOfConfigLine-1].id].getRawEndTime();
        }
        return result;
    }

    render() {
        if(this.state.visible){
            return (
                <div>
                    <div>
                        {getConfigsTable(this)}
                    </div>
                    {isViewMode(this) ? null :
                        <div className="valign-middle col-xs-1"/>
                    }
                    {getCreateTimeIntervalButton(this)}
                </div>
            );
        } else {
            return null;
        }
    }
}

function convertConfigsDataIntoLines(component, configsData) {
    let configLines = [];
    _.forEach(configsData, data => {
        configLines = getConfigLinesWithNewLine(component, configLines, data);
    });
    component.setConfigLines(configLines);
}

function getConfigsTable(component) {
    return <div className="p-l-r-0">
            {buildConfigsLines(component)}
    </div>;
}

function getCreateTimeIntervalButton(component) {
    let button;
    if(!component.state.isView) {
        button = (
            <div className="create-form-button open-form-button mt-25" style={{paddingLeft: "23px"}}>
                <span onClick={() => {
                    addNewLine(component);
                }}>
                    <i className="fa fa-lg fa-plus-circle fa-fw" aria-hidden="true"/>
                    {t("generic.scheduler.addTimeInterval")}
                </span>
            </div>
        );
    }
    return button;
}

function buildConfigsLines(component) {
    let configLines = component.state.configLines;
    let lines = [];
    _.forEach(configLines, line => {
        lines.push(
            <SchedulerConfigsLine
                key={line.id}
                id={line.id}
                startTime={line.startTime}
                endTime={line.endTime}
                action={line.action}
                forced={line.forced}
                displayName={line.displayName}
                value={line.value}
                parent={component}
                isView={component.state.isView}
                dataType={component.state.dataType}
                tariffMode={component.state.tariffMode}
                onStartTimeChanged={line.onStartTimeChanged}
                onEndTimeChanged={line.onEndTimeChanged}
                onActionChanged={line.onActionChanged}
                onForcedChanged={line.onForcedChanged}
                onDisplayNameChanged={line.onDisplayNameChanged}
                onValueChanged={line.onValueChanged}
                ref={c => component.configLineRefs[line.id] = c}
            />
        );
    });
    return lines;
}

function addNewLine(component) {
    let newConfigLines = getConfigLinesWithNewLine(component, component.state.configLines);
    component.setConfigLines(newConfigLines);
}

function getConfigLinesWithNewLine(component, lines, newLineData) {
    let configLines = lines;
    let id = uuid();
    let startTime, endTime, action, forced, displayName, value;
    if(newLineData) {
        startTime = moment(newLineData.startTime, 'HH:mm:ss');
        endTime = moment(newLineData.endTime, 'HH:mm:ss');
        action = newLineData.action;
        forced = newLineData.forced;
        value = newLineData.value;
        displayName = newLineData.displayName;
    } else {
        let lastConfigLine = configLines.length > 0? configLines[configLines.length-1] : null;
        startTime = (lastConfigLine && !_.isEmpty(lastConfigLine.endTime))? lastConfigLine.endTime.clone().add(1,'s') : '';
        if(startTime !== '' && startTime.format('HH:mm:ss') === '00:00:00') {
            startTime = startTime.clone().subtract(1,'d');
        }
        if (_.isEmpty(startTime)){
            startTime = moment().date(1).hours(0).minutes(0).seconds(0);
        }
        endTime = startTime.clone().add(1,'s');
        action = component.state.dataType === RuleService.dataType.BOOLEAN ? true : null;
        forced = component.state.dataType === RuleService.dataType.BOOLEAN ? true : null;
        value = null;
        displayName = null;
    }
    configLines.push(
        {
            id: id,
            startTime: startTime,
            endTime: endTime,
            action: action,
            forced: forced,
            displayName: displayName,
            value: value,
            onStartTimeChanged: value => saveNewValue(component, id, 'startTime', value),
            onEndTimeChanged: value => saveNewValue(component, id, 'endTime', value),
            onActionChanged: value => saveNewValue(component, id, 'action', value),
            onForcedChanged: value => saveNewValue(component, id, 'forced', value),
            onValueChanged: value => saveNewValue(component, id, 'value', value),
            onDisplayNameChanged: value => saveNewValue(component, id, 'displayName', value),
        }
    );
    return configLines;
}

function updateConfigsLinesDataTypes(component, dataType) {
    _.forOwn(component.configLineRefs, configLineRef => {
        if(configLineRef) {
            configLineRef.setInterfaceDataType(dataType);
        }
    })
}

function saveNewValue(component, configLineId, property, value) {
    let configLines = component.state.configLines;
    let configLineIndex = _.findIndex(configLines, line => {
        return line.id === configLineId;
    });

    configLines[configLineIndex][property] = value;

    component.setConfigLines(configLines);
}

function isViewMode(component) {
    return component.state.isView;
}

SchedulerConfigs.propTypes = {
    configsData: PropTypes.array,
    tariffMode: PropTypes.bool
};

export default SchedulerConfigs;