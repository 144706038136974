import humanizeDuration from "humanize-duration";
import UserSessionService from "../services/UserSessionService";

const humanizer = humanizeDuration.humanizer();
humanizer.languages.short_en = {
            y: () => 'y',
            mo: () => 'mo',
            w: () => 'w',
            d: () => 'd',
            h: () => 'h',
            m: () => 'm',
            s: () => 's',
            ms: () => 'ms'
};
humanizer.languages.short_pt = {
    y: () => 'ano',
    mo: () => 'mês',
    w: () => 'sem',
    d: () => 'd',
    h: () => 'h',
    m: () => 'm',
    s: () => 's',
    ms: () => 'ms'
};

const displayConfig = {
    serialComma: false,
    spacer: '',
    round: true,
    largest: 2,
    language: UserSessionService.getCurrentUserLanguage().split('_')[0],
    fallbacks: ['en']
};

const DurationFormatter = {
    format(time, shortLabel) {
        if(shortLabel) {
            return humanizer(time, {...displayConfig, language: 'short_' + displayConfig.language});
        } else {
            return humanizer(time, displayConfig);
        }
    }
};

export default DurationFormatter;