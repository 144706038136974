import "react-hot-loader/patch";
import React from "react";
import ReactDOM from "react-dom";
import {AppContainer} from "react-hot-loader";
import {Provider} from "react-redux";

import 'icheck/skins/all.css';
import "./../node_modules/react-select/dist/react-select.css";
import "./../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "./../node_modules/font-awesome/css/font-awesome.css";
import "./../node_modules/animate.css/animate.min.css";
import "./../node_modules/sweetalert2/dist/sweetalert2.min.css";
import "./../node_modules/nouislider/distribute/nouislider.css";
import "./../node_modules/react-grid-layout/css/styles.css";
import "./../node_modules/react-resizable/css/styles.css";
import "./../node_modules/react-quill/dist/quill.snow.css";

import 'tippy.js/themes/material.css';

import "./styles/components/fields/switch/SwitchField.css";
import "./styles/components/table/Table.css";
import "./styles/components/fields/input/Input.css";
import "./styles/components/fields/input/reactQuill.css";

import "./styles/react-datetime.css";
import "./styles/uiswitch.css";
import "./styles/style.css";
import "./styles/fonts.css";
import "./styles/custom.css";
import "./styles/bootstrapAddon.css";

import "./styles/users.css";
import "./styles/entities.css";
import "./styles/dropzone.css";
import "./styles/homepage.css";
import "./styles/widgets.scss";
import "./styles/interfaces.css";
import "./styles/sidePanel.css";
import "./styles/entitiesTree.css";
import "./styles/profile.css";
import "./styles/audit.css";
import "./styles/map.css";
import "./styles/batches.css";
import "./styles/api.css";
import "./styles/taskBoard.css";
import "./styles/starField.css";
import "./styles/reportsSubscription.css";
import "./styles/dashboard.css";
import "./styles/row-with-flex.css";
import "./views/rules/rules/generic/style/rules.scss";
import "./views/batches/animalMovements/style/AnimalMovementsListView.scss";
import "./views/events/style/events.scss";
import "./views/tasks/board/component/style/board.css";
import "./views/terminals/style/terminals.scss";
import "./views/terminals/style/list.scss";
import "./views/rules/rules/generic/style/rules.scss";
import "./views/interfaces/style/interface.scss";
import "./views/home/components/treeView/style/style.scss";
import "./views/rules/rules/alarms/components/eventConfiguration/actionConfiguration/style/alarm.scss";

import './i18n';

import createSagaMiddleware from 'redux-saga';
import {composeWithDevTools} from 'redux-devtools-extension';

import "./components/fontawesome/fontawesome";

import {applyMiddleware, createStore} from "redux";
import * as axios from "axios";
import $ from 'jquery';
import {config} from "./config";
import {forceCheck} from 'react-lazyload';

import Routes from "./routes";
import {rootReducer, rootSagas} from "./rootReducer";

import UserSessionService from "./services/UserSessionService";
import UserLanguageService from "./services/UserLanguageService";
import AuthenticationService from "./services/AuthenticationService";
import _ from "lodash";
import GenericUtils from "./utils/GenericUtils";
import {getTenantId, getTheme, ThemeContext} from "./context/ThemeProvider";
import Header from "./Header";
import CacheValidator from "./cache/CacheValidator";
import ErrorBoundary from "./errorBoundary/ErrorBoundary";

let qs = require('qs');

const sagaMiddleware = createSagaMiddleware();

let redux = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(sagaMiddleware))
);

axios.defaults.paramsSerializer = (params) => {
    return qs.stringify(params, {arrayFormat: 'repeat', encode: false})
};

axios.interceptors.request.use((requestConfig) => {
    if(requestConfig.url !== '/auth' && UserSessionService.getSessionIsExpired() && GenericUtils.pathHasAuthenticationAndIsNotHomePage(window.location.pathname)) {
        AuthenticationService.logout(true,
            () => {
                window.history.pushState(null, null, '/login');
                window.location.reload();
            }, () => {
                AuthenticationService.logoutWithoutRequest();
            });
    } else {
        UserSessionService.updateLastRequestTimeStamp();

        if(requestConfig.url.indexOf(config.widgets.weather.providerUrl) > -1) {
            delete requestConfig.headers.common.Authorization;
            return requestConfig;
        }

        requestConfig.url = '/services' + requestConfig.url;
        requestConfig.headers = {
            ...requestConfig.headers,
            tenant: getTenantId()
        };
        return requestConfig;
    }
}, (error) => {
    return Promise.reject(error);
});

axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if(_.includes(error.config.url, config.errorUrl)) {
        return Promise.resolve();
    } else if(_.includes(error.message, "cancelToken")) {
        //This means that the session was closed due to a timeout.
        //If it is rejected, it will show an application error.
        //In order to avoid showing an unnecessary error, the Promise should be resolved.
        return Promise.resolve();
    } else if(_.includes(error.message, "Network Error")) {
        AuthenticationService.logoutWithoutRequest();
    } else if (error.response.status === 403) {
        AuthenticationService.logoutWithoutRequest();
    }else if (error.response.status === 500 && ( _.includes(error.response.data, "ECONNREFUSED") || _.includes(error.response.data, "ConnectException"))){
        AuthenticationService.logoutWithoutRequest();
    }else if( _.includes(error.code, "ERR_NETWORK_IO_SUSPENDED") || _.includes(error.code, "ERR_INTERNET_DISCONNECTED") ){
        AuthenticationService.logoutWithoutRequest();
    }else if (error.response.status === 401) {
        return Promise.reject(error);
    } else {
        return Promise.reject(error.response.data);
    }
});

if (UserSessionService.isAuthenticated()) {
    axios.defaults.headers.common["Authorization"] = "Bearer " + UserSessionService.getSessionToken();
    UserLanguageService.setUserLanguage(UserSessionService.getCurrentUserLanguage());
} else {
    UserLanguageService.setDefaultLanguage();
}

sagaMiddleware.run(rootSagas);

const render = Component => {
    ReactDOM.render(
        <CacheValidator>
            {({ loading, isLatestVersion, refreshCacheAndReload }) => {
                //if (loading) return null;
                if (!loading && !isLatestVersion) {
                    // You can decide how and when you want to force reload
                    refreshCacheAndReload();
                }
                return (
                    <ThemeContext.Provider value={getTheme()}>
                        <Header>
                            <AppContainer>
                                <Provider store={redux}>
                                    <div onScroll={() => forceCheck()} >
                                        <ErrorBoundary>
                                            <Component />
                                        </ErrorBoundary>
                                    </div>
                                </Provider>
                            </AppContainer>
                        </Header>
                    </ThemeContext.Provider>
                );
            }}
        </CacheValidator>
        , document.getElementById('root')
    );
};

render(Routes);

if (module.hot) {
    module.hot.accept('./routes', () => {
        const NextApp = require('./routes').default;
        render(NextApp);
    });
}

$.fn.isOnScreen = function(){
    let win = $(window);
    let viewport = {
        top : win.scrollTop(),
        left : win.scrollLeft()
    };
    viewport.right = viewport.left + win.width();
    viewport.bottom = viewport.top + win.height();

    let bounds = this.offset();
    bounds.right = bounds.left + this.outerWidth();
    bounds.bottom = bounds.top + this.outerHeight();

    return (!(viewport.right < bounds.left || viewport.left > bounds.right || viewport.bottom < bounds.top || viewport.top > bounds.bottom));
};

export {
    redux,
    sagaMiddleware
};

