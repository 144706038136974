import React from "react";
import Row from "../../row/Row";
import "./basePanel.scss";
import classNames from 'classnames';
import useWindowSize from "../../../hooks/useWindowSize";

const BasePanel = ({className = "", children}) => {
    const {isMobile} = useWindowSize();
    return (
        <div className={classNames('base-panel', className, {"mobile": isMobile})}>
            <Row className="base-panel-row">
                {children}
            </Row>
        </div>
    );
};

export default BasePanel;