import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {t} from "../../../utils/Translation";
import Row from "../../row/Row";
import LabeledField from "../labeled/LabeledField";
import SchedulerLine from "./SchedulerLine";
import SchedulerConfigs from "./SchedulerConfigs";
import uuid from "../../../utils/uuid";
import TimezoneUtils from "../../../utils/TimezoneUtils";
import SchedulerUtils from "./SchedulerUtils";
import UserSessionService from "../../../services/UserSessionService";
import HelperIcon from './../../../img/helpFieldIcon.svg';
import RuleService from "../../../services/RuleService";
import Tooltip from "../../tooltip/Tooltip";
import blockedIcon from "../../../img/blocked.svg";
import ClickWrapper from "../../hoc/ClickWrapper";

class Scheduler extends React.Component {

    constructor(props){
        super(props);

        this.lineRefs = {};
        this.configRefs = {};
        this.schedulerRefs = {};

        this.state = {
            enabledCreateButton: !_.isNil(props.enabledCreateButton) ? props.enabledCreateButton : true,
            selectAllEnabled: !_.isNil(props.selectAllEnabled) ? props.selectAllEnabled : true,
            additionalSchedulerLines: !_.isNil(props.additionalSchedulerLines) ? props.additionalSchedulerLines : [],
            additionalSchedulerConfigs: !_.isNil(props.additionalSchedulerConfigs) ? props.additionalSchedulerConfigs : [],
            checkedOptions: getObjectFromArray(props.options, false),
            interfaceList: props.interfaceList? props.interfaceList : [],
            dataType: props.dataType? props.dataType : null,
            interfaceChosenOnEdit: '',
            tariffMode: !_.isNil(props.tariffMode) ? props.tariffMode : false,
            isView: !_.isNil(props.isView) ? props.isView : false,
            tariffCategories: !_.isNil(props.tariffCategories) ? props.tariffCategories : [],
            error: ''
        }
    }

    clearFieldValue() {
        _.forOwn(this.lineRefs, (key,value) => {
            if(value !== 'firstLine') {
                if(key){
                    this.deleteLine(value, key.weekdaysRef.state.checkedOptions);
                }
            } else {
                key.setWeekdays(getObjectFromArray(this.props.options, false));
                key.weekdaysRef.setAllOptionsDisabled(false);
            }
        });

        _.forOwn(this.configRefs, (key,value) => {
            if(key) {
                key.setState({configLines: []});
            }
        });
    }

    setError(error){
        this.setState({error: error});
    }

    componentWillReceiveProps(nextProps) {
        let self = this;
        this.setState({
            isView: !_.isNil(nextProps.isView) ? nextProps.isView : this.state.isView,
            enabledCreateButton: !_.isNil(nextProps.enabledCreateButton) ? nextProps.enabledCreateButton : this.state.enabledCreateButton,
            selectAllEnabled: !_.isNil(nextProps.selectAllEnabled) ? nextProps.selectAllEnabled : this.state.selectAllEnabled,
            additionalSchedulerLines: !_.isNil(nextProps.additionalSchedulerLines) ? nextProps.additionalSchedulerLines : this.state.additionalSchedulerLines,
            additionalSchedulerConfigs: !_.isNil(nextProps.additionalSchedulerConfigs) ? nextProps.additionalSchedulerConfigs : this.state.additionalSchedulerConfigs,
            checkedOptions: getObjectFromArray(nextProps.options, false),
            interfaceList: !_.isNil(nextProps.interfaceList) ? nextProps.interfaceList : this.state.interfaceList,
            dataType: !_.isNil(nextProps.dataType) ? nextProps.dataType : this.state.dataType,
            interfaceChosenOnEdit: ''
        }, () => {
            if(!_.isNil(nextProps.isView)) {
                updateIsViewRefs(self, nextProps.isView);
            }
            if(nextProps.timeZone && self.schedulerRefs.timeZone){
                self.schedulerRefs.timeZone.setFieldValue(nextProps.timeZone);
            }
            updateDisabledWeekdays(self);
        });
    }

    componentDidUpdate() {
        if(
            (isEditMode(this) || isViewMode(this))
            && this.schedulerRefs.iface
            && !_.isEmpty(this.schedulerRefs.iface.state.data)
            && !_.isEmpty(this.state.interfaceChosenOnEdit)
        ) {
            let iface = this.schedulerRefs.iface.getDropDownObjectFromValue(this.state.interfaceChosenOnEdit);
            this.schedulerRefs.iface.setFieldValue(this.state.interfaceChosenOnEdit);
            handleInterfaceChanged(this, iface, false);
        }
    }

    componentDidMount() {
        if(
            !this.state.tariffMode &&
            (isEditMode(this) || isViewMode(this)) &&
            this.props.selectedEntity && this.props.selectedTerminal
        ){
            let self = this;
            RuleService.getInterfacesWithCategory(self.props.selectedEntity.id, self.props.selectedTerminal.id, null, null, interfaces => {
                let filteredInterfaces = [];
                _.forEach(interfaces, iface => {
                    if(iface.terminalInterfaceDataType === RuleService.dataType.BOOLEAN) {
                        filteredInterfaces.push(iface);
                    }
                });
                self.setInterfaceList(filteredInterfaces);
            }, () => {
                throw new Error("It was not possible to fetch the Interface List");
            });
        }
    }

    validateField(...param) {
        let valid = true;
        if(!this.state.tariffMode){
            valid = this.schedulerRefs.iface.validateField()
        }
        if(valid) {
            _.forOwn(this.configRefs, config => {
                if (config && !config.validateConfig()) {
                    valid = false;
                }
            });
        }

        let checkedOptionsEmpty = true;
       _.forEach(this.state.checkedOptions, weekDay => {
            checkedOptionsEmpty = weekDay ? false : checkedOptionsEmpty;
        });

        if(checkedOptionsEmpty){
            let error = t('generic.scheduler.errors.noWeekdaysSelected');
            this.setState({error: error});
        }

        return valid;
    }

    setIsView(isView) {
        let self = this;
        this.setState({isView: isView}, () => {
            updateIsViewRefs(self, isView);
        });
    }

    getInterfaceId() {
        return this.schedulerRefs.iface.getFieldValue();
    }

    getCallbackValue() {
        return this.props.callbackValue;
    }

    setEnabledCreateButton(enabledCreateButton) {
        this.setState({enabledCreateButton: enabledCreateButton});
    }

    setSelectAllEnabled(selectAllEnabled) {
        this.setState({selectAllEnabled: selectAllEnabled});
    }

    setGeneralCheckedOption(id, value) {
        let checkedOptions = this.state.checkedOptions;
        checkedOptions[id] = value;
        this.setCheckedOptions(checkedOptions);
    }

    setMultipleGeneralCheckedOptions(ids, value) {
        let checkedOptions = this.state.checkedOptions;
        _.forEach(ids, id => {
            checkedOptions[id] = value;
        });
        this.setCheckedOptions(checkedOptions);
    }

    setCheckedOptions(checkedOptions) {
        this.setState({checkedOptions: checkedOptions});
    }

    setDataType(dataType, afterDataTypeIsSet) {
        afterDataTypeIsSet = afterDataTypeIsSet? afterDataTypeIsSet : () => {};
        this.setState({dataType: dataType}, () => {
            afterDataTypeIsSet();
        });
    }

    setBothScheduleLineAndConfigs(additionalSchedulerLines, additionalSchedulerConfigs, afterSettingLinesAndConfigs) {
        let self = this;
        afterSettingLinesAndConfigs = afterSettingLinesAndConfigs? afterSettingLinesAndConfigs : () => {};
        this.setState({
            additionalSchedulerLines: additionalSchedulerLines,
            additionalSchedulerConfigs: additionalSchedulerConfigs
        }, () => {
            if(additionalSchedulerLines.length === 0) {
                self.setSelectAllEnabled(true);
            }
            afterSettingLinesAndConfigs();
        });
    }

    setInterfaceList(interfaceList) {
        this.setState({interfaceList: interfaceList});
    }

    deleteLine(lineId, optionsThatWereChecked) {
        let self = this;
        let additionalSchedulerLines = this.state.additionalSchedulerLines;
        let additionalSchedulerConfigs = this.state.additionalSchedulerConfigs;

        _.remove(additionalSchedulerLines, line => {
            return line.props.id === lineId;
        });
        _.remove(additionalSchedulerConfigs, config => {
            return config.props.id === lineId;
        });

        _.remove(this.lineRefs, lineRef => {
            if(!_.isNil(lineRef)) {
                return lineRef.props.id === lineId;
            }
        });
        _.remove(this.configRefs, configRef => {
            if(!_.isNil(configRef)) {
                return configRef.props.id === lineId;
            }
        });

        this.setBothScheduleLineAndConfigs(additionalSchedulerLines, additionalSchedulerConfigs, () => {
            _.forOwn(optionsThatWereChecked, (value, key) => {
                if(value){
                    handleWeekdayChanged(self, lineId, key, !value);
                }
            })
        });
    }

    getFieldValue() {
        let self = this;
        let value = {};
        let timezone = self.props.timeZone;
        let lines = this.state.tariffMode?
            SchedulerUtils.normalizeUTCValues(
                getAllLinesData(self, true),
                self,
                timezone,
                SchedulerUtils.keys.weekdaysKeys.daysOfWeek,
                SchedulerUtils.keys.configsKeys.timeIntervals):
            SchedulerUtils.normalizeUTCValues(
                getAllLinesData(self, false),
                self,
                timezone,
                SchedulerUtils.keys.weekdaysKeys.weekdays,
                SchedulerUtils.keys.configsKeys.configs);
        if(this.state.tariffMode){
            value = lines
        } else {
            value = {lines: lines};
        }
        return value;
    }

    setFieldValue(data) {
        if(!_.isEmpty(data)){
            let interfaceChosenOnEdit = (!this.state.tariffMode &&
                !_.isNil(data.lines[0]) &&
                !_.isNil(data.lines[0].configs[0]) &&
                !_.isNil(data.lines[0].configs[0]).interfaceId)? data.lines[0].configs[0].interfaceId : null;
            convertDataIntoSchedulerLinesAndConfigs(this, data, interfaceChosenOnEdit);
            if(!this.state.tariffMode){
                this.setState({
                    interfaceChosenOnEdit : interfaceChosenOnEdit
                });
            }
        }
    }

    setErrorForLine(id) {
        let lineIndex = _.findIndex(this.state.additionalSchedulerLines, line => {
            return line.props.id === id;
        });
        let error = t('generic.scheduler.errors.noLinesInConfigs', {line: (lineIndex !== -1? (lineIndex+2) : 1)});
        this.setState({error: error});
    }

    render() {
        let self = this;
        let error = !_.isEmpty(this.state.error)? <Row className="pd-l-20 mb-15 has-error-color"><span><i>{this.state.error}</i></span></Row> : null;
        return (
            <div className="table.responsive ">
                <Row className="pd-l-20">
                    {this.state.tariffMode? null : getInterfaceField(this)}
                    {getTimeZoneField(this)}
                </Row>
                {error}
                <div className="pd-t-15">
                    {getTableHeader(this)}
                    <div>
                        <SchedulerLine
                            id={"firstLine"}
                            key={"firstLine"}
                            parent={this}
                            hasSelectAll={true}
                            isView={this.props.isView}
                            options={_.cloneDeep(this.props.options)}
                            selectAllEnabled={self.state.selectAllEnabled}
                            onWeekdayChanged={(optionId, value) => {handleWeekdayChanged(self, "firstLine", optionId, value)}}
                            onSelectAllChanged={(value, clicked) => handleSelectAllChanged(self, value, clicked)}
                            ref={c => this.lineRefs["firstLine"] = c}
                        />
                        <SchedulerConfigs
                            id={"firstLine"}
                            key={"firstLine_configs"}
                            parent={this}
                            isView={this.props.isView}
                            dataType={this.state.dataType}
                            tariffMode={this.state.tariffMode}
                            tariffCategories={this.state.tariffCategories}
                            ref={c => this.configRefs["firstLine"] = c}
                        />
                        {buildAdditionalLines(this)}
                    </div>
                </div>
                {getCreateWeekdaysButton(self)}
            </div>
        );
    }
}

function getTimeZoneField(component) {
    let result = null;
    if(component.state.tariffMode) {
        result = <LabeledField
            fieldLabel={t('profile.form.timezone.label')}
            fieldPlaceHolder={t('profile.form.timezone.placeholder')}
            fieldType="dropdown"
            width="4"
            isView={component.props.isView}
            value={component.props.timeZone? component.props.timeZone : UserSessionService.getCurrentUserTimezone()}
            disabled={true}
            selectData={TimezoneUtils.getTimezoneDataForDropdown()}
            sort={(data) => {
                return _.sortBy(data, element => {return _.toNumber(element.offset.replace(":",""))});
            }}
            ref={(c) => component.schedulerRefs.timeZone = c}
        />;
    }
    return result;
}

function updateIsViewRefs(component, isView) {
    _.forOwn(component.lineRefs, line => {
        if(line) {
            line.setIsView(isView);
        }
    });
    _.forOwn(component.configRefs, config => {
        if(config) {
            config.setIsView(isView);
        }
    });
}

function getInterfaceField(component) {
    const {isView, history} = component.props;
    const {interfaceList, interfaceChosenOnEdit} = component.state;
    return (
        <ClickWrapper onClickHandler={isView ? () => handleInterfaceClick(component, history) : null}>
            <LabeledField
                fieldLabel={t('generic.scheduler.interfaceField.label')}
                fieldPlaceHolder={t('generic.scheduler.interfaceField.placeholder')}
                fieldType="dropdown"
                dataAttribute="label"
                dataId="value"
                width="5"
                labelWidth='200px'
                isRequired={true}
                isView={isView}
                selectData={getFilteredInterfaceList(interfaceList, isView, interfaceChosenOnEdit) }
                onFieldChange={iface => handleInterfaceChanged(component, iface, true)}
                ref={c => component.schedulerRefs.iface = c}
            />
    </ClickWrapper>)
}

function handleInterfaceClick(component, history) {
    if(component.state.interfaceList && component.state.interfaceChosenOnEdit){
        const interfaceSelected = component.state.interfaceList.find(_interface => _interface.interfaceId === component.state.interfaceChosenOnEdit);

        if(interfaceSelected?.name && interfaceSelected?.terminal?.id) {
            history.push(`/system/terminals/details/${interfaceSelected.terminal.id}`, {
                historyBack: true,
                hideFilters: true,
                interfaceSearchName: interfaceSelected.name
            });
        }
    }
}

function convertDataIntoSchedulerLinesAndConfigs(component, data, iface) {
    let timezone = component.props.timeZone? component.props.timeZone : UserSessionService.getCurrentUserTimezone();
    if(timezone && component.schedulerRefs.timeZone){
        component.schedulerRefs.timeZone.setFieldValue(timezone);
    }
    let linesData = component.state.tariffMode?
        SchedulerUtils.localizeLinesData(
            _.cloneDeep(data.cycles),
            null,
            timezone,
            SchedulerUtils.keys.weekdaysKeys.daysOfWeek,
            SchedulerUtils.keys.configsKeys.timeIntervals
        ) :
        SchedulerUtils.localizeLinesData(
            _.cloneDeep(data.lines),
            iface,
            timezone,
            SchedulerUtils.keys.weekdaysKeys.weekdays,
            SchedulerUtils.keys.configsKeys.configs
        );

    if(component.state.tariffMode){
        component.lineRefs['firstLine'].setWeekdays(_.cloneDeep(linesData[0].daysOfWeek));
        component.configRefs['firstLine'].resetConfigsWithData(_.cloneDeep(linesData[0].timeIntervals));
    } else {
        component.lineRefs['firstLine'].setWeekdays(_.cloneDeep(linesData[0].weekdays));
        component.configRefs['firstLine'].resetConfigsWithData(_.cloneDeep(linesData[0].configs));
    }

    let additionalLines = [];
    let additionalConfigs = [];
    for(let i = 1; i < linesData.length; i++) {
        let id = uuid();
        additionalLines.push(
            <SchedulerLine
                id={id}
                key={id}
                parent={component}
                options={_.cloneDeep(component.props.options)}
                hasDeleteButton={true}
                selectedWeekdays={component.state.tariffMode? linesData[i].daysOfWeek : linesData[i].weekdays}
                isView={component.props.isView}
                onWeekdayChanged={(optionId, value) => {handleWeekdayChanged(component, id, optionId, value)}}
                ref={c => {component.lineRefs[id] = c}}
            />);
        additionalConfigs.push(
            <SchedulerConfigs
                id={id}
                key={id + "_config"}
                parent={component}
                configsData={component.state.tariffMode? _.cloneDeep(linesData[i].timeIntervals) : _.cloneDeep(linesData[i].configs)}
                isView={component.props.isView}
                dataType={component.state.dataType}
                tariffMode={component.state.tariffMode}
                tariffCategories={component.state.tariffCategories}
                ref={c => component.configRefs[id] = c}
            />
        );
    }
    if(additionalLines.length > 0) {
        component.setSelectAllEnabled(false);
    }
    component.setBothScheduleLineAndConfigs(additionalLines, additionalConfigs, () => {
        updateDisabledWeekdays(component);
    });
}

function updateDisabledWeekdays(component) {
    _.forOwn(component.lineRefs, lineRef => {
        if(lineRef){
            _.forOwn(_.cloneDeep(lineRef.getWeekdays()), (value, key) => {
                if(value) {
                    _.forOwn(component.lineRefs, scheduleLine => {
                        if(scheduleLine && scheduleLine.props.id !== lineRef.props.id && scheduleLine.weekdaysRef) {
                            scheduleLine.weekdaysRef.setDisabledOption(key, value);
                        }
                        component.setGeneralCheckedOption(key, value);
                    })
                }
            });
        }
    });
}

function getFilteredInterfaceList(list, isView, interfaceEdited) {
    let filteredList = [];
    _.forEach(list, iface => {
        if(!_.isNil(iface.terminalInterfaceType) && iface.terminalInterfaceType !== 'IN') {
            filteredList.push({
                'label': (
                    <>
                        <span style={{ paddingRight: "10px" }}>{iface.displayName}</span>
                        {hasSchedulersCreated(iface) && !isView && iface.interfaceId !== interfaceEdited && (
                            <Tooltip content={t('generic.scheduler.interfaceField.hasSchedulerCreated')}>
                                <img src={blockedIcon} alt={""} style={{width:"15px"}}/>
                            </Tooltip>
                        )
                        }
                    </>
                ),
                'value': iface.interfaceId,
                'searchValue': iface.displayName,
                disabled: hasSchedulersCreated(iface) && !isView && iface.interfaceId !== interfaceEdited,
                ...iface,
            });
        }
    });
    return filteredList;
}

function hasSchedulersCreated(iface){
    const schedulerRulesCount = iface?.ruleInterfaceModelView?.schedulers
    return schedulerRulesCount?.activeRules > 0 || schedulerRulesCount?.errorRules > 0 ||
        schedulerRulesCount?.pendingRules > 0 || schedulerRulesCount?.undeployedRules > 0
}

function getAllLinesData(component, isTariff) {
    let resultFromLines = [];
    if(isTariff) {
        _.forOwn(component.lineRefs, (line, lineId) => {
            if(line) {
                resultFromLines.push({
                    daysOfWeek: line.getWeekdays(),
                    timeIntervals: getAllConfigsDataFromLine(lineId, component, isTariff),
                });
            }
        });
    } else {
        let callbackValue = component.getCallbackValue();
        _.forOwn(component.lineRefs, (line, lineId) => {
            if(line) {
                resultFromLines.push({
                    weekdays: line.getWeekdays(),
                    configs: getAllConfigsDataFromLine(lineId, component, isTariff),
                    callbackValue: callbackValue
                });
            }
        });
    }

    return resultFromLines;
}
function getAllConfigsDataFromLine(lineId, component, isTariff) {
    let resultFromConfigs = [];

    if(isTariff) {
        _.forEach(component.configRefs[lineId].getConfigLines(), configLine => {
            resultFromConfigs.push({
                startTime: configLine.startTime,
                endTime: configLine.endTime,
                displayName: configLine.displayName,
                value: configLine.value
            });
        });
    } else {
        let interfaceId = component.getInterfaceId();
        _.forEach(component.configRefs[lineId].getConfigLines(), configLine => {
            resultFromConfigs.push({
                startTime: configLine.startTime,
                endTime: configLine.endTime,
                action: configLine.action,
                forced: configLine.forced,
                interfaceId: interfaceId,
            });
        });
    }
    return resultFromConfigs;
}

function handleInterfaceChanged(component, iface, resetAllActionFields) {
    _.forOwn(component.configRefs, configRef => {
        if(configRef) {
            if(!_.isNil(component.state.dataType) && iface?.terminalInterfaceDataType) {
                resetAllActionFields = component.state.dataType !== iface.terminalInterfaceDataType
            }

            configRef.setInterfaceDataType((iface && iface?.terminalInterfaceDataType) ? iface.terminalInterfaceDataType : null, resetAllActionFields);
        }
    });

    if(iface?.terminalInterfaceDataType && component.state.dataType !== iface.terminalInterfaceDataType) {
        component.setDataType((iface && iface.terminalInterfaceDataType)? iface.terminalInterfaceDataType : null);
    }
}

function buildAdditionalLines(component) {
    let additionalSchedulerLines = component.state.additionalSchedulerLines;
    let additionalSchedulerConfigs = component.state.additionalSchedulerConfigs;
    let lines = [];
    for (let i = 0; i < additionalSchedulerLines.length; i++) {
        lines.push(additionalSchedulerLines[i]);
        lines.push(additionalSchedulerConfigs[i]);
    }
    return lines;
}

function getObjectFromArray(optionsArray, value) {
    let result = {};
    if(!_.isEmpty(optionsArray)) {
        _.forEach(optionsArray, option => {
            result[option.id] = value;
        });
    }
    return result;
}

function handleWeekdayChanged(component, lineRef, optionId, value) {
    _.forOwn(component.lineRefs, scheduleLine => {
        if(scheduleLine && scheduleLine.props.id !== lineRef && scheduleLine.weekdaysRef) {
            scheduleLine.weekdaysRef.setDisabledOption(optionId, value);
        }
        component.setGeneralCheckedOption(optionId, value);
    })
}

function handleSelectAllChanged(component, value, clicked) {
    if(clicked) {
        let ids = [];
        _.forEach(_.cloneDeep(component.props.options), option => {
            ids.push(option.id);
        });
        component.setMultipleGeneralCheckedOptions(ids, value);
    }
    component.setEnabledCreateButton(!value);
}

function getCreateWeekdaysButton(component) {
    let button;
    if(component.state.enabledCreateButton && !component.props.isView){
        button = (
            <div className="create-form-button open-form-button mt-25">
                <span onClick={() => {
                    component.setSelectAllEnabled(false);
                    addNewLine(component);
                }}>
                    <i className="fa fa-lg fa-plus-circle fa-fw" aria-hidden="true"/>
                    {t("generic.scheduler.addSchedule")}
                </span>
            </div>
        );
    } else if (!component.state.enabledCreateButton && !component.props.isView){
        button = (
            <div className="create-form-button open-form-button">
                    <i className="fa fa-lg fa-plus-circle fa-fw" aria-hidden="true"/>
                    {t("generic.scheduler.addSchedule")}
            </div>
        );
    }
    return button;
}

function addNewLine(component) {
    let additionalSchedulerLines = component.state.additionalSchedulerLines;
    let additionalSchedulerConfigs = component.state.additionalSchedulerConfigs;
    let id = uuid();
    additionalSchedulerLines.push(
        <SchedulerLine
            id={id}
            key={id}
            parent={component}
            options={_.cloneDeep(component.props.options)}
            alreadyCheckedOptions={component.state.checkedOptions}
            hasDeleteButton={true}
            isView={component.props.isView}
            onWeekdayChanged={(optionId, value) => {handleWeekdayChanged(component, id, optionId, value)}}
            ref={c => {component.lineRefs[id] = c}}
        />);
    additionalSchedulerConfigs.push(
        <SchedulerConfigs
            id={id}
            key={id + "_config"}
            parent={component}
            tariffMode={component.state.tariffMode}
            tariffCategories={component.state.tariffCategories}
            isView={component.props.isView}
            dataType={component.state.dataType}
            ref={c => component.configRefs[id] = c}
        />
    );
    component.setBothScheduleLineAndConfigs(additionalSchedulerLines, additionalSchedulerConfigs);
}

function getTableHeader(component) {
    const tooltipContent = component.state.tariffMode?
        t('rules.form.tabs.genericInputs.timeHelpers.tariffTimezone') :
        t('rules.form.tabs.genericInputs.timeHelpers.terminalTimezone');

    return (
        <thead>
        <div>
            <div>
                {t('generic.scheduler.headers.weekdays')}
                <Tooltip content={tooltipContent}>
                    {
                        <img
                            alt=""
                            className={"pd-l-10 labeled-field-helper-icon"}
                            src={HelperIcon}
                        />
                    }
                </Tooltip>
            </div>
        </div>
        </thead>
    );
}

function isViewMode(component) {
    return component.props.isView;
}

function isEditMode(component) {
    return component.props.isEdit;
}

Scheduler.propTypes = {
    options: PropTypes.array,
    callbackValue: PropTypes.any,
    tariffMode: PropTypes.bool,
    isView: PropTypes.bool,
    timeZone: PropTypes.string
};

export default Scheduler;