import React from "react";
import packageJson from '../../package.json';
import moment from "moment";
import UserSessionService from "../services/UserSessionService";
import UserLanguageService from "../services/UserLanguageService";

global.appVersion = packageJson.version;

const semverGreaterThan = (versionA, versionB) => {
    const versionsA = versionA.split(/\./g);
    const versionsB = versionB.split(/\./g);
    if(versionsA.length > versionsB.length) {
        return true;
    }
    while (versionsA.length || versionsB.length) {
        const a = Number(versionsA.shift());
        const b = Number(versionsB.shift());
        // eslint-disable-next-line no-continue
        if (a === b) continue;
        // eslint-disable-next-line no-restricted-globals
        return a > b || isNaN(b);
    }
    return false;
}

export default class CacheValidator extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            isLatestVersion: false,
            refreshCacheAndReload: async () => {
                console.log('Clearing cache and hard reloading...')
                if (caches) {
                    // Service worker cache should be cleared with caches.delete()
                    await caches.keys().then(async function(names) {
                        await Promise.all(names.map(name => caches.delete(name)));
                    });
                    console.log('Done Clearing cache')
                }
                // delete browser cache and hard reload
                UserSessionService.removeAuthInfo(true);
                UserLanguageService.setDefaultLanguage();
                window.history.pushState(null, null, '/login');
                window.location.reload(true)
            }
        };
    }

    componentDidMount() {
        const millis = moment().valueOf();
        fetch('/meta.json?'+millis, { cache: 'no-cache' })
            .then((response) => response.json())
            .then((meta) => {
                const latestVersion = meta.version;
                const currentVersion = global.appVersion;

                const shouldForceRefresh = semverGreaterThan(latestVersion, currentVersion);
                if (shouldForceRefresh) {
                    console.log(`We have a new version - ${latestVersion}. Should force refresh`);
                    this.setState({ loading: false, isLatestVersion: false });
                } else {
                    console.log(`You already have the latest version - ${latestVersion}. No cache refresh needed.`);
                    this.setState({ loading: false, isLatestVersion: true });
                }
            });
    }

    render() {
        const { loading, isLatestVersion, refreshCacheAndReload } = this.state;
        return this.props.children({ loading, isLatestVersion, refreshCacheAndReload });
    }
}