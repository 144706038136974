import axios from "axios";

const RuleTemplateService = {

    getRuleTemplateSearch: function (searchData, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        axios.get('/rules/template/search', {
            params: searchData,
        }).then(response => {
                onSuccess(response.data);
            }, error => {
                onError(error);
            }
        )
    },

    getRuleTemplateCategories: function (onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};
        axios.get('/rules/template/categories').then(response => {
                onSuccess(response.data);
            }, error => {
                onError(error);
            }
        )
    },

    createRuleTemplate: function (ruleTemplate, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        axios.post('/rules/template', ruleTemplate).then(response => {
                onSuccess(response.data);
            }, error => {
                onError(error);
            }
        )
    },

    getRuleTemplate: function (id, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        axios.get('/rules/template/' + id).then(response => {
                onSuccess(response.data);
            }, error => {
                onError(error);
            }
        )
    },

    getRuleTemplateRaw: function (id, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        axios.get('/rules/template/' + id + "/raw").then(response => {
                onSuccess(response.data);
            }, error => {
                onError(error);
            }
        )
    },

    editRuleTemplate: function (id, ruleTemplate, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        axios.put('/rules/template/' + id, ruleTemplate).then(response => {
                onSuccess(response.data);
            }, error => {
                onError(error);
            }
        )
    },

    deleteRuleTemplate: function (ruleTemplate, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        axios.delete('/rules/template/' + ruleTemplate).then(response => {
                onSuccess(response.data);
            }, error => {
                onError(error);
            }
        )
    },

};


export default RuleTemplateService;