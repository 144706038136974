import { call, put, takeEvery } from 'redux-saga/effects';
import * as siloActionTypes from './actionTypes';
import * as actionTypes from './../../actionTypes';
import AsyncWidgetService from "../../../../services/async/AsyncWidgetService";

function* fetchSiloData({ id }) {
    try {
        const siloInfo = yield call(getSiloDataRequest, id );
        yield put({
            type: actionTypes.LOAD_WIDGET_DATA_SUCCESS,
            id: id,
            data: siloInfo
        })
    } catch (e) {
        yield put({
            id: id,
            type: actionTypes.LOAD_WIDGET_DATA_FAILED
        });
    }
}

const siloSagas = [
    takeEvery(siloActionTypes.LOAD_SILO_WIDGET_DATA_REQUEST,  fetchSiloData)
];

const getSiloDataRequest = (widgetId) => {
    return AsyncWidgetService.getAsyncSiloInfoData(widgetId);
};

export { siloSagas };