import {call, put, takeEvery} from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import _ from "lodash";
import AsyncInterfaceService from '../../services/async/AsyncInterfaceService'
import DashboardService from './../../services/DashboardService';
import AsyncWidgetService from '../../services/async/AsyncWidgetService';
import {batchInfoSagas} from "./widgetSagas/batchInfo/batchInfoSagas"
import {chartSagas} from "./widgetSagas/chart/chartSagas";
import {mapSagas} from "./widgetSagas/map/mapSagas";
import {mortalitySagas} from "./widgetSagas/mortality/mortalitySagas";
import {milkSagas} from "./widgetSagas/milk/milkSagas";
import {consumptionSagas} from "./widgetSagas/consumption/consumptionSagas";
import {siloSagas} from "./widgetSagas/silo/siloSagas";
import {animalWeightSagas} from "./widgetSagas/animalWeight/animalWeightSagas";
import {counterSagas} from "./widgetSagas/counter/counterSagas";
import AsyncEntityService from "../../services/async/AsyncEntityService";
import AsyncDashboardService from "../../services/async/AsyncDashboardService";
import __ from "../../utils/EnhancedLodash";
import {batchKPIEvolutionSagas} from "./widgetSagas/batchKPIEvolution/batchKPIEvolutionSagas";

const getWidgetInterfacesById = interfaceIds => AsyncInterfaceService.getInterfacesById(interfaceIds);
const getWidgetEntitiesById = entitiesId => AsyncEntityService.getEntitiesDetails(entitiesId);
const loadDashboardWidgets = dashboardId => DashboardService.getDashboardAsync(dashboardId);
const editWidget = (widgetId, widgetConfigs) => AsyncWidgetService.editWidgetAsync(widgetId, widgetConfigs);
const fetchRealTimeWidgetDataAsync = interfaceIds => AsyncWidgetService.fetchRealTimeWidgetDataAsync(interfaceIds);
const getUserDashboardAsync = () => AsyncDashboardService.getUserDashboardList();
const getEntityDashboardAsync = () => AsyncDashboardService.getEntityDashboardList();
const getHomepageDashboardAsync = () => AsyncDashboardService.getHomepageDashboard();
const setHomepageDashboardAsync = (id) => AsyncDashboardService.setDashboardHomePage(id);
const createDashboardAsync = (dashboardName) => AsyncDashboardService.createDashboard(dashboardName);
const updateDashboardNameAsync = (id, dashboardName) => AsyncDashboardService.updateDashboardName(id, dashboardName);
const deleteDashboardAsync = (id) => AsyncDashboardService.deleteDashboard(id);
const addDashboardFavouriteAsync = (ids) => AsyncDashboardService.addDashboardFavourite(ids);
const removeEntityDashboardFavouriteAsync = (ids) => AsyncDashboardService.removeEntityDashboardFavourite(ids);
const removeDashboardFavouriteAsync = (id) => AsyncDashboardService.removeDashboardFavourite(id);
const copyEntityDashboardToUserAsync = (id) => AsyncDashboardService.copyEntityDashboardToUser(id);
const getLastAggregatedReadings = interfaceIds => AsyncInterfaceService.getLastAggregatedReadings(interfaceIds);

function* getWidgetInterfacesRequest(action) {
    try {
        const interfacesDetails = yield call(getWidgetInterfacesById, _.uniq(action.interfaceIds).filter(x => x !== undefined));
        let result = {};
        _.forEach(interfacesDetails, interfaceDetails => {
                delete interfaceDetails.entity.responsibleUsers;
                result = {
                    ...result,
                    [interfaceDetails.id] : interfaceDetails}
            }
        );
        yield put({
            type: actionTypes.LOAD_WIDGET_INTERFACE_ID_SUCCESS,
            data: result
        })
    } catch (e) {
        yield put({
            error: action.interfaceIds,
            type: actionTypes.LOAD_WIDGET_INTERFACE_ID_FAILED
        });
    }
}

function* getWidgetEntitiesRequest(action) {
    try {
        const entitiesDetails = yield call(getWidgetEntitiesById, _.uniq(action.entitiesIds));
        let result = {};
        _.forEach(entitiesDetails, entityDetails => {
                delete entityDetails.responsibleUsers;
                result = {
                    ...result,
                    [entityDetails.id] : entityDetails}
            }
        );
        yield put({
            type: actionTypes.LOAD_WIDGET_ENTITIES_ID_SUCCESS,
            data: result
        })
    } catch (e) {
        yield put({
            error: action.entitiesIds,
            type: actionTypes.LOAD_WIDGET_ENTITIES_ID_FAILED
        });
    }
}

function* getDashboardWidgets(action) {
    try {
        const dashboard = yield call(loadDashboardWidgets, action.dashboardId);
        yield put({
            type: actionTypes.LOAD_WIDGETS_SUCCESS,
            dashboard: dashboard
        });
    } catch (e) {
        yield put({
            type: actionTypes.LOAD_WIDGETS_FAILED
        });
    }
}

function saveWidgetsNewPosition({ widgetsToUpdate }) {
    try {
        widgetsToUpdate
            .forEach(
                widget => editWidget(widget.i, {
                    rowIndex: widget.y,
                    columnIndex: widget.x,
                    widgetWidth: widget.w,
                    widgetHeight: widget.h
                }));
    } catch (e) {
    }
}

function* fetchWidgetReadings({ interfaceIds }) {
    try {
        const readingsArray = yield call(fetchRealTimeWidgetDataAsync, _.uniq(interfaceIds));
        yield put({
            type: actionTypes.UPDATE_REAL_TIME_INTERFACES_DATA_SUCCESS,
            readings: readingsArray
        });
    } catch (e) {
        yield put({
            type: actionTypes.UPDATE_REAL_TIME_INTERFACES_DATA_FAILED
        });
    }
}


function* createDashboard({dashboardName}) {
    try {
        const newDashboard = yield call(createDashboardAsync, dashboardName);
        const dashboard = yield call(loadDashboardWidgets, newDashboard.id);
        yield put({
            type: actionTypes.LOAD_WIDGETS_SUCCESS,
            dashboard: dashboard
        });
    } catch (e) {
        yield put({
            type: actionTypes.CREATE_USER_DASHBOARD_FAILED
        });
    }
}

function* deleteDashboard({dashboardId}) {
    try {
        yield call(deleteDashboardAsync, dashboardId);
        yield put({
            type: actionTypes.DELETE_USER_DASHBOARD_SUCCESS
        });
    } catch (e) {
        yield put({
            type: actionTypes.DELETE_USER_DASHBOARD_FAILED
        });
    }
}

function* updateDashboardName({dashboardId, dashboardName}) {
    try {
        yield call(updateDashboardNameAsync, dashboardId, dashboardName);
        yield put({
            type: actionTypes.UPDATE_DASHBOARD_NAME_SUCCESS
        });
    } catch (e) {
        yield put({
            type: actionTypes.UPDATE_DASHBOARD_NAME_FAILED
        });
    }
}

function* addDashboardFavourite({ids}) {
    try {
        yield call(addDashboardFavouriteAsync, ids);
        yield put({
            type: actionTypes.UPDATE_FAVOURITE_DASHBOARD_SUCCESS,
        });
    } catch (e) {
        yield put({
            type: actionTypes.UPDATE_FAVOURITE_DASHBOARD_FAILED
        });
    }
}

function* removeDashboardEntityFavourite({ids}){
    try {
        yield call(removeEntityDashboardFavouriteAsync, ids);
        yield put({
            type: actionTypes.DELETE_FAVOURITE_ENTITY_DASHBOARD_SUCCESS,
        });
    } catch (e) {
        yield put({
            type: actionTypes.DELETE_FAVOURITE_ENTITY_DASHBOARD_FAILED
        });
    }
}

function* removeDashboardFavourite({dashboardId}) {
    try {
        yield call(removeDashboardFavouriteAsync, dashboardId);
        yield put({
            type: actionTypes.DELETE_FAVOURITE_DASHBOARD_SUCCESS
        });
    } catch (e) {
        yield put({
            type: actionTypes.DELETE_FAVOURITE_DASHBOARD_FAILED
        });
    }
}

function* copyEntityDashboardToUser({dashboardId}) {
    try {
        yield call(copyEntityDashboardToUserAsync, dashboardId);
        yield put({
            type: actionTypes.COPY_ENTITY_DASHBOARD_TO_USER_SUCCESS,
            showSuccessAlert: true
        });
    } catch (e) {
        yield put({
            type: actionTypes.COPY_ENTITY_DASHBOARD_TO_USER_FAILED
        });
    }
}


function* getUserDashboard() {
    try {
        const dashboard = yield call(getUserDashboardAsync);
        yield put({
            type: actionTypes.LOAD_USER_DASHBOARD_SUCCESS,
            result: dashboard
        });
    } catch (e) {
        yield put({
            type: actionTypes.LOAD_USER_DASHBOARD_FAILED
        });
    }
}

function* getEntityDashboard() {
    try {
        const dashboard = yield call(getEntityDashboardAsync);
        yield put({
            type: actionTypes.LOAD_ENTITY_DASHBOARD_SUCCESS,
            result: dashboard
        });
    } catch (e) {
        yield put({
            type: actionTypes.LOAD_ENTITY_DASHBOARD_FAILED
        });
    }
}

function* getHomepageDashboard() {
    try {
        const dashboard = yield call(getHomepageDashboardAsync);
        yield put({
            type: actionTypes.LOAD_HOMEPAGE_DASHBOARD_SUCCESS,
            result: dashboard
        });
    } catch (e) {
        yield put({
            type: actionTypes.LOAD_HOMEPAGE_DASHBOARD_FAILED
        });
    }
}

function* setHomepageDashboard({dashboardId}) {
    try {
        const dashboard = yield call(setHomepageDashboardAsync, dashboardId);
        yield put({
            type: actionTypes.SET_HOMEPAGE_DASHBOARD_SUCCESS,
            result: dashboard
        });
    } catch (e) {
        yield put({
            type: actionTypes.SET_HOMEPAGE_DASHBOARD_FAILED
        });
    }
}

function* fetchLastAggregatedReadings({ interfaceIds }) {
    try {
        const readingsArray = yield call(getLastAggregatedReadings, _.uniq(interfaceIds));

        yield put({
            type: actionTypes.UPDATE_REAL_TIME_INTERFACES_AGGREGATE_DATA_SUCCESS,
            readings: !__.isNilOrEmpty(readingsArray) ? readingsArray : []
        });
    } catch (e) {
        yield put({
            type: actionTypes.UPDATE_REAL_TIME_INTERFACES_AGGREGATE_DATA_ERROR
        });
    }
}

const dashboardSagas = [
    takeEvery(actionTypes.CREATE_USER_DASHBOARD, createDashboard),
    takeEvery(actionTypes.UPDATE_DASHBOARD_NAME, updateDashboardName),
    takeEvery([
        actionTypes.LOAD_USER_DASHBOARD,
        actionTypes.CREATE_USER_DASHBOARD_SUCCESS, actionTypes.CREATE_USER_DASHBOARD_FAILED,
        actionTypes.UPDATE_DASHBOARD_NAME_SUCCESS, actionTypes.UPDATE_DASHBOARD_NAME_FAILED,
        actionTypes.DELETE_USER_DASHBOARD_SUCCESS,
        actionTypes.COPY_ENTITY_DASHBOARD_TO_USER_SUCCESS,
    ], getUserDashboard),
    takeEvery([
        actionTypes.LOAD_ENTITY_DASHBOARD,
        actionTypes.UPDATE_DASHBOARD_NAME_SUCCESS, actionTypes.UPDATE_DASHBOARD_NAME_FAILED,
        actionTypes.DELETE_FAVOURITE_DASHBOARD_SUCCESS,
        actionTypes.UPDATE_FAVOURITE_DASHBOARD_SUCCESS,
        actionTypes.DELETE_FAVOURITE_ENTITY_DASHBOARD_SUCCESS
    ], getEntityDashboard),
    takeEvery([
        actionTypes.LOAD_HOMEPAGE_DASHBOARD,
        actionTypes.SET_HOMEPAGE_DASHBOARD_SUCCESS
        ], getHomepageDashboard),
    takeEvery(actionTypes.SET_HOMEPAGE_DASHBOARD, setHomepageDashboard),
    takeEvery(actionTypes.UPDATE_FAVOURITE_DASHBOARD, addDashboardFavourite),
    takeEvery(actionTypes.DELETE_FAVOURITE_ENTITY_DASHBOARD, removeDashboardEntityFavourite),
    takeEvery(actionTypes.DELETE_DASHBOARD, deleteDashboard),
    takeEvery(actionTypes.DELETE_FAVOURITE_DASHBOARD, removeDashboardFavourite),
    takeEvery(actionTypes.COPY_ENTITY_DASHBOARD_TO_USER, copyEntityDashboardToUser),

    takeEvery([
        actionTypes.UPDATE_DASHBOARD_NAME_SUCCESS, actionTypes.UPDATE_DASHBOARD_NAME_FAILED,
        actionTypes.LOAD_WIDGETS_REQUEST
    ], getDashboardWidgets),
    takeEvery(actionTypes.LOAD_WIDGET_ENTITIES_ID_REQUEST, getWidgetEntitiesRequest),
    takeEvery(actionTypes.LOAD_WIDGET_INTERFACE_ID_REQUEST, getWidgetInterfacesRequest),
    takeEvery(actionTypes.UPDATE_LAYOUT, saveWidgetsNewPosition),
    takeEvery(actionTypes.UPDATE_REAL_TIME_INTERFACES_DATA_REQUEST, fetchWidgetReadings),
    takeEvery(actionTypes.UPDATE_REAL_TIME_INTERFACES_AGGREGATE_DATA_REQUEST, fetchLastAggregatedReadings),
    ...chartSagas,
    ...mapSagas,
    ...batchInfoSagas,
    ...animalWeightSagas,
    ...consumptionSagas,
    ...siloSagas,
    ...mortalitySagas,
    ...counterSagas,
    ...milkSagas,
    ...batchKPIEvolutionSagas
];

export { dashboardSagas };
