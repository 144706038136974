import {call, put, takeEvery} from 'redux-saga/effects';
import * as chartActionTypes from './actionTypes';
import * as actionTypes from './../../actionTypes';
import AsyncWidgetService from "../../../../services/async/AsyncWidgetService";
import AsyncInterfaceService from "../../../../services/async/AsyncInterfaceService";
import moment from "moment";

const getChartData = (widgetId, searchData) => AsyncWidgetService.getChartWidgetData(widgetId, searchData);
const getInterfaceData = (searchData) => AsyncInterfaceService.getInterfaceReadings(searchData);

function* fetchChartData({ id, searchData }) {
    try {
        const data = yield call(getChartData, id, searchData);
        yield put({
            type: actionTypes.LOAD_WIDGET_DATA_SUCCESS,
            id: id,
            data: data
        })
    } catch (e) {
        yield put({
            id: id,
            type: actionTypes.LOAD_WIDGET_DATA_FAILED
        });
    }
}

function* loadInterfaceData({id, interfaceId, operation}) {
    try {
        const data = yield call(getInterfaceData, {
            interfaceIds: [interfaceId],
            fromDate: moment().utc().subtract(1, 'day').format("YYYY-MM-DDTHH:mm:ss"),
            toDate: moment().utc().format("YYYY-MM-DDTHH:mm:ss"),
            readingResolution: "FIVE_MIN",
            readingOperationType: parseOperation(operation)
        });
        yield put({
            type: actionTypes.LOAD_WIDGET_DATA_SUCCESS,
            id: id,
            data: data && data[0]?.readings ? data[0].readings : []
        })
    } catch (e) {
        yield put({
            id: id,
            type: actionTypes.LOAD_WIDGET_DATA_FAILED
        });
    }

}

function parseOperation(op) {
    switch (op) {
        case "avg": return "AVERAGE";
        case "sum": return "SUM";
        case "min": return "MIN";
        case "max": return "MAX";
        default: return "AVERAGE";
    }
}

const chartSagas = [
    takeEvery(chartActionTypes.LOAD_CHART_WIDGET_DATA_REQUEST, fetchChartData),
    takeEvery(chartActionTypes.LOAD_INTERFACE_CHART_DATA_REQUEST, loadInterfaceData)
];

export { chartSagas };