import React from 'react';
import _ from "lodash";
import __ from "../../../utils/EnhancedLodash";
import LabeledField from "../../fields/labeled/LabeledField";
import Row from "../../row/Row";
import RuleService from "../../../services/RuleService";
import SwitchField from "../../fields/switch/SwitchField";
import {t} from "../../../utils/Translation";
import EntityService from "../../../services/EntityService";
import PropTypes from 'prop-types';
import CheckboxGroup from "../checkbox/CheckboxGroup";
import {config} from "../../../config";
import DropGroup from "../dropgroup/DropGroup";
import CycleGroup from "../cycle/CycleGroup";
import moment from 'moment';
import TimezoneUtils from "../../../utils/TimezoneUtils";
import Scheduler from "../scheduler/Scheduler";
import UserSessionService from "../../../services/UserSessionService";
import RuleCacheService from "../../../services/RuleCacheService";
import LocalesUtils from "../../../utils/LocalesUtils";
import GlobalRuleForm from "../../globalRule/GlobalRuleForm";
import ClickWrapper from "../../hoc/ClickWrapper";

class GenericInput extends React.Component {

    render() {
        return createFieldsFromInput(this, this.props.input, this.props.selectedEntity, this.props.selectedTerminal, this.props.selectedSector, this.props.history);
    }
}

function createFieldsFromInput (component, input, selectedEntity, selectedTerminal, selectedSector, history) {
    let inputComponent =  {};
    let parentForm = component.props.parentForm;
    if(!_.isNil(input) && !_.isEmpty(input)) {
        switch(input.type) {
            case 'DECIMAL':
                inputComponent = createGenericLabeledField(component, input, 'text', () => validateDecimal(parentForm, input));
                break;
            case 'INTEGER':
                inputComponent = createGenericLabeledField(component, input, 'text', () => validateInteger(parentForm, input));
                break;
            case 'TEXT':
                inputComponent = createGenericLabeledField(component, input, 'text');
                break;
            case 'BOOLEAN':
                inputComponent = createSwitchField(component, input);
                break;
            case 'DATE':
                inputComponent = createDateTimeField(component, input, true, false);
                break;
            case 'TIME':
                inputComponent = createDateTimeField(component, input, false, true, true);
                break;
            case 'DATETIME':
                inputComponent = createDateTimeField(component, input, true, true, true);
                break;
            case 'DATEINTERVAL':
                inputComponent = createDateTimeIntervalField(component, input, true, false, false);
                break;
            case 'TIMEINTERVAL':
                inputComponent = createDateTimeIntervalField(component, input, false, true, true);
                break;
            case 'DATETIMEINTERVAL':
                inputComponent = createDateTimeIntervalField(component, input, true, true, true);
                break;
            case 'WEEKDAYS':
                let options = RuleService.getWeekdays();
                let selectAll = RuleService.getSelectAll();
                inputComponent = createCheckBoxGroup(component, input, options, selectAll);
                break;
            case 'ENUM':
                inputComponent = createGenericLabeledField(component, input, 'dropdown');
                break;
            case 'RANGE':
                break;
            case 'ENTITY':
                inputComponent = createGenericLabeledField(component, input, 'dropdown', null, selectedEntity, selectedTerminal, selectedSector, history);
                break;
            case 'ARRAY':
                break;
            case 'DROPGROUP':
                inputComponent = createDropGroup(component, input, selectedEntity, selectedTerminal, selectedSector);
                break;
            case 'CYCLEGROUP':
                inputComponent = createCycleGroup(component, input);
                break;
            case 'SCHEDULER':
                inputComponent = createScheduler(component, input, selectedEntity, selectedTerminal, selectedSector, history);
                break;
            case 'TIMEZONE':
                inputComponent = createTimezoneField(component, input);
                break;
            default:
                throw new Error("It was not possible to parse input type: " + input.type);
        }
    }
    return inputComponent;
}

function createGenericLabeledField (component, input, type, validator, selectedEntity, selectedTerminal, selectedSector, history) {
    let defaultWidth = RuleService.templateFieldOptions.fieldWidth;
    let isViewMode = component.props.viewMode;
    let dropdownData = [];
    let placeholderKey = _.toLower(input.type);

    let dataId, dataAttribute;

    if(type === 'dropdown') {
        placeholderKey = type;
        if(!_.isEmpty(input.inputContext) && !_.isEmpty(input.inputContext.enumEntries)){
            if (component.props.selectedRule){
                dropdownData = translateEnumEntries(input.inputContext.enumEntries, input.id)
            } else {
                dropdownData = input.inputContext.enumEntries;
            }
        } else if (!_.isNil(input.inputContext.inputEntityType)) {
            dropdownData = getCachedInfoFor(component, input, selectedEntity, selectedTerminal, selectedSector);
            dataId = input.inputContext.inputEntityType === 'INTERFACE'? "interfaceId" : "id";
            dataAttribute = "displayName";
            if(!_.isEmpty(dropdownData) && !hasFilters(input)){
            } else {
                setDropdownDataOfInput(component, input, selectedEntity, selectedTerminal, selectedSector);
            }
        } else {
            throw new Error("There's no data available to show in " + input.name);
        }
    }
    return (
        <Row key={input.id}>
            <ClickWrapper onClickHandler={dataId === 'interfaceId' && isViewMode ?
                () => handleClickInterface(component, input, history) : null}>
                <LabeledField
                    key={input.id}
                    fieldLabel={input.name}
                    fieldPlaceHolder={t('rules.form.tabs.genericInputs.placeholders.' + placeholderKey)}
                    fieldType={type}
                    dataAttribute={dataAttribute}
                    dataId={dataId}
                    width={input.width? input.width : defaultWidth}
                    selectData={dropdownData}
                    labelWidth='100%'
                    isRequired={input.mandatory}
                    validator={validator}
                    isView={isViewMode}
                    tabNumber={component.props.tabNumber}
                    ref={(c) => component.props.parentForm.inputRefs[input.id] = c}
                />
            </ClickWrapper>
        </Row>
       );
}

function handleClickInterface(component, input, history){
    const state = component.props.parentForm.inputRefs[input.id].state
    if(state){
        const selectedInterface = state.data.find(_interface => _interface.interfaceId === state.value);

        if(selectedInterface) {
            history.push(`/system/terminals/details/${selectedInterface.terminal.id}`, {
                historyBack: true,
                hideFilters: true,
                interfaceSearchName: selectedInterface.name
            });
        }
    }

}

export function translateEnumEntries (inputEntries, enumId){
    const splitEnumIdValues = enumId.split("_")
    return inputEntries.map(entry=> {
        const translatedValue = t('rules.form.tabs.genericInputs.' + splitEnumIdValues[0] + "." + entry.id.toLowerCase());
        return{
            id: entry.id,
            name: translatedValue || entry.name
        }
    })
}

function createDateTimeField (component, input, hasDate, hasTime, hasSeconds=true) {
    let defaultWidth = RuleService.templateFieldOptions.fieldWidth;
    let isViewMode = component.props.viewMode;
    let fieldLabel = input.name;
    let tooltip;

    if((hasTime && hasDate) ||
        (hasTime && !hasDate && (
            _.isNil(input.inputContext) || (
                !_.isNil(input.inputContext) && !input.inputContext.duration
            )
        ))
    ) {
        tooltip =  !component.props.globalRule? t('rules.form.tabs.genericInputs.timeHelpers.terminalTimezone') : t('rules.form.tabs.genericInputs.timeHelpers.selectedTimezone');
    }

    return (
        <Row key={input.id}>
            <LabeledField
                key={input.id}
                fieldLabel={fieldLabel}
                fieldPlaceHolder={t('rules.form.tabs.genericInputs.placeholders.' + (hasDate? 'date': '') + (hasTime? 'time': ''))}
                fieldType='dateTime'
                width={input.width? input.width : defaultWidth}
                hasDate={hasDate}
                hasTime={hasTime}
                hasSeconds={hasSeconds}
                labelWidth='100%'
                isView={isViewMode}
                isRequired={input.mandatory}
                iconClassName={"fa fa-calendar"}
                keepDatetimeValuesInUTC={true}
                tabNumber={component.props.tabNumber}
                tooltip={tooltip}
                ref={(c) => component.props.parentForm.inputRefs[input.id] = c}
            />
        </Row>);
}

function createTimezoneField(component, input) {
    let isViewMode = component.props.viewMode;

    return <Row key={input.id}>
        <LabeledField
            fieldLabel={t('profile.form.timezone.label')}
            fieldPlaceHolder={t('profile.form.timezone.placeholder')}
            fieldType="dropdown"
            width="4"
            isView={isViewMode}
            isRequired={input.mandatory}
            selectData={TimezoneUtils.getTimezoneDataForDropdown()}
            tabNumber={component.props.tabNumber}
            value={UserSessionService.getCurrentUserTimezone()}
            sort={(data) => {
                return _.sortBy(data, element => {return _.toNumber(element.offset.replace(":",""))});
            }}
            ref={(c) => component.props.parentForm.inputRefs[input.id] = c}
        />
    </Row>
}

function createCheckBoxGroup (component, input, options, selectAll) {
    let isViewMode = component.props.viewMode;

    return (
        <Row key={input.id}>
            <CheckboxGroup
                key={input.id}
                groupLabel={input.name}
                selectAll={selectAll}
                options={options}
                isView={isViewMode}
                tabNumber={component.props.tabNumber}
                startAllChecked={true}
                ref={(c) => component.props.parentForm.inputRefs[input.id] = c}
            />
        </Row>);
}

function createDateTimeIntervalField (component, input, hasDate, hasTime, hasSeconds) {
    let defaultWidth = RuleService.templateFieldOptions.fieldWidth;
    let isViewMode = component.props.viewMode;

    let switchFieldForDateTimeIntervals = !isViewMode? createSwitchFieldForDateTimeIntervals(component, input, hasDate, hasTime) : null;
    let disabledInterval, defaultInterval;
    if(hasDate && !hasTime) {
        disabledInterval = {start: false, end: true};
        defaultInterval = {start: moment(), end: ''};
    } else if (hasTime && !hasDate) {
        disabledInterval = {start: true, end: true};
        defaultInterval = {start: moment('00:00:00', 'HH:mm:ss'), end: moment('23:59:59', 'HH:mm:ss')};
    }
    return (
        <Row key={input.id}>
            <LabeledField
                key={input.id}
                fieldLabel={input.name}
                fieldType='dateTimeInterval'
                width={input.width? input.width : defaultWidth}
                hasDate={hasDate}
                hasTime={hasTime}
                hasSeconds={hasSeconds}
                isView={isViewMode}
                labelWidth='200px'
                isRequired={input.mandatory}
                interval={defaultInterval}
                disabledInterval={disabledInterval}
                tooltip={hasTime? !component.props.globalRule? t('rules.form.tabs.genericInputs.timeHelpers.terminalTimezone') : t('rules.form.tabs.genericInputs.timeHelpers.selectedTimezone') : null}
                tabNumber={component.props.tabNumber}
                keepDatetimeValuesInUTC={true}
                onBlur={(changedValue, intervalValue) => validateDateTimeInterval(component.props.parentForm.inputRefs[input.id], intervalValue)}
                ref={(c) => component.props.parentForm.inputRefs[input.id] = c}
            />
            {switchFieldForDateTimeIntervals}
        </Row>);
}

function createSwitchField (component, input) {
    let isViewMode = component.props.viewMode;
    let checkedLabel = t('rules.form.tabs.basicInfo.switchStatus.active');
    let uncheckedLabel = t('rules.form.tabs.basicInfo.switchStatus.inactive');

    if(input.inputContext && input.inputContext.translations) {
        if(input.inputContext.translations['true']) {
            checkedLabel = LocalesUtils.getLocaleString(input.inputContext.translations['true']);
        }
        if(input.inputContext.translations['false']) {
            uncheckedLabel = LocalesUtils.getLocaleString(input.inputContext.translations['false']);
        }
    }

    return (
        <Row key={input.id}>
            <SwitchField
                forceNewPropValue={true}
                key={input.id}
                fieldLabel={input.name}
                width='4'
                labelPosition='in-line'
                labelWidth='200px'
                isView={isViewMode}
                checkedLabel={checkedLabel}
                uncheckedLabel={uncheckedLabel}
                checkedLabelClassName="status-label"
                uncheckedLabelClassName="status-label"
                tabNumber={component.props.tabNumber}
                ref={(c) => component.props.parentForm.inputRefs[input.id] = c}
            />
        </Row>);
}

function createDropGroup (component, input, selectedEntity, selectedTerminal, selectedSector) {
    let isViewMode = component.props.viewMode;
    let actionType = input.inputContext.inputEntityType;

    let cachedTerminalsInfo;
    let cachedInterfacesInfo;

    if(!component.props.globalRule) {
        cachedInterfacesInfo = !_.isEmpty(RuleCacheService.getInterfacesOfTerminal(selectedTerminal)) ?
            RuleCacheService.getInterfacesOfTerminal(selectedTerminal) :
            null;
        if(!cachedInterfacesInfo) {
            getInterfacesForLocalRule(component, input, selectedEntity, selectedTerminal, selectedSector);
        } else if(!_.isEmpty(selectedSector) && !component.props.showAllInterfaces) {
            cachedInterfacesInfo = _.filter(cachedInterfacesInfo, iface => {return iface.entity.id === selectedSector.id;})
        }
    } else {
        let entityToSearch = _.isString(selectedEntity)? selectedEntity : selectedEntity.id;
        cachedTerminalsInfo = !_.isEmpty(RuleCacheService.getTerminalsOfEntity(entityToSearch)) ?
            RuleCacheService.getTerminalsOfEntity(entityToSearch) :
            null;
        if(!cachedTerminalsInfo) {
             getTerminalsForGlobalRule(component, input, { id: entityToSearch });
        }
    }

    if(actionType === "OUTPUT"){
        return <DropGroup
            key={input.id}
            isView={isViewMode}
            actionType={actionType}
            buttonLabel={input.name}
            globalRule={component.props.globalRule}
            tabNumber={component.props.tabNumber}
            isRequired={input.mandatory}
            selectedEntity={selectedEntity}
            selectedTerminal={selectedTerminal}
            selectedSector={selectedSector}
            terminalList={cachedTerminalsInfo}
            interfaceList={cachedInterfacesInfo}
            showAllInterfaces={component.props.showAllInterfaces}
            ref={(c) => component.props.parentForm.inputRefs[input.id] = c}
        />
    } else {
        return <GlobalRuleForm
            key={input.id}
            tabNumber={component.props.tabNumber}
            isView={isViewMode}
            globalRule={component.props.globalRule}
            terminalList={cachedTerminalsInfo}
            interfaceList={cachedInterfacesInfo}
            actionType={actionType}
            buttonLabel={input.name}
            isRequired={input.mandatory}
            selectedEntity={selectedEntity}
            selectedTerminal={selectedTerminal}
            selectedSector={selectedSector}
            showCycleTitleSection={false}
            showAllInterfaces={component.props.showAllInterfaces}
            onGlobalRuleChange={(c) => {
                component.props.parentForm.inputRefs[input.id] = c
            }}
        />
    }




}

function createCycleGroup (component, input) {
    let isViewMode = component.props.viewMode;
    let inputs = input?.inputContext?.inputs ? input.inputContext.inputs :  [];

    return <CycleGroup
        key={input.id}
        viewMode={isViewMode}
        inputs={inputs}
        header={input.name}
        preCycleStartDate={moment()}
        parentForm={component.props.parentForm}
        tabNumber={component.props.tabNumber}
        ref={(c) => component.props.parentForm.inputRefs[input.id] = c}
    />
}

function createScheduler (component, input, selectedEntity, selectedTerminal, selectedSector, history) {
    const isViewMode = component.props.viewMode;
    const isEditMode = component.props.editMode;

    const callbackValue = !_.isNil(input.inputContext.callbackValue)? input.inputContext.callbackValue : null;

    getInterfacesForLocalRule(component, input, selectedEntity, selectedTerminal, selectedSector, RuleService.dataType.BOOLEAN);

    return <Scheduler
        key={input.id}
        isView={isViewMode}
        isEdit={isEditMode}
        callbackValue={callbackValue}
        tabNumber={component.props.tabNumber}
        options={RuleService.getWeekdays()}
        selectedEntity={selectedEntity}
        selectedTerminal={selectedTerminal}
        selectedSector={selectedSector}
        timeZone={'UTC'}
        history={history}
        ref={(c) => component.props.parentForm.inputRefs[input.id] = c}
    />;
}

function getInterfacesForLocalRule(component, input, selectedEntity, selectedTerminal, selectedSector, dataType) {
    let entity = !_.isNil(selectedEntity)? selectedEntity.id : null;
    let terminal = !_.isNil(selectedTerminal)? selectedTerminal.id : null;
    if (entity) {
        RuleService.getInterfacesWithCategory(entity, terminal, null, null, interfaces => {
            if (component.props.parentForm.inputRefs[input.id]) {
                if(dataType && (selectedSector && !component.props.showAllInterfaces)) {
                    interfaces = _.filter(
                        interfaces,
                        iface => {
                            return (_.isNil(dataType) || iface.terminalInterfaceDataType === dataType) &&
                                (_.isEmpty(selectedSector) || iface.entity.id === selectedSector.id);
                        });
                }
                component.props.parentForm.inputRefs[input.id].setInterfaceList(interfaces);
            }
        }, () => {
            throw new Error("It was not possible to fetch the Interface List and the dropgroup was not created");
        });
    } else {
        component.props.parentForm.inputRefs[input.id]?.setInterfaceList([]);
    }
}

function getTerminalsForGlobalRule(component, input, selectedEntity) {
    let entity = !_.isNil(selectedEntity) ? selectedEntity.id : null;
    if (entity) {
        RuleService.getTerminalsFromEntity(entity, false, terminals => {
            if (component.props.parentForm.inputRefs[input.id]) {
                component.props.parentForm.inputRefs[input.id].setTerminalList(terminals);
            }
        }, () => {
            throw new Error("It was not possible to fetch the Terminals List");
        });
    } else {
        component.props.parentForm.inputRefs[input.id].setTerminalList([]);
    }
}

function getCachedInfoFor(component, input, selectedEntity, selectedTerminal, selectedSector) {
    if (!_.isNil(selectedEntity)) {
        switch (input.inputContext.inputEntityType) {
            case 'TERMINAL':
                if (component.props.globalRule) {
                    return RuleCacheService.getTerminalsOfEntity(selectedEntity.id);
                } else {
                    throw new Error("It was not possible to fetch TerminalList since this is a Local rule.");
                }
            case 'INTERFACE':
                let interfaces = !__.isNilOrEmpty(selectedTerminal) ? RuleCacheService.getInterfacesOfTerminal(selectedTerminal.id) : [];
                interfaces = RuleService.filterInterfacesWithCategory(interfaces, input.inputContext.category? input.inputContext.category : null);
                if (!_.isEmpty(interfaces) && !_.isEmpty(selectedSector)) {
                    interfaces = _.filter(interfaces, iface => {return iface.entity.id === selectedSector.id;})
                }
                return interfaces;
            default:
                return null;
        }
    } else {
        return null;
    }
}

function setDropdownDataOfInput(component, input, selectedEntity, selectedTerminal, selectedSector) {
    if (!_.isNil(selectedEntity)) {
        let filters = input.inputContext? input.inputContext.filters?  input.inputContext.filters : null : null;
        switch (input.inputContext.inputEntityType) {
            case 'USER':
                break;
            case 'TERMINAL':
                if (component.props.globalRule) {
                    if (!_.isEmpty(selectedEntity)) {
                        RuleService.getTerminalsFromEntity(selectedEntity.id, false, terminals => {
                            component.props.parentForm.inputRefs[input.id].setDataAttribute('displayName');
                            component.props.parentForm.inputRefs[input.id].setData(terminals);
                        }, () => {
                            throw new Error("It was not possible to fetch TerminalList");
                        });
                    } else {
                        if (!_.isNil(component.props.parentForm.inputRefs[input.id])) {
                            component.props.parentForm.inputRefs[input.id].setData([]);
                        }
                        throw new Error("There is no selected Entity to fetch Terminals from");
                    }
                } else {
                    throw new Error("It was not possible to fetch TerminalList since this is a Local rule.");
                }
                break;
            case 'INTERFACE':
                if (!_.isEmpty(selectedEntity)) {
                    RuleService.getInterfacesWithCategory(selectedEntity.id,
                        !_.isEmpty(selectedTerminal)? selectedTerminal.id : null,
                        input.inputContext.category? input.inputContext.category : null,
                        filters,
                        interfaces => {
                            if(!_.isEmpty(selectedSector) && !component.props.showAllInterfaces) {
                                interfaces = _.filter(interfaces, iface => {return iface.entity.id === selectedSector.id;})
                            }
                            if(component.props.parentForm.inputRefs[input.id]){
                                component.props.parentForm.inputRefs[input.id].setData(interfaces);
                            }
                        }, () => {
                            throw new Error("It was not possible to fetch InterfaceList");
                        });
                }
                break;
            case 'ENTITIES':
                EntityService.getActiveEntityList({
                        entityStatus: EntityService.entityStatus.ACTIVE
                    }, entities => {
                        component.props.parentForm.inputRefs[input.id].setDataAttribute('displayName');
                        component.props.parentForm.inputRefs[input.id].setData(entities);
                    }, () => {
                        handleErrorForEntityInputType(input.inputContext.entityType)
                    }
                );
                break;
            default:
                handleErrorForEntityInputType(input.inputContext.entityType);
        }
    } else {
        if(!_.isNil(component.props.parentForm.inputRefs[input.id]) && !_.isEmpty(component.props.parentForm.inputRefs[input.id].state.data)) {
            component.props.parentForm.inputRefs[input.id].setData([]);
        }
    }
}

function hasFilters(input) {
    return !_.isNil(input.inputContext) && !_.isNil(input.inputContext.filters) && !_.isEmpty(input.inputContext.filters);
}

function validateInteger (parentForm, input) {
    let field = parentForm.inputRefs[input.id];
    let errorMessage = '';
    if(_.isNil(field)){
        return errorMessage;
    }
    let regex = config.generic.positiveornegativenumberregex;
    let value = field.getFieldValue();
    if(!regex.test(value)){
        errorMessage = t('errors.generic.notANumber');
    }
    if(errorMessage === '' && !_.isNil(input.inputContext) && (!_.isNil(input.inputContext.min) || !_.isNil(input.inputContext.max)) ) {
        errorMessage = validateMinAndMax(input, value);
    }
    return errorMessage;
}

function validateDecimal (parentForm, input) {
    let field = parentForm.inputRefs[input.id];
    let errorMessage = '';
    if(_.isNil(field)){
        return errorMessage;
    }
    let regex = config.generic.positiveornegativedecimalregex;
    let value = field.getFieldValue();
    if(!regex.test(value)){
        errorMessage = t('errors.generic.notADecimal');
    }
    if(errorMessage === '' && !_.isNil(input.inputContext) && (!_.isNil(input.inputContext.min) || !_.isNil(input.inputContext.max)) ) {
        errorMessage = validateMinAndMax(input, value);
    }
    return errorMessage;
}

function validateMinAndMax(input, value) {
    let error = '';
    let min = !__.isNilOrEmpty(input.inputContext.min) ? input.inputContext.min : Number.NEGATIVE_INFINITY;
    let max = !__.isNilOrEmpty(input.inputContext.max) ? input.inputContext.max : Number.POSITIVE_INFINITY;

    if(max !== Number.POSITIVE_INFINITY && min !== Number.NEGATIVE_INFINITY && (value < min || value > max)) {
        error = t('errors.generic.notBetweenMinAndMax', {min: input.inputContext.min, max: input.inputContext.max});
    } else if (max !== Number.POSITIVE_INFINITY && value > max) {
        error = t('errors.generic.notLessThan', {max: input.inputContext.max});
    } else if (min !== Number.NEGATIVE_INFINITY && value < min ) {
        error = t('errors.generic.notGreaterThan', {min: input.inputContext.min});
    }

    return error;
}

function validateDateTimeInterval(field, intervalValue) {
    let errorMessage = '';
    if(!_.isNil(field) &&
        intervalValue.start instanceof moment &&
        intervalValue.end instanceof moment &&
        intervalValue.end.isBefore(intervalValue.start)){
        errorMessage = t('errors.dateTimeField.dateTimeIntervalEndDate');
    }
    field.setError(errorMessage);
}

function handleErrorForEntityInputType(type) {
    throw new Error("It was not possible to fetch data for type " + type);
}

function createSwitchFieldForDateTimeIntervals(component, input, hasDate, hasTime) {
    let defaultWidth = RuleService.templateFieldOptions.fieldWidth;

    let onFieldChange = () => {};
    let label = "";

    if(hasTime) {
        onFieldChange = value => handleTimeIntervalSwitchChange(component, input, value);
        label = t('rules.form.tabs.genericInputs.allDay');
    } else if (hasDate) {
        onFieldChange = value => handleDateIntervalSwitchChange(component, input, value);
        label = t('rules.form.tabs.genericInputs.noEndDate');
    }

    return (
        <SwitchField
            forceNewPropValue={true}
            width={defaultWidth}
            fieldLabel={label}
            checkedLabel=" "
            uncheckedLabel=" "
            className={"mt-21"}
            onFieldChange={value => onFieldChange(value)}
            ref={c => {
                if(component.props.parentForm.inputRefs[input.id]){
                    component.props.parentForm.inputRefs[input.id].switch = c
                }
            }}
        />);
}

function handleTimeIntervalSwitchChange(component, input, value) {
    let field = component.props.parentForm.inputRefs[input.id];
    if (value) {
        field.setIntervalValueFor(moment('00:00:00', 'HH:mm:ss'), 'start');
        field.setIntervalValueFor(moment('23:59:59', 'HH:mm:ss'), 'end');
        field.setError('');
        field.setDisabled(true);
    } else {
        field.clearFieldValue();
        field.setDisabled(false);
    }
}

function handleDateIntervalSwitchChange(component, input, value) {
    let field = component.props.parentForm.inputRefs[input.id];
    if (value) {
        field.setIntervalValueFor('', 'end');
        field.setDisabledInterval(true, 'end');
    } else {
        field.setDisabled(false);
    }
}

GenericInput.propTypes = {
    parentForm: PropTypes.object.isRequired,
    globalRule: PropTypes.bool,
    selectedEntity: PropTypes.object,
    input: PropTypes.object.isRequired,
    tabNumber: PropTypes.number
};

GenericInput.defaultProps = {
    input: [],
    selectedEntity:{},
    selectedTerminal:{},
    selectedSector:{}
};

export default GenericInput;