export const permissions = {
    platform: {
        root: 'ROOT',
    },
    users: {
        create: 'CREATE_USER',
        read: 'READ_USER',
        update: 'UPDATE_USER',
        delete: 'DELETE_USER',
        list: 'LIST_USER'
    },
    roles: {
        create: 'CREATE_ROLE',
        read: 'READ_ROLE',
        update: 'UPDATE_ROLE',
        delete: 'DELETE_ROLE'
    },
    audit: {
        read: 'READ_AUDIT'
    },
    dashboard: {
        default: 'UPDATE_ENTITY_DEFAULT_DASHBOARD'
    },
    entities: {
        create: 'CREATE_ENTITY',
        read: 'READ_ENTITY',
        update: 'UPDATE_ENTITY',
        delete: 'DELETE_ENTITY',
        transfer: 'TRANSFER_ENTITY',
        create_tag: 'CREATE_ENTITY_TAG',
        read_tag: 'READ_ENTITY_TAG',
        update_tag: 'UPDATE_ENTITY_TAG',
        delete_tag: 'DELETE_ENTITY_TAG',
    },
    terminals: {
        create: 'CREATE_TERMINAL',
        read: 'READ_TERMINAL',
        update: 'UPDATE_TERMINAL',
        updateName: 'UPDATE_TERMINAL_NAME',
        delete: 'DELETE_TERMINAL',
        details: 'READ_DETAILS_TERMINAL'
    },
    interfaces: {
        create: 'CREATE_INTERFACE',
        read: 'READ_INTERFACE',
        update: 'UPDATE_INTERFACE',
        updateName: 'UPDATE_INTERFACE_NAME',
        updateSector: 'UPDATE_INTERFACE_SECTOR',
        act: 'ACTUATE_ON_INTERFACE',
        delete: 'DELETE_INTERFACE'
    },
    rules: {
        create: 'CREATE_RULE',
        read: 'READ_RULE',
        update: 'UPDATE_RULE',
        delete: 'DELETE_RULE',
        alarms: 'READ_ALARM_RULE',
        scheduler: 'READ_SCHEDULER_RULE',
        rule_groups: 'READ_RULE_GROUPS'
    },
    rulesTemplates: {
        create: 'CREATE_RULE_TEMPLATE',
        read: 'READ_RULE_TEMPLATE',
        update: 'UPDATE_RULE_TEMPLATE',
        delete: 'DELETE_RULE_TEMPLATE'
    },
    events: {
        create: 'CREATE_EVENT',
        read: 'READ_EVENT',
        update: 'UPDATE_EVENT',
        delete: 'DELETE_EVENT'
    },
    tariffs: {
        create: 'CREATE_TARIFF',
        read: 'READ_TARIFF',
        update: 'UPDATE_TARIFF',
        delete: 'DELETE_TARIFF'
    },
    api: {
        create: 'CREATE_API_ACCESS',
        read: 'READ_API_ACCESS',
        update: 'UPDATE_API_ACCESS',
        delete: 'DELETE_API_ACCESS'
    },
    batches: {
        create: 'CREATE_BATCH',
        read: 'READ_BATCH',
        update: 'UPDATE_BATCH',
        delete: 'DELETE_BATCH',
        transactions: {
            create: 'CREATE_BATCH_TRANSACTION',
            update: 'UPDATE_BATCH_TRANSACTION',
            delete: 'DELETE_BATCH_TRANSACTION',
            read: 'READ_BATCH_TRANSACTION'
        },
        feedMovements: {
            create: 'CREATE_BATCH_FEED_TRANSACTION',
            update: 'UPDATE_BATCH_FEED_TRANSACTION',
            delete: 'DELETE_BATCH_FEED_TRANSACTION',
            read: 'READ_BATCH_FEED_TRANSACTION'
        }
    },
    genetics: {
        create: 'CREATE_ANIMAL_GENETICS',
        read: 'READ_ANIMAL_GENETICS',
        update: 'UPDATE_ANIMAL_GENETICS',
        delete: 'DELETE_ANIMAL_GENETICS'
    },
    curve: {
        create: 'CREATE_PRODUCTION_CURVE',
        read: 'READ_PRODUCTION_CURVE',
        update: 'UPDATE_PRODUCTION_CURVE',
        delete: 'DELETE_PRODUCTION_CURVE'
    },
    feedTypes: {
        create: 'CREATE_FEED_TYPE',
        read: 'READ_FEED_TYPE',
        update: 'UPDATE_FEED_TYPE',
        delete: 'DELETE_FEED_TYPE'
    },
    deathReasons: {
        create: 'CREATE_SPECIES_DEATH_REASON',
        read: 'READ_SPECIES_DEATH_REASON',
        update: 'UPDATE_SPECIES_DEATH_REASON',
        delete: 'DELETE_SPECIES_DEATH_REASON'
    },
    feedFactory: {
        create: 'CREATE_FEED_FACTORY',
        read: 'READ_FEED_FACTORY',
        update: 'UPDATE_FEED_FACTORY',
        delete: 'DELETE_FEED_FACTORY'
    },
    taskTemplate: {
        delete: 'DELETE_TASK_TEMPLATE',
        approveReject: 'APPROVE_REJECT_TASK_TEMPLATE'
    },
    task: {
        create: 'CREATE_TASK',
        read: 'READ_TASK',
        update: 'UPDATE_TASK',
        delete: 'DELETE_TASK'
    },
    report:{
        create: 'CREATE_REPORT',
        read: 'READ_REPORT',
        update:'UPDATE_REPORT',
        delete:'DELETE_REPORT'
    },
    helpdesk:{
        read: 'READ_HELPDESK_ISSUE'
    },
    notifications:{
        sms: 'SMS_NOTIFICATION',
        voice: 'VOICE_NOTIFICATION'
    },
    analytics: {
        read: 'READ_ANALYTICS_OVERVIEW',
        readBatch: 'READ_ANALYTICS_BATCH'
    }
};