import React from "react";
import {t} from "../../../../../../utils/Translation";
import Row from "../../../../../../components/row/Row";
import LabeledField from "../../../../../../components/fields/labeled/LabeledField";
import EventService from "../../../../../../services/EventService";
import SwitchField from "../../../../../../components/fields/switch/SwitchField";

let EventTab = {

    render: ({isView, isEdit}, data, eventData, isGlobalRule, updateFormValue) => {
        return (
            <div className='active-tab'>
                <Row className='pd-l-15'>
                    <Row className='pd-t-5'>
                        <SwitchField
                            forceNewPropValue={true}
                            fieldLabel={t('rules.form.tabs.events.hasEventData.label')}
                            width='4'
                            isView={isView}
                            checkedLabelClassName="status-label"
                            uncheckedLabelClassName="status-label"
                            checkedLabel={t('rules.form.tabs.events.hasEventData.enabled')}
                            uncheckedLabel={t('rules.form.tabs.events.hasEventData.disabled')}
                            isChecked={eventData?.hasEventData?.value}
                            onFieldChange={checked => updateFormValue("hasEventData", checked)}
                        />
                    </Row>
                </Row>
                {
                    eventData?.hasEventData?.value && <Row className='pd-l-15'>
                        <Row className='pd-t-5'>
                            <LabeledField
                                forceNewPropValue={true}
                                fieldLabel={t('rules.form.tabs.events.title.label')}
                                fieldPlaceHolder={t('rules.form.tabs.events.title.placeholder')}
                                fieldType='text'
                                width='8'
                                isRequired={true}
                                isView={isView}
                                value={eventData?.title?.value}
                                error={eventData?.title?.error}
                                onFieldChange={title => updateFormValue("title", title)}
                            />
                        </Row>
                        <Row className='pd-t-5'>
                            <LabeledField
                                forceNewPropValue={true}
                                fieldLabel={t('rules.form.tabs.events.description.label')}
                                fieldPlaceHolder={t('rules.form.tabs.events.description.placeholder')}
                                fieldType='text-area'
                                width='8'
                                isRequired={true}
                                isView={isView}
                                value={eventData?.description?.value}
                                error={eventData?.description?.error}
                                onFieldChange={description => updateFormValue("description", description)}
                            />
                        </Row>
                        <Row className='pd-t-5'>
                            <LabeledField
                                forceNewPropValue={true}
                                fieldLabel={t('rules.form.tabs.events.severity.label')}
                                fieldType='button-group'
                                width='5'
                                selectData={EventService.getSeverityList()}
                                isRequired={true}
                                sort={data => {return data;}}
                                isView={isView}
                                value={eventData?.severity?.value}
                                error={eventData?.severity?.error}
                                onFieldChange={severity => updateFormValue("severity", severity)}
                            />
                        </Row>
                        <Row className='pd-t-5'>
                            <LabeledField
                                forceNewPropValue={true}
                                fieldLabel={t('rules.form.tabs.events.category.label')}
                                fieldPlaceHolder={t('rules.form.tabs.events.category.placeholder')}
                                fieldType='dropdown'
                                width='4'
                                isRequired={true}
                                isView={isView}
                                iconClassName='fa fa-bookmark'
                                value={eventData?.category?.value}
                                selectData={eventData?.category?.data}
                                error={eventData?.category?.error}
                                onFieldChange={category => updateFormValue("category", category)}
                            />
                        </Row>
                        <Row className='pd-t-5'>
                            <LabeledField
                                forceNewPropValue={true}
                                fieldLabel={t('rules.form.tabs.events.ruleDelay.label')}
                                fieldType='number'
                                width='4'
                                isRequired={true}
                                isView={isView}
                                isVisible={false}
                                value={eventData?.ruleDelay?.value ? eventData.ruleDelay.value : '0'}
                                error={eventData?.ruleDelay?.error}
                                onFieldChange={ruleDelay => updateFormValue("ruleDelay", ruleDelay)}
                            />
                        </Row>
                        <Row className='pd-t-5'>
                            <SwitchField
                                forceNewPropValue={true}
                                fieldLabel={t('rules.form.tabs.events.autoClose.label')}
                                width='4'
                                isView={isView}
                                checkedLabelClassName="status-label"
                                uncheckedLabelClassName="status-label"
                                isChecked={eventData.autoClose?.value}
                                onFieldChange={repetitionTime => updateFormValue("autoClose", repetitionTime)}
                            />
                        </Row>
                        {/*<Row className='pd-t-5'>*/}
                        {/*    <LabeledField*/}
                        {/*        fieldLabel={t('rules.form.tabs.events.sector.label')}*/}
                        {/*        fieldPlaceHolder={t('rules.form.tabs.events.sector.placeholder')}*/}
                        {/*        fieldType='dropdown'*/}
                        {/*        width='4'*/}
                        {/*        dataAttribute='displayName'*/}
                        {/*        isView={isView}*/}
                        {/*        sort={data => {return data;}}*/}
                        {/*        iconClassName="fa fa-sitemap"*/}
                        {/*        selectData={data?.sectorId?.data}*/}
                        {/*        value={eventData?.sector?.value}*/}
                        {/*        onFieldChange={entity => updateFormValue("sector", entity)}*/}
                        {/*    />*/}
                        {/*</Row>*/}
                        <Row className='pd-t-5'>
                            <LabeledField
                                forceNewPropValue={true}
                                fieldLabel={t('rules.form.tabs.events.status.label')}
                                fieldPlaceHolder={t('rules.form.tabs.events.status.placeholder')}
                                fieldType='dropdown'
                                selectData={EventService.getStatusList()}
                                width='4'
                                isRequired={true}
                                isView={isView}
                                sort={data => {return data;}}
                                iconClassName='fa fa-repeat'
                                value={eventData?.status?.value}
                                error={eventData?.status?.error}
                                onFieldChange={status => updateFormValue("status", status)}
                            />
                        </Row>
                    </Row>
                }
            </div>
        );
    }
};

export default EventTab;