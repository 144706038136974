import _ from "lodash";
import {config} from "../config";
import * as $ from "jquery";
import dialCodes from "../utils/dialCodes.json";
import RuleCacheService from "../services/RuleCacheService";

let DialCodesUtils = {

    getDialCodes() {
        let codes = _.map(dialCodes['codes'], code => {return {id: code.dial_code, name: '(' + code.dial_code + ') ' + code.name, simpleName: code.name};});
        return _.sortBy(codes, 'simpleName');
    },

    loadToCacheDefaultDialCode() {
        if(!_.isNil(RuleCacheService.getDefaultDialCode())){
            return;
        }

        let codes = this.getDialCodes();

        $.getJSON(config.ipstack.url, function(data) {
            if(!_.isEmpty(_.find(codes, {'simpleName': data.country_name}))){
                let dialCode = _.find(codes, {'simpleName': data.country_name}).id;
                RuleCacheService.storeDefaultDialCode(dialCode);
            }
        });
    },

    getDefaultDialCode() {
        return !_.isNil(RuleCacheService.getDefaultDialCode()) ? RuleCacheService.getDefaultDialCode() : config.ipstack.defaultDialCode;
    },
};

export default DialCodesUtils;








