import _ from "lodash";
import i18n from "i18next";

let UserLanguageService = {

    supportedLanguages: ['en-US', 'pt-PT', 'es-ES', 'fr-FR', 'de-DE', 'el-GR'],

    defaultLanguage: 'en-US',

    currentLanguage: 'en-US',

    isLanguageSupported: function (language) {
        return UserLanguageService.supportedLanguages.find(lang => lang.includes(language))
    },

    getBrowserLanguage: function () {
        return navigator.language || UserLanguageService.defaultLanguage;
    },

    getCurrentLanguage: function () {
        return this.currentLanguage;
    },

    setDefaultLanguage: function () {
        let browserLanguage = UserLanguageService.getBrowserLanguage();
        UserLanguageService.setUserLanguage(browserLanguage);
    },

    setUserLanguage: function (language) {
        if(_.isNil(language)) {
            i18n.changeLanguage(language);
        } else {
            language = language.indexOf('_') !== -1? _.replace(language,'_', '-') : language;
            const langSupported = UserLanguageService.isLanguageSupported(language)
            if(langSupported) {
                this.currentLanguage = langSupported;
                i18n.changeLanguage(langSupported);
            } else {
                i18n.changeLanguage(language);
            }
        }
    }
};

export default UserLanguageService;