import * as actionTypes from "./actionTypes";

const onURLChange = (url) => ({
    type: actionTypes.ON_URL_CHANGE,
    url: url
});

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
    onURLChange
};