import React, { useState, createContext } from 'react';
export const TableListViewContext = createContext();

export function TableListViewProvider({ children }) {
    const [selectedFilter, setSelectedFilter] = useState(null);

    return (
        <TableListViewContext.Provider value={{ selectedFilter, setSelectedFilter }}>
            {children}
        </TableListViewContext.Provider>
    );
}