export const GET_MANUAL_WEIGHING_REQUEST = '[PRODUCTION_BATCHES] GET_MANUAL_WEIGHING_REQUEST';
export const GET_MANUAL_WEIGHING_SUCCESS = '[PRODUCTION_BATCHES] GET_MANUAL_WEIGHING_SUCCESS';
export const GET_MANUAL_WEIGHING_FAILED = '[PRODUCTION_BATCHES] GET_MANUAL_WEIGHING_FAILED';

export const CREATE_MANUAL_WEIGHING_REQUEST = '[PRODUCTION_BATCHES] CREATE_MANUAL_WEIGHING_REQUEST';
export const CREATE_MANUAL_WEIGHING_SUCCESS = '[PRODUCTION_BATCHES] CREATE_MANUAL_WEIGHING_SUCCESS';
export const CREATE_MANUAL_WEIGHING_FAILED = '[PRODUCTION_BATCHES] CREATE_MANUAL_WEIGHING_FAILED';
export const CREATE_MANUAL_WEIGHING_DEFERRED_STATUS = '[PRODUCTION_BATCHES] CREATE_MANUAL_WEIGHING_DEFERRED_STATUS';

export const EDIT_MANUAL_WEIGHING_REQUEST = '[PRODUCTION_BATCHES] EDIT_MANUAL_WEIGHING_REQUEST';
export const EDIT_MANUAL_WEIGHING_SUCCESS = '[PRODUCTION_BATCHES] EDIT_MANUAL_WEIGHING_SUCCESS';
export const EDIT_MANUAL_WEIGHING_FAILED = '[PRODUCTION_BATCHES] EDIT_MANUAL_WEIGHING_FAILED';

export const DELETE_MANUAL_WEIGHING_REQUEST = '[PRODUCTION_BATCHES] DELETE_MANUAL_WEIGHING_REQUEST';
export const DELETE_MANUAL_WEIGHING_SUCCESS = '[PRODUCTION_BATCHES] DELETE_MANUAL_WEIGHING_SUCCESS';
export const DELETE_MANUAL_WEIGHING_FAILED = '[PRODUCTION_BATCHES] DELETE_MANUAL_WEIGHING_FAILED';

export const RESET_MANUAL_WEIGHING_FORM = '[PRODUCTION_BATCHES] RESET_MANUAL_WEIGHING_FORM';