import swal from "sweetalert2";
import {config} from "../config";
import _ from "lodash";
import {t} from "./Translation";

let Alerts = {
    Raw: {
        success: function (sweetAlertConfigs) {
            sweetAlertConfigs.type = 'success';
            return sweetAlert(sweetAlertConfigs);
        },

        error: function (sweetAlertConfigs) {
            sweetAlertConfigs.type = 'error';
            return sweetAlert(sweetAlertConfigs);
        },

        warning: function (sweetAlertConfigs) {
            sweetAlertConfigs.type = 'warning';
            return sweetAlert(sweetAlertConfigs);
        },

        info: function (sweetAlertConfigs) {
            sweetAlertConfigs.type = 'info';
            return sweetAlert(sweetAlertConfigs);
        },

        question: function (sweetAlertConfigs) {
            sweetAlertConfigs.type = 'question';
            return sweetAlert(sweetAlertConfigs);
        },

        default: function (sweetAlertConfigs) {
            return sweetAlert(sweetAlertConfigs);
        },
    },

    applicationError: function (configs) {
        let html, title, confirmButtonText, errorCodeText, alertText, allowOutsideClick, showConfirmButton;
        if (configs) {
            html = configs.html ? configs.html : configs.text;
            title = configs.title;
            confirmButtonText = configs.confirmButtonText;
            errorCodeText = configs.errorCode? t('generic.error.errorCodes.' + configs.errorCode) : null;
            allowOutsideClick = configs.allowOutsideClick;
            showConfirmButton = configs.showConfirmButton;
            if(html && errorCodeText) {
                alertText = html + ' (' + errorCodeText + ').';
            } else if (!html && errorCodeText) {
                alertText = errorCodeText;
            } else if (html && !errorCodeText){
                alertText = html;
            }
        }

        return this.Raw.error({
            html: alertText ? alertText : t("generic.error.description"),
            title: title ? title : t("generic.error.title"),
            confirmButtonText: confirmButtonText ? confirmButtonText : t("generic.ok"),
            allowOutsideClick: !_.isNil(allowOutsideClick) ? allowOutsideClick : true,
            showConfirmButton: !_.isNil(showConfirmButton) ? showConfirmButton : true
        });
    },

    info: function (configs) {
        let html;
        if (configs) {
            html = configs.html ? configs.html : configs.text;
        }

        return this.Raw.info({
            title: configs.title ? configs.title : t("generic.success"),
            html: html,
            allowOutsideClick: true,
            showCancelButton: false,
            confirmButtonText: configs.confirmButtonText ? configs.confirmButtonText : t("generic.ok")
        });
    },

    warning: function (configs) {
        let html;
        if (configs) {
            html = configs.html ? configs.html : configs.text;
        }

        return this.Raw.warning({
            title: configs.title ? configs.title : t("generic.success"),
            html: html,
            allowOutsideClick: true,
            showCancelButton: false,
            confirmButtonText: configs.confirmButtonText ? configs.confirmButtonText : t("generic.ok")
        });
    },

    default: function (configs) {
        let html;
        let width;
        if (configs) {
            html = configs.html ? configs.html : configs.text;
            width = configs.width ? configs.width : '32rem'
        }

        return this.Raw.default({
            title: configs.title ? configs.title : '',
            html: html,
            width: width,
            allowOutsideClick: true,
            showCancelButton: false,
            confirmButtonText: configs.confirmButtonText ? configs.confirmButtonText : t("generic.ok")
        });
    },

    timedSuccess: function (configs) {
        let html;
        if (configs) {
            html = configs.html ? configs.html : configs.text;
        }

        return this.Raw.success({
            title: configs.title ? configs.title : t("generic.success"),
            html: html,
            allowOutsideClick: true,
            showCancelButton: false,
            timer: config.alerts.successTimeoutMillis,
            confirmButtonText: configs.confirmButtonText ? configs.confirmButtonText : t("generic.ok")
        });
    },

    confirmWarningFlow: function (configs) {
        let html;
        let width;
        if (configs) {
            html = configs.html ? configs.html : configs.text;
            width = configs.width ? configs.width : '32em'
        }

        return this.Raw.warning({
            title: configs.title,
            html: html,
            allowOutsideClick: true,
            showCancelButton: true,
            focusCancel: true,
            reverseButtons: true,
            confirmButtonClass: ".base-green",
            cancelButtonText: configs.cancelButtonText ? configs.cancelButtonText : t("generic.cancel"),
            confirmButtonText: configs.confirmButtonText ? configs.confirmButtonText : t("generic.confirm"),
            width: width
        });
    },

    loading: function (title) {
        let alertTitle = !_.isNil(title) ? title : t("generic.loadingAlert");
        swal({
            title: alertTitle,
            allowOutsideClick: false
        }).catch(swal.noop);
        swal.showLoading();
    },

    successFunc: function (text) {
        Alerts.timedSuccess({
            html: text
        }).onClose(() => {}).show();
    },

    errorFunc: function (text) {
        Alerts.applicationError({
            html: text
        }).show();
    }
};

class AlertPromise {
    constructor(sweetAlertConfigs) {
        this.sweetAlertConfigs = sweetAlertConfigs ? sweetAlertConfigs : {};
    }

    onClose(onCloseCallback) {
        this.onCloseCallback = onCloseCallback;
        return this;
    }

    show(onFlowAccepted, onFlowRejected) {
        if (this.onCloseCallback) {
            this.sweetAlertConfigs.onClose = this.onCloseCallback;
        }

        onFlowAccepted = onFlowAccepted ? onFlowAccepted : () => {};
        onFlowRejected = onFlowRejected ? onFlowRejected : () => {};

        return swal(this.sweetAlertConfigs)
            .then(function(result) {
                if(_.has(result, "dismiss")){
                    onFlowRejected(result);
                }else {
                    onFlowAccepted(result);
                }
            }, function(dismiss) {
                onFlowRejected(dismiss);
            }).catch(swal.noop);
    }

}

function sweetAlert(sweetAlertConfigs) {
    return new AlertPromise(sweetAlertConfigs);
}

export default Alerts;