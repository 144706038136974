import React from "react";
import MailAction from "../../../alarms/components/eventConfiguration/actionConfiguration/MailAction";
import SmsAction from "../../../alarms/components/eventConfiguration/actionConfiguration/SmsAction";
import VoiceAction from "../../../alarms/components/eventConfiguration/actionConfiguration/VoiceAction";
import WhatsAppAction from "../../../alarms/components/eventConfiguration/actionConfiguration/WhatsAppAction";

let EventAccordion = {

    createAccordionFromEventTypes: (
        isView,
        smsService,
        voiceService,
        whatsAppService,

        actionConfigurations,

        dialCodes,
        defaultDialCode,
        farmResponsible,
        handleActionFieldChanged,
    ) => {
        return (
            <>
                <MailAction
                    {...actionConfigurations.mailAction}
                    isView={isView}
                    farmResponsible={farmResponsible}
                    handleFieldChanged={(value, key) => handleActionFieldChanged(value, key, "mailAction")}
                />
                {
                    smsService && <SmsAction
                        {...actionConfigurations.smsAction}
                        isView={isView}
                        farmResponsible={farmResponsible}
                        dialCodes={dialCodes}
                        defaultDialCode={defaultDialCode}
                        handleFieldChanged={(value, key) => handleActionFieldChanged(value, key, "smsAction")}
                    />
                }
                {
                    voiceService && <VoiceAction
                        {...actionConfigurations.voiceAction}
                        isView={isView}
                        dialCodes={dialCodes}
                        defaultDialCode={defaultDialCode}
                        farmResponsible={farmResponsible}
                        handleFieldChanged={(value, key) => handleActionFieldChanged(value, key, "voiceAction")}
                    />
                }
                {
                    whatsAppService && < WhatsAppAction
                        {...actionConfigurations.whatsAppAction}
                        isView={isView}
                        farmResponsible={farmResponsible}
                        dialCodes={dialCodes}
                        defaultDialCode={defaultDialCode}
                        handleFieldChanged={(value, key) => handleActionFieldChanged(value, key, "whatsAppAction")}
                    />
                }
            </>
        )
    }
}

export default EventAccordion;