import React, {useState} from "react";
import {Panel} from "react-bootstrap";
import ImgButton from "../../../../../../../components/buttons/ImgButton";
import arrow from "../../../../../../../img/back/back.svg";
import arrowOnHover from "../../../../../../../img/back/back_on_over.svg";
import classnames from "classnames";
import Row from "../../../../../../../components/row/Row";
import SwitchField from "../../../../../../../components/fields/switch/SwitchField";
import Multiselect from "../../../../../../../components/multiselect/MultiSelectField";
import {config} from "../../../../../../../config";
import TwoInputFieldMulti from "../../../../../../../components/fields/twoInput/TwoInputFieldMulti";
import "./style/alarm.scss";
import {useTranslation} from "react-i18next";
import {getUsersListOptionsForPhone} from "../../../../../../events/components/EventAction";
import Tooltip from "../../../../../../../components/tooltip/Tooltip";
import EuroIcon from "../../../../../../../img/currency/euro.svg";

const SmsAction = (
    {
        isView,
        active,
        notifyResponsible,
        users,
        numbers,
        handleFieldChanged,
        dialCodes,
        defaultDialCode,
        farmResponsible
    }) => {
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState(false);

    return (
        <Panel className={"action"} expanded={expanded} onToggle={()=>setExpanded(!expanded)}>
            <Panel.Heading className="action-heading">
                <Panel.Title toggle>
                    <div className="action-title">
                        <div>
                            <b>{t('events.form.sendEvent.actionSms')}</b>
                            <Tooltip content={t('events.form.sendEvent.costsSmsTooltip')} placement='top'>
                                <div className={"rct-text"}>
                                    <sup><img width={15} src={EuroIcon} className="c-black m-l-xs" alt="cost"/></sup>
                                </div>
                            </Tooltip>
                        </div>
                        <div className="action-title-btn">
                            <SwitchField
                                forceNewPropValue={true}
                                width="12"
                                isView={false}
                                className="mb-zero-absolute"
                                checkedLabel={t('generic.active')}
                                uncheckedLabel={t('generic.inactive')}
                                checkedLabelClassName="status-label"
                                uncheckedLabelClassName="status-label"
                                invert={true}
                                isChecked={active?.value}
                                disabled={isView}
                                onFieldChange={value => handleFieldChanged(value, "active")}
                            />
                            <ImgButton
                                alt="open"
                                image={arrow}
                                onHoverImage={arrowOnHover}
                                classes={classnames("arrow-direction", {"up": expanded})}
                                handleIconClick={() => setExpanded(!expanded)}
                            />
                        </div>
                    </div>
                </Panel.Title>
            </Panel.Heading>
            <Panel.Collapse>
                <Panel.Body className="action-body">
                    <div className='form-group col-xs-12'>
                        <Row className='pd-t-5'>
                            <SwitchField
                                forceNewPropValue={true}
                                fieldLabel={t('events.form.sendEvent.notifyResponsible.label')}
                                width="4"
                                isView={isView}
                                isChecked={farmResponsible.length ? notifyResponsible?.value : false}
                                disabled={!farmResponsible.some(r => r.hasValidPhone)}
                                checkedLabel={t('events.form.sendEvent.notifyResponsible.notify')}
                                uncheckedLabel={t('events.form.sendEvent.notifyResponsible.not')}
                                checkedLabelClassName="status-label"
                                uncheckedLabelClassName="status-label"
                                onFieldChange={value => handleFieldChanged(value, "notifyResponsible")}
                            />
                        </Row>
                        <Row>
                            <Multiselect
                                forceNewPropValue={true}
                                fieldLabel={t('events.form.sendEvent.users')}
                                fieldPlaceHolder={t('events.form.sendEvent.usersPlaceHolder')}
                                iconClassName='fa fa-user'
                                protectHiddenValues={users?.protectHiddenValues}
                                width='8'
                                isView={isView}
                                options={getUsersListOptionsForPhone(users?.data)}
                                useCustomFilter={true}
                                value={users?.value}
                                onFieldChange={value => handleFieldChanged(value, "users")}
                            />
                        </Row>
                        <Row>
                            <TwoInputFieldMulti
                                forceNewPropValue={true}
                                fieldLabel={t('events.form.sendEvent.phoneNumbers')}
                                dropPlaceHolder={t('users.form.phoneNumber.callingCodePlaceHolder')}
                                multiPlaceHolder={t('events.form.sendEvent.phoneNumbersPlaceHolder')}
                                isView={isView}
                                selectData={dialCodes}
                                dropValue={defaultDialCode}
                                permitCreate={true}
                                bottomMargin={true}
                                dataId={'value'}
                                dataAttribute={'label'}
                                requiredFailError={t('users.form.errors.phoneNumber.required')}
                                width='8'
                                validatorRegex={config.user.phoneregex}
                                multiValue={numbers?.value}
                                onFieldChange={value => handleFieldChanged(value, "numbers")}
                            />
                        </Row>
                    </div>
                </Panel.Body>
            </Panel.Collapse>
        </Panel>
    );
};

export default SmsAction;