import _ from "lodash";
import moment from "moment";
import DateUtils from "../../../../../utils/DateUtils";
import {config} from "../../../../../config";
import __ from "../../../../../utils/EnhancedLodash";
import EventService from "../../../../../services/EventService";
import RuleService from "../../../../../services/RuleService";
import EntityService from "../../../../../services/EntityService";
import LocalesUtils from "../../../../../utils/LocalesUtils";
import {t} from "../../../../../utils/Translation";

function groupInterfacesBySector(entity, interfaces, ruleType= 'alarms') {
    let group = _.groupBy(interfaces, _.property('entity.id'));
    let sectors = [];
    _.forOwn(group, (interfaceArray, key) => {
        interfaceArray.map(inter => {
            inter.icon = _.isObject(inter.category) ? LocalesUtils.filterLocalesOfObject({...inter.category}).icon :inter.icon
            inter.category = _.isObject(inter.category) ? LocalesUtils.filterLocalesOfObject({...inter.category}).name :inter.category
            return inter;
        });
        interfaceArray = _.sortBy(interfaceArray, ["terminal.displayName", "category", "displayName"]);
        sectors.push({
            sector: {
                ...interfaceArray[0].entity,
                ruleCounters: getSectorInterfacesCounter(interfaceArray, ruleType)
            },
            interfaces: interfaceArray
        });
    });

    sectors = _.sortBy(sectors, _.property("sector.displayName"));

    return sectors;
}

export function getSectorInterfacesCounter(interfaceArray, ruleType) {
    let active = 0;
    let pending = 0;
    let undeployed = 0;
    let error = 0;

    interfaceArray.forEach(inter => {
        if(inter?.ruleInterfaceModelView){
            active += inter.ruleInterfaceModelView[ruleType].activeRules;
            error += inter.ruleInterfaceModelView[ruleType].errorRules;
            undeployed += inter.ruleInterfaceModelView[ruleType].undeployedRules;
            pending += inter.ruleInterfaceModelView[ruleType].pendingRules;

        }
    })

    return {
        active: active,
        pending: pending,
        undeployed: undeployed,
        error: error
    }
}

function genericRuleBuilder(interfaceDetails, ruleId, operation, value, permanenceTime, repeatTime, isCloud, eventConfiguration, notificationLimit) {
    if(isCloud) {
        return cloudRule(interfaceDetails, ruleId, operation, value, permanenceTime, repeatTime, eventConfiguration, notificationLimit)
    } else {
        return onPremiseRule(interfaceDetails, ruleId, operation, value, permanenceTime, repeatTime, eventConfiguration, notificationLimit)
    }
}

function onPremiseRule(interfaceDetails, id, operation, value, permanenceTime, repeatTime,  eventConfiguration, notificationLimit) {
    const {
        entity,
        interfaceId,
        displayName,
        terminal
    } = interfaceDetails;

    const isSector = _.upperCase(entity.type.name) === EntityService.entityType.SECTOR.name

    return {
        "id": id,
        "entityId": entity.id,
        "rootEntityId": !isSector ? entity.id : entity.farmOfSector.id,
        "terminalId": terminal.id,
        "interfaceIds": [interfaceDetails.id],
        "templateCategory": "ACT_ON_CONDITION",
        "templateId": RuleService.templates.alarmTemplate,
        "displayName": t("alarmRules.alarm") + " ".concat(eventConfiguration?.title?.value ? eventConfiguration.title.value : displayName) ,
        "status": "ENABLED",
        "autoClose": eventConfiguration?.autoClose?.value ? eventConfiguration.autoClose.value : false,
        "repetitionTime": null,
        "deployRule": false,
        "inputs": [
            {
                "id": "date",
                "value": {
                    "start": moment().startOf("day").format(config.datepicker.formats.isoDate),
                    "end": null
                }
            },
            {
                "id": "time",
                "value": {
                    "start": "00:00:00",
                    "end": "23:59:59"
                }
            },
            {
                "id": "recurrence",
                "value": {
                    "fri": true,
                    "mon": true,
                    "sat": true,
                    "sun": true,
                    "thu": true,
                    "tue": true,
                    "wed": true
                }
            }, {
                "id": "device",
                "value": interfaceId
            }, {
                "id": "comparison",
                "value": _.isObject(operation) ? operation.id : operation
            }, {
                "id": "triggerLevel",
                "value": value
            }, {
                "id": "permanencePeriod",
                "value": permanenceTime.format(config.datepicker.formats.time)
            }, {
                "id": "repeatPeriod",
                "value": repeatTime.format(config.datepicker.formats.time)
            }, {
                "id": "notificationLimit",
                "value": notificationLimit
            }
        ],
        "eventConfiguration": !__.isNilOrEmpty(eventConfiguration) ? genericEventConfigurationBuilder(eventConfiguration) : undefined
    };
}

function cloudRule(interfaceDetails, ruleId, operation, value, permanenceTime, repeatTime, eventConfiguration, notificationLimit) {
    const {
        id,
        entity,
        displayName,
        terminal
    } = interfaceDetails;

    const isSector = _.upperCase(entity.type.name) === EntityService.entityType.SECTOR.name

    return {
        "id": ruleId,
        "entityId": entity.id,
        "rootEntityId": !isSector ? entity.id : entity.farmOfSector.id,
        "templateId": RuleService.templates.cloudAlarm,
        "interfaceIds": [id],
        "displayName": "Clould " + t("alarmRules.alarm") + " ".concat(eventConfiguration?.title?.value ? eventConfiguration.title.value : displayName),
        "status": "ENABLED",
        "autoClose": true,
        "repetitionTime": null,
        "deployRule": false,
        "inputs": [
            {
                "id": "date",
                "value": {
                    "start": "2020-11-03",
                    "end": null
                }
            },
            {
                "id": "time",
                "value": {
                    "start": "00:00:00",
                    "end": "23:59:59"
                }
            },
            {
                "id": "recurrence",
                "value": {
                    "sun": true,
                    "mon": true,
                    "tue": true,
                    "wed": true,
                    "thu": true,
                    "fri": true,
                    "sat": true
                }
            },
            {
                "id": "dropgroup-input",
                "value": [
                    {
                        "interfaceId": id,
                        "terminalId": terminal.id,
                        "logicOperator": RuleService.parseRuleOperationPremiseToCloud(_.isObject(operation) ? operation.id : operation),
                        "inputValue": value,
                        "repetitionTime": repeatTime && moment(repeatTime).isValid() ? repeatTime.format(config.datepicker.formats.timeWithoutSeconds) : repeatTime,
                        "permanenceTime": permanenceTime && moment(permanenceTime).isValid() ? permanenceTime.format(config.datepicker.formats.timeWithoutSeconds) : permanenceTime,
                        "notificationLimit": notificationLimit,
                    }
                ]
            }
        ],
        "eventConfiguration": !__.isNilOrEmpty(eventConfiguration) ? genericEventConfigurationBuilder(eventConfiguration, DateUtils.timeToSeconds(permanenceTime)) : undefined
    }
}

function genericEventConfigurationBuilder({title, description, severity, category, status, hasRepetitionTime, repetitionTime, actionConfigurations = {}}, ruleDelay = '0') {
    const {
        mailAction,
        voiceAction,
        smsAction,
        whatsAppAction
    } = actionConfigurations;

    if(__.isNilOrEmpty(title.value)) {
        return undefined;
    }

    return {
        "title": title.value,
        "description": description.value,
        "severityLevel": severity.value,
        "category": _.isObject(category.value) ? category.value.id : category.value,
        "ruleDelay": ruleDelay,
        "hasRepetitionTime": {
            "id": "hasRepetitionTime",
            "value": "DISABLED"
        },
        repetitionTime: hasRepetitionTime ? repetitionTime.value : null,
        "status": _.isObject(status.value) ? status.value.id : status.value,
        "actionConfigurations": [
            {
                "type": "MAIL",
                "status": mailAction.active.value,
                "properties": {
                    "subject": "dasdas",
                    "usersList": mailAction.users?.value ? mailAction.users.value.map(e => _.isObject(e) ? e.value : e) : [],
                    "contactsList": mailAction.emails?.value ? mailAction.emails.value.map(e => _.isObject(e) ? e.value : e) : [],
                    "body": description.value
                },
                "notifyResponsible": mailAction.notifyResponsible.value,
                "recurrence": DateUtils.timeToSeconds(mailAction.recurrence.value)
            },
            {
                "type": "SMS",
                "status": smsAction.active.value,
                "properties": {
                    "usersList": smsAction.users?.value ? smsAction.users.value.map(e => _.isObject(e) ? e.value : e) : [],
                    "contactsList": smsAction.numbers?.value ? smsAction.numbers.value.map(e => _.isObject(e) ? e.value.replace(/\s/g, '') : e) : [],
                    "body": description.value
                },
                "notifyResponsible": smsAction.notifyResponsible.value,
                "recurrence": DateUtils.timeToSeconds(smsAction.recurrence.value)
            },
            {
                "type": "VOICE",
                "status": voiceAction.active.value,
                "properties": {
                    "usersList": voiceAction.users?.value ? voiceAction.users.value.map(e => _.isObject(e) ? e.value : e) : [],
                    "contactsList": voiceAction.numbers?.value ? voiceAction.numbers.value.map(e => _.isObject(e) ? e.value.replace(/\s/g, '') : e) : [],
                    "body": description.value
                },
                "notifyResponsible": voiceAction.notifyResponsible.value,
                "recurrence": 0
            },
            {
                "type": "WHATSAPP",
                "status": whatsAppAction.active.value,
                "properties": {
                    "usersList": whatsAppAction.users?.value ? whatsAppAction.users.value.map(e => _.isObject(e) ? e.value : e) : [],
                    "contactsList": whatsAppAction.numbers?.value ? whatsAppAction.numbers.value.map(e => _.isObject(e) ? e.value.replace(/\s/g, '') : e) : [],
                    "body": whatsAppAction.value
                },
                "notifyResponsible": whatsAppAction.notifyResponsible.value,
                "recurrence": DateUtils.timeToSeconds(whatsAppAction.recurrence.value)
            }
        ]
    }
}

function setFormData(rule) {
    const {id, eventConfiguration={}, deploymentStatus, displayName, inputs, ruleTemplate, status} = rule;
    const inputsParsed = JSON.parse(inputs);
    const isCloud = ruleTemplate.id === RuleService.templates.cloudAlarm;

    const remoteMailConfig = eventConfiguration?.actionConfigurations ? _.find(eventConfiguration.actionConfigurations, { 'type': "MAIL"}) : {};
    const remoteVoiceConfig = eventConfiguration?.actionConfigurations ? _.find(eventConfiguration.actionConfigurations, { 'type': "VOICE"}) : {};
    const remoteSmsConfig = eventConfiguration?.actionConfigurations ? _.find(eventConfiguration.actionConfigurations, { 'type': "SMS"}) : {};

    const operation = !isCloud ? _.find(inputsParsed, { 'id': "comparison"}).value : RuleService.parseRuleOperationCloudToOnPremise(_.find(inputsParsed, { 'id': "dropgroup-input"}).value[0].logicOperator);
    const value = !isCloud ? _.find(inputsParsed, { 'id': "triggerLevel"}).value : _.find(inputsParsed, { 'id': "dropgroup-input"}).value[0].inputValue;
    const repeatTime = !isCloud ? moment(_.find(inputsParsed, { 'id': "repeatPeriod"}).value, config.datepicker.formats.time) : moment(RuleService.parseRuleOperationCloudToOnPremise(_.find(inputsParsed, { 'id': "dropgroup-input"}).value[0].repetitionTime), config.datepicker.formats.timeWithoutSeconds);
    const permanenceTime = !isCloud ? moment(_.find(inputsParsed, { 'id': "permanencePeriod"}).value, config.datepicker.formats.time) : moment(RuleService.parseRuleOperationCloudToOnPremise(_.find(inputsParsed, { 'id': "dropgroup-input"}).value[0].permanenceTime), config.datepicker.formats.timeWithoutSeconds);
    const notificationLimit = !isCloud ? (!_.isNull(_.find(inputsParsed, { 'id': "notificationLimit"})) && _.find(inputsParsed, { 'id': "notificationLimit"}) !== undefined ? _.find(inputsParsed, { 'id': "notificationLimit"}).value : 3) : (!_.isNull(_.find(inputsParsed, { 'id': "dropgroup-input"})) && _.find(inputsParsed, { 'id': "dropgroup-input"}) !== undefined ? _.find(inputsParsed, { 'id': "dropgroup-input"})?.value[0]?.notificationLimit : 3);

    return {
        id: id,
        displayName: displayName,
        updated: false,
        deploymentStatus: deploymentStatus,
        allowDelete: isCloud || RuleService.deploymentStatus.UNDEPLOYED === deploymentStatus,
        allowUndeploy: (status === 'ENABLED' && isCloud) || RuleService.deploymentStatus.DEPLOYED === deploymentStatus,
        status: status,
        operation: {
            value: operation
        },
        value: {
            value: value
        },
        permanenceTime: {
            value: permanenceTime
        },
        repeatTime: {
            value: repeatTime
        },
        notificationLimit: {
            value: notificationLimit
        },
        cloud: {
            value: isCloud
        },
        eventConfiguration: {
            value: {
                title: {value: eventConfiguration.title},
                description: {value: eventConfiguration.description},
                severity: {
                    data: EventService.getSeverityList(),
                    value: eventConfiguration.severityLevel
                },
                category: {
                    value:  eventConfiguration?.category?.value
                },
                ruleDelay: {value: eventConfiguration?.ruleDelay},
                status: {
                    data: EventService.getStatusList(),
                    value: eventConfiguration.status
                },
                autoClose: {value: eventConfiguration?.autoClose},
                actionConfigurations: {
                    mailAction: {
                        active: {value: remoteMailConfig?.status},
                        notifyResponsible: {value: remoteMailConfig?.notifyResponsible},
                        users: {value: remoteMailConfig?.properties?.usersList},
                        emails: {
                            options: remoteMailConfig?.properties?.contactsList.map(u => {
                                return {value: u, label: u, className: "Select-create-option-placeholder"}
                            }),
                            value: remoteMailConfig?.properties?.contactsList.map(u => {
                                return {value: u, label: u, className: "Select-create-option-placeholder"}
                            })
                        },
                        recurrence: {value: DateUtils.secondsToTime(remoteMailConfig.recurrence)}
                    },
                    smsAction: {
                        active: {value: remoteSmsConfig?.status},
                        notifyResponsible: {value: remoteSmsConfig?.notifyResponsible},
                        users: {value: remoteSmsConfig?.properties?.usersList},
                        numbers: {
                            value: remoteSmsConfig?.properties?.contactsList.map(u => {
                                return {value: u, label: u, className: "Select-create-option-placeholder"}
                            })
                        },
                        recurrence: {value: DateUtils.secondsToTime(remoteSmsConfig?.recurrence)}
                    },
                    voiceAction: {
                        active: {value: !__.isNilOrEmpty(remoteVoiceConfig) ? remoteVoiceConfig.status : false},
                        notifyResponsible: {value: !__.isNilOrEmpty(remoteVoiceConfig) ? remoteVoiceConfig.notifyResponsible : false},
                        users: {value: remoteVoiceConfig?.properties?.usersList},
                        numbers: {
                            value: remoteVoiceConfig?.properties?.contactsList.map(u => {
                                return {value: u, label: u, className: "Select-create-option-placeholder"}
                            })
                        },
                    }
                }
            }
        },
        audit: {
            createdBy: rule.createdBy,
            creationTimestampUtc: rule.creationTimestampUtc,
            lastUpdatedBy: rule.lastUpdatedBy,
            lastUpdateTimestampUtc: rule.lastUpdateTimestampUtc
        }
    }
}

export {
    groupInterfacesBySector,
    genericRuleBuilder,
    setFormData,
    genericEventConfigurationBuilder
}