import React from 'react';
import PropTypes from 'prop-types';
import {t} from "../../../utils/Translation";
import _ from "lodash";
import Row from "../../row/Row";
import LabeledField from "../labeled/LabeledField";
import moment from 'moment';
import {config} from "../../../config";
import ImgButton from "../../buttons/ImgButton";
import trash from "../../../img/trash/trash.svg";
import trashOnHover from "../../../img/trash/trash_on_over.svg";
import CycleInputContent from "./InputContent";

class CycleItem extends React.Component {
    constructor(props) {
        super(props);

        this.cycleScheduleFieldRefs = {};
        this.inputRefs = {};

        this.state = {
            title: this.props.title ? this.props.title : '',
            day: this.props.day ? this.props.day : '',
            startDate: this.props.startDate ? this.props.startDate : '',
            startTime: this.props.startTime ? this.props.startTime : '',
            referenceDate: this.props.referenceDate ? this.props.referenceDate : '',
            inputData: this.props.inputData ? this.props.inputData : {},
            isMobile: this.props.isMobile ? this.props.isMobile : false,
        };
    }

    componentWillReceiveProps(nextProps) {
        let self = this;
        this.setState({
            title: nextProps.title? nextProps.title : this.state.title,
            day: nextProps.day? nextProps.day : this.state.day,
            startDate: nextProps.startDate? nextProps.startDate : this.state.startDate,
            startTime: nextProps.startTime? nextProps.startTime : this.state.startTime,
            referenceDate: nextProps.referenceDate? nextProps.referenceDate : this.state.referenceDate,
            inputData: nextProps.inputData? nextProps.inputData : this.state.inputData,
            isMobile: nextProps.isMobile ? nextProps.isMobile :  this.state.isMobile
        }, () => {
            self.cycleScheduleFieldRefs.day.setFieldValue(nextProps.day? nextProps.day : self.state.day);
            self.cycleScheduleFieldRefs.startDate.setFieldValue(nextProps.startDate? nextProps.startDate : self.state.startDate);
            self.cycleScheduleFieldRefs.startTime.setFieldValue(nextProps.startTime? nextProps.startTime : self.state.startTime);
            setInputData(self, nextProps.inputData);
        })
    }

    validateCycle() {
        let isValid = true;
        _.forOwn(this.cycleScheduleFieldRefs, field => {
            if (!field.validateField()) {
                isValid = false;
            }
        });
        _.forOwn(this.inputRefs, field => {
            if (field && !field.validateField()) {
                isValid = false;
            }
        });
        return isValid;
    }


    showDeleteButton(props) {
        return props.id !== 'dayOneCycle' && props.id !== 'preCycle' && props.id !== 'lastDayCycle' && !props.viewMode
    }

    render() {
        let self = this;
        let dateLabel, datePlaceholder, timeLabel, dateValidator;
        if (this.props.id === 'lastDayCycle') {
            dateLabel = t('generic.cyclegroup.fields.endDate.label');
            datePlaceholder = t('generic.cyclegroup.fields.endDate.placeholder');
            timeLabel = t('generic.cyclegroup.fields.endTime.label');
        } else {
            dateLabel = t('generic.cyclegroup.fields.startDate.label');
            datePlaceholder = t('generic.cyclegroup.fields.startDate.placeholder');
            timeLabel = t('generic.cyclegroup.fields.startTime.label');
            if (this.props.id === 'preCycle') {
                dateValidator = getValidatorForPreCycle(self);
            } else if (this.props.id === 'dayOneCycle'){
                dateValidator = getValidatorForDayOneCycle(self);
            }
        }
        
        const {isMobile} = this.state;

        return (
            <div>
                <h3>{t('generic.cyclegroup.headers.schedule')}</h3>
                <Row className={`pd-t-15 col-lg-12 col-md-12 col-sm-12 col-xs-12 ${!isMobile ? 'flex-row-center' : ''}`} style={ !isMobile ? {height: '65px'} : {} }>
                    <LabeledField
                        fieldLabel={t('generic.cyclegroup.fields.day.label')}
                        fieldPlaceHolder={t('generic.cyclegroup.fields.day.placeholder')}
                        fieldType='text'
                        width='3'
                        labelWidth='150px'
                        value={self.state.day}
                        isView={self.props.disabledDay || self.props.viewMode}
                        isRequired={true}
                        hasRequiredAsterisk={false}
                        onBlur={value => self.props.onDayBlur(value)}
                        validator={params => validateDay(params)}
                        ref={c => {self.cycleScheduleFieldRefs.day = c}}
                    />
                    <LabeledField
                        fieldLabel={dateLabel}
                        fieldPlaceHolder={datePlaceholder}
                        fieldType='dateTime'
                        hasTime={false}
                        hasDate={true}
                        width='3'
                        labelWidth='150px'
                        value={self.state.startDate}
                        isView={self.props.disabledStartDate || self.props.viewMode}
                        dateValidator={value => dateValidator(value)}
                        keepDatetimeValuesInUTC={true}
                        ignoreForceRightClearField={true}
                        onFieldChange={value => self.props.onDateChanged(value)}
                        ref={c => {self.cycleScheduleFieldRefs.startDate = c}}
                    />
                    <LabeledField
                        fieldLabel={timeLabel}
                        fieldType='dateTime'
                        hasDate={false}
                        hasTime={true}
                        width='3'
                        value={self.state.startTime}
                        labelWidth='150px'
                        isView={self.props.viewMode}
                        keepDatetimeValuesInUTC={true}
                        ignoreForceRightClearField={true}
                        onFieldChange={value => self.props.onTimeChanged(value)}
                        ref={c => {self.cycleScheduleFieldRefs.startTime = c}}
                    />
                    {self.showDeleteButton(self.props) && (
                        <div className={`col-lg-3 ${!isMobile ? 'user-form-buttons' : ''}`}>
                            <ImgButton
                                alt="delete"
                                disabled={false}
                                image={trash}
                                onHoverImage={trashOnHover}
                                handleIconClick={(e) => {
                                    e.stopPropagation();
                                    deleteCycleEntry(self.props)
                                }}
                                tooltip={t("generic.delete")}
                            />
                        </div>
                    )}
                </Row>
                {buildInputArea(self)}
            </div>
        );
    }
}

function getValidatorForPreCycle(component) {
    return value => {
        if (component.props.cycleGroup.cycleRefs['dayOneCycle']) {
            return _.lte(value, component.props.cycleGroup.cycleRefs['dayOneCycle'].state.startDate);
        } else {
            return true;
        }
    }
}

function getValidatorForDayOneCycle(component) {
    return value => {
        if (component.props.cycleGroup.cycleRefs['preCycle']) {
            return _.gte(value, component.props.cycleGroup.cycleRefs['preCycle'].state.startDate.clone().subtract(1,'d'));
        } else {
            return true;
        }
    };
}

function validateDay(params) {
    let errorMessage = '';
    if(!_.isEmpty(params)) {
        let newDay = params[0];
        let refDay = params[1];
        let ref = params[2];

        let regex = config.generic.positiveornegativenumberregex;

        if(!regex.test(newDay)) {
            errorMessage = t('errors.generic.notANumber');
        }
        if(_.isEmpty(errorMessage)){
            if(ref === 'lastDayCycle' && newDay < refDay){
                errorMessage = t('errors.generic.notGreaterThan', {min: refDay-1});
            } else if (ref !== 'lastDayCycle' && newDay >= refDay) {
                errorMessage = t('errors.generic.notLessThan', {max: refDay});
            }
        }
    }
    return errorMessage;
}

function deleteCycleEntry(component) {
    component.onDelete()
}

function buildInputArea(component) {
    if(!_.isNil(component.props.inputs)) {
        return (
            <div className="row pd-t-15 col-lg-12 col-md-12 col-sm-12 col-xs-12">
                <div className="hr-line-dashed"/>
                <h3>{t('generic.cyclegroup.headers.parameters')}</h3>
                {buildInputs(component)}
            </div>
        );
    }
}

function buildInputs(component) {
    const {
        inputs,
        viewMode,
        editMode
    } = component.props;

    if(_.isEmpty(inputs)){
        return t('generic.cyclegroup.emptyInputs');
    } else {
        return (
            <CycleInputContent
                inputs={inputs}
                parentForm={component}
                viewMode={viewMode}
                editMode={editMode}
            />
        );
    }
}

function setInputData(component, inputData){
    let i = 0;
    _.forOwn(component.inputRefs, inputRef => {
        if(inputRef) {
            inputRef.setFieldValue(inputData? inputData[i]? inputData[i].value : inputRef.getFieldValue() : inputRef.getFieldValue());
        }
        i++;
    });
}

CycleItem.propTypes = {
    title: PropTypes.string.isRequired,
    day: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    startDate: PropTypes.instanceOf(moment),
    startTime: PropTypes.instanceOf(moment),
    disabledDay: PropTypes.bool,
    disabledStartDate: PropTypes.bool,
    onDateChanged: PropTypes.func,
    onTimeChanged: PropTypes.func,
    onDelete: PropTypes.func,
    referenceDate: PropTypes.instanceOf(moment),
    onDayBlur: PropTypes.func,
    isMobile: PropTypes.bool
};

export default CycleItem;