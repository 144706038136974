let DownloadService = {
   downloadFile: function (content) {
        let fileName = content.headers['content-disposition'].match(/filename="(.+)"/)[1];
        let mime = content.headers['content-type'];
        let blob = new Blob([content.data], {type: mime || 'application/octet-stream'});

        //IE11 workaround
        if (typeof window.navigator.msSaveBlob !== 'undefined') {
            window.navigator.msSaveBlob(blob, fileName);
        }
        else {
            let blobURL = window.URL.createObjectURL(blob);
            let tempLink = document.createElement('a');
            tempLink.href = blobURL;
            tempLink.setAttribute('download', fileName);
            tempLink.style.display = 'none';
            document.body.appendChild(tempLink);
            tempLink.click();
            document.body.removeChild(tempLink);
            if(!fileName.endsWith('.pdf')) {
                window.URL.revokeObjectURL(blobURL);
            } else {
                window.open(blobURL,'_blank');
            }

        }
    },

    downloadString: function (filename, text) {
        let element = document.createElement('a');
        element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
        element.setAttribute('download', filename);
        element.style.display = 'none';
        document.body.appendChild(element);
        element.click();
        document.body.removeChild(element);
    }
};
export default DownloadService;