import React, {useEffect, useMemo, useState} from "react";
import EventService from "../../../services/EventService";
import Alerts from "../../../utils/AlertUtils";
import BootstrapTable from "../../../components/tables/BootstrapTable";
import Row from "../../../components/row/Row";
import {getEventOccurrenceListColumns} from "../configs";
import {t} from "../../../utils/Translation";
import Divider from "../../../components/divider/Divider";
import {TableListViewProvider} from "../../../context/TableListViewContext";

const EventOccurrences = ({eventId, isAutomatic}) => {

    const [loading, setLoading] = useState(true);

    const defaultPageable = useMemo(() => {return {page: 0, sizePerPage: 5, sortField: 'occurrenceTimestampUtc', sortOrder: 'desc'}}, []);
    const [searchResult, setSearchResult] = useState({pageable: defaultPageable});
    const columnDefinitions = useMemo(getEventOccurrenceListColumns, []);

    useEffect(() => {
        fetchOccurrences();
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        if(loading){
            fetchOccurrences();
        }
        // eslint-disable-next-line
    }, [loading])

    const fetchOccurrences = () => {
        EventService.getEventOccurrences(eventId, {currentPage: searchResult.pageable.page,
            itemsPerPage: searchResult.pageable.sizePerPage,
            sortProperty: searchResult.pageable.sortField,
            sortDirection: searchResult.pageable.sortOrder?.toUpperCase()
        }, occurrences => {
            setSearchResult({
                pageable: {
                    sortOrder: occurrences.sortDirection,
                    sortField: occurrences.sortProperty,
                    sizePerPage: occurrences.size,
                    page: occurrences.number
                },
                content: occurrences.content,
                totalSize: occurrences.totalElements,
            })
            setLoading(false);
        }, () => Alerts.applicationError().show());
    }

    const handlerPagination = (page, sizePerPage, sortField, sortOrder) => {
        setSearchResult(prevState => {
            return {
                ...prevState,
                pageable: {
                    page: page,
                    sizePerPage: sizePerPage,
                    sortField: sortField || 'occurrenceTimestampUtc',
                    sortOrder: sortOrder || 'desc'
                }
            }
        });
        setLoading(true);
    }

    if(searchResult?.totalSize <= 0 || !isAutomatic){
        return <></>;
    }

    return (
        <TableListViewProvider>
            <Row className='event-list-searchbox-row'>

                <h3 className='f-bold'>{t('events.form.notificationList.title')}</h3>
                <BootstrapTable
                    loading={loading}
                    columns={columnDefinitions}
                    data={searchResult}
                    handlerPagination={handlerPagination}
                />
            </Row>
            <Divider />
        </TableListViewProvider>
        )
}

export default EventOccurrences;