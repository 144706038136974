import { call, put, takeEvery } from 'redux-saga/effects';
import * as chartActionTypes from './actionTypes';
import * as actionTypes from './../../actionTypes';
import _ from "lodash";
import moment from "moment";
import { config } from "../../../../config";
import AsyncEntityService from "../../../../services/async/AsyncEntityService";
import GenericUtils from "../../../../utils/GenericUtils";

const getMapData = (widgetId, eventsSearch) => AsyncEntityService.getEventsByEntities(eventsSearch);

function* fetchMapData({id, configs}) {
    try {
        const {
            farmSelector,
            lastEvents,
            statusOpen,
            statusPending,
            statusClosed
        } = configs;

        let eventStatus = [];
        if(GenericUtils.stringToBoolean(statusOpen)) {
            eventStatus.push("OPEN");
        }
        if(GenericUtils.stringToBoolean(statusPending)) {
            eventStatus.push("PENDING");
        }
        if(GenericUtils.stringToBoolean(statusClosed)) {
            eventStatus.push("CLOSED");
        }

        let search = getSearchEventsRequest(farmSelector, lastEvents);
        search.eventStatus = eventStatus;

        const data = yield call(getMapData, id, search);
        yield put({
            type: actionTypes.LOAD_WIDGET_DATA_SUCCESS,
            id: id,
            data: data
        })
    } catch (e) {
        yield put({
            id: id,
            type: actionTypes.LOAD_WIDGET_DATA_FAILED
        });
    }
}

const mapSagas = [
    takeEvery(chartActionTypes.LOAD_MAP_WIDGET_DATA_REQUEST, fetchMapData)
];

function getSearchEventsRequest(farmSelector, lastEvents) {
    farmSelector = farmSelector ? JSON.parse(farmSelector) : [];
    let eventsSearch = {entityList: farmSelector};

    const _lastEvents = _.isObject(lastEvents) ? lastEvents.id : lastEvents;

    if (!_.isNil(_lastEvents)) {
        switch (_lastEvents) {
            case 'ONE':
                eventsSearch.fromDate = moment.utc().subtract(1, 'hour').format(config.datepicker.formats.isoDateTime);
                break;
            case 'SIX':
                eventsSearch.fromDate = moment.utc().subtract(6, 'hour').format(config.datepicker.formats.isoDateTime);
                break;
            case 'TWELVE':
                eventsSearch.fromDate = moment.utc().subtract(12, 'hour').format(config.datepicker.formats.isoDateTime);
                break;
            case 'TWENTYFOUR':
                eventsSearch.fromDate = moment.utc().subtract(1, 'day').format(config.datepicker.formats.isoDateTime);
                break;
            default:
                eventsSearch.fromDate = moment.utc().subtract(1, 'day').format(config.datepicker.formats.isoDateTime);
        }
    } else {
        eventsSearch.fromDate = moment.utc().subtract(1, 'day').format(config.datepicker.formats.isoDateTime);
    }
    return eventsSearch;
}

export { mapSagas };