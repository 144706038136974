import axios from "axios";
import _ from "lodash";
import UserSessionService from "../UserSessionService";
import AsyncInterfaceService from "./AsyncInterfaceService";
import EntityService from "../EntityService";

const AsyncEventService = {
    getAllEntities: function (searchData) {
        return new Promise((accept, reject) => {
            axios.get('/entities', {
                params: searchData
            }).then(response => {
                accept(response.data);
            }, reject);
        });
    },

    getAllTags: function (searchData) {
        return new Promise((accept, reject) => {
            axios.get('/entities/tags', {
                params: searchData
            }).then(response => {
                accept(response.data);
            }, reject);
        });
    },

    getChildrenEntityTags: function (searchData) {
        return new Promise((accept, reject) => {
            axios.get('/entities/children/tags', {
                params: searchData
            }).then(response => {
                accept(response.data);
            }, reject);
        });
    },

    countRules: function (searchData, onSuccess, onError) {
        return new Promise((accept, reject) => {
        axios.get('/rules/count', {
            params: searchData
        }).then(response => {
            accept(response.data);
        }, reject);
    });
    },

    countEvents: function (searchData) {
       return new Promise ((accept,reject) => {
           axios.get('/events/count', {
               params: searchData
           }).then(response => {
               accept(response.data);
           }, reject);
       });
    },

    getEntityDetails: function (entityId) {
        return new Promise((accept, reject) => {
            axios.get('/entities/' + entityId).then(response => {
                accept(response.data);
            }, reject);
        });
    },

    getEntitiesDetails: function (entitiesId) {
        return new Promise((accept, reject) => {
            axios.get('/entities/details?entityId=' + entitiesId).then(response => {
                accept(response.data);
            }, reject);
        });
    },

    getSubEntities: function (entityId, searchData) {
        return new Promise((accept, reject) => {
            axios.get('/entities/' + entityId + '/subentities', {
                params: searchData
            }).then(response => {
                accept(response.data);
            }, reject);
        });
    },

    getSubEntitiesMultiEntities: function (searchData) {
        return new Promise((accept, reject) => {
            axios.get('/entities/subentities', {
                params: searchData
            }).then(response => {
                accept(response.data);
            }, reject);
        });
    },

    getAllSubEntities: function (entityId, searchData) {
        return new Promise((accept, reject) => {
            axios.get('/entities/' + entityId + '/all-subentities', {
                params: searchData
            }).then(response => {
                accept(response.data);
            }, reject);
        });
    },

    getParentEntities: function (entityId, searchData) {
        return new Promise((accept, reject) => {
            axios.get('/entities/' + entityId + '/parent', {
                params: searchData
            }).then(response => {
                accept(response.data);
            }, reject);
        });
    },

    getEntityParent: function (entityId) {
        return new Promise((accept, reject) => {
            axios.get('/entities/' + entityId + '/parentId'
            ).then(response => {
                accept(response.data);
            }, reject);
        });
    },

    getAllEntityTypes: function () {
        return new Promise((accept, reject) => {
            axios.get('/entities/entityTypes').then(response => {
                accept(response.data);
            }, error => {
                reject(error);
            });
        });
    },

    getAllEntityRoles: function (entityId) {
        return new Promise((accept, reject) => {
            axios.get('/entities/' + entityId + '/roles').then(response => {
                accept(response.data);
            }, reject);
        });
    },

    getRolesFromEntities: function (entities) {
        return new Promise((accept, reject) => {
            axios.get('/entities/roles/list', {
                params: entities
            }).then(response => {
                accept(response.data);
            }, reject);
        });
    },

    getAllTerminals: function (entityId, otherFilters) {
        return new Promise((accept, reject) => {
            axios.get('/entities/' + entityId + '/terminals/list', {
                params: otherFilters
            }).then(response => {
                _.forEach(response.data, terminal => {
                    terminal.name = terminal.displayName;
                });

                accept(response.data);
            }, reject);
        });
    },

    getAllTerminalInterfaces: function (entityId, terminalId, otherFilters) {
        return new Promise((accept, reject) => {
            axios.get('/entities/' + entityId + '/terminals/' + terminalId + '/interfaces', {
                params: otherFilters
            }).then(response => {
                _.forEach(response.data, interfaceElement => {
                    interfaceElement.name = interfaceElement.displayName;
                });

                accept(response.data);
            }, reject);
        });
    },

    getAllTerminalInterfacesFromTerminals: function (terminals) {
        return new Promise((accept, reject) => {
            axios.get('/interfaces/list', {
                params: terminals
            }).then(response => {
                _.forEach(response.data, interfaceElement => {
                    interfaceElement.name = interfaceElement.displayName;
                });

                accept(response.data);
            }, reject);
        });
    },

    getAllSectors: function (entityId) {
        return new Promise((accept, reject) => {
            axios.get('/entities/' + entityId + '/sectors/list').then(response => {
                accept(response.data);
            }, reject);
        });
    },

    getTerminalsByEntities: function (entities) {
        return new Promise((accept, reject) => {
            axios.get('/terminals', {
                params: entities
            }).then(response => {
                response.data.forEach(terminal => {
                    terminal.name = terminal.displayName;
                });
                accept(response.data);
            }, reject);
        });
    },

    getSectorsByEntities: function (entities) {
        return new Promise((accept, reject) => {
            axios.get('/entities/sectors/list', {
                params: entities
            }).then(response => {
                accept(response.data);
            }, reject);
        });
    },

    getInterfaces: function (searchData) {
        return new Promise((accept, reject) => {
            axios.get('/entities/interfaces', {
                params: searchData
            }).then((response) => {
                accept(response.data);
            }, reject);
        });
    },

    getEventsByEntities: function (searchData) {
        return new Promise((accept, reject) => {
            axios.get('/entities/map', {
                params: searchData
            }).then(response => {
                accept(response.data);
            }, reject);
        });
    },

    getEntityDefaultInterfaces: function (entityId) {
        return new Promise((accept, reject) => {
            axios.get('/entities/' + entityId + '/defaultInterfaces').then(response => {
                accept(response.data);
            }, reject);
        });
    },

    getEntityDefaultInterfacesWithCategories: function (entityId, categories) {
        return new Promise((accept, reject) => {
            axios.get('/entities/' + entityId + '/defaultInterfaces', {
                params: categories
            }).then(response => {
                accept(response.data);
            }, reject);
        });
    },

    getEntityInterfaces: function (entityId) {
        return new Promise((accept, reject) => {
            axios.get('/entities/' + entityId + '/interfaces').then(response => {
                accept(response.data);
            }, reject);
        });
    },

    getInterfaceCategories: function () {
        return new Promise((accept, reject) => {
            let result = {
                defaultCategories: UserSessionService.getDefaultInterfaceCategories(),
                allCategories: UserSessionService.getAllInterfaceCategories()
            };

            if (_.isNil(result.defaultCategories) || _.isNil(result.allCategories)) {
                AsyncInterfaceService.getInterfaceDefaultCategories().then(defaultCategories => {
                    defaultCategories = EntityService.getCategoryListWithValueAndName(defaultCategories);
                    UserSessionService.storeDefaultInterfaceCategories(defaultCategories);
                    AsyncInterfaceService.getAllCategoryTypes().then(allCategories => {
                        allCategories = EntityService.getCategoryListWithValueAndName(allCategories);
                        UserSessionService.storeAllInterfaceCategories(allCategories);
                        result.defaultCategories = defaultCategories;
                        result.allCategories =  allCategories;
                        accept(result)
                    })
                }, reject);
            } else {
                accept(result);
            }
        });
    },
};

export default AsyncEventService;