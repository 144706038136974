import React from "react";
import {t} from "../../../utils/Translation";
import Row from "../../../components/row/Row";
import Multiselect from '../../../components/multiselect/MultiSelectField';
import AccordionPanel from '../../../components/panels/accordion/AccordionPanel';
import EventService from "../../../services/EventService";
import _ from 'lodash';
import {config} from "../../../config";
import TwoInputFieldMulti from "../../../components/fields/twoInput/TwoInputFieldMulti";
import DialCodesUtils from "../../../utils/DialCodesUtils";
import RolesUtils from "../../../utils/RolesUtils";
import {permissions} from "../../../utils/permissions";
import RuleCacheService from "../../../services/RuleCacheService";
import SwitchField from "../../../components/fields/switch/SwitchField";
import Tooltip from "../../../components/tooltip/Tooltip";
import blockedIcon from "../../../img/blocked.svg";

let EventAction ={

    createAccordionFromEventTypes: function (eventTypes, component, isView, hasCustomKeys, helpdesk) {
        let accordionProperties = [];
        let accordionContents = [];
        let accordionKeys = [];
        let customRefs = 'fieldRefs';

        eventTypes.forEach(eventType => {
            switch (eventType) {
                case EventService.eventTypes.mail:
                    accordionProperties.push({
                        title: t('events.form.sendEvent.actionEmail'),
                        hasCost: false,
                        enabled: true
                    });
                    accordionContents.push(getEmailTab(component, isView, customRefs, helpdesk));
                    break;
                case EventService.eventTypes.sms:
                    accordionProperties.push({
                        title: t('events.form.sendEvent.actionSms'),
                        hasCost: true,
                        enabled: false
                    })
                    accordionContents.push(getSmsTab(component, isView, customRefs, helpdesk));
                    break;
                case EventService.eventTypes.voice:
                    accordionProperties.push({
                        title: t('events.form.sendEvent.actionVoice'),
                        hasCost: true,
                        enabled: false
                    });
                    accordionContents.push(getVoiceTab(component, isView, customRefs, helpdesk));
                    break;
                case EventService.eventTypes.whatsapp:
                    accordionProperties.push({
                        title: t('events.form.sendEvent.actionWhatsApp'),
                        hasCost: false,
                        enabled: false
                    });
                    accordionContents.push(getWhatsappTab(component, isView, customRefs, helpdesk));
                    break;
                default:
                    throw new Error("Unknown eventType: " + eventType);
            }
            if(hasCustomKeys) {
                accordionKeys.push(eventType);
            }
        });
        return (
            <AccordionPanel
                accordionKeys={accordionKeys}
                accordionProperties={accordionProperties}
                accordionPanels={accordionContents}
                hasSwitchArea={!isView}
                hasSwitchAreaDisable={helpdesk}
                ref={(c) => component[customRefs].eventAccordion = c}
            />
        );
    }
}

function getEmailTab(component, isView, customRef, helpdesk) {
    const {protectHiddenValues} = getGenericInformationData();

    return (
        <div className='form-group col-xs-12 p-l-r-0'>
            <Row className='pd-t-5'>
                <SwitchField
                    fieldLabel={t('events.form.sendEvent.notifyResponsible.label')}
                    width="4"
                    isView={isView}
                    checkedLabel={t('events.form.sendEvent.notifyResponsible.notify')}
                    uncheckedLabel={t('events.form.sendEvent.notifyResponsible.not')}
                    checkedLabelClassName="status-label"
                    uncheckedLabelClassName="status-label"
                    ref={(c) =>  component[customRef].emailNotifyResponsible = c}
                />
            </Row>
            <Row>
                <Multiselect
                    fieldLabel={t('events.form.sendEvent.users')}
                    fieldPlaceHolder={t('events.form.sendEvent.usersPlaceHolder')}
                    iconClassName='fa fa-user'
                    protectHiddenValues={protectHiddenValues}
                    width='8'
                    isView={isView}
                    options={getUsersListOptions(RuleCacheService.getActiveUsers())}
                    ref={(c) => component[customRef].emailUsers = c}
                />
            </Row>
            <Row>
                <Multiselect
                    fieldLabel={t('events.form.sendEvent.emails')}
                    fieldPlaceHolder={t('events.form.sendEvent.emailsPlaceHolder')}
                    width='8'
                    permitCreate={true}
                    isView={isView}
                    iconClassName='fa fa-envelope'
                    validatorRegex={config.user.emailregex}
                    ref={(c) => component[customRef].emailEmails = c}
                />
            </Row>
        </div>
    );
}

function getWhatsappTab(component, isView, customRef, helpdesk) {
    const {protectHiddenValues, codes, defaultDialCode} = getGenericInformationData();

    return (
        <div className='form-group col-xs-12 p-l-r-0'>
            <Row className='pd-t-5'>
                <SwitchField
                    fieldLabel={t('events.form.sendEvent.notifyResponsible.label')}
                    width="4"
                    isView={isView}
                    checkedLabel={t('events.form.sendEvent.notifyResponsible.notify')}
                    uncheckedLabel={t('events.form.sendEvent.notifyResponsible.not')}
                    checkedLabelClassName="status-label"
                    uncheckedLabelClassName="status-label"
                    ref={(c) =>  component[customRef].whatsappNotifyResponsible = c}
                />
            </Row>
            <Row>
                <Multiselect
                    fieldLabel={t('events.form.sendEvent.users')}
                    fieldPlaceHolder={t('events.form.sendEvent.usersPlaceHolder')}
                    iconClassName='fa fa-user'
                    protectHiddenValues={protectHiddenValues}
                    width='8'
                    isView={isView}
                    options={getUsersListOptionsForPhone(RuleCacheService.getActiveUsers())}
                    useCustomFilter={true}
                    ref={(c) => component[customRef].whatsappUsers = c}
                />
            </Row>
            <Row>
                <TwoInputFieldMulti
                    fieldLabel={t('events.form.sendEvent.phoneNumbers')}
                    dropPlaceHolder={t('users.form.phoneNumber.callingCodePlaceHolder')}
                    multiPlaceHolder={t('events.form.sendEvent.phoneNumbersPlaceHolder')}
                    isView={isView}
                    selectData={codes}
                    dropValue={defaultDialCode}
                    permitCreate={true}
                    bottomMargin={true}
                    dataId={'value'}
                    dataAttribute={'label'}
                    requiredFailError={t('users.form.errors.phoneNumber.required')}
                    width='8'
                    validatorRegex={config.user.phoneregex}
                    ref={(c) => component[customRef].whatsappNumbers = c}
                />
            </Row>
        </div>
    );
}

function getSmsTab(component, isView, customRef, helpdesk) {
    const {protectHiddenValues, codes, defaultDialCode} = getGenericInformationData();
    return (
        <div className='form-group col-xs-12 p-l-r-0'>
            <Row className='pd-t-5'>
                <SwitchField
                    fieldLabel={t('events.form.sendEvent.notifyResponsible.label')}
                    width="4"
                    isView={isView}
                    checkedLabel={t('events.form.sendEvent.notifyResponsible.notify')}
                    uncheckedLabel={t('events.form.sendEvent.notifyResponsible.not')}
                    checkedLabelClassName="status-label"
                    uncheckedLabelClassName="status-label"
                    ref={(c) =>  component[customRef].smsNotifyResponsible = c}
                />
            </Row>
            <Row>
                <Multiselect
                    fieldLabel={t('events.form.sendEvent.users')}
                    fieldPlaceHolder={t('events.form.sendEvent.usersPlaceHolder')}
                    iconClassName='fa fa-user'
                    protectHiddenValues={protectHiddenValues}
                    width='8'
                    isView={isView}
                    options={getUsersListOptionsForPhone(RuleCacheService.getActiveUsers())}
                    useCustomFilter={true}
                    ref={(c) => component[customRef].smsUsers = c}
                />
            </Row>
            <Row>
                <TwoInputFieldMulti
                    fieldLabel={t('events.form.sendEvent.phoneNumbers')}
                    dropPlaceHolder={t('users.form.phoneNumber.callingCodePlaceHolder')}
                    multiPlaceHolder={t('events.form.sendEvent.phoneNumbersPlaceHolder')}
                    isView={isView}
                    selectData={codes}
                    dropValue={defaultDialCode}
                    permitCreate={true}
                    bottomMargin={true}
                    dataId={'value'}
                    dataAttribute={'label'}
                    requiredFailError={t('users.form.errors.phoneNumber.required')}
                    width='8'
                    validatorRegex={config.user.phoneregex}
                    ref={(c) => component[customRef].smsNumbers = c}
                />
            </Row>
        </div>
    );
}

function getVoiceTab(component, isView, customRef, helpdesk) {
    const {protectHiddenValues, codes, defaultDialCode} = getGenericInformationData();
    return (
        <div className='form-group col-xs-12 p-l-r-0'>
            <Row className='pd-t-5'>
                <SwitchField
                    fieldLabel={t('events.form.sendEvent.notifyResponsible.label')}
                    width="4"
                    isView={isView}
                    checkedLabel={t('events.form.sendEvent.notifyResponsible.notify')}
                    uncheckedLabel={t('events.form.sendEvent.notifyResponsible.not')}
                    checkedLabelClassName="status-label"
                    uncheckedLabelClassName="status-label"
                    ref={(c) =>  component[customRef].voiceNotifyResponsible = c}
                />
            </Row>
            <Row>
                <Multiselect
                    fieldLabel={t('events.form.sendEvent.users')}
                    fieldPlaceHolder={t('events.form.sendEvent.usersPlaceHolder')}
                    iconClassName='fa fa-user'
                    protectHiddenValues={protectHiddenValues}
                    width='8'
                    isView={isView}
                    options={getUsersListOptionsForPhone(RuleCacheService.getActiveUsers())}
                    useCustomFilter={true}
                    ref={(c) => component[customRef].voiceUsers = c}
                />
                <TwoInputFieldMulti
                    fieldLabel={t('events.form.sendEvent.phoneNumbers')}
                    dropPlaceHolder={t('users.form.phoneNumber.callingCodePlaceHolder')}
                    multiPlaceHolder={t('events.form.sendEvent.phoneNumbersPlaceHolder')}
                    isView={isView}
                    selectData={codes}
                    dropValue={defaultDialCode}
                    permitCreate={true}
                    bottomMargin={true}
                    dataId={'value'}
                    dataAttribute={'label'}
                    requiredFailError={t('users.form.errors.phoneNumber.required')}
                    width='8'
                    validatorRegex={config.user.phoneregex}
                    ref={(c) => component[customRef].voiceNumbers = c}
                />
            </Row>
        </div>
    );
}

function getGenericInformationData() {
    const protectHiddenValues = !RolesUtils.hasPermissionForName(permissions.users.read) &&
        !RolesUtils.hasPermissionForName(permissions.users.list);
    let codes = DialCodesUtils.getDialCodes();
    codes = codes.map(code => {
        return {'value': code.id, 'label': code.name};
    });
    const defaultDialCode = DialCodesUtils.getDefaultDialCode();
    return {protectHiddenValues, codes, defaultDialCode}
}

function getUsersListOptions(userList) {
    let list = [];
    if(!_.isEmpty(userList)) {
        userList.forEach(user => {
            list.push({
                'label': user.shortName,
                'value': user.userId
            });
        });
    }
    return list;
}

export function getUsersListOptionsForPhone(userList) {
    let list = [];
    if(!_.isEmpty(userList)) {
        userList.forEach(user => {
            list.push({
                'label': (
                    <>
                        <span style={{ paddingRight: "10px" }}>{user.shortName}</span>
                        {!user.hasValidPhone && (
                                <Tooltip content={t('events.form.sendEvent.phoneNotConfigured')}>
                                    <img src={blockedIcon} alt={""} style={{width:"15px"}}/>
                                </Tooltip>
                            )
                        }
                    </>
                ),
                'value': user.userId,
                'searchValue': user.shortName,
                disabled: !user.hasValidPhone,
            });
        });
    }
    return list;
}

export default EventAction;