import {LOAD_CHART_WIDGET_DATA_REQUEST} from './widgetSagas/chart/actionTypes';
import {LOAD_BATCH_INFO_WIDGET_DATA_REQUEST} from './widgetSagas/batchInfo/actionTypes';
import {LOAD_CONSUMPTION_WIDGET_DATA_REQUEST} from './widgetSagas/consumption/actionTypes';
import {LOAD_MAP_WIDGET_DATA_REQUEST} from './widgetSagas/map/actionTypes';
import {LOAD_ANIMAL_WEIGHT_WIDGET_DATA_REQUEST} from './widgetSagas/animalWeight/actionTypes';
import {LOAD_SILO_WIDGET_DATA_REQUEST} from './widgetSagas/silo/actionTypes';
import {LOAD_MORTALITY_BY_CAUSE_WIDGET_DATA_REQUEST} from './widgetSagas/mortality/actionTypes';
import {
    LOAD_EVENTS_COUNTER_WIDGET_DATA_REQUEST,
    LOAD_RULES_COUNTER_WIDGET_DATA_REQUEST
} from "./widgetSagas/counter/actionTypes";

export const CREATE_USER_DASHBOARD = "[DASHBOARD] CREATE_USER_DASHBOARD";
export const CREATE_USER_DASHBOARD_SUCCESS = "[DASHBOARD] CREATE_USER_DASHBOARD_SUCCESS";
export const CREATE_USER_DASHBOARD_FAILED = "[DASHBOARD] CREATE_USER_DASHBOARD_FAILED";

export const UPDATE_DASHBOARD_NAME = "[DASHBOARD] UPDATE_DASHBOARD_NAME";
export const UPDATE_DASHBOARD_NAME_SUCCESS = "[DASHBOARD] UPDATE_DASHBOARD_NAME_SUCCESS";
export const UPDATE_DASHBOARD_NAME_FAILED = "[DASHBOARD] UPDATE_DASHBOARD_NAME_FAILED";

export const LOAD_USER_DASHBOARD = "[DASHBOARD] LOAD_USER_DASHBOARD";
export const LOAD_USER_DASHBOARD_SUCCESS = "[DASHBOARD] LOAD_USER_DASHBOARD_SUCCESS";
export const LOAD_USER_DASHBOARD_FAILED = "[DASHBOARD] LOAD_USER_DASHBOARD_FAILED";

export const DELETE_DASHBOARD = "[DASHBOARD] DELETE_USER_DASHBOARD";
export const DELETE_USER_DASHBOARD_SUCCESS = "[DASHBOARD] DELETE_USER_DASHBOARD_SUCCESS";
export const DELETE_USER_DASHBOARD_FAILED = "[DASHBOARD] DELETE_USER_DASHBOARD_FAILED";

export const COPY_ENTITY_DASHBOARD_TO_USER = "[DASHBOARD] COPY_ENTITY_DASHBOARD_TO_USER";
export const COPY_ENTITY_DASHBOARD_TO_USER_SUCCESS = "[DASHBOARD] COPY_ENTITY_DASHBOARD_TO_USER_SUCCESS";
export const COPY_ENTITY_DASHBOARD_TO_USER_FAILED = "[DASHBOARD] COPY_ENTITY_DASHBOARD_TO_USER_FAILED";

export const LOAD_ENTITY_DASHBOARD = "[DASHBOARD] LOAD_ENTITY_DASHBOARD";
export const LOAD_ENTITY_DASHBOARD_SUCCESS = "[DASHBOARD] LOAD_ENTITY_DASHBOARD_SUCCESS";
export const LOAD_ENTITY_DASHBOARD_FAILED = "[DASHBOARD] LOAD_ENTITY_DASHBOARD_FAILED";

export const UPDATE_FAVOURITE_DASHBOARD = "[DASHBOARD] UPDATE_FAVOURITE_DASHBOARD";
export const UPDATE_FAVOURITE_DASHBOARD_SUCCESS = "[DASHBOARD] UPDATE_FAVOURITE_DASHBOARD_SUCCESS";
export const UPDATE_FAVOURITE_DASHBOARD_FAILED = "[DASHBOARD] UPDATE_FAVOURITE_DASHBOARD_FAILED";

export const DELETE_FAVOURITE_DASHBOARD = "[DASHBOARD] DELETE_FAVOURITE_DASHBOARD";
export const DELETE_FAVOURITE_ENTITY_DASHBOARD = "[DASHBOARD] DELETE_FAVOURITE_ENTITY_DASHBOARD";
export const DELETE_FAVOURITE_DASHBOARD_SUCCESS = "[DASHBOARD] DELETE_FAVOURITE_DASHBOARD_SUCCESS";
export const DELETE_FAVOURITE_DASHBOARD_FAILED = "[DASHBOARD] DELETE_FAVOURITE_DASHBOARD_FAILED";
export const DELETE_FAVOURITE_ENTITY_DASHBOARD_SUCCESS = "[DASHBOARD] DELETE_FAVOURITE_ENTITY_DASHBOARD_SUCCESS";
export const DELETE_FAVOURITE_ENTITY_DASHBOARD_FAILED = "[DASHBOARD] DELETE_FAVOURITE_ENTITY_DASHBOARD_FAILED";

export const LOAD_HOMEPAGE_DASHBOARD = "[DASHBOARD] LOAD_HOMEPAGE_DASHBOARD";
export const LOAD_HOMEPAGE_DASHBOARD_SUCCESS = "[DASHBOARD] LOAD_HOMEPAGE_DASHBOARD_SUCCESS";
export const LOAD_HOMEPAGE_DASHBOARD_FAILED = "[DASHBOARD] LOAD_HOMEPAGE_DASHBOARD_FAILED";

export const SET_HOMEPAGE_DASHBOARD = "[DASHBOARD] SET_HOMEPAGE_DASHBOARD";
export const SET_HOMEPAGE_DASHBOARD_SUCCESS = "[DASHBOARD] SET_HOMEPAGE_DASHBOARD_SUCCESS";
export const SET_HOMEPAGE_DASHBOARD_FAILED = "[DASHBOARD] SET_HOMEPAGE_DASHBOARD_FAILED";

export const LOAD_WIDGETS_REQUEST = "[DASHBOARD] LOAD_WIDGETS_REQUEST";
export const LOAD_WIDGETS_SUCCESS = "[DASHBOARD] LOAD_WIDGETS_SUCCESS";
export const LOAD_WIDGETS_FAILED = "[DASHBOARD] LOAD_WIDGETS_FAILED";

export const LOAD_WIDGET_DATA_REQUEST = "[DASHBOARD] LOAD_WIDGET_DATA_REQUEST";
export const LOAD_WIDGET_DATA_SUCCESS = "[DASHBOARD] LOAD_WIDGET_DATA_SUCCESS";
export const LOAD_WIDGET_DATA_FAILED = "[DASHBOARD] LOAD_WIDGET_DATA_FAILED";

export const LOAD_WIDGET_INTERFACE_ID_REQUEST = "[DASHBOARD] LOAD_WIDGET_INTERFACE_ID_REQUEST";
export const LOAD_WIDGET_INTERFACE_ID_SUCCESS = "[DASHBOARD] LOAD_WIDGET_INTERFACE_ID_SUCCESS";
export const LOAD_WIDGET_INTERFACE_ID_FAILED = "[DASHBOARD] LOAD_WIDGET_INTERFACE_ID_FAILED";

export const LOAD_WIDGET_ENTITIES_ID_REQUEST = "[DASHBOARD] LOAD_WIDGET_ENTITIES_ID_REQUEST";
export const LOAD_WIDGET_ENTITIES_ID_SUCCESS = "[DASHBOARD] LOAD_WIDGET_ENTITIES_ID_SUCCESS";
export const LOAD_WIDGET_ENTITIES_ID_FAILED = "[DASHBOARD] LOAD_WIDGET_ENTITIES_ID_FAILED";

export const UPDATE_REAL_TIME_INTERFACES_DATA_REQUEST = "[DASHBOARD_WIDGET_DATA] UPDATE_REAL_TIME_INTERFACES_DATA_REQUEST";
export const UPDATE_REAL_TIME_INTERFACES_DATA_SUCCESS = "[DASHBOARD_WIDGET_DATA] UPDATE_REAL_TIME_INTERFACES_DATA_SUCCESS";
export const UPDATE_REAL_TIME_INTERFACES_DATA_FAILED = "[DASHBOARD_WIDGET_DATA] UPDATE_REAL_TIME_INTERFACES_DATA_FAILED";
export const UPDATE_REAL_TIME_INTERFACES_AGGREGATE_DATA_REQUEST = "[DASHBOARD_WIDGET_DATA] UPDATE_REAL_TIME_INTERFACES_AGGREGATE_DATA_REQUEST";
export const UPDATE_REAL_TIME_INTERFACES_AGGREGATE_DATA_SUCCESS = "[DASHBOARD_WIDGET_DATA] UPDATE_REAL_TIME_INTERFACES_AGGREGATE_DATA_SUCCESS";
export const UPDATE_REAL_TIME_INTERFACES_AGGREGATE_DATA_ERROR = "[DASHBOARD_WIDGET_DATA] UPDATE_REAL_TIME_INTERFACES_AGGREGATE_DATA_ERROR";

export const CREATE_WIDGET = "[DASHBOARD] CREATE_WIDGET";
export const EDIT_WIDGET = "[DASHBOARD] EDIT_WIDGET";
export const DELETE_WIDGET = "[DASHBOARD] DELETE_WIDGET";
export const UPDATE_REAL_TIME_INTERFACES = "[DASHBOARD] UPDATE_REAL_TIME_INTERFACES";
export const UPDATE_AGGREGATE_INTERFACES = "[DASHBOARD] UPDATE_AGGREGATE_INTERFACES";
export const UPDATE_LAYOUT = "[DASHBOARD] UPDATE_LAYOUT";

export const CLEAN_LAYOUT = "[DASHBOARD] CLEAN_LAYOUT";

export const UPDATE_SUBTITLE = "[DASHBOARD] UPDATE_SUBTITLE";

/*
 Dashboard Tree View
 */
export const RESET_STATE = "[DASHBOARD_TREE] RESET_STATE";
export const TREE_ENTITY_INTERFACES_REQUEST = "[DASHBOARD_TREE] TREE_ENTITY_INTERFACES_REQUEST";
export const TREE_ENTITY_INTERFACES_SUCCESS = "[DASHBOARD_TREE] TREE_ENTITY_INTERFACES_SUCCESS";
export const TREE_ENTITY_INTERFACES_ERROR = "[DASHBOARD_TREE] TREE_ENTITY_INTERFACES_ERROR";
export const TREE_ENTITY_INTERFACES_REAL_TIME_DATA_REQUEST = "[DASHBOARD_TREE] TREE_ENTITY_INTERFACES_REAL_TIME_DATA_REQUEST";
export const TREE_ENTITY_INTERFACES_REAL_TIME_DATA_SUCCESS = "[DASHBOARD_TREE] TREE_ENTITY_INTERFACES_REAL_TIME_DATA_SUCCESS";
export const TREE_ENTITY_INTERFACES_REAL_TIME_DATA_ERROR = "[DASHBOARD_TREE] TREE_ENTITY_INTERFACES_REAL_TIME_DATA_ERROR";
export const TREE_ENTITY_INTERFACES_AGGREGATE_DATA_REQUEST = "[DASHBOARD_TREE] TREE_ENTITY_INTERFACES_AGGREGATE_DATA_REQUEST";
export const TREE_ENTITY_INTERFACES_AGGREGATE_DATA_SUCCESS = "[DASHBOARD_TREE] TREE_ENTITY_INTERFACES_AGGREGATE_DATA_SUCCESS";
export const TREE_ENTITY_INTERFACES_AGGREGATE_DATA_ERROR = "[DASHBOARD_TREE] TREE_ENTITY_INTERFACES_AGGREGATE_DATA_ERROR";

export {
    LOAD_CHART_WIDGET_DATA_REQUEST,
    LOAD_MAP_WIDGET_DATA_REQUEST,
    LOAD_SILO_WIDGET_DATA_REQUEST,
    LOAD_CONSUMPTION_WIDGET_DATA_REQUEST,
    LOAD_BATCH_INFO_WIDGET_DATA_REQUEST,
    LOAD_ANIMAL_WEIGHT_WIDGET_DATA_REQUEST,
    LOAD_MORTALITY_BY_CAUSE_WIDGET_DATA_REQUEST,
    LOAD_RULES_COUNTER_WIDGET_DATA_REQUEST,
    LOAD_EVENTS_COUNTER_WIDGET_DATA_REQUEST
};