import React from 'react';
import _ from "lodash";
import classnames from 'classnames';

class TabContent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            position: props.position,
            active: props.active,
            loadOnce: !_.isNil(props.loadOnce) ?  props.loadOnce : true,
            loaded: props.active,
            lazyLoad: !_.isNil(props.lazyLoad)? props.lazyLoad : false
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            active: nextProps.active
        });
    }

    setActive(active) {
        let newState = {
            active: active
        };

        if(active){
            newState.loaded = true;
        }
        this.setState(newState);
    }

    render() {
        let bgColorClass = this.props.bgColorClass ? this.props.bgColorClass : "gray-bg";
        let classNames = classnames("tab-pane", bgColorClass, {"active": this.state.active});
        let content = this.props.componentToShow;

        if(this.state.lazyLoad) {
            content = this.state.active || (this.state.loadOnce && this.state.loaded)  ? this.props.componentToShow : null
        }

        return <div className={classNames}>
            <div className="panel-body">
                {content}
            </div>
        </div>;
    }
}

export default TabContent;