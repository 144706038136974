import axios from "axios";
import _ from "lodash";
import RuleService from "../RuleService";
import UserSessionService from "../UserSessionService";

const AsyncRuleService = {

    getTemplateList: function (entityObj) {
        if(UserSessionService.getRuleTemplateListForEntity(entityObj? entityObj.entityId : null)) {
            return UserSessionService.getRuleTemplateListForEntity(entityObj? entityObj.entityId : null);
        } else {
            return new Promise((accept, reject) => {
                axios.get('/rules/template', {
                    params: entityObj
                }).then(response => {
                    let templateListWithCurrentLocale = [];
                    let templateAux = {};
                    _.forEach(response.data, template => {
                        templateAux = template;
                        templateAux = RuleService.filterTemplateWithCurrentLocale(templateAux);
                        templateListWithCurrentLocale.push(templateAux);
                    });
                    UserSessionService.storeRuleTemplateListForEntity(entityObj? entityObj.entityId : null, templateListWithCurrentLocale);
                    accept(templateListWithCurrentLocale);
                }, error => {
                    reject(error);
                });
            });
        }
    },

    countRules: function (searchData) {
        return new Promise((accept, reject) => {
            axios.get('/rules/count', {
                params: searchData
            }).then(response => {
                accept(response.data);
            }, reject);
        });
    },

    countReceiptUserRules: function () {
        return new Promise((accept, reject) => {
            axios.get('/rules/count/receiptUser').then(response => {
                accept(response.data);
            }, reject);
        });
    },

    undeployRule: function (ruleId) {
        return new Promise((accept, reject) => {
            axios.get('/rules/' + ruleId + '/undeploy').then(response => {
                accept(response.data);
            }, reject);
        });
    },

    createAlarmRule: function (ruleData) {
        return new Promise((accept, reject) => {
            axios.post('/rules/bulk', ruleData).then(response => {
                accept(response.data);
            }, reject);
        });
    },

    editAlarmRule: function (ruleData) {
        return new Promise((accept, reject) => {
            axios.put('/rules/bulk', ruleData).then(response => {
                accept(response.data);
            }, reject);
        });
    }

};

export default AsyncRuleService;