import UserSessionService from "./UserSessionService";
import _ from "lodash";

let RuleCacheService = {

    prefixes: {
        farmEntities: 'farmEntities',
        sectorsOfFarm: 'sectorsOfFarm.',
        terminalsOfEntity: 'terminalsOfEntity.',
        interfacesOfTerminal: 'interfacesOfTerminal.',

        activeUsers: 'activeUsers',
        eventCategories: 'eventCategories',
        dialCode: 'dialCode',
        userEntityTree: 'userEntityTree',

        logicalOperations: 'logicalOperations.'
    },

    getLogicalOperationsForDataType(dataType) {
        return UserSessionService.getSessionStorageEntryWithTimer(RuleCacheService.prefixes.logicalOperations + dataType);
    },

    storeLogicalOperationsForDataType(dataType, data) {
        UserSessionService.createSessionStorageEntryWithTimer(
            RuleCacheService.prefixes.logicalOperations + dataType,
            data,
            600
        );
    },

    getActiveFarmEntities() {
        return UserSessionService.getSessionStorageEntryWithTimer(RuleCacheService.prefixes.farmEntities);
    },

    storeActiveFarmEntities(data) {
        UserSessionService.createSessionStorageEntryWithTimer(
            RuleCacheService.prefixes.farmEntities,
            data,
            300
        );
    },

    getSectorsOfFarm(farmId) {
        return UserSessionService.getSessionStorageEntryWithTimer(RuleCacheService.prefixes.sectorsOfFarm + farmId);
    },

    storeSectorsOfFarm(farmId, data) {
        UserSessionService.createSessionStorageEntryWithTimer(
            RuleCacheService.prefixes.sectorsOfFarm + farmId,
            data,
            120
        );
    },

    getTerminalsOfEntity(entityId) {
        return UserSessionService.getSessionStorageEntryWithTimer(RuleCacheService.prefixes.terminalsOfEntity + entityId);
    },

    storeTerminalsOfEntity(entityId, data) {
        UserSessionService.createSessionStorageEntryWithTimer(
            RuleCacheService.prefixes.terminalsOfEntity + entityId,
            data,
            120
        );
    },

    getInterfacesOfTerminal(terminalId) {
        return UserSessionService.getSessionStorageEntryWithTimer(RuleCacheService.prefixes.interfacesOfTerminal + terminalId);
    },

    storeInterfacesOfTerminal(terminalId, data) {
        _.forEach(data, inter => {
            delete inter.category.name;
            delete inter.entity.code;
            delete inter.entity.responsibleUsers;
            delete inter.entity.status;
            if(inter.entity.farmOfSector) {
                delete inter.entity.farmOfSector.responsibleUsers;
                delete inter.entity.farmOfSector.status;
            }
            delete inter.hidden;
            delete inter.historyConfiguration;
            delete inter.lastConvertedReading;
            delete inter.lastReadingTimestampUtc;
            delete inter.terminal.lastCommunicationTimestampUtc;
        });
        //UserSessionService.clearSessionStorageByKey(RuleCacheService.prefixes.interfacesOfTerminal);
        // UserSessionService.createSessionStorageEntry(RuleCacheService.prefixes.interfacesOfTerminal + terminalId, JSON.stringify(data));
        UserSessionService.createSessionStorageEntryWithTimer(
            RuleCacheService.prefixes.interfacesOfTerminal + terminalId,
            data,
            3600
        );
    },

    getActiveUsers() {
        return UserSessionService.getSessionStorageEntryWithTimer(RuleCacheService.prefixes.activeUsers);
    },

    storeActiveUsers(data) {
        UserSessionService.createSessionStorageEntryWithTimer(
            RuleCacheService.prefixes.activeUsers,
            data,
            3600
        );
    },

    getEventCategories() {
        return UserSessionService.getSessionStorageEntryWithTimer(RuleCacheService.prefixes.eventCategories);
    },

    storeEventCategories(data) {
        UserSessionService.createSessionStorageEntryWithTimer(
            RuleCacheService.prefixes.eventCategories,
            data,
            300
        );
    },

    getDefaultDialCode() {
        return UserSessionService.getSessionStorageEntry(RuleCacheService.prefixes.dialCode);
    },

    storeDefaultDialCode(data) {
        UserSessionService.createSessionStorageEntry(
            RuleCacheService.prefixes.dialCode,
            data
        );
    },

};

export default RuleCacheService;