import axios from "axios";
import _ from "lodash";
import {t} from "../utils/Translation";
import UserSessionService from "./UserSessionService";
import AsyncEntityService from "./async/AsyncEntityService";
import RuleCacheService from "./RuleCacheService";
import LocalesUtils from "../utils/LocalesUtils";
import {permissions} from "../utils/permissions";
import RolesUtils from "../utils/RolesUtils";
import EntityUtils from "../utils/EntityUtils";
import AsyncInterfaceService from "./async/AsyncInterfaceService";
import __ from "../utils/EnhancedLodash";
import InterfaceService from "./InterfaceService";

let EntityService = {

    entityDetailsTabParam: {
        entityInfo: 0,
        defaultInterfaces: 1
    },

    entityTypeGroup: {
        ROOT: 'ROOT',
        TENANT: 'TENANT',
        PHYSICAL: 'PHYSICAL'
    },

    entityGroup : {
        NON_PHYSICAL : ['GROUP', 'ENTERPRISE', 'ROOT', 'PARTNER'],
        PHYSICAL: ['FARM', 'SECTOR']
    },

    entityType: {
        ROOT: {
            name: 'ROOT',
            possibleChildrenTypes: ['PARTNER', 'GROUP', 'ENTERPRISE']
        },
        PARTNER: {
            name: 'PARTNER',
            possibleChildrenTypes: ['GROUP', 'ENTERPRISE'],
            possibleParentTypes: ['ROOT']
        },
        GROUP: {
            name: 'GROUP',
            possibleChildrenTypes: ['GROUP', 'ENTERPRISE'],
            possibleTransferParentEntityTypes: ['ROOT', 'PARTNER', 'GROUP'],
            possibleParentTypes: ['ROOT', 'PARTNER']
        },
        ENTERPRISE: {
            name: 'ENTERPRISE',
            possibleChildrenTypes: ['FARM'],
            possibleTransferParentEntityTypes: ['ROOT', 'PARTNER', 'GROUP'],
            possibleParentTypes: ['ROOT', 'PARTNER', 'GROUP']
        },
        FARM: {
            name: 'FARM',
            possibleChildrenTypes: ['SECTOR'],
            possibleTransferParentEntityTypes: ['ENTERPRISE'],
            possibleParentTypes: ['ENTERPRISE']
        },
        SECTOR: {
            name: 'SECTOR',
            possibleChildrenTypes: ['SECTOR'],
            possibleTransferParentEntityTypes: ['FARM', 'SECTOR'],
            possibleParentTypes: ['FARM', 'SECTOR']
        },
    },

    entityStatus: {
        all: [
            { "id": "ACTIVE", "name": "Active" },
            { "id": "DISABLED", "name": "Disabled" },
            { "id": "CLOSED", "name": "Closed" },
            { "id": "EXPIRED", "name": "Expired" }
        ],
        ACTIVE: 'ACTIVE',
        DISABLED: 'DISABLED',
        CLOSED: 'CLOSED',
        EXPIRED: 'EXPIRED'
    },

    tagCreationResult: {
        DEFERRED: 'DEFERRED',
        SUCCESS: 'SUCCESS',
        ERROR: 'ERROR'
    },

    getPossibleNewEntityStatus() {
        return [
            {"id": "ACTIVE", "name": t("entities.form.entityInfo.status.options.active")},
            {"id": "DISABLED", "name": t("entities.form.entityInfo.status.options.disabled")}
        ]
    },

    loadEntities: function (searchData, onSuccess, onError) {
        axios.get('/entities/search', {
            params: searchData
        }).then((response) => {
            if (onSuccess) {
                _.forEach(response.data.content, entity => {
                    if(!_.isNil(entity.farmOfSector) ) {
                        entity.displayName = entity.farmOfSector.displayName + " | " + entity.displayName;
                    }
                });

                onSuccess(response.data);
            }
        }, (error) => {
            if (onError) {
                onError(error);
            }
        });
    },

    getEventsByEntities: function (searchData, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};
        AsyncEntityService.getEventsByEntities(searchData).then(onSuccess, onError);
    },

    getEntities: function (searchData, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};
        AsyncEntityService.getAllEntities(searchData).then(onSuccess, onError);
    },

    getSubEntities: function (entityId, searchData, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};
        AsyncEntityService.getSubEntities(entityId, searchData).then(onSuccess, onError);
    },

    getSubEntitiesMultiEntities: function (searchData, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};
        AsyncEntityService.getSubEntitiesMultiEntities(searchData).then(onSuccess, onError);
    },

    getParentEntities: function (entityId, searchData, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};
        AsyncEntityService.getParentEntities(entityId, searchData).then(onSuccess, onError);
    },

    getEntityParent: function (entityId, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};
        AsyncEntityService.getEntityParent(entityId).then(onSuccess, onError);
    },

    getEntitiesDetails: function (entities, onSuccess = () => {}, onError = () => {}) {
        axios.get('/entities/details', {params: {entityId: entities}})
            .then((response) => {
                onSuccess(response.data);
            }, (error) => {
                onError(error);
            })
    },

    getEntityDetails: function (entityId, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        axios.get('/entities/' + entityId).then((response) => {
            let result = _.cloneDeep(response.data);
            result.type = {
                ...result.type,
                displayName: t('entities.types.' + _.lowerCase(result.type.name)),
                name: _.upperCase(result.type.name)
            };
            result.entityInfo = EntityUtils.getEntityInfoFromResponse(response.data);
            if (response.data.type.group === EntityService.entityTypeGroup.TENANT) {
                result.billingInfo = EntityUtils.getBillingInfoFromResponse(response.data.billingInformation);
                onSuccess(result);
            } else {
                onSuccess(result);
            }
        }, (error) => {
            onError(error);
        });
    },

    createEntity: function (entityData, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};
        let formBox = new FormData();
        formBox.append('file', entityData.image);
        formBox.append('createEntityRequest', new Blob([JSON.stringify(entityData)], {type: "application/json"}));

        axios.post('/entities', formBox).then((response) => {
            onSuccess(response.data);
        }, (error) => {
            onError(error);
        });
    },

    deleteEntity: function (entityId, onSuccess, onError) {
        axios.delete('/entities/' + entityId).then((response) => {
            if (onSuccess) {
                onSuccess(response.data);
            }
        }, (error) => {
            if (onError) {
                onError(error);
            }
        });
    },

    editEntity: function (entityId, entityData, onSuccess, onError) {
        let formBox = new FormData();
        formBox.append('file', entityData.image);
        entityData.image = null;
        formBox.append('editEntityRequest', new Blob([JSON.stringify(entityData)], {type: "application/json"}));
        axios.put('/entities/' + entityId, formBox).then((response) => {
            if (onSuccess) {
                onSuccess(response.data);
            }
        }, (error) => {
            if (onError) {
                onError(error);
            }
        });
    },

    getEntityTypes: function (onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => { };
        onError = onError ? onError : () => { };

        let entityTypeList = UserSessionService.getEntityTypeList();

        if (_.isNil(entityTypeList)) {
            AsyncEntityService.getAllEntityTypes().then(response => {
                UserSessionService.storeEntityTypeList(response);
                response.forEach(type => {
                    type.displayName = t('entities.types.' + _.lowerCase(type.name));
                    type.name = _.upperCase(type.name);
                });
                onSuccess(response);
            }, onError);
        } else {
            entityTypeList.forEach(type => {
                type.displayName = t('entities.types.' + _.lowerCase(type.name));
                type.name = _.upperCase(type.name);
            });
            onSuccess(entityTypeList);
        }
    },

    getEntityTypesWithNamesSync: function (names) {
        let entityTypeList = UserSessionService.getEntityTypeList();
        let filteredTypes = [];

        entityTypeList.forEach(type => {
            if (_.includes(names, type.name.toUpperCase())) {
                type.name = _.capitalize(type.name);
                filteredTypes.push(type);
            }
        });

        return filteredTypes;
    },

    getEntityTypesWithNames: function (names, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => { };
        onError = onError ? onError : () => { };

        AsyncEntityService.getAllEntityTypes().then(response => {
            let filteredTypes = [];
            response.forEach(type => {
                if (_.includes(names,type.name.toUpperCase())) {
                    type.name = _.capitalize(type.name);
                    filteredTypes.push(type);
                }
            });
            onSuccess(filteredTypes);
        }, onError);
    },

    getEntityInterfaces: function (entityId, otherFilters, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => { };
        onError = onError ? onError : () => { };

        axios.get('/entities/' + entityId + '/interfaces', {
            params: otherFilters
        }).then((response) => {
            response.data.forEach(inter => {
                if(inter.category.id === InterfaceService.categories.CONNECTION_STATUS){
                    inter.displayName = inter.terminal.displayName + " - " + inter.displayName;
                }
            })
            onSuccess(response.data);
        }, (error) => {
            onError(error);
        });
    },

    getEntityRoles: function (entityId, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};
        AsyncEntityService.getAllEntityRoles(entityId).then(onSuccess, onError);
    },

    getRolesFromEntities: function (entities, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};
        AsyncEntityService.getRolesFromEntities(entities).then(onSuccess,onError);
    },

    getActiveEntityList: function (search = {}, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};
        EntityService.getEntities(search, entities => {
            onSuccess(entities);
        }, onError);
    },

    getEntitySectorsList: function (entityId, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};
        let cachedInfo = RuleCacheService.getSectorsOfFarm(entityId);
        if(cachedInfo) {
            onSuccess(cachedInfo);
        } else {
            AsyncEntityService.getAllSectors(entityId).then(sectors => {
                RuleCacheService.storeSectorsOfFarm(entityId, sectors);
                onSuccess(sectors);
            }, onError);
        }
    },

    getSectorsByEntitiesList: function (entities, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};
        AsyncEntityService.getSectorsByEntities(entities).then(onSuccess, onError);
    },

    getEntityTerminalsList: function (entityId, otherFilters, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};
        AsyncEntityService.getAllTerminals(entityId, otherFilters).then(onSuccess, onError);
    },

    getEntityTerminalInterfacesList: function (entityId, terminalId, otherFilters, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};
        AsyncEntityService.getAllTerminalInterfaces(entityId, terminalId, otherFilters).then(onSuccess, onError);
    },

    transferEntityParent: function (rootEntityId, data, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        axios.put('/entities/' + rootEntityId + '/transfer', data).then((response) => {
            onSuccess(response.data);
        }, (error) => {
            onError(error);
        });
    },

    getInterfaceListByEntitiesAndCategories(searchData, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};
        AsyncEntityService.getInterfaces(searchData).then(onSuccess, onError);
    },

    getTagsList: function (parentEntityId, forceFetch, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => { };
        onError = onError ? onError : () => { };

        let tagsList = [];
        if(RolesUtils.hasPermissionForName(permissions.entities.read_tag)) {
            if(!forceFetch) {
                tagsList  = UserSessionService.getSessionStorageEntryWithTimer("entityTags");
            }

            if (forceFetch || _.isNil(tagsList) || _.isEmpty(tagsList)) {
                axios.get('/entities/tags?entityId=' + parentEntityId).then((response) => {
                    let tags = [];
                    _.forEach(response.data, tag => {
                        let refactoredTag = _.cloneDeep(tag);
                        if(!_.isNil(refactoredTag.translations)) {
                            refactoredTag.name = LocalesUtils.getLocaleString(refactoredTag.translations);
                        }
                        tags.push(refactoredTag);
                    });
                    UserSessionService.createSessionStorageEntryWithTimer("entityTags", JSON.stringify(tags));
                    onSuccess(tags);
                }, error => {
                    onError(error);
                });
            } else {
                onSuccess(JSON.parse(tagsList));
            }

        } else {
            onSuccess(tagsList);
        }

    },

    createTag: function (parentEntityId, tag, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => { };
        onError = onError ? onError : () => { };

        axios.post('/entities/tags?entityId=' + parentEntityId, tag).then(
            response => {
                onSuccess(response.data);
            },
            error => {
                onError(error);
            });
    },

    getInterfaceCategories(onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => { };
        onError = onError ? onError : () => { };

        let result = {
            defaultCategories: UserSessionService.getDefaultInterfaceCategories(),
            allCategories: UserSessionService.getAllInterfaceCategories()
        };

        if (_.isNil(result.defaultCategories) || _.isNil(result.allCategories)) {
            AsyncInterfaceService.getInterfaceDefaultCategories().then(defaultCategories => {
                defaultCategories = EntityService.getCategoryListWithValueAndName(defaultCategories);
                UserSessionService.storeDefaultInterfaceCategories(defaultCategories);
                AsyncInterfaceService.getAllCategoryTypes().then(allCategories => {
                    allCategories = EntityService.getCategoryListWithValueAndName(allCategories);
                    UserSessionService.storeAllInterfaceCategories(allCategories);
                    result.defaultCategories = defaultCategories;
                    result.allCategories =  allCategories;
                    onSuccess(result)

                })
            }, onError);
        } else {
            onSuccess(result);
            return result;
        }
    },

    gatherSectorInfo(entityId, onSuccess, onError = ()=>{}) {
        onSuccess = onSuccess? onSuccess : () => {};
        onError = onError? onError : () => {};

        EntityService.getEntityDefaultInterfaces(
            entityId,
            defaultInterfacesAggrList => {
                let defaultInterfacesCategories =  [];
                _.forEach(defaultInterfacesAggrList, defaultInterfacesAggr => {
                    if(_.findIndex(defaultInterfacesCategories, ifaceCat => {return ifaceCat === defaultInterfacesAggr.category.value;}) === -1) {
                        defaultInterfacesCategories.push(defaultInterfacesAggr.category.value);
                    }
                });
                EntityService.getInterfaceCategories(categories => {
                    let counter = 0;
                    let categoriesToSearch = _.union(_.map(categories.defaultCategories, 'value'), defaultInterfacesCategories);
                    for (let i = 0; i < categoriesToSearch.length; ++i) {
                        let currentCat = categoriesToSearch[i];
                        EntityService.getInterfaceListByEntitiesAndCategories(
                            {
                                entityUuid: entityId,
                                category: currentCat
                            },
                            // eslint-disable-next-line
                            interfaceList => {
                                UserSessionService.createSessionStorageEntryWithTimer(EntityService.getSectorCategoryInterfaceListKey(entityId, currentCat), interfaceList);
                                if(++counter >= categoriesToSearch.length) {
                                    onSuccess(defaultInterfacesAggrList);
                                }
                            },
                            () => {
                            }
                        );
                    }
                });
            }, error => onError(error)
        );
    },

    getEntityDefaultInterfaces(entityId, onSuccess, onError) {
        onSuccess = onSuccess? onSuccess : () => {};
        onError = onError? onError : () => {};

        axios.get('/entities/' + entityId + '/defaultInterfaces').then(
            response => onSuccess(response.data),
            error => onError(error)
        );
    },

    getSectorCategoryInterfaceListKey(entityId, category) {
        return 'sectorInterfaceList-' + entityId + '-category-' + category;
    },

    getCategoryListWithValueAndName(categories) {
        let result = [];
        _.forEach(categories, cat => {
            result.push({
                value: cat.value,
                name: !__.isNilOrEmpty(cat.properties) ? LocalesUtils.getLocaleString(cat.properties) : _.capitalize(cat.value),
                metadata: !__.isNilOrEmpty(cat.metadata) ? cat.metadata.map(meta => !__.isNilOrEmpty(meta.name) ? {
                    value: meta.id,
                    name: LocalesUtils.getLocaleString(meta.name)
                } : _.capitalize(meta.id)) : null
            });
        });
        return result;
    },

    entityHasBatches(entity, onSuccess = () => { }, onError = () => { }) {
        if(entity.type.name === this.entityType.FARM.name) {
            axios.get('/entities/hasBatches?entityId=' + entity.id).then(
                response => onSuccess(response.data),
                error => onError(error)
            );

        } else if(entity.type.name === this.entityType.ENTERPRISE.name) {
            this.getSubEntities(entity.id, {entityTypeNames: [this.entityType.FARM.name]},
                allChildEntities => {
                    const childIdList = allChildEntities.map(entityRecord => entityRecord.id);
                    axios.get('/entities/hasBatches?entityId=' + childIdList).then(
                        response => onSuccess(response.data),
                        error => onError(error)
                    )
                }, error => onError(error))
        } else {
            onSuccess(false);
        }
    },

    getEntityResponsibleUsers(entityId) {
        return new Promise((accept, reject) => {
            axios.get('/entities/' + entityId + '/responsible-users').then(
                response => accept(response.data), reject);
        });
    }
};

export default EntityService;