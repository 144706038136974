import React from "react";
import {Helmet} from "react-helmet";
import {ThemeContext} from "./context/ThemeProvider";

const Header =
    ({
         children
     }) => {

        const { images, css, strings } = React.useContext(ThemeContext);

        return (
            <>
                <Helmet>
                    <title>{strings.title}</title>
                    <link rel="shortcut icon" href={images.favicon}/>
                    <link rel="apple-touch-icon" href={images.touchIconIphone}/>
                    <link rel="apple-touch-icon" sizes="152x152" href={images.touchIconIpad}/>
                    <link rel="apple-touch-icon" sizes="180x180" href={images.touchIconIphoneRetina}/>
                    <link rel="apple-touch-icon" sizes="167x167" href={images.touchIconIpadRetina}/>
                    <link rel="apple-touch-startup-image" href={images.appleTouchStartupImage}/>
                    {
                        css.map(function(f, index) {
                            return (
                                <link key={index} rel="stylesheet" href={f} />
                            )
                        })
                    }
                </Helmet>
                {children}
            </>
        )
    };

export default Header;