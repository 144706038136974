import * as actionTypes from "./actionTypes";
import RolesUtils from "../../utils/RolesUtils";
import {permissions} from "../../utils/permissions";
import EntityService from "../../services/EntityService"
import _ from "lodash";
import moment from "moment";
import EntityUtils from "../../utils/EntityUtils";
import __ from "../../utils/EnhancedLodash";

const initialState =  {
    entityType: { },
    parent: {},
    displayName: {},
    fullName: {},
    tags: {},
    users: {},
    options: {
        readTagsPermission: false,
        createTagsPermission: false,
        tagCreateFormVisible: false,
        isLoading: false,
        tagCreationSuccess: null,
        isEnterprise: false,
        isFarm: false,
        isSector: false,
        enabledBillingInfo: false,
        isPhysicalEntity: false
    },

    enterpriseList: {
        loading: false,
        data: []
    },
    farmList: {
        loading: false,
        data: []
    },
    farmSectorList: {
        loading: false,
        data: []
    },
    entitiesDetails: {

    }
};

const entitiesReducer = (state = initialState, payload) => {
    switch (payload.type) {
        case actionTypes.RESET_STATE: {
            return initialState;
        }
        case actionTypes.LOAD_ENTITY_TYPES:
            return {
                ...state,
                entityType: {
                    ...state.entityType,
                    data: payload.data,
                    value: payload.value
                },
                options: {
                    ...initialState.options,
                    readTagsPermission: RolesUtils.hasPermissionForName(permissions.entities.read_tag),
                    createTagsPermission: RolesUtils.hasPermissionForName(permissions.entities.create_tag)
                }
            };
        case actionTypes.ENTITY_TYPE_CHANGE_SUCCESS:
            return {
                ...state,
                entityType: {
                    ...state.entityType,
                    value: payload.data.entityType
                },
                users: {
                    ...state.users,
                    data: payload.data.users
                },
                parent: {
                    ...state.parent,
                    data: payload.data.entities,
                    value: payload.data.parent
                },
                options: {
                    ...state.options,
                    ...payload.options
                },
                tags: {
                    data: []
                }
            };
        case actionTypes.PARENT_CHANGE_SUCCESS:
            return {
                ...state,
                tags: {
                    ...state.tags,
                    data: payload.data.tags,
                    value: []
                },
                parent: {
                    ...state.parent,
                    value: payload.data.parent
                }
            };
        case actionTypes.SHOW_CREATE_TAGS:
            return {
                ...state,
                options: {
                    ...state.options,
                    tagCreateFormVisible: payload.state
                }
            };
        case actionTypes.UPDATE_TAGS_STATE:
            return {
                ...state,
                options: {
                    ...state.options,
                    isLoading: payload.data.isLoading,
                    tagCreateFormVisible: payload.data.tagCreateFormVisible,
                    tagCreationSuccess: payload.data.tagCreationSuccess
                }
            };
        case actionTypes.SUBMIT_TAGS_SUCCESS:
            return {
                ...state,
                tags: {
                    ...state.tags,
                    data: payload.data.tags
                },
                options: {
                    ...state.options,
                    isLoading: payload.isLoading,
                    tagCreateFormVisible: payload.tagCreateFormVisible,
                    tagCreationSuccess: payload.tagCreationSuccess
                }
            };
        case actionTypes.ENTITY_VIEW_EDIT:
            return {
                entityType: {
                    data: payload.entityTypes,
                    value: payload.entityType
                },
                parent: {
                    data: payload.entities,
                    value: !__.isNilOrEmpty(payload.parent) ? EntityUtils.getSectorWithFarmName(payload.parent) : null
                },
                tags: {
                    data: payload.tagList
                },
                users: {
                    data: payload.usersList
                },
                options: {
                    isEnterprise: _.upperCase(payload.entityType.name) === EntityService.entityType.ENTERPRISE.name,
                    isFarm: _.upperCase(payload.entityType.name) === EntityService.entityType.FARM.name,
                    isSector: _.upperCase(payload.entityType.name) === EntityService.entityType.SECTOR.name,
                    enabledBillingInfo: payload.entityType.group === EntityService.entityTypeGroup.TENANT,
                    isPhysicalEntity: payload.entityType.group === EntityService.entityTypeGroup.PHYSICAL,
                    createTagsPermission: RolesUtils.hasPermissionForName(permissions.entities.create_tag),
                    readTagsPermission: RolesUtils.hasPermissionForName(permissions.entities.read_tag)
                }
            };

        case actionTypes.GET_ENTERPRISE_LIST_REQUEST:
            return {
                ...state,
                enterpriseList: {
                    ...state.enterpriseList,
                    loading: true
                }
            };
        case actionTypes.GET_ENTERPRISE_LIST_SUCCESS:
            return {
                ...state,
                enterpriseList: {
                    loading: false,
                    data: payload.payload,
                    error: null,
                    timestamp: moment().utc()
                }
            };
        case actionTypes.GET_ENTERPRISE_LIST_FAILED:
            return {
                ...state,
                enterpriseList: {
                    loading: false,
                    error: payload.payload,
                    timestamp: undefined
                }
            };
        case actionTypes.RESET_ENTERPRISE_LIST:
            return {
                ...state,
                enterpriseList: {
                    ...state.enterpriseList,
                    value: undefined
                }
            };


        case actionTypes.GET_FARM_LIST_REQUEST:
            return {
                ...state,
                farmList: {
                    loading: true
                }
            };
        case actionTypes.GET_FARM_LIST_SUCCESS:
            return {
                ...state,
                farmList: {
                    loading: false,
                    data: payload.payload,
                    error: null
                }
            };
        case actionTypes.GET_FARM_LIST_FAILED:
            return {
                ...state,
                farmList: {
                    loading: false,
                    error: payload.payload,
                }
            };


        case actionTypes.GET_FARM_AND_SECTOR_LIST_REQUEST:
            return {
                ...state,
                farmSectorList: {
                    loading: true
                }
            };
        case actionTypes.GET_FARM_AND_SECTOR_LIST_SUCCESS:
            return {
                ...state,
                farmSectorList: {
                    loading: false,
                    data: payload.payload
                }
            };
        case actionTypes.GET_FARM_AND_SECTOR_LIST_FAILED:
            return {
                ...state,
                farmSectorList: {
                    loading: false,
                    error: payload.payload,
                }
            };
        case actionTypes.RESET_FARM_AND_SECTOR_LIST:
            return {
                ...state,
                farmSectorList: {
                    loading: false,
                    data: []
                }
            };


        case actionTypes.GET_ENTITY_DEFAULT_INTERFACES_REQUEST:
            return {
                ...state,
                entitiesDetails: {
                    [payload.entityId] : {
                        ...state.entitiesDetails[payload.batchId],
                        defaultInterfaces: {
                            loading: true
                        }
                    }
                }
            };
        case actionTypes.GET_ENTITY_DEFAULT_INTERFACES_SUCCESS:
            return {
                ...state,
                entitiesDetails: {
                    [payload.entityId] : {
                        ...state.entitiesDetails[payload.batchId],
                        defaultInterfaces: {
                            loading: false,
                            data: payload.payload
                        }
                    }
                }
            };
        case actionTypes.GET_ENTITY_DEFAULT_INTERFACES_FAILED:
            return {
                ...state,
                entitiesDetails: {
                    [payload.entityId] : {
                        ...state.entitiesDetails[payload.batchId],
                        defaultInterfaces: {
                            loading: false,
                            data: [],
                            error: payload.payload
                        }
                    }
                }
            };
        default:
            return state;
    }
};

export default entitiesReducer;