import axios from "axios";
import _ from "lodash";
import UserSessionsService from "./UserSessionService";
import RuleCacheService from "./RuleCacheService";

let DashboardService = {
    homeDashboard: 'homeDashboard',
    homeFavourites: 'homeFavourites',
    userDashboardList: 'userDashboardList',

    getDashboardAsync(dashboard) {
        return new Promise((accept, reject) => {
            let url = _.includes(window.location.href, "/public-dashboard") ?
                "/public-dashboard" : "/dashboard";

            if(!(_.isNil(dashboard) || _.isEmpty(dashboard))) {
                dashboard = _.isObject(dashboard) ?  dashboard.id : dashboard;
                url += "/" + dashboard;
            }

            axios.get(url + "/true").then(response => {
                let result = {
                    ...response.data,
                    widgets: response.data.widgets.reduce(
                        (acc, widget) => ({
                            ...acc,
                            [widget.id]: widget
                        }),
                        {}
                    )
                };
                accept(result);
            }, error => {
                reject(error);
            });
        });
    },

    getDashboardInfo(dashboard, onSuccess, onFailure) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onFailure = onFailure ? onFailure : () => {};
        let url = "/dashboard";

        url += _.isEmpty(dashboard) || _.isNil(dashboard) ? "/false" : "/" + dashboard + "/false";

        axios.get(url).then(response => {
            onSuccess(response.data);
        }, onFailure);
    },

    getPublicDashboardInfo(dashboard, onSuccess, onFailure) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onFailure = onFailure ? onFailure : () => {};
        let url = "/public-dashboard";

        url +=  _.isEmpty(dashboard) || _.isNil(dashboard) ? "/false" : "/" + dashboard + "/false";

        axios.get(url).then(response => {
            onSuccess(response.data);
        }, onFailure);
    },

    getDashboardEntitiesTree: function (rootEntityId, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        axios.get( "/dashboard/" + rootEntityId + "/tree").then((response) => {
            if(onSuccess) {
                UserSessionsService.createLocalStorageEntry(RuleCacheService.prefixes.userEntityTree, JSON.stringify(response.data))
                onSuccess(response.data);
            }
        }, (error) => {
            if(onError) {
                onError(error);
            }
        });
    },

    addNewDashboard: function (dashboardName, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        UserSessionsService.clearSessionStorageByKey(DashboardService.userDashboardList);

        axios.post( "/dashboard", {
            displayName : dashboardName
        }).then((response) => {
            onSuccess(response.data);
        }, (error) => {
            onError(error);
        });
    },

    editDashboard: function (dashboardId, dashboardDisplayName, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        UserSessionsService.clearSessionStorageByKey(DashboardService.homeDashboard);
        UserSessionsService.clearSessionStorageByKey(DashboardService.homeFavourites);
        UserSessionsService.clearSessionStorageByKey(DashboardService.userDashboardList);

        axios.put( "/dashboard/" + dashboardId , {
            displayName: dashboardDisplayName
        }).then((response) => {
            onSuccess(response.data);
        }, (error) => {
            onError(error);
        });
    }
};

export default DashboardService;