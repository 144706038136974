import _ from "lodash";
import UserSessionService from "../services/UserSessionService";

let RolesUtils = {
    isCurrentUserAllowedTo: function (permission) {
        if(RolesUtils.isCurrentUserRoot()){
            return true;
        }
        let currentUserPermissions = UserSessionService.getCurrentUserPermissions();
        return currentUserPermissions.some(
            (userPermission) => {
                return (
                    (userPermission.name === permission.name) && (userPermission.category === permission.category)
                );
            }
        );
    },

    isCurrentUserRoot: function () {
        let currentUserPermissions = UserSessionService.getCurrentUserPermissions();
        return currentUserPermissions.some(
            (userPermission) => {
                return (userPermission.name === 'ROOT' && userPermission.category === 'PLATFORM')
            }
        );
    },

    filterCurrentUserPermissions: function (permissions) {
        if(RolesUtils.isCurrentUserRoot()){
            return permissions;
        }
        return _.intersectionWith(UserSessionService.getCurrentUserPermissions(), permissions, _.isEqual);
    },

    hasPermissionForCategory: function (category) {
        if(RolesUtils.isCurrentUserRoot()){
            return true;
        }
        let currentUserPermissions = UserSessionService.getCurrentUserPermissions();
        return currentUserPermissions.some(
            (userPermission) => {
                return (
                    userPermission.category === category
                );
            }
        );
    },

    hasPermissionForName: function (permissionName) {
        if(RolesUtils.isCurrentUserRoot()){
            return true;
        }
        let currentUserPermissions = UserSessionService.getCurrentUserPermissions();
        return currentUserPermissions.some(
            (userPermission) => {
                return (
                    userPermission.name === permissionName
                );
            }
        );
    }

};

export default RolesUtils;
