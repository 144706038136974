import axios from "axios";
import _ from "lodash";
import moment from "moment";
import UserSessionService from "../UserSessionService";

const AsyncInterfaceService = {
    getAllUnitTypes: function (searchData) {
        return new Promise((accept, reject) => {
            axios.get('/interfaces/unitTypes', {
                params: searchData
            }).then(response => {
                accept(response.data);
            }, reject);
        });
    },

    getAllDataTypes: function () {
        return new Promise((accept, reject) => {
            axios.get('/interfaces/dataTypes').then(response => {
                accept(response.data);
            }, reject);
        });
    },

    getAllCategoryTypes: function () {
        return new Promise((accept, reject) => {
            axios.get('/interfaces/categories').then(response => {
                accept(response.data);
            }, reject);
        });
    },

    getAllCategoriesForAnalytics: function () {
        return new Promise((accept, reject) => {
            axios.get('/interfaces/analytics/categories').then(response => {
                accept(response.data);
            }, reject);
        });
    },

    getInterfaceDefaultCategories: function () {
        return new Promise((accept, reject) => {
            axios.get('/interfaces/defaultCategories').then(response => {
                accept(response.data);
            }, error => {
                reject(error);
            });
        });
    },

    getAllInterfaceTypes: function () {
        return new Promise((accept, reject) => {
            axios.get('/interfaces/interfaceTypes').then(response => {
                accept(response.data);
            }, reject);
        });
    },

    requestReadingOnInterface: function (entityId, terminalId, interfaceId) {
        return new Promise((accept, reject) => {
            axios.get("/entities/" + entityId + "/terminals/" + terminalId +"/interfaces/" + interfaceId + "/reading").then(response => {
                accept(response.data);
            }, reject);
        });
    },

    getInterfaceReadings: function (search) {
        if(_.isNil(search.interfaceIds) || search.interfaceIds.length === 0 ||
            moment(search.fromDate).isAfter(moment(search.toDate))) {
            return new Promise((accept, reject) => {
                accept([{readings : []}], reject);
            });
        }

        return new Promise((accept, reject) => {
            axios.get("/widgets/chart_series_readings", {params: search})
                .then(response => {
                    accept(response.data);
                }, reject);
        });
    },

    getInterfacesById: function (interfaceIds) {
        return new Promise((accept, reject) => {
            let url = _.includes(window.location.href, "/public-dashboard") ?
                "/interfaces/public?ids=" + interfaceIds : "/interfaces?ids=" + interfaceIds;

            axios.get(url)
                .then(response => {
                    accept(response.data);
                }, reject);
        });
    },

    checkInterfacesHaveAlarmsByInterfaceIds: function (interfaceIds) {
        return new Promise((accept, reject) => {
            axios.get("/interfaces/check-interfaces-with-alarms?interfaceIds=" + interfaceIds)
                .then(response => {
                    accept(response.data);
                }, reject);
        });
    },

    loadInterfaceReadings: function (searchData) {
        return new Promise((accept, reject) => {
            axios.get('/interfaces/readings/search', {
                params: searchData
            }).then((response) => {
                accept(response.data);
            }, (error) => {
                reject(error);
            });
        });
    },

    getLastAggregatedReadings: function (interfaces) {
        return new Promise((accept, reject) => {
            let url = "/interfaces/readings"
            if(_.includes(window.location.href, "/public-dashboard")) {
                url += "/public"
            }
            axios.post(url + '/last-aggregate', {
                interfaceIds: interfaces,
                //Dashboard request use the timezone of user. Public dashboard request use the browser timezone.
                timezone: UserSessionService.getCurrentUserTimezone()
            }).then((response) => {
                accept(response.data);
            }, (error) => {
                reject(error);
            });
        });
    },

    getFistDailyReading: function (searchData) {
        return new Promise((accept, reject) => {
            let url = "/interfaces"
            if(_.includes(window.location.href, "/public-dashboard")) {
                url += "/public"
            }
            axios.get(url + 'first-last-reading', {
                params: searchData
            }).then((response) => {
                accept(response.data);
            }, (error) => {
                reject(error);
            });
        });
    }
};

export default AsyncInterfaceService;