import React from "react";
import "./mainLoading.scss";
import {ThemeContext} from "../../context/ThemeProvider";

const MainLoading = (props) => {
    const { images: { loginLogo } } = props.context;
    return (
        <div className="gray-bg main-div">
            <img alt="farm-logo" src={loginLogo}/>
        </div>
    )
};

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (props) => (
    <ThemeContext.Consumer>
        {(context) => <MainLoading {...props} context={context}/>}
    </ThemeContext.Consumer>
)