import {call, put, takeEvery} from 'redux-saga/effects';
import * as chartActionTypes from './actionTypes';
import * as actionTypes from './../../actionTypes';
import _ from "lodash";
import AsyncWidgetService from "../../../../services/async/AsyncWidgetService";
import WidgetService from "../../../../services/WidgetService";

function* fetchBatchInfoData({id, batchInfoOption, farmSelector, chartWindowRange, species, endTime, startTime}) {
    try {
        let entitiesFilter = !_.isNil(farmSelector) ? farmSelector : [];
        let speciesType = !_.isNil(species) ? species : null;
        const batchInfoData = yield call(getBachInfoDataRequest, batchInfoOption, entitiesFilter, speciesType, chartWindowRange, endTime, startTime);
        const finalData = {
            batchInfoData: batchInfoData
        };
        yield put({
            type: actionTypes.LOAD_WIDGET_DATA_SUCCESS,
            id: id,
            data: finalData
        })
    } catch (e) {
        yield put({
            id: id,
            type: actionTypes.LOAD_WIDGET_DATA_FAILED
        });
    }
}

const batchInfoSagas = [
    takeEvery(chartActionTypes.LOAD_BATCH_INFO_WIDGET_DATA_REQUEST, fetchBatchInfoData)
];

const getBachInfoDataRequest = (batchInfoOption, entitiesFilter, species, chartWindowRange, endTime, startTime) => {
    let data = [];
    switch (batchInfoOption) {
        case  WidgetService.widget.simpleBatchInfo.options.averageDailyGain:
            let speciesArray = [];
            speciesArray.push(species);
            return AsyncWidgetService.getAverageDailyGain(entitiesFilter, speciesArray, chartWindowRange, endTime, startTime);
        case WidgetService.widget.simpleBatchInfo.options.conversionIndex :
            speciesArray = [];
            speciesArray.push(species);
            return AsyncWidgetService.getConversionIndex(entitiesFilter, speciesArray, chartWindowRange, endTime, startTime);
        case WidgetService.widget.simpleBatchInfo.options.mortality:
            speciesArray = [];
            speciesArray.push(species);
            return AsyncWidgetService.getMortality(entitiesFilter, speciesArray, chartWindowRange, endTime, startTime);
        case WidgetService.widget.simpleBatchInfo.options.averageWeightAnimalExists:
            speciesArray = [];
            speciesArray.push(species);
            return AsyncWidgetService.getAverageWeightOfAnimalExitsInfo(entitiesFilter, speciesArray, chartWindowRange, endTime, startTime);
        default:
            console.error("Invalid SimpleBatchInfoWidget Configuration");
    }
    return data;
};

export {batchInfoSagas};