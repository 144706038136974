import _ from "lodash";
import UserLanguageService from "../services/UserLanguageService";

let LocalesUtils = {

    getLocaleString: function (nameObject) {
        let currentLocale = _.replace(_.toUpper(UserLanguageService.getCurrentLanguage()), "-", "_");
        let ucObject = Object.keys(nameObject).reduce( (acc, k) => ({
            ...acc,
            [k.toUpperCase()]: nameObject[k]
        }), {});

        return !_.isNil(ucObject[currentLocale])? ucObject[currentLocale] : ucObject["DEFAULT"]? ucObject["DEFAULT"] : ucObject["default"];
    },

    getLocaleStringWithValue: function (translationObject, value) {
        if(_.isNil(translationObject)) {
            return value.toString();
        }
        if(typeof(value) === typeof(true)) {
            return LocalesUtils.getLocaleString(translationObject[value.toString()]);

        } else if(typeof(value) === typeof(1)) {
            return LocalesUtils.getLocaleString(translationObject[Boolean(value).toString()]);
        }

        return LocalesUtils.getLocaleString(translationObject[value]);
    },

    filterLocalesOfObject: function (obj) {

        if(!_.isNil(obj.name)){
            obj.name = LocalesUtils.getLocaleString(obj.name);
        }
        if(!_.isNil(obj.description)){
            obj.description = LocalesUtils.getLocaleString(obj.description);
        }
        if(!_.isNil(obj.help)){
            obj.help = LocalesUtils.getLocaleString(obj.help);
        }
        if(!_.isNil(obj.properties)){
            obj.properties = LocalesUtils.getLocaleString(obj.properties);
        }
        if(!_.isNil(obj.category && !_.isNil(obj.category.name))){
            obj.category.name = LocalesUtils.getLocaleString(obj.category.name);
        }
        if(!_.isNil(obj.categoryType && !_.isNil(obj.categoryType.name))){
            obj.categoryType.name = LocalesUtils.getLocaleString(obj.categoryType.name);
        }
        if(_.isNil(obj.name)) {
            obj.name = obj.id;
        }
        return obj;
    },

};

export default LocalesUtils;