import React, {useContext, useEffect, useMemo} from "react";
import ReactBootstrapTable from 'react-bootstrap-table-next';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import paginationFactory, {
    PaginationListStandalone,
    PaginationProvider,
    PaginationTotalStandalone
} from 'react-bootstrap-table2-paginator';
import "./BootstrapTable.scss";
import {DropdownButton, MenuItem} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Loading from "../loading/Loading";
import useWindowSize from "../../hooks/useWindowSize";
import classnames from 'classnames';
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {TableListViewContext} from "../../context/TableListViewContext";
import {useHistory} from "react-router";

const BootstrapTable = (props) => {
    const { t } = useTranslation();
    let {
        loading, columns, handlerPagination, ignoreRowLink,
        data: { content, totalSize=0, pageable: {sortOrder, sortField, sizePerPage, page} },
        onRowClicked, openDetails,
        selectRow: {rowSelection , selectedRows, nonSelectableRows, handleOnSelect, handleOnSelectAll, hideSelectAll},
        paginationPanelCustom,
        filterTableColumns,
        detailsUrl,
        openDetailsWithoutIcon,
        detailsIcon
    } = props;
    const {isMobile} = useWindowSize();
    const history = useHistory();
    const hidePaginationBtn = totalSize <= sizePerPage;

    const { selectedFilter, setSelectedFilter } = useContext(TableListViewContext);

    useEffect(() => {
        setSelectedFilter(history?.location?.state?.selectedFilter)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const customTotal = (from, to, size) => {
        if(loading && hidePaginationBtn) {
            return <span>. . .</span>
        } else if(size < sizePerPage) {
            if(size === 1) {
                return <span>{ size + " " + t('generic.entry')} </span>
            } else {
                return <span>{ size + " " +t('generic.entries')} </span>
            }
        }

        return (
            <span className="react-bootstrap-table-pagination-total mr-10">
                <span className="size-per-page">
                    <DropdownButton
                        noCaret
                        title={sizePerPage}
                    >
                        <MenuItem key={"10"} onClick={()=>handleTableChange("sizePerPage", {page:page, sizePerPage:10, sortField:sortField , sortOrder:sortOrder})}>{10}</MenuItem>
                        <MenuItem key={"20"} onClick={()=>handleTableChange("sizePerPage", {page:page, sizePerPage:20, sortField:sortField , sortOrder:sortOrder})}>{20}</MenuItem>
                        <MenuItem key={"50"} onClick={()=>handleTableChange("sizePerPage", {page:page, sizePerPage:50, sortField:sortField , sortOrder:sortOrder})}>{50}</MenuItem>
                    </DropdownButton>
                </span>
                <span>{" " + t('generic.of') + " " + + size + " " + t('generic.entries') }</span>
            </span>
        )
    };

    const options = {
        custom: true,
        page: page+1,
        sizePerPage: sizePerPage,
        totalSize: totalSize,
        paginationSize: hidePaginationBtn ? -1 : 5,
        pageStartIndex: 1,
        alwaysShowAllBtns: false, // Always show next and previous button
        withFirstAndLast: false, // Hide the going to First and Last page button
        // hideSizePerPage: true, // Hide the sizePerPage dropdown always
        // hidePageListOnlyOnePage: true, // Hide the pagination list when only one page
        firstPageText: 'First',
        // prePageText: <FontAwesomeIcon icon={"caret-left"}/>,
        // nextPageText: <FontAwesomeIcon icon={"caret-right"}/>,
        prePageText: "<",
        nextPageText:  ">",
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '10', value: 10
        }, {
            text: '20', value: 20
        }, {
            text: '50', value: 50
        }]
    };

    const handleTableChange = (type, {page, sizePerPage, sortField, sortOrder}) => {
        if(page !== 0) {
            page = page-1
        }
        handlerPagination(page, sizePerPage, sortField, sortOrder);
    }

    const tableRowEvents = {
        onClick: (e, row, rowIndex) => {
            onRowClicked(row.id, row);
        }
    }

    const noDataIndication = useMemo(() => {
            return <div>{loading ? t('generic.table.loadingData') : t('generic.table.emptyTable')}</div>
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [loading]
    );

    if(filterTableColumns && filterTableColumns.length > 0) {
        columns = columns.filter(cd => filterTableColumns.includes(cd.dataField));
    }

    if(openDetails) {
        columns = [
            ...columns,
            {
                dataField: 'actions',
                text: '',
                headerAttrs: { width: 50 },
                attrs: { width: 50 },
                isDummyField: true,
                formatter: (_, row) => {
                    return <span
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            openDetails(row.id, row);
                        }}>
                        {
                            detailsUrl ? <Link to={{pathname: detailsUrl.replace("{id}", row.id), state: {selectedFilter: selectedFilter}}}>
                                <FontAwesomeIcon icon={detailsIcon} size={"lg"} className="tr-icon-color"/>
                            </Link> : <FontAwesomeIcon icon={detailsIcon} size={"lg"} className="tr-icon-color"/>
                        }
                    </span>
                }
            }
        ]
    }

    if(openDetailsWithoutIcon) {
        columns = columns.map(c => {
            return {
                ...c,
                linked: true,
                classes: classnames(c.classes, "table-column-fix-height"),
                formatter: (id, row) => {
                    return <div
                        onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            openDetailsWithoutIcon(row.id, row);
                        }}>
                        {c.formatter(id, row)}
                    </div>
                }
            }
        });
    }

    columns = columns.map(c => {
        if(c.linked || c.isDummyField || !detailsUrl || ignoreRowLink) return c;
        return {
            ...c,
            linked: true,
            classes: classnames(c.classes, "table-column-fix-height"),
            formatter: (id, row) => {
                return (
                    <Link to={{
                        pathname: detailsUrl.replace("{id}", row.id),
                        state: { selectedFilter: selectedFilter }
                    }} className="no-underline">
                        <div className="table-column-fix-td-div">
                            {c.formatter(id, row)}
                        </div>
                    </Link>
                )
            }
        }
    });

    const selectRow = () => {
        if(rowSelection) {
            return  {
                mode: 'checkbox',
                onSelect: handleOnSelect,
                onSelectAll: handleOnSelectAll,
                nonSelectable: nonSelectableRows,
                selected: selectedRows,
                hideSelectAll: hideSelectAll
            };
        } else {
            return {
                hideSelectColumn: true
            }
        }
    }

    return (
        <>
            {loading && <Loading className="form-loading"/>}
            <PaginationProvider
                pagination={ paginationFactory(options) }
            >
                {
                    ({
                         paginationProps,
                         paginationTableProps
                     }) => (
                        <>
                            <div className="pagination-panel">
                                <div>
                                    {paginationPanelCustom}
                                </div>
                                <div className="pagination-wrapper">
                                    <PaginationTotalStandalone
                                        { ...paginationProps }
                                    />
                                    <PaginationListStandalone
                                        { ...paginationProps }
                                    />
                                </div>
                            </div>
                            <div className={classnames("pd-15 fc-table", {"mobile" : isMobile})}>
                                <ReactBootstrapTable
                                    remote
                                    keyField="id"
                                    data={ content ? content : [] }
                                    columns={ columns }
                                    pagination={ paginationFactory(options) }
                                    onTableChange={ handleTableChange }
                                    sort={{
                                        dataField: sortField,
                                        order: sortOrder?.toLowerCase()
                                    }}
                                    defaultSorted={[{
                                        dataField: sortField,
                                        order: sortOrder?.toLowerCase()
                                    }]}
                                    noDataIndication={noDataIndication}
                                    striped={true}
                                    condensed={true}
                                    bordered={false}
                                    hover={true}
                                    selectRow={ selectRow() }
                                    rowEvents={ tableRowEvents }
                                    { ...paginationTableProps }
                                />
                            </div>
                        </>
                    )
                }
            </PaginationProvider>
        </>
    );

}

BootstrapTable.defaultProps= {
    loading: true,
    columns:[],
    data: {
        content: [],
        totalSize:0,
        pageable: {
            sortOrder: "desc",
            sortField: "id",
            sizePerPage:10,
            page: 0
        }
    },
    onRowClicked: () => {},
    detailsIcon: "eye",
    openDetails: undefined,
    handlerPagination: () => {},
    selectRow: {
        rowSelection: false ,
        selectedRows: [],
        nonSelectableRows: [],
        handleOnSelect: ()=>{},
        handleOnSelectAll: ()=>{},
        hideSelectAll: false
    },
    paginationPanelCustom: null,
    filterTableColumns: [],
    detailsUrl: null,
    ignoreRowLink: false,
    openDetailsWithoutIcon: undefined
}

export default BootstrapTable;