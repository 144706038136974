import {call, put, select, takeEvery} from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import {redux} from "../../index";
import _ from "lodash";
import EntityService from "../../services/EntityService";
import UserService from "../../services/UserService";
import Alerts from "../../utils/AlertUtils";
import axios from "axios";
import EntityUtils from "../../utils/EntityUtils";
import {getEnterprises} from "./selector";
import AsyncEntityService from "../../services/async/AsyncEntityService";

const controllerUrl = '/entities/';

function entityTypeChanges({ entityType }) {

    if(_.isNil(entityType)) {
        redux.dispatch({
            type: actionTypes.RESET_STATE,
        });
        return;
    }

    let possibleParentEntityTypeNames = EntityService.entityType[_.upperCase(entityType.name)].possibleParentTypes;

    EntityService.getActiveEntityList({entityTypeNames: possibleParentEntityTypeNames}, entities => {
        UserService.getActiveUsersList(userList => {

            userList = userList.map(user => {
                return {
                    'label': user.shortName,
                    'value': user.userId
                }
            });

            entities = EntityUtils.getSectorsWithFarmName(entities)

            redux.dispatch({
                type: actionTypes.ENTITY_TYPE_CHANGE_SUCCESS,
                data: {
                    entityType: entityType,
                    users: userList,
                    entities: entities,
                    tags: []
                },
                options: {
                    isEnterprise : _.upperCase(entityType.name) === EntityService.entityType.ENTERPRISE.name,
                    isFarm : _.upperCase(entityType.name) === EntityService.entityType.FARM.name,
                    isSector: _.upperCase(entityType.name) === EntityService.entityType.SECTOR.name,
                    enabledBillingInfo : entityType.group === EntityService.entityTypeGroup.TENANT,
                    isPhysicalEntity: entityType.group === EntityService.entityTypeGroup.PHYSICAL
                }
            });
        },() => Alerts.applicationError(), true);
    }, () => Alerts.applicationError());
}

function entityParentChange({ parent }) {
    if(_.isNil(parent) || _.isEmpty(parent)) {
        redux.dispatch({
            type: actionTypes.PARENT_CHANGE_SUCCESS,
            data: {
                tags: [],
                parent: {}
            }
        });

    } else {
        EntityService.getTagsList(parent.id, true, tags => {
            let list = [];
            let sortedTags = _.sortBy(tags, tag => {
                return _.startCase(tag['name']);
            });
            sortedTags.forEach(tag => {
                list.push({
                    'label': tag.name,
                    'value': tag.id
                });
            });

            redux.dispatch({
                type: actionTypes.PARENT_CHANGE_SUCCESS,
                data: {
                    tags: list,
                    parent: parent
                }
            });
        });
    }
}

function handleTagsSubmit({tag, parent}){
    tag = _.trim(tag);

    if(!_.isEmpty(tag)) {
        redux.dispatch({
            type: actionTypes.UPDATE_TAGS_STATE,
            data: {
                isLoading: true,
                tagCreateFormVisible: null,
                tagCreationSuccess: null
            }
        });
        EntityService.createTag( parent.id, {value: tag}, response => {
                if(response && response.deferredStatus) {
                    redux.dispatch({
                        type: actionTypes.UPDATE_TAGS_STATE,
                        data: {
                            isLoading: false,
                            tagCreateFormVisible: false,
                            tagCreationSuccess: EntityService.tagCreationResult.DEFERRED
                        }
                    });
                } else {
                    EntityService.getTagsList( parent.id, true,  tags => {
                            redux.dispatch({
                                type: actionTypes.UPDATE_TAGS_STATE,
                                data: {
                                    isLoading: false,
                                    tagCreateFormVisible: false,
                                    tagCreationSuccess: EntityService.tagCreationResult.SUCCESS
                                }
                            });

                            let list = getTagsOptions(tags);
                            redux.dispatch({
                                type: actionTypes.SUBMIT_TAGS_SUCCESS,
                                data: {
                                    tags: list
                                }
                            });

                        },
                        () => {}
                    );
                }
            },
            () => {
                redux.dispatch({
                    type: actionTypes.UPDATE_TAGS_STATE,
                    data: {
                        isLoading: false,
                        tagCreateFormVisible: false,
                        tagCreationSuccess: EntityService.tagCreationResult.ERROR
                    }
                });
            }
        )
    } else {
    }
}

function getTagsOptions(tags) {
    let list = [];
    if(!_.isEmpty(tags)) {
        let sortedTags = _.sortBy(tags, tag => {
            return _.startCase(tag['name']);
        });
        sortedTags.forEach(tag => {
            list.push({
                'label': tag.name,
                'value': tag.id
            });
        });
    }
    return list;
}

function* getEnterpriseListRequest() {
    try {
        let enterpriseList = yield select(getEnterprises);

        if(_.isNil(enterpriseList) || _.isEmpty(enterpriseList)) {
            enterpriseList = yield call(getEnterpriseListCall);
        }

        yield put ({
            type: actionTypes.GET_ENTERPRISE_LIST_SUCCESS,
            payload: enterpriseList
        })
    } catch (e) {
        yield put ({
            type: actionTypes.GET_ENTERPRISE_LIST_FAILED,
            payload: e
        })
    }
}

const getEnterpriseListCall = () => new Promise ((resolve, reject) => {
    axios.get(controllerUrl, {params: {
        entityTypeNames: "ENTERPRISE",
        entityStatus: "ACTIVE"
    }}).then(
        response => resolve(response.data),
        error => reject(error)
    )
});

function* getFarmListRequest({enterpriseId}) {
    try {
        const farmList = yield call(getFarmListCall, enterpriseId);

        yield put ({
            type: actionTypes.GET_FARM_LIST_SUCCESS,
            payload: farmList
        })
    } catch (e) {
        yield put ({
            type: actionTypes.GET_FARM_LIST_FAILED,
            payload: e
        })
    }
}

function* getFarmAndSectorListRequest({enterpriseId}) {
    try {
        const farmList = yield call(getFarmAndSectorListCall, enterpriseId);

        yield put ({
            type: actionTypes.GET_FARM_AND_SECTOR_LIST_SUCCESS,
            payload: farmList
        })
    } catch (e) {
        yield put ({
            type: actionTypes.GET_FARM_AND_SECTOR_LIST_FAILED,
            payload: e
        })
    }
}

const getFarmListCall = (enterpriseId) => new Promise ((resolve, reject) => {
    axios.get(controllerUrl + enterpriseId + "/subentities/", {params: {
            entityTypeNames: "FARM",
            entityStatus: "ACTIVE"
        }}).then(
        response => resolve(response.data),
        error => reject(error)
    )
});

const getFarmAndSectorListCall = (enterpriseId) => new Promise ((resolve, reject) => {
    axios.get(controllerUrl + enterpriseId + "/subentities/", {params: {
            entityTypeNames: [EntityService.entityType.FARM.name, EntityService.entityType.SECTOR.name],
            entityStatus: "ACTIVE"
        }}).then(
        response => resolve(EntityUtils.getSectorsWithFarmName(response.data)),
        error => reject(error)
    )
});

function* getEntityDefaultInterfaces({entityId}) {
    try {
        const result = yield call(() => AsyncEntityService.getEntityDefaultInterfaces(entityId));
        yield put ({
            type: actionTypes.GET_ENTITY_DEFAULT_INTERFACES_SUCCESS,
            payload: result,
            entityId: entityId
        })
    } catch (e) {
        yield put ({
            type: actionTypes.GET_ENTITY_DEFAULT_INTERFACES_FAILED,
            payload: e,
            entityId: entityId
        })
    }
}

const entitiesSagas = [
    takeEvery(actionTypes.ENTITY_TYPE_CHANGE_REQUEST, entityTypeChanges),
    takeEvery(actionTypes.PARENT_CHANGE_REQUEST, entityParentChange),
    takeEvery(actionTypes.SUBMIT_TAGS_REQUEST, handleTagsSubmit),
    takeEvery(actionTypes.GET_ENTERPRISE_LIST_REQUEST, getEnterpriseListRequest),
    takeEvery(actionTypes.GET_FARM_LIST_REQUEST, getFarmListRequest),
    takeEvery(actionTypes.GET_FARM_AND_SECTOR_LIST_REQUEST, getFarmAndSectorListRequest),
    takeEvery(actionTypes.GET_ENTITY_DEFAULT_INTERFACES_REQUEST, getEntityDefaultInterfaces)
];

export { entitiesSagas };