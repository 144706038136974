import React, {useEffect, useState} from 'react'
import classNames from "classnames";
import ImgButton from "../buttons/ImgButton";
import trash from "../../img/trash/trash.svg";
import trashOnHover from "../../img/trash/trash_on_over.svg";
import LabeledField from "../fields/labeled/LabeledField";
import {t} from "../../utils/Translation";
import _ from "lodash";
import RuleService from "../../services/RuleService";
import LocalesUtils from "../../utils/LocalesUtils";
import SwitchField from "../fields/switch/SwitchField";
import uuid from "../../utils/uuid";
import Tooltip from "../tooltip/Tooltip";
import IconButton from "../buttons/IconButton";
import addIcon from "../../img/add/add-widget-button-white.svg";
import ClickWrapper from "../hoc/ClickWrapper";
import {useHistory} from "react-router";

function GlobalRuleLine({isView, isCyclical, globalRule, cycleStart, cycleEnd, line, actionType, deleteLine,
                            handleUpdate, selectedSector, showAllInterfaces, updateInterfaceList}) {

    const [cycleDays, setCycleDays] = useState(line.cycle || [])
    const [interfaceDataType, setInterfaceDataType] = useState(line.interfaceDataType || null)
    const [logicalOperations, setLogicalOperations] = useState(line.logicOperations || [])
    const [checkedLabelForBooleanAction, setCheckedLabelForBooleanAction] = useState(null)
    const [uncheckedLabelForBooleanAction, setUncheckedLabelForBooleanAction] = useState(null)
    const [booleanActionValue, setBooleanActionValue] = useState(null)
    const history = useHistory();

    useEffect(() => {
        if (!_.isNil(line.action)) {
            let value = line.action;
            if (interfaceDataType === RuleService.dataType.BOOLEAN) {
                value = value === 'true';
                if(!_.isEmpty(line.interfaceList)){
                    let interfaceObj = _.find(line.interfaceList, iface => {
                        return (globalRule && iface.id === line.interfaceId) ||
                            (!globalRule && iface.interfaceId ===line.interfaceId);
                    });

                    updateBooleanFieldLabels(interfaceObj, value);
                }
            }
        }
        // eslint-disable-next-line
    }, []);

    const addNewCycleItem = () => {
        let id = uuid();
        const cycle = [...cycleDays, {
            id: id,
            date: null,
            logicOperator: null,
            action: null
        }]

        setCycleDays(cycle)

        line.cycle = cycle;
        handleUpdate(line)
    }

    const deleteCycleItem = (dayId) => {
        const cycleAfterDelete = cycleDays.filter(cycleDay => cycleDay.id !== dayId)
        setCycleDays(cycleAfterDelete);
        line.cycle = cycleAfterDelete;
        handleUpdate(line)
    }

    const updateLine = (field, value) => {
        let updatedLine = line;

        if(field === 'terminalId'){
            handleTerminalChange(value)
            value = value.id
        }

        if(field === 'interfaceId') {
            handleInterfaceChange(value, actionType, line)
            value = value.id
        }

        if(field === 'action'){
            let interfaceObj = _.find(line.interfaceList, iface => {
                return (globalRule && iface.id === line.interfaceId) ||
                    (!globalRule && iface.interfaceId ===line.interfaceId);
            });

            updateBooleanFieldLabels(interfaceObj, value);
        }

        updatedLine[field] = value;

        handleUpdate(updatedLine)
    }

    const updateCycle = (day, field, value) => {
        if(field === 'day'){
            day.date = cycleStart.clone().add(value, 'days')
        } else if (field === 'date'){
            day.day = Math.abs(value.diff(cycleStart.clone(), 'days'))
        } else if(field === 'logicOperator' && day.action === null){
            day.action = line.action
        } else if(field === 'action'){
            let interfaceObj = _.find(line.interfaceList, iface => {
                return (globalRule && iface.id === line.interfaceId) ||
                    (!globalRule && iface.interfaceId ===line.interfaceId);
            });

            day.booleanField = updateBooleanFieldLabels(interfaceObj, value);
        }

        day[field] = value;
        const updatedCycle = cycleDays.map(d => {
            if(day.id === d.id){
                day[field] = value
                return day
            } else {
                return d
            }
        })

        setCycleDays(updatedCycle);
        line.cycle = updatedCycle;
        handleUpdate(line)
    }

    const handleTerminalChange = (terminal) => {
        if(!_.isNil(terminal)){
            RuleService.getInterfacesFromTerminal(terminal, interfaces => {
                if(!_.isEmpty(selectedSector) && !showAllInterfaces) {
                    interfaces = _.filter(interfaces, iface => {
                        return iface.entity.id === selectedSector.id;
                    })
                }
                updateInterfaceList(interfaces);
            });
        } else {
            updateInterfaceList([]);
        }
    }

    function setLabelsForBooleanField(checkedLabel, uncheckedLabel, booleanActionValue) {
        setCheckedLabelForBooleanAction(checkedLabel)
        setUncheckedLabelForBooleanAction(uncheckedLabel)
        setBooleanActionValue(booleanActionValue)
    }

    const handleInterfaceChange = (interf, actionType, lineData) => {
        if(!_.isNil(interf) && !_.isNil(interf.terminalInterfaceDataType)){
            let dataType = interf.terminalInterfaceDataType;
            setInterfaceDataType(dataType)
            if(actionType === RuleService.actionType.INPUT) {
                RuleService.getLogicOperationsForDataType(dataType, [RuleService.logicOperator.BETWEEN], operations => {
                    if (dataType === RuleService.dataType.BOOLEAN) {
                        setLogicalOperations([RuleService.getObjectFor(RuleService.logicOperator.EQUAL, t('generic.logicOperations.equal'))], () => {
                            lineData.logicOperator = RuleService.logicOperator.EQUAL
                            if(interf.interfaceUnitType && interf.interfaceUnitType.properties && interf.interfaceUnitType.properties.name) {
                                let name = JSON.parse(interf.interfaceUnitType.properties.name);
                                setLabelsForBooleanField(LocalesUtils.getLocaleString(name['true']), LocalesUtils.getLocaleString(name['false']));
                            } else {
                                setLabelsForBooleanField(null, null);
                            }
                        });
                    } else {
                        setLogicalOperations(operations);
                    }
                    if (!_.isNil(lineData)) {
                        if (!_.isNil(lineData.action)) {
                            let value = lineData.action;
                            if (dataType === RuleService.dataType.BOOLEAN) {
                                value = value === 'true';
                            }
                            lineData.action = value;
                        }
                    }
                }, () => {
                    throw new Error('It was not possible to fetch the logical operations for data type ' + dataType);
                });
            }
            if(!_.isNil(lineData) && !_.isNil(lineData.action)) {
                let value = lineData.action;
                if (dataType === RuleService.dataType.BOOLEAN) {
                    value = value === 'true';
                }
                lineData.action = value;
            }
            updateBooleanFieldLabels(interf);

            if (_.isNil(lineData.action)){
                lineData.action = dataType === RuleService.dataType.BOOLEAN ? true : null
            }
        } else {
            setInterfaceDataType(null);
            setLogicalOperations([]);
        }

        handleUpdate(lineData)
    }

    function updateBooleanFieldLabels(interf, booleanActionValue) {
        if (interf && interf.terminalInterfaceDataType === RuleService.dataType.BOOLEAN) {
            if(interf.interfaceUnitType && interf.interfaceUnitType.properties && interf.interfaceUnitType.properties.name) {
                let name = JSON.parse(interf.interfaceUnitType.properties.name);
                setLabelsForBooleanField(LocalesUtils.getLocaleString(name['true']), LocalesUtils.getLocaleString(name['false']), booleanActionValue);
            } else {
                setLabelsForBooleanField(null, null, booleanActionValue);
            }
        } else {
            setLabelsForBooleanField(null, null, booleanActionValue);
        }

        return booleanActionValue;
    }

    const buildTerminalField = () => {
        return (
            <LabeledField
                fieldType="dropdown"
                fieldLabel={t('generic.dropgroup.headers.terminal')}
                width="12"
                dataAttribute="displayName"
                isRequired={true}
                hasRequiredAsterisk={true}
                bottomMargin={false}
                isView={isView}
                className={"p-left-0"}
                selectData={line.terminalList}
                value={line.terminalId}
                onFieldChange={terminal => updateLine("terminalId", terminal)}
            />
        )
    }

    const buildInterfaceField = () => {
        return (
            <ClickWrapper onClickHandler={isView ? () => handleInterfaceClick() : null}>
                <LabeledField
                    fieldType="dropdown"
                    width="12"
                    fieldLabel={t('generic.dropgroup.headers.interface')}
                    dataAttribute="displayName"
                    dataId={globalRule ? "id" : "interfaceId"}
                    bottomMargin={false}
                    isRequired={true}
                    className={"p-left-0"}
                    hasRequiredAsterisk={true}
                    isView={isView}
                    value={line.interfaceId}
                    selectData={filterByActionType(line.interfaceList, actionType)}
                    onFieldChange={interf => updateLine("interfaceId", interf)}
                />
            </ClickWrapper>
        );
    }

    const handleInterfaceClick = () => {
        if(line?.terminalId && line?.interfaceId && line?.interfaceList){
            const _interface = line.interfaceList.find(inter => inter.id === line.interfaceId)

            if(_interface){
                history.push(`/system/terminals/details/${line.terminalId}`, {
                    historyBack: true,
                    hideFilters: true,
                    interfaceSearchName: _interface.name
                });
            }

        }
    }

    const buildCycleDateField = (day) => {
        return (
            <div className="flex flex-wrap">
                <LabeledField
                    forceNewPropValue={true}
                    fieldLabel={t('rules.globalRule.fields.cycleDay.label')}
                    fieldPlaceHolder={t('rules.globalRule.fields.cycleDay.placeholder')}
                    bottomMargin={false}
                    isRequired={true}
                    fieldType="number"
                    className={"p-left-0 col-xs-12"}
                    width='6'
                    min={"0"}
                    isView={isView}
                    value={day?.day?.toString()}
                    onFieldChange={value => updateCycle(day, "day", value)}
                />
                <LabeledField
                    forceNewPropValue={true}
                    fieldLabel={t('rules.globalRule.fields.cycleDate.label')}
                    fieldPlaceHolder={t('rules.globalRule.fields.cycleDate.placeholder')}
                    bottomMargin={false}
                    fieldType="dateTime"
                    className={"p-left-0 col-xs-12"}
                    width='6'
                    isView={isView}
                    hasDate={true}
                    hasTime={true}
                    value={day?.date}
                    onFieldChange={value => updateCycle(day, "date", value)}
                />
            </div>
        )
    }

    const buildLogicalOperatorField = (day) => {
        return (
            <LabeledField
                fieldType="dropdown"
                fieldLabel={t('generic.dropgroup.headers.logicOperator')}
                width="12"
                bottomMargin={false}
                isRequired={true}
                className={"p-left-0"}
                hasRequiredAsterisk={true}
                isView={isView}
                selectData={logicalOperations}
                value={day ? day.logicOperator : line.logicOperator}
                onFieldChange={logicOperator => isCyclical ? updateCycle(day, "logicOperator", logicOperator?.id) :
                    updateLine("logicOperator", logicOperator?.id)}
            />
        )
    }

    const buildActionField = (day) => {
        let dataType = interfaceDataType;
        let field = null;
        if(!_.isNil(dataType)) {
            switch (dataType) {
                case RuleService.dataType.DECIMAL:
                    field = (
                        <LabeledField
                            forceNewPropValue={true}
                            fieldType="text"
                            fieldLabel={t('generic.dropgroup.headers.action')}
                            fieldPlaceHolder={t('generic.dropgroup.fields.decimalPlaceholder')}
                            bottomMargin={false}
                            width="12"
                            isRequired={true}
                            disabled={false}
                            className={"p-left-0 action-field-height"}
                            hasRequiredAsterisk={true}
                            isView={isView}
                            value={day ? day.action : line.action}
                            onFieldChange={action => updateAction(action, day)}
                        />
                    );
                    break;
                case RuleService.dataType.STRING:
                    field = (
                        <LabeledField
                            forceNewPropValue={true}
                            fieldType="text"
                            fieldLabel={t('generic.dropgroup.headers.action')}
                            fieldPlaceHolder={t('generic.dropgroup.fields.stringPlaceholder')}
                            bottomMargin={false}
                            isRequired={true}
                            hasRequiredAsterisk={true}
                            width="12"
                            className={"p-left-0"}
                            disabled={false}
                            isView={isView}
                            value={day ? day.action : line.action}
                            onFieldChange={action => updateAction(action, day)}
                        />
                    );
                    break;
                case RuleService.dataType.BOOLEAN:
                    field = (
                        <SwitchField
                            forceNewPropValue={true}
                            bottomMargin={false}
                            fieldLabel={t('generic.dropgroup.headers.action')}
                            width='12'
                            className={"p-left-0"}
                            isView={isView}
                            checkedLabel={checkedLabelForBooleanAction}
                            uncheckedLabel={uncheckedLabelForBooleanAction}
                            isChecked={isCyclical ? day.booleanField : booleanActionValue}
                            onFieldChange={action => updateAction(action, day)}
                        />
                    );
                    break;
                case RuleService.dataType.INTEGER:
                    field = (
                        <LabeledField
                            forceNewPropValue={true}
                            fieldType="text"
                            fieldLabel={t('generic.dropgroup.headers.action')}
                            fieldPlaceHolder={t('generic.dropgroup.fields.integerPlaceholder')}
                            bottomMargin={false}
                            isRequired={true}
                            hasRequiredAsterisk={true}
                            width="12"
                            className={"p-left-0"}
                            disabled={false}
                            isView={isView}
                            value={day ? day.action : line.action}
                            onFieldChange={action => updateAction(action, day)}
                        />
                    );
                    break;
                default:
                    throw new Error("Unknown interface data type: " + dataType);
            }
        } else {
            field = <LabeledField
                forceNewPropValue={true}
                fieldType="text"
                fieldLabel={t('generic.dropgroup.headers.action')}
                bottomMargin={false}
                isRequired={true}
                hasRequiredAsterisk={true}
                width="12"
                disabled={true}
                className={"p-left-0"}
                isView={isView}
                value={day ? day.action : line.action}
                onFieldChange={action => updateAction(action, day)}
            />
        }
        return field;
    }

    const updateAction = (action, day) => {
        isCyclical ? updateCycle(day, "action", action) : updateLine("action", action)
    }

    const buildRemoveLineButton = () => {
        if(!isView) {
            return <ImgButton
                alt = "delete"
                image={trash}
                onHoverImage={trashOnHover}
                handleIconClick={() => deleteLine(line.id)}
            />
        } else {
            return null;
        }
    }

    const buildRemoveCycleButton = (day) => {
        if(!isView) {
            return(
                <span
                    className={"cursor-pointer"}
                    onClick={(e) => {
                    e.stopPropagation();
                    deleteCycleItem(day.id)
                }}>
                    <Tooltip content={t("rules.globalRule.removeCycleDay")}>
                        <i className="fa fa-1x fa-times-circle fa-fw c-red"
                           aria-hidden="true"/>
                    </Tooltip>
                </span>
            )
        } else {
            return null;
        }
    }

    const filterByActionType = (interfaceList, actionType) => {
        return  _.filter(interfaceList, iface => {
            return iface.terminalInterfaceType === getSimpleNameOfActionType(actionType) || iface.terminalInterfaceType === 'IN_OUT';
        });
    }

    const getSimpleNameOfActionType = (actionType) => {
        return actionType === 'OUTPUT' ? 'OUT' : 'IN';
    }

    const isMobile = window.matchMedia("(min-width: 200px) and (max-width: 1200px)").matches;

    return (
        <div style={{borderBottom: "1px solid #f1f1f1"}}>
            <div className="flex mb-15 flex-wrap">
                <div className={`col-lg-${isCyclical ? '4' : '3'} col-xs-12 pd-r-0 pd-b-15`}>
                    {buildTerminalField()}
                </div>
                <div className={`col-lg-${isCyclical ? '4' : '3'} col-xs-12 pd-r-0 pd-b-15`}>
                    {buildInterfaceField()}
                </div>
                {!isCyclical && (
                    <>
                        <div className='col-lg-3 col-xs-12 pd-r-0 pd-b-15'>
                            {buildLogicalOperatorField()}
                        </div>
                        <div className='col-lg-1 col-xs-12 pd-r-0 pd-b-15' style={{minWidth: '170px'}}>
                            {buildActionField()}
                        </div>
                    </>
                )}

                <div className={classNames('col-lg-1 col-xs-12 flex p-left-0', {"justify-center pd-b-10": isMobile})}>
                    {buildRemoveLineButton()}
                </div>
            </div>
            {isCyclical && (
                <div>
                    {!isView && (
                        <div className={classNames('mb-15', {"flex justify-center" : isMobile})}>
                            <IconButton
                                onClick={() => addNewCycleItem()}
                                isMobile={isMobile}
                                icon={addIcon}
                                disabled={cycleStart === null || cycleEnd === null}
                                label={t("rules.globalRule.addCycleDay")}
                            />
                        </div>
                    )}
                    {isView && (
                        <div className="form-header flex-column">
                            <h3>{t('rules.globalRule.cycle')}</h3>
                        </div>
                    )}
                    {cycleDays.map((day) => (
                        <div className="flex flex-1 align-items-end flex-wrap" key={day.id}>
                            <div className='col-lg-4 col-xs-12 pd-r-0 pd-b-15'>
                                {buildCycleDateField(day)}
                            </div>
                            <div className='col-lg-3 col-xs-12 pd-r-0 pd-b-15'>
                                {buildLogicalOperatorField(day)}
                            </div>
                            <div className='col-lg-1 col-xs-12 pd-r-0 pd-b-15' style={{minWidth: '170px'}}>
                                {buildActionField(day)}
                            </div>
                            <div className={classNames('col-lg-2 col-xs-12 pd-r-0 pd-b-15 flex p-left-0', {"justify-center pd-b-10": isMobile})}>
                                {buildRemoveCycleButton(day)}
                            </div>
                        </div>
                    ))}
                </div>)}
        </div>
    )
}

const GlobalRuleLines = ({lines, isCyclical, isView, globalRule, cycleStart, cycleEnd, actionType, deleteLine, onUpdateLines,
                             mapInterfaceList, selectedSector, showAllInterfaces}) => {
    const updateLines = (lineToUpdate) => {
        onUpdateLines(lines.map(line => {
            if(line.id === lineToUpdate.id){
                return lineToUpdate
            } else {
                return  line;
            }
        }));
    }

    return <>
        {lines.map((line, idx) => (
            <GlobalRuleLine key={idx}
                            isView={isView}
                            globalRule={globalRule}
                            isCyclical={isCyclical}
                            actionType={actionType}
                            line={line}
                            cycleStart={cycleStart}
                            cycleEnd={cycleEnd}
                            deleteLine={deleteLine}
                            showAllInterfaces={showAllInterfaces}
                            selectedSector={selectedSector}
                            updateInterfaceList={(interfaces) => mapInterfaceList(interfaces, line)}
                            handleUpdate={(updatedLine) => updateLines(updatedLine)}/>
        ))}

    </>
}

export default GlobalRuleLines