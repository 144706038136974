import React from 'react';
import _ from "lodash";
import GenericInput from "../../fields/input/GenericInput";

class InputContent extends React.Component {

    render() {
        return <div>
            {createFieldsFromInputs (this.props)}
        </div>;
    }

}

function createFieldsFromInputs (props) {
    let content = [];
    let {
        inputs,
        viewMode,
        editMode,
        parentForm
    } = props;

    if(_.isNil(viewMode)) {
        viewMode = parentForm.state.isView? parentForm.state.isView : false
    }
    if(_.isNil(editMode)) {
        editMode = parentForm.state.editMode? parentForm.state.editMode : false
    }

    if(!_.isNil(inputs) && !_.isEmpty(inputs)) {
        _.forOwn(inputs, (input, key) => {
            content.push(
                <GenericInput
                    key={input.id}
                    parentForm={props.parentForm}
                    globalRule={props.global}
                    input={input}
                    tabNumber={props.tabNumber}
                    editMode={editMode}
                    viewMode={viewMode}
                    selectedEntity={props.selectedEntity}
                    selectedTerminal={props.selectedTerminal}
                    selectedSector={props.selectedSector}
                    selectedRule={props.selectedRule}
                />
            );
        });
    }
    return content;
}

export default InputContent;