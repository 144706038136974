import React from "react";
import _ from "lodash";
import {t} from "../../../utils/Translation";
import EventService from "./../../../services/EventService";
import UserService from "./../../../services/UserService";
import {config} from "../../../config";
import DownloadService from "../../../services/DownloadService";
import Alerts from "./../../../utils/AlertUtils";
import ProductionUtils from "../../../utils/ProductionUtils";
import moment from "moment";
import EntityService from "../../../services/EntityService";
import EntityUtils from "../../../utils/EntityUtils";
import RolesUtils from "../../../utils/RolesUtils";
import {permissions} from "../../../utils/permissions";
import EventDetailsForm from "./EventDetailsForm";
import AsyncEntityService from "../../../services/async/AsyncEntityService";
import AsyncBatchService from "../../../services/async/AsyncProductionBatchService";
import __ from "../../../utils/EnhancedLodash";
import BatchService from "../../../services/BatchService";
import UserSessionService from "../../../services/UserSessionService";
import AsyncEventService from "../../../services/async/AsyncEventService";

class EventDetailsFormContainer extends React.Component {
    constructor(props) {
        super(props);
        this.dropzoneRef = React.createRef();
        this.fieldRefs ={};

        let helpdesk = !__.isNilOrEmpty(props.helpdesk) ? props.helpdesk : false;
        const isBatch = !__.isNilOrEmpty(props.isBatch) ? props.isBatch : false;
        let _status = !_.isNil(props.match.params.batchId) ? EventService.eventStatus.closed : undefined;
        if(helpdesk) {
            _status = EventService.eventStatus.open;
        }
        const isCreate = _.isNil(props.match.params.eventId);

        this.state = {
            helpdesk: helpdesk,
            isBatch: isBatch,
            responsibleList: [],

            batchId: props.match.params.batchId,
            batchDetails: {},
            hasParent: !_.isNil(props.match.params.batchId),

            isView: !_.isNil(props.match.params.eventId),
            isEdit: false,
            isCreate: isCreate,
            eventId: props.match.params.eventId,

            remoteData: {},

            formHasErrors: false,

            data: {
                id: undefined,
                openDate: {
                    value: moment().utc(),
                    hasTime: true,
                    hasDate: true,
                    hasSeconds: false
                },
                title: {
                    value: undefined
                },
                enterpriseId: {
                    data : [],
                    value: undefined,
                    error: null
                },
                createdBy : {
                    value: undefined,
                    data: []
                },
                cause: {
                    data : [],
                    value: helpdesk && isCreate ? 'UNKNOWN' : undefined,
                    error: null
                },
                status: {
                    value: _status
                },
                entityId: {
                    data : [],
                    value: undefined,
                    error: null
                },
                sectorId: {
                    data : [],
                    value: undefined,
                    error: null,
                },
                batchId: {
                    data : [],
                    value: undefined,
                    error: null,
                    visible: RolesUtils.hasPermissionForName(permissions.batches.read)
                },
                severityLevel: {
                    data : [],
                    value: !_.isNil(props.match.params.batchId) ? 'LOW' : undefined,
                    error: null
                },
                description: {
                    data : [],
                    value: undefined,
                    error: null
                },
                category: {
                    data : helpdesk ? EventService.getHelpdeskCategories() : undefined,
                    value: undefined,
                    error: null
                },
                additionalNotes: {
                    data : [],
                    value: undefined,
                    error: null
                },
                origin: {
                    isAutomatic: false
                },
                changes: {
                    value: []
                }
            },

            loading: true
        }
    }

    componentDidMount() {
        const {
            eventId,
            isCreate,
            hasParent,
            batchId,
            helpdesk
        } = this.state;

        if(isCreate) {
            if(!hasParent) {
                initCreateForm(this, () => {
                    if(helpdesk) {
                        updateHelpdeskForm(this);
                    }
                });
            } else {
                initCreateChildrenForm(this);
            }
            return;
        }

        EventService.getEventDetails(eventId, details => {
            EventService.getEventFiles(eventId, files => {
                initCreateForm(this,  async () => setDataIntoForm(this, details, files, hasParent, batchId));
                fetchResponsibleUsersList(this, details.entity)
            }, () => Alerts.applicationError().show());
        }, () => Alerts.applicationError().show());

    }

    handleFieldChanged = async (value, id) => {
        let formData = {
            ...this.state.data,
            [id]: {
                ...this.state.data[id],
                value: value
            }
        };

        switch (id) {
            case "enterpriseId" :
                formData = await enterpriseHandler(formData, value);
                break;
            case "entityId":
                if(formData.batchId.visible && !this.state.helpdesk) {
                    formData = await farmSectorHandler(formData, value);
                }
                if(value){
                    await fetchResponsibleUsersList(this, value)
                    notifyResponsibleCheckboxEnable(this, value)
                    if(this.state.helpdesk){
                        formData = await fetchFarmSectors(formData, value);
                    }
                }
                break;
            default:
        }

        this.setState({
            data: formData
        });
    };

    handleOnFieldBlur = (key) => {
        let {
            data,
            hasParent
        } =  this.state;
        validateField(key,  data[key], data, hasParent);
        this.setState({
            data: data
        });
    };

    onFormSubmit = (createNewAfterSave) => {
        const {
            isEdit,
            isCreate,
            eventId
        } = this.state;

        const isValid = validateFormValues(this);

        if(isValid) {
            const formValues = getFormValues(this);
            if(isCreate) {
                handleCreateEventSubmit(this, formValues, createNewAfterSave)
            } else if(isEdit) {
                handleEditEventSubmit(this, eventId, formValues);
            }
        }
    };

    onBack = () => {
        const {
            hasParent,
            batchDetails,
            helpdesk
        } = this.state;

        if(this.props.location.moreProps && this.props.location.moreProps.historyBack) {
            this.props.history.goBack();
        }

        if(helpdesk) {
            this.props.history.push('/helpdesk', {...this.props?.location?.state});
            return
        }

        if(hasParent) {
            this.props.history.push({
                pathname: '/production/batches/' + batchDetails.id + '/details',
                search: '?tab=events'
            });
        } else {
            this.props.history.push('/events', {...this.props?.location?.state});
        }
    };

    onEdit = () => {
        const {
            isEdit,
            isView,
            remoteData,
            files,
            data
        } = this.state;

        this.setState({
            isEdit: !isEdit,
            isView: !isView
        }, () => {
            if(!isView && isEdit) {
                setDataIntoForm(this, remoteData, files);
            } else {
                this.setState({
                    data: {
                        ...data,
                        additionalNotes: {
                            ...data.additionalNotes,
                            value: ""
                        }
                    },
                }, async () => {
                    await updateActions(this, remoteData);
                });

            }
        });
    };

    onRuleDetailClick = (id) => {
        this.props.history.push('/rules/' + id + '/details', {eventId: this.state.eventId});
    }

    exportFile = (fileName) => {
        const {
            remoteData : {
                id
            }
        } = this.state;
        handleExportFile(id, fileName);
    };

    onDelete = () => {
        handleDelete(this);
    }

    render() {

        const {
            isView,
            isEdit,
            isCreate,
            data,
            hasParent,
            helpdesk,
            loading,
            eventId
        } = this.state;

        const isAutomatic = data.origin.isAutomatic;
        let actionTypes = [EventService.eventTypes.mail];
        if(!helpdesk) {
            if(UserSessionService.getSmsServiceStatus() && RolesUtils.hasPermissionForName(permissions.notifications.sms)) {
                actionTypes.push(EventService.eventTypes.sms)
            }
            if(UserSessionService.getVoiceServiceStatus() && RolesUtils.hasPermissionForName(permissions.notifications.voice)) {
                actionTypes.push(EventService.eventTypes.voice)
            }
            if(UserSessionService.getWhatsappServiceStatus() && RolesUtils.hasPermissionForName(permissions.notifications.sms)){
                actionTypes.push(EventService.eventTypes.whatsapp)
            }
        }

        return (
            <EventDetailsForm
                isView={isView}
                isEdit={isEdit}
                isCreate={isCreate}
                hasEditPermission={RolesUtils.hasPermissionForName(permissions.events.update) && data?.category?.value !== EventService.categories.NOTIFICATION}
                hasDeletePermission={!helpdesk && !isAutomatic && RolesUtils.hasPermissionForName(permissions.events.delete)}
                helpdesk={helpdesk}
                hasParent={hasParent}
                data={data}
                eventId={eventId}
                dropzoneRef={this.dropzoneRef}
                onBack={this.onBack}
                onEdit={this.onEdit}
                onDelete={this.onDelete}
                onRuleDetailClick={this.onRuleDetailClick}
                handleFieldChanged={this.handleFieldChanged}
                onFormSubmit={this.onFormSubmit}
                handleOnFieldBlur={this.handleOnFieldBlur}
                handleExportFile={this.exportFile}
                componentRef={this}
                actionTypes={actionTypes}
                loading={loading}
            />
        );
    }
}

function initCreateForm(component, callback=() => {}) {
    const {helpdesk} = component.state;
    EntityService.getEntities(helpdesk ? {entityTypeNames:EntityService.entityType.FARM.name} : {entityTypeNames:EntityService.entityType.ENTERPRISE.name}, entities => {
        EventService.getEventCauses(causes => {
            EventService.getEventCategories(categories => {
                UserService.getActiveUsersList(userList => {
                    let _data = {
                        ...component.state.data,
                        enterpriseId: {
                            ...component.state.data.enterpriseId,
                            data: entities
                        },
                        cause: {
                            ...component.state.data.cause,
                            data: causes.filter(cause => helpdesk ? cause.isHelpdesk : cause.isEvent)
                        },
                        category: {
                            ...component.state.data.category,
                            data: helpdesk ? categories.filter(category => category.name.toLowerCase().startsWith('helpdesk')) : categories.filter(category => !category.name.toLowerCase().startsWith('helpdesk'))
                        },
                        userList: {
                            ...component.state.data.userList,
                            data: userList
                        }
                    };
                    if(helpdesk) {
                        _data.category = {
                            ...component.state.data.category
                        };
                        _data.entityId = {
                            ...component.state.data.entityId,
                            data: entities
                        }
                    }
                    component.setState({
                        data: _data,
                        loading: false
                    }, callback);

                }, () => Alerts.applicationError().show(), true);
            },() => Alerts.applicationError().show());
        },() => Alerts.applicationError().show());
    },() => Alerts.applicationError().show());
}

function initCreateChildrenForm(component, callback=() => {}) {
    const {
        batchId,
        helpdesk
    } = component.state;

    BatchService.getBatchDetails(batchId, batchDetails => {
        EventService.getEventCauses(causes => {
            EventService.getEventCategories(categories => {
                UserService.getActiveUsersList(userList => {
                    let _data = {
                        ...component.state.data,
                        cause: {
                            ...component.state.data.cause,
                            data: causes.filter(cause => helpdesk ? cause.isHelpdesk : cause.isEvent)
                        },
                        category: {
                            ...component.state.data.category,
                            data: helpdesk ? categories.filter(category => category.name.toLowerCase().startsWith('helpdesk')) : categories.filter(category => !category.name.toLowerCase().startsWith('helpdesk'))
                        },
                        userList: {
                            ...component.state.data.userList,
                            data: userList
                        }
                    };
                    if(helpdesk) {
                        _data.category = {
                            ...component.state.data.category
                        };
                    }
                    component.setState({
                        batchDetails: batchDetails,
                        data: _data,
                        loading: false
                    }, callback);
                }, () => Alerts.applicationError().show(), true);
            },() => Alerts.applicationError().show());
        },() => Alerts.applicationError().show());
    },() => Alerts.applicationError().show());
}

async function fetchResponsibleUsersList(component, entity){
    const entityId = entity.type.name.toLowerCase() === EntityService.entityType.SECTOR.name.toLowerCase() ?
        entity.farmOfSector.id : entity.id

    const responsibleList = await EntityService.getEntityResponsibleUsers(entityId)

    component.setState(prevState => {
        return {
            ...prevState,
            responsibleList
        }
    })
}
async function fetchFarmSectors(formData, value){
    if(_.isNil(value)) {
        return  {
            ...formData,
            entityId: {
                value: null
            },
            sectorId: {
                data: [],
                value: null
            }
        }
    }

    let entities = await AsyncEntityService.getSubEntities(value.id, {entityTypeNames : EntityService.entityType.SECTOR.name});

    return  {
        ...formData,
        sectorId: {
            data: entities
        }
    }
}

function updateHelpdeskForm(component) {
    setTimeout(() => {
        component.fieldRefs.eventAccordion.setAccordionStatus('MAIL', true);
        component.fieldRefs.emailEmails.setOptions([{
            'label': UserSessionService.getHelpdeskUserId(),
            'value': UserSessionService.getHelpdeskUserId()
        }]);
        component.fieldRefs.emailEmails.setData([UserSessionService.getHelpdeskUserId()]);
        component.fieldRefs.emailNotifyResponsible.setFieldValue(false);
    }, 10);
}

function validateFormValues(component) {
    let formData = component.state.data;
    const hasParent = component.state.hasParent;
    let formHasErrors = false;

    _.forOwn(formData, (field, key) => {
        validateField(key, field, formData, hasParent, component.state.helpdesk, component.state.isBatch);
    });

    _.forOwn(formData, (field, key) => {
        if(!__.isNilOrEmpty(field) && !__.isNilOrEmpty(field.error) && !formHasErrors) {
            formHasErrors = true;
        }
    });

    component.setState({
        data: formData,
        formHasErrors: formHasErrors
    });

    return !formHasErrors;
}

function validateField(key, field, formData, hasParent, helpdesk, isBatch) {
    switch (key) {
        case "enterpriseId":
            formData[key].error = __.isNilOrEmpty(formData[key].value) && !helpdesk && !isBatch ? t('generic.errors.emptyRequiredField') : undefined;
            break;
        case "entityId":
            formData[key].error = __.isNilOrEmpty(formData[key].value) && !hasParent ? t('generic.errors.emptyRequiredField') : undefined;
            break;

        case "title":
            const validateTitle = (title) => {
                if(__.isNilOrEmpty(title)) {
                    return t('generic.errors.emptyRequiredField');
                }
                if(title.length > 150) {
                    return t('errors.labeledField.tooLong');
                }

                return undefined;
            };
            formData[key].error = validateTitle(formData[key].value);
            break;
        case "severityLevel":
        case "category":
        case "openDate":
        case "status":
        case "cause":
            formData[key].error = __.isNilOrEmpty(formData[key].value) ? t('generic.errors.emptyRequiredField') : undefined;
            break;
        default:
            return;
    }
}

async function enterpriseHandler(formData, value) {
    if(_.isNil(value)) {
        return  {
            ...formData,
            enterpriseId: {
                value: null
            },
            entityId: {
                data: [],
                value: null
            },
            sectorId: {
                data: [],
                value: null
            },
            batchId: {
                data: [],
                value: null
            }
        }
    }

    let entities = await AsyncEntityService.getSubEntities(value.id, {entityTypeNames : EntityService.entityGroup.PHYSICAL});
    entities = EntityUtils.getSectorsWithFarmName(entities);
    entities = _.map(entities, entity => {
        return {
            ...entity,
            name: entity.complexName
        };
    });

    return  {
        ...formData,
        entityId: {
            data: entities
        }
    }
}

async function farmSectorHandler(formData, value) {
    if(_.isNil(value)) {
        return  {
            ...formData,
            entityId: {
                value: null
            },
            sectorId: {
                data: [],
                value: null
            },
            batchId: {
                data: [],
                value: null
            }
        }
    }

    let batches = await AsyncBatchService.getListBatches({entityId: value.id, status: true});
    batches = ProductionUtils.getBatchCodeWithEnterpriseCode(batches);

    return  {
        ...formData,
        batchId: {
            data: batches
        }
    }
}

function getFormValues(component) {
    const {
        title,
        status,
        openDate,
        cause,
        enterpriseId,
        entityId,
        sectorId,
        batchId,
        severityLevel,
        description,
        category,
        additionalNotes,
        origin
    } = component.state.data;

    const {
        hasParent,
        batchDetails
    } = component.state;

    let _status = !__.isNilOrEmpty(status.value) ? _.isObject(status.value) ? status.value.id : status.value : null;

    let formParams = {
        title: !__.isNilOrEmpty(title.value) ? title.value : null,
        status: _status,
        eventDate: !__.isNilOrEmpty(openDate.value) ? openDate.value instanceof moment ? openDate.value.format(config.datepicker.formats.isoDateTime) : openDate.value : null,
        cause: _.isObject(cause.value) ? cause.value.id : cause.value,
        enterpriseId: !__.isNilOrEmpty(enterpriseId.value) ? enterpriseId.value.id : null,
        entityId: !__.isNilOrEmpty(sectorId.value) ? sectorId.value.id : (!__.isNilOrEmpty(entityId.value) ? entityId.value.id : null),
        batchId: !__.isNilOrEmpty(batchId.value) ? batchId.value.id : null,
        severityLevel: !__.isNilOrEmpty(severityLevel.value) ? severityLevel.value : null,
        description: !__.isNilOrEmpty(description.value) ? description.value : null,
        category: !__.isNilOrEmpty(category.value) ? _.isObject(category.value) ? category.value.id : category.value : null,
        additionalNotes: !__.isNilOrEmpty(additionalNotes.value) ? additionalNotes.value : null
    };

    let actions = [];

    if(!origin.isAutomatic){
        _.forOwn(component.fieldRefs, (field, key) => {
            if (key !== 'eventAccordion' && !__.isNilOrEmpty(field.getFieldValue())) {
                formParams[key] = field.getFieldValue();
            }
        });


        component.fieldRefs['eventAccordion'].getAccordionStates().forEach(item => {
            let action = {};
            action.type = item.key;
            action.status = item.value;
            action.properties = {};
            action.properties.body = formParams.description;
            action.properties.subject = '';
            if (item.key === EventService.eventTypes.mail) {
                action.notifyResponsible = component.fieldRefs.emailNotifyResponsible.getFieldValue();
                action.properties.subject = formParams['title'];
                action.properties.usersList = !_.isEmpty(formParams['emailUsers']) ? formParams['emailUsers'] : [];
                action.properties.contactsList = !_.isEmpty(formParams['emailEmails']) ? formParams['emailEmails'].map(mail => mail.toLowerCase()) : [];
                action.recurrence = 0;
            } else if (item.key === EventService.eventTypes.sms) {
                action.notifyResponsible = component.fieldRefs.smsNotifyResponsible.getFieldValue();
                action.properties.usersList = !_.isEmpty(formParams['smsUsers']) ? formParams['smsUsers'] : [];
                action.properties.contactsList = !_.isEmpty(formParams['smsNumbers']) ? formParams['smsNumbers'] : [];
                action.recurrence = 0;
            } else if (item.key === EventService.eventTypes.voice) {
                action.notifyResponsible = component.fieldRefs.voiceNotifyResponsible.getFieldValue();
                action.properties.usersList = !_.isEmpty(formParams['voiceUsers']) ? formParams['voiceUsers'] : [];
                action.properties.contactsList = !_.isEmpty(formParams['voiceNumbers']) ? formParams['voiceNumbers'] : [];
                action.recurrence = 0;
            } else if (item.key === EventService.eventTypes.whatsapp) {
                action.notifyResponsible = component.fieldRefs.whatsappNotifyResponsible.getFieldValue();
                action.properties.usersList = !_.isEmpty(formParams['whatsappUsers']) ? formParams['whatsappUsers'] : [];
                action.properties.contactsList = !_.isEmpty(formParams['whatsappNumbers']) ? formParams['whatsappNumbers'] : [];
                action.recurrence = 0;
            }
            actions.push(action);
        });
    }


    if(hasParent) {
        formParams.entityId = batchDetails.entity.id;
        formParams.batchId = batchDetails.id;
    }

    return {
        ...formParams,
        actions: actions,
        dropzone: component.dropzoneRef.current.getFieldValue()
    }
}

async function setDataIntoForm(component, details, files, hasParent, parentBatchId) {
    const {
        data
    } = component.state;
    let batchDetails = null;
    const {
        id,
        title,
        severityLevel,
        openDate,
        description,
        status,
        additionalNotes,
        category,
        cause,
        batchId,
        eventDate,
        createdBy,
        entity,
        batch,
        ruleId
    } = details;

    data.id = id;
    data.ruleId = ruleId
    data.title = {
        ...data.title,
        value: title
    };
    data.severityLevel = {
        ...data.severityLevel,
        value: severityLevel
    };
    data.openDate = {
        ...data.openDate,
        value: openDate
    };
    data.description = {
        ...data.description,
        value: description
    };
    data.status = {
        ...data.status,
        value: status
    };
    data.additionalNotes = {
        ...data.additionalNotes,
        value: additionalNotes
    };
    data.category = {
        ...data.category,
        value: __.isNilOrEmpty(category) ? null : category.id
    };
    data.cause = {
        ...data.cause,
        value: __.isNilOrEmpty(cause) ? null : cause.id
    };
    data.batchId = {
        ...data.batchId,
        value: __.isNilOrEmpty(batchId) ? null : batchId
    };
    data.eventDate = {
        ...data.eventDate,
        value: eventDate
    };

    let eventCreatedBy = t('events.list.tableHeaders.createdBy.system');
    if (!__.isNilOrEmpty(createdBy)) {
        eventCreatedBy = createdBy.shortName;
    } else {
        data.origin = {
            isAutomatic: true
        };
    }
    data.createdBy = {
        ...data.createdBy,
        value: eventCreatedBy
    };

    if(hasParent && RolesUtils.hasPermissionForName(permissions.batches.read)) {
        batchDetails = await AsyncBatchService.getBatchDetails(parentBatchId);
        await setEntitiesAttributes(data, entity);
    }

    if(!_.isNil(entity) && !hasParent) {
        if(entity.type.name === EntityService.entityType.FARM.name) {
            data.enterpriseId = {
                ...data.enterpriseId,
                value: entity.parent
            };

            let entities = await AsyncEntityService.getSubEntities(entity.parent.id, {entityTypeNames : EntityService.entityGroup.PHYSICAL});
            entities = EntityUtils.getSectorsWithFarmName(entities);
            entities = _.map(entities, _entity => {
                return {
                    ..._entity,
                    name: _entity.complexName
                };
            });

            data.entityId = {
                ...data.entityId,
                data: entities,
                value: EntityUtils.getSectorWithFarmName(entity)
            };
        } else {
            if (component.props.helpdesk){
                if(__.isNilOrEmpty(entity.farmOfSector)) {
                    data.enterpriseId = {
                        ...data.enterpriseId,
                        value: entity
                    };
                    data.entityId = {
                        ...data.entityId,
                        data: [entity],
                        value: entity
                    };
                } else {
                    let farms = await AsyncEntityService.getAllEntities({entityTypeNames : EntityService.entityType.FARM.name});
                    data.entityId = {
                        ...data.entityId,
                        data: farms,
                        value: entity.farmOfSector
                    }

                    let sectors = await AsyncEntityService.getSubEntities(entity.farmOfSector.id, {entityTypeNames : EntityService.entityType.SECTOR.name});
                    data.sectorId = {
                        ...data.sectorId,
                        data: sectors,
                        value: entity
                    };
                }
            } else {
                if(__.isNilOrEmpty(entity.farmOfSector)) {
                    data.enterpriseId = {
                        ...data.enterpriseId,
                        value: entity
                    };
                    data.entityId = {
                        ...data.entityId,
                        data: [entity],
                        value: entity
                    };
                } else {
                    await setEntitiesAttributes(data, entity);
                }
            }
        }

        if(!_.isNil(batch) && RolesUtils.hasPermissionForName(permissions.batches.read)) {
            let batches = await AsyncBatchService.getListBatches({entityId: entity.id});
            batches = ProductionUtils.getBatchCodeWithEnterpriseCode(batches);
            data.batchId = {
                ...data.batchId,
                data: batches,
                value: ProductionUtils.getBatchCodeWithEnterpriseCodeObject(batch)
            };
        }
    }

    data.audit = {
        ...data.audit,
        createdBy: details.createdBy,
        creationTimestampUtc: details.creationTimestampUtc,
        lastUpdatedBy: details.lastUpdatedBy,
        lastUpdateTimestampUtc: details.lastUpdateTimestampUtc
    };

    component.setState({
        batchDetails: batchDetails,
        data: data,
        remoteData: details,
        files: files,
        loading: false
    }, async () => {

        let changes = await AsyncEventService.getEventChanges(id);
        if(!__.isNilOrEmpty(changes)) {
            let changesSorted = _.sortBy(changes, 'date');
            data.changes = {
                ...data.changes,
                value: changesSorted
            };
        }

        component.setState(prevState => {
            return {
                ...prevState,
                data: data,
            }
        }, async () =>{
            await updateActions(component, details);
        })

        component.dropzoneRef.current.setNewFiles(files)
    });
}

async function updateActions(component, details) {
    const {
        id,
        entity
    } = details;

    const actions = await AsyncEventService.getEventActions(id);
    if (!_.isNil(actions) && !_.isEmpty(actions) && component.fieldRefs['eventAccordion']) {
        actions.forEach(action => {
            let contactsOptions = [];
            component.fieldRefs['eventAccordion'].setAccordionStatus(action.type, action.status);
            action.properties.contactsList.forEach(contact => {
                if(!_.isEmpty(contact)){
                    contactsOptions.push({ 'label': contact, 'value': contact });
                }
            });
            if (action.type === EventService.eventTypes.mail) {
                setTimeout(() => {
                    component.fieldRefs.emailNotifyResponsible.setFieldValue(action.notifyResponsible, () => notifyResponsibleCheckboxEnable(component, entity));
                    component.fieldRefs.emailUsers.setData(action.properties.usersList);
                    component.fieldRefs.emailEmails.setOptions(contactsOptions);
                    component.fieldRefs.emailEmails.setData(action.properties.contactsList);
                }, 10);
            }
            else if (action.type === EventService.eventTypes.sms && component.fieldRefs.smsUsers && component.fieldRefs.smsNumbers) {
                setTimeout(() => {
                    component.fieldRefs.smsNotifyResponsible.setFieldValue(action.notifyResponsible, () => notifyResponsibleCheckboxEnable(component, entity));
                    component.fieldRefs.smsUsers.setData(action.properties.usersList);
                    component.fieldRefs.smsNumbers.setOptions(contactsOptions);
                    component.fieldRefs.smsNumbers.setData(action.properties.contactsList);
                }, 10);
            }
            else if (action.type === EventService.eventTypes.voice && component.fieldRefs.voiceUsers && component.fieldRefs.voiceNumbers) {
                setTimeout(() => {
                    component.fieldRefs.voiceNotifyResponsible.setFieldValue(action.notifyResponsible, () => notifyResponsibleCheckboxEnable(component, entity));
                    component.fieldRefs.voiceUsers.setData(action.properties.usersList);
                    component.fieldRefs.voiceNumbers.setOptions(contactsOptions);
                    component.fieldRefs.voiceNumbers.setData(action.properties.contactsList);
                }, 10);
            }
            else if (action.type === EventService.eventTypes.whatsapp && component.fieldRefs.whatsappUsers && component.fieldRefs.whatsappNumbers) {
                setTimeout(() => {
                    component.fieldRefs.whatsappNotifyResponsible.setFieldValue(action.notifyResponsible, () => notifyResponsibleCheckboxEnable(component, entity));
                    component.fieldRefs.whatsappUsers.setData(action.properties.usersList);
                    component.fieldRefs.whatsappNumbers.setOptions(contactsOptions);
                    component.fieldRefs.whatsappNumbers.setData(action.properties.contactsList);
                }, 10);
            }
        });
    }
}

function notifyResponsibleCheckboxEnable(component, entity) {
    let hasNoneFarmResponsible = true;

    if(entity && entity.type){
        hasNoneFarmResponsible = component.state.responsibleList === null ||
            component.state.responsibleList.length === 0
    }

    if(!_.isNil(component.fieldRefs.emailNotifyResponsible)) {
        component.fieldRefs.emailNotifyResponsible.setDisable(hasNoneFarmResponsible);
    }

    if(!_.isNil(component.fieldRefs.smsNotifyResponsible)) {
        component.fieldRefs.smsNotifyResponsible.setDisable(hasNoneFarmResponsible);
    }

    if(!_.isNil(component.fieldRefs.voiceNotifyResponsible)) {
        component.fieldRefs.voiceNotifyResponsible.setDisable(hasNoneFarmResponsible);
    }

    if(!_.isNil(component.fieldRefs.whatsappNotifyResponsible)) {
        component.fieldRefs.whatsappNotifyResponsible.setDisable(hasNoneFarmResponsible);
    }
}

/***************** Handlers *****************/
function handleCreateEventSubmit(component, eventData, createNewAfterSave) {
    const { helpdesk } = component.state;
    if (_.isEmpty(eventData.actions)) {
        handleSaveEvent(component, eventData, createNewAfterSave);
    } else {
        Alerts.confirmWarningFlow({
            title: t(helpdesk ? 'helpdesk.new.create.title' : 'events.form.alerts.newEvent.create.title'),
            html: t(helpdesk ? 'helpdesk.new.createAndExecute.text' : 'events.form.alerts.newEvent.createAndExecute.text', {title: eventData.title}),
            confirmButtonText: t("generic.create")
        }).show(() => {
            handleSaveEvent(component, eventData, createNewAfterSave);
        });
    }
}

function handleSaveEvent(component, eventData, createNewAfterSave) {
    const { helpdesk } = component.state;
    Alerts.loading();
    EventService.createEvent(eventData, (savedEvent) => {
        Alerts.timedSuccess({
            title: helpdesk ? t('helpdesk.new.saved.title'): t('events.form.alerts.newEvent.saved.title'),
            html: t(helpdesk ? 'helpdesk.new.saved.text' : 'events.form.alerts.newEvent.saved.text', {title: eventData.title}) +
                (getContactsSubmittedCount(eventData) !== getContactsSavedCount(savedEvent) ?
                    '<br><br><b class="c-orange">' + t('generic.contactWarning') + '</b>' : '')
        }).onClose(() => {
            if(createNewAfterSave) {
                component.setState({
                    data: {
                        ...component.state.data,
                        title: {
                            value: undefined
                        },
                        description: {
                            value: undefined
                        },
                        additionalNotes: {
                            value: undefined
                        },
                        files: [],
                    },
                }, () => component.dropzoneRef.current.setNewFiles([], false));
            } else {
                component.onBack();
            }
        }).show();
    }, () => {
        Alerts.applicationError({
            html: t('events.form.alerts.newEvent.error.text', {title: eventData.title})
        }).show();
    });
}

function handleEditEventSubmit(component, eventId, eventData) {
    const { helpdesk } = component.state;
    Alerts.loading();
    EventService.editEvent(eventId, eventData, (savedEvent) => {
        Alerts.timedSuccess({
            title: t(helpdesk ? 'helpdesk.edit.success.title' : 'events.form.alerts.editEvent.success.title'),
            html: t(helpdesk ? 'helpdesk.edit.success.text' : 'events.form.alerts.editEvent.success.text', {title: eventData.title}) +
                (getContactsSubmittedCount(eventData) !== getContactsSavedCount(savedEvent) ?
                    '<br><br><b class="c-orange">' + t('generic.contactWarning') + '</b>' : '')
        }).onClose(() => {
            component.onBack();
        }).show();
    }, () => {
        Alerts.applicationError({
            html: t(helpdesk ? 'helpdesk.edit.error.text' : 'events.form.alerts.editEvent.error.text', {title: eventData.title})
        }).show();
    });
}

function handleExportFile(id, fileName) {
    EventService.getEventFile(id, fileName, (content) => {
        DownloadService.downloadFile(content);
    }, () => {
        Alerts.applicationError().show();
    });
}

function handleDelete(component) {
    const {remoteData, helpdesk} = component.state;

    if(helpdesk) {
        Alerts.applicationError({
            title: t('events.form.alerts.deleteEvent.error.helpdesk')
        }).show();
        return;
    }

    Alerts.confirmWarningFlow({
        title: t('events.form.alerts.deleteEvent.title'),
        html: t('events.form.alerts.deleteEvent.text', {title: remoteData.title}),
        confirmButtonText: t("generic.delete")
    }).show(() => {
        EventService.deleteEvent(remoteData.id, () => {
            Alerts.timedSuccess({
                title: t('events.form.alerts.deleteEvent.success.title'),
                html: t('events.form.alerts.deleteEvent.success.text', {title: remoteData.title})
            }).onClose(() => {
                component.onBack();
            }).show();
        }, () => {
            Alerts.applicationError({
                title: t('events.form.alerts.deleteEvent.error.text'),
            }).show();
        });
    });
}

function getContactsSubmittedCount(eventData){
    return (eventData.voiceNumbers ? eventData.voiceNumbers.length : 0) +
        (eventData.whatsappNumbers ? eventData.whatsappNumbers.length : 0) + (eventData.smsNumbers ? eventData.smsNumbers.length : 0)
}

function getContactsSavedCount(eventData){
    return eventData.actions ? reduceActionContactListCount(eventData.actions) : 0
}

export function reduceActionContactListCount(actions) {
    return actions.reduce((acc, action) => action.type && action.type !== "MAIL" ? acc + action?.properties?.contactsList.length : acc,0)
}

async function setEntitiesAttributes(data, entity) {
    data.enterpriseId = {
        ...data.enterpriseId,
        value: entity.farmOfSector.parent
    };

    let entities = await AsyncEntityService.getSubEntities(entity.farmOfSector.parent.id, {entityTypeNames: EntityService.entityGroup.PHYSICAL});
    entities = EntityUtils.getSectorsWithFarmName(entities);
    entities = _.map(entities, _entity => {
        return {
            ..._entity,
            name: _entity.complexName
        };
    });

    data.entityId = {
        ...data.entityId,
        data: entities,
        value: EntityUtils.getSectorWithFarmName(entity)
    };
}

export default EventDetailsFormContainer;