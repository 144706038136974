import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import {t} from "../../../utils/Translation";
import {config} from '../../../config';
import LabeledField from "../labeled/LabeledField";
import RuleService from "../../../services/RuleService";
import SwitchField from "../switch/SwitchField";
import Row from "../../row/Row";
import trash from "../../../img/trash/trash.svg";
import trashOnHover from "../../../img/trash/trash_on_over.svg";
import ImgButton from "../../buttons/ImgButton";
import classNames from "classnames";

class SchedulerConfigsLine extends React.Component {
    constructor(props){
        super(props);

        this.fieldRefs = {};

        this.state = {
            dataType: props.dataType? props.dataType : null,
            startTime: props.startTime? props.startTime : null,
            endTime: props.endTime? props.endTime : null,
            action: !_.isNil(props.action)? props.action : null,
            forced: !_.isNil(props.forced)? props.forced : null,
            displayName: !_.isNil(props.displayName)? props.displayName : null,
            value: !_.isNil(props.value)? props.value : null,
            tariffMode: !_.isNil(props.tariffMode) ? props.tariffMode : false,
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            dataType: nextProps.dataType? nextProps.dataType : this.state.dataType,
            startTime: nextProps.startTime? nextProps.startTime : this.state.startTime,
            endTime: nextProps.endTime? nextProps.endTime : this.state.endTime,
            displayName: !_.isNil(nextProps.displayName)? nextProps.displayName : null,
            value: !_.isNil(nextProps.value)? nextProps.value : null,
            action: nextProps.action,
            forced: nextProps.forced,
            tariffMode: !_.isNil(nextProps.tariffMode) ? nextProps.tariffMode : false,
        })
    }

    setInterfaceDataType(dataType) {
        this.setState({dataType: dataType})
    }

    validateConfigLine(...param) {
        let valid = true;
        let self = this;
        _.forOwn(this.fieldRefs, (field, key) => {
            if(!field.validateField(self.state[key])){
                valid = false;
            }
        });
        return valid;
    }

    getRawEndTime() {
        let endTime = null;
        if(this.fieldRefs.endTime) {
            endTime = this.fieldRefs.endTime.getRawFieldValue();
        }
        return endTime;
    }

    getFieldValue() {
        return this.state.value;
    }

    getStartTime() {
        return this.state.startTime;
    }

    render() {
        let self = this;
        const isMobile = window.matchMedia("(min-width: 200px) and (max-width: 768px)").matches;

        return (
            <Row style={{margin: "20px 0"}} className={classNames('', {"flex align-items-center justify-center": !isMobile}, {"gray-border-bottom": isMobile})}>
                {this.state.tariffMode? buildDisplayNameField(this) : null}
                <LabeledField
                    fieldType='dateTime'
                    fieldPlaceHolder={t("generic.scheduler.startTimePlaceholder")}
                    fieldLabel={t('generic.scheduler.headers.startTime')}
                    width='2'
                    hasDate={false}
                    hasTime={true}
                    hasSeconds={true}
                    isView={this.props.isView}
                    isRequired={true}
                    className='mb-zero-absolute p-left-0 mt-10'
                    iconClassName='fa fa-calendar'
                    value={this.state.startTime}
                    avoidDateTimeConversionToUserTimeZone={true}
                    onFieldChange={value => self.props.onStartTimeChanged(value)}
                    onBlur={value => validateAndSetEndTimeField(self, this.fieldRefs.startTime, value)}
                    validator={paramsArray => validateStartTimeGreaterThanPreviousEndTime(self, paramsArray[0])}
                    ref={c => this.fieldRefs.startTime = c}
                />
                <LabeledField
                    fieldType='dateTime'
                    fieldPlaceHolder={t("generic.scheduler.endTimePlaceholder")}
                    fieldLabel={t('generic.scheduler.headers.endTime')}
                    width='2'
                    hasDate={false}
                    hasTime={true}
                    hasSeconds={true}
                    className='mb-zero-absolute p-left-0 mt-10 '
                    iconClassName='fa fa-calendar'
                    isView={this.props.isView}
                    isRequired={true}
                    value={this.state.endTime}
                    avoidDateTimeConversionToUserTimeZone={true}
                    onFieldChange={value => self.props.onEndTimeChanged(value)}
                    onBlur={value => this.fieldRefs.endTime.validateField(value)}
                    validator={paramsArray => validateEndTimeGreaterOrEqualThanStartTime(self, paramsArray[0])}
                    ref={c => this.fieldRefs.endTime = c}
                />
                <div className={classNames('',{"flex-1 pd-l-20 mb-zero-absolute": !isMobile}, {"p-left-0": isMobile})}>
                    <div className={classNames('', {"col-lg-4 justify-start": !isMobile}, {'flex align-items-center justify-between': isMobile})}>
                        {this.state.tariffMode? buildValueField(this) : buildActionField(this)}
                        {buildForcedField(this)}
                    </div>
                    <div className={classNames('', {"justify-start": !isMobile}, {'flex flex-wrap justify-center align-items-center': isMobile})}>
                        <div>
                            {buildDeleteButton(this)}
                        </div>
                    </div>
                </div>
            </Row>
        );
    }
}

function buildDisplayNameField(component) {
    return (
        <td className="pd-t-12 col-xs-3">
            <LabeledField
                fieldPlaceHolder={t("generic.scheduler.displayNamePlaceholder")}
                fieldType={'text'}
                isRequired={true}
                bottomMargin={false}
                className={"p-total-0"}
                width={"12"}
                value={component.state.displayName}
                isView={component.props.isView}
                onFieldChange={value => component.props.onDisplayNameChanged(value)}
                ref={c => component.fieldRefs.displayName = c}
            />
        </td>
    );
}

function buildDeleteButton(component) {

    if(!component.props.isView) {
        return <ImgButton
            alt = "delete"
            divClassname={classNames('col-lg-2 col-xs-12 py-10 flex ml-0 mt-23')}
            image={trash}
            onHoverImage={trashOnHover}
            handleIconClick={() => deleteLine(component.props.parent, component.props.id)}
        />
    } else {
        return null;
    }
}

function deleteLine(parent, line) {
    parent.deleteLine(line);
}

function buildValueField(component) {
    return (
        <LabeledField
            fieldPlaceHolder={t("generic.scheduler.value")}
            fieldType={'text'}
            isRequired={true}
            bottomMargin={false}
            width={"12"}
            className={"p-total-0"}
            value={component.state.value}
            isView={component.props.isView}
            validator={() => validateWithRegex(component, config.generic.decimalregex, t('errors.generic.notADecimal'))}
            onFieldChange={value => component.props.onValueChanged(value)}
            ref={c => component.fieldRefs.value = c}
        />
    );
}

function buildActionField(component) {
    let dataType = component.state.dataType;
    let value = component.state.action;
    let field = null;
    if(!_.isNil(dataType)) {
        switch (dataType) {
            case RuleService.dataType.DECIMAL:
                field = (
                    <LabeledField
                        fieldType="text"
                        fieldPlaceHolder={t('generic.dropgroup.fields.decimalPlaceholder')}
                        fieldLabel={t('generic.scheduler.headers.value')}
                        bottomMargin={false}
                        width="6"
                        isRequired={true}
                        disabled={false}
                        isView={component.props.isView}
                        value={value}
                        className={"p-total-0 mt-10"}
                        onFieldChange={value => component.props.onActionChanged(value)}
                        validator={() => validateWithRegex(component, config.generic.decimalregex, t('errors.generic.notADecimal'))}
                        ref={c => {component.fieldRefs.action = c}}
                    />
                );
                break;
            case RuleService.dataType.STRING:
                field = (
                    <LabeledField
                        fieldType="text"
                        fieldPlaceHolder={t('generic.dropgroup.fields.stringPlaceholder')}
                        fieldLabel={t('generic.scheduler.headers.value')}
                        bottomMargin={false}
                        isRequired={true}
                        width="6"
                        value={value}
                        className={"p-total-0 mt-10"}
                        isView={component.props.isView}
                        onFieldChange={value => component.props.onActionChanged(value)}
                        ref={c => {component.fieldRefs.action = c}}
                    />
                );
                break;
            case RuleService.dataType.BOOLEAN:
                field = (
                    <SwitchField
                        noMarginBottom={true}
                        fieldLabel={t('generic.scheduler.headers.state')}
                        width='6'
                        isChecked={value}
                        className={"p-total-0 mt-21"}
                        isView={component.props.isView}
                        onFieldChange={value => component.props.onActionChanged(value)}
                        ref={c => {component.fieldRefs.action = c}}
                    />
                );
                break;
            case RuleService.dataType.INTEGER:
                field = (
                    <LabeledField
                        fieldType="text"
                        fieldPlaceHolder={t('generic.dropgroup.fields.integerPlaceholder')}
                        fieldLabel={t('generic.scheduler.headers.value')}
                        bottomMargin={false}
                        isRequired={true}
                        width="6"
                        value={value}
                        className={"p-t-15 mt-10"}
                        isView={component.props.isView}
                        onFieldChange={value => component.props.onActionChanged(value)}
                        validator={() => validateWithRegex(component, config.generic.numberregex, t('errors.generic.notANumber'))}
                        ref={c => {component.fieldRefs.action = c}}
                    />
                );
                break;
            default:
                throw new Error("Unknown interface data type: " + dataType);
        }
    } else {
        field = <LabeledField
            fieldType="text"
            fieldPlaceHolder={t('generic.dropgroup.fields.actionDefaultPlaceholder')}
            fieldLabel={t('generic.scheduler.headers.value')}
            width="6"
            disabled={true}
            isRequired={true}
            className='mb-zero-absolute p-total-0 mt-10'
            ref={c => {component.fieldRefs.action = c}}
        />
    }
    return field;
}

function buildForcedField(component) {
    let dataType = component.state.dataType;
    let forced = component.state.forced;
    let field = null;
    if(!_.isNil(dataType)) {
        field = <SwitchField
                    noMarginBottom={true}
                    width='6'
                    isChecked={forced}
                    className={"p-total-0 justify-center mt-21"}
                    fieldLabel={t('generic.scheduler.headers.forced')}
                    uncheckedLabel={t('generic.no')}
                    checkedLabel={t('generic.yes')}
                    isView={component.props.isView}
                    onFieldChange={value => component.props.onForcedChanged(value)}
                    ref={c => {component.fieldRefs.forced = c}}
        />
    }
    return field;
}

function validateWithRegex(component, regex, errorMsg) {
    let errorMessage = '';
    let value;
    if(component.state.tariffMode){
        if(_.isNil(component.fieldRefs.value)){
            return errorMessage;
        }
        value = component.fieldRefs.value.getFieldValue();
    } else {
        if(_.isNil(component.fieldRefs.action)){
            return errorMessage;
        }
        value = component.fieldRefs.action.getFieldValue();
    }

    if(!regex.test(value)){
        errorMessage = errorMsg;
    }
    return errorMessage;
}

function validateAndSetEndTimeField(component, ref, value) {
    if(ref.validateField(value) && (_.isNil(component.state.endTime) || value.isSameOrAfter(component.state.endTime))) {
        let endTimeValue = value.hours() === 23 && value.minutes() === 59 && value.seconds() === 59? value.clone() : value.clone().add(1, 's');
        component.props.parent.updateLineValue(component.props.id, 'endTime', endTimeValue);
        component.fieldRefs.endTime.setFieldValue(endTimeValue);
        component.fieldRefs.endTime.setError('');
    }
}

function validateEndTimeGreaterOrEqualThanStartTime(component, endTimeValue) {
    let errorMessage = '';
    if(_.isNil(component.fieldRefs.endTime) || _.isNil(component.fieldRefs.startTime)){
        return errorMessage;
    }

    let startTimeValue = component.fieldRefs.startTime.getRawFieldValue();
    if(component.state.tariffMode) {
        if(!_.isNil(endTimeValue) && !_.isNil(startTimeValue) &&
            (startTimeValue.isSame(endTimeValue) || startTimeValue.isAfter(endTimeValue))){
            errorMessage = t('generic.scheduler.errors.endTimeAfterStartTime');
        }
    } else {
        if(!_.isNil(endTimeValue) && !_.isNil(startTimeValue) && startTimeValue.isAfter(endTimeValue)){
            errorMessage = t('generic.scheduler.errors.endTimeAfterOrEqualStartTime');
        }
    }
    return errorMessage;
}

function validateStartTimeGreaterThanPreviousEndTime(component, startTimeValue) {
    let errorMessage = '';
    if(_.isNil(component.fieldRefs.endTime) || _.isNil(component.fieldRefs.startTime)){
        return errorMessage;
    }
    let endTimeValue = component.props.parent.getEndTimeOfConfigLineBefore(component.props.id);
    if(!_.isNil(endTimeValue) && !startTimeValue.isAfter(endTimeValue)){
        errorMessage = t('generic.scheduler.errors.startTimeAfterEndTime')
    }
    return errorMessage;
}

SchedulerConfigsLine.propTypes = {
    dataType: PropTypes.string,
    isView: PropTypes.bool,
    parent: PropTypes.object,
    onStartTimeChanged: PropTypes.func,
    onEndTimeChanged: PropTypes.func,
    onActionChanged: PropTypes.func,
    onForcedChanged: PropTypes.func,
    onDisplayNameChanged: PropTypes.func,
    onValueChanged: PropTypes.func,
    tariffMode: PropTypes.bool
};

export default SchedulerConfigsLine;