import _ from "lodash";

const Utils = {

    normalizeByFarm(dashboards) {
        let menu = [];
        _.forEach(dashboards, function (fav) {
            let farmName = !_.isNil(fav.entity.farmOfSector) ? fav.entity.farmOfSector.displayName : fav.entity.displayName;
            if(menu.findIndex(x => x.farmName === farmName) !== -1){
                let tt = menu.findIndex(x => x.farmName === farmName);
                menu[tt].dashboards.push(fav);
            } else {
                let obj = {};
                obj.dashboards = [];
                obj.farmName = farmName;
                obj.dashboards.push(fav);
                menu.push(obj);
            }
        });
        menu = _.sortBy(menu, "farmName");
        return menu;
    },

    getHrefMainOption(href) {
        let hrefSlip = _.split(href, '/')[3];
        if(_.includes(hrefSlip,"?")) {
            return _.split(hrefSlip, '?')[0];
        }
        return hrefSlip;
    },

    getHrefGetValue(href, key) {
        let url = new URL(href);
        return url.searchParams.get(key);
    },

    isRootUrl(href) {
        return _.split(href, '/')[3] === 'farmview' || _.isEmpty(_.split(href, '/')[3]);
    }


}

export default Utils;