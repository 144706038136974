import React from 'react';
import Row from "../../row/Row";
import _ from 'lodash';
import ImgButton from "../../buttons/ImgButton";
import back from "../../../img/back/back.svg";
import backOnHover from "../../../img/back/back_on_over.svg";
import {t} from "../../../utils/Translation";
import Tooltip from "../../tooltip/Tooltip";
import EuroIcon from "../../../img/currency/euro.svg";
import EventService from "../../../services/EventService";

class AccordionTitle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            accordionTitleTextAlignment: props.accordionTitleTextAlignment ? props.accordionTitleTextAlignment : "left",
            position: props.position,
            enabled: props.enabled,
            active: !_.isNil(props.active)? props.active : false
        };
    }

    setEnabled(enabled){
        this.setState({enabled: enabled});
    }

    toggleVisibility(){
        this.setState({
            active: !this.state.active
        })
    }

    render() {
        const {
            panelHeadCustomClass ="",
            hasSwitchArea,
            text,
            hasAdditionalCost,
            customIcon = false,
            type
        } = this.props;

        const {
            active,
            position
        } = this.state;

        let onClickCallback = this.props.onClick ? this.props.onClick : () => {};
        let switchArea = hasSwitchArea? getSwitchInputArea(this) : null;
        let switchText = hasSwitchArea? getSwitchTextArea(this) : null;
        let arrowDirection = active? 'up' : 'down';
        let imageClassName = "fa fa-chevron-" + arrowDirection + " pull-right";
        return (
            <>
                <div className={"panel-heading " + panelHeadCustomClass} onClick={() => onClickCallback(position)} style={{cursor: "pointer"}}>
                    <Row className="flex-row" style={{height: "100%"}}>
                        <div className={customIcon ? "col-sm-11 col-xs-11" : "col-xs-5 col-sm-8"}>
                            <h5 className="panel-title" style={{textAlign: "left"}} >
                                <span>{text}</span>
                                {
                                    hasAdditionalCost &&
                                        <Tooltip content={type === EventService.eventTypes.voice ? t('events.form.sendEvent.costsVoiceTooltip') : (type === EventService.eventTypes.sms ? t('events.form.sendEvent.costsSmsTooltip') : t('events.form.sendEvent.costsTooltip'))} placement='top'>
                                            <div className={"rct-text"}>
                                                <sup><img width={15} src={EuroIcon} className="tooltip-icon c-black m-l-xs" alt="cost"/></sup>
                                            </div>
                                        </Tooltip>
                                }
                            </h5>
                        </div>
                        {switchArea}
                        {switchText}
                        {
                            customIcon ?
                                <div className="col-sm-1 dashboard-tree-panel-head-icons-div">
                                    <div className="p-total-0 col-sm-1 dashboard-tree-panel-head-icons-div">
                                        <ImgButton
                                            height={30}
                                            alt = "open"
                                            image={back}
                                            onHoverImage = {backOnHover}
                                            classes={active ? "rotate-90" : "rotate--90"}
                                        />
                                    </div>
                                </div>
                                :
                                <div className={hasSwitchArea? "col-xs-1" : "col-xs-offset-3 col-xs-4"}>
                                    <i className={imageClassName}/>
                                </div>
                        }
                    </Row>
                </div>
            </>
        );
    }
}

function toggleSwitch(component) {
    const stateUpdated = !component.state.enabled;
    component.setState({enabled: stateUpdated}, () => component.props.onToggleSwitch(stateUpdated));
}

function getSwitchText(component) {
    return component.state.enabled? t('generic.active') : t('generic.inactive');
}

function getSwitchInputArea(component) {
    return (
        <div className="col-xs-3 col-sm-2">
            <input
                disabled={component.props.hasSwitchAreaDisable}
                className="uiswitch switch-field"
                type="checkbox"
                onChange={() => toggleSwitch(component)}
                onClick={event => event.stopPropagation()}
                checked={component.state.enabled}
                style={{textAlign: "right"}}
            />
        </div>

    );
}

function getSwitchTextArea(component) {
    return (
        <div className="col-xs-3" style={{textAlign: "left", paddingLeft: "0px", paddingRight: "0px"}}>
            <span>{getSwitchText(component)}</span>
        </div>
    );
}

AccordionTitle.defaultProps = {
    onToggleSwitch: () => {}
}

export default AccordionTitle;