import React, {useState} from "react";
import {Panel} from "react-bootstrap";
import ImgButton from "../../../../../../../components/buttons/ImgButton";
import arrow from "../../../../../../../img/back/back.svg";
import arrowOnHover from "../../../../../../../img/back/back_on_over.svg";
import classnames from "classnames";
import Row from "../../../../../../../components/row/Row";
import SwitchField from "../../../../../../../components/fields/switch/SwitchField";
import _ from "lodash";
import Multiselect from "../../../../../../../components/multiselect/MultiSelectField";
import {config} from "../../../../../../../config";
import "./style/alarm.scss";
import {useTranslation} from "react-i18next";

const MailAction = (
    {
        isView,
        active,
        hasActivation = true,
        isFarmResponsibleAvailable = true,
        notifyResponsible,
        users,
        emails,
        handleFieldChanged,
        farmResponsible,
    }) => {
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState(false);
    return (
        <Panel className={"action"} expanded={expanded} onToggle={()=>setExpanded(!expanded)}>
            <Panel.Heading className="action-heading">
                <Panel.Title toggle>
                    <div className="action-title">
                        <p><b>{t('events.form.sendEvent.actionEmail')}</b></p>
                        <div className="action-title-btn">
                            { hasActivation &&
                                <SwitchField
                                    forceNewPropValue={true}
                                    width="12"
                                    isView={false}
                                    className="mb-zero-absolute"
                                    checkedLabel={t('generic.active')}
                                    uncheckedLabel={t('generic.inactive')}
                                    checkedLabelClassName="status-label"
                                    uncheckedLabelClassName="status-label"
                                    invert={true}
                                    isChecked={active?.value}
                                    disabled={isView}
                                    onFieldChange={value => handleFieldChanged(value, "active")}
                                />
                            }
                            <ImgButton
                                alt="open"
                                image={arrow}
                                onHoverImage={arrowOnHover}
                                classes={classnames("arrow-direction", {"up": expanded})}
                                handleIconClick={() => setExpanded(!expanded)}
                            />
                        </div>
                    </div>
                </Panel.Title>
            </Panel.Heading>
            <Panel.Collapse>
                <Panel.Body className="action-body">
                    <div className='form-group col-xs-12'>
                        {isFarmResponsibleAvailable &&
                            <Row className='pd-t-5'>
                                <SwitchField
                                    forceNewPropValue={true}
                                    fieldLabel={t('events.form.sendEvent.notifyResponsible.label')}
                                    width="4"
                                    isView={isView}
                                    isChecked={farmResponsible?.length ? notifyResponsible?.value : false}
                                    disabled={!farmResponsible}
                                    checkedLabel={t('events.form.sendEvent.notifyResponsible.notify')}
                                    uncheckedLabel={t('events.form.sendEvent.notifyResponsible.not')}
                                    checkedLabelClassName="status-label"
                                    uncheckedLabelClassName="status-label"
                                    onFieldChange={value => handleFieldChanged(value, "notifyResponsible")}
                                />
                            </Row>
                        }
                        <Row>
                            <Multiselect
                                forceNewPropValue={true}
                                fieldLabel={t('events.form.sendEvent.users')}
                                fieldPlaceHolder={t('events.form.sendEvent.usersPlaceHolder')}
                                iconClassName='fa fa-user'
                                protectHiddenValues={users?.protectHiddenValues}
                                width='8'
                                isView={isView}
                                options={getUsersListOptions(users?.data)}
                                value={users?.value}
                                onFieldChange={value => handleFieldChanged(value, "users")}
                            />
                        </Row>
                        <Row>
                            <Multiselect
                                forceNewPropValue={true}
                                fieldLabel={t('events.form.sendEvent.emails')}
                                fieldPlaceHolder={t('events.form.sendEvent.emailsPlaceHolder')}
                                width='8'
                                permitCreate={true}
                                isView={isView}
                                iconClassName='fa fa-envelope'
                                value={emails?.value}
                                validatorRegex={config.user.emailregex}
                                onFieldChange={value => handleFieldChanged(value, "emails")}
                            />
                        </Row>
                    </div>
                </Panel.Body>
            </Panel.Collapse>
        </Panel>
    );
};

function getUsersListOptions(userList) {
    let list = [];
    if(!_.isEmpty(userList)) {
        userList.forEach(user => {
            list.push({
                'label': user.shortName,
                'value': user.userId
            });
        });
    }
    return list;
}

export default MailAction;