import * as actionTypes from "./actionTypes";

const initialState = {
    values: {},
    loading: false,
    dataOfSearchFields: {},
    table: {
        data: [],
        sort: {
            property: undefined,
            direction: undefined
        },
        totalElements: undefined,
        pageNumber: undefined,
        totalPages: undefined
    },
    list: {
        data: [],
        loading: false
    },

    //form specific:
    manualWeighingData: {},

    loadingCreateRequest: false,
    loadingEditRequest: false,
    loadingDeleteRequest: false,

    manualWeighingWasCreated: false,
    manualWeighingWasEdited: false,
    manualWeighingWasDeleted: false,

    manualWeighingActionDeferredStatus: false,
    error: undefined,

    weightInterface: {
        data: [],
        loading: false
    }
};

const manualWeighingReducer = (state = initialState, {type, payload}) => {
    switch (type) {
        case actionTypes.GET_MANUAL_WEIGHING_REQUEST:
            return {
                ...state,
                loading: true
            };
        case actionTypes.GET_MANUAL_WEIGHING_SUCCESS:
            return {
                ...state,
                loading: false,
                manualWeighingData: payload
            };
        case actionTypes.GET_MANUAL_WEIGHING_FAILED:
            return {
                ...state,
                loading: false,
                error: payload
            };

        case actionTypes.CREATE_MANUAL_WEIGHING_REQUEST:
            return {
                ...state,
                loadingCreateRequest: true,
                manualWeighingWasCreated: false
            };
        case actionTypes.CREATE_MANUAL_WEIGHING_SUCCESS:
            return {
                ...state,
                loadingCreateRequest: false,
                manualWeighingWasCreated: true,
                manualWeighingActionDeferredStatus: false
            };
        case actionTypes.CREATE_MANUAL_WEIGHING_FAILED:
            return {
                ...state,
                loadingCreateRequest: false,
                manualWeighingWasCreated: false,
                manualWeighingActionDeferredStatus: false,
                error: payload
            };
        case actionTypes.CREATE_MANUAL_WEIGHING_DEFERRED_STATUS:
            return {
                ...state,
                loadingCreateRequest: false,
                manualWeighingWasCreated: false,
                manualWeighingActionDeferredStatus: true,
                error: payload
            };

        //List was not needed but can be added
        case actionTypes.EDIT_MANUAL_WEIGHING_REQUEST:
            return {
                ...state,
                loadingEditRequest: true,
                manualWeighingWasEdited: false
            };
        case actionTypes.EDIT_MANUAL_WEIGHING_SUCCESS:
            return {
                ...state,
                loadingEditRequest: false,
                manualWeighingWasEdited: true
            };
        case actionTypes.EDIT_MANUAL_WEIGHING_FAILED:
            return {
                ...state,
                loadingEditRequest: false,
                manualWeighingWasEdited: false,
                error: payload
            };
        case actionTypes.DELETE_MANUAL_WEIGHING_REQUEST:
            return {
                ...state,
                loadingDeleteRequest: true,
                manualWeighingWasDeleted: false
            };
        case actionTypes.DELETE_MANUAL_WEIGHING_SUCCESS:
            return {
                ...state,
                loadingDeleteRequest: false,
                manualWeighingWasDeleted: true
            };
        case actionTypes.DELETE_MANUAL_WEIGHING_FAILED:
            return {
                ...state,
                loadingDeleteRequest: false,
                manualWeighingWasDeleted: false,
                error: payload
            };

        case actionTypes.RESET_MANUAL_WEIGHING_FORM:
            return {
                ...initialState,
                table: state.table
            };
        default:
            return state;
    }
};

export default manualWeighingReducer;