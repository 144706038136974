import _ from 'lodash';

const EnhancedLodash = {
    isNilOrEmpty(object){
        if(_.isNumber(object)) {
            if(_.isNaN(object) || !_.isFinite(object)) {
                return true;
            }
            object = object + "";
        } else if (_.isBoolean(object)) {
            return false;
        }

        return _.isNil(object) || _.isEmpty(object) || _.isUndefined(object);
    },

    isObject(item) {
        return _.isObject(item)  && !_.isArray(item);
    },

    truncate(str, length=100) {
        let dots = str.length > length ? '...' : '';
        return str.substring(0, length)+dots;
    }
};

export default EnhancedLodash