import _ from "lodash";

let NumberUtils = {

    separators: {
        DECIMAL: ',',
        THOUSANDS: '.'
    },

    formatDecimalNumber(decimalNumber, decimalPlaces) {
        return !_.isNil(decimalNumber)? parseFloat(Math.round(decimalNumber * Math.pow(10,decimalPlaces)) / Math.pow(10,decimalPlaces)).toFixed(decimalPlaces).toString() : decimalNumber;
    },

    formatDecimalSeparator(number, decimalPlaces) {
        if(!_.isNil(number)) {
            number = number.constructor.name === "Number" ? number : Number(number);
            let decimalNumber = this.formatDecimalNumber(number, decimalPlaces);
            let numberAsString = decimalNumber.toString().replace(".", this.separators.DECIMAL);
            return numberAsString.replace(/\B(?=(\d{3})+(?!\d))/g, this.separators.THOUSANDS);
        }
    },

    decimalPlaces(value) {
        value = _.toString(value);
        const isComma = value.indexOf(",") !== -1;
        if(Math.floor(value) === Number(value)) return 0;
        return value.toString().split(isComma ? ',' : '.')[1].length || 0;
    },

    getPercentageValue(firstElement, secondElement) {
        if (firstElement === null || firstElement === undefined || secondElement === null || secondElement === undefined) {
            return null;
        }

        if(secondElement === 0){
            return -100
        }

        return firstElement * 100 / secondElement;
    }
};

export default NumberUtils;