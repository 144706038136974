import React from 'react';
import PropTypes from 'prop-types';
import DropGroup from "./DropGroup";
import {t} from "../../../utils/Translation";
import LabeledField from "../labeled/LabeledField";
import _ from "lodash";
import SwitchField from "../switch/SwitchField";
import RuleService from "../../../services/RuleService";
import {config} from "../../../config";
import LocalesUtils from "../../../utils/LocalesUtils";
import trash from "../../../img/trash/trash.svg";
import trashOnHover from "../../../img/trash/trash_on_over.svg";
import ImgButton from "../../buttons/ImgButton";
import classNames from "classnames";

class DropGroupLine extends React.Component {
    constructor(props) {
        super(props);

        this.fieldRefs = {};

        this.state = {
            terminalList: this.props.terminalList?  this.props.terminalList : [],
            interfaceList: this.props.interfaceList? this.props.interfaceList : [],
            interfaceDataType: this.props.interfaceDataType? this.props.interfaceDataType : null,
            logicOperations: this.props.logicOperations? this.props.logicOperations : [],
            actionType: this.props.actionType? this.props.actionType : null,
            checkedLabelForBooleanAction: null,
            uncheckedLabelForBooleanAction: null,
            booleanActionValue: null,
            dataIsSet: false,
            isView: !_.isNil(props.isView)? props.isView : false,
            showAllInterfaces: this.props.showAllInterfaces
        }
    }

    componentDidMount() {
        let self = this;
        if(this.props.lineData) {
            if(this.props.parent.state.globalRule) {
                this.fieldRefs.terminal.setFieldValue(this.props.lineData.terminalId);
            }
            this.fieldRefs.interfaceId.setFieldValue(this.props.lineData.interfaceId);
            if (!_.isNil(this.props.lineData.logicOperator)) {
                this.fieldRefs.logicOperator.setFieldValue(this.props.lineData.logicOperator);
            }
            if (!_.isNil(this.props.lineData.inputValue)) {
                let value = this.props.lineData.inputValue;
                if (this.state.interfaceDataType === RuleService.dataType.BOOLEAN) {
                    value = value === 'true';
                    if(!_.isEmpty(this.state.interfaceList)){
                        let interfaceObj = _.find(this.state.interfaceList, iface => {
                            return (self.props.parent.state.globalRule && iface.id === self.props.lineData.interfaceId) ||
                                (!self.props.parent.state.globalRule && iface.interfaceId === self.props.lineData.interfaceId);
                        });
                        updateBooleanFieldLabels(self, interfaceObj, value);
                    }
                }
                this.fieldRefs.action.setFieldValue(value);
            }
            this.fieldRefs.interfaceId.setFieldValue(this.props.lineData.interfaceId);
        }
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            terminalList: nextProps.terminalList? nextProps.terminalList : this.state.terminalList,
            dataIsSet: false,
            isView: nextProps.isView
        });
    }

    setLogicalOperations(logicOperations, afterLogicalOperationsAreSet) {
        afterLogicalOperationsAreSet = afterLogicalOperationsAreSet? afterLogicalOperationsAreSet : () => {};
        this.setState({logicOperations: logicOperations}, () => {
            afterLogicalOperationsAreSet();
        });
    }

    setInterfaceList(interfaceList, afterInterfaceListIsSet) {
        afterInterfaceListIsSet = afterInterfaceListIsSet? afterInterfaceListIsSet : () => {};
        this.setState({interfaceList: interfaceList}, () => afterInterfaceListIsSet());
    }

    setTerminalList(terminalList, afterTerminalListIsSet) {
        afterTerminalListIsSet = afterTerminalListIsSet? afterTerminalListIsSet : () => {};
        this.setState({terminalList: terminalList}, () => afterTerminalListIsSet());
    }

    setInterfaceDataType(dataType, afterDataTypeIsSet) {
        afterDataTypeIsSet = afterDataTypeIsSet? afterDataTypeIsSet : () => {};
        this.setState({interfaceDataType: dataType}, () => {
            afterDataTypeIsSet();
        });
    }

    setLabelsForBooleanField(checkedLabel, uncheckedLabel, booleanActionValue) {
        this.setState({
            checkedLabelForBooleanAction: checkedLabel,
            uncheckedLabelForBooleanAction: uncheckedLabel,
            booleanActionValue: booleanActionValue
        });
    }

    setIsView(isView) {
        this.setState({
            isView: isView
        });
    }

    render() {
        let self = this;
        let parent = this.props.parent;
        let globalRule = parent.state.globalRule;
        let actionType = parent.state.actionType;
        const isMobile = window.matchMedia("(min-width: 200px) and (max-width: 1200px)").matches;


        return (
            <div className="flex mb-15 flex-wrap"
                 style={{borderBottom: "1px solid #f1f1f1"}}>
                <div className='col-lg-3 col-xs-12 pd-r-0 pd-b-15'>
                    {buildTerminalField(self, globalRule)}
                </div>
                <div className='col-lg-3 col-xs-12 pd-r-0 pd-b-15'>
                    {buildInterfaceField(self, globalRule, actionType)}
                </div>
                <div className='col-lg-2 col-xs-12 pd-r-0 pd-b-15'>
                    {buildLogicalOperatorField(self, actionType)}
                </div>
                <div className='col-lg-2 col-xs-12 pd-r-0 pd-b-15' style={{minWidth: '170px'}}>
                    {buildActionField(self)}
                </div>
                <div className={classNames('col-lg-1 col-xs-12 flex p-left-0', {"justify-center pd-b-10": isMobile})}>
                    {buildDeleteButton(self, parent)}
                </div>
            </div>
        );
    }

    getLineValue(){
        if(this.fieldRefs.interfaceId && !_.isEmpty(this.fieldRefs.interfaceId.getFieldValue())) {
            return {
                interfaceId: this.fieldRefs.interfaceId.getFieldValue(),
                terminalId: this.fieldRefs.terminal? this.fieldRefs.terminal.getFieldValue() : null,
                logicOperator: this.fieldRefs.logicOperator? this.fieldRefs.logicOperator.getFieldValue() : null,
                inputValue: this.state.interfaceDataType === RuleService.dataType.BOOLEAN? this.fieldRefs.action.getFieldValue()? 'true' : 'false' : this.fieldRefs.action.getFieldValue(),
                permanenceTime: this.props.permanenceTime || '00:00',
                repetitionTime: this.props.repetitionTime || '00:00',
                notificationLimit: this.props.notificationLimit || '3'
            };
        } else {
            return null;
        }
    }

    validateLine() {
        let isValid = true;
        _.forOwn(this.fieldRefs, field => {
            if(!_.isNil(field)) {
                if (!field.validateField()) {
                    isValid = false;
                }
            }
        });
        return isValid;
    }
}

function deleteLine(component, line) {
    component.deleteLine(line);
}

function buildDeleteButton(component, parent) {
    if(!component.state.isView) {
        return <ImgButton
            alt = "delete"
            image={trash}
            onHoverImage={trashOnHover}
            handleIconClick={() => deleteLine(parent, component.props.id)}
        />
    } else {
        return null;
    }
}

function buildTerminalField(component, globalRule) {
    let field = null;
    if(globalRule){
        field = <LabeledField
                fieldType="dropdown"
                fieldLabel={t('generic.dropgroup.headers.terminal')}
                width="12"
                dataAttribute="displayName"
                isRequired={true}
                hasRequiredAsterisk={true}
                bottomMargin={false}
                isView={component.state.isView}
                className={"p-left-0"}
                selectData={component.state.terminalList}
                onFieldChange={terminal => handleTerminalChange(component, terminal)}
                ref={c => {component.fieldRefs.terminal = c}}
            />;
    }
    return field;
}

function buildLogicalOperatorField(component, actionType) {
    let field = null;
    if(actionType === RuleService.actionType.INPUT){
        field = <LabeledField
                fieldType="dropdown"
                fieldLabel={t('generic.dropgroup.headers.logicOperator')}
                width="12"
                bottomMargin={false}
                isRequired={true}
                className={"p-left-0"}
                hasRequiredAsterisk={true}
                isView={component.state.isView}
                selectData={component.state.logicOperations}
                ref={c => {component.fieldRefs.logicOperator = c}}
            />;
    }
    return field;
}

function buildInterfaceField(component, globalRule, actionType) {
    return (
        <LabeledField
            fieldType="dropdown"
            width="12"
            fieldLabel={t('generic.dropgroup.headers.interface')}
            dataAttribute="displayName"
            dataId={globalRule? "id" : "interfaceId"}
            bottomMargin={false}
            isRequired={true}
            className={"p-left-0"}
            hasRequiredAsterisk={true}
            isView={component.state.isView}
            selectData={filterByActionType(component.state.interfaceList, actionType)}
            onFieldChange={interf => handleInterfaceChange(component, interf, actionType)}
            ref={c => {component.fieldRefs.interfaceId = c}}
        />
    );
}

function buildActionField(component) {
    let dataType = component.state.interfaceDataType;
    let field = null;
    if(!_.isNil(dataType)) {
        switch (dataType) {
            case RuleService.dataType.DECIMAL:
                field = (
                    <LabeledField
                        fieldType="text"
                        fieldLabel={t('generic.dropgroup.headers.action')}
                        fieldPlaceHolder={t('generic.dropgroup.fields.decimalPlaceholder')}
                        bottomMargin={false}
                        width="12"
                        isRequired={true}
                        disabled={false}
                        className={"p-left-0 action-field-height"}
                        hasRequiredAsterisk={true}
                        isView={component.state.isView}
                        validator={() => validateWithRegex(component, config.generic.positiveornegativedecimalregex, t('errors.generic.notADecimal'))}
                        ref={c => {component.fieldRefs.action = c}}
                    />
                );
                break;
            case RuleService.dataType.STRING:
                field = (
                    <LabeledField
                        fieldType="text"
                        fieldLabel={t('generic.dropgroup.headers.action')}
                        fieldPlaceHolder={t('generic.dropgroup.fields.stringPlaceholder')}
                        bottomMargin={false}
                        isRequired={true}
                        hasRequiredAsterisk={true}
                        width="12"
                        className={"p-left-0"}
                        disabled={false}
                        isView={component.state.isView}
                        ref={c => {component.fieldRefs.action = c}}
                    />
                );
                break;
            case RuleService.dataType.BOOLEAN:
                field = (
                    <SwitchField
                        noMarginBottom={true}
                        fieldLabel={t('generic.dropgroup.headers.action')}
                        width='12'
                        className={"p-left-0"}
                        isView={component.state.isView}
                        checkedLabel={component.state.checkedLabelForBooleanAction}
                        uncheckedLabel={component.state.uncheckedLabelForBooleanAction}
                        isChecked={component.state.booleanActionValue}
                        ref={c => {component.fieldRefs.action = c}}
                    />
                );
                break;
            case RuleService.dataType.INTEGER:
                field = (
                    <LabeledField
                        fieldType="text"
                        fieldLabel={t('generic.dropgroup.headers.action')}
                        fieldPlaceHolder={t('generic.dropgroup.fields.integerPlaceholder')}
                        bottomMargin={false}
                        isRequired={true}
                        hasRequiredAsterisk={true}
                        width="12"
                        className={"p-left-0"}
                        disabled={false}
                        isView={component.state.isView}
                        validator={() => validateWithRegex(component, config.generic.positiveornegativenumberregex, t('errors.generic.notANumber'))}
                        ref={c => {component.fieldRefs.action = c}}
                    />
                );
                break;
            default:
                throw new Error("Unknown interface data type: " + dataType);
        }
    } else {
        field = <LabeledField
            fieldType="text"
            fieldLabel={t('generic.dropgroup.headers.action')}
            bottomMargin={false}
            isRequired={true}
            hasRequiredAsterisk={true}
            width="12"
            disabled={true}
            className={"p-left-0"}
            isView={component.state.isView}
            ref={c => {component.fieldRefs.action = c}}
        />
    }
    return field;
}

function handleTerminalChange(component, terminal, afterTerminalIsChanged) {
    if(!_.isNil(terminal)){
        afterTerminalIsChanged = afterTerminalIsChanged? afterTerminalIsChanged : () => {};
        RuleService.getInterfacesFromTerminal(terminal, interfaces => {
            if(!_.isEmpty(component.props.selectedSector) && !component.props.showAllInterfaces) {
                interfaces = _.filter(interfaces, iface => {return iface.entity.id === component.props.selectedSector.id;})
            }
            component.setInterfaceList(interfaces, afterTerminalIsChanged);
        });
    } else {
        component.setInterfaceList([]);
    }
    resetFields(component, true);
}

function handleInterfaceChange(component, interf, actionType, lineData) {
    if(!_.isNil(interf) && !_.isNil(interf.terminalInterfaceDataType)){
        let dataType = interf.terminalInterfaceDataType;
        component.setInterfaceDataType(dataType, () => {
            if(actionType === RuleService.actionType.INPUT) {
                RuleService.getLogicOperationsForDataType(dataType, [RuleService.logicOperator.BETWEEN], operations => {
                    if (dataType === RuleService.dataType.BOOLEAN) {
                        component.setLogicalOperations([RuleService.getObjectFor(RuleService.logicOperator.EQUAL, t('generic.logicOperations.equal'))], () => {
                            component.fieldRefs.logicOperator.setFieldValue(RuleService.logicOperator.EQUAL);
                            if(interf.interfaceUnitType && interf.interfaceUnitType.properties && interf.interfaceUnitType.properties.name) {
                                let name = JSON.parse(interf.interfaceUnitType.properties.name);
                                component.setLabelsForBooleanField(LocalesUtils.getLocaleString(name['true']), LocalesUtils.getLocaleString(name['false']));
                            } else {
                                component.setLabelsForBooleanField(null, null);
                            }
                        });
                    } else {
                        component.setLogicalOperations(operations);
                    }
                    if (!_.isNil(lineData)) {
                        if (!_.isNil(lineData.logicOperator) &&
                            !_.isNil(component.fieldRefs.logicOperator)) {
                            component.fieldRefs.logicOperator.setFieldValue(lineData.logicOperator);
                        }
                        if (!_.isNil(lineData.inputValue)) {
                            let value = lineData.inputValue;
                            if (dataType === RuleService.dataType.BOOLEAN) {
                                value = value === 'true';
                            }
                            component.fieldRefs.action.setFieldValue(value);
                        }
                    }
                }, () => {
                    throw new Error('It was not possible to fetch the logical operations for data type ' + dataType);
                });
            }
            if(!_.isNil(lineData) && !_.isNil(lineData.inputValue)) {
                let value = lineData.inputValue;
                if (dataType === RuleService.dataType.BOOLEAN) {
                    value = value === 'true';
                }
                component.fieldRefs.action.setFieldValue(value);
            }
            updateBooleanFieldLabels(component, interf);
        });
    } else {
        component.setInterfaceDataType(null, () => {
            component.setLogicalOperations([]);
        });
    }
}

function updateBooleanFieldLabels(component, interf, booleanActionValue) {
    if (interf && interf.terminalInterfaceDataType === RuleService.dataType.BOOLEAN) {
        if(interf.interfaceUnitType && interf.interfaceUnitType.properties && interf.interfaceUnitType.properties.name) {
            let name = JSON.parse(interf.interfaceUnitType.properties.name);
            component.setLabelsForBooleanField(LocalesUtils.getLocaleString(name['true']), LocalesUtils.getLocaleString(name['false']), booleanActionValue);
        } else {
            component.setLabelsForBooleanField(null, null, booleanActionValue);
        }
    } else {
        component.setLabelsForBooleanField(null, null, booleanActionValue);
    }
}

function filterByActionType(interfaceList, actionType) {
    return  _.filter(interfaceList, iface => {
        return iface.terminalInterfaceType === getSimpleNameOfActionType(actionType) || iface.terminalInterfaceType === 'IN_OUT';
    });
}

function getSimpleNameOfActionType(actionType) {
    return actionType === 'OUTPUT' ? 'OUT' : 'IN';
}

function resetFields(component, removeAction){
    if(component.fieldRefs.logicOperator) {
        component.fieldRefs.logicOperator.clearFieldValue();
    }
    if(component.fieldRefs.action) {
        if(removeAction) {
            component.setInterfaceDataType(null);
        } else {
            component.fieldRefs.action.clearFieldValue();
        }
    }
}

function validateWithRegex(component, regex, errorMsg) {
    let errorMessage = '';
    if(_.isNil(component.fieldRefs.action)){
        return errorMessage;
    }
    let value = component.fieldRefs.action.getFieldValue();
    if(!regex.test(value)){
        errorMessage = errorMsg;
    }
    return errorMessage;
}

DropGroupLine.propTypes = {
    parent: PropTypes.shape({
        type: DropGroup,
    }).isRequired,
    measures: PropTypes.object,
    lineData: PropTypes.object,
    selectedSector: PropTypes.object
};

export default DropGroupLine;