import axios from "axios";
import _ from "lodash";
import RolesUtils from "../utils/RolesUtils";
import {permissions} from "../utils/permissions";
import UserSessionService from "./UserSessionService";
import RuleCacheService from "./RuleCacheService";
import UserSessionsService from "./UserSessionService";

let UserService = {
    loadUsers: function (searchData, onSuccess, onError) {
        axios.get('/users', {
            params: searchData
        }).then((response) => {
            if (onSuccess) {
                response.data.content.forEach(user => {
                    user.id = user.userId;
                });

                onSuccess(response.data);
            }
        }, (error) => {
            if(onError) {
                onError(error);
            }
        });
    },

    getUserDetails: function (userId, onSuccess, onError) {
        axios.get('/users/' + userId).then((response) => {
            if(onSuccess) {
                response.data.id = response.data.userId;
                response.data.roleId = response.data.role.id;
                response.data.entityId = response.data.rootEntity.id;
                response.data.image = response.data.imageBase64;
                response.data.contacts = _.isNil(response.data.contacts) ? [] : response.data.contacts;
                response.data.contacts.forEach(
                    contact => {
                        if (contact.type === 'WORK_PHONE_NUMBER') {
                            response.data.phoneNumber = contact.phoneNumber;
                        }
                    }
                );
                onSuccess(response.data);
            }
        }, (error) => {
            if(onError) {
                onError(error);
            }
        });
    },

    getUserPersonalDetails: function (onSuccess, onError) {
        axios.get('/users/personal').then((response) => {
            if(onSuccess) {
                response.data.id = response.data.userId;
                response.data.roleId = response.data.role.id;
                response.data.entityId = response.data.rootEntity.id;
                response.data.image = response.data.imageBase64;
                response.data.contacts = _.isNil(response.data.contacts) ? [] : response.data.contacts;
                response.data.contacts.forEach(
                    contact => {
                        switch (contact.type) {
                            case 'POSTAL_ADDRESS':
                                response.data.address1 = contact.addressLine1;
                                response.data.address2 = contact.addressLine2;
                                response.data.region = contact.region;
                                response.data.postalCode = contact.postalCode;
                                response.data.city = contact.city;
                                response.data.country = contact.countryCodeA3;
                                break;
                            case 'WORK_PHONE_NUMBER':
                                response.data.phoneNumber = contact.phoneNumber;
                                break;
                            default:
                                break;
                        }
                    }
                );
                onSuccess(response.data);
            }
        }, (error) => {
            if(onError) {
                onError(error);
            }
        });
    },

    getUserActivationStatus: function (userId, onSuccess, onError) {
        axios.get('/users/activationStatus/' + userId).then((response) => {
            if(onSuccess) {
                onSuccess(response.data);
            }
        }, (error) => {
            if(onError) {
                onError(error);
            }
        });
    },

    deleteUser: function (userId, onSuccess, onError) {
        axios.delete('/users/' + userId).then((response) => {
            if (onSuccess){
                onSuccess(response.data);
            }
        }, (error) => {
            if (onError) {
                onError(error);
            }
        });
    },

    deleteMyUser: function (userId, onSuccess, onError) {
        axios.delete('/users/myUser/' + userId).then((response) => {
            if (onSuccess){
                onSuccess(response.data);
            }
        }, (error) => {
            if (onError) {
                onError(error);
            }
        });
    },

    getUserAccessTree: function (userId, editedUser, rootEntity, onSuccess, onError) {
        axios.get('/users/' + userId + '/accessTree', {
            params: {
                editedUser: editedUser,
                rootEntity: rootEntity,
            }
        }).then((response) => {
            if (onSuccess) {
                onSuccess(response.data);
            }
        }, (error) => {
            if(onError) {
                onError(error);
            }
        });
    },

    refreshUserAccessTree: function (){
        let rootEntity = UserSessionService.getCurrentUserRootEntity();
        if(rootEntity){
            axios.get( "/dashboard/" + rootEntity.id + "/tree").then((response) => {
                UserSessionsService.createLocalStorageEntry(RuleCacheService.prefixes.userEntityTree, JSON.stringify(response.data))
            }, (error) => {
                console.error(error)
            });
        }
    },

    getUserAccessEntities: function (userId, onSuccess, onError) {
        axios.get('/users/' + userId + '/entities', {
            params: {
                entityTypeGroups: 'entityTypeGroups'
            }
        }).then((response) => {
            if (onSuccess) {
                onSuccess(response.data);
            }
        }, (error) => {
            if (onError) {
                onError(error);
            }
        });
    },

    resetPassword: function (userEmail, onSuccess, onError) {
        axios.post('/users/password_reset', {
            email: userEmail,
            host: window.location.origin
        }).then((response) => {
            if (onSuccess) {
                onSuccess(response.data);
            }
        }, (error) => {
            if(onError) {
                onError(error);
            }
        });
    },

    getUsersList: function (onSuccess, onError) {
        if(RolesUtils.hasPermissionForName(permissions.users.read) || RolesUtils.hasPermissionForName(permissions.users.list)) {
            axios.get('/users/list').then((response) => {
                if (onSuccess) {
                    onSuccess(_.sortBy(response.data, 'shortName'));
                }
            }, (error) => {
                if (onError) {
                    onError(error);
                }
            });
        } else {
            onSuccess([{ 'shortName': UserSessionService.getShortName(), 'userId': UserSessionService.getCurrentUserId() }]);
        }
    },

    getActiveUsersList: function (onSuccess, onError, useCache=true) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};
        if(RolesUtils.hasPermissionForName(permissions.users.read) || RolesUtils.hasPermissionForName(permissions.users.list)) {
            let cachedInfo = RuleCacheService.getActiveUsers();
            if(useCache && cachedInfo) {
                setTimeout(() => onSuccess(cachedInfo), 0);
            } else {
                axios.get('/users/list', {
                    params: {
                        status: 'ACTIVE'
                    }
                }).then(response => {
                    let users = response.data.map(u => {
                        return {
                            ...u,
                            id: u.userId
                        }
                    })
                    if(useCache) {
                        RuleCacheService.storeActiveUsers(_.sortBy(users, 'shortName'));
                        onSuccess(_.sortBy(users, 'shortName'));
                    } else {
                        onSuccess(_.sortBy(users, 'shortName'));
                    }
                }, error => {
                    onError(error);
                });
            }
        } else {
            onSuccess([{ 'shortName': UserSessionService.getShortName(), 'userId': UserSessionService.getCurrentUserId() }]);
        }
    },

    updatePassword: function (userId, data, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        axios.put('/users/' + userId + '/password', data).then((response) => {
            onSuccess(response.data);
        }, (error) => {
            onError(error);
        })
    },

    createUser: function (userData, onSuccess, onError) {
        let formBox = new FormData();
        formBox.append('file', userData.image);
        userData.image = null;
        userData.host = window.location.origin
        formBox.append('createUserRequest', new Blob([JSON.stringify(userData)], {type: "application/json"}));
        axios.post('/users', formBox).then((response) => {
            if(onSuccess) {
                onSuccess(response.data);
            }
        }, (error) => {
            if(onError) {
                onError(error);
            }
        });
    },

    editUser: function (userId, data, onSuccess, onError) {
        let formBox = new FormData();
        formBox.append('file', data.image);
        data.image = null;
        formBox.append('editUserRequest', new Blob([JSON.stringify(data)], {type: "application/json"}));
        axios.put('/users/' + userId,formBox).then((response) => {
            if(onSuccess) {
                onSuccess(response.data);
            }
        }, (error) => {
            if(onError) {
                onError(error);
            }
        });
    },

    editUserProfile: function (userId, data, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};

        let formBox = new FormData();
        formBox.append('file', data.image);
        data.image = null;
        formBox.append('editUserRequest', new Blob([JSON.stringify(data)], {type: "application/json"}));
        axios.put('/users/personal', formBox).then((response) => {
            onSuccess(response.data);
        }, (error) => {
            onError(error);
        });
    },

    userAcceptTerms: function (onSuccess, onError) {
        axios.get('/users/accept-terms').then((response) => {
            if(onSuccess) {
                onSuccess(response.data);
            }
        }, (error) => {
            if(onError) {
                onError(error);
            }
        });
    },

    knowledgeVersion: function (onSuccess, onError) {
        axios.get('/users/knowledge-version').then((response) => {
            if(onSuccess) {
                onSuccess(response.data);
            }
        }, (error) => {
            if(onError) {
                onError(error);
            }
        });
    },

    validatePhoneNumber: function (phoneNumber, onSuccess, onError) {
        axios.get('/users/validate-phone/' + phoneNumber).then((response) => {
            if(onSuccess) {
                onSuccess(response.data);
            }
        }, (error) => {
            if(onError) {
                onError(error);
            }
        });
    },

    getNotificationPreferences: function (onSuccess, onError) {
        axios.get('/users/preferences/notifications').then((response) => {
            if(onSuccess) {
                onSuccess(response.data);
            }
        }, (error) => {
            if(onError) {
                onError(error);
            }
        });
    },

    getSubscriptionPreferences: function (onSuccess, onError) {
        axios.get('/users/preferences/subscriptions').then((response) => {
            if(onSuccess) {
                onSuccess(response.data);
            }
        }, (error) => {
            if(onError) {
                onError(error);
            }
        });
    }
};

export default UserService;