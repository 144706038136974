import React from "react";
import {ThemeContext} from "../context/ThemeProvider";
import "./style.scss";
import ErrorService from "../services/ErrorService";
import {t} from "../utils/Translation";

class ErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError(error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    componentDidCatch(error, errorInfo) {
        // You can also log the error to an error reporting service
        ErrorService.createError(error.stack, JSON.stringify(errorInfo));
    }

    render() {
        const {
            images: {
                loginLogo
            }
        } = this.props.context;

        if (this.state.hasError) {
            return (
                <div className="error-page center">
                    <img alt="farm-logo" src={loginLogo}/>
                    <h1>{t("generic.error.description")}</h1>
                    <p>{t("generic.error.moreInfo")}</p>
                    <a href={"/"}><h3 className="font-bold">{t("generic.back")}</h3></a>
                </div>
            );
        }

        return this.props.children;
    }
}

/* eslint import/no-anonymous-default-export: [2, {"allowArrowFunction": true}] */
export default (props) => (
    <ThemeContext.Consumer>
        {(context) => <ErrorBoundary {...props} context={context}/>}
    </ThemeContext.Consumer>
)