import React from 'react';
import AccordionContent from './AccordionContent';
import AccordionTitle from './AccordionTitle';
import _ from 'lodash';

class AccordionPanel extends React.Component {
    constructor(props) {
        super(props);

        this.titlesRefs = {};
        this.contentsRefs = {};

        if (props.accordionProperties.length !== props.accordionPanels.length) {
            throw new Error("The number of titles must be equal to the number of panels");
        }

        this.state = {
            accordionProperties: [],
            accordionPanels: [],
            accordionKeys: [],
            activeAccordionPosition: -1,
            accordionTitleTextAlignment: props.accordionTitleTextAlignment ? props.accordionTitleTextAlignment : "left"
        };
    }

    componentWillMount() {
        this.updateAccordionTitlesAndContent(this.props, true);
    }

    componentWillReceiveProps(nextProps) {
        this.updateAccordionTitlesAndContent(nextProps, true);
    }

    updateAccordionTitlesAndContent(props) {
        let accordionProperties = [];
        let accordionPanels = [];
        let self = this;
        for (let i = 0; i < props.accordionProperties.length; i++) {
            let key = !_.isNil(props.accordionKeys[i])? props.accordionKeys[i] : i;
            accordionProperties.push(
                <AccordionTitle
                    accordionTitleTextAlignment={this.state.accordionTitleTextAlignment}
                    key={"accordionTitle-" + key}
                    position={i}
                    enabled={props.accordionProperties[i].enabled}
                    hasSwitchArea={props.hasSwitchArea}
                    hasSwitchAreaDisable={props.hasSwitchAreaDisable}
                    text={props.accordionProperties[i].title}
                    hasAdditionalCost={props.accordionProperties[i].hasCost}
                    onClick={() => {
                        self.titlesRefs[key].toggleVisibility();
                        self.contentsRefs[key].toggleVisibility();
                    }}
                    customIcon={props.customIcon}
                    type={key}
                    panelHeadCustomClass={props.panelHeadCustomClass}
                    ref={c => this.titlesRefs[key] = c}
                />
            );
            accordionPanels.push(
                <AccordionContent
                    key={"accordionContent-" + key}
                    position={i}
                    customClass={props.customClass}
                    customStyle={props.customStyle}
                    componentToShow={props.accordionPanels[i]}
                    active={props.active}
                    ref={c => this.contentsRefs[key] = c}
                />
            );

        }
        this.setState({
            accordionProperties : accordionProperties,
            accordionPanels : accordionPanels
        });
    }

    render() {
        let {
            customClass = "",
            customStyle,
            panelGroupCustomClass,
            isTreeHeaderDashboard = false
        } = this.props;

        if(isTreeHeaderDashboard) {
            customStyle = {
                ...customStyle,
                paddingTop: 0
            };
        }

        return (
            <div>
                <div className={"panel-body " + customClass} style={customStyle}>
                    <div className={"panel-group " + panelGroupCustomClass}>
                        {listAccordions(this)}
                    </div>
                </div>
            </div>
        );
    }

    setAccordionStatus(key, value) {
        if(!_.isNil(this.titlesRefs[key])){
            this.titlesRefs[key].setState({enabled: value});
        }
    }

    getAccordionStates() {
        let states = [];
        _.forOwn(this.titlesRefs, (field, key) => {
            states.push({'key': key, 'value': field.state.enabled});
        });

        return states;
    }

}

function listAccordions(component) {
    let accordionList = [];
    for (let i = 0; i < component.state.accordionProperties.length; i++) {
        let style = i > 0 ? {marginTop: "0px", borderTopWidth: "0px"} : {} ;
        accordionList.push(
            <div key={"accordionGroup-" + i} className="panel panel-default" style={style}>
                {component.state.accordionProperties[i]}
                {component.state.accordionPanels[i]}
            </div>
        );
    }
    return accordionList;
}


export default AccordionPanel;