import axios from "axios";
import * as actionTypes from "./actionTypes";
import {call, put, takeEvery} from 'redux-saga/effects';
import {config} from "../../../../config";
import moment from "moment";
import TimezoneUtils from "../../../../utils/TimezoneUtils";

const controllerUrl = '/batches/manualWeighing';

const getManualWeighingCall = (manualWeighingId) => new Promise ((resolve, reject) => {
    axios.get(controllerUrl + "/" + manualWeighingId).then(
        response => resolve(response.data),
        error => reject(error)
    )
});

const createManualWeighingCall = manualWeighingData => new Promise ((resolve, reject) => {
    axios.post(controllerUrl, manualWeighingData).then(
        response => resolve(response.data),
        error => reject(error)
    )
});

const editManualWeighingCall = (manualWeighingId, manualWeighingData) => new Promise ((resolve, reject) => {
    axios.put(controllerUrl + "/" + manualWeighingId, manualWeighingData).then(
        response => resolve(response.data),
        error => reject(error)
    )
});

const deleteManualWeighingCall = (manualWeighingId) => new Promise ((resolve, reject) => {
    axios.delete(controllerUrl + "/" + manualWeighingId).then(
        response => resolve(response.data),
        error => reject(error)
    )
});

function* getManualWeighingRequest({ manualWeighingId }) {
    try {
        const manualWeighing = yield call(getManualWeighingCall, manualWeighingId);
        yield put ({
            type: actionTypes.GET_MANUAL_WEIGHING_SUCCESS,
            payload: manualWeighing
        })
    } catch (e) {
        yield put ({
            type: actionTypes.GET_MANUAL_WEIGHING_FAILED,
            payload: e
        })
    }
}

function* createManualWeighingRequest({ manualWeighingData }){
    try {
        manualWeighingData.timestamp = manualWeighingData.timestamp instanceof moment ? manualWeighingData.timestamp.format(config.datepicker.formats.isoDateTime) : manualWeighingData.timestamp;

        let response = yield call(createManualWeighingCall, manualWeighingData);

        if(response.deferredStatus) {
            yield put({
                type: actionTypes.CREATE_MANUAL_WEIGHING_DEFERRED_STATUS,
                payload: response.deferredStatus
            })
        } else {
            yield put({
                type: actionTypes.CREATE_MANUAL_WEIGHING_SUCCESS
            })
        }
    } catch(e) {
        yield put({
            type: actionTypes.CREATE_MANUAL_WEIGHING_FAILED,
            payload: e
        })
    }
}

function* editManualWeighingRequest({ manualWeighingId, manualWeighingData }){
    try {
        if(manualWeighingData.timestamp instanceof moment) {
            manualWeighingData.timestamp = TimezoneUtils.convertUserTimezoneToUTCFormat(manualWeighingData.timestamp).format(config.datepicker.formats.isoDateTime);
        }

        yield call(editManualWeighingCall, manualWeighingId, manualWeighingData);

        yield put({
            type: actionTypes.EDIT_MANUAL_WEIGHING_SUCCESS
        })
    } catch (e) {
        yield put({
            type: actionTypes.EDIT_MANUAL_WEIGHING_FAILED,
            payload: e
        })
    }
}

function* deleteManualWeighingRequest({ manualWeighingId }) {
    try {
        yield call(deleteManualWeighingCall, manualWeighingId);

        yield put ({
            type: actionTypes.DELETE_MANUAL_WEIGHING_SUCCESS,
        })
    } catch (e) {
        yield put ({
            type: actionTypes.DELETE_MANUAL_WEIGHING_FAILED,
            payload: e
        })
    }
}

const manualWeighingSagas = [
    takeEvery(actionTypes.GET_MANUAL_WEIGHING_REQUEST, getManualWeighingRequest),
    takeEvery(actionTypes.CREATE_MANUAL_WEIGHING_REQUEST, createManualWeighingRequest),
    takeEvery(actionTypes.EDIT_MANUAL_WEIGHING_REQUEST, editManualWeighingRequest),
    takeEvery(actionTypes.DELETE_MANUAL_WEIGHING_REQUEST, deleteManualWeighingRequest),
];

export {
    manualWeighingSagas
}