import React from 'react';
import classnames from 'classnames';
import {t} from "../../../utils/Translation";
import _ from "lodash";
import __ from "../../../utils/EnhancedLodash";
import DropGroupLine from "./DropGroupLine";
import PropTypes from 'prop-types';
import uuid from './../../../utils/uuid'
import Row from "../../row/Row";
import RuleService from "../../../services/RuleService";
import RuleCacheService from "../../../services/RuleCacheService";
import LabeledField from "../labeled/LabeledField";
import {config} from "../../../config";
import moment from "moment";
import classNames from "classnames";

class DropGroup extends React.Component {
    constructor(props) {
        super(props);

        this.lineRefs = {};

        this.measures = getMeasures(props.globalRule, props.actionType);

        this.state = {
            lines: props.lines? props.lines : [],
            globalRule: !_.isNil(this.props.globalRule) ? this.props.globalRule : false,
            actionType: this.props.actionType,
            interfaceList: this.props.interfaceList ? this.props.interfaceList : [],
            terminalList: this.props.terminalList? this.props.terminalList : [],
            showAllInterfaces: this.props.showAllInterfaces || false,
            error: null,
            permanenceTime: '00:00',
            repetitionTime: '00:00',
            notificationLimit: '3',
            permanenceTimeError: null,
            repetitionTimeError: null,
            notificationLimitError: null
        }
    }

    componentDidMount() {
        if(!this.props.globalRule && this.props.selectedEntity && this.props.selectedTerminal) {
            let self = this;
            RuleService.getInterfacesWithCategory(self.props.selectedEntity.id, self.props.selectedTerminal.id, null, null, interfaces => {
                if(this.props.selectedSector && !this.state.showAllInterfaces) {
                    interfaces = _.filter(interfaces, iface => { return iface.entity.id === this.props.selectedSector.id; })
                }
                self.setInterfaceList(interfaces);
            }, () => {
                throw new Error("It was not possible to fetch the Interface List");
            });
        }
    }

    componentWillReceiveProps(nextProps) {
        let permanenceTime = this.state.permanenceTime;
        let repetitionTime = this.state.repetitionTime;
        let notificationLimit = this.state.notificationLimit;
        let self = this;
        self.lineRefs = {};
        let newLines = nextProps.lines? nextProps.lines : this.state.lines;
        if( nextProps.selectedEntity !== this.props.selectedEntity || nextProps.selectedSector !== this.props.selectedSector ||
            (!this.state.globalRule &&  nextProps.selectedTerminal !== this.props.selectedTerminal)) {
                newLines = [];
            }
        if(!_.isEmpty(newLines)) {
            newLines = _.map(newLines, line => {
                line.isView = nextProps.isView;
                return line;
            });

        }
        this.setState(prevState => (
            {
                ...prevState,
                lines: newLines,
                permanenceTime: permanenceTime,
                repetitionTime: repetitionTime,
                notificationLimit: notificationLimit
            })
        );
    }

    setError(error, afterErrorIsSet = () => { }) {
        this.setState({error: error}, afterErrorIsSet);
    }

    setLines(lines, lineRefs) {
        this.setState({lines: lines}, () => {
            if (lineRefs) {
                this.lineRefs = lineRefs;
            }
        });
    }

    setInterfaceList(interfaces) {
        let lines = this.state.lines;
        lines = _.map(lines, line => {
            line.interfaceList = interfaces;
            return line;
        })
        this.setState({interfaceList: interfaces, lines: lines});
    }

    setTerminalList(terminals) {
        let lines = this.state.lines;
        lines = _.map(lines, line => {
            line.terminalList = terminals;
            return line;
        })
        this.setState({terminalList: terminals, lines: lines});
    }

    deleteLine(lineId) {
        let dropGroupLines = this.state.lines;
        let dropGroupLineRefs = this.lineRefs;

        dropGroupLines = _.filter(dropGroupLines, line => {
            return line.id !== lineId;
        });

        let newRefs = Object.entries(dropGroupLineRefs).reduce(
            (acc, refIdPair) => {
                if(refIdPair[0] !== lineId) {
                    return {
                        ...acc,
                        [refIdPair[0]]: refIdPair[1]
                    }
                }
                return null;
            },
            {}
        );
        this.setLines(dropGroupLines, newRefs);
    }

    render() {
        let self = this;
        let dropGroupLines = buildDropGroupLines(this);
        let error = this.state.error? <Row className="pd-l-20 mb-15 has-error-color"><span><i>{this.state.error}</i></span></Row> : null;

        return (
            <>
                <div className="row form-group">
                    <Row className="row pd-l-20 form-group">
                        {getAddButton(self)}
                    </Row>
                    {error}
                    <div>
                        {dropGroupLines}
                    </div>
                    <div>
                        {renderPermanenceAndRepetitionPeriods(this)}
                    </div>
                </div>
            </>

        );
    }

    getFieldValue() {
        let result = [];
        _.forOwn(this.lineRefs, line => {
            if(!_.isNil(line)){
                let lineValue = line.getLineValue();
                if(!_.isNil(lineValue)) {
                    result.push(lineValue)
                }
            }
        });

        return result;
    }

    validateField() {
        let isValid = (this.props.isRequired && this.state.lines.length > 0 &&
            this.state.permanenceTime && this.state.repetitionTime && !isNaN(this.state.notificationLimit)) || !this.props.isRequired;
        if(isValid) {
            isValid = checkFieldValidity(this.lineRefs)
            if(!isValid){
                this.setError(t('generic.dropgroup.error.generic'),
                    () => checkFieldValidity(this.lineRefs)
                );
            }
        } else {
            if(!this.state.permanenceTime) {
                this.setState({permanenceTimeError: t('generic.dropgroup.error.emptyRequiredField')})
            }else if (!this.state.repetitionTime) {
                this.setState({repetitionTimeError: t('generic.dropgroup.error.emptyRequiredField')})
            }else if (!this.state.notificationLimit) {
                    this.setState({notificationLimitError:  t('generic.dropgroup.error.emptyRequiredField')})

            }else {
                this.setError(t('generic.dropgroup.error.noLines'))
            }
        }

        return isValid;
    }

    setFieldValue(data){
        let dropGroupLines =[];
        let self = this;
        _.forEach(data, lineData => {
            let cachedInterfaces = RuleCacheService.getInterfacesOfTerminal(lineData.terminalId);
            let interfaceObj;
            if(!_.isEmpty(cachedInterfaces)) {
                interfaceObj = _.find(cachedInterfaces, iface => {
                    return self.state.globalRule? lineData.interfaceId === iface.id : lineData.interfaceId === iface.interfaceId;
                });
            } else if (!_.isEmpty(self.state.interfaceList)) {
                interfaceObj = _.find(self.state.interfaceList, iface => {
                    return self.state.globalRule? lineData.interfaceId === iface.id : lineData.interfaceId === iface.interfaceId;
                });
            }
            dropGroupLines.push(
                getLine(
                    !_.isEmpty(self.state.interfaceList) ? self.state.interfaceList : cachedInterfaces,
                    self.state.terminalList,
                    interfaceObj ? interfaceObj.terminalInterfaceDataType: null,
                    interfaceObj ?
                        interfaceObj.terminalInterfaceDataType === RuleService.dataType.BOOLEAN ?
                            [RuleService.getObjectFor(RuleService.logicOperator.EQUAL, t('generic.logicOperations.equal'))] :
                            RuleCacheService.getLogicalOperationsForDataType(interfaceObj.terminalInterfaceDataType) :
                        null,
                    lineData
                    ));
        });
        if(!__.isNilOrEmpty(dropGroupLines)){
            self.setState({
                permanenceTime: dropGroupLines[0].lineData?.permanenceTime || '00:00',
                repetitionTime: dropGroupLines[0].lineData?.repetitionTime || '00:00',
                notificationLimit: dropGroupLines[0].lineData?.notificationLimit ? dropGroupLines[0].lineData.notificationLimit : 0
            })
        }
        self.setLines(dropGroupLines);
    }
}

function checkFieldValidity(lines) {
    let isValid = true;
    _.forOwn(lines, line => {
        if(!_.isNil(line)){
            if(!line.validateLine()){
                isValid = false;
            }
        }
    })

    return isValid;
}

function getAddButton(component) {
    let button = null;
    if(!component.props.isView){
        button = <button
            type="button"
            className={classnames("form-group", "col-lg-2", "btn", "btn-w-m", "btn-primary", "mb-15")}
            onClick={() => addNewLine(component)}>
            {component.props.buttonLabel? component.props.buttonLabel : t("generic.dropgroup.addDevice")}
        </button>;
    }
    return button;
}

function buildDropGroupLines (component) {
    let dropGroupLines = component.state.lines;
    let lines = [];

    for (let i = 0; i < dropGroupLines.length; i++) {
        lines.push(
            <DropGroupLine
                key={dropGroupLines[i].key}
                id={dropGroupLines[i].id}
                parent={component}
                interfaceList={dropGroupLines[i].interfaceList}
                terminalList={dropGroupLines[i].terminalList}
                measures={component.measures}
                selectedSector={component.props.selectedSector}
                interfaceDataType={dropGroupLines[i].interfaceDataType}
                logicOperations={dropGroupLines[i].logicOperations} 
                actionType={component.props.actionType} 
                isView={component.props.isView}
                permanenceTime={component.state.permanenceTime}
                repetitionTime={component.state.repetitionTime}
                notificationLimit={component.state.notificationLimit}
                lineData={dropGroupLines[i].lineData}
                ref={c => component.lineRefs[dropGroupLines[i].key] = c}
                showAllInterfaces={component.props.showAllInterfaces}
            />
        );
    }

    if(dropGroupLines.length === 0 && component.state.actionType === RuleService.actionType.INPUT){
        addNewLine(component)
    }

    return lines;
}

function addNewLine(component) {
    let dropGroupLines = component.state.lines;
    dropGroupLines.push( getLine(component.state.interfaceList, component.state.terminalList) );
    component.setLines(dropGroupLines);
}

function renderPermanenceAndRepetitionPeriods(component) {
    if(component.state.actionType === RuleService.actionType.INPUT) {
        const isMobile = window.matchMedia("(min-width: 200px) and (max-width: 1200px)").matches;
        return (
            <div className="flex flex-wrap">
                <div className={classNames('col-lg-3 col-xs-12 pd-r-0', {"pd-b-15": isMobile})} style={{minWidth: '230px'}}>
                    <LabeledField
                        forceNewPropValue={true}
                        fieldPlaceHolder={'HH:mm'}
                        fieldLabel={t('alarmRules.form.permanenceTime.placeholder')}
                        fieldType='dateTime'
                        width= "12"
                        className="p-left-0"
                        isRequired={true}
                        hasRequiredAsterisk={true}
                        hasDate={false}
                        hasTime={true}
                        hasSeconds={false}
                        bottomMargin={false}
                        iconClassName={"fa fa-calendar"}
                        keepDatetimeValuesInUTC={true}
                        isView={component.props.isView}
                        tooltip={t('alarmRules.form.permanenceTime.tooltip')}
                        value={component.state.permanenceTime}
                        error={component.state.permanenceTimeError}
                        onFieldChange={(value) => {component.setState(prevState => {
                            return{
                                ...prevState,
                                permanenceTime: value && moment(value).isValid() ? value.format(config.datepicker.formats.timeWithoutSeconds) : value,
                                permanenceTimeError: null
                            }})}}
                    />
                </div>
                <div className={classNames('col-lg-3 col-xs-12 pd-r-0', {"pd-b-15": isMobile})}>
                    <LabeledField
                        forceNewPropValue={true}
                        fieldPlaceHolder={'HH:mm'}
                        fieldLabel={t('alarmRules.form.repeatTime.placeholder')}
                        fieldType='dateTime'
                        width= "12"
                        className="p-left-0"
                        isRequired={true}
                        hasRequiredAsterisk={true}
                        hasDate={false}
                        hasTime={true}
                        hasSeconds={false}
                        bottomMargin={false}
                        iconClassName={"fa fa-calendar"}
                        keepDatetimeValuesInUTC={true}
                        isView={component.props.isView}
                        tooltip={t('alarmRules.form.repeatTime.tooltip')}
                        value={component.state.repetitionTime}
                        error={component.state.repetitionTimeError}
                        onFieldChange={(value) => {component.setState(prevState => {
                            return{
                                ...prevState,
                                repetitionTime: value && moment(value).isValid() ? value.format(config.datepicker.formats.timeWithoutSeconds) : value,
                                repetitionTimeError: null
                            }})}}
                    />
                </div>
                <div className={classNames('col-lg-2 col-xs-12 pd-r-0', {"pd-b-15": isMobile})} style={{minWidth: '230px'}}>
                    <LabeledField
                        forceNewPropValue={true}
                        fieldPlaceHolder={t('alarmRules.form.notificationLimit.placeholder')}
                        fieldLabel={t('alarmRules.form.notificationLimit.placeholder')}
                        fieldType='number'
                        width= "12"
                        className="p-left-0"
                        isRequired={true}
                        hasRequiredAsterisk={true}
                        bottomMargin={false}
                        isView={component.props.isView}
                        min={0}
                        tooltip={t('alarmRules.form.notificationLimit.tooltip')}
                        value={component.state.notificationLimit}
                        error={component.state.notificationLimitError}
                        onFieldChange={(value) => {component.setState(prevState => {
                            return{
                                ...prevState,
                                notificationLimit: value,
                                notificationLimitError: null
                            }})}}
                    />
                </div>
            </div>
        )
    }

    return null;
}

function getMeasures(globalRule, actionType) {
    let result = {
        terminalField: '',
        interfaceField: '',
        logicalOperatorField: '',
        actionField: ''
    };

    if(globalRule) {
        if(actionType === 'INPUT') {
            result.terminalField = '25%';
            result.interfaceField = '25%';
            result.logicalOperatorField = '22.5%';
            result.actionField = '22.5%';
        } else if (actionType === 'OUTPUT') {
            result.terminalField = '30%';
            result.interfaceField = '30%';
            result.actionField = '25%';
        }
    } else {
        if(actionType === 'INPUT') {
            result.interfaceField = '35%';
            result.logicalOperatorField = '30%';
            result.actionField = '30%';
        } else if (actionType === 'OUTPUT') {
            result.interfaceField = '60%';
            result.actionField = '35%';
        }
    }

    return result;
}

function getLine(interfaceList, terminalList, dataType, logicOperations, lineData) {
    let id = uuid();
    return {
        key: id,
        id: id,
        interfaceList: interfaceList,
        terminalList: terminalList,
        interfaceDataType: dataType,
        logicOperations: logicOperations,
        lineData: lineData? lineData : {}
    }
}

DropGroup.propTypes = {
    actionType: PropTypes.oneOf(['INPUT','OUTPUT']).isRequired,
    globalRule: PropTypes.bool,
    isView: PropTypes.bool,
    data: PropTypes.object,
    interfaceList: PropTypes.array,
    terminalList: PropTypes.array,
    buttonLabel: PropTypes.string,
    selectedSector: PropTypes.object
};

export default DropGroup;