import React from "react";
import './errorDisplay.scss'

const ErrorDisplay = ({errors}) => {
    const errorArr = [...errors];
    return (
        <div className="error-section">
            <ul className="error-list">
                {errorArr.map((error, idx) => (
                    <li key={idx} className="error-item">
                        <div className="error-icon">&#9888;</div>
                        <div className="error-message">{error}</div>
                    </li>
                ))}
            </ul>
        </div>
    );
}

export default ErrorDisplay;