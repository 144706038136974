import {call, put, takeEvery} from 'redux-saga/effects';
import * as counterActionTypes from './actionTypes';
import * as actionTypes from './../../actionTypes';
import AsyncEventService from "../../../../services/async/AsyncEventService";
import AsyncRuleService from "../../../../services/async/AsyncRuleService";

const getEventsCounter = farms => AsyncEventService.countEvents({
    entity: farms,
    status: ["OPEN"]
});

const getRulesCounter = farms => AsyncRuleService.countRules({
    entityId: farms,
    status: ["ENABLED"]
});

const getRulesDeployedCounter = farms => AsyncRuleService.countRules({
    entityId: farms,
    status: ["ENABLED"],
    ruleDeployment: ["DEPLOYED"]
});

function* getCounterWidgetEventsRequest({ id, farms = [] }) {
    try {
        const counterData = yield call(getEventsCounter, farms);
        yield put({
            type: actionTypes.LOAD_WIDGET_DATA_SUCCESS,
            id: id,
            data: {
                total: counterData
            }
        })
    } catch (e) {
        yield put({
            id: id,
            type: actionTypes.LOAD_WIDGET_DATA_FAILED
        });
    }
}

function* getCounterWidgetRulesRequest({ id, farms = [] }) {
    try {
        const counterData = yield call(getRulesCounter, farms);
        const counterDeployRules = yield call(getRulesDeployedCounter, farms);
        yield put({
            type: actionTypes.LOAD_WIDGET_DATA_SUCCESS,
            id: id,
            data: {
                total: counterData,
                deployed: counterDeployRules
            }
        })
    } catch (e) {
        yield put({
            id: id,
            type: actionTypes.LOAD_WIDGET_DATA_FAILED
        });
    }
}

const counterSagas = [
    takeEvery(counterActionTypes.LOAD_EVENTS_COUNTER_WIDGET_DATA_REQUEST, getCounterWidgetEventsRequest),
    takeEvery(counterActionTypes.LOAD_RULES_COUNTER_WIDGET_DATA_REQUEST, getCounterWidgetRulesRequest)
];

export { counterSagas };