import _ from "lodash";
import {config} from "../config";
import moment from "moment";

let UserSessionService = {
    storeAuthInfo: function (info) {
        localStorage.setItem('farmcontrol-session', JSON.stringify(info));
        localStorage.setItem('lastRequestTimeStamp', moment().utc().format(config.datepicker.formats.isoDateTime));
    },

    removeAuthInfo: function () {
        localStorage.clear();
        sessionStorage.clear();
    },

    getAppVersion: async function () {
        let response = await fetch('/meta.json?' + moment().valueOf(), { cache: 'no-cache' });
        let metaJsonFile = await response.json()
        if (metaJsonFile) {
            return metaJsonFile.version + "." + metaJsonFile.date;
        }
    },

    getSession: function () {
        let sessionJson = localStorage.getItem('farmcontrol-session');
        if (_.isNil(sessionJson)) {
            return null;
        } else {
            return JSON.parse(sessionJson);
        }
    },

    getSessionToken: function () {
        return !_.isNil(this.getSession()) ? this.getSession().sessionToken : null;
    },

    isAuthenticated: function () {
        return !_.isNil(this.getSessionToken());
    },

    getShortName: function () {
        return !_.isNil(this.getSession()) ? this.getSession().shortName : null;
    },

    getCurrentUserId: function () {
        return !_.isNil(this.getSession()) ? this.getSession().userId : null;
    },

    getHelpdeskUserId: function () {
        return !_.isNil(this.getSession()) ? this.getSession().helpdeskEmail : null;
    },

    getCurrentUserLanguage: function () {
        return !_.isNil(this.getSession()) ? this.getSession().language : "en_US";
    },

    getSmsServiceStatus: function () {
        let session = this.getSession();
        if (_.isNil(session)) {
            return null;
        } else {
            return (session.smsEnabled === 'true');
        }
    },

    getWhatsappServiceStatus: function () {
        let session = this.getSession();
        if (_.isNil(session)) {
            return null;
        } else {
            return (session.whatsappEnabled === 'true');
        }
    },

    getVoiceServiceStatus: function () {
        let session = this.getSession();
        if (_.isNil(session)) {
            return null;
        } else {
            return (session.voiceEnabled === 'true');
        }
    },

    setCurrentUserLanguage: function (language) {
        let session = this.getSession();
        if (!_.isNil(session)) {
            session.language = language;
            UserSessionService.storeAuthInfo(session);
        }
    },

    setCurrentUserShortName: function (shortName) {
        let session = this.getSession();
        if (!_.isNil(session)) {
            session.shortName = shortName;
            UserSessionService.storeAuthInfo(session);
        }
    },

    getCurrentUserTimezone: function () {
        let session = this.getSession();
        if (_.isNil(session)) {
            return Intl.DateTimeFormat().resolvedOptions().timeZone;
        } else {
            return session.timeZone;
        }
    },

    setCurrentUserTimezone: function (timeZone) {
        let session = this.getSession();
        if (!_.isNil(session)) {
            session.timeZone = timeZone;
            UserSessionService.storeAuthInfo(session);
        }
    },

    getEntityTypeList: function () {
        let typeListJson = localStorage.getItem('entityTypeList');
        if (_.isNil(typeListJson)) {
            return null;
        } else {
            return JSON.parse(typeListJson);
        }
    },

    storeEntityTypeList: function (entityTypeList) {
        localStorage.setItem('entityTypeList', JSON.stringify(entityTypeList));
    },

    getDefaultInterfaceCategories: function () {
        let categoriesListJson = localStorage.getItem('defaultInterfaceCategories');
        if (_.isNil(categoriesListJson)) {
            return null;
        } else {
            return JSON.parse(categoriesListJson);
        }
    },

    getAllInterfaceCategories: function () {
        let categoriesListJson = localStorage.getItem('allInterfaceCategories');
        if (_.isNil(categoriesListJson)) {
            return null;
        } else {
            return JSON.parse(categoriesListJson);
        }
    },

    storeDefaultInterfaceCategories: function (categories) {
        localStorage.setItem('defaultInterfaceCategories', JSON.stringify(categories));
    },

    storeAllInterfaceCategories: function (categories) {
        localStorage.setItem('allInterfaceCategories', JSON.stringify(categories));
    },

    getRuleTemplateListForEntity: function (entityId) {
        entityId = entityId? entityId : 'all';
        let templateListJson = sessionStorage.getItem('ruleTemplateList.' + entityId);
        if (_.isNil(templateListJson)) {
            return null;
        } else {
            return JSON.parse(templateListJson);
        }
    },

    storeRuleTemplateListForEntity: function (entityId, ruleTemplateList) {
        entityId = entityId? entityId : 'all';
        sessionStorage.setItem('ruleTemplateList.' + entityId, JSON.stringify(ruleTemplateList));
    },

    getCurrentUserPermissions: function () {
        let session = this.getSession();
        if (_.isNil(session)) {
            return [];
        } else {
            return session.permissions;
        }
    },

    getCurrentUserRootEntity: function () {
        let session = this.getSession();
        if (_.isNil(session)) {
            return null;
        } else {
            return session.rootEntity;
        }
    },

    storeExpandedEntityList: function (entityTypeList) {
        localStorage.setItem('expandedEntityTypeList', JSON.stringify(entityTypeList));
    },

    getExpandedEntityList: function () {
        let typeListJson = localStorage.getItem('expandedEntityTypeList');
        if (_.isNil(typeListJson)) {
            return [];
        } else {
            return JSON.parse(typeListJson);
        }
    },

    storeInterfaceInfo: function (iface) {
        UserSessionService.createSessionStorageEntry('interfaceInfo.' + (iface? iface.id : ''), JSON.stringify(iface));
    },

    getInterfaceInfo: function (ifaceId) {
        return JSON.parse(UserSessionService.getSessionStorageEntry('interfaceInfo.' + ifaceId));
    },

    clearInterfaceInfo: function () {
        UserSessionService.clearSessionStorageByKey('interfaceInfo.');
    },

    createSessionStorageEntry(key, data) {
        sessionStorage.setItem(key, data);
    },

    getSessionStorageEntry(key) {
        return sessionStorage.getItem(key);
    },

    createLocalStorageEntry(key, data) {
        localStorage.setItem(key, data);
    },

    getLocalStorageEntry(key) {
        return localStorage.getItem(key);
    },

    clearSessionStorageByKey(stringKey) {
        let entries = _.pickBy(sessionStorage, (entry, entryId) => {
            return _.startsWith(entryId, stringKey);
        });
        _.forOwn(entries, (entry, entryId) => {
            sessionStorage.removeItem(entryId);
        });
    },

    createSessionStorageEntryWithTimer(key, data, timer) {
        timer = timer? timer : config.generic.services.defaultExpiryTimerForSessionStorageSeconds;
        let objToStore = {
            data: data,
            creationDate: moment().utc().format(config.datepicker.formats.isoDateTime),
            expiryTime: timer
        };
        sessionStorage.setItem(key, JSON.stringify(objToStore));
    },

    getSessionStorageEntryWithTimer(key) {
        let result;
        if(sessionStorage.getItem(key)){
            let item = JSON.parse(sessionStorage.getItem(key));
            if(item.expiryTime &&
                item.creationDate &&
                moment.utc().isBefore(moment.utc(item.creationDate).clone().add(item.expiryTime, 's'))
            ) {
                result = _.cloneDeep(item.data);
            } else {
                sessionStorage.removeItem(key);
            }
        }
        return result;
    },

    clearAllStoredCache() {
        _.forOwn(localStorage, (entry, entryId) => {
            localStorage.removeItem(entryId);
        });
    },

    getSessionIsExpired() {
        if(!_.isNil(localStorage.getItem('lastRequestTimeStamp'))) {
            return moment()
                .utc()
                .isAfter(
                    moment.utc(localStorage.getItem('lastRequestTimeStamp'))
                        .clone()
                        .add(config.generic.sessionTimeout.requestsTimeout, 'ms'));
        } else {
            return false;
        }
    },

    updateLastRequestTimeStamp() {
        if(!_.isNil(localStorage.getItem('lastRequestTimeStamp'))) {
            localStorage.setItem('lastRequestTimeStamp', moment().utc().format(config.datepicker.formats.isoDateTime));
        }
    },

    replaceTokenWith(newToken) {
        if(newToken) {
            let session = this.getSession();
            if (!_.isNil(session)) {
                session.sessionToken = newToken;
                UserSessionService.storeAuthInfo(session);
            }
        }
    },

    isAcceptedTerms() {
        let session = this.getSession();
        if (_.isNil(session)) {
            return false;
        } else {
            return session.acceptedTerms;
        }
    },

    storeAcceptedTerms: function () {
        let session = this.getSession();
        UserSessionService.storeAuthInfo({
            ...session,
            acceptedTerms: true
        });
    },

    storeKnowledgeVersion: function () {
        let session = this.getSession();
        UserSessionService.storeAuthInfo({
            ...session,
            knowledgeVersion: true
        });
    },

    isKnowledgeVersion() {
        let session = this.getSession();
        if (_.isNil(session)) {
            return false;
        } else {
            return session.knowledgeVersion;
        }
    }
};

export default UserSessionService;
