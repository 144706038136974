const ValporTheme = {
    images : {
        loginLogo: "/img/valpor/login-logo.png",
        whiteLogo: "/img/valpor/login-logo.png",
        favicon: "/img/valpor/favicon.ico",
        leftMenuLogo: "/img/valpor/logo-only.png",
        headerLogo: "/img/valpor/name-only.png",

        touchIconIphone: "/img/touch-icon-iphone.png",
        touchIconIpad:"/img/touch-icon-ipad.png",
        touchIconIphoneRetina: "/img/touch-icon-iphone-retina.png",
        touchIconIpadRetina: "/img/touch-icon-ipad-retina.png",
        appleTouchStartupImage: "/img/apple-touch-startup-image",
        qrcodeLogo: "/img/valpor/login-logo.png"
    },
    css: [
        "/themes/valpor/valpor.css"
    ],
    strings: {
        title: "Valpor - MyFarmcontrol"
    }
}

export default ValporTheme;