import React from "react";
import Tippy from "@tippyjs/react";
import PropTypes from "prop-types";

const Tooltip = (props) => {
    const tippyProps = {
        placement:'auto',
        theme:"material",
        arrow:true,
        hideOnClick:false,
        className:"fc-tippy-tooltip",
        duration: 0,
        ...props
    }

    if(props.content === "" || props.content === undefined || props.content === null) {
        return props.children;
    }

    return (
        <Tippy {...tippyProps} >
            {props.children}
        </Tippy>
    )
};

Tooltip.propTypes = {
    content: PropTypes.any
};

export default Tooltip;