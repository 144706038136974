import React from 'react';
import PropTypes from 'prop-types';
import _ from "lodash";
import TabbedPanel from "../../panels/tabs/TabbedPanel";
import CycleItem from "./CycleItem";
import moment from "moment";
import {config} from "../../../config";
import Row from "../../row/Row";
import classnames from 'classnames';
import {t} from "../../../utils/Translation";
import AddCycleForm from "./AddCycleForm";
import uuid from "../../../utils/uuid";
import ModalComponent from "../../modal/ModalComponent";

class CycleGroup extends React.Component {
    constructor(props) {
        super(props);

        this.cycleRefs = {};
        this.fieldRefs = {};

        this.state = {
            cycles: [],
            inputs: this.props.inputs? this.props.inputs : [],
            preCycleStartDate: this.props.preCycleStartDate? this.props.preCycleStartDate : moment(),
            lastCycleDay: 1
        };
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            inputs: nextProps.inputs? nextProps.inputs : this.state.inputs,
            preCycleStartDate: nextProps.preCycleStartDate? nextProps.preCycleStartDate : this.state.preCycleStartDate,
        });
    }

    componentDidMount() {
        createInitialStaticCycles(this);
    }

    setPreCycleStartDate(cycleDay, afterPreCycleStartDateIsSet) {
        afterPreCycleStartDateIsSet = afterPreCycleStartDateIsSet? afterPreCycleStartDateIsSet : () => {};
        this.setState({preCycleStartDate: cycleDay}, () => {
            afterPreCycleStartDateIsSet();
        });
    }

    setLastCycleDay(cycleDay) {
        this.setState({lastCycleDay: cycleDay});
    }

    getCycles() {
        return this.state.cycles;
    }

    setCycles(cycles, afterCyclesAreSet) {
        afterCyclesAreSet = afterCyclesAreSet? afterCyclesAreSet : () => {};
        this.setState({cycles: cycles}, () => {
            afterCyclesAreSet();
        });
    }

    buildCyclesWithNewCycle(originalCycles, id, day, startTime) {
        let self = this;
        let newCycle = {
            id: id,
            day: day.toString(),
            title: t('generic.cyclegroup.fields.day.label') + " " + day,
            startTime: moment(startTime, 'HH:mm:ss'),
            startDate: originalCycles[1].startDate.clone().add(day-1, 'd'),
            referenceDate: originalCycles[1].startDate,
            disabledStartDate: true,
            disabledDay: false,
            inputData: [],
            inputs: self.state.inputs,
            onDateChanged: null,
            onTimeChanged: time => {
                handleTimeChanged(self, time, id)
            },
            onDelete: () => {
                handleDeleteCycle(self, id)
            },
            onDayBlur: () => {
                updateDateAndTitle(self, id)
            }
        };

        let indexToAddCycle = _.findIndex(originalCycles, cycle => {
            return _.parseInt(day) < _.parseInt(cycle.day);
        });

        let firstPartOfTheArray = _.slice(originalCycles, 0, indexToAddCycle);
        let lastPartOfTheArray = _.slice(originalCycles, indexToAddCycle);

        firstPartOfTheArray.push(newCycle);

        return _.concat(firstPartOfTheArray, lastPartOfTheArray);
    }

    addNewCycle(day, startTime){
        let self = this;
        let id = uuid();

        this.setCycles(this.buildCyclesWithNewCycle(this.state.cycles, id, day, startTime), () => {
            let cycleIndex = _.findIndex(this.state.cycles, cycle => {return cycle.id === id});
            self.cycleRefs[id].cycleScheduleFieldRefs.startDate.setFieldValue(this.state.cycles[cycleIndex].startDate);
            setInputDataIntoCycles(self, this.state.cycles);
        });
    }

    validateField() {
        let isValid = true;
        let tabNumber = -1;
        _.forEach(this.cycleRefs, cycleRef => {
            if(!_.isNil(cycleRef)){
                if(!cycleRef.validateCycle()){
                    isValid = false;
                    if(!_.isNil(cycleRef.props.tabNumber) && (cycleRef.props.tabNumber <= tabNumber || tabNumber === -1)){
                        tabNumber = cycleRef.props.tabNumber;
                    }
                }
            }
        });
        if(!isValid && tabNumber !== -1 && this.fieldRefs.tabPanel.state.activeTabPosition !== tabNumber){
            this.fieldRefs.tabPanel.setTabActive(tabNumber);
        }
        return isValid;
    }

    getFieldValue(){
        let self = this;
        let result = [];
        let cycles = this.state.cycles;
        for(let i = 0; i < cycles.length - 1; i++) {
            result.push({
                startDate: moment(joinDateAndTime(cycles[i].startDate, cycles[i].startTime)).format('YYYY-MM-DDTHH:mm:ss'),
                endDate: moment(joinDateAndTime(cycles[i+1].startDate, cycles[i+1].startTime)).format('YYYY-MM-DDTHH:mm:ss'),
                inputs: fetchInputsData(self, cycles[i])
            });
        }
        return result;
    }

    setFieldValue(data){
        if(!_.isEmpty(data)){
            this.setPreCycleStartDate(moment(extractDate(data[0].startDate)), () => {
                convertDataIntoCycles(this, data);
            });
        }
    }

    render() {
        let self = this;
        const isMobile = window.matchMedia("(min-width: 200px) and (max-width: 768px)").matches;
        let cycleGroupTitles = buildCycleTitles(this);
        let cycleGroupContents = buildCycleContents(this, isMobile);
        const firstDayCycle = self.state.cycles && self.state.cycles.length > 0 ? self.state.cycles[1].startDate : moment();
        let modalContent=(
            <AddCycleForm
                lastDay={self.state.lastCycleDay}
                firstDayCycle={firstDayCycle}
                preCycleStartDate={self.state.preCycleStartDate.clone()}
                cycleGroup={self}
                ref="addCycleForm"/>
        );

        return (
            <div>
                <ModalComponent
                    data={modalContent}
                    className="add-cycle-modal"
                    ref={c => self.addCycleModal = c}>
                </ModalComponent>
                <Row className="ml-total-15">
                    <h3 className="col-md-10">{this.props.header}</h3>
                    {getAddButton(this)}
                </Row>
                <TabbedPanel
                    tabHeaderClass={!isMobile ? "" : "rule-form-mobile-top-header" }
                    tabTitles={cycleGroupTitles}
                    tabPanels={cycleGroupContents}
                    tabsLocation={!isMobile ? "left" : null}
                    ref={c => {this.fieldRefs.tabPanel = c}}
                />
            </div>
        );
    }
}

function buildCycleTitles(component){
    let titleList = [];
    _.forEach(component.getCycles(), cycle => {
        titleList.push(cycle.title);
    });
    return titleList;
}

function buildCycleContents(component, isMobile=false){
    let content = [];
    let tabNumber = 0;
    _.forEach(component.getCycles(), cycle => {
        content.push(
            <CycleItem
                id={cycle.id}
                day={cycle.day}
                title={cycle.title}
                startTime={cycle.startTime}
                startDate={cycle.startDate}
                disabledStartDate={cycle.disabledStartDate}
                disabledDay={cycle.disabledDay}
                inputs={cycle.inputs}
                parentForm={component.props.parentForm}
                referenceDate={cycle.referenceDate}
                inputData={cycle.inputData}
                onDayBlur={cycle.onDayBlur}
                cycleGroup={component}
                onDateChanged={cycle.onDateChanged}
                onTimeChanged={cycle.onTimeChanged}
                viewMode={component.props.viewMode}
                onDelete={cycle.onDelete}
                tabNumber={tabNumber}
                isMobile={isMobile}
                ref={c => {component.cycleRefs[cycle.id] = c}}
            />);
        tabNumber++;
    });
    return content;
}

function createInitialStaticCycles(component) {
    let initialCycles = [];
    let preCycle = {
        id: 'preCycle',
        day: t('generic.cyclegroup.fields.day.preCycleLabel'),
        title: t('generic.cyclegroup.fields.day.preCycleLabel'),
        startTime: null,
        startDate: component.state.preCycleStartDate.clone(),
        referenceDate: null,
        disabledStartDate: false,
        disabledDay: true,
        inputs: component.state.inputs,
        inputData: [],
        onDateChanged: date => {handlePreCycleDateChanged(component, date)},
        onTimeChanged: time => {handleTimeChanged(component, time, 'preCycle')},
        onDayBlur: null
    };
    let dayOneCycle = {
        id: 'dayOneCycle',
        day: '1',
        title: t('generic.cyclegroup.fields.day.firstDayLabel'),
        startTime: null,
        startDate: component.state.preCycleStartDate.clone().add(1,'d'),
        referenceDate: component.state.preCycleStartDate.clone(),
        disabledStartDate: false,
        disabledDay: true,
        inputs: component.state.inputs,
        inputData: [],
        onDateChanged: date => {handleDayOneDateChanged(component, date)},
        onTimeChanged: time => {handleTimeChanged(component, time, 'dayOneCycle')},
        onDayBlur: null
    };
    let lastDayCycle = {
        id: 'lastDayCycle',
        day: null,
        title: t('generic.cyclegroup.fields.day.lastDayInitLabel'),
        startTime: null,
        startDate: null,
        referenceDate: component.state.preCycleStartDate.clone(),
        disabledStartDate: true,
        disabledDay: false,
        inputs: null,
        inputData: [],
        onDateChanged: null,
        onTimeChanged: time => {handleTimeChanged(component, time, 'lastDayCycle')},
        onDayBlur: () => {updateDateAndTitle(component, "lastDayCycle")}
    };
    initialCycles.push(preCycle, dayOneCycle, lastDayCycle);
    component.setCycles(initialCycles);
}

function handleDayOneDateChanged(component, date) {
    if(moment(date).isValid()) {
        let cycles = component.getCycles();
        cycles[1].startDate = date.clone();
        for(let i = 2; i < cycles.length; i++) {
            cycles[i] = updateStartAndReferenceDateOfCycle(cycles[i], date.clone().subtract(1,'d'));
            getCycleRef(component, cycles[i].id).cycleScheduleFieldRefs.startDate.setFieldValue(cycles[i].startDate.clone());
        }
        component.setCycles(cycles);
    }
}

function handlePreCycleDateChanged(component, date) {
    if(moment(date).isValid()) {
        let cycles = component.getCycles();
        cycles[0].startDate = date.clone();
        component.setCycles(cycles);
    }
}

function handleTimeChanged(component, time, ref){
    if(moment(time).isValid()) {
        let cycles = component.getCycles();
        let cycleIndex = _.findIndex(cycles, cycle => {return cycle.id === ref});

        cycles[cycleIndex].startTime = time;

        component.setCycles(cycles);
    }
}

function handleDeleteCycle(component, idx){
    if(idx){
        let cycles = component.getCycles();
        let cycleIndex = _.findIndex(cycles, cycle => {return cycle.id === idx});

        cycles.splice(cycleIndex, 1);

        component.setCycles(cycles);
    }
}

function updateStartAndReferenceDateOfCycle(cycle, date) {
    if(moment(date).isValid()) {
        let resultCycle = cycle;
        resultCycle.referenceDate = date.clone();
        resultCycle.startDate = date.clone().add(_.parseInt(cycle.day), 'd');
        return resultCycle;
    }
}

function updateDateAndTitle(component, ref){
    let cycles = component.getCycles();

    let cycleRef = component.cycleRefs[ref];
    let cycleNewDay = cycleRef.cycleScheduleFieldRefs.day.getFieldValue();
    let cycleNewDayInt = _.parseInt(cycleNewDay)-1;

    let cycleIndex = _.findIndex(cycles, cycle => {return cycle.id === ref});

    let lastDay;
    if (ref === "lastDayCycle") {
        lastDay = _.parseInt(cycles[cycles.length-2].day);
    } else {
        lastDay = component.state.lastCycleDay;
    }

    if(isValidNewDay(component, ref, cycleNewDayInt, lastDay)){
        let title = t('generic.cyclegroup.fields.day.label') + " " + cycleNewDay;
        if(ref === "lastDayCycle") {
            title += " " + t('generic.cyclegroup.fields.day.lastDay');
        }
        cycles[cycleIndex].title = title;
        cycles[cycleIndex].day = cycleNewDay;
        if (ref === "lastDayCycle") {
            cycles[cycleIndex].startDate = cycles[1].startDate.clone().add(cycleNewDayInt, 'd');
        }else{
            cycles[cycleIndex].startDate = cycles[cycleIndex].referenceDate.clone().add(cycleNewDayInt, 'd');
        }


        cycles = saveInputData(component, cycles);

        component.setCycles(cycles, () => {
            cycleRef.cycleScheduleFieldRefs.startDate.setFieldValue(cycles[cycleIndex].startDate);

            if(ref==="lastDayCycle"){
                component.setLastCycleDay(_.parseInt(cycleNewDay));
            }
        });
    }
}

function isValidNewDay(component, ref, newDay, lastDay) {
    let regex = config.generic.numberregex;
    let result;
    if(regex.test(newDay) &&
        ((ref === "lastDayCycle" && newDay >= lastDay)
            || (newDay < lastDay && newDay >= 1) )) {
        result = true;
    } else {
        result = false;
    }
    component.cycleRefs[ref].cycleScheduleFieldRefs.day.validateField(newDay, lastDay, ref);
    return result;
}

function getAddButton(component) {
    let button = null;
    if(!component.props.viewMode){
        button = <button
            type="button"
            className={classnames("form-group", "col-md-2", "btn", "btn-w-m", "btn-primary")}
            style={{float: "right"}}
            onClick={() => addNewCycle(component)}>
            {t("generic.cyclegroup.addCycle")}
        </button>;
    }
    return button;
}

function addNewCycle(component) {
    saveInputData(component, component.state.cycles)
    component.addCycleModal.showModal();
}

function getCycleRef(component, cycleId){
    return component.cycleRefs[cycleId];
}

function saveInputData(component, cycles){
    let cycleInputData = {};
    let cyclesAux = cycles;
    _.forEach(cyclesAux, cycle => {
        cycleInputData = [];
        if(cycle.inputs){
            _.forEach(cycle.inputs, input => {
                cycleInputData.push({
                    id: input.id,
                    value: component.cycleRefs[cycle.id].inputRefs[input.id].getFieldValue()
                });
            });
            cycle.inputData = cycleInputData;
        }
    });
    return cyclesAux;
}

function joinDateAndTime(startDate, startTime) {
    if(startDate === null){
        return null;
    }
    return startDate.format('YYYY-MM-DD') + 'T' + (!_.isNil(startTime)? startTime.format('HH:mm:ss') : '00:00:00');
}

function fetchInputsData(component, cycle){
    let result = [];
    _.forEach(component.state.inputs, input => {
        let inputId = input.id;
        let inputValue = component.cycleRefs[cycle.id].inputRefs[input.id].getFieldValue();
        result.push({
            id: inputId,
            value: inputValue
        })
    });
    return result;
}

function convertDataIntoCycles(component, data){
    if(!_.isEmpty(data) && data.length >= 2) {
        let originalCycles = component.getCycles();

        originalCycles[0].startDate = moment(extractDate(data[0].startDate));
        originalCycles[0].startTime = moment(extractTime(data[0].startDate), 'HH:mm:ss');
        originalCycles[0].inputData = data[0].inputs;

        originalCycles[1].startDate = moment(extractDate(data[1].startDate));
        originalCycles[1].startTime = moment(extractTime(data[1].startDate), 'HH:mm:ss');
        originalCycles[1].inputData = data[1].inputs;

        let firstDate = moment(originalCycles[1].startDate).clone().add(-1, 'd');
        let dayAux = 0;

        let convertedOriginalCycles = []

        for (let i=0; i<originalCycles.length; i++){
            let daysInCycle = []
            //Always add pre cycle, first day and last day of the cycle
            if(i===0 || i===1 || i=== originalCycles.length-1){
                convertedOriginalCycles.push(originalCycles[i])
            }else{
                for(let j = 2; j < data.length; j++){
                    dayAux = moment(extractDate(data[j].startDate)).clone().diff(firstDate-1, 'days');
                    daysInCycle.push(dayAux)
                }
            }
        }

        originalCycles = convertedOriginalCycles;

        for(let i = 2; i < data.length; i++){
            dayAux = moment(extractDate(data[i].startDate)).clone().diff(firstDate, 'days');
            originalCycles = component.buildCyclesWithNewCycle(originalCycles, uuid(), dayAux, extractTime(data[i].startDate));
            originalCycles[i].inputData = data[i].inputs;
        }

        originalCycles[originalCycles.length-1].startDate = moment(extractDate(data[data.length - 1].endDate));
        originalCycles[originalCycles.length-1].startTime = moment(extractTime(data[data.length - 1].endDate),'HH:mm:ss');
        originalCycles[originalCycles.length-1].day = originalCycles[originalCycles.length-1].startDate.clone().diff(firstDate, 'days').toString();
        originalCycles[originalCycles.length-1].title = t('generic.cyclegroup.fields.day.label') + " " +
            originalCycles[originalCycles.length-1].day + " " +
            t('generic.cyclegroup.fields.day.lastDay');

        component.setCycles(originalCycles, () => {
            setInputDataIntoCycles(component, originalCycles);
            component.setLastCycleDay(_.parseInt(originalCycles[originalCycles.length-1].day));
        });

    } else {
        throw new Error("Impossible to set data in cycles. There should be more info.");
    }
}

function setInputDataIntoCycles(component, cycles) {
    _.forEach(cycles, cycle => {
        if(component.cycleRefs[cycle.id] && component.cycleRefs[cycle.id].inputRefs) {
            if(!_.isEmpty(cycle.inputData)) {
                _.forEach(cycle.inputData, input => {
                    component.cycleRefs[cycle.id].inputRefs[input.id].setFieldValue(input.value);
                });
            } else {
                _.forOwn(component.cycleRefs[cycle.id].inputRefs, inputRef => {
                    inputRef.clearFieldValue();
                });
            }
        }
    })
}

function extractDate(dateTime) {
    return moment(dateTime).format('YYYY-MM-DD');
}

function extractTime(dateTime) {
    return moment(dateTime).format('HH:mm:ss');
}

CycleGroup.propTypes = {
    inputs: PropTypes.object,
    preCycleStartDate: PropTypes.instanceOf(moment),
    parentForm: PropTypes.object,
    header: PropTypes.string
};

export default CycleGroup;