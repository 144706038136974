import axios from "axios";
import UserSessionService from "./UserSessionService";

let ErrorService = {

    createError: function (error, errorInfo, onSuccess, onFailure) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onFailure = onFailure ? onFailure : () => {};
        axios.post('/error/fe', {
            userId: UserSessionService.getCurrentUserId(),
            error: error,
            errorInfo: errorInfo
        }).then(onSuccess, onFailure);
    }
};

export default ErrorService;