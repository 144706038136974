const StagingTheme = {
    images : {
        loginLogo: "/img/farm-logo-stg.svg",
        whiteLogo: "/img/fc-logo-stg.svg",
        favicon: "/img/favicon.ico",
        leftMenuLogo: "/img/farmcontrol_logo_only.svg",
        headerLogo: "/img/name-only.png.svg",

        touchIconIphone: "/img/touch-icon-iphone.png",
        touchIconIpad:"/img/touch-icon-ipad.png",
        touchIconIphoneRetina: "/img/touch-icon-iphone-retina.png",
        touchIconIpadRetina: "/img/touch-icon-ipad-retina.png",
        appleTouchStartupImage: "/img/apple-touch-startup-image",
        qrcodeLogo: "/img/farm-logo.png"
    },
    css: [
        "/themes/staging/staging.css"
    ],
    strings: {
        title: "MySTAGINGcontrol - SMART LIVESTOCK MANAGER"
    }
}

export default StagingTheme;