import React from "react";
import "./loading.css"
import PropTypes from "prop-types";

const Loading = (
    {
        style = {},
        className = ""
    }) => {
    return (
        <div className={"loading " + className}>
            <i className="fa fa-spinner fa-pulse" style={style}/>
        </div>
    )
};

Loading.propTypes = {
    style: PropTypes.object,
    className: PropTypes.string
};

export default Loading;