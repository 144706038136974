import React, {useState} from "react";
import "./style.scss";
import __ from "../../../utils/EnhancedLodash";
import DurationFormatter from "../../../utils/DurationFormatter";
import moment from "moment";
import TimezoneUtils from "../../../utils/TimezoneUtils";
import DateUtils from "../../../utils/DateUtils";
import {useTranslation} from "react-i18next";

const EntityAudit = (
    {
        createdBy,
        creationTimestampUtc,
        lastUpdatedBy,
        lastUpdateTimestampUtc,
        deletedTimestamp
    }) => {
    const { t } = useTranslation();
    const [showCreatedDuration, setShowCreatedDuration] = useState(false);
    const [showUpdatedDuration, setShowUpdatedDuration] = useState(false);
    const [showDeletedDuration, setShowDeletedDuration] = useState(false);

    if(__.isNilOrEmpty(createdBy) && __.isNilOrEmpty(lastUpdatedBy) && __.isNilOrEmpty(lastUpdatedBy) && __.isNilOrEmpty(lastUpdateTimestampUtc)) {
        return null;
    }

    let creationDuration = null;
    if(creationTimestampUtc) {
        creationDuration = DurationFormatter.format(moment.duration(TimezoneUtils.convertUTCToTimezone(moment().utc()).diff(moment(TimezoneUtils.convertUTCToTimezone(creationTimestampUtc)).utc())), true);
    }
    let lastUpdateDuration = null;
    if(lastUpdateTimestampUtc) {
        lastUpdateDuration = DurationFormatter.format(moment.duration(TimezoneUtils.convertUTCToTimezone(moment().utc()).diff(moment(TimezoneUtils.convertUTCToTimezone(lastUpdateTimestampUtc)).utc())), true);
    }
    let deletedDuration = null;
    if(deletedTimestamp) {
        deletedDuration = DurationFormatter.format(moment.duration(TimezoneUtils.convertUTCToTimezone(moment().utc()).diff(moment(TimezoneUtils.convertUTCToTimezone(deletedTimestamp)).utc())), true);
    }

    return (
        <div className="created-update-wrapper">
            {
                <p className="created">
                    <span className="font-bold m-r-xs">{t("generic.createdBy")}</span>
                    <span className="m-r-xs">{createdBy ? createdBy?.shortName : t("generic.system")}</span>
                    <span className="cursor-pointer" onClick={() => setShowCreatedDuration(!showCreatedDuration)}>
                        {showCreatedDuration ? creationDuration : DateUtils.simpleDisplayDateTime(creationTimestampUtc)}
                    </span>
                </p>
            }
            {
                !__.isNilOrEmpty(lastUpdatedBy) && <p className="updated">
                    <span className="font-bold m-r-xs">{t("generic.lastUpdatedBy")}</span>
                    <span className="m-r-xs">{lastUpdatedBy?.shortName}</span>
                    <span className="cursor-pointer" onClick={() => setShowUpdatedDuration(!showUpdatedDuration)}>
                        {showUpdatedDuration ? lastUpdateDuration : DateUtils.simpleDisplayDateTime(lastUpdateTimestampUtc)}
                    </span>
                </p>
            }
            {
                !__.isNilOrEmpty(deletedTimestamp) && <p className="updated">
                    <span className="font-bold m-r-xs">{t("generic.deletedAt")}:</span>
                    <span className="cursor-pointer" onClick={() => setShowDeletedDuration(!showDeletedDuration)}>
                        {showDeletedDuration ? deletedDuration : DateUtils.simpleDisplayDateTime(deletedTimestamp)}
                    </span>
                </p>
            }
        </div>
    );
}

export default EntityAudit;
