import axios from "axios";
import _ from "lodash";
import LocalesUtils from "../../utils/LocalesUtils";

const AsyncProductionBatchService = {

    getAnimalMovements: function (search) {
        return new Promise((accept, reject) => {
            axios.get('/batches/transactions', {params: search}).then(response => {
                accept(response.data);
            }, reject);
        });
    },

    getFeedMovements: function (search) {
        return new Promise((accept, reject) => {
            axios.get('/batches/feedTransactions', {params: search}).then(response => {
                accept(response.data);
            }, reject);
        });
    },

    getProductionBatchCurveDays: function (batchId) {
        return new Promise( (accept, reject) => {
            axios.get('/batches/' + batchId + '/curveWindowRange')
                .then(response => {
                    accept(response.data);
                }, reject);
        });
    },

    getListBatches: function (search) {
        return new Promise( (accept, reject) => {
            axios.get('/batches', {
                params: search
            }).then(response => {
                accept(response.data);
            }, reject);
        });
    },

    getEntityListBatches: function (search) {
        return new Promise( (accept, reject) => {
            axios.get('/entity-batches', {
                params: search
            }).then(response => {
                accept(response.data);
            }, reject);
        });
    },

    getBatchDetails: function (id) {
        return new Promise( (accept, reject) => {
            axios.get('/batches/' + id).then(response => {
                accept(response.data);
            }, reject);
        });
    },

    getLastBatchFeedTransaction: function (id) {
        return new Promise( (accept, reject) => {
            axios.get('/batches/' + id + "/last-feedTransactions").then(response => {
                accept(response.data);
            }, reject);
        });
    },

    createFeedTransaction: function (feedTransactionData) {
        return new Promise( (accept, reject) => {
            let formBox = new FormData();
            if (!_.isNil(feedTransactionData.dropzone)) {
                feedTransactionData.dropzone.forEach(file => {
                    formBox.append('files', file);
                });
            }
            feedTransactionData.dropzone = null;
            formBox.append('createFeedTransactionRequest', new Blob([JSON.stringify(feedTransactionData)], { type: "application/json" }));
            axios.post('/batches/feedTransactions', formBox).then((response) => {
                accept(response.data);
            }, reject);
        });
    },


    getBatchAnimalsAggregation: function (search) {
        return new Promise( (accept, reject) => {
            axios.get("/batches/transactions/aggregation", {
                params: search
            }).then(response => {
                accept(response.data);
            }, reject);
        });
    },

    getBatchFeedAggregation: function (search) {
        return new Promise( (accept, reject) => {
            axios.get("/batches/feedTransactions/aggregation", {
                params: search
            }).then(response => {
                accept(response.data);
            }, reject);
        });
    },

    getProductionSpecies: function () {
        return new Promise((accept, reject) => {
            axios.get('/batches/productionSpecies')
                .then((response) => {
                    let subSpecies = [];
                    _.forEach(response.data, subSpecie => {
                        subSpecies.push({
                            id: subSpecie.value,
                            name: LocalesUtils.getLocaleString(subSpecie.species.translations) + ' | ' + LocalesUtils.getLocaleString(subSpecie.translations)
                        });
                    });
                    accept(subSpecies);
                }, (error) => {
                    reject(error);
                });
        });
    },

    getProductionType: function (search) {
        return new Promise( (accept, reject) => {
            axios.get("/batches/productionTypes" , {
                params: search
            }
            ).then(response => {
                let productionType = [];
                response.data.forEach(productionTypeData => {
                    productionType.push({
                        id: productionTypeData.id,
                        name: LocalesUtils.getLocaleString(productionTypeData.mode.translations)
                    });
                });
                accept(productionType);
            }, reject);
        });
    }
}

export default AsyncProductionBatchService;