import React, {useState} from "react";
import PropTypes from "prop-types";
import __ from "../../utils/EnhancedLodash";
import Tooltip from "../tooltip/Tooltip";
import "./ImgButton.scss";
import classnames from "classnames";

const ImgButton = (props) => {

    const [onHover, setOnHover] = useState(false);

    const {
        image = undefined,
        onHoverImage = undefined,
        disableImage = undefined,
        handleIconClick = () => {},
        classes = "",
        divClassname = "",
        alt = "img",
        disabled = false,
        height = 40,
        imgStyle = { height: height + "px", width: height + "px"},
        tooltip = ""
    } = props;


    return (
        <div className={classnames("img-btn", divClassname)}>
            {
                <Tooltip content={tooltip} placement={"top"}>
                    <img
                        style={imgStyle}
                        alt={alt}
                        src={disabled && !__.isNilOrEmpty(disableImage) ? disableImage : onHover ? onHoverImage : image}
                        className={(disabled ? "disabled " : "") + classes}
                        onClick={disabled ? null : handleIconClick}
                        onMouseEnter={() => !__.isNilOrEmpty(onHoverImage) && !disabled ? setOnHover(true) : {}}
                        onMouseOut={() => !__.isNilOrEmpty(onHoverImage) && !disabled ? setOnHover(false) : {}}
                    />
                </Tooltip>
            }
        </div>
    )

}

ImgButton.propTypes = {
    image: PropTypes.any.isRequired,
    onHoverImage: PropTypes.any,
    disableImage: PropTypes.any,
    handleIconClick: PropTypes.func,
    divClassname: PropTypes.string,
    classes: PropTypes.string,
    alt: PropTypes.string,
    disabled: PropTypes.bool,
    hasText: PropTypes.bool,
    height: PropTypes.number,
    imgStyle: PropTypes.object,
    tooltip: PropTypes.string
};

export default ImgButton;