import React from 'react';
import classnames from 'classnames';

class TabTitle extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tabTitleTextAlignment: props.tabTitleTextAlignment ? props.tabTitleTextAlignment : "left",
            position: props.position,
            active: props.active
        };
    }

    setActive(active) {
        this.setState({
            active: active
        });
    }

    render() {
        let {
            bgColorClass = "gray-bg",
            onClick = () => {},
            className,
            text
        } = this.props;

        const {
            active,
            tabTitleTextAlignment,
            position
        } = this.state;

        return (
            <li className={classnames("text-" + tabTitleTextAlignment, bgColorClass, {"active": active}, className)} onClick={() => onClick(position)}>
                <span>{text}</span>
            </li>
        );
    }
}

export default TabTitle;