import _ from "lodash";
import axios from "axios";
import {t} from "../../utils/Translation";
import LocalesUtils from "../../utils/LocalesUtils";

const AsyncEventService = {
    getAllEventCategories: function () {
        return new Promise((accept, reject) => {
            axios.get('/events/categories').then(response => {
                let translatedCategories = [];
                if(!_.isNil(response.data) && !_.isEmpty(response.data)){
                    _.forEach(response.data, cat => {
                        translatedCategories.push(LocalesUtils.filterLocalesOfObject(cat));
                    });
                }
                accept(translatedCategories);
            }, reject);
        });
    },

    getAllEventOrigins: function () {
        return new Promise((accept, reject) => {
            axios.get('/events/origins').then((response) => {
                accept(_.map(response.data, mapToEventSearchBoxObj));
            }, reject);
        });
    },

    getEventCauses: function () {
        return new Promise((accept, reject) => {
            axios.get('/events/causes').then(response => {
                let parsedData = [];
                if(!_.isNil(response.data) && !_.isEmpty(response.data)){
                    _.forEach(response.data, cat => {
                        parsedData.push(LocalesUtils.filterLocalesOfObject(cat));
                    });
                }
                accept(parsedData);
            }, reject);
        });
    },

    countEvents: function (searchData) {
        return new Promise((accept, reject) => {
            axios.get('/events/count', {
                params: searchData
            }).then(response => {
                accept(response.data);
            }, reject);
        });
    },

    countUserOpenEvents: function () {
        return new Promise((accept, reject) => {
            axios.get('/events/count/open').then(response => {
                accept(response.data);
            }, reject);
        });
    },


    getEvents: function (search) {
        return new Promise((accept, reject) => {
            axios.get('/events', {params: search}).then(response => {
                let newData = [];
                if(!_.isNil(response.data) && !_.isEmpty(response.data)){
                    _.forEach(response.data, e => {
                        e.category = LocalesUtils.filterLocalesOfObject(e.category);
                        newData.push(e);
                    });
                }
                accept(newData);
            }, reject);
        });
    },

    getEventsFiles: function (eventIds) {
        return new Promise((accept, reject) => {
            axios.get('/events/attachments', {params: {events: eventIds}}).then((response) => {
                let result = {};
                _.forOwn(response.data, (eventFileList, eventId) => {
                    let files = [];
                    eventFileList.forEach(file => {
                        let fileType = (!_.isNil(file.contentBase64)) ? file.contentBase64.split(';')[0] : '';
                        fileType = fileType.replace('data:', '');
                        if (fileType.indexOf('image') !== -1){
                            let newFile = new File([""], file.name, {type: fileType});
                            newFile.preview = '/services/static/event/attachments/' + eventId + '/' + file.name;
                            files.push(newFile);
                        }
                    });
                    result[eventId] = files;
                });
                accept(result);
            }, reject);
        });
    },

    getEventActions: function (eventId) {
        return new Promise((accept, reject) => {
            axios.get('/events/' + eventId + '/actions').then((response) => {
                accept(response.data);
            }, reject);
        });
    },

    getEventChanges: function (eventId) {
        return new Promise((accept, reject) => {
            axios.get('/events/' + eventId + '/changes').then((response) => {
                accept(response.data);
            }, reject);
        });
    },
};

function mapToEventSearchBoxObj(value) {
    return {
        'id': value,
        'name': t('events.list.searchBox.types.' + value)
    };
}

export default AsyncEventService;