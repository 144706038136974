import * as actionTypes from "./actionTypes";
import _ from "lodash";
import Utils from "../../components/navigation/utils/Utils";

const initialState =  {
    loading: undefined,
    error: false,
    layout: [],
    selectedDashboard: undefined,
    selectedSubTitle: undefined,
    userDashboard: {
        loading: undefined,
        error: undefined,
        dashboards: [],
        dashboardsIds: []
    },
    entityDashboard: {
        loading: undefined,
        error: undefined,
        dashboards: [],
        dashboardsIds: []
    },
    homepage: {},
    showSuccessAlert: undefined,
};

const dashboardReducer = (state = initialState, payload) => {
    switch (payload.type) {
        case actionTypes.LOAD_USER_DASHBOARD:
            return {
                ...state,
                userDashboard: {
                    ...state.userDashboard,
                    loading: true
                }
            };
        case actionTypes.LOAD_USER_DASHBOARD_SUCCESS:
            return {
                ...state,
                userDashboard: {
                    ...state.userDashboard,
                    dashboards: payload.result,
                    dashboardsIds: payload.result.map(d=>d.id),
                    loading: false,
                    error: false

                }
            };
        case actionTypes.LOAD_USER_DASHBOARD_FAILED:
            return {
                ...state,
                userDashboard: {
                    ...state.userDashboard,
                    dashboards: [],
                    loading: false,
                    error: true

                }
            };
        case actionTypes.LOAD_ENTITY_DASHBOARD:
            return {
                ...state,
                entityDashboard: {
                    ...state.entityDashboard,
                    loading: true
                }
            };
        case actionTypes.LOAD_ENTITY_DASHBOARD_SUCCESS:
            return {
                ...state,
                entityDashboard: {
                    dashboards: Utils.normalizeByFarm(payload.result),
                    dashboardsIds: payload.result.map(d=>d.id),
                    loading: false,
                    error: false

                }
            };
        case actionTypes.LOAD_ENTITY_DASHBOARD_FAILED:
            return {
                ...state,
                entityDashboard: {
                    dashboards: [],
                    loading: false,
                    error: true

                }
            };
        case actionTypes.LOAD_HOMEPAGE_DASHBOARD:
            return {
                ...state,
                homepage: {
                    ...state.homepage,
                    loading: true
                }
            };
        case actionTypes.LOAD_HOMEPAGE_DASHBOARD_SUCCESS:
            return {
                ...state,
                homepage: {
                    ...payload.result,
                    loading: false
                }
            };
        case actionTypes.LOAD_HOMEPAGE_DASHBOARD_FAILED:
            return {
                ...state,
                homepage: {
                    loading: false,
                    error: true

                }
            };
        case actionTypes.LOAD_WIDGETS_REQUEST:
            return {
                ...state,
                loading: true
            };
        case actionTypes.LOAD_WIDGETS_SUCCESS:
            const otherPayload = {
                ...payload.dashboard
            }
            const gridLayout = generateGridLayout(otherPayload.widgets);
            delete otherPayload.widgets;
            return {
                ...state,
                loading: false,
                error: false,
                layout: gridLayout,
                selectedDashboard: otherPayload
            };
        case actionTypes.LOAD_WIDGETS_FAILED:
            return {
                ...state,
                loading: false,
                error: true
            };
        case actionTypes.UPDATE_LAYOUT:
            let layout = state.layout.map(widget => {
                let indexOfWidgetToUpdate = _.findIndex(payload.widgetsToUpdate, widgetToUpdate => widgetToUpdate.i === widget.i);
                if (indexOfWidgetToUpdate === -1) {
                    return widget;
                }
                return {
                    ...widget,
                    x: payload.widgetsToUpdate[indexOfWidgetToUpdate].x,
                    y: payload.widgetsToUpdate[indexOfWidgetToUpdate].y,
                    h: payload.widgetsToUpdate[indexOfWidgetToUpdate].h,
                    w: payload.widgetsToUpdate[indexOfWidgetToUpdate].w
                }
            });

            _.forEach(payload.widgetsCreated, widget => {
                layout.push({
                    x: widget.x,
                    y: widget.y,
                    h: widget.h,
                    w: widget.w
                });
            });

            _.pullAllBy(layout, payload.widgetsDeleted, 'i');

            return {
                ...state,
                layout: layout
            };
        case actionTypes.CLEAN_LAYOUT:
            return {
                ...initialState,
                userDashboard: {
                    ...state.userDashboard
                },
                entityDashboard: {
                    ...state.entityDashboard
                },
                homepage: {
                    ...state.homepage
                }
            };
        case actionTypes.COPY_ENTITY_DASHBOARD_TO_USER_SUCCESS:
            return {
                ...state,
                showSuccessAlert: payload.showSuccessAlert
            };
        case actionTypes.UPDATE_SUBTITLE:
            return {
                ...state,
                selectedSubTitle: payload.subTitle
            };
        default:
            return{
                ...state,
            };
    }
};

function generateGridLayout(widgets) {
    return _.map(widgets, widget => {
        return {
            i: widget.id,
            x: parseInt(widget.configs.columnIndex, 10),
            y: parseInt(widget.configs.rowIndex, 10),
            h: !_.isNil(widget.configs.widgetHeight) ? parseInt(widget.configs.widgetHeight, 10) : 4,
            w: !_.isNil(widget.configs.widgetWidth) ? parseInt(widget.configs.widgetWidth, 10) : 4,
            isResizable: true
        };
    });
}

export default dashboardReducer;
