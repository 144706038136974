import axios from "axios";
import _ from "lodash";
import AsyncEventService from "./async/AsyncEventService";
import moment from "moment";
import RuleCacheService from "./RuleCacheService";
import LocalesUtils from "../utils/LocalesUtils";
import __ from "../utils/EnhancedLodash";
import {t} from "../utils/Translation";

let EventService = {

    eventTypes: {
        mail: 'MAIL',
        sms: 'SMS',
        voice: 'VOICE',
        whatsapp: 'WHATSAPP'
    },

    eventOrigins: {
        manual: 'MANUAL',
        domatica: 'FC-SMARTBOX-1'
    },

    eventStatus: {
        open: 'OPEN',
        pending: 'PENDING',
        closed: 'CLOSED'
    },

    categories: {
        NOTIFICATION: 'NOTIFICATION'
    },

    getHelpdeskCategories() {
        return [
            {
                id: 'HELPDESK_MALFUNCTIONS',
                name: t('helpdesk.form.categories.helpdeskMalfunctions')
            }, {
                id: 'HELPDESK_DEVELOPMENT',
                name: t('helpdesk.form.categories.helpdeskDevelopment')
            }, {
                id: 'HELPDESK',
                name: t('helpdesk.form.categories.helpdesk')
            }, {
                id: 'HELPDESK_SYSTEM',
                name: t('helpdesk.form.categories.helpdeskSystem')
            }
        ]
    },

    getSeverityList() {
        return [
            {
                id: 'LOW',
                name: t('events.list.searchBox.fields.severity.low')
            }, {
                id: 'MODERATE',
                name: t('events.list.searchBox.fields.severity.moderate')
            }, {
                id: 'MAJOR',
                name: t('events.list.searchBox.fields.severity.major')
            }, {
                id: 'CRITICAL',
                name: t('events.list.searchBox.fields.severity.critical')
            }
        ]
    },

    getStatusList() {
        return [
            {
                id: 'OPEN',
                name: t('events.list.searchBox.fields.status.open')
            }, {
                id: 'PENDING',
                name: t('events.list.searchBox.fields.status.pending')
            }, {
                id: 'CLOSED',
                name: t('events.list.searchBox.fields.status.closed')
            }
        ]
    },

    loadEvents: function (searchData, onSuccess, onError) {

        if (searchData.fromDate){
            searchData.fromDate = searchData.fromDate.valueOf();
        }

        if (searchData.toDate){
            searchData.toDate = searchData.toDate.valueOf();
        }

        if(searchData.lastTime){
            switch (searchData.lastTime) {
                case 'ONE':
                    searchData.fromDate = moment.utc().subtract(1, 'hour').format('YYYY-MM-DDTHH:mm:ss');
                    break;
                case 'SIX':
                    searchData.fromDate = moment.utc().subtract(6, 'hour').format('YYYY-MM-DDTHH:mm:ss');
                    break;
                case 'TWELVE':
                    searchData.fromDate = moment.utc().subtract(12, 'hour').format('YYYY-MM-DDTHH:mm:ss');
                    break;
                case 'TWENTYFOUR':
                    searchData.fromDate = moment.utc().subtract(24, 'hour').format('YYYY-MM-DDTHH:mm:ss');
                    break;
                default:
                    searchData.fromDate = moment.utc().subtract(24, 'hour').format('YYYY-MM-DDTHH:mm:ss');
            }
            delete searchData.toDate;
            delete searchData.lastTime;
        }

        axios.get('/events/search', {
            params: searchData
        }).then((response) => {
            if (onSuccess) {
                let closedEvents = [];
                response.data.content.forEach(event => {
                    if(event.status === EventService.eventStatus.closed){
                        closedEvents.push(event);
                    }
                    event.severity = event.severityLevel;
                    event.openDate = event.eventDate;
                    event.category = LocalesUtils.filterLocalesOfObject(event.category);
                    event.cause = !__.isNilOrEmpty(event.cause) ? LocalesUtils.filterLocalesOfObject(event.cause) : undefined;
                    event.closeDate = event.closedTimestampUtc;
                });
                response.data.closedEvents = closedEvents;
                onSuccess(response.data);
            }
        }, (error) => {
            if (onError) {
                onError(error);
            }
        });
    },

    countEvents: function (searchData, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};
        axios.get('/events/count', {
            params: searchData
        }).then((response) => {
            if (onSuccess) {
                onSuccess(response.data);
            }
        }, (error) => {
            if (onError) {
                onError(error);
            }
        });
    },

    getLastTimeValues(withAll) {
        let values = [];
        if(withAll) {
            values.push({id: 'ALL', name: t('events.list.searchBox.fields.lastTime.all')});
        }
        values.push(...[{
            id: 'ONE',
            name: '1 ' + t('events.list.searchBox.fields.lastTime.hour'),
        }, {
            id: 'SIX',
            name: '6 ' + t('events.list.searchBox.fields.lastTime.hours'),
        }, {
            id: 'TWELVE',
            name: '12 ' + t('events.list.searchBox.fields.lastTime.hours'),
        }, {
            id: 'TWENTYFOUR',
            name: '24 ' + t('events.list.searchBox.fields.lastTime.hours'),
        }]);
        return values;
    },

    getLastBigTimeValues() {
        let values = [];
        values.push(...[{
            id: 'TWENTYFOUR',
            name: '24 ' + t('events.list.searchBox.fields.lastTime.hours'),
        }, {
            id: 'WEEK',
            name: '1 ' + t('events.list.searchBox.fields.lastTime.week'),
        }, {
            id: 'MONTH',
            name: '1 ' + t('events.list.searchBox.fields.lastTime.month'),
        }]);
        return values;
    },

    getEventCategories: function (onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};
        let cachedInfo = RuleCacheService.getEventCategories();
        if(cachedInfo) {
            setTimeout(() => onSuccess(cachedInfo), 10);
        } else {
            AsyncEventService.getAllEventCategories().then( categories => {
                RuleCacheService.storeEventCategories(categories);
                onSuccess(categories);
            }, onError);
        }
    },

    getEventCauses: function (onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};
        axios.get('/events/causes').then(response => {
            let translatedCauses = [];
            if(!_.isNil(response.data) && !_.isEmpty(response.data)){
                _.forEach(response.data, cr => {
                    translatedCauses.push(LocalesUtils.filterLocalesOfObject(cr));
                });
            }
            onSuccess(translatedCauses);
        }, error => {
            onError(error);
        });
    },

    getEventOrigin: function (onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {};
        onError = onError ? onError : () => {};
        AsyncEventService.getAllEventOrigins().then(onSuccess, onError);
    },

    createEvent: function (eventData, onSuccess, onError) {
        let formBox = new FormData();
        if (!_.isNil(eventData.dropzone)) {
            eventData.dropzone.forEach(file => {
                formBox.append('files', file);
            });
        }
        eventData.dropzone = null;
        formBox.append('createEventRequest', new Blob([JSON.stringify(eventData)], {type: "application/json"}));
        axios.post('/events',formBox).then((response) => {
            if(onSuccess) {
                onSuccess(response.data);
            }
        }, (error) => {
            if(onError) {
                onError(error);
            }
        });
    },

    editEvent: function (eventId, eventData, onSuccess, onError) {
        let formBox = new FormData();
        if (!_.isNil(eventData.dropzone)) {
            eventData.dropzone.forEach(file => {
                formBox.append('files', file);
            });
        }
        eventData.dropzone = null;
        formBox.append('editEventRequest', new Blob([JSON.stringify(eventData)], {type: "application/json"}));
        axios.put('/events/' + eventId, formBox).then((response) => {
            if(onSuccess) {
                onSuccess(response.data);
            }
        }, (error) => {
            if(onError) {
                onError(error);
            }
        });
    },

    uploadFile: function (fileData, onSuccess, onError) {
        axios.post('/events', fileData).then((response) => {
            if (onSuccess) {
                onSuccess(response.data);
            }
        }, (error) => {
            if (onError) {
                onError(error);
            }
        });
    },

    getEventList: function (search, onSuccess, onError) {
        axios.get('/events', {
            params: search
        }).then((response) => {
            if (onSuccess) {
                let newData = [];
                if (!_.isNil(response.data) && !_.isEmpty(response.data)) {
                    _.forEach(response.data, e => {
                        e.category = LocalesUtils.filterLocalesOfObject(e.category);
                        newData.push(e);
                    });
                }
                onSuccess(newData);
            }
            }, (error) => {
            if (onError) {
                onError(error);
            }
        });
        },

    getEventDetails: function (eventId, onSuccess, onError) {
        axios.get('/events/' + eventId).then((response) => {
            if (onSuccess) {
                response.data.openDate = response.data.eventDate;
                response.data.entityId = response.data.entity;
                response.data.batchId = response.data.batch;
                response.data.notes = response.data.additionalNotes;
                onSuccess(response.data);
            }
        }, (error) => {
            if (onError) {
                onError(error);
            }
        });
    },

    getEventOccurrences: function (eventId, search, onSuccess, onError) {
        axios.get('/events/' + eventId + '/occurrences/search', {
            params: search
        }).then((response) => {
            onSuccess(response.data);
        }, (error) => {
            onError(error);
        });
    },

    deleteEvent: function (eventId, onSuccess, onError) {
        axios.delete('/events/' + eventId).then((response) => {
            onSuccess(response.data);
        }, (error) => {
            onError(error);
        });
    },

    getEventFiles: function (eventId, onSuccess, onError) {
        axios.get('/events/' + eventId + '/attachments').then((response) => {
            if (onSuccess) {
                let files = [];
                response.data.forEach(file => {

                    let fileType = (!_.isNil(file.contentBase64)) ? file.contentBase64.split(';')[0] : '';
                    fileType = fileType.replace('data:', '');
                    let newFile = new File([""], file.name, {type: fileType});

                    if (fileType.indexOf('image') !== -1){
                        newFile.preview = '/services/static/event/attachments/preview/' + eventId + '/' + file.name;
                    }else{
                        newFile.preview = file.contentBase64;
                    }
                    files.push(newFile);
                });
                onSuccess(files);
            }
        }, (error) => {
            if (onError) {
                onError(error);
            }
        });
    },

    getEventsFiles: function (eventIds, onSuccess, onError) {
        axios.get('/events/attachments', {params: {events: eventIds}}).then((response) => {
            if (onSuccess) {
                let result = {};
                _.forOwn(response.data, (eventFileList, eventId) => {
                    let files = [];
                    eventFileList.forEach(file => {
                        let fileType = (!_.isNil(file.contentBase64)) ? file.contentBase64.split(';')[0] : '';
                        fileType = fileType.replace('data:', '');
                        if (fileType.indexOf('image') !== -1) {
                            let newFile = new File([""], file.name, {type: fileType});
                            newFile.preview = '/services/static/event/attachments/' + eventId + '/' + file.name;
                            files.push(newFile);
                        }
                    });
                    result[eventId] = files;
                    onSuccess(result);
                });
            }
        }, (error) => {
            if (onError) {
                onError(error);
            }
        });
    },

    getEventFile: function (eventId, fileName, onSuccess, onError) {
        axios.get('/events/' + eventId + '/attachments/' + fileName, {
            responseType: 'arraybuffer'
        }).then((response) => {

            if (onSuccess) {
                onSuccess(response);
            }
        }, (error) => {
            if (onError) {
                onError(error);
            }
        });
    },

    quickClose: function (ids, onSuccess, onError) {
        axios.put('/events/quickClose', {
            ids: ids
        }).then((response) => {
            if(onSuccess) {
                onSuccess(response.data);
            }
        }, (error) => {
            if (onError) {
                onError(error);
            }
        });
    },

};


export default EventService;