import axios from "axios";
import AsyncInterfaceService from "./async/AsyncInterfaceService";
import moment from "moment";
import _ from "lodash";
import UserSessionService from "./UserSessionService";
import LocalesUtils from "../utils/LocalesUtils";
import {t} from "../utils/Translation";

let InterfaceService = {

    historyConfig: {
        NO_HISTORY: 'NO_HISTORY',
        TIME_INTERVAL: "TIME_INTERVAL",
        DIFFERENT_VALUES: 'DIFFERENT_VALUES',
        ALL_VALUES: 'ALL_VALUES'
    },

    dataType: {
        BOOLEAN: 'BOOLEAN',
        STRING: 'STRING',
        DECIMAL: 'DECIMAL',
        INTEGER: 'INTEGER'
    },

    categories: {
        TEMPERATURE: 'TEMPERATURE',
        HUMIDITY: 'HUMIDITY',
        CO2: 'CO2',
        SILO_WEIGHT: 'SILO_WEIGHT',
        INDOOR_AIR_TEMPERATURE: 'INDOOR_AIR_TEMPERATURE',
        INDOOR_AIR_HUMIDITY: 'INDOOR_AIR_HUMIDITY',
        SWINE_WEIGHT: 'SWINE_WEIGHT',
        INDOOR_AIR_TEMPERATURE_MAX: 'INDOOR_AIR_TEMPERATURE_MAX',
        INDOOR_AIR_TEMPERATURE_MIN: 'INDOOR_AIR_TEMPERATURE_MIN',
        WATER_CONSUMPTION: 'WATER_CONSUMPTION',
        WATER_PUMP_CONSUMPTION: 'WATER_PUMP_CONSUMPTION',
        WATERTANK_DEPOSIT:'WATERTANK_DEPOSIT',
        FEED_LINE_WORKING_TIME: 'FEED_LINE_WORKING_TIME',
        AVG_WEIGHT_GAIN: 'AVG_WEIGHT_GAIN',
        CONSUMPTION: "CONSUMPTION",
        CONNECTION_STATUS: "CONNECTION_STATUS"
    },

    loadInterfacesSearchUnitTypeOptions: function (searchData, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {// This is intentional
        };
        onError = onError ? onError : () => {// This is intentional
        };
        AsyncInterfaceService.getAllUnitTypes(searchData).then(onSuccess, onError);
    },

    loadInterfacesSearchCategoriesTypeOptions: function (onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {// This is intentional
        };
        onError = onError ? onError : () => {// This is intentional
        };
        AsyncInterfaceService.getAllCategoryTypes().then(onSuccess, onError);
    },

    loadInterfaceSearchCategoriesForAnalytics: function (onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {// This is intentional
        };
        onError = onError ? onError : () => {// This is intentional
        };
        AsyncInterfaceService.getAllCategoriesForAnalytics().then(onSuccess, onError);
    },

    loadInterfacesSearchInterfaceTypeOptions: function (onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {// This is intentional
        };
        onError = onError ? onError : () => {// This is intentional
        };
        AsyncInterfaceService.getAllInterfaceTypes().then(onSuccess, onError);
    },

    loadInterfacesSearchDataTypeOptions: function (onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {// This is intentional
        };
        onError = onError ? onError : () => {// This is intentional
        };
        AsyncInterfaceService.getAllDataTypes().then(onSuccess, onError);
    },

    loadInterfacesSearchUnitOptions: function (category, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {// This is intentional
        };
        onError = onError ? onError : () => {// This is intentional
        };
        AsyncInterfaceService.getAllUnitTypes(category).then(onSuccess, onError);
    },

    loadInterfaces: function (searchData, onSuccess, onError) {
        if(searchData.sortProperty && searchData.sortProperty === 'readingWithSymbol'){
            searchData.sortProperty = 'lastConvertedReading';
        }
        if(searchData.hidden && searchData.hidden.length > 1) {
            delete searchData.hidden;
        }
        axios.get('/interfaces/search', {
            params: searchData
        }).then((response) => {
            if (onSuccess) {
                response.data.content = parseInterfaceValues(response.data.content);
                onSuccess(response.data);
            }
        }, (error) => {
            if (onError) {
                onError(error);
            }
        });
    },

    editInterface: function (entityId, terminalId, interfaceId, data, onSuccess, onError) {
        axios.put('/interfaces/' + entityId + '/terminals/' + terminalId + '/interfaces/' + interfaceId, data).then((response) => {
            if (onSuccess) {
                onSuccess(response.data);
            }
        }, (error) => {
            if (onError) {
                onError(error);
            }
        });
    },

    createInterface: function (data, onSuccess, onError) {
        axios.post('/interfaces', data).then((response) => {
            if (onSuccess) {
                onSuccess(response.data);
            }
        }, (error) => {
            if (onError) {
                onError(error);
            }
        });
    },

    getInterfaceDetails: function (entity, terminal, interfaceId, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {// This is intentional
        };
        onError = onError ? onError : () => {// This is intentional
        };
        axios.get('/interfaces/' + entity + '/terminals/' + terminal + '/interfaces/' + interfaceId).then((response) => {
            if (onSuccess) {
                onSuccess(response.data);
            }
        }, (error) => {
            if (onError) {
                onError(error);
            }
        });
    },

    getInterfacesById: function (interfaceIds, onSuccess, onFailure) {
        onSuccess = onSuccess ? onSuccess : () => {// This is intentional
        };
        onFailure = onFailure ? onFailure : () => {// This is intentional
        };

        if(_.isNil(interfaceIds)) {
            return;
        }

        let url = _.includes(window.location.href, "/public-dashboard") ?
            "/interfaces/public?ids=" + interfaceIds : "/interfaces?ids=" + interfaceIds;

        axios.get(url).then(response => {
            onSuccess(response.data);
        }, onFailure);
    },

    getInterfaceById: function (interfaceId, onSuccess, onFailure) {
        onSuccess = onSuccess ? onSuccess : () => {// This is intentional
        };
        onFailure = onFailure ? onFailure : () => {// This is intentional
        };

        axios.get('/interfaces/' + interfaceId).then(response => {
            onSuccess(response.data);
        }, onFailure);
    },

    deleteInterface: function (entity, terminal, interfaceId, onSuccess, onError) {
        axios.delete('/interfaces/' + entity + '/terminals/' + terminal + '/interfaces/' + interfaceId).then((response) => {
            if (onSuccess) {
                onSuccess(response.data);
            }
        }, (error) => {
            if (onError) {
                onError(error);
            }
        });
    },

    loadInterfaceReadings: function (searchData, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {// This is intentional
        };
        onError = onError ? onError : () => {// This is intentional
        };

        if (searchData.dateFrom){
            searchData.dateFrom = searchData.dateFrom.valueOf();
        }

        if (searchData.dateTo){
            searchData.dateTo = searchData.dateTo.valueOf();
        }
        if(searchData.lastTime){
            switch (searchData.lastTime) {
                case 'ONE':
                    searchData.dateFrom = moment.utc().subtract(1, 'hour').format('YYYY-MM-DDTHH:mm:ss');
                    break;
                case 'SIX':
                    searchData.dateFrom = moment.utc().subtract(6, 'hour').format('YYYY-MM-DDTHH:mm:ss');
                    break;
                case 'TWELVE':
                    searchData.dateFrom = moment.utc().subtract(12, 'hour').format('YYYY-MM-DDTHH:mm:ss');
                    break;
                case 'TWENTYFOUR':
                    searchData.dateFrom = moment.utc().subtract(24, 'hour').format('YYYY-MM-DDTHH:mm:ss');
                    break;
                default:
            }
            searchData.dateTo = moment.utc().format('YYYY-MM-DDTHH:mm:ss');
        }

        axios.get('/interfaces/readings/search', {
            params: searchData
        }).then((response) => {
            if(!_.isEmpty(response.data.content)) {
                InterfaceService.gatherInformationAboutInterfaceReadings(response.data.content,
                    readingsWithInterfaceInfo => {
                        response.data.content = parseInterfaceReadingValues(readingsWithInterfaceInfo);
                        onSuccess(response.data);
                    });
            } else {
                response.data.content = [];
                onSuccess(response.data);
            }
        }, (error) => {
            onError(error);
        });
    },

    searchInterfaceReadings: function (searchData, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {// This is intentional
        };
        onError = onError ? onError : () => {// This is intentional
        };

        axios.get('/interfaces/readings/search', {
            params: searchData
        }).then((response) => {
            if(!_.isEmpty(response.data.content)) {
                onSuccess(response.data);
            } else {
                response.data.content = [];
                onSuccess(response.data);
            }
        }, (error) => {
            onError(error);
        });
    },

    getInterfaceListByTerminalIds(searchData, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {// This is intentional
        };
        onError = onError ? onError : () => {// This is intentional
        };

        axios.get('/interfaces/list', {
            params: searchData
        }).then((response) => {
            onSuccess(response.data);
        }, (error) => {
            onError(error);
        })
    },

    getEntityInterfaces(entityId, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {// This is intentional
        };
        onError = onError ? onError : () => {// This is intentional
        };

        axios.get('/entities/' + entityId + '/interfaces').then((response) => {
            onSuccess(response.data);
        }, (error) => {
            onError(error);
        })
    },

    requestReadingOnInterface: function (entityId, terminalId, interfaceId, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {// This is intentional
        };
        onError = onError ? onError : () => {// This is intentional
        };
        AsyncInterfaceService.requestReadingOnInterface(entityId, terminalId, interfaceId).then(onSuccess, onError);
    },

    getInterfaceReadingsTimeIntervalOptions() {
        return [{
            id: 'ONE',
            name: t('interfaces.readings.searchBox.fields.intervalTime.oneHour'),
        }, {
            id: 'SIX',
            name: t('interfaces.readings.searchBox.fields.intervalTime.sixHours'),
        }, {
            id: 'TWELVE',
            name: t('interfaces.readings.searchBox.fields.intervalTime.twelveHours'),
        }, {
            id: 'TWENTYFOUR',
            name: t('interfaces.readings.searchBox.fields.intervalTime.twentyFourHours'),
        }];
    },

    getHistoryConfigData: function() {
        return [
            {
                id: 'NO_HISTORY',
                name: t('interfaces.form.historyConfigs.mainConfig.options.noHistory')
            },{
                id: 'DIFFERENT_VALUES',
                name: t('interfaces.form.historyConfigs.mainConfig.options.differentValues')
            },{
                id: 'TIME_INTERVAL',
                name: t('interfaces.form.historyConfigs.mainConfig.options.timeInterval')
            },{
                id: 'ALL_VALUES',
                name: t('interfaces.form.historyConfigs.mainConfig.options.allValues')
            }
        ];
    },

    gatherInformationAboutInterfaceReadings(readings, afterInterfaceInfoIsGathered) {
        afterInterfaceInfoIsGathered = afterInterfaceInfoIsGathered? afterInterfaceInfoIsGathered : () => {// This is intentional
        };
        let uniqueInterfaceIdsOfReadings = [];
        UserSessionService.clearInterfaceInfo();

        _.forEach(readings, reading => {
            let ifaceIndex = _.findIndex(uniqueInterfaceIdsOfReadings, interfaceId => {
                return reading.interfaceId === interfaceId;
            });
            if(ifaceIndex === -1) {
                uniqueInterfaceIdsOfReadings.push(reading.interfaceId);
            }
        });
        InterfaceService.getInterfacesById(uniqueInterfaceIdsOfReadings, ifaces => {
            _.forEach(ifaces, iface => {
                UserSessionService.storeInterfaceInfo(iface);
            });
            let result = [];
            _.forEach(readings, reading => {
                let readingWithInterfaceInfo = {};
                let interfaceInfo = UserSessionService.getInterfaceInfo(reading.interfaceId);
                readingWithInterfaceInfo.reading = reading.convertedValue;
                readingWithInterfaceInfo.timestamp = reading.timestampUtc;
                readingWithInterfaceInfo.interfaceView = interfaceInfo;
                result.push(readingWithInterfaceInfo);
            });
            afterInterfaceInfoIsGathered(result);
        });
    },

    getBooleanInterfaceTranslations(interfaceId, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {// This is intentional
        };
        onError = onError ? onError : () => {// This is intentional
        };
        let result = {"true": "true", "false": "false"};

        InterfaceService.getInterfacesById([interfaceId],
            ifaces => {
                if(ifaces[0].interfaceUnitType && ifaces[0].interfaceUnitType.properties && ifaces[0].interfaceUnitType.properties.name) {
                    result["true"] = LocalesUtils.getLocaleString(JSON.parse(ifaces[0].interfaceUnitType.properties.name)['true']);
                    result["false"] = LocalesUtils.getLocaleString(JSON.parse(ifaces[0].interfaceUnitType.properties.name)['false']);
                }
                onSuccess(result);
            },
            () => {
                onError(result);
            });
    },

    getBooleanInterfaceUnitType(interfaceId, onSuccess, onError) {
        onSuccess = onSuccess ? onSuccess : () => {// This is intentional
        };
        onError = onError ? onError : () => {// This is intentional
        };

        InterfaceService.getInterfacesById([interfaceId],
            ifaces => {
                onSuccess(ifaces[0]? ifaces[0].interfaceUnitType : null);
            },
            () => {
                onError();
            });
    },

    getInterfaceReadings: function(searchData, onSuccess, onFailure) {
        onSuccess = onSuccess ? onSuccess : () => { // This is intentional
        };
        onFailure = onFailure ? onFailure : () => { // This is intentional
        };

        if(_.isNil(searchData?.interfaceIds) || searchData?.interfaceIds.length === 0) {
            onSuccess([{readings : []}]);
            return;
        }

        if(moment(searchData.fromDate).isAfter(moment(searchData.toDate))) {
            onSuccess([{readings : []}]);
            return;
        }

        axios.get("/widgets/chart_series_readings/", {
            params: searchData
        }).then(response => {
            onSuccess(response.data);
        }, onFailure);
    },

    getInterfaceReadingsOverview(searchData, onSuccess, onFailure){
        onSuccess = onSuccess ? onSuccess : () => { // This is intentional
        };
        onFailure = onFailure ? onFailure : () => { // This is intentional
        };

        axios.get("/interfaces/readings-overview", {params: searchData}).then(
            response => {
                onSuccess(response.data);
            }, error => {
                onFailure(error);
            });

    },

    getInterfaceReadingsDataCompliaceOverview(batchId, searchData, onSuccess, onFailure){
        onSuccess = onSuccess ? onSuccess : () => { // This is intentional
        };
        onFailure = onFailure ? onFailure : () => { // This is intentional
        };

        axios.get("/batches/" + batchId + "/interfaces/data-compliance-overview", {params: searchData}).then(
            response => {
                onSuccess(response.data);
            }, error => {
                onFailure(error);
            });

    },

    showMaxCapacity(interfaceCategory) {
        return interfaceCategory && (interfaceCategory.id === InterfaceService.categories.WATERTANK_DEPOSIT ||
            interfaceCategory.id === InterfaceService.categories.SILO_WEIGHT);
    }
};

function parseInterfaceReadingValues(results) {
    _.forEach(results, reading => {
        let symbol = " ";
        let booleanTranslation;
        if (!_.isNil(reading.interfaceView.interfaceUnitType) && !_.isNil(reading.interfaceView.interfaceUnitType.properties)) {
            if (reading.reading && reading.interfaceView.terminalInterfaceDataType === InterfaceService.dataType.BOOLEAN && reading.interfaceView.interfaceUnitType.properties.name) {
                booleanTranslation = LocalesUtils.getLocaleString(JSON.parse(reading.interfaceView.interfaceUnitType.properties.name)[reading.reading]);
            } else {
                symbol = reading.interfaceView.interfaceUnitType.properties.symbol;
            }
        }
        reading.terminal = reading.interfaceView.terminal.displayName;
        reading.interface = reading.interfaceView.displayName;

        //todo remove this code
        if(reading.interfaceView.terminalInterfaceDataType === InterfaceService.dataType.BOOLEAN) {
            reading.reading = booleanTranslation ? booleanTranslation : (reading.reading + ' ' + symbol);
        } else {
            reading.reading = Math.round(reading.reading * 100) / 100 + ' ' + symbol;
        }
        reading.category = reading.interfaceView.category;
    });

    return results;
}


function parseInterfaceValues(results) {
    _.forEach(results, row => {
        let symbol = "";
        let name = "";

        if (!_.isNil(row.interfaceUnitType)) {
            if (row.terminalInterfaceDataType === InterfaceService.dataType.BOOLEAN) {
                if (row.lastConvertedReading && row.interfaceUnitType?.properties?.name) {
                    try {
                        name = LocalesUtils.getLocaleString(JSON.parse(row.interfaceUnitType.properties.name)[row.lastConvertedReading]);
                    } catch (e) {
                        try {
                            const wrongValue = parseInt(row.lastConvertedReading) > 0 ? "false" : true;
                            name = LocalesUtils.getLocaleString(JSON.parse(row.interfaceUnitType.properties.name)[wrongValue]);
                        } catch (ee) {
                            name = row.lastConvertedReading
                        }
                    }
                }
            } else {
                symbol = row.interfaceUnitType.properties ? row.interfaceUnitType.properties.symbol ? row.interfaceUnitType.properties.symbol : null : null;
                name = row.interfaceUnitType.value ? row.interfaceUnitType.value : null;
            }
        }
        row.category = _.isObject(row.category) ? LocalesUtils.filterLocalesOfObject(row.category) : row.category;
        row.readingWithSymbol = {
            'lastConvertedReading': row.lastConvertedReading,
            'symbol': symbol,
            'name': name
        };
    });

    return results;
}

export default InterfaceService;