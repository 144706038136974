import _ from "lodash";
import moment from 'moment';
import TimezoneUtils from "../../../utils/TimezoneUtils";
import __ from "../../../utils/EnhancedLodash";

let SchedulerUtils = {

    keys: {
        weekdaysKeys: {
            daysOfWeek: 'daysOfWeek',
            weekdays: 'weekdays'
        },
        configsKeys: {
            timeIntervals: 'timeIntervals',
            configs: 'configs'
        }
    },

    rawDaysMap: {
        mon: [],
        tue: [],
        wed: [],
        thu: [],
        fri: [],
        sat: [],
        sun: []
    },

    weekdaysEmptyObject : {
        mon: false,
        tue: false,
        wed: false,
        thu: false,
        fri: false,
        sat: false,
        sun: false
    },

    weekdays: {
        mon: {
            after: 'tue',
            before: 'sun',
            name: 'Monday',
            isoWeekday: 1
        },
        tue: {
            after: 'wed',
            before: 'mon',
            name: 'Tuesday',
            isoWeekday: 2
        },
        wed: {
            after: 'thu',
            before: 'tue',
            name: 'Wednesday',
            isoWeekday: 3
        },
        thu: {
            after: 'fri',
            before: 'wed',
            name: 'Thursday',
            isoWeekday: 4
        },
        fri: {
            after: 'sat',
            before: 'thu',
            name: 'Friday',
            isoWeekday: 5
        },
        sat: {
            after: 'sun',
            before: 'fri',
            name: 'Saturday',
            isoWeekday: 6
        },
        sun: {
            after: 'mon',
            before: 'sat',
            name: 'Sunday',
            isoWeekday: 7
        },

    },

    localizeLinesData: function (lines, iface, timezone, weekdaysKey, configsKey) {
        let dates = {};
        _.forEach(lines, line => {
            let weekday = _.keysIn(_.pickBy(line[weekdaysKey], w => {return w}));
            dates[weekday] = [];
            _.forEach(line[configsKey], config => {
                let startTimeArr = _.split(config.startTime, ':');
                let endTimeArr = _.split(config.endTime, ':');
                let startTime = SchedulerUtils.getMomentForWeekDayWithTime(weekday, startTimeArr[0], startTimeArr[1], startTimeArr[2]);
                let endTime = SchedulerUtils.getMomentForWeekDayWithTime(weekday, endTimeArr[0], endTimeArr[1], endTimeArr[2]);

                if(!__.isNilOrEmpty(startTime) && !__.isNilOrEmpty(endTime)) {
                    dates[weekday].push({
                        startTime: TimezoneUtils.convertUTCToTimezone(startTime.format('YYYY-MM-DDTHH:mm:ss'), timezone),
                        endTime: TimezoneUtils.convertUTCToTimezone(endTime.format('YYYY-MM-DDTHH:mm:ss'), timezone),
                        action: config.action,
                        forced: config.forced,
                        displayName: config.displayName,
                        value: config.value
                    });
                }
            });
        });
        return SchedulerUtils.getUnzippedDays(dates, iface, weekdaysKey, configsKey);
    },

    getZippedDays: function (rawDaysMap) {
        let daysMap = _.cloneDeep(SchedulerUtils.rawDaysMap);
        _.forOwn(rawDaysMap, rawDay => {
            _.forEach(rawDay, timeInterval => {
                if (timeInterval.startTime.isoWeekday() === timeInterval.endTime.isoWeekday()) {
                    let weekday = SchedulerUtils.getWeekdayKey(timeInterval.startTime.isoWeekday());
                    daysMap[weekday].push(_.cloneDeep(timeInterval));
                } else {
                    let startTimeWeekDay = SchedulerUtils.getWeekdayKey(timeInterval.startTime.isoWeekday());
                    let endTimeWeekDay = SchedulerUtils.getWeekdayKey(timeInterval.endTime.isoWeekday());

                    daysMap[startTimeWeekDay].push({
                        startTime: timeInterval.startTime.clone(),
                        endTime: SchedulerUtils.getEndOfDayOfDate(timeInterval.startTime.clone()),
                        action: timeInterval.action,
                        forced: timeInterval.forced,
                        displayName: timeInterval.displayName,
                        value: timeInterval.value
                    });

                    daysMap[endTimeWeekDay].push({
                        startTime: SchedulerUtils.getStartofDayOfDate(timeInterval.endTime.clone()),
                        endTime: timeInterval.endTime.clone(),
                        action: timeInterval.action,
                        forced: timeInterval.forced,
                        displayName: timeInterval.displayName,
                        value: timeInterval.value
                    });
                }
            });
        });
        return daysMap;
    },

    getUnzippedDays: function (rawDaysMap, iface, weekdaysKey, configsKey) {
        let daysMap = SchedulerUtils.getZippedDays(rawDaysMap);
        daysMap = SchedulerUtils.allDaysSameDayAndOrderTimeIntervals(daysMap);
        daysMap = SchedulerUtils.zipDays(daysMap);
        let lines = SchedulerUtils.createLinesData(daysMap, iface, weekdaysKey, configsKey);
        lines = SchedulerUtils.zipLines(lines, weekdaysKey, configsKey);
        return lines;
    },

    createLinesData: function (daysMap, iface, weekdaysKey) {
        let lines = [];
        let weekdaysEmptyObject = _.cloneDeep(SchedulerUtils.weekdaysEmptyObject);
        _.forOwn(daysMap, (timeIntervals, dayKey) => {
            let weekdays = _.clone(weekdaysEmptyObject);
            weekdays[dayKey] = true;
            if(weekdaysKey === SchedulerUtils.keys.weekdaysKeys.daysOfWeek){
                lines.push({
                    daysOfWeek: weekdays,
                    timeIntervals: SchedulerUtils.getZippedConfigs(timeIntervals, iface)
                })
            } else if (weekdaysKey === SchedulerUtils.keys.weekdaysKeys.weekdays){
                lines.push({
                    weekdays: weekdays,
                    configs: SchedulerUtils.getZippedConfigs(timeIntervals, iface)
                })
            }
        });
        return lines;
    },

    zipLines: function (lines, weekdaysKey, configsKey) {
        let linesResult = [];
        _.forEach(lines, line => {
            let trueDaysInLine = _.keysIn(_.pickBy(line[weekdaysKey], w => {return w}));
            if (_.isEmpty(linesResult)) {
                linesResult.push(line);
            } else {
                let independentConfigs = true;
                _.forEach(linesResult, existingLine => {
                    if (_.isEqual(existingLine[configsKey], line[configsKey])) {
                        _.forEach(trueDaysInLine, day => {
                            existingLine[weekdaysKey][day] = true;
                            independentConfigs = false;
                        });
                    }
                });
                if(independentConfigs) {
                    linesResult.push(line);
                }
            }
        });
        return linesResult;
    },

    allDaysSameDayAndOrderTimeIntervals: function (daysMap) {
        let daysMapClean = _.cloneDeep(SchedulerUtils.rawDaysMap);
        _.forOwn(daysMap, (day, dayKey) => {
            if (!_.isEmpty(day)) {
                daysMapClean[dayKey] = [];
                let startDayToSet = day[0].startTime.date();
                let endDayToSet = day[0].endTime.date();
                _.forEach(day, timeInterval => {
                    let timeIntervalAux = _.cloneDeep(timeInterval);
                    timeIntervalAux.startTime.date(startDayToSet);
                    timeIntervalAux.endTime.date(endDayToSet);
                    daysMapClean[dayKey].push(_.cloneDeep(timeIntervalAux));
                });
                daysMapClean[dayKey] = _.sortBy(_.clone(daysMapClean[dayKey]), timeInterval => {
                    return timeInterval.startTime;
                })
            }
        });
        return daysMapClean;
    },

    normalizeUTCValues: function (linesData, component, timezone, weekdaysKey, configsKey) {
        let linesDataAux = _.cloneDeep(linesData);
        let rawDaysMap = _.clone(SchedulerUtils.rawDaysMap);
        _.forEach(linesDataAux, line => {
            let trueDays = _.keysIn(_.pickBy(line[weekdaysKey], w => {
                return w
            }));
            _.forEach(trueDays, weekday => {
                rawDaysMap[weekday] = SchedulerUtils.getLineConfigsForDay(line[configsKey], weekday, timezone);
            });
        });
        let daysMap = SchedulerUtils.getZippedDays(rawDaysMap);
        let zippedDays = SchedulerUtils.zipDays(daysMap);
        return SchedulerUtils.transformZippedDaysIntoScheduleObject(zippedDays, component, weekdaysKey, configsKey);
    },

    transformZippedDaysIntoScheduleObject: function (zippedDays, component, weekdaysKey, configsKey) {
        let lines = [];
        let interfaceId, callbackValue;
        let weekdaysEmptyObject = _.cloneDeep(SchedulerUtils.weekdaysEmptyObject);

        if(weekdaysKey === SchedulerUtils.keys.weekdaysKeys.weekdays) {
            interfaceId = component.getInterfaceId();
            callbackValue = component.getCallbackValue();
        }
        _.forOwn(zippedDays, (timeIntervals, dayKey) => {
            let weekdays = _.clone(weekdaysEmptyObject);
            weekdays[dayKey] = true;
            if(weekdaysKey === SchedulerUtils.keys.weekdaysKeys.weekdays) {
                lines.push({
                    callbackValue: callbackValue,
                    weekdays: weekdays,
                    configs: SchedulerUtils.getZippedConfigs(timeIntervals, interfaceId)
                })
            } else if (weekdaysKey === SchedulerUtils.keys.weekdaysKeys.daysOfWeek) {
                lines.push({
                    daysOfWeek: weekdays,
                    timeIntervals: SchedulerUtils.getZippedConfigs(timeIntervals)
                })
            }
        });

        return lines;
    },

    getZippedConfigs: function (timeIntervals, interfaceId) {
        let result = [];
        _.forEach(timeIntervals, timeInterval => {
            result.push({
                startTime: timeInterval.startTime.format('HH:mm:ss'),
                endTime: timeInterval.endTime.format('HH:mm:ss'),
                action: timeInterval.action,
                forced: timeInterval.forced,
                displayName: timeInterval.displayName,
                value: timeInterval.value,
                interfaceId: interfaceId
            });
        });
        return result;
    },

    getLineConfigsForDay: function (lineConfigs, weekday, timezone) {
        let result = [];
        let timezoneOffset =  moment.tz(timezone).utcOffset();
        _.forEach(lineConfigs, config => {
            let startTime = SchedulerUtils.getMomentForWeekDayWithTime(
                weekday,
                _.isString(config.startTime)? _.split(config.startTime, ':')[0] : config.startTime.hour(),
                _.isString(config.startTime)? _.split(config.startTime, ':')[1] : config.startTime.minute(),
                _.isString(config.startTime)? _.split(config.startTime, ':')[2] : config.startTime.second());
            let endTime = SchedulerUtils.getMomentForWeekDayWithTime(
                weekday,
                _.isString(config.endTime)? _.split(config.endTime, ':')[0] : config.endTime.hour(),
                _.isString(config.endTime)? _.split(config.endTime, ':')[1] : config.endTime.minute(),
                _.isString(config.endTime)? _.split(config.endTime, ':')[2] : config.endTime.second());
            result.push({
                startTime: startTime.clone().subtract(timezoneOffset, 'minutes').utc(),
                endTime: endTime.clone().subtract(timezoneOffset, 'minutes').utc(),
                action: config.action,
                forced: config.forced,
                displayName: config.displayName,
                value: config.value,
            });
        });
        return result;
    },

    getMomentForWeekDayWithTime: function (weekday, hour, minute, second) {
        if(!__.isNilOrEmpty(weekday)) {
            return moment().utc().day(SchedulerUtils.weekdays[weekday].isoWeekday).hours(hour).minutes(minute).seconds(second);
        }
    },

    zipDays: function (daysMap) {
        let result = {};
        _.forOwn(daysMap, (timeIntervals, key) => {
            if (!_.isEmpty(timeIntervals)) {
                result[key] = SchedulerUtils.zipTimeIntervals(timeIntervals);
            }
        });
        return result;
    },

    zipTimeIntervals: function (timeIntervals) {
        let orderedTimeIntervals = _.sortBy(_.clone(timeIntervals), timeInterval => {
            return timeInterval.startTime;
        });
        let result = [];
        for (let i = 0; i < orderedTimeIntervals.length; i++) {
            let thisTimeInterval = orderedTimeIntervals[i];
            let nextTimeInterval = orderedTimeIntervals[i + 1];
            if (!SchedulerUtils.timeIntervalsCanBeMerged(result[result.length - 1], thisTimeInterval) &&
                SchedulerUtils.timeIntervalsCanBeMerged(thisTimeInterval, nextTimeInterval)) {
                result.push({
                    startTime: thisTimeInterval.startTime.clone(),
                    endTime: nextTimeInterval.endTime.clone(),
                    action: thisTimeInterval.action,
                    forced: thisTimeInterval.forced,
                    displayName: thisTimeInterval.displayName,
                    value: thisTimeInterval.value
                });
                i++;
            } else if (SchedulerUtils.timeIntervalsCanBeMerged(result[result.length - 1], thisTimeInterval)) {
                result[result.length - 1].endTime = thisTimeInterval.endTime.clone();
            } else {
                result.push(thisTimeInterval);
            }
        }
        return result;
    },

    timeIntervalsCanBeMerged: function (thisTimeInterval, nextTimeInterval) {
        if(thisTimeInterval && nextTimeInterval) {
            if (!_.isNil(thisTimeInterval.displayName) && !_.isEmpty(thisTimeInterval.displayName)) {
                return thisTimeInterval.displayName === nextTimeInterval.displayName  &&
                    thisTimeInterval.value === nextTimeInterval.value && thisTimeInterval.forced === nextTimeInterval.forced &&
                    nextTimeInterval.startTime.diff(thisTimeInterval.endTime, 'seconds') === 1
            } else {
                return thisTimeInterval.action === nextTimeInterval.action && thisTimeInterval.forced === nextTimeInterval.forced &&
                    nextTimeInterval.startTime.diff(thisTimeInterval.endTime, 'seconds') === 1
            }
        } else {
            return false;
        }
    },

    getWeekdayKey(isoWeekday){
        return _.findKey(SchedulerUtils.weekdays, wd => {return wd.isoWeekday === isoWeekday});
    },

    getEndOfDayOfDate(datetime) {
        let result = datetime.clone();
        result.hours(23).minutes(59).seconds(59);
        return result;
    },

    getStartofDayOfDate(datetime) {
        let result = datetime.clone();
        result.hours(0).minutes(0).seconds(0);
        return result;
    },

};

export default SchedulerUtils;