import { call, put, takeEvery } from 'redux-saga/effects';
import * as chartActionTypes from './actionTypes';
import * as actionTypes from './../../actionTypes';
import _ from "lodash";
import AsyncWidgetService from "../../../../services/async/AsyncWidgetService";

function* fetchMortalityInfoData({ id, farmSelector }) {
    try {
        let entitiesFilter =  !_.isNil(farmSelector) && farmSelector !== 'null' ? JSON.parse(farmSelector) : [];
        const mortalityInfoData = yield call(getMortalityInfoDataRequest, entitiesFilter);
        const finalData = {
            mortalityInfoData: mortalityInfoData
        };

        yield put({
            type: actionTypes.LOAD_WIDGET_DATA_SUCCESS,
            id: id,
            data: finalData
        })
    } catch (e) {
        yield put({
            id: id,
            type: actionTypes.LOAD_WIDGET_DATA_FAILED
        });
    }
}

const mortalitySagas = [
    takeEvery(chartActionTypes.LOAD_MORTALITY_BY_CAUSE_WIDGET_DATA_REQUEST,  fetchMortalityInfoData)
];

const getMortalityInfoDataRequest = (entitiesFilter) => {
    return AsyncWidgetService.getAnimalsMortalityByCategory(entitiesFilter);
};

export { mortalitySagas };