import _ from "lodash";

/* eslint import/no-anonymous-default-export: [2, {"allowObject": true}] */
export default {
    pathHasAuthenticationAndIsNotHomePage(pathname) {
        return !_.includes([
                "/login",
                "/forgot_password",
                "/not_found"
            ], pathname)
            && !_.startsWith(pathname, "/account/")
            && !_.startsWith(pathname, "/farmview")
            && !_.isEqual(pathname, '/')
    },

    stringToBoolean: function(string){
        switch(_.toLower(string).trim()){
            case "true": case "yes": case "1": return true;
            case "false": case "no": case "0": case null: return false;
            default: return Boolean(string);
        }
    },

    flattenDeep: function(source, filter) {
        let result = {}
        ;(function flat(obj, stack) {
            Object.keys(obj).forEach(function(k) {
                let s = stack.concat([k]);
                let v = obj[k];
                if (filter && filter(k, v)) return;
                if (typeof v === 'object') flat(v, s);
                else result[s.join('.')] = v
            })
        })(source, []);
        return Object.keys(result)
    }
};