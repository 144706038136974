export const GET_BATCH_LIST_REQUEST = '[PRODUCTION_BATCHES] GET_BATCH_LIST_REQUEST';
export const GET_BATCH_LIST_SUCCESS = '[PRODUCTION_BATCHES] GET_BATCH_LIST_SUCCESS';
export const GET_BATCH_LIST_FAILED = '[PRODUCTION_BATCHES] GET_BATCH_LIST_FAILED';
export const RESET_BATCH_LIST = '[PRODUCTION_BATCHES] RESET_BATCH_LIST';

export const GET_BATCH_REQUEST = '[PRODUCTION_BATCHES] GET_BATCH_REQUEST';
export const GET_BATCH_SUCCESS = '[PRODUCTION_BATCHES] GET_BATCH_SUCCESS';
export const GET_BATCH_FAILED = '[PRODUCTION_BATCHES] GET_BATCH_FAILED';

export const GET_PRODUCTION_CURVE_REQUEST = '[PRODUCTION_BATCHES] GET_PRODUCTION_CURVE_REQUEST';
export const GET_PRODUCTION_CURVE_SUCCESS = '[PRODUCTION_BATCHES] GET_PRODUCTION_CURVE_SUCCESS';
export const GET_PRODUCTION_CURVE_FAILED = '[PRODUCTION_BATCHES] GET_PRODUCTION_CURVE_FAILED';

export const GET_PRODUCTION_BATCH_WINDOW_REQUEST = '[PRODUCTION_BATCHES] GET_PRODUCTION_BATCH_WINDOW_REQUEST';
export const GET_PRODUCTION_BATCH_WINDOW_SUCCESS = '[PRODUCTION_BATCHES] GET_PRODUCTION_BATCH_WINDOW_SUCCESS';
export const GET_PRODUCTION_BATCH_WINDOW_FAILED = '[PRODUCTION_BATCHES] GET_PRODUCTION_BATCH_WINDOW_FAILED';