import React, {useEffect} from "react";

export default function useWindowSize() {
    const isSSR = typeof window === "undefined";
    const [windowSize, setWindowSize] = React.useState({
        width: isSSR ? 1200 : window.innerWidth,
        height: isSSR ? 800 : window.innerHeight,
        isMobile: isSSR ? false : window.innerWidth > 200 && window.innerWidth < 1512,
    });

    useEffect(() => {
        window.addEventListener("resize", () => {
            setWindowSize({ width: window.innerWidth, height: window.innerHeight, isMobile: window.innerWidth > 200 && window.innerWidth < 1512 });
        });

        return () => {
            window.removeEventListener("resize", () => {
                setWindowSize({ width: window.innerWidth, height: window.innerHeight, isMobile: false });
            });
        };
    }, []);

    return windowSize;
}

