import React from "react";
import DateTime from "react-datetime";

const DateTimeWithMask = React.forwardRef((props, ref) => {
    const mask = props.mask;
    const renderInput = ( props ) => {
        return (
            <input data-inputmask={"'mask': '" + mask +  "'"} {...props} style={{border:0, height: "33px"}}/>
        );
    };
    return (
        <DateTime
            renderInput={ renderInput }
            {...props}
            ref={ref}
        />
    ) ;
});

export default DateTimeWithMask;