import { call, put, takeEvery } from 'redux-saga/effects';
import * as chartActionTypes from './actionTypes';
import * as actionTypes from './../../actionTypes';
import _ from "lodash";
import AsyncWidgetService from "../../../../services/async/AsyncWidgetService";

function* fetchAnimalWeightData({ id, farmSelector }) {
    try {
        let entitiesFilter =  !_.isNil(farmSelector) && farmSelector !== 'null' ? JSON.parse(farmSelector) : [];
        const animalWeightData = yield call(getAnimalWeightDataRequest, entitiesFilter);
        const finalData = {
            animalWeightData: animalWeightData
        };

        yield put({
            type: actionTypes.LOAD_WIDGET_DATA_SUCCESS,
            id: id,
            data: finalData
        })
    } catch (e) {
        yield put({
            id: id,
            type: actionTypes.LOAD_WIDGET_DATA_FAILED
        });
    }
}

const animalWeightSagas = [
    takeEvery(chartActionTypes.LOAD_ANIMAL_WEIGHT_WIDGET_DATA_REQUEST,  fetchAnimalWeightData)
];

const getAnimalWeightDataRequest = (entitiesFilter) => {
    return AsyncWidgetService.getAnimalsByWeight(entitiesFilter);
};

export { animalWeightSagas };




