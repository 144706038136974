export const RESET_STATE = "[ENTITIES] RESET_STATE";

export const LOAD_ENTITY_TYPES = "[ENTITIES] LOAD_ENTITY_TYPES";

export const ENTITY_TYPE_CHANGE_REQUEST = "[ENTITIES] ENTITY_TYPE_CHANGE_REQUEST";
export const ENTITY_TYPE_CHANGE_SUCCESS = "[ENTITIES] ENTITY_TYPE_CHANGE_SUCCESS";

export const PARENT_CHANGE_REQUEST = "[ENTITIES] PARENT_CHANGE_REQUEST";
export const PARENT_CHANGE_SUCCESS = "[ENTITIES] PARENT_CHANGE_SUCCESS";

export const SHOW_CREATE_TAGS = "[ENTITIES] SHOW_CREATE_TAGS";
export const SUBMIT_TAGS_REQUEST = "[ENTITIES] SUBMIT_TAGS_REQUEST";
export const UPDATE_TAGS_STATE = "[ENTITIES] UPDATE_TAGS_STATE";
export const SUBMIT_TAGS_SUCCESS = "[ENTITIES] SUBMIT_TAGS_SUCCESS";

export const ENTITY_VIEW_EDIT = "[ENTITIES] ENTITY_VIEW_EDIT";

export const GET_ENTERPRISE_LIST_REQUEST = '[ENTITIES] GET_ENTERPRISE_LIST_REQUEST';
export const GET_ENTERPRISE_LIST_SUCCESS = '[ENTITIES] GET_ENTERPRISE_LIST_SUCCESS';
export const GET_ENTERPRISE_LIST_FAILED = '[ENTITIES] GET_ENTERPRISE_LIST_FAILED';
export const RESET_ENTERPRISE_LIST = '[ENTITIES] RESET_ENTERPRISE_LIST';

export const GET_FARM_LIST_REQUEST = '[ENTITIES] GET_FARM_LIST_REQUEST';
export const GET_FARM_LIST_SUCCESS = '[ENTITIES] GET_FARM_LIST_SUCCESS';
export const GET_FARM_LIST_FAILED = '[ENTITIES] GET_FARM_LIST_FAILED';

export const GET_FARM_AND_SECTOR_LIST_REQUEST = '[ENTITIES] GET_FARM_AND_SECTOR_LIST_REQUEST';
export const GET_FARM_AND_SECTOR_LIST_SUCCESS = '[ENTITIES] GET_FARM_AND_SECTOR_LIST_SUCCESS';
export const GET_FARM_AND_SECTOR_LIST_FAILED = '[ENTITIES] GET_FARM_AND_SECTOR_LIST_FAILED';
export const RESET_FARM_AND_SECTOR_LIST = '[ENTITIES] RESET_FARM_AND_SECTOR_LIST';

export const GET_ENTITY_DEFAULT_INTERFACES_REQUEST = '[ENTITIES] GET_ENTITY_DEFAULT_INTERFACES_REQUEST';
export const GET_ENTITY_DEFAULT_INTERFACES_SUCCESS = '[ENTITIES] GET_ENTITY_DEFAULT_INTERFACES_SUCCESS';
export const GET_ENTITY_DEFAULT_INTERFACES_FAILED = '[ENTITIES] GET_ENTITY_DEFAULT_INTERFACES_FAILED';
