import React from 'react';

const ClickWrapper = ({children, onClickHandler}) => {
    return (
        <div onClick={onClickHandler} className={'cursor-pointer'}>
            {children}
        </div>
    )
}

export default ClickWrapper;