import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import RuleService from "../../../services/RuleService";
import CheckboxGroup from "../checkbox/CheckboxGroup";

class SchedulerLine extends React.Component {
    constructor(props){
        super(props);

        this.weekdaysRef = {};
        this.state = {
            configsAreOpened: false,
            isView: !_.isNil(props.isView) ? props.isView : false
        }
    }

    componentDidMount() {
        let self = this;
        if(this.props.alreadyCheckedOptions) {
            _.forOwn(this.props.alreadyCheckedOptions, (optionValue, optionId) => {
                if(optionValue && self.weekdaysRef) {
                    self.weekdaysRef.setDisabledOption(optionId, true);
                }
            })
        }
        if(this.props.selectedWeekdays) {
            this.setWeekdays(this.props.selectedWeekdays);
        }
    }

    toggleConfigsAreOpened() {
        this.setState({
            configsAreOpened: !this.state.configsAreOpened
        });
    }

    setIsView (isView) {
        this.setState({isView: isView});
    }

    validateField(...param) {
        return true;
    }

    getWeekdays() {
        return this.weekdaysRef.getFieldValue();
    }

    setWeekdays(weekdays) {
        this.weekdaysRef.setCheckedOptions(weekdays);
    }

    render() {
        let self = this;
        let options = this.props.options;
        let selectAll = this.props.hasSelectAll? RuleService.weekdays.selectAll() : null;
        let onWeekdayChanged = this.props.onWeekdayChanged? this.props.onWeekdayChanged : () => {};
        let onSelectAllChanged = this.props.onSelectAllChanged? this.props.onSelectAllChanged : () => {};
        let arrowIcon = self.state.configsAreOpened? 'fa-chevron-down' : 'fa-chevron-right';

        return (
            <div className={"flex justify-center align-items-center"} key={this.props.id}>
                <div>
                    <i
                        className={'fa fa-lg fa-fw ' + arrowIcon}
                        style={{cursor: 'pointer', marginBottom: '15px'}}
                        onClick={() => {
                            toggleVisibility(self, self.props.id);
                        }}/>
                </div>
                <div className={"flex-1"} key={this.props.id}>
                    <CheckboxGroup
                        key={this.props.id + "_checkboxGroup"}
                        id={this.props.id}
                        options={options}
                        selectAll={selectAll}
                        isView={this.state.isView}
                        selectAllEnabled={self.props.selectAllEnabled}
                        onOptionChanged={(optionId, value) => {
                            onWeekdayChanged(optionId, value)
                        }}
                        onSelectAllChanged={(value, clicked) => {
                            onSelectAllChanged(value, clicked);
                        }}
                        ref={c => self.weekdaysRef = c}
                    />
                </div>
                {isViewMode(this)? null :
                    <div>
                    {buildDeleteButton(self)}
                </div>}
            </div>
        );
    }
}

function buildDeleteButton(component) {
    if(!component.state.isView && component.props.hasDeleteButton) {
        return <i className="fa fa-lg mb-zero-absolute fa-trash col-md-4"
                  style={{alignSelf: "center", cursor: "pointer", textAlign: "right"}}
                  onClick={() => deleteLine(component.props.parent, component.props.id, component.weekdaysRef.state.checkedOptions)}/>;
    } else {
        return null;
    }
}

function deleteLine(parent, line, checkedOptions) {
    parent.deleteLine(line, checkedOptions);
}

function toggleVisibility(component, id) {
    component.props.parent.configRefs[id].toggleVisibility();
    component.toggleConfigsAreOpened();
}

function isViewMode(component) {
    return component.state.isView;
}

SchedulerLine.propTypes = {
    id: PropTypes.string,
    parent: PropTypes.object,
    onWeekdayChanged: PropTypes.func,
    hasSelectAll: PropTypes.bool,
    hasDeleteButton: PropTypes.bool,
    alreadyCheckedOptions: PropTypes.object,
    selectedWeekdays: PropTypes.object
};

export default SchedulerLine;