import {NavLink} from "react-router-dom";
import _ from "lodash";
import ProductionUtils from "../../utils/ProductionUtils";
import EntityService from "../../services/EntityService";
import DateUtils from "../../utils/DateUtils";
import React from "react";
import __ from "../../utils/EnhancedLodash";
import UserSessionService from "../../services/UserSessionService";
import {config} from "../../config";
import {t} from "../../utils/Translation";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import Tooltip from "../../components/tooltip/Tooltip";
import './style/events.scss'
import EventService from "../../services/EventService";
import RuleCacheService from "../../services/RuleCacheService";

function getEventsSearchBoxDefinition(hasParent, isHelpdesk) {
    if(isHelpdesk) {
        return [[{
            fieldId: 'title',
            fieldLabel: t('events.list.searchBox.fields.eventTitle.label'),
            fieldPlaceHolder: t('events.list.searchBox.fields.eventTitle.placeholder'),
            fieldType: 'text',
            width: 4
        }, {
            fieldId: 'enterpriseId',
            fieldLabel: t('events.list.searchBox.fields.enterpriseId.label'),
            fieldPlaceHolder: t('events.list.searchBox.fields.enterpriseId.placeholder'),
            fieldType: 'dropdown',
            dataAttribute: 'displayName',
            iconClassName: 'fa fa-sitemap',
            width: 4,
        }, {
            fieldId: 'entity',
            fieldLabel: t('events.list.searchBox.fields.entityId.label'),
            fieldPlaceHolder: t('events.list.searchBox.fields.entityId.placeholder'),
            fieldType: 'dropdown',
            iconClassName: 'fa fa-sitemap',
            width: 4,
            dataAttribute: "complexName"
        }], [{
            fieldId: 'severityLevel',
            fieldLabel: t('events.list.searchBox.fields.severity.label'),
            fieldPlaceHolder: t('events.list.searchBox.fields.severity.placeholder'),
            fieldType: 'dropdown-multi',
            width: 4,
        }, {
            fieldId: 'category',
            fieldLabel: t('events.list.searchBox.fields.categoryId.label'),
            fieldPlaceHolder: t('events.list.searchBox.fields.categoryId.placeholder'),
            fieldType: 'dropdown-multi',
            width: 4
        }, {
            fieldId: 'cause',
            fieldLabel: t('events.list.searchBox.fields.cause.label'),
            fieldPlaceHolder: t('events.list.searchBox.fields.cause.placeholder'),
            fieldType: 'dropdown-multi',
            iconClassName: 'fa fa-sitemap',
            width: 4,
        }], [{
            fieldId: 'fromDate',
            fieldLabel: t('events.list.searchBox.fields.dateFrom.label'),
            fieldPlaceHolder: t('events.list.searchBox.fields.dateFrom.placeholder'),
            hasDate: true,
            hasTime: true,
            fieldType: 'dateTime',
            iconClassName: 'fa fa-calendar',
            width: 4
        }, {
            fieldId: 'toDate',
            fieldLabel: t('events.list.searchBox.fields.dateTo.label'),
            fieldPlaceHolder: t('events.list.searchBox.fields.dateTo.placeholder'),
            hasDate: true,
            hasTime: true,
            fieldType: 'dateTime',
            iconClassName: 'fa fa-calendar',
            width: 4
        }, {
            fieldId: "user",
            fieldLabel: t('events.list.searchBox.fields.user.label'),
            fieldPlaceHolder: t('events.list.searchBox.fields.user.placeholder'),
            dataAttribute: 'shortName',
            dataId: 'userId',
            fieldType: "dropdown",
            width: 4
        }], [{
            fieldId: "closeDateFrom",
            fieldLabel: t('events.list.searchBox.fields.closeDateFrom.label'),
            fieldPlaceHolder: t('events.list.searchBox.fields.closeDateFrom.placeholder'),
            fieldType: "dateTime",
            hasTime: true,
            hasDate: true,
            width: "4",
            iconClassName: "fa fa-calendar",
        }, {
            fieldId: "closeDateTo",
            fieldLabel: t('events.list.searchBox.fields.closeDateTo.label'),
            fieldPlaceHolder: t('events.list.searchBox.fields.closeDateTo.placeholder'),
            fieldType: "dateTime",
            hasTime: true,
            hasDate: true,
            width: "4",
            iconClassName: "fa fa-calendar"
        }, {
            fieldId: 'contact',
            fieldLabel: t('events.list.searchBox.fields.contact.label'),
            fieldPlaceHolder: t('events.list.searchBox.fields.contact.placeholder'),
            fieldType: 'text',
            width: 4
        }], [{
            fieldId: 'status',
            fieldLabel: t('events.list.searchBox.fields.status.label'),
            fieldPlaceHolder: t('events.list.searchBox.fields.status.placeholder'),
            fieldType: 'dropdown-multi',
            width: 4,
            iconClassName: 'fa fa-repeat',
            sort: data => data,
        }]];
    } else {
        return [[{
            fieldId: 'title',
            fieldLabel: t('events.list.searchBox.fields.eventTitle.label'),
            fieldPlaceHolder: t('events.list.searchBox.fields.eventTitle.placeholder'),
            fieldType: 'text',
            width: 4
        }, !hasParent ? {
            fieldId: 'enterpriseId',
            fieldLabel: t('events.list.searchBox.fields.enterpriseId.label'),
            fieldPlaceHolder: t('events.list.searchBox.fields.enterpriseId.placeholder'),
            fieldType: 'dropdown',
            dataAttribute: 'displayName',
            iconClassName: 'fa fa-sitemap',
            width: 4,
        } : null, !hasParent ? {
            fieldId: 'entity',
            fieldLabel: t('events.list.searchBox.fields.entityId.label'),
            fieldPlaceHolder: t('events.list.searchBox.fields.entityId.placeholder'),
            fieldType: 'dropdown-multi',
            iconClassName: 'fa fa-sitemap',
            width: 4,
            dataAttribute: "complexName",
        } : null, !hasParent && !isHelpdesk ? {
            fieldId: 'batchId',
            fieldLabel: t('events.list.searchBox.fields.batchId.label'),
            fieldPlaceHolder: t('events.list.searchBox.fields.batchId.placeholder'),
            fieldType: 'dropdown-multi',
            dataAttribute: 'complexCode',
            iconClassName: 'fa fa-sitemap',
            width: 4,
            sort: data => data,
        } : null, {
            fieldId: 'status',
            fieldLabel: t('events.list.searchBox.fields.status.label'),
            fieldPlaceHolder: t('events.list.searchBox.fields.status.placeholder'),
            fieldType: 'dropdown-multi',
            width: 4,
            iconClassName: 'fa fa-repeat',
            sort: data => data,
        }, {
            fieldId: 'severityLevel',
            fieldLabel: t('events.list.searchBox.fields.severity.label'),
            fieldPlaceHolder: t('events.list.searchBox.fields.severity.placeholder'),
            fieldType: 'dropdown-multi',
            width: 4,
        },  !isHelpdesk ? {
            fieldId: 'origin',
            fieldLabel: t('events.list.searchBox.fields.origin.label'),
            fieldPlaceHolder: t('events.list.searchBox.fields.origin.placeholder'),
            fieldType: 'dropdown-multi',
            width: 4
        } : null, {
            fieldId: 'category',
            fieldLabel: t('events.list.searchBox.fields.categoryId.label'),
            fieldPlaceHolder: t('events.list.searchBox.fields.categoryId.placeholder'),
            fieldType: 'dropdown-multi',
            width: 4
        }, {
            fieldId: 'cause',
            fieldLabel: t('events.list.searchBox.fields.cause.label'),
            fieldPlaceHolder: t('events.list.searchBox.fields.cause.placeholder'),
            fieldType: 'dropdown-multi',
            iconClassName: 'fa fa-sitemap',
            width: 4,
        }], [ !hasParent ? {
            fieldId: 'fromDate',
            fieldLabel: t('events.list.searchBox.fields.dateFrom.label'),
            fieldPlaceHolder: t('events.list.searchBox.fields.dateFrom.placeholder'),
            hasDate: true,
            hasTime: true,
            fieldType: 'dateTime',
            iconClassName: 'fa fa-calendar',
            width: 4
        }: null, !hasParent ? {
            fieldId: 'toDate',
            fieldLabel: t('events.list.searchBox.fields.dateTo.label'),
            fieldPlaceHolder: t('events.list.searchBox.fields.dateTo.placeholder'),
            hasDate: true,
            hasTime: true,
            fieldType: 'dateTime',
            iconClassName: 'fa fa-calendar',
            width: 4
        }: null, !hasParent ? {
            fieldId: 'lastTime',
            fieldLabel: t('events.list.searchBox.fields.lastTime.label'),
            fieldPlaceHolder: t('events.list.searchBox.fields.lastTime.placeholder'),
            fieldType: 'dropdown',
            width: 4
        }: null], [{
            fieldId: "user",
            fieldLabel: t('events.list.searchBox.fields.user.label'),
            fieldPlaceHolder: t('events.list.searchBox.fields.user.placeholder'),
            dataAttribute: 'shortName',
            dataId: 'userId',
            fieldType: "dropdown",
            width: 4
        }, {
            fieldId: 'contact',
            fieldLabel: t('events.list.searchBox.fields.contact.label'),
            fieldPlaceHolder: t('events.list.searchBox.fields.contact.placeholder'),
            fieldType: 'text',
            width: 4
        }]];
    }
}

function getEventListColumns(hasParent, helpdesk, isMobile) {
    let specialWidth = isMobile? "25%" : "25%";
    return [{
        dataField: 'id',
        isKey: true,
        hidden: true
    }, {
        dataField: 'title',
        headerAttrs: { width: isMobile? "60%" : specialWidth },
        attrs: { width: isMobile? "60%" : specialWidth },
        text: t('events.list.tableHeaders.title'),
        headerClasses: "xs-sm-ta-center",
        classes: isMobile ? "" : "xs-sm-ta-center",
        sort: true,
        formatter: (value) => {
            return <span>{value}</span>
        }
    },{
        dataField: helpdesk ? 'entity' : 'batch',
        headerAttrs: { width: specialWidth },
        attrs: { width: specialWidth },
        classes: "hidden-xs hidden-sm",
        headerClasses: "hidden-xs hidden-sm",
        hidden: isMobile || hasParent,
        sort: true,
        text: helpdesk ? t('events.list.tableHeaders.entity') : t('events.list.tableHeaders.batch') + " / " + t('events.list.tableHeaders.entity'),
        formatter: (value, row) => {
            let hasFullCode = false;
            let fullCode = "---";
            if(!_.isNil(value)) {
                fullCode = ProductionUtils.getBatchCodeWithEnterpriseCodeObject(value).complexCode;
                hasFullCode = fullCode !== "---";
            }
            let entityComplexName;
            let entity = row.entity;
            if (_.isString(entity)) {
                entityComplexName = entity;
            } else if (entity.type.name === EntityService.entityType.SECTOR.name) {
                entityComplexName = entity.farmOfSector.displayName + ' | ' + entity.displayName;
            } else {
                entityComplexName = entity.displayName;
            }

            if(helpdesk) {
                return <span>{entityComplexName}</span>;
            } else {
                return <span className="multi-line">
                    {
                        hasFullCode ?
                            <NavLink
                                to={{
                                    pathname: "/production/batches/" + row.batch.id + "/details",
                                    moreProps: {
                                        historyBack: true
                                    }
                                }}
                                onClick={(e) => e.stopPropagation()}
                            >
                                <span>{fullCode}</span>
                            </NavLink>
                            :
                            <span>{fullCode}</span>
                    }
                 <NavLink
                     to={{
                         pathname: "/system/entities/" + row.entity.id + "/details",
                         moreProps: {
                             historyBack: true
                         }
                     }}
                     onClick={(e) => e.stopPropagation()}
                 >
                    <span>{entityComplexName}</span>
                </NavLink>
            </span>
            }
        }

    }, {
        dataField: 'severity',
        headerAttrs: { width: isMobile? "30%" : "10%" },
        attrs: { width: isMobile? "30%" : "10%" },
        text: t('events.list.tableHeaders.severity'),
        formatter: (value) => {
            let statusValue  = t('events.list.searchBox.fields.severity.' + value.toLowerCase());
            return (
                <span className={'event-severity-' + value.toLowerCase()}>
                    <b>{statusValue}</b>
                </span>

            )
        }
    }, {
        dataField: 'category',
        headerAttrs: { width: '15%' },
        attrs: { width: '15%' },
        text: t('events.list.tableHeaders.category'),
        hidden: isMobile || helpdesk,
        classes: "hidden-xs hidden-sm",
        headerClasses: "hidden-xs hidden-sm",
        formatter: (entity) => {
            let result;
            if(_.isString(entity)){
                result = entity;
            } else if(!_.isNil(entity) && !_.isNil(entity.name)) {
                result = entity.name;
            } else {
                result = '---';
            }
            return <span>{result}</span>
        }
    }, {
        dataField: 'openDate',
        text: t('events.list.tableHeaders.open'),
        classes: "hidden-xs hidden-sm",
        headerClasses: "hidden-xs hidden-sm",
        sort: true,
        headerAttrs: { width: isMobile ? "25%" : '15%' },
        attrs: { width: isMobile ? "25%" : '15%' },
        formatter: (value, data) => {
            let openDate = _.isNil(value) ? '---' : DateUtils.simpleDisplayDateTime(value);
            return (
                <span className="multi-line">
                    <span>{openDate}</span>
                    <span>{_.isNil(data.createdBy) ? t('events.list.tableHeaders.createdBy.system') : data.createdBy.shortName}</span>
                </span>
            )
        }
    }, {
        dataField: 'closeDate',
        text: t('events.list.tableHeaders.close'),
        classes: "hidden-xs hidden-sm",
        headerClasses: "hidden-xs hidden-sm",
        sort: true,
        headerAttrs: {width: isMobile ? "20%" : '15%'},
        attrs: {width: isMobile ? "20%" : '15%'},
        formatter: (value, data) => {
            if(_.isNil(value)){
                return (
                        <span>---</span>
                )
            }
            const closeDate = DateUtils.simpleDisplayDateTime(value);
            const closedBy = data?.closedBy;
            return (
                <span className="multi-line">
                    <span>{closeDate}</span>
                    <span>{_.isNil(closedBy) ? t('events.list.tableHeaders.createdBy.system') : closedBy.shortName}</span>
                </span>
            );
        }
    }, {
        dataField: 'status',
        text: t('events.list.tableHeaders.status'),
        classes: "hidden-xs hidden-sm",
        headerClasses: "hidden-xs hidden-sm",
        headerAttrs: { width: isMobile ? "20%" : '18%' },
        attrs: { width: isMobile ? "20%" : '18%' },
        sort: true,
        formatter: (value, data) => {
            const status = t("events.list.searchBox.fields.status." + _.toLower(value));
            const eventOccurrencesCounterIcon = data.notificationCounter && data.latestOccurrenceTimestampUtc && (+data.notificationCounter > 1) && !isMobile ? (
                <Tooltip
                    content={DateUtils.simpleDisplayDateTime(data.latestOccurrenceTimestampUtc)}
                    allowHTML={true} hideOnClick={false}>
                            <span className='flex align-items-center mr-10 ml-8'>
                                <span className="font-bold bg-gray event-notification-counter-container">{data.notificationCounter}</span>
                                <span>
                                    <FontAwesomeIcon icon="bell" className={`fa-lg fa-fw m-r-md c-darkGray h-1_2em`}/>
                                </span>
                            </span>
                </Tooltip>
            ) : undefined
            return (
                <div className={"flex flex-1 align-items-center justify-between"}>
                    <span className={ 'event-status-' + _.toLower(status)}><b>{status}</b></span>
                    {eventOccurrencesCounterIcon}
                </div>
            )
        }
    }];
}

function getNewOriginListToBatches(originList){
    let newOriginList=[];
    let automaticIdList = null;
    originList.filter(data => data.id !== 'MANUAL').forEach(record=> {
        if(__.isNilOrEmpty(automaticIdList)){
            automaticIdList = record.id;
        }else {
            automaticIdList = automaticIdList + '&origin=' + record.id;
        }
    });
    newOriginList.push({id: "MANUAL", name: t('events.list.searchBox.fields.origin.manual')}, {id: automaticIdList, name: t('events.list.searchBox.fields.origin.automatic')});
    return newOriginList;
}

function validateEventRequestMandatoryParameters(searchData) {
    if(__.isNilOrEmpty(searchData)) {
        return false;
    }
    return !__.isNilOrEmpty(searchData.fromDate.value) ||
        !__.isNilOrEmpty(searchData.toDate.value) ||
        !__.isNilOrEmpty(searchData.lastTime.value);
}

function validateEventTimeSearch(key, value, search, lastTime, ignore) {
    if(key === "lastTime") {
        return {
            ...search,
            toDate: {
                ...search.toDate,
                value: undefined
            },
            fromDate: {
                ...search.fromDate,
                value: undefined
            }
        }
    } else if( key === "toDate") {
        let fromDate = search?.fromDate?.value;
        if(__.isNilOrEmpty(fromDate) &&  __.isNilOrEmpty(value) && !ignore) {
            return {
                ...search,
                lastTime: {
                    ...search.lastTime,
                    value: lastTime
                }
            }
        }
        return {
            ...search,
            lastTime: {
                ...search.lastTime,
                value: undefined
            }
        }
    } else if(key === "fromDate") {
        let toDate = search?.toDate?.value;
        if(__.isNilOrEmpty(value) &&  __.isNilOrEmpty(toDate) && !ignore) {
            return {
                ...search,
                lastTime: {
                    ...search.lastTime,
                    value: lastTime
                }
            }
        }

        return {
            ...search,
            lastTime: {
                ...search.lastTime,
                value: undefined
            }
        }
    } else {
        return search;
    }

}

function loadCacheSearch(listViewKey, params, setSearchData) {
    let cache = {search: {}, pageable: {}};
    if(listViewKey) {
        const cacheStr = UserSessionService.getSessionStorageEntry(config.tables[listViewKey].cacheKey);
        cache = __.isNilOrEmpty(cacheStr) ? {search: {}, pageable: {}} : JSON.parse(cacheStr);
    }

    setSearchData((state) => {
        Object.keys(cache.search).forEach(k => {
            state = {
                ...state,
                search : {
                    ...state.search,
                    [k]: {
                        ...state.search[k],
                        value: cache.search[k]
                    }
                }
            }
        });

        Object.keys(params).forEach(k => {
            state = {
                ...state,
                search : {
                    ...state.search,
                    [k]: {
                        ...state.search[k],
                        value: params[k]
                    }

                },
            }
        });

        if(!__.isNilOrEmpty(cache.search.fromDate) || !__.isNilOrEmpty(cache.search.toDate)) {
            state = {
                ...state,
                search : {
                    ...state.search,
                    lastTime: {
                        ...state.search.lastTime,
                        value: undefined
                    }

                },
            }
        }

        return {
            ...state,
            pageable: {
                ...state.pageable,
                ...cache.pageable
            }
        };
    });
}

function getEventOccurrenceListColumns() {
    return [{
        dataField: 'occurrenceTimestampUtc',
        text: t('events.list.tableHeaders.date'),
        headerAttrs: { width: '80%' },
        attrs: { width: '80%' },
        sort: true,
        formatter: (value) => {
            return <span>{_.isNil(value) ? '---' : DateUtils.simpleDisplayDateTime(value)}</span>
        }
    }, {
        dataField: 'contacts',
        text: t('events.list.tableHeaders.actions'),
        headerClasses: "text-align-center",
        headerAttrs: { width: '20%' },
        attrs: { width: '20%' },
        sort: false,
        formatter: (value, data) => {
            const mailContent = hasMailNotification(data.actions)
            const whatsappContent = hasWhatsappNotification(data.actions)
            const smsContent = hasSmsNotification(data.actions)
            const voiceContent = hasVoiceNotification(data.actions)

            if(!mailContent && !whatsappContent && !smsContent && !voiceContent){
                return <p className={"text-align-center"}>{t('events.list.eventOccurrences.noActions')}</p>
            }

            return <div className={"flex justify-around"}>
                {mailContent}
                {smsContent}
                {voiceContent}
                {whatsappContent}
            </div>
        }
    }];
}

function buildAction(action, icon) {
    if(!action || !action.status){
        return undefined
    } else {
        let content = "";
        let contentCounter = 0;
        if(action.notifyResponsible && action.responsibles && action.responsibles.length > 0){
            action.responsibles.forEach(responsible => {
                content += responsible.shortName + " (" + t('events.form.sendEvent.notifyResponsible.responsible') + " )\n"
                contentCounter++;
            })
        }

        if(action?.properties?.usersList.length > 0){
            const userList = RuleCacheService.getActiveUsers();
            action.properties.usersList.forEach(notificationUser => {
                const userName = userList.find(user => user.id === notificationUser)?.shortName
                if(userName){
                    content += userName + "\n"
                    contentCounter++;
                }
            });
        }

        if(action?.properties?.contactsList.length > 0){
            action.properties.contactsList.forEach(notificationContact => {
                content += notificationContact + "\n"
                contentCounter++;
            })
        }

        return(
            <div className={"flex align-items-center"}>
                <span className={"mr-5 font-bold"}>{contentCounter}</span>
                <Tooltip content={content}>
                    {icon}
                </Tooltip>
            </div>
        )

    }

}

function hasMailNotification(actions) {
    const mailAction = actions.find(action => action.type === EventService.eventTypes.mail);

    return buildAction(mailAction, <i className="fa fa-envelope-o fa-2x" aria-hidden="true"></i>)
}

function hasWhatsappNotification(actions) {
    const whatsappAction = actions.find(action => action.type === EventService.eventTypes.whatsapp);

    return buildAction(whatsappAction, <i className="fa fa-whatsapp fa-2x" aria-hidden="true"></i>)
}

function hasSmsNotification(actions) {
    const smsAction = actions.find(action => action.type === EventService.eventTypes.sms);

    return buildAction(smsAction, <i className="fa fa-commenting-o fa-2x" aria-hidden="true"></i>)
}

function hasVoiceNotification(actions) {
    const voiceAction = actions.find(action => action.type === EventService.eventTypes.voice);

    return buildAction(voiceAction, <i className="fa fa-phone fa-2x" aria-hidden="true"></i>)
}


export {
    getEventsSearchBoxDefinition,
    getEventListColumns,
    getEventOccurrenceListColumns,
    getNewOriginListToBatches,
    validateEventRequestMandatoryParameters,
    validateEventTimeSearch,
    loadCacheSearch
}