import React from 'react';
import classnames from 'classnames';
import TabContent from './TabContent';
import TabTitle from './TabTitle';
import PropTypes from 'prop-types';
import _ from "lodash";

class TabbedPanel extends React.Component {
    constructor(props) {
        super(props);

        this.titleRefs = {};
        this.contentRefs = {};

        if (props.tabTitles.length !== props.tabPanels.length) {
            console.error("The number of titles must be equal to the number of panels");
        }

        this.state = {
            tabTitles: [],
            tabPanels: [],
            lazyLoad: !_.isNil(props.lazyLoad)? props.lazyLoad : false,
            activeTabPosition: !_.isNil(props.activeTabPosition)? props.activeTabPosition : 0,
            tabsLocation: props.tabsLocation ? props.tabsLocation : "top",
            tabTitleTextAlignment: props.tabTitleTextAlignment ? props.tabTitleTextAlignment : "left"
        };
    }

    updateTabTitlesAndContent(props) {
        let tabTitles = [];
        let tabPanels = [];
        let self = this;
        let onTabSelected = props.onTabSelected ? props.onTabSelected : () => {};

        for (let i = 0; i < props.tabTitles.length; i++) {
            tabTitles.push(
                <TabTitle
                    className={props.tabHeaderClass}
                    tabTitleTextAlignment={this.state.tabTitleTextAlignment}
                    key={"tabtitle-" + i}
                    position={i}
                    active={this.state.activeTabPosition === i}
                    text={props.tabTitles[i]}
                    onClick={tabPosition => {
                        self.setTabActive(tabPosition);
                        onTabSelected(tabPosition, self.state.activeTabPosition);
                    }}
                    ref={(c) => self.titleRefs[i] = c}
                />
            );

            tabPanels.push(
                <TabContent
                    key={"tabcontent-" + i}
                    position={i}
                    lazyLoad={this.state.lazyLoad}
                    active={this.state.activeTabPosition === i}
                    componentToShow={props.tabPanels[i]}
                    ref={(c) => self.contentRefs[i] = c}
                />
            );
        }
        this.setState({
            tabTitles : tabTitles,
            tabPanels : tabPanels
        });
    }

    componentWillMount() {
        this.updateTabTitlesAndContent(this.props);
    }

    componentWillReceiveProps(nextProps) {
        let previousPosition = this.state.activeTabPosition;

        if(nextProps.forceActiveTabPosition) {
            this.setState({
                activeTabPosition: nextProps.activeTabPosition
            }, () => {
                if(previousPosition !== nextProps.activeTabPosition) {
                    this.titleRefs[previousPosition].setActive(false);
                    this.contentRefs[previousPosition].setActive(false);
                    this.titleRefs[nextProps.activeTabPosition].setActive(true);
                    this.contentRefs[nextProps.activeTabPosition].setActive(true);
                }
                this.updateTabTitlesAndContent(nextProps);
            });
        } else {
            this.updateTabTitlesAndContent(nextProps);
        }
    }

    render() {
        return (
            <div className={classnames("tabs-container", "removeTopBorder", this.props.ignoreMargins? "" : "ml-total-15", this.props.className)}>
                <div className={"tabs-" + this.state.tabsLocation}>
                    <ul className="nav nav-tabs">
                        {this.state.tabTitles}
                    </ul>
                    <div className="tab-content">
                        {this.state.tabPanels}
                    </div>
                </div>
            </div>);
    }

    setTabActive(position) {
        let self = this;
        let previousPosition = this.state.activeTabPosition;
        position = position < this.state.tabTitles.length ? position : 0;
        this.setState({
            activeTabPosition: position
        }, () =>{
            if(previousPosition !== position) {
                self.titleRefs[previousPosition].setActive(false);
                self.contentRefs[previousPosition].setActive(false);
                self.titleRefs[position].setActive(true);
                self.contentRefs[position].setActive(true);
            }
        });
    }
}

TabbedPanel.propTypes = {
    className: PropTypes.string,
    tabHeaderClass: PropTypes.string,
    tabsLocation: PropTypes.string,
    tabTitleTextAlignment: PropTypes.string,
    tabTitles: PropTypes.array,
    tabPanels: PropTypes.array,
    onTabSelected: PropTypes.func,
    forceActiveTabPosition: PropTypes.bool,
};

export default TabbedPanel;