import * as actionTypes from "./actionTypes";

const initialState =  {
    widgetsData: {},
    interfacesDetails: {},
    entitiesDetails: {}
};

const widgetConfigReducer = (state = initialState, payload) => {
    switch (payload.type) {
        case actionTypes.LOAD_EVENTS_COUNTER_WIDGET_DATA_REQUEST:
        case actionTypes.LOAD_RULES_COUNTER_WIDGET_DATA_REQUEST:
        case actionTypes.LOAD_CHART_WIDGET_DATA_REQUEST:
        case actionTypes.LOAD_MORTALITY_BY_CAUSE_WIDGET_DATA_REQUEST:
        case actionTypes.LOAD_BATCH_INFO_WIDGET_DATA_REQUEST:
        case actionTypes.LOAD_MAP_WIDGET_DATA_REQUEST:
        case actionTypes.LOAD_SILO_WIDGET_DATA_REQUEST:
        case actionTypes.LOAD_WIDGET_DATA_REQUEST:
        case actionTypes.LOAD_CONSUMPTION_WIDGET_DATA_REQUEST:
        case actionTypes.LOAD_ANIMAL_WEIGHT_WIDGET_DATA_REQUEST:
            return {
                ...state,
                widgetsData: {
                    ...state.widgetsData,
                    [payload.id]: {
                        ...state.widgetsData[payload.id],
                        loading: true
                    }
                }
            };
        case actionTypes.LOAD_WIDGET_DATA_SUCCESS:
            return {
                ...state,
                widgetsData: {
                    ...state.widgetsData,
                    [payload.id]: {
                        ...state.widgetsData[payload.id],
                        data: payload.data,
                        error: false,
                        loading: false
                    }
                }
            };
        case actionTypes.LOAD_WIDGET_DATA_FAILED:
            return {
                ...state,
                widgetsData: {
                    ...state.widgetsData,
                    [payload.id]: {
                        ...state.widgetsData[payload.id],
                        data: {},
                        error: true,
                        loading: false
                    }
                }
            };
        case actionTypes.LOAD_WIDGET_INTERFACE_ID_SUCCESS:
            return {
                ...state,
                interfacesDetails: {
                    ...state.interfacesDetails,
                    ...payload.data
                }
            };
        case actionTypes.LOAD_WIDGET_INTERFACE_ID_FAILED:
            return {
                ...state,
                interfacesDetails: {
                    ...state.interfacesDetails,
                }
            };
        case actionTypes.LOAD_WIDGET_ENTITIES_ID_SUCCESS:
            return {
                ...state,
                entitiesDetails: {
                    ...state.entitiesDetails,
                    ...payload.data
                }
            };
        case actionTypes.LOAD_WIDGET_ENTITIES_ID_FAILED:
            return {
                ...state,
                entitiesDetails: {
                    ...state.entitiesDetails,
                }
            };
        case actionTypes.CLEAN_LAYOUT:
            return initialState;
        default:
            return state;
    }
};

export default widgetConfigReducer;