import * as actionTypes from "./actionTypes";
import _ from "lodash";

const initialState =  {
    realTimeInterfaces: [],
    realTimeInterfacesData: {},
    aggregateInterfaces: [],
    interfaceAggregateData: {}
};

const realTimeReducer = (state = initialState, payload) => {
    switch (payload.type) {
        // case actionTypes.LOAD_WIDGET_INTERFACE_ID_SUCCESS:
        //     return {
        //         ...state,
        //         // realTimeInterfacesData: {
        //         //     ...state.realTimeInterfacesData,
        //         //     //...getRealTimeMissingData(state.realTimeInterfaces, payload.data)
        //         // }};
        //     }
        case actionTypes.UPDATE_REAL_TIME_INTERFACES:
            return {
                ...state,
                realTimeInterfaces: _.uniq(payload.interfaceIds)
            };
        case actionTypes.UPDATE_AGGREGATE_INTERFACES:
            return {
                ...state,
                aggregateInterfaces: _.uniq(payload.interfaceIds)
            };
        case actionTypes.UPDATE_REAL_TIME_INTERFACES_DATA_SUCCESS:
            return {
                ...state,
                realTimeInterfacesData: {
                    ...state.realTimeInterfacesData,
                    ...payload.readings.reduce(
                        (acc, reading) => ({
                            ...acc,
                            [reading.interfaceId]: {
                                value: reading.value,
                                timestamp: reading.readingTimestampUtc
                            }
                        }),
                        {}
                    )
                }};
        case actionTypes.UPDATE_REAL_TIME_INTERFACES_AGGREGATE_DATA_SUCCESS:
            return {
                ...state,
                interfaceAggregateData: payload.readings.reduce(
                    (acc, reading) => ({
                        ...acc,
                        [reading.interfaceId]: {
                            min: reading.min,
                            max: reading.max,
                            sum: reading.sum,
                            avg: reading.avg,
                        }
                    }),
                    {}
                )
            };
        case actionTypes.CLEAN_LAYOUT:
            return initialState;
        default:
            return state;
    }
};


// function getRealTimeMissingData(realTimeInterfaces, interfaceDetails) {
//     let realTimeInterfacesData = {};
//     _.forOwn(interfaceDetails, inter => {
//         realTimeInterfacesData = {
//             ...realTimeInterfacesData,
//             [inter.id] : {
//                 value: inter.lastConvertedReading,
//                 timestamp: inter.lastReadingTimestampUtc
//             }
//         }
//     });
//     return realTimeInterfacesData;
// }

export default realTimeReducer;