import {call, put, takeEvery} from 'redux-saga/effects';
import * as actionTypes from './actionTypes';
import axios from "axios";
import AsyncProductionCurveService from "../../services/async/AsyncProductionCurveService";
import AsyncProductionBatchService from "../../services/async/AsyncProductionBatchService";

function* getBatchListRequest({entityId}) {
    try {
        const batches = yield call(getBatchListCall, entityId);

        yield put ({
            type: actionTypes.GET_BATCH_LIST_SUCCESS,
            payload: batches
        })
    } catch (e) {
        yield put ({
            type: actionTypes.GET_BATCH_LIST_FAILED,
            payload: e
        })
    }
}

const getBatchListCall = (farmId) => new Promise ((resolve, reject) => {
    axios.get("/batches/" , {params: {
            entityId: farmId,
            status: true
        }}).then(
        response => resolve(response.data),
        error => reject(error)
    )
});

function* getBatchRequest({batchId}) {
    try {
        let batch = yield call(getBatchCall, batchId);
        yield put ({
            type: actionTypes.GET_BATCH_SUCCESS,
            batch: batch
        })
    } catch (e) {
        yield put ({
            type: actionTypes.GET_BATCH_FAILED,
            payload: e
        })
    }
}

const getBatchCall = (batchId) => new Promise ((resolve, reject) => {
    axios.get("/batches/" + batchId).then(
        response => resolve(response.data),
        error => reject(error)
    )
});

function* getProductionCurveRequest({curveId}) {
    try {
        let curve = yield call(() => AsyncProductionCurveService.fetchCurveDetails(curveId));
        yield put ({
            type: actionTypes.GET_PRODUCTION_CURVE_SUCCESS,
            payload: curve,
            curveId: curveId
        })
    } catch (e) {
        yield put ({
            type: actionTypes.GET_PRODUCTION_CURVE_FAILED,
            payload: e
        })
    }
}

function* getProductionBatchCurveDays({batchId}) {
    try {
        let curveWindowRange = yield call(() => AsyncProductionBatchService.getProductionBatchCurveDays(batchId));
        yield put ({
            type: actionTypes.GET_PRODUCTION_BATCH_WINDOW_SUCCESS,
            payload: curveWindowRange,
            batchId: batchId
        })
    } catch (e) {
        yield put ({
            type: actionTypes.GET_PRODUCTION_BATCH_WINDOW_FAILED,
            batchId: batchId,
            payload: e
        })
    }
}

const batchesSagas = [
    takeEvery(actionTypes.GET_BATCH_LIST_REQUEST, getBatchListRequest),
    takeEvery(actionTypes.GET_BATCH_REQUEST, getBatchRequest),
    takeEvery(actionTypes.GET_PRODUCTION_CURVE_REQUEST, getProductionCurveRequest),
    takeEvery(actionTypes.GET_PRODUCTION_BATCH_WINDOW_REQUEST, getProductionBatchCurveDays)
];

export { batchesSagas };