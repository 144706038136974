import React, {useState} from "react";
import {t} from "../../../utils/Translation";
import Row from "../../../components/row/Row";
import LabeledField from "../../../components/fields/labeled/LabeledField";
import EventService from "./../../../services/EventService";
import {config} from "../../../config";
import Dropzone from "../../../components/dropzone/Dropzone";
import Alerts from "./../../../utils/AlertUtils";
import FormHeader from "../../../components/header/FormHeader";
import DateUtils from "../../../utils/DateUtils";
import __ from "../../../utils/EnhancedLodash";
import EventAction from "./EventAction";
import Loading from "../../../components/loading/Loading";
import BasePanel from "../../../components/panels/basepanel/BasePanel";
import EntityAudit from "../../base/entityAudit/EntityAuditView";
import {Checkbox} from "react-bootstrap";
import "../style/events.scss";
import useWindowSize from "../../../hooks/useWindowSize";
import ImgButton from "../../../components/buttons/ImgButton";
import rule from "../../../img/rules/rule.svg";
import ruleOnHover from "../../../img/rules/rule_hover.svg";
import EventOccurrences from "./EventOccurrences";
import RoundedButton from "../../../components/buttons/RoundedButton";

const EventDetailsForm =
    ({
         isCreate,
         isView,
         isEdit,
         helpdesk,
         hasEditPermission,
         hasDeletePermission,
         hasParent,

         data : {
             title,
             createdBy,
             status,
             openDate,
             cause,
             enterpriseId,
             entityId,
             sectorId,
             batchId,
             severityLevel,
             description,
             category,
             additionalNotes,
             origin,
             changes,
             ruleId,
             audit
         },

         //callbacks
         onBack = ()=>{},
         onEdit = ()=>{},
         onDelete = ()=>{},
         onFormSubmit = ()=>{},
         onRuleDetailClick = ()=>{},
         handleFieldChanged = ()=>{},
         handleOnFieldBlur = ()=>{},
         handleExportFile = ()=>{},

         dropzoneRef,
         componentRef,
         actionTypes = [],
         loading,
         eventId
     }) => {

        const [createNew, setCreateNew] = useState(false);
        const {isMobile} = useWindowSize();

        return (
            <BasePanel>
                <FormHeader
                    isCreate={isCreate}
                    isView={isView}
                    isEdit={isEdit}
                    hasEditPermission={hasEditPermission}
                    hasDeletePermission={hasDeletePermission}
                    onBack={onBack}
                    onEdit={onEdit}
                    onDelete={onDelete}
                    hasAdditionalIcons={ruleId}
                    additionalIcons={(
                        <ImgButton
                            alt = "rule"
                            handleIconClick={() => onRuleDetailClick(ruleId)}
                            image={rule}
                            onHoverImage = {ruleOnHover}
                        />
                    )}
                />
                { loading && <Loading className="form-loading"/> }
                <div className={loading ? "opacity-0" : "opacity-1" }>
                    <Row>
                        <div className='form-group col-md-12'>
                            <Row>
                                <LabeledField
                                    forceNewPropValue={true}
                                    fieldLabel={t('events.form.title.label')}
                                    fieldPlaceHolder={t('events.form.title.placeholder')}
                                    fieldType='text'
                                    width={"8"}
                                    isRequired={true}
                                    isView={origin.isAutomatic || isView}
                                    requiredFailError={t('events.form.errors.title.required')}
                                    value={title.value}
                                    error={title.error}
                                    maxTextLength={150}
                                    onFieldChange={value => handleFieldChanged(value, "title")}
                                    onBlur={() => handleOnFieldBlur("title")}
                                />
                                <LabeledField
                                    forceNewPropValue={true}
                                    fieldLabel={t('events.form.createdBy.label')}
                                    fieldType='text'
                                    width='4'
                                    isRequired={true}
                                    isView={true}
                                    isVisible={!__.isNilOrEmpty(createdBy.value)}
                                    value={createdBy.value}
                                    error={createdBy.error}
                                />
                            </Row>
                            <Row>
                                {!helpdesk && (
                                    <LabeledField
                                        forceNewPropValue={true}
                                        fieldLabel={t('events.form.enterprise.label')}
                                        fieldPlaceHolder={t('events.form.enterprise.defaultOption')}
                                        fieldType="dropdown"
                                        dataAttribute='displayName'
                                        width="4"
                                        isRequired={true}
                                        isVisible={!hasParent}
                                        isView={origin.isAutomatic || isView}
                                        requiredFailError={t('events.form.errors.enterprise.required')}
                                        iconClassName="fa fa-sitemap"
                                        selectData={enterpriseId.data}
                                        value={enterpriseId.value}
                                        error={enterpriseId.error}
                                        onFieldChange={value => handleFieldChanged(value, "enterpriseId")}
                                        onBlur={() => handleOnFieldBlur("enterpriseId")}
                                    />
                                )}

                                <LabeledField
                                    forceNewPropValue={true}
                                    fieldLabel={t('events.form.entity.label')}
                                    fieldPlaceHolder={t('events.form.entity.defaultOption')}
                                    fieldType='dropdown'
                                    dataAttribute= {!helpdesk ? 'complexName' : 'displayName'}
                                    width='4'
                                    isVisible={!hasParent}
                                    isRequired={true}
                                    isView={origin.isAutomatic || isView}
                                    requiredFailError={t('events.form.errors.entity.required')}
                                    iconClassName='fa fa-sitemap'
                                    selectData={entityId.data}
                                    value={entityId.value}
                                    error={entityId.error}
                                    onFieldChange={value => handleFieldChanged(value, "entityId")}
                                    onBlur={() => handleOnFieldBlur("entityId")}
                                />
                                {helpdesk && (
                                    <LabeledField
                                        forceNewPropValue={true}
                                        fieldLabel={t('events.form.sector.label')}
                                        fieldPlaceHolder={t('events.form.sector.defaultOption')}
                                        fieldType='dropdown'
                                        dataAttribute='displayName'
                                        width='4'
                                        isVisible={!hasParent}
                                        isRequired={false}
                                        isView={origin.isAutomatic || isView}
                                        requiredFailError={t('events.form.errors.sector.required')}
                                        iconClassName='fa fa-sitemap'
                                        selectData={sectorId.data}
                                        value={sectorId.value}
                                        error={sectorId.error}
                                        onFieldChange={value => handleFieldChanged(value, "sectorId")}
                                        onBlur={() => handleOnFieldBlur("sectorId")}
                                    />)
                                }
                                <LabeledField
                                    forceNewPropValue={true}
                                    fieldLabel={t('events.form.productionBatch.label')}
                                    fieldPlaceHolder={t('events.form.productionBatch.defaultOption')}
                                    fieldType='dropdown'
                                    dataAttribute='complexCode'
                                    width='4'
                                    isVisible={!hasParent && !helpdesk && batchId.visible}
                                    isRequired={false}
                                    isView={origin.isAutomatic || isView}
                                    selectData={batchId.data}
                                    value={batchId.value}
                                    error={batchId.error}
                                    onFieldChange={value => handleFieldChanged(value, "batchId")}
                                />
                            </Row>
                            <Row className={'pd-t-15'}>
                                <LabeledField
                                    forceNewPropValue={true}
                                    fieldLabel={t('events.form.severity.label')}
                                    fieldType='button-group'
                                    width='6'
                                    isRequired={true}
                                    isView={origin.isAutomatic || isView}
                                    selectData={EventService.getSeverityList()}
                                    sort={data => data}
                                    requiredFailError={t('events.form.errors.severity.required')}
                                    value={severityLevel.value}
                                    error={severityLevel.error}
                                    onFieldChange={value => handleFieldChanged(value, "severityLevel")}
                                    onBlur={() => handleOnFieldBlur("severityLevel")}
                                />
                            </Row>
                            <Row className={'pd-t-15'}>
                                <LabeledField
                                    forceNewPropValue={true}
                                    fieldLabel={t('events.form.description.label')}
                                    fieldPlaceHolder={t('events.form.description.placeholder')}
                                    fieldType='text-area'
                                    width='12'
                                    maxTextLength={500}
                                    isView={origin.isAutomatic || isView}
                                    requiredFailError={t('events.form.errors.description.required')}
                                    value={description.value}
                                    error={description.error}
                                    onFieldChange={value => handleFieldChanged(value, "description")}
                                />
                            </Row>
                            <Row>
                                <LabeledField
                                    forceNewPropValue={true}
                                    fieldLabel={t('events.form.category.label')}
                                    fieldPlaceHolder={t('events.form.category.placeholder')}
                                    fieldType='dropdown'
                                    width='4'
                                    isRequired={true}
                                    isView={origin.isAutomatic || isView}
                                    requiredFailError={t('events.form.errors.category.required')}
                                    selectData={category.data}
                                    value={category.value}
                                    error={category.error}
                                    onFieldChange={value => handleFieldChanged(value, "category")}
                                    onBlur={() => handleOnFieldBlur("category")}
                                />
                                <LabeledField
                                    forceNewPropValue={true}
                                    fieldLabel={t('events.form.cause.label')}
                                    fieldPlaceHolder={t('events.form.cause.placeholder')}
                                    fieldType='dropdown'
                                    width='4'
                                    isRequired={true}
                                    isView={isView}
                                    selectData={cause.data}
                                    value={cause.value}
                                    error={cause.error}
                                    onFieldChange={value => handleFieldChanged(value, "cause")}
                                    onBlur={() => handleOnFieldBlur("cause")}
                                />
                            </Row>
                            <Row>
                                <LabeledField
                                    forceNewPropValue={true}
                                    fieldLabel={t('events.form.status.label')}
                                    fieldPlaceHolder={t('events.form.status.placeholder')}
                                    fieldType='dropdown'
                                    width='4'
                                    isRequired={true}
                                    isView={isView}
                                    iconClassName='fa fa-repeat'
                                    selectData={EventService.getStatusList()}
                                    sort={data => data}
                                    requiredFailError={t('events.form.errors.status.required')}
                                    value={status.value}
                                    error={status.error}
                                    onFieldChange={value => handleFieldChanged(value, "status")}
                                    onBlur={() => handleOnFieldBlur("status")}
                                />
                                <LabeledField
                                    forceNewPropValue={true}
                                    fieldLabel={t('events.form.openDate.label')}
                                    fieldPlaceHolder={t('events.form.openDate.placeholder')}
                                    fieldType='dateTime'
                                    hasDate={true}
                                    hasTime={true}
                                    width='4'
                                    disabled={helpdesk}
                                    isRequired={true}
                                    isView={origin.isAutomatic || isView}
                                    iconClassName='fa fa-calendar'
                                    requiredFailError={t('generic.errors.emptyRequiredField')}
                                    value={openDate.value}
                                    error={openDate.error}
                                    onFieldChange={value => handleFieldChanged(value, "openDate")}
                                    onBlur={() => handleOnFieldBlur("openDate")}
                                />
                            </Row>
                            <Row>
                                <LabeledField
                                    forceNewPropValue={true}
                                    fieldLabel={t('events.form.notes.label')}
                                    fieldPlaceHolder={t('events.form.notes.placeholder')}
                                    fieldType='text-area'
                                    width='12'
                                    maxTextLength={1000}
                                    isRequired={false}
                                    isView={isView}
                                    value={additionalNotes.value}
                                    error={additionalNotes.error}
                                    onFieldChange={value => handleFieldChanged(value, "additionalNotes")}
                                />
                            </Row>
                            <Row className='event-list-searchbox-row'>
                                {isView && createTableHistory(changes)}
                            </Row>
                            {!isEdit && !isCreate && (
                                <EventOccurrences eventId={eventId} isAutomatic={origin.isAutomatic}/>
                            )}
                        </div>
                    </Row>
                    <div className='event-list-searchbox-row'>
                        {(!origin.isAutomatic || category.value === EventService.categories.NOTIFICATION) && (
                            <Row>
                                <h3 className='f-bold'>{t('events.form.sendEvent.title')}</h3>
                                {
                                    EventAction.createAccordionFromEventTypes(actionTypes, componentRef, isView, true, helpdesk)
                                }
                            </Row>
                        )}
                        <Row>
                            <h3 className='f-bold'>{t('events.form.files.title')}</h3>
                            <Dropzone
                                maxFilesNumber={config.tables.EVENTS.dropzone.maxFilesNumber}
                                maxFileSize={config.tables.EVENTS.dropzone.maxFileSize}
                                width={12}
                                isView={isView}
                                deleteWarning={true}
                                className='filepicker-white'
                                fieldPlaceHolder={isView ? t('components.dropzone.placeholderView') : t('components.dropzone.placeholder')}
                                errorMaxSize={t('errors.dropzoneField.maxSize')}
                                errorMaxFiles={t('errors.dropzoneField.maxFiles')}
                                onErrorEvent={error => {
                                    Alerts.applicationError({
                                        title: t('errors.dropzoneField.error'),
                                        html: error
                                    }).show();
                                }}
                                onExportEvent={fileName => handleExportFile(fileName)}
                                ref={dropzoneRef}
                            />
                        </Row>
                    </div>
                    {
                        isView && <Row>
                            <EntityAudit
                                {...audit}
                            />
                        </Row>
                    }
                    {createFormButtons(isMobile, isView, isEdit, isCreate, helpdesk, onBack, onFormSubmit, createNew, setCreateNew)}
                </div>
            </BasePanel>
        );
    };

function createTableHistory(changes) {
    return (
        <Row className='event-list-searchbox-row'>
            <div className="hr-line-dashed"/>
            <div className="table-responsive max-height-34em">
                <table className="table table-striped">
                    <thead>
                    <tr>
                        <th style={{ width: "20%"}}>{t('events.form.changes.status')}</th>
                        <th style={{ width: "40%"}}>{t('events.form.changes.notes')}</th>
                        <th style={{ width: "20%"}}>{t('events.form.changes.user')}</th>
                        <th style={{ width: "20%"}}>{t('events.form.changes.date')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        changes?.value.map((c, idx) => {
                                return (
                                    <tr key={"changes_" + idx}>
                                        <td style={{ width: "20%"}}>{c.status}</td>
                                        <td style={{ width: "40%"}}>{c.notes ? __.truncate(c.notes, 1000) : ' - - - ' }</td>
                                        <td style={{ width: "20%"}}>{c.user ? c.user.shortName : ' - - - ' }</td>
                                        <td style={{ width: "20%"}}>{c.date ? DateUtils.simpleDisplayDateTime(c.date) : ' - - - ' }</td>
                                    </tr>
                                )
                            }
                        )
                    }
                    </tbody>
                </table>
            </div>
            <div className="hr-line-dashed"/>
        </Row>
    );
}

function createFormButtons(isMobile, isView, isEdit, isCreate, helpdesk, onBack, onFormSubmit, createNew, setCreateNew) {
    if (isView) {
        return;
    }

    if(isMobile) {
        return (
            <Row>
                <div className="flex flex-row justify-end pd-r-10">
                        {
                            isEdit &&
                            <RoundedButton label={helpdesk ?  t('helpdesk.list.buttons.edit') : t('events.form.buttons.save')}
                                           onClick={() => onFormSubmit()}
                                           disabled={!((isCreate || isEdit))}
                                           classes= "btn-buffer rounded-btn-success pd-5" />
                        }
                        {
                            isCreate &&
                            <>
                                {
                                    !helpdesk && (
                                        <div className="flex cursor-pointer" onClick={() => setCreateNew(!createNew)}>
                                            <span className="mr-5">{t("events.form.buttons.createAnother")}</span>
                                            <Checkbox inline checked={createNew} readOnly className="cursor-pointer"/>
                                        </div>
                                    )
                                }
                                <RoundedButton label={helpdesk ?  t('helpdesk.list.buttons.create') : t('events.form.buttons.submit')}
                                               onClick={() => onFormSubmit(createNew)}
                                               disabled={!((isCreate || isEdit))}
                                               classes= "btn-buffer rounded-btn-success pd-5" />
                            </>
                        }
                    <RoundedButton label={t("generic.cancel")}
                                   onClick={() => onBack()}
                                   disabled={!((isCreate || isEdit))}
                                   classes= "btn-buffer rounded-btn-cancel pd-5" />
                </div>
            </Row>
        );
    } else {
        return (
            <Row>
                <div className="col-lg-3 col-lg-offset-9">
                    <div className="events-submit-btn">
                        {
                            isEdit &&
                            <RoundedButton label={helpdesk ?  t('helpdesk.list.buttons.edit') : t('events.form.buttons.save')}
                                           onClick={() => onFormSubmit()}
                                           disabled={!((isCreate || isEdit))}
                                           classes= "btn-buffer rounded-btn-success pd-5" />
                        }
                        {
                            isCreate &&
                            <>
                                <div className="flex cursor-pointer" onClick={() => setCreateNew(!createNew)}>
                                    <span className="mr-5">{t("events.form.buttons.createAnother")}</span>
                                    <Checkbox inline checked={createNew} readOnly className="cursor-pointer"/>
                                </div>
                                <RoundedButton label={helpdesk ?  t('helpdesk.list.buttons.create') : t('events.form.buttons.submit')}
                                               onClick={() => onFormSubmit(createNew)}
                                               disabled={!((isCreate || isEdit))}
                                               classes= "btn-buffer rounded-btn-success pd-5" />
                            </>
                        }
                        <RoundedButton label={t("generic.cancel")}
                                       onClick={() => onBack()}
                                       disabled={!((isCreate || isEdit))}
                                       classes= "btn-buffer rounded-btn-cancel pd-5" />
                    </div>
                </div>
            </Row>
        );
    }
}


export default EventDetailsForm;