import axios from "axios";
import _ from "lodash";
import LocalesUtils from "../../utils/LocalesUtils";

const AsyncTerminalService = {
    getAllProfiles: function () {
        return new Promise((accept, reject) => {
            axios.get('/terminals/profiles').then(response => {
                accept(_.map(response.data, profile => {
                    return {
                        id: profile.value,
                        name: LocalesUtils.getLocaleString(profile.translations),
                        hasConnectionInterface: profile.hasConnectionInterface
                    };
                }));
            }, reject);
        });
    },

    getTerminalList: function (search) {
        return new Promise((accept, reject) => {
            axios.get('/terminals', {params: search}).then(response => {
                response.data.forEach(terminal => {
                    terminal.name = terminal.displayName;
                });
                accept(response.data);
            }, reject);
        });
    },

    checkTerminalStatus: function (terminalId) {
        return new Promise((accept, reject) => {
            axios.get("/terminals/"+ terminalId +"/check-connection-status")
                .then(response => {
                    accept(response.data);
                }, reject);
        });
    },
};

export default AsyncTerminalService;