import React from "react";
import "./IconButton.scss";
import PropTypes from "prop-types";
import Tooltip from "../tooltip/Tooltip";

const IconButton = ({disabled, onClick, label, icon, imgStyle, alt="", classes="", isMobile=false}) => {

    return (
        <Tooltip content={isMobile ? label : ""}>
            <button className="icon-btn" disabled={disabled} onClick={onClick}>
                <img
                    style={imgStyle}
                    alt={alt}
                    src={icon}
                    className={(disabled ? "disabled " : "") + classes}
                />
                {!isMobile && (
                    <span className="icon-btn-text">{label}</span>
                )}
            </button>
        </Tooltip>
    )
}

IconButton.propTypes = {
    onClick: PropTypes.func,
    label: PropTypes.string.isRequired,
    icon: PropTypes.string,
    disabled: PropTypes.bool,
    isMobile: PropTypes.bool
};

export default IconButton;