import React from "react";
import "./RoundedButton.scss";
import PropTypes from "prop-types";

const RoundedButton = ({disabled, onClick, label, classes= ""}) => {

    return (
        <button className={`rounded-btn ${classes}`} disabled={disabled} onClick={onClick}>
            <span className="rounded-btn-text">{label}</span>
        </button>
    )
}

RoundedButton.propTypes = {
    onClick: PropTypes.func,
    label: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    classes:PropTypes.string
};

export default RoundedButton;