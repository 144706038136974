import * as actionTypes from "./actionTypes";

const initialState =  {
    loading: undefined,
    error: false,
    tree: [],
    interfacesId: [],
    interfacesIdReadings: [],
    interfaceAggregateReadings: []
};

const dashboardTreeReducer = (state = initialState, payload) => {
    switch (payload.type) {
        case actionTypes.RESET_STATE:
            return {
                ...initialState
            };
        case actionTypes.TREE_ENTITY_INTERFACES_REQUEST:
            return {
                ...state,
                loading: true
            };
        case actionTypes.TREE_ENTITY_INTERFACES_SUCCESS:
            return {
                ...state,
                loading: false,
                tree: payload.tree,
                interfacesId: payload.interfacesId
            };
        case actionTypes.TREE_ENTITY_INTERFACES_ERROR:
            return {
                ...state,
                loading: false,
                tree: [],
                error: payload.error
            };
        case actionTypes.TREE_ENTITY_INTERFACES_REAL_TIME_DATA_SUCCESS:
            return {
                ...state,
                interfacesIdReadings: payload.readings.reduce(
                    (acc, reading) => ({
                        ...acc,
                        [reading.interfaceId]: {
                            value: reading.value,
                            timestamp: reading.readingTimestampUtc
                        }
                    }),
                    {}
                )
            };
        case actionTypes.TREE_ENTITY_INTERFACES_AGGREGATE_DATA_SUCCESS:
            return {
                ...state,
                interfaceAggregateReadings: payload.readings.reduce(
                    (acc, reading) => ({
                        ...acc,
                        [reading.interfaceId]: {
                            min: reading.min,
                            max: reading.max,
                            sum: reading.sum,
                            avg: reading.avg,
                        }
                    }),
                    {}
                )
            };
        default:
            return state;
    }
};

export default dashboardTreeReducer;
